import React, { Component } from "react";
import { reduxForm, Field, formValueSelector, initialize } from "redux-form";
import { connect } from "react-redux";

import { find } from "lodash";
import {
  Label,
  FormGroup,
  Row,
  Col,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Form,
  Button,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import moment from "moment";
import {
  required,
  checkHourValue,
  checkMinValue,
  checkSecValue,
  validateSubscriptionExpiry,
  checkRestrictionMinValue,
  validateRestrictionCampaignDuration
} from "../../../helper/validation";
import {
  Resources,
  createUserDomain,
  updateUserDomain,
  reloadHeaderDispatcher
} from "../redux/domainRedux";
import { Resources as UserData } from "../redux";
import { Input } from "../../../core";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { convertToSecond } from "../../../helper";
import DatePicker from "../../../core/form/datePicker";

class AddDomain extends Component {
  constructor(props) {
    super(props);

    const api = [];
    UserData.getGurzuUsers.url = `/users/gurzu_users`;
    api.push(this.props.Get(UserData.getGurzuUsers));

    this.props.initialize({
      subscription: "premium",
      campaignRestrictHour: 0,
      campaignRestrictMinute: 0,
      campaignRestrictSecond: 0
    });
  }

  handleEditFormLoad = () => {
    const {
      currentDomain: {
        attributes: { name }
      }
    } = this.props;
    this.props.initialize({
      name,
      users: this.generateUsersOptions(),
      minute: 10,
      second: 20
    });
  };

  generateUsersOptions = () => {
    const {
      userList,
      currentDomain: {
        relationships: {
          users: { data }
        }
      }
    } = this.props;
    const users = [];
    data.map(item => {
      users.push(find(userList, { id: item.id }));
    });
    return users.map(item => ({ value: item.id, label: item.attributes.name }));
  };

  handleReloadDeviceDetail = async id => {
    this.props.Get(Resources.getDomainsDetails(id));
  };

  handleSubmit = async values => {
    const { users } = values;
    const formattedUser = users
      ? users.map(item => ({
        roles: ["Admin"],
        user_id: item.value
      }))
      : [];

    if (this.props.action === "edit") {
      const currentDomain = JSON.parse(window.localStorage.getItem("domain"));
      const { updateDomain } = Resources;
      const screenShotTime = convertToSecond(values);
      updateDomain.body = createUserDomain({
        name: values.name,
        screenShotTime,
        formattedUser: [],
        subscription: values.subscription
      });
      updateDomain.url = `/domains/${this.props.domain.id}`;
      await this.props.Put(updateDomain).then(() => {
        if (currentDomain.id === this.props.domain.id) {
          currentDomain.name = values.name;
          currentDomain.screenshot_time = values.screenshot_time;
          window.localStorage.setItem(
            "domain",
            JSON.stringify(currentDomain, currentDomain.screenshot_time)
          );
          this.props.reloadHeaderDispatcher();
        }
      });
    } else {
      const { addDomain } = Resources;
      const screenShotTime = convertToSecond(values);
      const campaignRestrictValues = {
        hour: values.campaignRestrictHour,
        minute: values.campaignRestrictMinute,
        second: values.campaignRestrictSecond
      };
      const campaignRestrictTime = convertToSecond(campaignRestrictValues);
      addDomain.body = createUserDomain({
        ...values,
        screenShotTime,
        formattedUser,
        subscription: values.subscription,
        subscription_start_date: values.subscription_start_date
          ? moment(values.subscription_start_date).format("YYYY-MM-DD")
          : null,
        subscription_end_date: values.subscription_end_date
          ? moment(values.subscription_end_date).format("YYYY-MM-DD")
          : null,
        campaignLocked: values.campaignRestrictionFlag,
        campaignLockedDuration: campaignRestrictTime
      });
      await this.props.Post(addDomain).then(() => {
        this.props.loadDomainList(1);
        this.props.refetchDomain(1);
      });
    }
    if (this.props.action === "edit") {
      this.handleReloadDeviceDetail(this.props.domain.id);
    }
    this.props.handleDomainCreate(false);
  };

  render() {
    const { handleSubmit, userList, domain } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.createModal}>
          <ModalHeader toggle={() => this.props.handleDomainCreate(false)}>
            {this.props.action === "edit" ? "Edit Domain" : "Add Domain"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <div className="globalForm globalForm--mod">
                <FormGroup>
                  <div className="globalInput">
                    <Field
                      label="Name"
                      component={Input}
                      name="name"
                      type="text"
                      validate={required}
                      placeholder="Name"
                      props={
                        (this.props.roles.includes("SuperAdmin") &&
                          domain &&
                          domain.attributes.name === "gurzu") ||
                          !this.props.roles.includes("SuperAdmin")
                          ? { disabled: true }
                          : { disabled: false }
                      }
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="globalInput">
                    <label>Subscription Start Date</label>
                    <Field
                      component={props => {
                        const {
                          meta: { touched, error, warning }
                        } = props;
                        return (
                          <div>
                            <DatePicker {...props} />
                            {touched &&
                              ((error && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {error}
                                </div>
                              )) ||
                                (warning && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {warning}
                                  </div>
                                )))}
                          </div>
                        );
                      }}
                      dateFormat="yyyy/MM/dd"
                      name="subscription_start_date"
                      placeholderText="mm/dd/yyyy"
                      validate={validateSubscriptionExpiry}
                      props={
                        (this.props.roles.includes("SuperAdmin") &&
                          domain &&
                          domain.attributes.name === "gurzu") ||
                          !this.props.roles.includes("SuperAdmin")
                          ? { disabled: true }
                          : { disabled: false }
                      }
                    />
                  </div>
                  <div className="globalInput">
                    <label>Subscription End Date</label>
                    <Field
                      component={props => {
                        const {
                          input,
                          label,
                          type,
                          meta: { touched, error, warning }
                        } = props;
                        return (
                          <div>
                            <DatePicker {...props} />
                            {touched &&
                              ((error && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {error}
                                </div>
                              )) ||
                                (warning && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {warning}
                                  </div>
                                )))}
                          </div>
                        );
                      }}
                      dateFormat="yyyy/MM/dd"
                      name="subscription_end_date"
                      placeholderText="mm/dd/yyyy"
                      validate={validateSubscriptionExpiry}
                      props={
                        (this.props.roles.includes("SuperAdmin") &&
                          domain &&
                          domain.attributes.name === "gurzu") ||
                          !this.props.roles.includes("SuperAdmin")
                          ? { disabled: true }
                          : { disabled: false }
                      }
                    />
                  </div>
                </FormGroup>
                {this.props.action === "edit" && (
                  <FormGroup className="globalInput">
                    <Label>Screenshot Time</Label>
                    <div className="d-flex">
                      <div className="domain-input-label text-center">
                        Hours
                      </div>
                      <br />
                      <div className="domain-input-label text-center ml-2">
                        Minutes
                      </div>
                      <br />
                      <div className="domain-input-label text-center ml-4">
                        Seconds
                      </div>
                    </div>
                    <div className="d-flex  globalInput--mod domain-input-group">
                      <Field
                        component={Input}
                        name="hour"
                        type="number"
                        placeholder="HH"
                        label="hour"
                        validate={checkHourValue}
                        min={0}
                        max={24}
                        inputClass="modal-duration text-center"
                      />
                      <b>:</b>
                      <Field
                        component={Input}
                        name="minute"
                        type="number"
                        placeholder="MM"
                        validate={checkMinValue}
                        min={1}
                        max={59}
                        inputClass="modal-duration text-center"
                      />
                      <b>:</b>
                      <Field
                        component={Input}
                        name="second"
                        type="number"
                        placeholder="SS"
                        validate={checkSecValue}
                        min={0}
                        max={59}
                        inputClass="modal-duration text-center"
                      />
                    </div>
                  </FormGroup>
                )}
                <div>
                  <FormGroup>
                    <div className="globalInput">
                      <Label>Plan</Label>
                      <div className="subInput">
                        <div className="subscriptionContainer">
                          <Field
                            name="subscription"
                            component={Input}
                            type="radio"
                            value="basic"
                            id="basic"
                            labelClass="noExtraLabelField"
                            checked={this.props.subscription === "basic"}
                          />{" "}
                          <Label
                            for="basic"
                            className={`${this.props.subscription === "basic" &&
                              "activePlan"}`}
                          >
                            Basic Plan
                          </Label>
                        </div>
                        <div className="subscriptionContainer">
                          <Field
                            name="subscription"
                            component={Input}
                            type="radio"
                            value="premium"
                            id="premium"
                            labelClass="noExtraLabelField"
                            checked={this.props.subscription === "premium"}
                          />{" "}
                          <Label
                            for="premium"
                            className={`${this.props.subscription ===
                              "premium" && "activePlan"}`}
                          >
                            Premium Plan
                          </Label>
                        </div>
                      </div>
                    </div>
                  </FormGroup>{" "}
                </div>
                {this.props.roles.includes("SuperAdmin") && (
                  <div className=" mb-0 userProfile__body  form-validation-arrange userinput toggle-version">
                    <Label>Restrict Campaign</Label>
                    <Label className="ml-1 mb-0 mt-0 switch">
                      <Field
                        name="campaignRestrictionFlag"
                        id="campaignRestrictionFlag"
                        component="input"
                        type="checkbox"
                        checked={this.props.campaignRestrictionFlag}
                      />
                      <p className="slider round" />
                    </Label>
                    <div className="globalInput--mod domain-input-group">
                      <InputGroup
                        style={{
                          background:
                            !this.props.campaignRestrictionFlag && "#e3e3ea"
                        }}
                      >
                        <InputGroupAddon
                          className="mr-2 hour add-domain-input-group-prepend"
                          addonType="prepend"
                        >
                          HH
                        </InputGroupAddon>
                        <div className="domain-input-block">
                          <Field
                            component={Input}
                            name="campaignRestrictHour"
                            type="number"
                            placeholder="HH"
                            label="hour"
                            validate={checkHourValue}
                            min={0}
                            max={24}
                            inputClass="domain-edit-input modal-duration "
                            disabled={!this.props.campaignRestrictionFlag}
                          />
                        </div>
                        <InputGroupAddon
                          className="mr-2 add-domain-input-group-prepend"
                          addonType="prepend"
                        >
                          MM
                        </InputGroupAddon>
                        <div className="domain-input-block">
                          <Field
                            component={Input}
                            name="campaignRestrictMinute"
                            type="number"
                            placeholder="MM"
                            validate={checkRestrictionMinValue}
                            min={1}
                            max={59}
                            inputClass="domain-edit-input modal-duration "
                            disabled={!this.props.campaignRestrictionFlag}
                          />
                        </div>

                        <InputGroupAddon
                          className="mr-2 add-domain-input-group-prepend "
                          addonType="prepend"
                        >
                          SS
                        </InputGroupAddon>
                        <div className="domain-input-block">
                          <Field
                            component={Input}
                            name="campaignRestrictSecond"
                            type="number"
                            placeholder="SS"
                            validate={[
                              checkSecValue,
                              validateRestrictionCampaignDuration
                            ]}
                            min={0}
                            max={59}
                            inputClass="domain-edit-input modal-duration"
                            disabled={!this.props.campaignRestrictionFlag}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                )}

                {this.props.action === "create" && (
                  <div>
                    <FormGroup>
                      <div className="globalInput">
                        <Label>Users</Label>
                        <Field
                          component={MultipleSelect}
                          name="users"
                          options={userList.map(item => ({
                            value: item.id,
                            label: item.attributes.name
                          }))}
                          onChange={this.userChange}
                          isMulti
                        />
                      </div>
                    </FormGroup>{" "}
                    <small style={{ fontSize: "12px", color: "#707070" }}>
                      {" "}
                      * All the selected users will be the default admin of the
                      domain{" "}
                    </small>
                  </div>
                )}
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-outline-primary"
              onClick={() => this.props.handleDomainCreate(false)}
            >
              Cancel
            </Button>
            <Button
              className="primaryButton mt-0"
              onClick={handleSubmit(values => this.handleSubmit(values))}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const AddDomainComponent = reduxForm({
  form: "AddDomainForm"
})(AddDomain);

function mapStateToProps(state, ownProps) {
  // const {
  //   match: {
  //     path,
  //     params: { id }
  //   }
  // } = ownProps;

  // const isEditing = path === "/dashboard/domain/edit/:id";
  const { list } = state.gurzuUserList;
  const currentDomain = state.domainDetails.list;
  const selector = formValueSelector("AddDomainForm");

  return {
    userList: list || [],
    // isEditing,

    currentDomain: currentDomain || {},
    subscription: selector(state, "subscription"),
    campaignRestrictionFlag: selector(state, "campaignRestrictionFlag")
  };
}
export default connect(
  mapStateToProps,
  { reloadHeaderDispatcher }
)(AddDomainComponent);
