import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { columns } from "../utils/domainDeviceUtils";
import DomainDeviceHeader from "./domainDeviceTableHeader";

export default class DomainDeviceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        name: false,
        mac_address: false,
        orientation: false,
        resolution: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  render() {
    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.devices}
          columns={columns(
            this.props.currentDomainName,
            this.props.currentUserRoles,
            this.props.removeExistingDevicesFromDomain,
            this.state.hiddenColumns,
            this.props.removeExistingDevicesFromSystem
          )}
          columnToggle
        >
          {props => (
            <div>
              <DomainDeviceHeader
                currentDomainNameDDT={this.props.currentDomainNameDDT}
                currentUserRolesDDT={this.props.currentUserRolesDDT}
                editDDT={this.props.editDDT}
                toggleModalDDT={this.props.toggleModalDDT}
                handleSearchValueDDT={this.props.handleSearchValueDDT}
                searchValDDT={this.props.searchValDDT}
                {...this.props}
                // the following props are used for visible column toggler component
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
              />
              <BootstrapTable
                classes="contentSectionTable"
                hover
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                keyField="id"
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
