import React from "react";
import { Link } from "react-router-dom";
import { TextClipper } from "../../../helper/TextFormatter";
import { PopOver, Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import { unitConvert } from "../../../helper/unitConvert";
import { getLocalTime } from "../../../helper/helperFuncs";

export function columns(
  roles,
  permission,
  handleDelete,
  handleUnArchiveItem,
  handleSort,
  sortOrder,
  hiddenColumns
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      hidden: hiddenColumns.name,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      style: { width: "150px" },
      formatter: (name, row) => (
        <div className="thumb">
          <div className="content-thumb-image">
            <img
              src={
                row.attributes.s3_thumbnail_url ||
                require("../../../assets/images/empty-img.png")
              }
              alt={name || undefined}
              className="content__thumb"
            />
          </div>
          <span className="content__titlename">
            {name.length >= 25 ? (
              <Tooltip
                item={
                  <span className="content__name">
                    {" "}
                    {`${name.slice(0, 25)}...`}{" "}
                  </span>
                }
                id={`viewname-${row.id}`}
                tooltipContent={name}
              />
            ) : (
              <span className="content__name">{name}</span>
            )}
          </span>{" "}
          <RouteAllowed
            roles={roles && roles}
            permission={permission && permission.content.show}
          >
            <Link
              className="linkTitle"
              to={{
                pathname: `/content/show/${row.id}`,
                params: { ...row, handleDelete, roles, permission }
              }}
            />
          </RouteAllowed>
          <span className="contentType"> {row.attributes.content_type}</span>
        </div>
      )
    },
    {
      dataField: "attributes.size",
      text: "Size",
      columnName: "size",
      hidden: hiddenColumns.size,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("size")}
            className="sort-by "
            style={{
              color: sortOrder.size.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Size
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.size.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{unitConvert(row)}</div>
    },
    {
      dataField: "attributes.dimension",
      text: "Dimension",
      columnName: "dimension",
      hidden: hiddenColumns.dimension,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("dimension")}
            className="sort-by "
            style={{
              color: sortOrder.dimension.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Dimension
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.dimension.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.duration",
      text: "Duration",
      columnName: "duration",
      hidden: hiddenColumns.duration,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("duration")}
            className="sort-by "
            style={{
              color: sortOrder.duration.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Duration
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.duration.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.tags",
      text: "Tags",
      columnName: "tags",
      hidden: hiddenColumns.tags,
      formatter: (name, row) => (
        <div>
          <div className="contentTag">
            <ul>
              <li>
                {row.attributes.tags &&
                  row.attributes.tags.slice(0, 2).map((i, index) => (
                    <span key={`tags-${row.id}`} className="contentTag__badge">
                      {TextClipper(
                        row.id,
                        i,
                        8,
                        index,
                        "tags",
                        "contentTag__wrapper"
                      )}
                    </span>
                  ))}
                <span className="tagmenu-icon">
                  {row.attributes.tags && row.attributes.tags.length > 2 && (
                    <PopOver id={`tags-${row.id}`}>
                      {row.attributes.tags.map(i => (
                        <span
                          key={`tags-${row.id}`}
                          className="contentTag__badge"
                        >
                          {i}
                        </span>
                      ))}
                    </PopOver>
                  )}
                </span>
              </li>
            </ul>
          </div>
          <div className="contentTag">
            <ul>
              <li>
                {row.attributes.sponsor_tags &&
                  row.attributes.sponsor_tags.slice(0, 2).map((i, index) => (
                    <span
                      key={`sponserTags-${row.id}`}
                      className="contentTag__badge contentTag__badge--mod"
                    >
                      {TextClipper(
                        row.id,
                        i,
                        8,
                        index,
                        "sponsers",
                        "contentTag__wrapper"
                      )}
                    </span>
                  ))}
                <span className="tagmenu-icon">
                  {row.attributes.sponsor_tags &&
                    row.attributes.sponsor_tags.length > 2 && (
                      <PopOver id={`sponserTags-${row.id}`}>
                        {row.attributes.sponsor_tags.map(i => (
                          <span
                            key={`sponserTags-${row.id}`}
                            className="contentTag__badge contentTag__badge--mod"
                          >
                            {i}
                          </span>
                        ))}
                      </PopOver>
                    )}
                </span>
              </li>
            </ul>
          </div>
        </div>
      )
    },
    {
      dataField: "attributes.created_at",
      text: "Date Created",
      columnName: "date_created",
      hidden: hiddenColumns.date_created,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.created_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{getLocalTime(row)}</div>
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      columnName: "date_updated",
      hidden: hiddenColumns.date_updated,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{getLocalTime(row)}</div>
    },
    // {
    //   dataField: "attributes.status",
    //   text: " Status",
    //   headerFormatter: () => (
    //     <div style={{ display: "flex", flexDirection: "column" }}>
    //       <span
    //         role="presentation"
    //         onClick={() => handleSort("status")}
    //         className="sort-by "
    //         style={{
    //           color: sortOrder.status.active ? "#e44b32" : "#2B2B2B"
    //         }}
    //       >
    //         Status
    //         <i
    //           className={`fa fa-sort-alpha-${sortOrder.status.sortBy === "desc" ? "up" : "down"}`}
    //         />
    //       </span>
    //     </div>
    //   ),
    //   formatter: (name, row) => (
    //     <span
    //       className={`content-text ${
    //         row.attributes.status === "offline"
    //           ? "text-offline statusoffline"
    //           : "text-info statuslive"
    //       }`}
    //     >
    //       {row.attributes.status}
    //     </span>
    //   )
    // },
    {
      dataField: "actionButtons",
      text: "",
      style: { position: "relative" },
      columnName: "isSelected",
      formatter: (name, row) => (
        <div className="d-flex">
          <div className="contentSectionTable__menu">
            <i className="fas fa-ellipsis-h" />
          </div>
          <div className="contentSectionTable__actionButtonWrapper">
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.archive.content}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() => handleUnArchiveItem(row.id, name)}
                      className="fas fa-history "
                    />
                  }
                  id={`archive-${row.id}`}
                  tooltipContent="Restore"
                />
              </span>
            </RouteAllowed>{" "}
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.content.delete}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      className="fa fa-trash action__trash"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleDelete(row.id, row.attributes.name);
                      }}
                    />
                  }
                  tooltipContent="Delete"
                  id="delete-archive-content"
                />
              </span>
            </RouteAllowed>
          </div>
        </div>
      )
    }
  ];
}
