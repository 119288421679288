import React, { Component } from "react";
import CreatableSelect from "react-select/lib/Creatable";

export class TagSelect extends Component {
  static defaultProps = {
    input: { value: undefined }
  };

  handleChange = (newValue: any, actionMeta: any) => {
    if (this.props.clearError) {
      this.props.clearError();
    }
    this.props.input.onChange(newValue);
  };

  render() {
    const { input, meta, ...rest } = this.props;
    return (
      <React.Fragment>
        <CreatableSelect
          isMulti
          value={input.value || undefined}
          onChange={this.handleChange}
          onInputChange={this.props.handleInputChange}
          options={this.props.options ? this.props.options : []}
          placeholder={
            this.props.placeholder ? this.props.placeholder : "Add Tags"
          }
          isDisabled={this.props.disabled}
        />
        {this.props.tagHasError && (
          <p style={{ color: "red" }}>{this.props.tagHasError}</p>
        )}
      </React.Fragment>
    );
  }
}
