/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Tooltip } from "../../../core";

export const CollapseCalendarList = props => (
  <div
    role="presentation"
    onClick={() => {
      props.toggleCalendarList(!props.isCalendarListOpen);
    }}
  >
    <Tooltip
      className="collapse-tooltip"
      item={
        <img
          style={{ height: "24px", color: "#A5A4BF" }}
          src={require("../../../assets/images/menu.svg")}
          alt="IMG"
        />
      }
      id="collapse"
      tooltipContent={
        props.isCalendarListOpen ? "Hide Calendar List" : "Show Calendar List"
      }
    />
  </div>
);
