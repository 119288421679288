import React, { Component, Fragment } from "react";
import { Table } from "reactstrap";
import { change } from "redux-form";
import { connect } from "react-redux";
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { Tooltip } from "../../../core";

import AddPlaylistSingleItem from "./addPlaylistSingleItem";

const DragHandle = sortableHandle(() => (
  <Tooltip
    item={<i className="fas fa-bars dragHandle" />}
    tooltipContent="Sort Playlist"
    id="sort"
  />
));

const SortableItem = SortableElement(props => (
  <li style={{ position: "relative", width: "100%", listStyle: "none" }}>
    <DragHandle />
    <AddPlaylistSingleItem
      setIsSubmtable={props.setIsSubmtable}
      key={props.item.uniqueKey}
      data={props.item}
      playListData={props.playListData}
      change={props.change}
      playlistControl={props.playlistControl}
      sortableItemStyle={props.sortableItemStyle}
      customDatePickerInput={props.customDatePickerInput}
      customTimePickerInput={props.customTimePickerInput}
    />
  </li>
));

const SortableList = SortableContainer(
  props =>
    props.playListData.length !== 0 && (
      <ul style={{ border: "1px solid red;" }}>
        {props.playListData.map((item, index) => (
          <SortableItem
            setIsSubmtable={props.setIsSubmtable}
            key={item.uniqueKey}
            index={index}
            item={item}
            playListData={props.playListData}
            change={props.change}
            playlistControl={props.playlistControl}
            sortableItemStyle={props.sortableItemStyle}
            customDatePickerInput={props.customDatePickerInput}
            customTimePickerInput={props.customTimePickerInput}
          />
        ))}
      </ul>
    )
);

class PlaylistStudioContentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let { playListData } = this.props;
    playListData = arrayMove(playListData, oldIndex, newIndex);
    this.props.change("addPlaylistForm", "playListData", playListData);
  };

  render() {
    const { playlistControl } = this.props;
    return (
      <Table table responsive style={{ background: "none" }}>
        <div className="playlist-table ">
          <div className="playlist-table__list">
            <ul className="">
              <li>
                <ul className="playlist-table__theader">
                  <li />
                  <li>Playlist Name</li>
                  <li style={{ width: "120px" }}>Duration</li>

                  {playlistControl.date && (
                    <Fragment>
                      <li style={{ width: "332px" }}>Start Date - End Date</li>
                    </Fragment>
                  )}

                  {playlistControl.restriction && (
                    <li style={{ width: "200px" }}>Start Time - End Time</li>
                  )}
                  {playlistControl.weight && (
                    <li style={{ width: "137px" }}>Share of Voice </li>
                  )}

                  <li style={{ width: "30px" }} />
                </ul>
              </li>
            </ul>

            <SortableList
              axis="y"
              useDragHandle
              onSortEnd={this.onSortEnd}
              setIsSubmtable={this.props.setIsSubmtable}
              playListData={this.props.playListData}
              change={this.props.change}
              playlistControl={this.props.playlistControl}
              sortableItemStyle={this.props.sortableItemStyle}
              customDatePickerInput={this.props.customDatePickerInput}
              customTimePickerInput={this.props.customTimePickerInput}
            />
          </div>
        </div>
        {this.props.displayAddMediaIcon && (
          <div
            role="presentation"
            className="addButton"
            onClick={() => this.props.displayMediaModal(true)}
          >
            <i role="presentation" className=" fa fa-plus" />
          </div>
        )}
      </Table>
    );
  }
}

export default connect(
  null,
  { change }
)(PlaylistStudioContentTable);
