import React, { Component } from "react";
import { Button } from "reactstrap";
import { Tooltip } from "../../../../core";
import DesktopBreakpoint from "../../../../context/desktop_breakpoint";
import SelectLayoutModal from "../selectLayoutModal";

export default class StudioActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = { isLayoutModalOpen: false };

    this.mediaMatch = window.matchMedia(
      "(max-width: 1650px) and (min-width: 1350px)"
    );
  }
  handleZoom = flag => {
    const { campaign, updateCampaign } = this.props;
    campaign.zoomCampaignEditor(flag, this.mediaMatch.matches);
    updateCampaign(campaign);
  };

  toggleSelectLayoutModal = () => {
    const isContentEmpty = this.props.isContentEmpty.includes(true);
    if (!isContentEmpty) {
      this.setState(prevState => ({
        isLayoutModalOpen: !prevState.isLayoutModalOpen
      }));
    }
  };

  changeScreenLayout = () => {
    const { campaign, updateCampaign, layout, resolutionList } = this.props;
    campaign.screens = [];
    campaign.setSelectedScreen(undefined);
    campaign.handleScreenLayout(layout, resolutionList, this.mediaMatch);
    campaign.generateScreens();
    updateCampaign(campaign);
    this.toggleSelectLayoutModal();
  };

  render() {
    const { campaign } = this.props;
    const { showResOptions } = this.props;

    return (
      <div
        className=" d-flex justify-content-between campaignStudioWrapper__stage-actionButton"
        style={{ width: "100%", position: "relative" }}
      >
        <div className="d-flex">
          {" "}
          <DesktopBreakpoint>
            {!campaign.isZoomEnable ? (
              <Tooltip
                item={
                  <i
                    role="presentation"
                    className="fas fa-expand campaignStudioWrapper__rightSection__playIcon"
                    onClick={() => this.handleZoom(true)}
                  />
                }
                id="zoomIn"
                tooltipContent="Zoom Mode"
              />
            ) : (
              <Tooltip
                item={
                  <i
                    role="presentation"
                    className="fas fa-compress campaignStudioWrapper__rightSection__playIcon"
                    onClick={() => this.handleZoom(false)}
                  />
                }
                id="zoomIn"
                tooltipContent="Exit Zoom Mode"
              />
            )}
          </DesktopBreakpoint>
          <div className="select_box">
            <Tooltip
              item={
                <i
                  className={`bx bx-layout layoutButton ${this.props.isContentEmpty.includes(
                    true
                  ) && "layoutButton--disabled"}`}
                  onClick={() => this.toggleSelectLayoutModal()}
                  disabled
                />
              }
              id="selectlay"
              tooltipContent="Select Layout"
            />{" "}
          </div>
        </div>

        <div className="campaignStudioWrapper__rightSection__middleIcons">
          {this.props.showPreview ? (
            <Tooltip
              item={
                <i
                  role="presentation"
                  className="fa fa-stop campaignStudioWrapper__rightSection__stopIcon"
                  onClick={() => this.props.togglePreview(false)}
                />
              }
              id="stop"
              tooltipContent="Stop"
            />
          ) : (
            <Tooltip
              item={
                <i
                  role="presentation"
                  className="fa fa-play campaignStudioWrapper__rightSection__playIcon"
                  onClick={() => this.props.togglePreview(true)}
                />
              }
              id="play"
              tooltipContent="Play"
            />
          )}
        </div>

        <div className="d-flex float-right select_box">
          <span className="mr-1">Resolution:</span>
          <Tooltip
            item={
              <Button
                role="button"
                className="select_box__button select_box__button--mod"
                disabled={
                  campaign.screens.length > 1 ||
                  this.props.isContentEmpty.includes(true)
                }
                onClick={() => this.props.handleResolutionList()}
              >
                {campaign.displayResolution}
              </Button>
            }
            id="selectRes"
            tooltipContent="Select Resolution"
            customClass="customTooltipClass"
          />{" "}
        </div>
        {showResOptions && (
          <div className="customSelectOptions customSelectOptions--campaignResSelect">
            <ul>
              {campaign.resolutionList.map(item => (
                <li
                  className={
                    campaign.displayResolution === item.attributes.display &&
                    "activeResolution"
                  }
                  onClick={() =>
                    this.props.handleResolutionChange(
                      item.attributes.display,
                      item.attributes.height,
                      item.attributes.width
                    )
                  }
                >
                  {item.attributes.display}
                </li>
              ))}

              {/* <li
                className={
                  campaign.orientation === "Portrait" && "activeResolution"
                }
                onClick={() => this.props.handleResolutionChange("Portrait")}
              >
                1080 X 1920
              </li> */}
            </ul>
          </div>
        )}
        <SelectLayoutModal
          isLayoutModalOpen={this.state.isLayoutModalOpen}
          toggleSelectLayoutModal={this.toggleSelectLayoutModal}
          customCreate={
            <button
              className="primaryButton mt-0"
              onClick={() => this.changeScreenLayout()}
            >
              Create
            </button>
          }
          layout={this.props.layout}
        />
      </div>
    );
  }
}
