import React from "react";
import { Link } from "react-router-dom";
import { upperFirst } from "lodash";
import { Button } from "reactstrap";
import { Tooltip } from "../../../core";
import { getHourMinutesAndSeconds } from "../../../helper";
import moment from "moment";

const convertToMins = (cell, row) => {
  const screenShotDuration = getHourMinutesAndSeconds(
    row.attributes.screenshot_time
  );
  return (
    <div>{`${screenShotDuration.hours}h ${screenShotDuration.minutes}m ${screenShotDuration.seconds}s`}</div>
  );
};

const getFormattedDaysToExpire = (expiryDate) => {
  const daysToExpire = getDaysToExpire(expiryDate);
  if (daysToExpire < 1) {
    return (
      <div style={{ color: '#8E8E8E' }}>
        Subscription expired
      </div>
    )
  }
  return (
    <div style={{ color: '#2B2B2B' }}>
      Expires in {daysToExpire} {daysToExpire > 1 ? 'days' : 'day'}
    </div>
  )
}

const getDaysToExpire = (expiryDate) => {
  return moment(expiryDate).startOf('day').diff(moment().startOf('day'), 'days');
}

const getSubscriptionExpiryHoverInfo = (row) => {
  return (
    <div>
      <p>  {`${getDaysToExpire(row.subscription_start_date) ? `Started On ${row.subscription_start_date}` : ''}`} </p>
      <p>  {`${getDaysToExpire(row.subscription_end_date) < 1 ? 'Ended On' : 'Ends On'} ${row.subscription_end_date}`} </p>
    </div>
  );
}


export function columns(
  update,
  deleteDomain,
  handleDomain,
  setDefaultDomain,
  currentUser,
  defaultDomain,
  currentDomainID,
  handleSort,
  sortOrder,
  userRoles,
  handleDomainAction,
  hiddenColumns
) {
  const columnsList = [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      hidden: hiddenColumns.name,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${sortOrder.name.sortBy === "desc" ? "up" : "down"
                }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.screenshot_time",
      text: "Screenshot Time",
      columnName: "screenshot_time",
      hidden: hiddenColumns.screenshot_time,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("screenshot_time")}
            className="sort-by "
            style={{
              color: sortOrder.screenshot_time.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Screenshot Time
            <i
              className={`fa fa-sort-alpha-${sortOrder.screenshot_time.sortBy === "desc" ? "up" : "down"
                }`}
            />
          </span>
        </div>
      ),

      formatter: convertToMins
    },
    {
      dataField: "attributes.subscription",
      text: "Subscription",
      columnName: "subscription",
      hidden: hiddenColumns.subscription,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("subscription")}
            className="sort-by "
            style={{
              color: sortOrder.subscription.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            Subscription
            <i
              className={`fa fa-sort-alpha-${sortOrder.subscription.sortBy === "desc" ? "up" : "down"
                }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <span>
          {name ? (
            <span
              className={`subscription ${name === "basic"
                ? "subscription--basicPlan"
                : "subscription--premiumPlan"
                }`}
            >
              {upperFirst(name)}
            </span>
          ) : (
            "N/A"
          )}
          <Tooltip
            item={
              row.attributes.subscription_end_date
                ? getFormattedDaysToExpire(row.attributes.subscription_end_date)
                : ""
            }
            id={`expiry-hover-${row.id}`}
            tooltipContent={getSubscriptionExpiryHoverInfo(row.attributes)}
          />
        </span>
      )
    },

    {
      dataField: "attributes.created_at",
      text: "Date Created",
      columnName: "date_created",
      hidden: hiddenColumns.date_created,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${sortOrder.created_at.sortBy === "desc" ? "up" : "down"
                }`}
            />{" "}
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      columnName: "date_updated",
      hidden: hiddenColumns.date_updated,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
                }`}
            />{" "}
          </span>
        </div>
      )
    },
    {
      dataField: "buttons",
      text: "",
      columnName: "isSelected",
      headerStyle: {
        width: "18%"
      },
      formatter: (name, row) => (
        <div className="switchButtons">
          <Button
            className="btn-outline-primary"
            type="button"
            onClick={() => handleDomain(row.id)}
            disabled={currentDomainID === row.id}
          >
            Switch
          </Button>
          <Button
            className="btn-outline-primary defaultButton"
            type="button"
            onClick={() => {
              setDefaultDomain(row.id);
            }}
            disabled={defaultDomain === row.id}
          >
            Set as default
          </Button>
        </div>
      )
    },
    {
      dataField: "actionButtons",
      text: "",
      style: { position: "relative" },
      columnName: "actionButtons",
      formatter: (name, row) => (
        <div className="d-flex">
          {(userRoles.includes("Admin") ||
            userRoles.includes("SuperAdmin") ||
            userRoles.includes("ReportManager")) && (

              <div className="contentSectionTable__actionButtonWrapper">
                <Link
                  className="contentSectionTable__actionButtonWrapper__buttons"
                  to={{
                    pathname: `/domain/setting/${row.id}`,
                    state: { name: row.attributes.name }
                  }}
                >
                  <Tooltip
                    item={<i className="fa fa-edit " />}
                    id={`edit-${row.id}`}
                    tooltipContent="Edit"
                  />
                </Link>

                {currentDomainID !== row.id &&
                  (row.attributes.name !== "gurzu" &&
                    row.attributes.name !== "Gurzu") && (
                    <span className="contentSectionTable__actionButtonWrapper__buttons">
                      <Tooltip
                        item={
                          <i
                            role="presentation"
                            onClick={() => deleteDomain(row.id)}
                            className="fa fa-trash "
                          />
                        }
                        id={`delete-${row.id}`}
                        tooltipContent="Delete"
                      />
                    </span>
                  )}
              </div>
            )}
        </div>
      )
    }
  ];

  let isPartyBrandFeatureEnabled = JSON.parse(window.localStorage.getItem("domain"))
    .is_party_brand_feature_enabled;

  if (userRoles.includes("SuperAdmin") ||
    userRoles.includes("Admin")) {
    return columnsList;
  }
  else if (userRoles.includes("ReportManager")) {
    return columnsList.filter(column => {
      if (column.columnName === "actionButtons") {
        return isPartyBrandFeatureEnabled;
      }
      return true;
    })
  } else {
    return columnsList.filter(column => {
      if (column.columnName === "actionButtons") {
        return false;
      }
      return true;
    })
  }

}
