import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import { formValueSelector } from "redux-form";

import { connect } from "react-redux";
import SendToDeviceTabs from "./sendToDeviceTabs";

const selectorForMedia = formValueSelector("SendMediaToDevice");
// eslint-disable-next-line react/prefer-stateless-function
class SendToDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      fetch: true
    };
  }

  toggleFetch = flag => {
    this.setState({
      fetch: flag
    });
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    return (
      <div>
        {this.props.children}
        <Modal
          isOpen={
            this.props.selectedGroup
              ? this.props.modal === this.props.selectedGroup
              : this.props.modal
          }
          className={this.props.className || "sendMediaModal"}
        >
          <ModalHeader toggle={this.props.toggle}>
            {" "}
            Send Media To Devices
          </ModalHeader>
          <br />
          <SendToDeviceTabs
            {...this.props}
            activeTab={this.state.activeTab}
            toggleTab={this.toggle}
            refetchCampaign={this.props.refetchCampaign}
            toggleFetch={this.toggleFetch}
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selectedCampaign = selectorForMedia(state, "selectedCampaign");
  const selectedCalendar = selectorForMedia(state, "selectedCalendar");

  return {
    selectedCampaign: selectedCampaign || [],
    selectedCalendar: selectedCalendar || []
  };
}

export default connect(
  mapStateToProps,
  null
)(SendToDevice);
