/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { findIndex, remove } from "lodash";
import { Row, Col, Card } from "reactstrap";
import moment from "moment"

import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { Tooltip } from "../../../../core";
import { updateCampaign } from "../../redux";
import { Resources } from "../../../contents/redux/contentRedux";

function UnuseableContent(props) {
  const { item, message } = props;
  return (
    <Col key={item.id} md="2" sm="4" xs="6" className="contentWrapper">
      <div style={{ cursor: "not-allowed" }} className="widgetWrapper--mod">
        <div style={{ height: "95px", width: "95px", position: "relative" }}>
        {item.attributes.is_filler_content &&
          item.attributes.is_filler_content === true ? (
<           div className="imageWrap">
            <img
              src={item.attributes.s3_thumbnail_medium_url}
              alt="img"
              className="img-fluid--contentImage"
            />
            <div
                    style={{ position: "relative", top: "70px", left: "95px" }}
                  >
                    <i
                      class="bx bxs-certification"
                      style={{ color: "#e44b32" }}
                    ></i>
                  </div>
          </div>
              ):(
            <div className="imageWrap">
              <img
                src={item.attributes.s3_thumbnail_medium_url}
                alt="img"
                className="img-fluid--contentImage"
              />
            </div>)}
          
          <div
            className="lockedOverlay"
            style={{ width: "120px", height: "100%" }}
          >
            <small
              className="lockedMessage"
              style={{ fontSize: 12, paddingLeft: "16px", color: "white" }}
            >
              {message}
            </small>
          </div>
        </div>
        {item.attributes.name.length >= 20 ? (
          <Tooltip
            item={
              <p className=""> {`${item.attributes.name.slice(0, 20)}...`} </p>
            }
            id={item.id}
            tooltipContent={item.attributes.name}
          />
        ) : (
          <p className="">{item.attributes.name}</p>
        )}
      </div>
    </Col>
  );
}

function UseableContent(props) {
  const { item, handleClickedItem, handleDragStart, selected } = props;
  return (
    <Col
      key={item.id}
      onClick={e => handleClickedItem(e, item)}
      onDragStart={e => handleDragStart(e, item)}
      // onDragLeave={e => handleDragLeave(e)}
      // lg="2"
      // md="2"
      // sm="4"
      md="2"
      sm="4"
      xs="6"
      className="contentWrapper"
    >
      <div>
        <div draggable>
          <div className=" imgcheck">
            <input type="checkbox" id="check" name="1" value="" />
            <label htmlFor="check">
              {item.attributes.is_filler_content &&
                item.attributes.is_filler_content === true ? (
                <div className="imageWrap">
                  <img
                    src={item.attributes.s3_thumbnail_medium_url}
                    alt="img"
                    className="img-fluid--contentImage"
                  />
                  <div
                    style={{ position: "relative", top: "70px", left: "75px" }}
                  >
                    <i
                      class="bx bxs-certification"
                      style={{ color: "#e44b32" }}
                    ></i>
                  </div>
                </div>
              ) : (
                <div className="imageWrap">
                  <img
                    src={item.attributes.s3_thumbnail_medium_url}
                    alt="img"
                    className="img-fluid--contentImage"
                  />
                </div>
              )}

              {findIndex(selected, { id: item.id }) > -1 && (
                <i
                  className="fas fa-check-circle mt-2 ml-2"
                  style={{ color: "#e44b32", fontSize: "20px" }}
                />
              )}
            </label>
          </div>
          {item.attributes.name.length >= 20 ? (
            <Tooltip
              item={
                <p className="">
                  {" "}
                  {`${item.attributes.name.slice(0, 20)}...`}{" "}
                </p>
              }
              id={item.id}
              tooltipContent={item.attributes.name}
            />
          ) : (
            <p className="">{item.attributes.name}</p>
          )}
        </div>
      </div>
    </Col>
  );
}

function MediaMapper(
  items,
  handleClickedItem,
  handleDragStart,
  handleDragLeave,
  selected,
  contentLoading,
  isLoading,
  rateAmountRestriction
) {
  return items.map(item => {
    if (
      (!item.attributes.is_negotiable &&
        !item.attributes.is_filler_content &&
        rateAmountRestriction &&
        !(item.attributes.rate_per_minute || item.attributes.is_negotiable))
        || (rateAmountRestriction && item.attributes.end_date && moment().isAfter(moment(new Date(item.attributes.end_date))))
    ) {
      return <UnuseableContent item={item} message="Please contact admin or report manager.
      "/>;
    }
    return (
      <UseableContent
        item={item}
        handleClickedItem={handleClickedItem}
        handleDragStart={handleDragStart}
        selected={selected}
      />
    );
  });
}

class MediaTab extends React.Component {
  /**
   * @param item element array data being click
   */
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isLoading: true,
      hasMore: true,
      url: "/contents?page="
    };
    if (this.props.campaignLoading) {
      this.loadContens();
    }
  }

  loadContens = () => {
    this.props.toggleFetch(true);
    const { getContent } = Resources;
    getContent.url = "/contents?page=1&per_page=18";
    this.props.Get(Resources.getContent).then(response => {
      this.props.setLoading(false);
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.props.toggleFetch(false);
      }
    });
  };

  handleClickedItem = (e, item) => {
    e.preventDefault();
    const { campaign } = this.props;

    if (findIndex(campaign.contentToBeDropped, { id: item.id }) === -1) {
      campaign.loadContentToBeDropped(item);
      this.props.updateCampaign(campaign);
      return 0;
    }
    remove(campaign.contentToBeDropped, { id: item.id });

    this.props.updateCampaign(campaign);
  };

  handleDragStart = (e, item) => {
    // This code is required for firefox to work.
    // e.dataTransfer.setData("text", "foo");
    const { campaign } = this.props;
    campaign.loadContentToBeDropped(item);
    this.props.updateCampaign(campaign);
  };

  handleDragLeave = e => {
    e.preventDefault();
    const { campaign } = this.props;
    campaign.contentToBeDropped = [];
    this.props.updateCampaign(campaign);
  };

  fetchMoreData = async () => {
    if (this.state.hasMore) {
      this.props.increasePerPage();
      this.setState(() => {
        if (this.props.contentSearchByName) {
          Resources.getContent.url = `/contents/search?search=${this.props.contentSearchByName}&page=1&per_page=${this.props.perPage}`;
          this.props.Get(Resources.getContent).then(response => {
            if (response.data.meta.totalPages <= response.data.meta.page) {
              this.props.toggleFetch(false);
            }
          });
          return true;
        }

        if (this.props.contentSearchByTag) {
          Resources.getContent.url =
            this.props.selectedOptionType === "Sponsors"
              ? `/contents/search_sponsor_tag?sponsor_tags=${this.props.contentSearchByTag}&page=1&per_page=${this.props.perPage}`
              : this.props.selectedOptionType === "Tags"
                ? `/contents/search_tag?tags=${this.props.contentSearchByTag}&page=1&per_page=${this.props.perPage}`
                : this.props.selectedOptionType === "Brand"
                  ? `/contents/search_by_brand?brand_tags=${this.props.contentSearchByTag}&page=1&per_page=${this.props.perPage}`
                  : `/contents/search_by_party?party_tags=${this.props.contentSearchByTag}&page=1&per_page=${this.props.perPage}`;
          this.props.Get(Resources.getContent).then(response => {
            if (response.data.meta.totalPages <= response.data.meta.page) {
              // this.setState({ hasMore: false });
              this.props.toggleFetch(false);
            }
          });
          return true;
        }

        if (this.props.contentFilter) {
          this.props.contentFilter === "true" ? (Resources.getContent.url = `/contents/?is_filler_content=${this.props.contentFilter}&page=1&per_page=${this.props.perPage}`) : (Resources.getContent.url = `/contents/?type=${this.props.contentFilter}&page=1&per_page=${this.props.perPage}`)
          this.props.Get(Resources.getContent).then(response => {
            if (response.data.meta.totalPages <= response.data.meta.page) {
              this.props.toggleFetch(false);
            }
          });
          return true;
        }
        if (this.props.contentSort) {
          Resources.getContent.url = `/contents?sort=${this.props.contentSort}&sort_by=${this.props.sortOrder}&page=1&per_page=${this.props.perPage}`;
          this.props.Get(Resources.getContent).then(response => {
            if (response.data.meta.totalPages <= response.data.meta.page) {
              this.props.toggleFetch(false);
            }
          });
          return true;
        }

        Resources.getContent.url = `${this.state.url}1&per_page=${this.props.perPage}`;
        this.props.Get(Resources.getContent).then(response => {
          if (response.data.meta.totalPages <= response.data.meta.page) {
            this.props.toggleFetch(false);
          }
        });
      });
    }
    return true;
  };

  render() {
    const { list, contentLoading, campaign, isLoading } = this.props;
    if (contentLoading || isLoading) {
      return (
        <svg
          className="spinner"
          viewBox="0 0 50 50"
          style={{ marginTop: "35px" }}
        >
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
          />
        </svg>
      );
    }
    return (
      // <div className="tab-content__wrapper campaigncontent__box">
      <InfiniteScroll
        dataLength={list.length}
        next={this.fetchMoreData}
        hasMore={this.props.fetch}
        loader={
          !(contentLoading || isLoading) &&
          list.length > 12 && (
            <div className="d-block text-center">
              <span>...Loading</span>
            </div>
          )
        }
        className="content-infinity"
        height={this.props.infiniteScrollHeight}
        // endMessage={
        //   !(contentLoading || isLoading) &&
        //   (list.length > 0 && (
        //     <p style={{ textAlign: "center" }}>
        //       <b>This is the end of Content Section.</b>
        //     </p>
        //   ))
        // }
        style={{ overFlow: "visible" }}
      >
        {list.length > 0 ? (
          <Row>
            {MediaMapper(
              list,
              this.handleClickedItem,
              this.handleDragStart,
              this.handleDragLeave,
              campaign.contentToBeDropped,
              contentLoading,
              isLoading,
              this.props.rateAmountRestriction
            )}
          </Row>
        ) : (
          <p
            className="text-center"
            style={{ lineHeight: "25vh", fontSize: "20px" }}
          >
            Content Not Found
          </p>
        )}
      </InfiniteScroll>
      // </div>
    );
  }
}

MediaTab.defaultProps = {
  mediaList: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }]
};

export default connect(
  state => {
    const { list, loading: contentLoading } = state.content;
    const { campaign } = state.newCampaign;
    return {
      contentLoading,
      list: list || [],
      campaign,
      rateAmountRestriction: JSON.parse(
        window.localStorage.getItem("domain") !== "undefined"
          ? window.localStorage.getItem("domain")
          : null
      ).is_content_rate_enabled
    };
  },
  { updateCampaign }
)(MediaTab);
