import React, { Component } from "react";
import { Card, Label, Button } from "reactstrap";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm, initialize } from "redux-form";
import { Input } from "../../../core";
import { TagSelect } from "../../../core/form/tagSelect";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { timeZones } from "../../../constant/timeZoneNUCs";
import { resolutions } from "../../../constant/resolution";
import { orientations } from "../../../constant/orientation";
import { showNotification } from "../../../core/modal/toster";
import { convertOrientation } from "../utls/helper";
import { integer } from "../../../helper/validation";
import moment from "moment";

class DeviceDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false
    };
    this.loadFormData();
  }

  loadFormData = () => {
    const {
      attributes: {
        name,
        mac_address,
        hostname,
        total,
        asset_id,
        loc,
        volume,
        tags,
        timezone,
        resolution,
        orientation
      }
    } = this.props;
    this.props.initialize({
      name,
      hostname,
      assetId: asset_id,
      longitude: loc ? loc[0] : undefined,
      latitude: loc ? loc[1] : undefined,
      tags: tags.map(item => ({ value: item, label: item })),
      timeZone: timeZones.filter(item => item.value === timezone),
      resolution: resolutions.filter(item => item.resolution === resolution),
      orientation: orientations.filter(
        item => item.orientation === orientation
      ),
      volume
    });
  };

  handleEdit = flag => {
    this.setState({ edit: flag });
  };

  checkLongLat = ({ longitude, latitude }) => {
    if ((longitude && latitude) || (!longitude && !latitude)) {
      return true;
    }
    return false;
  };

  handleDeviceUpdate = async values => {
    if (this.checkLongLat(values)) {
      await this.props
        .handleDeviceUpdate(values)
        .then(() => this.handleEdit(false));
    } else if (values.longitude) {
      this.props.showNotification("Latitude is not present", "danger");
    } else {
      this.props.showNotification("Longitude is not present", "danger");
    }
  };

  handleCancelChanges = () => {
    this.loadFormData();
    this.handleEdit(false);
  };

  getElectronVersion = (electronVersion, version) => {
    if (electronVersion === "error") {
      return version;
    }
    return electronVersion;
  };

  render() {
    const {
      attributes: {
        name,
        mac_address,
        hostname,
        total,
        asset_id,
        calendar_name,
        calendar_id,
        campaign_name,
        campaign_id,
        loc,
        volume,
        tags,
        timezone,
        resolution,
        orientation,
        status,
        last_synched,
        last_connected,
        last_disconnected,
        electron_version,
        electron_environment
      }
    } = this.props;
    const { electronVersion } = this.props;

    return (
      <div className="networkInformation clearfix">
        {!this.state.edit && (
          <i
            className="bx bxs-pencil"
            style={{ marginLeft: "90%" }}
            onClick={() => this.handleEdit(true)}
          />
        )}
        <div className="row informationBlock">
          <div className="col-md-4 networkInformation__block">
            <i>Host Name</i>
            <span className="pb-2">{hostname || "N/A"}</span>
          </div>
          <div className="col-md-4 networkInformation__block">
            <i>Mac Address</i>
            <span className="pb-2">{mac_address || "N/A"}</span>
          </div>
          <div className="col-md-4 networkInformation__block">
            <i>Resolution</i>
            <span className="pb-2">{resolution}</span>
          </div>
          <div className="col-md-4 networkInformation__block">
            <i>Orientation</i>
            <span className="pb-2">
              {orientation ? convertOrientation(orientation) : "N/A"}
            </span>
          </div>
          <div className="col-md-4 networkInformation__block">
            <i>Campaign Name</i>
            <span className="pb-2">{campaign_name || "N/A"}</span>
          </div>

          <div className="col-md-4 networkInformation__block">
            <i>Calendar Name</i>
            <span className="pb-2">{calendar_name || "N/A"}</span>
          </div>
          <div className="col-md-4 networkInformation__block">
            <i>Electron Version</i>
            <span className="pb-2">
              {/* {this.getElectronVersion(electronVersion, electron_version) ||
                "N/A"} */}
              {electron_version || "N/A"}
            </span>
            {/* <span className="pb-2">{electronVersion || "N/A"}</span> */}
            {/* <span className="pb-2">{electron_version || "N/A"}</span> */}
          </div>
          <div className="col-md-4 networkInformation__block">
            <i>Electron Environment</i>
            <span className="pb-2">{electron_environment || "N/A"}</span>
          </div>
          <div className="col-md-4 networkInformation__block">
            <i>Time Zone</i>
            <span>{timezone || "N/A"}</span>
          </div>

          <div className="col-md-4 networkInformation__block">
            <i>Status</i>
            <span
              style={{ color: status === "online" ? "#6ed886" : "#A5A4BF" }}
            >
              {status}
            </span>
          </div>

          <div className="col-md-4 networkInformation__block">
            <i>Last Synced</i>
            <span>{moment.utc(last_synched).local().format("YYYY-MM-DD hh:mm A")|| "N/A"}</span>
          </div>

          <div className="col-md-4 networkInformation__block">
            <i>Last Connected</i>
            <span>{moment.utc(last_connected).local().format("YYYY-MM-DD hh:mm A") || "N/A"}</span>
          </div>

          <div className="col-md-4 networkInformation__block">
            <i>Last Disconnected</i>
            <span>{moment.utc(last_disconnected).local().format("YYYY-MM-DD hh:mm A")|| "N/A"}</span>
          </div>
          <div className="col-md-4 networkInformation__block">
            <Field
              label="Name"
              component={Input}
              name="name"
              maxLength="30"
              placeholder={this.state.edit ? "Enter Name" : "N/A"}
              disabled={!this.state.edit}
              inputClass="fieldInput"
            />
          </div>
          <div className="col-md-4 networkInformation__block">
            <Field
              label="Latitude"
              component={Input}
              name="latitude"
              maxLength="30"
              placeholder={this.state.edit ? "Enter latitude" : "N/A"}
              inputClass="fieldInput"
              disabled={!this.state.edit}
            />
          </div>
          <div className="col-md-4 networkInformation__block">
            <Field
              label="Longitude"
              component={Input}
              name="longitude"
              maxLength="30"
              placeholder={this.state.edit ? "Enter longitude" : "N/A"}
              inputClass="fieldInput"
              disabled={!this.state.edit}
            />
          </div>
          <div className="col-md-4 networkInformation__block">
            <Field
              label="Unit Number"
              component={Input}
              name="assetId"
              maxLength="30"
              placeholder={this.state.edit ? "Enter Unit Number" : "N/A"}
              inputClass="fieldInput"
              disabled={!this.state.edit}
              validate={integer}
            />
          </div>
          <div className="col-md-8 networkInformation__block">
            <i>Tags</i>
            {this.state.edit ? (
              <Field
                component={TagSelect}
                isMulti
                disabled
                type="text"
                name="tags"
                id="tags"
                clearError={this.props.clearError}
                placeholder="Device Tag"
                onCreateOption={this.props.handleNewTag}
                tagHasError={this.props.tagHasError}
              />
            ) : (
              tags.map(item => (
                <span className="contentTag__badge">{item}</span>
              ))
            )}
          </div>
        </div>
        {this.state.edit && (
          <div className="button-group float-right">
            <Button
              className="btn-outline-primary cancelButton"
              onClick={() => this.handleCancelChanges(false)}
            >
              Cancel
            </Button>
            <Button
              className="primaryButton mt-0"
              onClick={this.props.handleSubmit(values =>
                this.handleDeviceUpdate(values)
              )}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { list, hasList, diskUsages } = state.deviceDetails;
  const { tags } = state.electronTags.list || [];
  const selector = formValueSelector("deviceDetailForm");

  return {
    ...(list || []),
    diskUsages: diskUsages || undefined,
    hasList,
    tagOptions: tags || [],
    selectedTags: selector(state, "electronTags")
  };
}

const DeviceDetailWithForm = reduxForm({
  form: "deviceDetailForm"
})(DeviceDetailForm);

export default connect(
  mapStateToProps,
  { initialize, showNotification }
)(DeviceDetailWithForm);
