import { getFilterStatus } from "../../../helper/helperFuncs";

export const Resources = {
  getArchivedContent: {
    url: "/contents/archive",
    asyncActions: {
      init: "getArchivedContent_INIT",
      success: "getArchivedContent_SUCCESS",
      error: "getArchivedContent_ERROR"
    }
  },
  unArchiveContent: {
    url: "/contents/unarchive",
    asyncActions: {
      init: "unArchivedContent_INIT",
      success: "unArchivedContent_SUCCESS",
      error: "unArchivedContent_ERROR"
    }
  },
  getArchivedPlaylist: {
    url: "/playlists/archive",
    asyncActions: {
      init: "getArchivedPlaylist_INIT",
      success: "getArchivedPlaylist_SUCCESS",
      error: "getArchivedPlaylist_ERROR"
    }
  },
  unArchivePlaylist: {
    url: "/playlists/unarchive",
    asyncActions: {
      init: "unArchivePlaylist_INIT",
      success: "unArchivePlaylist_SUCCESS",
      error: "unArchivePlaylist_ERROR"
    }
  },
  getArchivedCampaign: {
    url: "/campaigns/archive",
    asyncActions: {
      init: "getArchivedCampaign_INIT",
      success: "getArchivedCampaign_SUCCESS",
      error: "getArchivedCampaign_ERROR"
    }
  },
  postArchiveCampaign: {
    url: "campaigns/bulk_archive",
    asyncActions: {
      init: "postArchiveCampaign_INIT",
      success: "postArchiveCampaign_SUCCESS",
      error: "postArchiveCampaign_ERROR"
    }
  },
  getArchivedCalendar: {
    url: "/calendars/archive",
    asyncActions: {
      init: "getArchivedCalendar_INIT",
      success: "getArchivedCalendar_SUCCESS",
      error: "getArchivedCalendar_ERROR"
    }
  },
  unArchiveCalendar: {
    url: "/calendars/unarchive",
    asyncActions: {
      init: "unArchiveCalendar_INIT",
      success: "unArchiveCalendar_SUCCESS",
      error: "unArchiveCalendar_ERROR"
    }
  }
};

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: true,
  hasError: undefined,
  url: "http://www.dummy.com?page=1&per_page=20"
};

export function archivedContent(state = initialState, action = {}) {
  const { init, success, error } = Resources.getArchivedContent.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      const url = action.payload.request.responseURL;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        url
      };
    }

    // case updateList: {
    //   return {
    //     ...state,
    //     list: [...action.payload]
    //   };
    // }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function archivePlaylists(state = initialState, action = {}) {
  const { init, success, error } = Resources.getArchivedPlaylist.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function archiveCampaign(state = { initialState }, action = {}) {
  const { init, success, error } = Resources.getArchivedCampaign.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        filterStatus: getFilterStatus(action.payload.config.url)
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function archiveCalendars(state = initialState, action = {}) {
  const { init, success, error } = Resources.getArchivedCalendar.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}
