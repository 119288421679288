/* eslint-disable react/no-multi-comp */
import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";

/** Developer Guide */
/**
 * @description: https://react-select.com/async
 * @description: https://github.com/JedWatson/react-select
 */

const defaultProps = {
  options: [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" }
  ],
  input: { value: undefined },
  onChange: () => {}
};

export class MultipleSelect extends React.Component {
  state = {};

  handleKeyPress(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  handleOptionChange(e) {
    this.props.onChange(e);
    this.props.input &&
      this.props.input.onChange &&
      this.props.input.onChange(e);
  }

  render() {
    return (
      <div className={this.props.selectWrapperClass}>
        <Select
          ref={this.props.forwardRef}
          required
          clearable
          {...this.props}
          value={this.props.input.value || undefined}
          onChange={e => this.handleOptionChange(e)}
          onKeyDown={e => this.handleKeyPress(e)}
          closeMenuOnSelect={
            this.props.closeMenuOnSelect && this.props.closeMenuOnSelect
          }
        />
      </div>
    );
  }
}

MultipleSelect.defaultProps = defaultProps;

export class AppCreatableSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      originalOptions: [],
      options: []
    };
    this.searchSelectRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.options !== prevState.originalOptions) {
      return { options: nextProps.options, originalOptions: nextProps.options };
    }
  }

  handleOptionChange = e => {
    this.props.onChange(e);
    this.props.input &&
      this.props.input.onChange &&
      this.props.input.onChange(e);
  };

  handleChange = (newValue: "any") => {
    this.setState({ value: newValue });
    if (this.props.input) {
      this.props.input.onChange(newValue);
    }
  };

  handleCreate = (inputValue: "any") => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      const { options } = this.state;
      const newOption = createOption(inputValue);
      this.setState({
        isLoading: false,
        options: [...options, newOption],
        value: newOption
      });
      if (this.props.input) {
        this.props.input.onChange(newOption);
      }
    }, 1000);
  };

  formatCreateLabel = input => `search name '${input}'`;

  handleSearchName = e => {
    this.searchSelectRef.current.state.value = {
      value: e,
      label: e,
      className: "someclass"
    };
    this.props.handleSearch(e);
  };

  validNewOption = inputValue => {
    if (inputValue) {
      return true;
    }
  };

  render() {
    const { isLoading, options, value } = this.state;
    return (
      <CreatableSelect
        ref={this.searchSelectRef}
        isClearable
        isDisabled={isLoading}
        createOptionPosition="first"
        placeholder={this.props.isSearch ? "Search" : "Select"}
        // isLoading={isLoading}
        onChange={
          this.props.isSearch
            ? e => this.handleOptionChange(e)
            : this.handleChange
        }
        onCreateOption={
          this.props.isSearch
            ? e => this.handleSearchName(e)
            : this.handleCreate
        }
        formatCreateLabel={this.props.isSearch ? this.formatCreateLabel : false}
        isValidNewOption={this.props.isSearch ? this.validNewOption : false}
        options={options}
        value={value}
      />
    );
  }
}

CreatableSelect.defaultProps = defaultProps;

const createOption = label => ({
  label,
  value: label
});
