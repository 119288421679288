import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { columns } from "../utils/domainDefaultCampaignUtils";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

export default class DefaultCampaignTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        name: false,
        status: false,
        orientation: false,
        date_created: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.defaultCampaign}
          columns={columns(this.state.hiddenColumns)}
          columnToggle
        >
          {props => (
            <div>
              <div className="d-flex flex-row-reverse mb-2">
                <VisibleColumnToggler
                  hiddenColumns={this.state.hiddenColumns}
                  toggleHiddenColumns={this.toggleHiddenColumns}
                  columnToggleProps={{ ...props.columnToggleProps }}
                />
              </div>
              <BootstrapTable
                classes="contentSectionTable"
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                // loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                keyField="id"
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
