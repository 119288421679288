// @flow

import React, { Component, Fragment } from "react";
import {
  Circle,
  CircleMarker,
  Map,
  Marker,
  Popup,
  TileLayer
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import onlineMarker from "../../../assets/images/online.svg";
import offlineMarker from "../../../assets/images/offline.svg";

// const center = [28.3949, 84.124];
const center = [27.7172, 85.324];

const onlinePointer = L.icon({
  iconUrl: onlineMarker,
  iconSize: [40, 40],
  iconAnchor: [20, 40]
});

const offlinePointer = L.icon({
  iconUrl: offlineMarker,
  iconSize: [40, 40],
  iconAnchor: [20, 40]
});

const createClusterCustomIcon = function(cluster) {
  // return L.divIcon({
  //   iconUrl: require("../../../assets/images/online.png"),
  //   iconAnchor: [5, 5],
  //   iconSize: [15, 15]
  // });
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "marker-cluster-custom",
    iconSize: L.point(40, 40, true)
  });
};

const MyPopupMarker = ({ device_name, location, status }) => (
  <Marker
    position={location}
    icon={status === "online" ? onlinePointer : offlinePointer}
  >
    <Popup>{device_name}</Popup>
  </Marker>
);

const MyMarkersList = ({ markers }) => {
  const items = markers.map(({ key, ...props }) => (
    <MyPopupMarker key={key} {...props} />
  ));
  return <Fragment>{items}</Fragment>;
};

export default class WorldMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: this.props.location || []
    };
  }

  render() {
    return (
      <Map center={center} zoom={11} onViewportChange={this.onViewportChange}>
        {/* <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" /> */}
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MarkerClusterGroup
          showCoverageOnHover={false}
          iconCreateFunction={createClusterCustomIcon}
        >
          <MyMarkersList markers={this.state.markers} />
        </MarkerClusterGroup>
      </Map>
    );
  }
}

// SimpleExample
