/* eslint-disable camelcase */
import React from "react";
import moment from "moment";

function convert(kelvin, unit) {
  if (unit === "celsius")
    return (
      <span>
        <span>{`${Number(kelvin - 273.15).toFixed(0)}`} &deg;C</span>
      </span>
    );
  if (unit === "fahrenheit") {
    return (
      <span>
        <span>
          {`${Number((kelvin - 273.15) * (9 / 5) + 32).toFixed(0)}`} &deg;F
        </span>
      </span>
    );
  }
  return kelvin;
}

function RenderNextDayWeather({ dt_txt, main, weather }, unit) {
  return (
    <div className="col">
      <span className="weatherWidget__date">
        {moment(dt_txt).format("dddd")}
      </span>
      <div className="weatherWidget__imageWrapper">
        <img
          style={{ display: "block", margin: "0 auto" }}
          src={`http://openweathermap.org/img/wn/${
            weather !== undefined ? weather[0].icon : "10d"
          }@2x.png`}
          alt={weather[0].main}
          width="100px"
        />
      </div>
      <div className="weatherWidget__temp">{convert(main.temp, unit)}</div>
    </div>
  );
}

export function Weather({ location, weather, unit, days }) {
  if (!weather || !location || !unit || !days) {
    return null;
  }
  return (
    <div className="weatherWidget__wrapper" style={style.widget}>
      <div style={style.top_bar}>
        <div style={{ ...style.panel, ...style.leftPanel }}>
          <div style={{ ...style.date }} className="date">
            {moment().format("dddd DD MMM YYYY")}
          </div>
          <div style={{ ...style.city }} className="city">
            {location}
          </div>
          {weather !== undefined && weather.data.weather_data.length === 0 && (
            <div className="d-flex">
              <i className="fas fa-exclamation-triangle weatherWidget__disclaimerIcon" />
              <p className="ml-1">
                Weather data could not be fetched for this location.
              </p>
            </div>
          )}
          <div style={style.temp} className="temp">
            {weather !== undefined &&
              weather.data.weather_data.length > 0 &&
              convert(weather.data.weather_data[0].main.temp, unit)}
          </div>
        </div>
        <div
          style={{ ...style.rightPanel, ...style.panel }}
          className="right-panel panel"
        >
          <img
            src={`http://openweathermap.org/img/wn/${
              weather !== undefined && weather.data.weather_data.length > 0
                ? weather.data.weather_data[0].weather[0].icon
                : "10d"
            }@2x.png`}
            alt=""
            width="100px"
          />
        </div>
      </div>
      {days != 1 && weather.data.weather_data.length > 0 && (
        <div className="weatherWidget__imgSection">
          <div className="row">
            {RenderNextDayWeather(weather.data.weather_data[1], unit)}
            {RenderNextDayWeather(weather.data.weather_data[2], unit)}
            {days == 5 && (
              <React.Fragment>
                {RenderNextDayWeather(weather.data.weather_data[3], unit)}
                {RenderNextDayWeather(weather.data.weather_data[4], unit)}
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const style = {
  widget: {
    position: "relative",
    width: "575px",
    // height: "155px",
    margin: "15px auto",
    backgroundColor: "#02B8E9",
    borderRadius: "3px",
    // textAlign: center,
    border: "none",
    color: "#fff",
    fontFamily: "Roboto",
    overflow: "hidden"
  },

  top_bar: {
    padding: "25px",
    paddingRight: "30px"
  },

  date: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#fff"
  },

  city: {
    fontSize: "24px",
    fontWeight: "bold",
    textTransform: "uppercase",
    paddingTop: "5px",
    color: "#fff"
  },

  temp: {
    fontSize: "36px",
    color: "#fff",
    fontWeight: "100"
  },

  panel: {
    display: "inline-block"
  },

  rightPanel: {
    position: "absolute",
    float: "right",
    top: "0",
    right: "50px",
    marginTop: "35px",
    paddingLeft: "50px"
  }
};
