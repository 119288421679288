import React from "react";
import { Map } from "../../helper/routeMap";

/** Campaign feature Pages */
import UserProfile from "./pages/userProfile";
import AddUser from "./pages/addUser";
import Home from "./pages/home";
import UserList from "./pages/userlist";
import DeviceList from "./pages/deviceList";
import Domain from "./pages/domainList";
import AddDomain from "./pages/addDomain";
import DomainSetting from "./pages/domainSetting";
import { DomainNotFound } from "./pages/domainNotFound";
import SystemInfo from "./pages/systemInfo";
import NotificationList from "../notifications/pages/notificationList";

// const mainPath = "/user";
const pathCreator = `/`;
const subPathCreator = subPath => `${pathCreator}${subPath}`;

/** Path Configuration End Here */

/**
 *
 * TODO: change this route with 404 pages
 */
const NoRoutes = props => <h1>No Routes Exist</h1>;

export default [
  {
    path: `${subPathCreator("dashboard")}`,
    component: Map(Home),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${"/domain_not_found"}`,
    component: Map(DomainNotFound),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("domain")}`,
    component: Map(Domain),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("userlist")}`,
    component: Map(UserList),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("devices")}`,
    component: Map(DeviceList),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("profile")}`,
    component: Map(UserProfile),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("adduser")}`,
    component: Map(AddUser),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("adddomain")}`,
    component: Map(AddDomain),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("edituser/:id")}`,
    component: Map(AddUser),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("domain/edit/:id")}`,
    component: Map(AddDomain),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("domain/setting/:id")}`,
    component: Map(DomainSetting),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("notifications")}`,
    component: Map(NotificationList),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${pathCreator}/*`,
    component: Map(NoRoutes),
    exact: true,
    secured: true,
    feature: "all"
  },
  {
    path: `${subPathCreator("systeminfo")}`,
    component: Map(SystemInfo),
    exact: true,
    secured: true,
    feature: "all"
  }
];
