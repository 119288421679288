import moment from "moment";
import { values } from "redux-form";

export function formValidator(value, name, formState) {
  // name is the name, that input component uses, to get the show and send the value
  // so validation is necessary for different names, say
  // { restricted hours, restricted minutes, restricted playcount, restricted playduration,
  // volume
  // starting date, ending date, duration}
  // this method should retrun a boolean
  let errors = { error: false };
  switch (name) {
    case "durationMinute":
      if (
        Number(value) < 0 ||
        Number(value) > 59 ||
        Number(formState.durationSecond) < 0 ||
        Number(formState.durationSecond) > 59
      ) {
        errors = {
          [`${name}Error`]: "0 - 59 format",
          error: true,
          durationSecondError: undefined
        };
      } else {
        errors = { ...errors, durationSecondError: undefined, [`${name}Error`]: undefined };
      }
      break;
    case "durationSecond":
      if (
        Number(value) < 0 ||
        Number(value) > 59 ||
        Number(formState.durationMinute) < 0 ||
        Number(formState.durationMinute) > 59
      ) {
        errors = {
          [`${name}Error`]: "0 - 59 format",
          error: true,
          durationMinuteError: undefined
        };
      } else {
        errors = { ...errors, durationSecondError: undefined, [`${name}Error`]: undefined };
      }
      break;
    case "weight":
      if (
        Number(value) < 0 ||
        Number(value) > 100 ||
        Number(formState.playlistWeight) < 0 ||
        Number(formState.playlistWeight) > 100
      ) {
        errors = {
          [`${name}Error`]: "0 - 100 format",
          error: true
        };
      } else if (!Number.isInteger(Number(value))) {
        errors = {
          [`${name}Error`]: "Only Integer",
          error: true
        };
      } else {
        errors = { ...errors, weightError: undefined, [`${name}Error`]: undefined };
      }
      break;

    case "restrictStartHour":
      if (formState.restrictEndHour && moment(value).isAfter(moment(formState.restrictEndHour))) {
        errors = {
          [`${name}Error`]: "Must be before ending time.",
          restrictEndHourError: undefined,
          error: true
        };
      } else {
        errors = { ...errors, restrictEndHourError: undefined, [`${name}Error`]: undefined };
      }
      break;

    case "restrictEndHour":
      if (
        formState.restrictStartHour &&
        moment(value).isBefore(moment(formState.restrictStartHour))
      ) {
        errors = {
          [`${name}Error`]: "Must be after starting time.",
          restrictStartHourError: undefined,
          error: true
        };
      } else {
        errors = { ...errors, restrictStartHourError: undefined, [`${name}Error`]: undefined };
      }
      break;

    case "startDate":
      if (formState.endDate && moment(value).isAfter(moment(formState.endDate))) {
        errors = {
          [`${name}Error`]: "Must be before ending time.",
          endDateError: undefined,
          error: true
        };
      } else {
        errors = { ...errors, endDateError: undefined, [`${name}Error`]: undefined };
      }
      break;

    case "endDate":
      if (formState.startDate && moment(value).isBefore(moment(formState.startDate))) {
        errors = {
          [`${name}Error`]: "Must be after starting time.",
          startDateError: undefined,
          error: true
        };
      } else {
        errors = { ...errors, startDateError: undefined, [`${name}Error`]: undefined };
      }
      break;

    default:
  }
  return errors;
}

export function playlistByWeightRestrictions(data) {
  const firstObject = data[0];
  const validated = {
    startvalidated: false,
    endvalidated: false,
    startHourValidated: false,
    endHourValidated: false,
    weight: false
  };
  validated.startvalidated = data.every(item =>
    item.startDate && firstObject.startDate
      ? item.startDate.valueOf() === firstObject.startDate.valueOf()
      : item.startDate === firstObject.startDate
  );

  validated.endvalidated = data.every(item =>
    item.endDate && firstObject.endDate
      ? item.endDate.valueOf() === firstObject.endDate.valueOf()
      : item.endDate === firstObject.endDate
  );

  validated.startHourValidated = data.every(item =>
    item.restrictStartHour && firstObject.restrictStartHour
      ? moment(item.restrictStartHour)
          .format("HH:mm")
          .toString() ===
        moment(firstObject.restrictStartHour)
          .format("HH:mm")
          .toString()
      : true
  );

  validated.endHourValidated = data.every(item =>
    item.restrictEndHour && firstObject.restrictEndHour
      ? moment(item.restrictEndHour)
          .format("HH:mm")
          .toString() ===
        moment(firstObject.restrictEndHour)
          .format("HH:mm")
          .toString()
      : true
  );
  const weightArray = data.map(item => Number(item.weight));
  const totalWeight = weightArray.reduce((a, b) => a + b, 0);
  validated.weight = totalWeight === 100;
  return validated;
}
// case "restrictStartMinute":
// // code block
// if (Number(value) >= 0 && Number(value) <= 59) {
//   state = true;
// } else {
//   message = "0 - 59 format";
// }
// break;
// case "restrictEndMinute":
// // code block
// if (Number(value) >= 0 && Number(value) <= 59) {
//   state = true;
// } else {
//   message = "0 - 59 format";
// }
// break;

// case "maximumCountPerHour":
// // code block
// if (Number(value) >= 0) {
//   state = true;
// } else {
//   message = "Invalid Input";
// }
// break;
// case "maximumTimePerHourMinute":
// // code block
// if (Number(value) >= 0 && Number(value) <= 59) {
//   state = true;
// } else {
//   message = "0 - 59 format";
// }
// break;
// case "maximumTimePerHourSecond":
// // code block
// if (Number(value) >= 0 && Number(value) <= 59) {
//   state = true;
// } else {
//   message = "0 - 59 format";
// }
// break;
// case "volume":
// // code block
// if (Number(value) >= 0 && Number(value) <= 100) {
//   state = true;
// } else {
//   message = "0 - 100 ";
// }
// break;
