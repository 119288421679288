import React, { Component } from "react"
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Link } from "react-router-dom";


class NetworkNotifications extends Component {
    render() {
        return (
            <div>
                {this.props.notifications.length > 0 ? (
                    <div>
                        <div className="notificationContainer">
                            <ul>
                                {" "}
                                {this.props.notifications.map(item => (
                                    <Link
                                        to={{
                                            pathname: `/networks/device/${item.attributes.device_id}?id=${item.attributes.device_group_id}&gn=${item.attributes.device_group_name}&n=${item.attributes.device_name}&view=groups`,
                                            state: {
                                                key: item.id,
                                                deviceID: item.attributes.device_id,
                                                groupID: item.attributes.device_group_id,
                                                groupName: item.attributes.device_group_name,
                                                name: item.attributes.device_name || "",
                                                fromNotification: true
                                            }
                                        }}
                                    >
                                        <li
                                            key={item.id}
                                            style={{
                                                background:
                                                    !item.attributes.read && "rgb(234, 248, 241)"
                                            }}
                                            onClick={() => {
                                                this.markNotificationAsRead(item.id);
                                            }}
                                        >
                                            <div className="d-flex">
                                                <div className="mr-auto">
                                                    <strong>{item.attributes.hostname} </strong> has
                                                    exceeded{" "}
                                                    <strong> {item.attributes.alert_type} </strong>{" "}
                                                    threshold.{" "}
                                                </div>
                                                <div
                                                    style={{
                                                        color: "darkgrey",
                                                        fontSize: "12px",
                                                        marginTop: "2px"
                                                    }}
                                                >
                                                    {moment.utc(item.attributes.created_at).fromNow()}
                                                </div>
                                            </div>
                                            <div className="mt-2 mb-2">
                                                {this.handleIcon(item.attributes.alert_type)}
                                                {item.attributes.value}%
                                                <i className="mr-2 ml-3 fas fa-desktop"></i>
                                                {item.attributes.mac_address}
                                            </div>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                        <div className="contentSectionPagination notificationPagination">
                            <ReactPaginate
                                pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                marginPagesDisplayed={2}
                                containerClassName="pagination"
                                subContainerClassName="pages pagination"
                                activeClassName="active"
                                previousLinkClassName="pagination-label"
                                nextLinkClassName="pagination-label"
                                previousLabel="<"
                                forcePage={this.state.forcePage}
                                nextLabel=">"
                                breakLabel="..."
                                breakClassName="break-me"
                                disabledClassName="paginationDisable"
                            />
                        </div>
                    </div>
                ) : (
                    <div id="notfound">
                        <div className="notfound">
                            <div className="notfound-404">
                                <i className="fas fa-bell fa-4x" />

                                <h3 className="mt-3"> No notifications right now!</h3>
                            </div>
                            <Link to="/dashboard">
                                Go to Homepage
                                <i className="fa fa-arrow-right" />
                            </Link>
                        </div>
                    </div>
                )
                }
            </div>
        )
    }
}

export default NetworkNotifications;