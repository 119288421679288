/** Import Node Modules */
import React, { Component } from "react";

/** Import Redux Library */
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { change, destroy } from "redux-form";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { RemoveItemFromContentList } from "../contents/redux";

/** Import Local Components */
import { CampaignTable } from "./modules";
import { Dialog } from "../../core";
import { Resources, postArchivedItems } from "./redux/campaignRedux";

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    Resources.getCampaign.url = `/campaigns?page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props.Get(Resources.getCampaign).then(() => {
      this.setState({
        isLoading: false
      });
    });
    this.campaignTableRefs = React.createRef();
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      screen_size: { active: false, sortBy: "desc" },
      orientation: { active: false, sortBy: "desc" },
      status: { active: false, sortBy: "desc" },
      created_at: { active: false, sortBy: "desc" },
      updated_at: { active: false, sortBy: "desc" }
    };
    this.state = {
      forcePage: undefined,
      refreshing: false,
      status: undefined,
      sortOrder: this.initialSortOrder,
      isLoading: true,
      sortValue: { isSortable: false },
      deleteModal: {
        id: undefined,
        modal: false,
        body: "Are you Sure you want to delete this content.",
        title: "Confirm Delete"
      },
      searchVal: "",
      searchRes: false,
      disableArchiveIcon: false
    };
    this.searchRef = React.createRef();
  }

  handleDeleteConfirmation = (id, name) => {
    const { deleteModal } = this.state;
    deleteModal.modal = true;
    deleteModal.id = id;
    deleteModal.body = (
      <span>
        <small>Are you sure you want to delete </small>
        <strong>{name}</strong>?
      </span>
    );

    this.setState({ deleteModal });
  };

  handleDelete = () => {
    const {
      deleteModal: { id }
    } = this.state;
    if (id) {
      this.props
        .Delete(Resources.deleteCampaign(id))
        .then(() => {
          this.props.Get(Resources.getCampaign).then(result => {
            if (result.status === 200) {
              return (
                this.props.campaignList.length === 0 &&
                this.handlePageClick(this.props.meta, true)
              );
            }
          });
          const { deleteModal } = this.state;
          deleteModal.modal = false;
          deleteModal.id = undefined;
          this.setState({
            deleteModal
          });
        })
        .then(() =>
          this.props.change("SelectedCampaigns", "campaignsSelected", [])
        );
      return 0;
    }
    return 0;
  };

  toggleModel = flag => {
    const { deleteModal } = this.state;
    deleteModal.modal = flag;
    this.setState({ deleteModal });
    this.forceUpdate();
  };

  /**
   * @param {id}  campaign id
   */
  handleDuplicate = id => {
    this.props.Get(Resources.duplicateCampaign(id)).then(() => {
      this.props.Get(Resources.getCampaign);
    });
  };

  /**
   * @param {id}  campaign id
   */
  handlePlayCampaign = id => {
    this.props.Get(Resources.playCampaign(id)).then(() => {
      this.props.Get(Resources.getCampaign);
    });
  };

  handleSearchValue = value => {
    this.setState({ forcePage: 0, searchVal: value }, () => {
      this.handleSearch();
    });
  };

  // eslint-disable-next-line react/sort-comp
  handleSearch = debounce(() => {
    const { getCampaign } = Resources;
    if (this.state.searchVal === "") {
      this.setState({ searchRes: false });
      getCampaign.url = `/campaigns?page=${1}&per_page=${this.perPageCount}`;
      this.props.Get(getCampaign);
    } else {
      this.setState({ searchRes: true });
      getCampaign.url = `/campaigns/search?search=${
        this.state.searchVal
      }&page=${1}&per_page=${this.perPageCount}`;
      this.props.Get(getCampaign);
    }
  }, 3000);

  handleSort = name => {
    this.setState({ forcePage: 0, isLoading: true });
    this.props.change("SelectedCampaigns", "campaignsSelected", []);
    this.campaignTableRefs.current.selectionContext.selected = [];
    this.campaignTableRefs.current.setState({});
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getCampaign } = Resources;
        getCampaign.url = `/campaigns/?sort=${name}&sort_by=${
          this.state.sortOrder[name].sortBy
        }&page=${1}&per_page=${this.perPageCount}`;
        this.props
          .Get(Resources.getCampaign)
          .then(() => this.setState({ isLoading: false }));
      }
    );
  };

  handleRefresh = () => {
    if (!this.state.refreshing) {
      Resources.getCampaign.url = `/campaigns?page=${1}&per_page=${
        this.perPageCount
      }`;
      this.searchRef.current.clearSearchByName();
      this.setState({
        refreshing: true,
        forcePage: 0,
        sortOrder: this.initialSortOrder,
        status: undefined,
        searchRes: false
      });
      this.props
        .Get(Resources.getCampaign)
        .then(() => this.setState({ refreshing: false }))
        .then(() => this.props.showNotification("Refreshed", "info"));
    }
  };

  handleStatus = value => {
    this.setState({ forcePage: 0, isLoading: true, status: value });
    const { getCampaign } = Resources;
    getCampaign.url = `/campaigns?status=${value}&page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props
      .Get(Resources.getCampaign)
      .then(() => this.setState({ isLoading: false }));
  };

  handleArchiveItem = async id => {
    const { postArchiveCampaign } = Resources;
    postArchiveCampaign.body = postArchivedItems({ filteredCampaignId: [id] });
    await this.props
      .Put(postArchiveCampaign)
      .then(() =>
        this.props.Get(Resources.getCampaign).then(result => {
          if (result.status === 200) {
            return (
              this.props.campaignList.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        })
      )
      .then(() =>
        this.props.change("SelectedCampaigns", "campaignsSelected", [])
      );
  };

  handleArchiveItems = async (campaigns, isDisabled) => {
    this.setState({ disableArchiveIcon: true });
    this.forceUpdate();
    if (!isDisabled) {
      const { postArchiveCampaign } = Resources;
      const filteredCampaignId = campaigns.map(item => item.id);
      postArchiveCampaign.body = postArchivedItems({ filteredCampaignId });
      await this.props
        .Put(postArchiveCampaign)
        .then(() =>
          this.props.Get(Resources.getCampaign).then(result => {
            if (result.status === 200) {
              return (
                this.props.campaignList.length === 0 &&
                this.handlePageClick(this.props.meta, true)
              );
            }
          })
        )
        .then(() =>
          this.props.change("SelectedCampaigns", "campaignsSelected", [])
        )
        .then(() => {
          this.setState({ disableArchiveIcon: false });
        });
    }
  };

  handleDeleteCampaigns = campaigns => {
    const filteredCampaignId = campaigns.map(item => item.id);
    const { removeBulkCampaign } = Resources;
    removeBulkCampaign.body = postArchivedItems({ filteredCampaignId });
    this.props.openGlobalModal({
      heading: "Delete Campaigns",
      body: "Are you sure you want to delete?",
      actionFunc: () => {
        this.props
          .Put(removeBulkCampaign)
          .then(() =>
            this.props.Get(Resources.getCampaign).then(result => {
              if (result.status === 200) {
                return (
                  this.props.campaignList.length === 0 &&
                  this.handlePageClick(this.props.meta, true)
                );
              }
            })
          )
          .then(() =>
            this.props.change("SelectedCampaigns", "campaignsSelected", [])
          );
      }
    });
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    const { getCampaign } = Resources;
    if (moveToPrevious) {
      getCampaign.url = `/campaigns?page=${data.page - 1}&per_page=${
        this.perPageCount
      }`;
      this.props.Get(getCampaign).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      this.setState({ forcePage: undefined });
      this.setState({ isLoading: true });
      const { selected } = data;
      if (this.state.sortValue.isSortable) {
        getCampaign.url = `/campaigns?sort=${
          this.state.sortValue.name
        }&sort_by=${this.state.sortValue.sortBy}&page=${selected +
          1}&per_page=${this.perPageCount}`;
      } else if (this.state.status) {
        getCampaign.url = `/campaigns?status=${
          this.state.status
        }&page=${selected + 1}&per_page=${this.perPageCount}`;
      } else if (this.state.searchRes) {
        getCampaign.url = `/campaigns/search?search=${
          this.state.searchVal
        }&page=${selected + 1}&per_page=${this.perPageCount}`;
      } else {
        getCampaign.url = `/campaigns?page=${selected + 1}&per_page=${
          this.perPageCount
        }`;
      }
      this.props
        .Get(getCampaign)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  render() {
    const { deleteModal } = this.state;

    return (
      <div className="mainPage">
        <div className="contentSection">
          {" "}
          <Dialog
            externalControl
            showHandler={false}
            modal={deleteModal.modal}
            body={deleteModal.body}
            title={deleteModal.title}
            toggleModel={this.toggleModel}
            primaryMethod={this.handleDelete}
          />
          <ReactTooltip />
          <CampaignTable
            {...this.props}
            searchRef={this.searchRef}
            tableRef={this.campaignTableRefs}
            handlePlayCampaign={this.handlePlayCampaign}
            handleDelete={this.handleDeleteConfirmation}
            handleSort={this.handleSort}
            sortOrder={this.state.sortOrder}
            handleDuplicate={this.handleDuplicate}
            isLoading={this.state.isLoading}
            campaignList={this.props.campaignList}
            handleArchiveItem={this.handleArchiveItem}
            // below props used by table header
            refreshing={this.state.refreshing}
            handleRefresh={this.handleRefresh}
            handleSearch={this.handleSearchValue}
            handleStatus={this.handleStatus}
            handleArchiveItems={this.handleArchiveItems}
            handleDeleteCampaigns={this.handleDeleteCampaigns}
            disableArchiveIcon={this.state.disableArchiveIcon}
          />
          {!this.state.isLoading &&
            this.props.campaignList &&
            this.props.campaignList.length > 0 && (
              <div className="contentSectionPagination clearfix">
                <ReactPaginate
                  pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  marginPagesDisplayed={2}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  previousLabel="<"
                  forcePage={this.state.forcePage}
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  disabledClassName="paginationDisable"
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    list,
    status,
    loading,
    hasError,
    meta,
    filterStatus
  } = state.campaign;
  return {
    campaignList: list !== undefined ? list : [],
    loading,
    hasError,
    meta,
    status,
    filterStatus
  };
}

export default connect(
  mapStateToProps,
  { RemoveItemFromContentList, change }
)(Campaign);
