/** Import Node Modules */
import React, { Component } from "react";
import {
  change as ReduxFormChange,
  destroy,
  formValueSelector
} from "redux-form";
/** Import Redux Library */
import { connect } from "react-redux";
/** Import Local Components */
import ReactPaginate from "react-paginate";
import PlaylistArchiveTable from "../modules/playlistArchiveTable";
import { Dialog, Tooltip } from "../../../core";

import { RemoveItemFromContentList } from "../redux";
import RouteAllowed from "../../../helper/isRouteAllowed";
import {
  Resources,
  postArchivedItems,
  removeSingleArchivedPlaylist,
  unArchivePlaylists
} from "../redux/playlistRedux";

class ArchivePlaylist extends Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    Resources.getArchivedPlaylist.url = `/playlists/archive?page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props.Get(Resources.getArchivedPlaylist).then(() => {
      this.setState({
        isLoading: false
      });
    });
    this.archiveTableRefs = React.createRef();
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      status: { active: false, sortBy: "desc" },
      created_at: { active: false, sortBy: "desc" },
      updated_at: { active: false, sortBy: "desc" }
    };
    this.state = {
      forcePage: undefined,
      sortOrder: this.initialSortOrder,
      isLoading: true,
      sortValue: { isSortable: false },
      deleteModal: {
        id: undefined,
        modal: false,
        body: "Are you sure you want to delete this content.",
        title: "Confirm Delete"
      }
    };
  }

  handleSort = name => {
    this.setState({ isLoading: true, forcePage: 0 });
    this.props.ReduxFormChange(
      "SelectedPlaylistOnArchive",
      "selectedPlaylist",
      []
    );
    this.archiveTableRefs.current.selectionContext.selected = [];
    this.archiveTableRefs.current.setState({});
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getArchivedPlaylist } = Resources;
        getArchivedPlaylist.url = `/playlists/archive/?sort=${name}&sort_by=${
          this.state.sortOrder[name].sortBy
        }&page=${1}&per_page=${this.perPageCount}`;
        this.props
          .Get(Resources.getArchivedPlaylist)
          .then(() => this.setState({ isLoading: false }));
      }
    );
  };

  handleDeleteConfirmation = id => {
    const { deleteModal } = this.state;
    deleteModal.modal = true;
    deleteModal.id = id;
    deleteModal.body = (
      <span>
        <span>Are you sure you want to delete? </span>
        {/* <strong>{name}</strong>? */}
      </span>
    );

    this.setState({ deleteModal });
  };

  handleUnArchiveItem = id => {
    const { unArchivePlaylist } = Resources;
    unArchivePlaylist.body = unArchivePlaylists({ filteredPlaylistId: [id] });
    this.props.Put(unArchivePlaylist).then(() => {
      this.props.Get(Resources.getArchivedPlaylist).then(result => {
        if (result.status === 200) {
          return (
            this.props.archivePlaylists.length === 0 &&
            this.handlePageClick(this.props.meta, true)
          );
        }
      });
    });
  };

  handleUnArchivePlaylists = () => {
    const { unArchivePlaylist } = Resources;
    const filteredPlaylistId = this.props.selectedPlaylist.map(item => item.id);
    unArchivePlaylist.body = unArchivePlaylists({ filteredPlaylistId });
    this.props
      .Put(unArchivePlaylist)
      .then(() => {
        this.props.Get(Resources.getArchivedPlaylist).then(result => {
          if (result.status === 200) {
            return (
              this.props.archivePlaylists.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        });
      })
      .then(() =>
        this.props.ReduxFormChange(
          "SelectedPlaylistOnArchive",
          "selectedPlaylist",
          []
        )
      )
      .then(() => {
        this.archiveTableRefs.current.selectionContext.selected = [];
        this.archiveTableRefs.current.setState({});
      })
      .then
      // () => console.error(this.props.archivePlaylists.length)
      // this.props.archivePlaylists.length === 0 && this.handlePageClick(this.props.meta, true)
      ();
  };

  handleDelete = () => {
    const { deleteModal } = this.state;
    const filteredPlaylistId = this.props.selectedPlaylist.map(item => item.id);
    const { removeBulkPlaylist } = Resources;
    if (deleteModal.id) {
      removeBulkPlaylist.body = removeSingleArchivedPlaylist(deleteModal.id);
    } else {
      removeBulkPlaylist.body = postArchivedItems({ filteredPlaylistId });
    }
    this.props
      .Put(removeBulkPlaylist)
      .then(() => {
        this.props.Get(Resources.getArchivedPlaylist).then(result => {
          if (result.status === 200) {
            return (
              this.props.archivePlaylists.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        });
        deleteModal.modal = false;
        deleteModal.id = undefined;
        this.setState({
          deleteModal
        });
      })
      .then(() =>
        this.props.ReduxFormChange(
          "SelectedPlaylistOnArchive",
          "selectedPlaylist",
          []
        )
      )
      .then(() => {
        this.archiveTableRefs.current.selectionContext.selected = [];
        this.archiveTableRefs.current.setState({});
      });
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    const { getArchivedPlaylist } = Resources;
    if (moveToPrevious) {
      getArchivedPlaylist.url = `/playlists/archive?page=${data.page -
        1}&per_page=${this.perPageCount}`;
      this.props.Get(getArchivedPlaylist).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      this.setState({ forcePage: undefined });
      this.setState({ isLoading: true });
      const { selected } = data;
      if (this.state.sortValue.isSortable) {
        getArchivedPlaylist.url = `/playlists/archive?sort=${
          this.state.sortValue.name
        }&sort_by=${this.state.sortValue.sortBy}&page=${selected +
          1}&per_page=${this.perPageCount}`;
      } else {
        getArchivedPlaylist.url = `/playlists/archive?page=${selected +
          1}&per_page=${this.perPageCount}`;
      }
      this.props
        .Get(getArchivedPlaylist)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  toggleModel = flag => {
    const { deleteModal } = this.state;
    deleteModal.modal = flag;
    this.setState({ deleteModal });
  };

  render() {
    const { deleteModal } = this.state;
    return (
      <div className="contentwrapper">
        <Dialog
          externalControl
          showHandler={false}
          modal={deleteModal.modal}
          body={deleteModal.body}
          title={deleteModal.title}
          toggleModel={this.toggleModel}
          primaryMethod={this.handleDelete}
        />
        <div className="d-flex mb-2 pr-0 contentheader networkFixedHeader mr-0">
          <h3 className="p-0 mr-auto mt-2">Archived Playlist</h3>
          {this.props.selectedPlaylist.length > 0 && (
            <div className="d-flex">
              {" "}
              <Tooltip
                item={
                  <div className="content-refresh ml-2">
                    <div
                      role="presentation"
                      className="fas fa-history dropdown dropdown--filter"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.handleUnArchivePlaylists();
                      }}
                    />
                  </div>
                }
                tooltipContent="Restore"
                id="Unarchive-content"
              />
              <Tooltip
                item={
                  <div className="content-refresh ml-2">
                    <div
                      role="presentation"
                      className="fa fa-trash"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.handleDeleteConfirmation();
                      }}
                    />
                  </div>
                }
                tooltipContent="Delete"
                id="delete-archive-content"
              />
            </div>
          )}
        </div>
        <PlaylistArchiveTable
          {...this.props}
          forwardRef={this.archiveTableRefs}
          handleSort={this.handleSort}
          sortOrder={this.state.sortOrder}
          handleDelete={this.handleDeleteConfirmation}
          isLoading={this.state.isLoading}
          playlistList={this.props.archivePlaylists}
          handleUnArchiveItem={this.handleUnArchiveItem}
        />
        {!this.state.isLoading &&
          this.props.archivePlaylists &&
          this.props.archivePlaylists.length > 0 && (
            <ReactPaginate
              pageCount={this.props.meta ? this.props.meta.totalPages : 0}
              pageRangeDisplayed={3}
              onPageChange={this.handlePageClick}
              marginPagesDisplayed={2}
              activeClassName="activePage"
              containerClassName="contentPagination"
              subContainerClassName="pages pagination"
              previousLabel="Previous"
              forcePage={this.state.forcePage}
              nextLabel="Next"
              breakLabel="..."
              breakClassName="break-me"
              disabledClassName="paginationDisable"
            />
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list, status, loading, hasError, meta } = state.archivePlaylists;
  const selector = formValueSelector("SelectedPlaylistOnArchive");

  return {
    archivePlaylists: list !== undefined ? list : [],
    selectedPlaylist: selector(state, "selectedPlaylist") || [],
    loading,
    hasError,
    meta,
    status
  };
}

export default connect(
  mapStateToProps,
  { ReduxFormChange, destroy, RemoveItemFromContentList }
)(ArchivePlaylist);
