/* eslint-disable no-underscore-dangle */
import { RRule, RRuleSet } from "rrule";
import moment from "moment";

const uuidv4 = require("uuid/v4");

function rRuleDateGenerator(data) {
  const rruleSetDates = new RRuleSet();
  rruleSetDates.rrule(new RRule(data));
  return rruleSetDates.all();
}

export function allEventsViewDataGenerator(event) {
  let count = 0;
  const eventData = event.filter(item => {
    count++;
    if (!item.hasOwnProperty("_destroy")) {
      return true;
    }
    if (item._destroy === "0") {
      return true;
    }
    if (item._destroy === 0) {
      return true;
    }
    return false;
  });

  const data = eventData.map(i => {
    /**
     * Rrule gives the range of data of that particular event.
     */

    const startingDates = rRuleDateGenerator({
      freq: RRule[i.repeat.value.toUpperCase()],
      interval: i.repeatFreq.value,
      dtstart: moment(i.start)._d,
      until: new Date(i.end)
    });

    const endingDate = moment(i.end);
    const endingDTStart = moment(
      startingDates.length > 1 ? startingDates[0] : i.end
    );
    endingDTStart.set({ h: endingDate.hour(), m: endingDate.minute() });

    const endingDates = rRuleDateGenerator({
      freq: RRule[i.repeat.value.toUpperCase()],
      interval: i.repeatFreq.value,
      dtstart: endingDTStart._d,
      until: new Date(i.end)
    });

    const excludedDatesISOString = i.excludedDates.map(excludedDateItem =>
      new Date(
        new Date(excludedDateItem).setHours(
          startingDates[0].getHours(),
          startingDates[0].getMinutes(),
          startingDates[0].getSeconds(),
          startingDates[0].getMilliseconds()
        )
      ).toISOString()
    );

    const hasManyEvents = startingDates.length > 1;
    return startingDates
      .map(
        (dates, index) =>
          !excludedDatesISOString.includes(dates.toISOString()) && {
            // These attributes are required for frontend part
            start: new Date(startingDates[index]),
            end: new Date(endingDates[index]),
            title: i.title,
            eventId: i.eventId,
            campaignName: i.campaignName,
            id: uuidv4(), // instance ID,
            _destroy: i._destroy,
            hasManyEvents,
            parent: { ...i }
          }
      )
      .filter(item => item !== false);
  });
  return data.flat();
  // allEventData mapping ends here
}

/**
 * id
title
start
end
campaignName
eventId
repeat
excludedDates
_destroy
repeatFreq
 */
