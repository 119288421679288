import moment from "moment";

export function getFilterStatus(url) {
  if (url.search("live") > -1 || url.search("online") > -1) {
    return "live";
  }
  if (url.search("offline") > -1) {
    return "offline";
  }
  return "all";
}

export function getMinutesAndSeconds(secondInTime) {
  if (secondInTime && secondInTime !== "N/A") {
    const minutes = Math.floor(secondInTime / 60);
    const seconds = secondInTime - minutes * 60;
    return { minutes, seconds };
  }
  return { minutes: 0, seconds: 5 };
}

export function getHourMinutesAndSeconds(secondInTime) {
  if (secondInTime && secondInTime !== "N/A") {
    const hours = Math.floor(secondInTime / 3600);
    const minutes = Math.floor(secondInTime / 60) - hours * 60;
    const seconds = secondInTime - minutes * 60 - hours * 3600;
    return { hours, minutes, seconds };
  }
  return { hours: 0, minutes: 0, seconds: 0 };
}

export function convertToSecond({ hour, minute, second }) {
  return Number(hour) * 3600 + Number(minute) * 60 + Number(second);
}

export function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getLocalTime(time) {
  return moment.utc(time).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A");
}
