/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Select from "react-select";

import { Button } from "reactstrap";
import moment from "moment";
import { values } from "ramda";
import { Tooltip } from "../../../core";
import { Resources as reportResources } from "../redux/reportRedux";
import { MultipleSelect } from "../../../core/form/searchSelect";

import { Resources as contentResources } from "../../contents/redux";
import { Resources as campaignResources } from "../../campaign/redux";
// import { Resources as networkResources } from "../../network/redux";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

class ReportHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultVal: [{ label: "Last 24hrs", value: "24.hour" }],
      sendingEmail: false,
    };
    // this.props.Get(contentResources.getContent);
    // this.props.Get(campaignResources.getCampaign);
    // this.props.Get(networkResources.getNetwork);
  }

  handleSendAsCSV = () => {
    this.setState({ sendingEmail: true }, () => {
      const { downloadReport } = reportResources;
      const {
        startDate,
        endDate,
        deviceName,
        contentName,
        campaignName,
        contentTags
      } = this.props.filter;
      const startTime = startDate ? moment(startDate).format("MM/DD/YYYY") : "";
      const endTime = endDate ? moment(endDate).format("MM/DD/YYYY") : "";
      const tag = contentTags
        ? contentTags.map(contentTag => contentTag.value)
        : "";
      const url = `/reports/report_as_csv?device_name=${deviceName ||
        ""}&&start_time=${startTime}&&end_time=${endTime}&&campaign_name=${campaignName ||
        ""}&&content_name=${contentName || ""}&&content_tags=${tag || ""}&&time_frame=${this.props.timeFrame}`;
      downloadReport.url = url;
      this.props.Get(downloadReport)
        .then(() => {
          this.setState({ sendingEmail: false });
        });
    })
  };
  handleExportAsCSV = () => {
    const rows = this.props.reportList;
    const commaSeperatedReport = rows.map(item => {
      const {
        name,
        content_tags,
        campaign_name,
        content_type,
        height,
        width,
        device_mac,
        start_time,
        end_time
      } = item.attributes;
      return `${name && name.replace(",", "")},${content_tags &&
        content_tags.join(" / ")}, ${campaign_name &&
        campaign_name.replace(
          ",",
          ""
        )},${content_type},${height}x${width},${device_mac},${start_time.replace(
          ",",
          " "
        )},${end_time.replace(",", " ")}`;
    });
    const csvContent = `Content Name,Content Tags,Campaign Name,MIME Type,Dimension,Device MAC,Start Time,End Time\n${commaSeperatedReport.join(
      "\n"
    )}`;
    const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const url = URL.createObjectURL(csvBlob);

    const link = document.createElement("a");
    link.style.visibility = "hidden";
    link.setAttribute("href", url);
    link.setAttribute("download", `${moment().format("MMMDDYYYY")}Reports.csv`);
    link.click();
  };

  handleAction = () => {
    this.props.handleToggleDrawer(true);
  };

  handleChange = ({ label, value }) => {
    this.props.handleTimeFrameChange(value, label);
    this.props.initializeFilter();
  };

  render() {
    const { refreshing } = this.props;
    return (
      <div
        className="d-flex contentHeader justify-content-end"
        data-spy="affix"
        data-offset-top="197"
      >
        {/* <SearchTagRefresh handleSearch={this.props.handleSearch} {...this.props} />
            <StatusButton handleStatus={this.props.handleStatusFilter} /> */}
        <div className="d-flex flex-row-reverse">
          <VisibleColumnToggler
            hiddenColumns={this.props.hiddenColumns}
            toggleHiddenColumns={this.props.toggleHiddenColumns}
            columnToggleProps={this.props.columnToggleProps}
          />

          <Tooltip
            item={
              <div className="iconButton" onClick={this.handleAction}>
                <i className="bx bx-filter" />
              </div>
            }
            id="header-content-filter"
            tooltipContent="Filter"
          />
          <Tooltip
            item={
              <div>
                <div
                  role="button"
                  className=" iconButton"
                  onClick={() => this.props.handleRefresh()}
                  disabled={refreshing}
                >
                  <i
                    className={`bx bx-revision ${refreshing ? "refresh-rotate " : ""
                      }`}
                  />{" "}
                </div>
              </div>
            }
            id="header-content-refresh"
            tooltipContent="Refresh"
          />
          <button
            onClick={this.handleExportAsCSV}
            id="get-csv"
            className="btn-outline-primary"
          >
            Download CSV
          </button>
          <Button
            className="btn-outline-primary mr-2 csv-button"
            type="button"
            id="get-csv"
            onClick={this.handleSendAsCSV}
          >
            Send CSV to Email
          </Button>

          <div style={{ width: "150px" }} className="reportSection-dropdown">
            <MultipleSelect
              style={{ padding: "1px", "margin-top": "1px" }}
              defaultValue={this.props.defaultVal}
              onChange={this.handleChange}
              options={[
                { label: "Last 24hrs", value: "24.hours" },
                { label: "Last 48hrs", value: "48.hours" },
                { label: "This Week", value: "1.weeks" },
                { label: "This Month", value: "1.months" }
              ]}
            />
          </div>
          {this.state.sendingEmail &&
            <div
              id="notification_toast-flash"
              className="notification_toast info animated fadeInUp"
            >
              <p>
                Sending Report
                <i className="fa fa-spinner fa-spin" />
              </p>
            </div>
          }
          {/* <Select
            // forwardRef={this.searchByTagRef}
            style={{ width: "125px" }}
            // searchable
            // onChange={this.handleTagChange}
            // className="header-tag-search header-tag-search--contentTagSearch"
            value=""
            options={[{ label: "Last 24hrs", value: "24.hour" }]}
          /> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("ReportFilter");

  return {
    values: selector(
      state,
      "startDate",
      "endDate",
      "deviceName",
      "contentName",
      "campaignName",
      "contentTags"
    )
  };
}

export default connect(
  mapStateToProps,
  {}
)(ReportHeader);
