/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Card, CardTitle, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { EditModule } from "../modules";
import { Resources, RemoveItemFromContentList } from "../redux";
import { Dialog, Tooltip } from "../../../core";
import { Delete } from "../../../constant/thunk";
import RouteAllowed from "../../../helper/isRouteAllowed";

/**
 * TODO:
 * this is also the module later on must be shifted to storybook
 * name, content_type, s3_thumbnail_url, dimension, size, status
 * s3_object_key
}
 */

class ItemDetail extends React.PureComponent {
  state = {
    deleteModal: {
      id: undefined,
      modal: false,
      body: "Are you sure you want to delete this content?",
      title: "Confirm Delete"
    }
  };

  handleDeleteConfirmation = id => {
    const { deleteModal } = this.state;
    deleteModal.modal = true;
    deleteModal.id = id;
    this.setState({ deleteModal });
    this.forceUpdate();
  };

  handleDeleteModalToggle = result => {
    const { deleteModal } = this.state;
    deleteModal.modal = result;
    this.setState({ deleteModal }, () => {
      this.forceUpdate();
    });
  };

  handleDelete = () => {
    const {
      deleteModal: { id }
    } = this.state;
    if (id) {
      this.props.Delete(Resources.deleteContent(id)).then(() => {
        this.props.RemoveItemFromContentList(id);
        const { deleteModal } = this.state;
        deleteModal.modal = false;
        deleteModal.id = undefined;
        this.setState(
          {
            deleteModal
          },
          () => {
            this.props.history.push("/dashboard/content");
          }
        );
      });
      return 0;
    }
    return 0;
  };

  render() {
    if (this.props.location.params === undefined) {
      this.props.history.push("/dashboard/content/");
      return null;
    }
    const { location } = this.props;
    const { deleteModal } = this.state;

    return (
      <div className="container edit-wrapper">
        <Dialog
          externalControl
          showHandler={false}
          modal={deleteModal.modal}
          body={deleteModal.body}
          title={deleteModal.title}
          toggleModel={this.handleDeleteModalToggle}
          primaryMethod={this.handleDelete}
        />
        {/* <HeaderBlock
          id={location.params.id}
          title={location.params.name}
          handleDelete={this.handleDeleteConfirmation}
        /> */}
        <h5 className="mb-2">{location.params.attributes.name} </h5>
        <div className="editblock-wrapper">
          <Row>
            <Col sm="7">
              <EditModule
                awsKey={location.params.attributes.s3_object_key}
                contentType={location.params.attributes.content_type}
              />
            </Col>
            <Col sm="5">
              <Card body className="pt-0">
                <CardTitle className="d-flex">
                  Information
                  <div className="d-flex justify-content-end ml-auto contentbutton  inneraction">
                    <span className="d-flex justify-content-end action">
                      <RouteAllowed
                        roles={location.params.roles}
                        permission={location.params.permission.content.edit}
                      >
                        <Link
                          to={`/dashboard/content/edit/${location.params.id}`}
                          className="action-edit"
                        >
                          <Tooltip
                            item={<i className="fa fa-edit" />}
                            id={`edit-${location.params.id}`}
                            tooltipContent="Edit"
                          />
                        </Link>
                      </RouteAllowed>
                      <RouteAllowed
                        roles={location.params.roles}
                        permission={location.params.permission.content.delete}
                      >
                        <Tooltip
                          item={
                            <i
                              onClick={() => this.handleDeleteConfirmation(location.params.id)}
                              className="fa fa-trash action-trash pt-2 pl-2 mr-0"
                              style={{ cursor: "pointer" }}
                            />
                          }
                          id={`delete-${location.params.id}`}
                          tooltipContent="Delete"
                        />
                      </RouteAllowed>
                    </span>
                  </div>
                </CardTitle>
                <span className="title-border" />

                <div className="d-inline-flex mt-2">
                  <div className="title-left">Name</div>
                  <div className="title-right">{location.params.attributes.name}</div>
                </div>

                <div className="d-inline-flex mt-2">
                  <div className="title-left">Size</div>
                  <div className="title-right"> {location.params.attributes.size}</div>
                </div>

                <div className="d-inline-flex mt-2">
                  <div className="title-left">Dimension</div>
                  <div className="title-right"> {location.params.attributes.dimension}</div>
                </div>

                <div className="d-inline-flex mt-2">
                  <div className="title-left">Expiration Date</div>
                  <div className="title-right">
                    {" "}
                    {location.params.attributes.expiration_date || "Null"}
                  </div>
                </div>
                {location.params.content_type &&
                  location.params.content_type.search("video/") >= 0 && (
                    <div className="d-inline-flex mt-2">
                      <div className="title-left">Duration</div>
                      <div className="title-right"> {location.params.attributes.duration}</div>
                    </div>
                  )}
                {/* <div className="d-inline-flex mt-2">
                    <div className="title-left">Status</div>
                  <div className="title-right">
                  {location.params.status} */}
                {/* <span
                      className={`content-text ${
                        status === "offline" ? "text-warning statusoffline" : "text-info statuslive"
                      }`}
                    >
                      {status}
                    </span>
                  </div>
                </div> */}
                <div className="d-inline-flex mt-2">
                  <div className="title-left">Extension</div>
                  <div className="title-right"> {location.params.attributes.content_type}</div>
                </div>
                {location.params.attributes.tags.length > 0 && (
                  <div className="d-inline-flex mt-2">
                    <div className="title-left">Tags</div>
                    <div className="title-right">
                      {location.params.attributes.tags.map((i, index) => (
                        <span key={`tags-${index}`} className="contentTag__badge">
                          {i}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { RemoveItemFromContentList, Delete }
)(ItemDetail);
