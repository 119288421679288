import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import { Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";

export function columns(
  roles,
  permission,
  handleDelete,
  handleSort,
  sortOrder,
  hiddenColumns
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "booking_name",
      hidden: hiddenColumns.booking_name,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Booking Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <div>
          {permission.ticket.show.some(r => roles.includes(r)) ? (
            <Link
              to={{
                pathname: `/tickets/show/${row.id}/`,
                state: { name: row.attributes.name }
              }}
              style={{ textDecoration: "none" }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <span className="content__titlename">
                {name.length >= 20 ? (
                  <Tooltip
                    item={
                      <span className="content__name">
                        {" "}
                        {`${name.slice(0, 20)}...`}{" "}
                      </span>
                    }
                    id={`viewname-${row.id}`}
                    tooltipContent={name}
                  />
                ) : (
                  <span className="content__name">{name}</span>
                )}
              </span>
            </Link>
          ) : (
            <span className="content__titlename">
              {name.length >= 20 ? (
                <Tooltip
                  item={
                    <span className="content__name">
                      {" "}
                      {`${name.slice(0, 20)}...`}{" "}
                    </span>
                  }
                  id={`viewname-${row.id}`}
                  tooltipContent={name}
                />
              ) : (
                <span className="content__name">{name}</span>
              )}
            </span>
          )}
        </div>
      )
    },
    {
      dataField: "attributes.customer",
      text: "Customer",
      columnName: "customer_name",
      hidden: hiddenColumns.customer_name,
      headerFormatter: () => (
        <div>
          <span
            role="presentation"
            onClick={() => handleSort("customer")}
            className="sort-by "
            style={{
              color: sortOrder.customer.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Customer Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.customer.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (customer, row) => (
        <div>
          <span className="content__titlename" style={{ fontSize: "18px" }}>
            {customer.length >= 20 ? (
              <Tooltip
                item={<span> {`${customer.slice(0, 20)}...`} </span>}
                id={`customer-${row.id}`}
                tooltipContent={customer}
              />
            ) : (
              <span>{customer}</span>
            )}
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.starting_date",
      text: "Booking Dates",
      columnName: "booking_dates",
      hidden: hiddenColumns.booking_dates,
      formatter: (name, row) => (
        <div>
          <span className="content__name">
            {row.attributes.starting_date || " - "}
          </span>{" "}
          -{" "}
          <span className="content__name">
            {row.attributes.ending_date || " - "}
          </span>
        </div>
      ),
      headerFormatter: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "120px"
          }}
        >
          <span
            role="presentation"
            onClick={() => handleSort("starting_date")}
            className="sort-by "
            style={{
              color: sortOrder.starting_date.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Booking Dates
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.starting_date.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.start_time",
      text: "Screen Time",
      columnName: "screen_time",
      hidden: hiddenColumns.screen_time,
      formatter: (name, row) => (
        <div>
          <span className="content__name">
            {row.attributes.start_time || " - "}
          </span>{" "}
          -{" "}
          <span className="content__name">
            {row.attributes.end_time || " - "}
          </span>
        </div>
      ),
      headerFormatter: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "120px"
          }}
        >
          <span
            role="presentation"
            onClick={() => handleSort("start_time")}
            className="sort-by "
            style={{
              color: sortOrder.start_time.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Screen Time
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.start_time.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.share_of_voice",
      text: "Share of Voice",
      columnName: "share_of_voice",
      hidden: hiddenColumns.share_of_voice,
      formatter: (name, row) => (
        <div>
          <span className="content__name">
            {row.attributes.share_of_voice}
            {"% "}
          </span>{" "}
          of{" "}
          <span className="content__name">
            {" "}
            {row.attributes.blocksize || "N/A"} sec
          </span>
        </div>
      ),
      headerFormatter: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "120px"
          }}
        >
          <span
            role="presentation"
            onClick={() => handleSort("share_of_voice")}
            className="sort-by "
            style={{
              color: sortOrder.share_of_voice.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Share of Voice
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.share_of_voice.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.updated_at",
      text: "Last Updated",
      columnName: "last_updated",
      hidden: hiddenColumns.last_updated,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Last Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.status",
      text: "Ticket Status",
      columnName: "ticket_status",
      hidden: hiddenColumns.ticket_status,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("status")}
            className="sort-by"
            style={{
              color: sortOrder.status.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Ticket Status
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.status.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: name => {
        if (name === "resolved") {
          return <div className="badge badge-resolved">{name}</div>;
        }
        if (name === "pending") {
          return <div className="badge badge-pending">{name}</div>;
        }
        return <div className="badge badge-open">new</div>;
      }
    },
    {
      dataField: "actions",
      text: "",
      style: { position: "relative" },
      columnName: "isSelected",
      formatter: (name, row) => (
        <div className="d-flex">
          <div className="contentSectionTable__menu">
            <i className="fas fa-ellipsis-h" />
          </div>
          <div
            className="contentSectionTable__actionButtonWrapper"
            style={{ left: "14px" }}
          >
            <RouteAllowed
              roles={roles || []}
              permission={permission.ticket.show}
            >
              <Link
                to={{
                  pathname: `/tickets/show/${row.id}`,
                  state: { name: row.attributes.name || "" },
                  params: { ...row, handleDelete, roles, permission }
                }}
                className="contentSectionTable__actionButtonWrapper__buttons"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Tooltip
                  item={<i className="fa fa-eye " />}
                  id={`view-${row.id}`}
                  tooltipContent="View"
                />
              </Link>
            </RouteAllowed>
            {/* <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.ticket.delete}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() => handleDelete(row.id, row.attributes.name)}
                      className="fa fa-trash action__trash"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  id={`delete-${row.id}`}
                  tooltipContent="Delete"
                />
              </span>
            </RouteAllowed> */}
          </div>
        </div>
      )
    }
  ];
}
