import React from "react";
import moment from "moment";
import "moment-timezone";
import HLSPlayer from "../utls/hlsPlayer";
import YoutubePlayer from "../utls/youtubePlayer";
import { StyledIframe } from "../../../core/layout/styledIframe";

// import weather from "../../../assets/picture/weather.png";

const RssRender = props =>
  props.rss.title.map(item => (
    <span className="ml-5 mr-5" style={{ fontFamily: props.rss.fontFamily }}>
      {item}
    </span>
  ));

const setTime = (timezone, id) => {
  const elTime = document.getElementById(`show_time-${id}`);
  const elDate = document.getElementById(`show_date-${id}`);
  const time = moment().tz(timezone || "Asia/Kathmandu");
  if (elTime && elDate) {
    // set time in html
    elTime.innerHTML = elTime && time.format("hh:mm A");
    // set date in html
    elDate.innerHTML = elDate && time.format("dddd, MMMM DD YYYY");
  }
};

const handleTime = (timezone, id) => {
  setTimeout(() => {
    setTime(timezone, id);
  }, 1000);
};

export const textPreview = (item, key, verticalScale = 2) => (
  <div
    key={key.contentID}
    style={{
      backgroundColor: key.backgroundColor,
      height: item.height,
      width: item.width,
      color: key.textColor,
      fontSize: `${key.fontSize / verticalScale}px`,
      fontFamily: key.fontFamily,
      textAlign: key.textAlign
    }}
  >
    <div className="center-the-element-vertically">
      {key.rotate || key.marquee ? (
        <marquee
          className="d-flex justify-content-center align-item-center"
          style={{
            backgroundColor: key.backgroundColor,
            color: key.textColor,
            border: "none",
            fontFamily: key.fontFamily
          }}
        >
          {key.text}
        </marquee>
      ) : (
        <span style={{ fontFamily: key.fontFamily }}>{key.text}</span>
      )}
    </div>
  </div>
);

export const urlPreview = (item, key, scale = 2) => {
  const transformScale = key.scaleFactor / scale;
  const expandFactor = scale / key.scaleFactor;
  return (
    <StyledIframe
      id="player"
      forwardRef={key.forwardRef}
      title={key.contentID}
      dynamicUrl={key.dynamicUrl}
      width={item.width * expandFactor}
      height={item.height * expandFactor}
      transformScale={transformScale}
      expandFactor={expandFactor}
    />
  );
};

export const hlsPreview = (item, key) => (
  <HLSPlayer
    src={key.dynamicUrl}
    width={item.width}
    height={item.height}
    disableDefaultControls
  />
);

export const dateTimePreview = (item, key, verticalScale = 2) => (
  <div
    style={{
      backgroundColor: key.backgroundColor,
      height: "100%",
      width: "100%"
    }}
  >
    <span className="text-center center-the-element-vertically">
      {handleTime(key.timezone.value, key.id || key.contentID)}
      <span
        id={`show_time-${key.id || key.contentID}`}
        style={{
          fontSize: `${24 / verticalScale}px`,
          color: key.textColor
        }}
      />
      <br />
      <span
        id={`show_date-${key.id || key.contentID}`}
        style={{ fontSize: `${16 / verticalScale}px`, color: key.textColor }}
      />
    </span>
  </div>
);

export const rssPreview = (item, key, verticalScale = 2) => (
  <div
    key={key.contentID}
    style={{
      fontSize: `${key.fontSize / verticalScale}px`,
      fontFamily: key.fontFamily,
      backgroundColor: key.backgroundColor,
      color: key.textColor,
      textAlign: "center",
      height: "100%",
      width: "100%"
    }}
  >
    <div className="center-the-element-vertically">
      <marquee
        scrollamount={key.delay}
        trueSpeed
        direction={key.direction}
        style={{
          backgroundColor: key.backgroundColor,
          border: "none",
          fontFamily: key.fontFamily,
          padding: "none"
        }}
      >
        <RssRender rss={key} />
      </marquee>
    </div>
  </div>
);

export const youtubePreview = (item, key) => (
  <div
    className="url-widget-background d-flex justify-content-center"
    style={{
      height: item.height,
      width: item.width
    }}
  >
    <YoutubePlayer
      url={key.dynamicUrl}
      height={item.height}
      width={item.width}
    />
  </div>
);

export const picToScreenPreview = (item, key) => (
  <iframe
    key={key.contentID}
    title={key.contentID}
    src={key.dynamicUrl}
    width={item.width}
    height={item.height}
    scrolling="no"
    style={{ border: "none" }}
  />
);

export const routePreview = (item, key, verticalScale = 2) => (
  <div
    key={key.contentID}
    style={{
      backgroundColor: key.backgroundColor,
      height: item.height,
      width: item.width,
      color: key.textColor,
      fontSize: `${key.fontSize / verticalScale}px`,
      fontFamily: key.fontFamily,
      textAlign: key.textAlign
    }}
  >
    <div className="center-the-element-vertically">
      <span style={{ fontFamily: key.fontFamily }}>{key.routeDuration}</span>
    </div>
  </div>
);

export const transparentPreview = (item, key) => (
  <div
    key={key.contentID}
    style={{
      height: item.height,
      width: item.width,
      display: "none"
    }}
  />
);
