import React from "react";
import { Card } from "reactstrap";
import { StudioContentSelector, StudioSplitScreen } from "../modules";

export function CampaignStudioDesktopView(props) {
  const { campaign } = props;
  return (
    <div className="row">
      <div
        className="col-md-6 pr-0"
        style={{ borderRight: "1px solid #dddde4" }}
      >
        <Card body>
          {" "}
          <StudioContentSelector
            orientation={props.orientation}
            handleFilter={props.handleFilter}
            handleContentSearch={props.handleContentSearch}
            isLoading={props.isLoading}
            setLoading={props.setLoading}
            forwardRef={props.forwardRef}
            filter={props.filter}
            resetFilter={props.resetFilter}
            campaignLoading={props.campaignLoading}
            subscription={props.subscription}
            {...props}
          />
        </Card>
      </div>
      <div className="col-md-6 pl-0">
        <Card body className="campaignStudioWrapper__rightSection">
          <div className="d-flex flex-column campaignStudioWrapper__rightSection__column">
            <StudioSplitScreen
              handleCampaignSave={props.handleCampaignSave}
              handleCancelCampaignCreation={props.handleCancelCampaignCreation}
              showPreview={props.showPreview}
              togglePreview={props.togglePreview}
              campaign={campaign}
              {...props}
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
