import {
  Resources,
  archivedContent,
  archivePlaylists,
  archiveCampaign,
  archiveCalendars
} from "./archiveRedux";

const archiveReducer = {
  archivedContent,
  archivePlaylists,
  archiveCampaign,
  archiveCalendars
};

export { archiveReducer, Resources };
