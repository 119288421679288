import React from "react";
import moment from "moment";
import { horizontalLongTemplate } from "../utils/horizontalLongTemplate";
import { verticalLongTemplate } from "../utils/verticalLongTemplate";
import { standardTemplate } from "../utils/standardTemplate";

const getCelsius = kelvin => Number(kelvin - 273.15).toFixed(0);

const getFahrenhiet = kelvin =>
  Number((kelvin - 273.15) * (9 / 5) + 32).toFixed(0);

const getDateDay = date => {
  const dt = moment(date * 1000);
  return {
    day: dt.format("dddd"),
    date: dt.format("dddd DD MMM YYYY")
  };
};

const toDeg = r => {
  const pi = 22 / 7;
  return (180 / pi) * r;
};

const toRad = d => {
  const pi = 22 / 7;
  return (pi / 180) * d;
};

const calcAngle = (w, h) => Math.round(toDeg(Math.atan(h / w)));

const getRadius = (w, h) => (w ** 2 + h ** 2) ** (1 / 2);
const getOrientation = (w, h) => {
  const angle = calcAngle(w, h);
  if (angle > 65) {
    return "verticalLong";
  }
  if (angle < 20) {
    return "horizontalLong";
  }
  if (angle >= 20 <= 30) {
    return "especialCase";
  }
  return "normalized";
};

const getFontSize = (width, height) => {
  let fontSize = 17 / 2;
  const radius = getRadius(width, height);
  const verticalWeight = 0.0055;
  const horizontalWeight = 0.0041;
  const normalizedWeight = 0.01;
  switch (getOrientation(width, height)) {
    case "verticalLong":
      fontSize = verticalWeight * radius;
      break;
    case "horizontalLong":
      fontSize = horizontalWeight * radius;
      break;
    default:
      fontSize = normalizedWeight * radius;
  }

  return fontSize;
};

const getRowHeight = height => 0.33333 * height;

const getImgWidth = (width, height) => {
  let imgWidth = 500 / 2;
  if (Number(width) < 1199 / 2 || Number(height) < 1199 / 2) {
    imgWidth = 285 / 2;
  }
  if (Number(width) < 991 / 2 || Number(height) < 991 / 2) {
    imgWidth = 200 / 2;
  }
  if (Number(width) < 767 / 2 || Number(height) < 767 / 2) {
    imgWidth = 200 / 2;
  }
  if (Number(width) < 576 / 2 || Number(height) < 576 / 2) {
    imgWidth = 200 / 2;
  }
  if (Number(width) < 400 / 2 || Number(height) < 400 / 2) {
    imgWidth = 100 / 2;
  }
  if (Number(width) < 200 / 2 || Number(height) < 200 / 2) {
    imgWidth = 80 / 2;
  }
  if (Number(width) < 100 / 2 || Number(height) < 100 / 2) {
    imgWidth = 50 / 2;
  }
  if (Number(width) < 60 / 2 || Number(height) < 60 / 2) {
    imgWidth = 40 / 2;
  }
  return imgWidth;
};

export const weatherPreview = (item, key) => {
  const { height, width } = item;
  const { location, days } = key;
  const fontSize = getFontSize(width, height);
  const forecastFontSize = fontSize * 0.15;
  const forecastFontSizeDay = fontSize * 0.105;
  const forecastFontSizeLoc = fontSize * 0.105;
  const imgWidth = getImgWidth(width, height);
  const rowHeight = getRowHeight(height);
  const { weatherData } = key.weather;
  const forecastImgWidth = width < 90 || height < 90 ? 0.2 * imgWidth : "";
  const temprData = weatherData.map(data => {
    switch (key.unit) {
      case "celsius":
        return {
          unit: "C",
          temp: getCelsius(data.main.temp),
          minTemp: getCelsius(data.main.tempMin),
          maxTemp: getCelsius(data.main.tempMax),
          icon: data.weather[0].icon,
          ...getDateDay(data.dt)
        };
      case "fahrenheit":
        return {
          unit: "F",
          temp: getFahrenhiet(data.main.temp),
          minTemp: getFahrenhiet(data.main.tempMin),
          maxTemp: getFahrenhiet(data.main.tempMax),
          icon: data.weather[0].icon,
          ...getDateDay(data.dt)
        };
      default:
        return {
          unit: "F",
          temp: getFahrenhiet(data.main.temp),
          minTemp: getFahrenhiet(data.main.tempMin),
          maxTemp: getFahrenhiet(data.main.tempMax),
          icon: data.weather[0].icon,
          ...getDateDay(data.dt)
        };
    }
  });

  let rData = {
    fontSize,
    imgWidth,
    rowHeight,
    temprData,
    location,
    days,
    forecastFontSize,
    forecastFontSizeDay,
    forecastFontSizeLoc,
    forecastImgWidth
  };

  const orientation = getOrientation(width, height);

  switch (orientation) {
    case "verticalLong":
      rData = {
        ...rData,
        forecastFontSize: rData.fontSize * 0.32,
        forecastFontSizeDay: rData.fontSize * 0.19,
        forecastFontSizeLoc: rData.fontSize * 0.19
      };
      return verticalLongTemplate(rData);
    case "horizontalLong":
      rData = {
        ...rData,
        forecastFontSize: rData.fontSize * 0.27,
        forecastFontSizeDay: rData.fontSize * 0.21,
        forecastFontSizeLoc: rData.fontSize * 0.21
      };
      return horizontalLongTemplate(rData);
    case "especialCase":
      rData = {
        ...rData,
        fontSize: rData.fontSize * 0.7
      };
      return standardTemplate(rData);
    default:
      return standardTemplate(rData);
  }
  if (Number(width) >= 200 && Number(height) >= 200) {
    // standard template
    return standardTemplate(rData);
  }
  if (Number(height) / Number(width) >= 1) {
    // height is greater
    return verticalLongTemplate(rData);
  }
  if (Number(height) / Number(width) < 1) {
    // width is greater
    return horizontalLongTemplate(rData);
  }
  // same height and width
};
