import { Map } from "../../helper/routeMap";

/** Network */
import Network from "./network";

import NoRoutes, { UpcomingFeatures } from "../../core/layout/noroutes";
import NetworkDetails from "./pages/networkDetails";
import AddGroupTable from "./modules/addGroupTable";
import SSView from "./pages/SSView";

const mainPath = "/networks";
const pathCreator = `${mainPath}`;
// const subPathCreator = subPath => `${pathCreator}${subPath}`;
const feature = "Network";

export default [
  {
    path: `${pathCreator}`,
    component: Map(Network),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/screen_shot_view_all`,
    component: Map(SSView),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/screen_shot_view/:id`,
    component: Map(SSView),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/new_group`,
    component: Map(AddGroupTable),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/group/edit/:id`,
    component: Map(AddGroupTable),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/device/:id`,
    component: Map(NetworkDetails),
    exact: true,
    secured: true,
    feature
  },

  {
    path: `${pathCreator}/upcoming`,
    component: Map(UpcomingFeatures),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/*`,
    component: Map(NoRoutes),
    exact: true,
    secured: true,
    feature
  }
];
