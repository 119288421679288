/* eslint-disable no-useless-return */
/* eslint-disable camelcase */
import React, { Component } from "react";
import {
  Label,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardTitle,
  CardText
} from "reactstrap";
import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  reduxForm,
  initialize as remoteInitialize,
  SubmissionError
} from "redux-form";
import { CircularProgressbar } from "react-circular-progressbar";
import { ButtonGroup, Dropdown, Input } from "semantic-ui-react";

import classnames from "classnames";
import { Tooltip, RangeSlider } from "../../../core";
import { alphaNumeric } from "../../../helper/validation";

// Local Import
import { DeviceResources, Resources, RS232BodyGenerator } from "../redux";
import { Resources as campaignResources } from "../../campaign/redux/campaignRedux";
import { Resources as calendarResources } from "../../calendar/redux/calendarRedux";
import { Oval } from "../../../core/modal/loader";
import TVHEXCODE from "../../../constant/tvHex";
import { UpdateDeviceInformationForm } from "../forms";
import { MonitorControl } from "../modules/RS232Control";
import VolumeControlFrom from "../forms/volumeControlFrom";

// CSS Import
import "react-circular-progressbar/dist/styles.css";
import HostNameForm from "../forms/hostNameForm";
import TimeZoneForm from "../forms/timeZoneForm";
import ResolutionForm from "../forms/deviceResolutionForm";
import OrientationControlForm from "../forms/orientationControlForm";
import { timeZones } from "../../../constant/timeZoneNUCs";
import { resolutions } from "../../../constant/resolution";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { isEmpty } from "lodash";
import DeviceInformation from "./deviceInformation";
import DeviceDetailForm from "../forms/deviceDetailForm";
import DeviceControl from "../modules/deviceControl";
import Metrics from "../modules/metrics";
import { getUrlParameter } from "../../../helper/formatter";

class NetworkDetails extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      restartDev: {
        active: false
      },
      stopAndReboot: { active: false },
      sendMedia: { active: false },
      restartApp: {
        active: false
      },
      changeEnvironment: { active: false },
      stopPlayingContent: { active: false },
      updateSoftware: {
        active: false
      }
    };
    this.state = {
      modal: false,
      setEnvironment: false,
      restartApp: false,
      updateSoftware: false,
      stopPlayingContent: false,
      stopAndReboot: false,
      newDataArrived: false,
      isRS232ControlSupported: undefined,
      softwareUpdateProcessIsWaiting: false,
      sendingEnvironmentInstruction: false,
      sendingVersion: false,
      restartingMPS: false,
      restartingDevice: false,
      electronVersion: "Loading...",
      tagHasError: undefined,
      activeTab: "1",
      buttonState: this.initialState,
      isScreenShotLoading: false,
      isSendingMediaToDevice: false,
      isSendingPopRequest: false
    };
    this.loadNetworkDetail(this.props.match.params.id);
    const groupView = getUrlParameter("view");
    this.props.setUserSetting("deviceListView", groupView);
  }

  loadNetworkDetail = deviceID => {
    DeviceResources.getDeviceDetails.url = `/devices/${deviceID}`;
    this.props.Get(DeviceResources.getDeviceDetails).then(() => {
      this.loadFormData();
      this.checkIfThisDeviceSupportRS232(deviceID);
      this.loadDeviceDiskUsage(deviceID);
      // this.loadElectronVersion();
      this.handleLoadAllTags();
      this.getCampaignAndCalendar();
      this.setState({ newDataArrived: true });
    });
  };

  componentDidMount = () => {
    if (
      this.props.history.location.state &&
      this.props.history.location.state.fromNotification
    ) {
      this.toggleTabs("3");
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.history.location.state &&
      this.props.history.location.state.fromNotification &&
      prevProps.location.key !== this.props.location.key
    ) {
      this.loadNetworkDetail(this.props.location.state.deviceID);
    }
  }

  toggleTabs = tab => {
    this.setState({ activeTab: tab });
  };

  toggleButtons = (name, flag) => {
    this.setState(state => ({
      buttonState: {
        ...this.initialState,
        [name]: {
          active: flag
        }
      }
    }));
  };

  /** Wil clear state error */
  clearError = () => {
    this.setState(() => ({ tagHasError: undefined }));
  };

  /** Initialize Network data */
  loadFormData = () => {
    const {
      attributes: {
        volume,
        hostname,
        timezone,
        resolution,
        orientation,
        block_pop
      }
    } = this.props;
    this.props.remoteInitialize("hostNameForm", { hostname });
    this.props.remoteInitialize("volumeControlForm", { volume });

    this.props.remoteInitialize("timezoneForm", {
      timeZone: timeZones.filter(item => item.value === timezone)
    });
    this.props.remoteInitialize("resolutionChangeForm", {
      resolution: resolutions.filter(item => item.resolution === resolution)
    });
    this.props.remoteInitialize("deviceOrientationForm", { orientation });
    this.props.remoteInitialize("DeviceControlForm", {
      proofOfPlay: block_pop
    });
  };

  getCampaignAndCalendar = () => {
    const { getTotalCampaign } = campaignResources;
    getTotalCampaign.url = `/campaigns?page=1&per_page=20`;
    this.props.Get(campaignResources.getTotalCampaign);
    const { getTotalCalendar } = calendarResources;
    getTotalCalendar.url = `/calendars?page=1&per_page=20`;
    this.props.Get(calendarResources.getTotalCalendar);
  };

  /** Trigger RS232 command */
  triggerRS232Command = code => {
    const options = DeviceResources.MonitorControlCommand(this.props.id);
    options.body = RS232BodyGenerator(TVHEXCODE[code]);
    this.props.Post(options);
  };

  restartDevice = () => {
    this.toggleButtons("restartDev", false);
    const { restartDevice } = DeviceResources;
    this.setState({ restartingDevice: true });
    if (this.state.stopAndReboot) {
      const { stopDevice } = DeviceResources;
      this.props
        .Get(stopDevice(this.props.id))
        .then(() => this.props.Get(restartDevice(this.props.id)))
        .then(() =>
          this.setState({ restartingDevice: false, stopAndReboot: false })
        );
    } else {
      this.props
        .Get(restartDevice(this.props.id))
        .then(() => this.setState({ restartingDevice: false }));
    }
  };

  stopPlayingDeviceContent = () => {
    this.toggleButtons("stopPlayingContent", false);
    this.setState({ stopPlayingContent: true });
    const { stopDevice } = DeviceResources;
    this.props
      .Get(stopDevice(this.props.id))
      .then(() => this.setState({ stopPlayingContent: false }));
  };

  restartElectronClient = () => {
    this.toggleButtons("restartApp", false);
    const { restartElectronClient } = DeviceResources;

    this.setState({ restartingMPS: true });
    this.props
      .Get(restartElectronClient(this.props.id))
      .then(() => this.setState({ restartingMPS: false }));
  };

  /** Server call to change orientation */
  changeOrientation = orientation => {
    const { setDeviceOrientation } = Resources;
    this.props.Get(setDeviceOrientation(this.props.id, orientation));
  };

  /** Get screenshot */
  getScreenshot = () => {
    this.setState({ isScreenShotLoading: true });
    const { getDeviceSS } = DeviceResources;
    this.props
      .Get(getDeviceSS(this.props.id))
      .then(() => this.setState({ isScreenShotLoading: false }));
  };

  /** handle device update form submission */
  handleDeviceUpdate = async values => {
    values.tags.map(item => {
      if (alphaNumeric(item.value)) {
        this.setState({ tagHasError: alphaNumeric(item.value) });
        throw new SubmissionError({
          tags: "Tag has Error"
        });
      }
    });

    if (!this.state.tagHasError) {
      const attributes = {
        name: values.name,
        asset_id: values.assetId,
        loc: [values.longitude || undefined, values.latitude || undefined],
        tags: values.tags.map(item => item.value)
      };
      const { putNetwork } = Resources;
      putNetwork.url = `/devices/${this.props.id}`;
      putNetwork.body = this.props.requestFormatter("devices", attributes);
      await this.props.Put(putNetwork);
    }
  };

  /** Volume Save function */
  handleVolumeSave = async values => {
    const { setDeviceVolume } = DeviceResources;
    return this.props.Get(setDeviceVolume(this.props.id, values.volume));
  };

  /** New Tag addition Options */
  handleNewTag = (inputValue, selectValue, selectOptions) => {
    // console.error(inputValue, selectValue, selectOptions);
  };

  /** Check If device support RS232. & if Support then just show the UI. */
  checkIfThisDeviceSupportRS232 = deviceID => {
    const { checkMonitorSupport } = DeviceResources;
    this.props.Get(checkMonitorSupport(deviceID)).then(result => {
      if (result.status === 200) {
        this.setState({
          isRS232ControlSupported: true
        });
      } else if (result.status === 412) {
        this.setState({
          isRS232ControlSupported: false
        });
      } else {
        this.setState({
          isRS232ControlSupported: "error"
        });
      }
    });
  };

  /** Handle time zone changes */
  handleTimeZoneChange = async values => {
    const { setDeviceTimeZone } = DeviceResources;
    await this.props.Get(
      setDeviceTimeZone(this.props.id, values.timeZone.value)
      // .then(() => {
      //   this.props.remoteInitialize("timezoneForm", []);
      // })
    );
  };

  handleHostNameChange = async value => {
    const { setDeviceHostName } = DeviceResources;
    await this.props
      .Get(setDeviceHostName(this.props.id, value.hostname))
      // .then(() => this.props.remoteInitialize("hostNameForm", {}))
      .then(() => {
        this.loadFormData();
        this.setState({ newDataArrived: true });
      });
  };

  /** Handle resolution changes */
  handleResolutionChange = async values => {
    const { setDeviceResolution } = DeviceResources;
    await this.props.Get(
      setDeviceResolution(this.props.id, values.resolution.resolution)
      // .then(
      //   () => {
      //     this.props.remoteInitialize("resolutionChangeForm", []);
      //   }
      // )
    );
  };

  loadDeviceDiskUsage = async deviceID => {
    const { getDiskUsages } = DeviceResources;
    await this.props.Get(getDiskUsages(deviceID));
  };

  handleLoadAllTags = () => {
    const { getElectronTags, getLegacyElectronTags } = Resources;
    this.props.Get(getElectronTags);
    // this.props.Get(getLegacyElectronTags);
  };

  /** Handle orientation change */
  handleOrientationChange = async value => {
    const { setDeviceOrientation } = DeviceResources;
    await this.props
      .Get(setDeviceOrientation(this.props.id, value.orientation))
      // .then(() => {
      //   this.props.remoteInitialize("deviceOrientationForm", {});
      // })
      .then(() => {
        this.loadFormData();
        this.setState({ newDataArrived: true });
      });
  };

  /** load electron version */
  loadElectronVersion = async () => {
    const { getElectronVersion } = DeviceResources;

    this.props
      .Get(getElectronVersion(this.props.match.params.id))
      .then(result => {
        if (result.status === 200) {
          const {
            data: {
              data: {
                attributes: { electron_version }
              }
            }
          } = result;
          this.setState({ electronVersion: electron_version });
        } else {
          this.setState({ electronVersion: "error" });
        }
      });
  };

  /** Handle Device Software Update */
  sendUpdateSoftwareToDevice = tag => {
    // this.toggleUpdateSoftware();
    this.toggleButtons("updateSoftware", false);

    const { sendLatestElectronAppToDevice } = DeviceResources;
    const { electronTag } = tag;
    this.setState({ sendingVersion: true });
    this.props
      .Get(sendLatestElectronAppToDevice(this.props.id, electronTag.value))
      .then(() => {
        this.setState({ sendingVersion: false });
      })
      .then(() =>
        this.props.remoteInitialize("DeviceControlForm", {
          electronTag: [],
          electronEnv: [],
          media: []
        })
      );
  };

  // For Legacy update
  sendLegacyUpdateSoftwareToDevice = tag => {
    // this.toggleUpdateSoftware();
    this.toggleButtons("updateSoftware", false);

    const { sendLegacyElectronAppToDevice } = DeviceResources;
    const { electronTag } = tag;
    this.setState({ sendingVersion: true });
    this.props
      .Get(sendLegacyElectronAppToDevice(this.props.id, electronTag.value))
      .then(() => {
        this.setState({ sendingVersion: false });
      })
      .then(() =>
        this.props.remoteInitialize("DeviceControlForm", {
          electronTag: [],
          electronEnv: [],
          media: []
        })
      );
  };

  sendElectronAppEnvironment = values => {
    this.toggleButtons("changeEnvironment", false);

    const { electronEnv } = values;
    const { sendElectronAppEnvironment } = DeviceResources;

    this.setState({ sendingEnvironmentInstruction: true });
    this.props
      .Get(sendElectronAppEnvironment(this.props.id, electronEnv.value))
      .then(() => {
        this.setState({ sendingEnvironmentInstruction: false });
        // this.loadElectronVersion();
      })
      .then(() =>
        this.props.remoteInitialize("DeviceControlForm", {
          electronTag: [],
          electronEnv: [],
          media: []
        })
      );
  };

  sendMediaToDevice = (values, type) => {
    this.toggleButtons("sendMedia", false);
    this.setState({ isSendingMediaToDevice: true });
    const { playCampaignInNetwork, playCalendarInNetwork } = Resources;
    const request =
      type === "campaign"
        ? playCampaignInNetwork(values.media.value, "run")
        : playCalendarInNetwork(values.media.value, "run");
    request.body = JSON.stringify({
      _jsonapi: {
        data: {
          type,
          attributes: {
            device_ids: [this.props.id]
          }
        }
      }
    });
    this.props
      .Post(request)
      .then(() => this.setState({ isSendingMediaToDevice: false }))
      .then(() =>
        this.props.remoteInitialize("DeviceControlForm", {
          media: []
        })
      );
  };

  /** Calculate Disk Space */
  calculateDiskSpace = (total, free) =>
    (100.0 - (free / total) * 100).toFixed(1);

  handleStopAndReboot = () => {
    this.setState(state => ({ stopAndReboot: !state.stopAndReboot }));
  };

  handleProofOfPlay = value => {
    this.setState({ isSendingPopRequest: true });
    this.props.remoteInitialize("DeviceControlForm", {
      proofOfPlay: value
    });
    const request = DeviceResources.singleDevicePopOnOff;
    request.url = `/devices/${this.props.id}/update_pop`;
    request.body = request.bodyFunction(value);
    this.props
      .Post(request)
      .then(() => this.setState({ isSendingPopRequest: false }));
  };

  render() {
    const { hasList, selectedTags, selectedEnv } = this.props;
    const { newDataArrived } = this.state;
    if ((!hasList && !newDataArrived) || !newDataArrived) {
      return (
        <div className="d-block text-center">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    const {
      attributes: {
        hostname,
        mac_address,
        asset_id,
        loc,
        calendar_name,
        calendar_id,
        campaign_name,
        campaign_id,
        orientation,
        resolution,
        status,
        volume,
        total_space,
        available_space,
        electron_version,
        electron_environment
      },
      primaryMethod
    } = this.props;
    const { activeTab } = this.state;
    return (
      <div className="mainPage">
        <div className="contentSection">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggleTabs("1");
                }}
              >
                Information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  this.toggleTabs("2");
                }}
              >
                Device Administration
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  this.toggleTabs("3");
                }}
              >
                Metrics
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="networkDetail__info row">
                <div className="col-md-4">
                  {" "}
                  <div className="networkDetail__info__leftSection">
                    {" "}
                    <div className="networkDetail__devicescreenshot network-screen">
                      <img
                        src={
                          this.props.attributes.s3_screenshot_url
                            ? this.props.attributes.s3_screenshot_url
                            : require("../../../assets/images/no-image.png")
                        }
                        alt="screenshot"
                        style={
                          this.props.orientation !== "landscape"
                            ? {
                                height: "auto",
                                margin: "0 auto",
                                display: "table"
                              }
                            : { height: "300px" }
                        }
                        className="network-screen__img"
                      />
                    </div>
                    <Button
                      type="button"
                      className="btn-outline-primary networkDetail__buttonSection"
                      onClick={this.getScreenshot}
                      disabled={this.state.isScreenShotLoading}
                    >
                      Take Screenshot
                    </Button>
                  </div>
                </div>
                <div className="col-md-8">
                  <DeviceDetailForm
                    handleDeviceUpdate={this.handleDeviceUpdate}
                    electronVersion={this.state.electronVersion}
                    tagHasError={this.state.tagHasError}
                    {...this.props}
                  />

                  <DeviceInformation
                    calculateDiskSpace={this.calculateDiskSpace}
                    totalSpace={total_space}
                    availableSpace={available_space}
                    {...this.props}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="networkDetail__info row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <Card
                        body
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <div>
                          <div className="campaignDetail__information campaignDetail__information--border">
                            Device Control
                          </div>
                          <HostNameForm
                            handleHostNameChange={this.handleHostNameChange}
                          />
                          <TimeZoneForm
                            handleTimeZoneChange={this.handleTimeZoneChange}
                          />
                          <ResolutionForm
                            handleResolutionChange={this.handleResolutionChange}
                          />
                          <VolumeControlFrom
                            handleVolumeSave={this.handleVolumeSave}
                            volume={volume}
                          />
                          <OrientationControlForm
                            handleOrientationChange={
                              this.handleOrientationChange
                            }
                          />
                          <DeviceControl
                            toggle={this.toggle}
                            modal={this.state.modal}
                            toggleButtons={this.toggleButtons}
                            buttonState={this.state.buttonState}
                            setEnvironment={this.state.setEnvironment}
                            toggleChangeEnvironment={
                              this.toggleChangeEnvironment
                            }
                            restartApp={this.state.restartApp}
                            stopPlayingContent={this.state.stopPlayingContent}
                            stopPlayingDeviceContent={
                              this.stopPlayingDeviceContent
                            }
                            isSendingMediaToDevice={
                              this.state.isSendingMediaToDevice
                            }
                            updateSoftware={this.state.updateSoftware}
                            toggleRestartApp={this.toggleRestartApp}
                            toggleUpdateSoftware={this.toggleUpdateSoftware}
                            environmentLabel={selectedTags}
                            stopAndReboot={this.state.stopAndReboot}
                            handleStopAndReboot={this.handleStopAndReboot}
                            restartDevice={this.restartDevice}
                            restartingDevice={this.state.restartingDevice}
                            sendingEnvironmentInstruction={
                              this.state.sendingEnvironmentInstruction
                            }
                            sendingVersion={this.state.sendingVersion}
                            restartingMPS={this.state.restartingMPS}
                            restartElectronClient={this.restartElectronClient}
                            selectedTags={this.props.selectedTags}
                            selectedEnv={this.props.selectedEnv}
                            selectedMedia={this.props.selectedMedia}
                            softwareUpdateProcessIsWaiting={
                              this.state.softwareUpdateProcessIsWaiting
                            }
                            sendElectronAppEnvironment={
                              this.sendElectronAppEnvironment
                            }
                            sendUpdateSoftwareToDevice={
                              this.sendUpdateSoftwareToDevice
                            }
                            sendUpdateSoftwareToDeviceLegacy={
                              this.sendLegacyUpdateSoftwareToDevice
                            }
                            sendMediaToDevice={this.sendMediaToDevice}
                            handleProofOfPlay={this.handleProofOfPlay}
                            popValue={this.props.popValue}
                            isSendingPopRequest={this.state.isSendingPopRequest}
                            {...this.props}
                          />
                        </div>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        body
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <div>
                          <span className="campaignDetail__information campaignDetail__information--border">
                            Monitor Control
                          </span>
                          {this.state.isRS232ControlSupported === undefined && (
                            <div className="d-flex justify-content-center align-item-center">
                              <p>Please Wait... Loading device status.</p>
                            </div>
                          )}
                          {this.state.isRS232ControlSupported === true && (
                            <MonitorControl
                              triggerRS232Command={this.triggerRS232Command}
                            />
                          )}
                          {this.state.isRS232ControlSupported === false && (
                            <div className="d-flex justify-content-center align-item-center">
                              <p>
                                Monitor control commands are not supported for
                                this device
                              </p>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">
              {this.state.activeTab == 3 && (
                <Metrics
                  deviceId={this.props.id}
                  Get={this.props.Get}
                  history={this.props.history}
                  location={this.props.location}
                />
              )}
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { list, hasList, diskUsages } = state.deviceDetails;
  const { tags } = state.electronTags.list || [];
  const { tags: legacyTags } = state.legacyElectronTags.list || [];
  // const legacyTags = state.legacyElectronTags.list
  //   ? state.legacyElectronTags.list
  //   : [];
  const selector = formValueSelector("DeviceControlForm");

  return {
    ...(list || []),
    diskUsages: diskUsages || undefined,
    hasList,
    tagOptions: tags || [],
    legacyTagOptions: legacyTags || [],
    envOptions: ["Production", "Staging", "Development"],
    selectedTags: selector(state, "electronTag"),
    selectedEnv: selector(state, "electronEnv"),
    selectedMedia: selector(state, "media"),
    popValue: selector(state, "proofOfPlay")
  };
}
export default connect(
  mapStateToProps,
  { remoteInitialize }
)(NetworkDetails);
