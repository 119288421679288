import React, { Component } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { remove, differenceBy } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import overlayFactory from "react-bootstrap-table2-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import { Link } from "react-router-dom";

import { columns } from "../utils/ticketUtils";
import TicketHeader from "./ticketHeader";

// eslint-disable-next-line react/prefer-stateless-function
class TicketTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        booking_name: false,
        customer_name: false,
        booking_dates: false,
        screen_time: false,
        share_of_voice: false,
        last_updated: false,
        ticket_status: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    // const selectRow = {
    //   mode: "checkbox",
    //   selectColumnStyle: { width: "40px" },
    //   selectionHeaderRenderer: ({ mode, ...rest }) => (
    //     <label className="contentSection__checkBoxWrapper">
    //       <input type={mode} {...rest} />
    //       <span className="checkmark" />
    //     </label>
    //   ),
    //   selectionRenderer: ({ mode, ...rest }) => (
    //     <label className="contentSection__checkBoxWrapper">
    //       <input type={mode} {...rest} />
    //       <span className="checkmark" />
    //     </label>
    //   ),
    //   onSelect: (row, isSelect) => {
    //     if (isSelect) {
    //       this.props.change("ticketSelected", [
    //         ...this.props.ticketSelected,
    //         row
    //       ]);
    //     } else {
    //       const { ticketSelected } = this.props;
    //       remove(ticketSelected, { id: row.id });
    //       this.props.change("ticketSelected", ticketSelected);
    //     }
    //   },
    //   onSelectAll: (isSelect, rows) => {
    //     if (isSelect) {
    //       this.props.change("ticketSelected", [
    //         ...this.props.ticketSelected,
    //         ...rows
    //       ]);
    //     } else {
    //       const { ticketSelected } = this.props;
    //       const formattedTicket = differenceBy(ticketSelected, rows, "id");
    //       this.props.change("ticketSelected", formattedTicket);
    //     }
    //   }
    // };

    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.ticketList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleSort,
            this.props.sortOrder,
            this.state.hiddenColumns
          )}
          columnToggle
        >
          {props => (
            <div>
              <TicketHeader
                refreshing={this.props.refreshing}
                handleRefresh={this.props.handleRefresh}
                handleStatusFilter={this.props.handleStatusFilter}
                handleFilter={this.props.handleFilter}
                handleSearch={this.props.handleSearch}
                handleFilterWithStatus={this.props.handleFilterWithStatus}
                // below props are needed for table col toggler
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                {...this.props}
              />
              <BootstrapTable
                classes="contentSectionTable ticketTable"
                hover
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                // selectRow={selectRow}
                rowEvents={{
                  onClick: (e, row) => {
                    e.stopPropagation();
                    this.props.history.push(`/tickets/show/${row.id}/`);
                  },
                  style: {
                    cursor: "pointer"
                  }
                }}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
export default connect(state => {
  const selector = formValueSelector("SelectedTickets");
  return {
    ticketSelected: selector(state, "ticketSelected") || []
  };
})(
  reduxForm({
    form: "SelectedTickets"
  })(TicketTable)
);
