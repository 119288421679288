/* eslint-disable camelcase */
import React, { Component } from "react";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import {
  Table,
  Card,
  Form,
  FormGroup,
  Button,
  Label,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { showNotification } from "../../../core/modal/toster";
import { Resources } from "../../campaign/redux";
// import { Resources as DomainResources } from "../redux/domainRedux";

import { MultipleSelect } from "../../../core/form/searchSelect";
import DefaultCampaignTable from "./defaultCampaignTable";
import SelectAsync from "../../../core/form/selectAsyncClass";

class DefaultCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: [],
      isLoading: true,
      isDisabled: false,
      defaultCampaign: false
    };
    // this.loadAllCampaignList();
    this.loadDefaultCampaign();
  }

  toggleDefaultCampaign = flag => {
    this.setState({ defaultCampaign: flag });
  };

  loadAllCampaignList = () => {
    Resources.getCampaign.url = `/campaigns`;
    this.props.Get(Resources.getCampaign);
  };

  handleSubmit = campaign => {
    this.setState({ isDisabled: true });
    const { value } = campaign.default_campaign;
    const { setDefaultCampaign } = Resources;
    this.props
      .Get(setDefaultCampaign(value))
      .then(result => {
        if (result.status === 200) {
          this.props.showNotification(
            "Default Campaign Set Successfully",
            "success"
          );
        } else {
          this.props.showNotification("Couldn't set default campaign", "error");
        }
      })
      .then(() => this.props.change("default_campaign", []))
      .then(() => this.loadDefaultCampaign())
      .then(() => this.setState({ isDisabled: false, defaultCampaign: false }));
  };

  loadDefaultCampaign = () => {
    const { getDefaultCampaign } = Resources;
    this.props
      .Get(getDefaultCampaign())
      .then(result => {
        if (result.status === 200) {
          const {
            data: { _id, name, created_at, status, orientation }
          } = result;
          this.setState({
            campaign: [
              {
                id: _id.$oid,
                name,
                created_at,
                status,
                orientation
              }
            ]
          });
        } else if (result.status === 450) {
          this.setState({
            campaign: []
          });
        }
      })
      .then(() => this.setState({ isLoading: false }));
  };

  render() {
    const {
      id,
      name,
      created_at,
      status,
      orientation,
      isLoading,
      isDisabled
    } = this.state;
    if (isLoading) {
      return (
        <div className="d-block text-center">
          {/* <div className="lds-dual-ring" /> */}
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    return (
      <div>
        <div
          className="d-flex contentHeader"
          data-spy="affix"
          data-offset-top="197"
        >
          <Button
            className="primaryButton primaryButton--contentHeader"
            onClick={() => this.toggleDefaultCampaign(true)}
            type="button"
          >
            <i className="fa fa-plus mr-2" />
            Default Campaign
          </Button>
          <Modal
            isOpen={this.state.defaultCampaign}
            toggle={() => this.toggleDefaultCampaign(false)}
          >
            <ModalHeader toggle={() => this.toggleDefaultCampaign(false)}>
              Set Default Campaign
            </ModalHeader>
            <ModalBody>
              <Form>
                <div className="globalForm">
                  <FormGroup>
                    <Label>Default campaign</Label>
                    <Field
                      component={SelectAsync}
                      name="default_campaign"
                      currentDefaultCampaign={
                        this.state.campaign[0] && this.state.campaign[0].id
                      }
                      isClearable
                    />
                  </FormGroup>
                </div>
              </Form>
            </ModalBody>
            <ModalFooter>
              <button
                onClick={() => this.toggleDefaultCampaign(false)}
                className="btn-outline-primary"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={this.props.handleSubmit(values =>
                  this.handleSubmit(values)
                )}
                className="primaryButton mt-0"
                disabled={this.props.selectedCampaign.length <= 0 || isDisabled}
              >
                Set as Default
              </button>
            </ModalFooter>
          </Modal>
        </div>

        {isDisabled && (
          <div>
            <div
              id="notification_toast-flash"
              className="notification_toast info animated fadeInUp"
            >
              <p>
                Sending default campaign to all devices{" "}
                <i className="fa fa-spinner fa-spin" />
              </p>
            </div>
          </div>
        )}

        <DefaultCampaignTable
          defaultCampaign={this.state.campaign ? this.state.campaign : []}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list } = state.totalCampaign;
  const selector = formValueSelector("DefaultCampaignForm");
  return {
    campaignList: list !== undefined ? list : [],
    selectedCampaign: selector(state, "default_campaign") || []
  };
}

export default connect(
  mapStateToProps,
  { showNotification, change }
)(
  reduxForm({
    form: "DefaultCampaignForm"
  })(DefaultCampaign)
);
