/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { Link } from "react-router-dom";
// header block is the storymodule to be use in the all files.
// shift it into the storybook later on.

export default class HeaderBlock extends Component {
  state = {};

  render() {
    return (
      <div className="contentheader">
        <div className="d-flex justify-content-end contentbutton">
          <span className="d-flex justify-content-end action">
            <Link
              to={`/dashboard/content/edit/${this.props.id}`}
              className="action__edit"
            >
              <i className="fa fa-edit" />
            </Link>
            <i
              onClick={() => this.props.handleDelete(this.props.id)}
              className="fa fa-trash action__trash"
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      </div>
    );
  }
}
