import normalize from "json-api-normalizer";

export const generateCreatePlaylistData = (
  playlistContents,
  state,
  id = undefined
) => ({
  _jsonapi: {
    data: {
      type: "playlists",
      attributes: {
        id,
        name: state.playlistTitle || "Untitled Playlist",
        playlist_controls: JSON.stringify(state.playlistControl),
        random: state.random,
        pop_one: state.popOne,
        playlist_contents_attributes: playlistContents
      }
    }
  }
});

export const updatePlaylistLockedAttribute = (id, flag) => ({
  _jsonapi: {
    data: {
      type: "playlists",
      attributes: {
        id,
        locked: flag
      }
    }
  }
});
export const postArchivedItems = ({ filteredPlaylistId }) => ({
  _jsonapi: {
    data: {
      type: "playlist",
      attributes: {
        playlist_ids: [...filteredPlaylistId]
      }
    }
  }
});
export const unArchivePlaylists = ({ filteredPlaylistId }) => ({
  _jsonapi: {
    data: {
      type: "content",
      attributes: {
        playlist_ids: [...filteredPlaylistId]
      }
    }
  }
});

export const removeSingleArchivedPlaylist = id => ({
  _jsonapi: {
    data: {
      type: "playlist",
      attributes: {
        playlist_ids: [id]
      }
    }
  }
});

export const Resources = {
  getPlaylist: {
    url: "/playlists",
    asyncActions: {
      init: "getPlaylist_INIT",
      success: "getPlaylist_SUCCESS",
      error: "getPlaylist_ERROR"
    },
    syncActions: {
      updateList: "updatePlaylist"
    }
  },
  showPlaylist: {
    url: `/playlists/id`,
    asyncActions: {
      init: "showPlaylist_INIT",
      success: "showPlaylist_SUCCESS",
      error: "showPlaylist_ERROR"
    }
  },
  createPlaylist: {
    url: "/playlists",
    asyncActions: {
      init: "createPlaylist_INIT",
      success: "createPlaylist_SUCCESS",
      error: "createPlaylist_ERROR",
      errorMsg: "Couldn't create. Please try again later."
    }
  },
  getArchivedPlaylist: {
    url: "/playlists/archive",
    asyncActions: {
      init: "getArchivedPlaylist_INIT",
      success: "getArchivedPlaylist_SUCCESS",
      error: "getArchivedPlaylist_ERROR"
    }
  },
  unArchivePlaylist: {
    url: "/playlists/unarchive",
    asyncActions: {
      init: "unArchivePlaylist_INIT",
      success: "unArchivePlaylist_SUCCESS",
      error: "unArchivePlaylist_ERROR"
    }
  },
  postArchivePlaylist: {
    url: "playlists/bulk_archive",
    asyncActions: {
      init: "postArchivePlaylist_INIT",
      success: "postArchivePlaylist_SUCCESS",
      error: "postArchivePlaylist_ERROR"
    }
  },
  removeBulkPlaylist: {
    url: "playlists/bulk_destroy",
    asyncActions: {
      init: "removeBulkPlaylist_INIT",
      success: "removeBulkPlaylist_SUCCESS",
      error: "removeBulkPlaylist_ERROR"
    }
  },
  putPlaylist: {
    url: "/playlists",
    asyncActions: {
      init: "updatePlaylist_INIT",
      success: "updatePlaylist_SUCCESS",
      error: "updatePlaylist_ERROR"
    }
  },
  lockUnlockPlaylist: {
    url: "/playlists/id/locked_update",
    asyncActions: {
      init: "lockUnlockPlaylist_INIT",
      success: "lockUnlockPlaylist_SUCCESS",
      error: "lockUnlockPlaylist_ERROR"
    }
  },
  deletePlaylist: id => ({
    url: `/playlists/${id}`,
    asyncActions: {
      init: "deletePlaylist_INIT",
      success: "deletePlaylist_SUCCESS",
      error: "deletePlaylist_ERROR"
    }
  }),
  duplicatePlaylist: id => ({
    url: `/playlists/${id}/duplicate`,
    asyncActions: {
      init: "duplicatePlaylist_INIT",
      success: "duundefinedplicatePlaylist_SUCCESS",
      error: "duplicatePlaylist_ERROR",
      successMsg: "Playlist cloned successfully.",
      errorMsg: "Couldn't clone. Please try again later."
    }
  })
};

export function RemoveItemFromContentList(id) {
  const { updateList } = Resources.getPlaylist.syncActions;
  return (dispatch, getState) => {
    const { list } = getState().playlists;
    const newlist = list.filter(item => item.id !== id);
    dispatch({ type: updateList, payload: [...newlist] });
  };
}

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: true,
  hasError: undefined
};

export function playlists(state = initialState, action = {}) {
  const { init, success, error } = Resources.getPlaylist.asyncActions;
  const { updateList } = Resources.getPlaylist.syncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case updateList: {
      return {
        ...state,
        list: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
export function archivePlaylists(state = initialState, action = {}) {
  const { init, success, error } = Resources.getArchivedPlaylist.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}
export function playlistDetails(state = initialState, action = {}) {
  const { init, success, error } = Resources.showPlaylist.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { links, meta },
        status,
        statusText
      } = action.payload;
      const normalizedData = normalize(action.payload.data);
      return {
        list: normalizedData,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}
