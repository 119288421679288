import { Map } from "../../helper/routeMap";

/** Campaign feature Pages */
import Campaign from "./campaign";
import CampaignDetail from "./pages/campaignDetails";
import Studio from "./pages/campaignStudio";
import CreateCampaign from "./pages/createCampaign";
import ArchiveCampaign from "./pages/archiveCampaign";
import Defaultcampaign from "./pages/defaultCampaign";
import NoRoute, { UpcomingFeatures } from "../../core/layout/noroutes";
import DownloadCampaignForUSB from "./pages/downloadCampaing";

/** Path Configuration */
const mainPath = "/campaign";
const pathCreator = `${mainPath}`;
const subPathCreator = subPath => `${pathCreator}${subPath}`;
const feature = "Campaign";

/** Path Configuration End Here */

/**
 *
 * TODO: change this route with 404 pages
 */
export default [
  {
    path: subPathCreator("/prepare_usb/:id"),
    component: Map(DownloadCampaignForUSB),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/edit/:id"),
    component: Map(CreateCampaign),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/show/:id"),
    component: Map(CampaignDetail),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/create"),
    component: Map(CreateCampaign),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/archive"),
    component: Map(ArchiveCampaign),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/defaultcampaign"),
    component: Map(Defaultcampaign),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/studio"),
    component: Map(Studio),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}`,
    component: Map(Campaign),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/upcoming`,
    component: Map(UpcomingFeatures),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/*`,
    component: Map(NoRoute),
    exact: true,
    secured: true,
    feature
  }
];
