import React, { Component } from "react";
import { Link } from "react-router-dom";
import RouteAllowed from "../../helper/isRouteAllowed";

export default class HeaderAddButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDomain: JSON.parse(window.localStorage.getItem("domain"))
    };
  }

  checkPath = path => {
    if (
      path === "/dashboard/content" ||
      path.search("/dashboard/content/edit") >= 0 ||
      path.search("/dashboard/content/show") >= 0
    ) {
      // if (path === "/dashboard/content/create") {
      //   return undefined;
      // }
      // if (path === "/dashboard/content/upload") {
      //   return undefined;
      // }
      return "content";
    }
    if (
      path === "/dashboard/campaign" ||
      path.search("/dashboard/campaign/edit") >= 0 ||
      path.search("/dashboard/campaign/show") >= 0
    ) {
      return "campaign";
    }
    if (path === "/dashboard/userlist") {
      return "userlist";
    }
    if (path === "/dashboard/calendar") {
      return "calendar";
    }
    if (path === "/dashboard/playlist") {
      return "playlist";
    }
    if (path === "/dashboard/domain") {
      return "domain";
    }
  };

  render() {
    const { path } = this.props;
    const { roles } = this.props;
    const { permission } = this.props;
    if (this.checkPath(path) === "content") {
      return (
        <RouteAllowed roles={roles} permission={permission.content.creates}>
          <Link to="/dashboard/content/create">
            <button className="btn btn-primary" type="button">
              Add Content
            </button>
          </Link>
        </RouteAllowed>
      );
    }

    if (this.checkPath(path) === "campaign") {
      return (
        <RouteAllowed roles={roles} permission={permission.campaign.creates}>
          <Link to="/campaign/create">
            <button className="btn btn-primary" type="button">
              Create Campaign
            </button>
          </Link>
        </RouteAllowed>
      );
    }
    if (this.checkPath(path) === "userlist") {
      return (
        <RouteAllowed roles={roles} permission={permission.user.creates}>
          <Link to="/adduser">
            <button className="btn btn-primary" type="button">
              Add User
            </button>
          </Link>
        </RouteAllowed>
      );
    }
    if (this.checkPath(path) === "calendar") {
      return (
        <RouteAllowed roles={roles} permission={permission.calendar.creates}>
          <Link to="/dashboard/calendar/create">
            <button className="btn btn-primary" type="button">
              Create Calendar
            </button>
          </Link>
        </RouteAllowed>
      );
    }
    if (this.checkPath(path) === "playlist") {
      return (
        <RouteAllowed roles={roles} permission={permission.playlist.creates}>
          <Link to="/dashboard/playlist/create">
            <button className="btn btn-primary" type="button">
              Add PlayList
            </button>
          </Link>
        </RouteAllowed>
      );
    }
    if (
      this.checkPath(path) === "domain" &&
      this.state.currentDomain.name === "gurzu"
    ) {
      return (
        <RouteAllowed roles={roles} permission={permission.domain.creates}>
          <Link to="/dashboard/adddomain">
            <button className="btn btn-primary" type="button">
              Add Domain
            </button>
          </Link>
        </RouteAllowed>
      );
    }
    return null;
  }
}
