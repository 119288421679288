import React from "react";
import { Label, Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { timeZones } from "../../../constant/timeZoneNUCs";

function TimeZoneForm(props) {
  return (
    <div className="row mt-3">
      <div className="col-md-2 informationBlock">
        <Label className="mt-2"> Time Zone</Label>
      </div>
      <div className="col-md-10 informationTag">
        <Field
          component={MultipleSelect}
          name="timeZone"
          className="mr-0 mb-0"
          placeholder="Select time zone"
          options={timeZones}
        />
        <Button
          className="btn-info ml-2"
          color="info"
          onClick={props.handleSubmit(props.handleTimeZoneChange)}
          disabled={props.submitting || props.pristine}
        >
          {props.submitting ? "Saving" : "Save"}
        </Button>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "timezoneForm"
})(TimeZoneForm);
