import React, { Component } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import ArchiveContent from "./pages/archiveContent";
import { Get, Patch, Post, Delete } from "../../constant/thunk";
import ArchivePlaylist from "./pages/archivePlaylists";
import ArchiveCalendar from "./pages/archiveCalendar";
import ArchiveCampaign from "./pages/archiveCampaign";

class Archives extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "content"
    };
  }

  toggleTabs = activeTab => {
    this.setState({ activeTab });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <div className="mainPage">
        <div className="contentSection">
          {" "}
          <Nav tabs className="mb-3">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "content" })}
                onClick={() => {
                  this.toggleTabs("content");
                }}
              >
                Content
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "playlist" })}
                onClick={() => {
                  this.toggleTabs("playlist");
                }}
              >
                Playlist{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "campaign" })}
                onClick={() => {
                  this.toggleTabs("campaign");
                }}
              >
                Campaign{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "calendar" })}
                onClick={() => {
                  this.toggleTabs("calendar");
                }}
              >
                Calendar{" "}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="content">
              <ArchiveContent
                Get={this.props.Get}
                Post={this.props.Post}
                {...this.props}
              />
            </TabPane>
            <TabPane tabId="campaign">
              <ArchiveCampaign
                Get={this.props.Get}
                Post={this.props.Post}
                {...this.props}
              />
            </TabPane>
            <TabPane tabId="playlist">
              <ArchivePlaylist
                Get={this.props.Get}
                Post={this.props.Post}
                {...this.props}
              />
            </TabPane>

            <TabPane tabId="calendar">
              <ArchiveCalendar
                Get={this.props.Get}
                Post={this.props.Post}
                {...this.props}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({}),
  { Get, Post, Patch, Delete }
)(Archives);
