/** Import Node Modules */
import React, { Component } from "react";

/** Import Redux Library */
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import ReactPaginate from "react-paginate";
import { change } from "redux-form";
import { debounce } from "lodash";
import { Resources, RemoveItemFromContentList } from "./redux";

import {
  Resources as ContentResources,
  postArchivedItems
} from "./redux/contentRedux";
/** Import Local Components */

import ContentTable from "./modules/contentTable";
import { Dialog } from "../../core";
import { Logout } from "../auth/redux";
import EditContent from "./pages/editContent";
import GridContentView from "./modules/contentGridView";
import {
  ContentTagResources,
  SponserTagResources,
  PartyTagListResources,
  BrandTagListResources
} from "./redux";
class Contents extends Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;

    this.contentTableRefs = React.createRef();
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      content_type: { active: false, sortBy: "desc" },
      dimension: { active: false, sortBy: "desc" },
      duration: { active: false, sortBy: "desc" },
      size: { active: false, sortBy: "desc" },
      kind: { active: false, sortBy: "desc" },
      status: { active: false, sortBy: "desc" },
      created_at: { active: false, sortBy: "desc" },
      updated_at: { active: false, sortBy: "desc" }
    };
    this.state = {
      forcePage: undefined,
      refreshing: false,
      sortOrder: this.initialSortOrder,
      sortValue: { isSortable: false },
      filterType: { active: false, value: "" },
      isLoading: true,
      deleteModal: {
        id: undefined,
        modal: false,
        body: "Are you sure you want to delete this content.",
        title: "Confirm Delete"
      },
      searchVal: { value: "", url: "", type: "" },
      searchRes: false,
      isEditing: false,
      contentToBeEdited: undefined,
      action: undefined,
      searchTag: [],
      disableArchiveIcon: false
    };
    this.searchByTag = React.createRef();
    this.handleLoadContents();
    // load tags and sponser for contentHeaders search by tag or sponsor feature
    this.loadTags();
    this.loadSponserTag();
    this.loadPartyTag();
    this.loadBrandTag();
  }

  componentDidMount = () => {
    const contentView = this.props.getUserSetting().contentListView;

    this.setState({ userListView: contentView });
  };

  handleSearchTag = tag => {
    this.setState({ searchTag: tag });
  };

  handleLoadContents = (data = this.state.forcePage) => {
    if (this.state.filterType.active) {
      this.handleFilterWithType(this.state.filterType.value)
    } else {
      const api = [];
      Resources.getContent.url = `/contents?page=${data ? data : 1}&per_page=${this.perPageCount
        }`;
      api.push(this.props.Get(Resources.getContent));
      Promise.all(api).then(() => {
        this.setState({
          isLoading: false
        });
      });
    }
  };
  // load tags and sponser for contentHeaders search by tag or sponsor feature
  loadTags = async () => {
    const { getContent } = ContentTagResources;
    await this.props.Get(getContent);
  };

  loadSponserTag = async () => {
    const { getContent } = SponserTagResources;
    await this.props.Get(getContent);
  };
  loadPartyTag = async () => {
    const { getContent } = PartyTagListResources;
    await this.props.Get(getContent);
  };
  loadBrandTag = async () => {
    const { getContent } = BrandTagListResources;
    await this.props.Get(getContent);
  };
  handleContentEditAndView = (flag, action, content) => {
    this.setState({ isEditing: flag, action, contentToBeEdited: content });
    this.forceUpdate();
  };

  handleRefresh = () => {
    if (!this.state.refreshing) {
      this.setState({
        filterType: { active: false, value: "" },
        searchRes: false
      });
      this.searchByTag.current.props.clearSearchByName();
      this.searchByTag.current.state.value = "";
      this.searchByTag.current.props.clearSearchTag();
      Resources.getContent.url = `/contents?page=${1}&per_page=${this.perPageCount
        }`;
      this.setState(
        { refreshing: true, forcePage: 0, sortOrder: this.initialSortOrder },
        () => {
          this.props
            .Get(Resources.getContent)
            .then(() => {
              setTimeout(() => {
                this.setState({ refreshing: false });
              }, 3000);
            })
            .then(() => this.props.showNotification("Refreshed", "info"));
        }
      );
    }
  };

  handleFilter = filter => {
    this.setState({ forcePage: 0, isLoading: true });
    const { getContent } = Resources;
    filter === "true"
      ? (getContent.url = `/contents?is_filler_content=${filter}&page=${1}&per_page=${this.perPageCount
        }`)
      : (getContent.url = `/contents/search?search=${filter}&page=${1}&per_page=${this.perPageCount
        }`);
    this.props
      .Get(Resources.getContent)
      .then(() => this.setState({ isLoading: false }));
  };

  handleFilterWithType = filter => {
    this.setState({ filterType: { active: true, value: filter } });
    this.setState({ forcePage: 0, isLoading: true });
    const { getContent } = Resources;

    var currentSortOrder = [];
    if (
      (currentSortOrder = Object.entries(this.state.sortOrder).find(
        i => i[1].active
      ))
    ) {
      getContent.url = `/contents?sort=${currentSortOrder[0]}&sort_by=${currentSortOrder[1].sortBy
        }&page=${1}&per_page=${this.perPageCount}&type=${filter}`;
    } else {
      filter === "true"
        ? (getContent.url = `/contents?is_filler_content=${filter}&page=${1}&per_page=${this.perPageCount
          }`)
        : (getContent.url = `/contents?type=${filter}&page=${1}&per_page=${this.perPageCount
          }`);
    }

    this.props
      .Get(Resources.getContent)
      .then(() => this.setState({ isLoading: false }));
  };

  handleSort = name => {
    if (this.searchByTag) {
      this.searchByTag.current.state.value = "";
      this.searchByTag.current.props.clearSearchTag();
    }
    this.setState({ forcePage: 0, isLoading: true });
    this.props.change("SelectedContents", "contentSelected", []);
    this.contentTableRefs.current.selectionContext.selected = [];
    this.contentTableRefs.current.setState({});
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getContent } = Resources;

        if (this.state.filterType.active) {
          getContent.url = `/contents/?sort=${name}&sort_by=${this.state.sortOrder[name].sortBy
            }&page=${1}&per_page=${this.perPageCount}&type=${this.state.filterType.value
            }`;
        } else {
          getContent.url = `/contents/?sort=${name}&sort_by=${this.state.sortOrder[name].sortBy
            }&page=${1}&per_page=${this.perPageCount}`;
        }

        this.props
          .Get(Resources.getContent)
          .then(() => this.setState({ isLoading: false }));
      }
    );
  };

  handleDeleteConfirmation = (id, name) => {
    const { deleteModal } = this.state;
    deleteModal.modal = true;
    deleteModal.id = id;
    deleteModal.body = (
      <span>
        <small>Are you sure you want to delete </small>
        <strong>{name}</strong>?
      </span>
    );

    this.setState({ deleteModal });
  };

  handleDelete = () => {
    const {
      deleteModal: { id }
    } = this.state;
    if (id) {
      this.props
        .Delete(Resources.deleteContent(id))
        .then(result => {
          if (result.status === 200) {
            this.props.RemoveItemFromContentList(id);
            if (this.props.contentList.length === 0) {
              this.handlePageClick(this.props.meta, true);
            }
          }
          const { deleteModal } = this.state;
          deleteModal.modal = false;
          deleteModal.id = undefined;
          this.setState({
            deleteModal
          });
        })
        .then(() =>
          this.props.change("SelectedContents", "contentSelected", [])
        );
      return 0;
    }
    return 0;
  };

  toggleModel = flag => {
    const { deleteModal } = this.state;
    deleteModal.modal = flag;
    this.setState({ deleteModal });
  };

  handleSearchValue = async (value, url = false, type = "") => {
    if (!url) {
      this.searchByTag.current.state.value = "";
      this.searchByTag.current.props.clearSearchTag();
    }
    this.setState({ forcePage: 0, searchVal: { value, url, type } }, () => {
      this.handleSearch();
    });
  };

  handleClearSearchValue = () => {
    this.searchByTag.current.state.value = "";
    this.searchByTag.current.props.clearSearchTag();
  };

  // eslint-disable-next-line react/sort-comp
  handleSearch = debounce(() => {
    const { getContent } = Resources;
    if (this.state.searchVal.value === "") {
      getContent.url = `/contents?page=${1}&per_page=${this.perPageCount}`;
      this.setState({ searchRes: false });
      this.props.Get(getContent);
    } else {
      this.setState({ searchRes: true });
      if (!this.state.searchVal.url) {
        getContent.url = `/contents/search?search=${this.state.searchVal.value
          }&page=${1}&per_page=${this.perPageCount}`;
        this.props.Get(getContent);
        return 0;
      }
      if (this.state.searchVal.type === "Sponsors") {
        getContent.url = `/contents/search_sponsor_tag?sponsor_tags=${this.state.searchVal.value}&page=1&per_page=${this.perPageCount}`;
        this.props.Get(getContent);
        return 0;
      }
      if (this.state.searchVal.type === "Party") {
        getContent.url = `/contents/search_by_party?party_tags=${this.state.searchVal.value}&page=1&per_page=${this.perPageCount}`;
        this.props.Get(getContent);
        return 0;
      }
      if (this.state.searchVal.type === "Brand") {
        getContent.url = `/contents/search_by_brand?brand_tags=${this.state.searchVal.value}&page=1&per_page=${this.perPageCount}`;
        this.props.Get(getContent);
        return 0;
      }
      getContent.url = `/contents/search_tag?tags=${this.state.searchVal.value}&page=1&per_page=${this.perPageCount}`;

      this.props.Get(getContent);
    }
  }, 3000);

  handleArchiveItems = async (contents, isDisabled) => {
    this.setState({ disableArchiveIcon: true });
    this.forceUpdate();
    if (!isDisabled) {
      const filteredContentId = contents.map(item => item.id);
      const { getContent } = Resources;
      const { postArchiveContent } = ContentResources;
      postArchiveContent.body = postArchivedItems({ filteredContentId });
      await this.props
        .Put(postArchiveContent)
        .then(() =>
          this.props.Get(getContent).then(result => {
            if (result.status === 200) {
              return (
                this.props.contentList.length === 0 &&
                this.handlePageClick(this.props.meta, true)
              );
            }
          })
        )
        .then(() =>
          this.props.change("SelectedContents", "contentSelected", [])
        )
        .then(() => {
          this.setState({ disableArchiveIcon: false });
        });
    }
  };

  handleArchiveItem = async id => {
    const { postArchiveContent } = ContentResources;
    postArchiveContent.body = postArchivedItems({ filteredContentId: [id] });
    await this.props
      .Put(postArchiveContent)
      .then(() =>
        this.props.Get(Resources.getContent).then(result => {
          if (result.status === 200) {
            return (
              this.props.contentList.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        })
      )
      .then(() => this.props.change("SelectedContents", "contentSelected", []));
  };

  handleDeleteContents = contents => {
    const filteredContentId = contents.map(item => item.id);
    const { removeBulkContents } = ContentResources;
    const { getContent } = Resources;
    removeBulkContents.body = postArchivedItems({ filteredContentId });
    this.props.openGlobalModal({
      heading: "Delete Contents",
      body: "Are you sure you want to delete?",
      actionFunc: () => {
        this.props
          .Put(removeBulkContents)
          .then(() =>
            this.props.Get(getContent).then(result => {
              if (result.status === 200) {
                return (
                  this.props.contentList.length === 0 &&
                  this.handlePageClick(this.props.meta, true)
                );
              }
            })
          )
          .then(() =>
            this.props.change("SelectedContents", "contentSelected", [])
          );
      }
    });
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    const { getContent } = Resources;
    if (moveToPrevious) {
      getContent.url = `/contents?page=${data.page - 1}&per_page=${this.perPageCount
        }`;
      this.props.Get(getContent).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      this.setState({ forcePage: undefined });
      this.setState({ isLoading: true });
      const { selected } = data;
      if (this.state.sortValue.isSortable || this.state.filterType.active) {
        if (this.state.sortValue.isSortable && this.state.filterType.active) {
          getContent.url = `/contents?sort=${this.state.sortValue.name
            }&sort_by=${this.state.sortValue.sortBy}&page=${selected + 1}
          &per_page=${this.perPageCount}&type=${this.state.filterType.value}`;
        } else if (this.state.sortValue.isSortable) {
          getContent.url = `/contents?sort=${this.state.sortValue.name
            }&sort_by=${this.state.sortValue.sortBy}&page=${selected +
            1}&per_page=${this.perPageCount}`;
        } else {
          this.state.filterType.value === "true"
            ? (getContent.url = `/contents?is_filler_content=${this.state.filterType.value
              }&page=${selected + 1}&per_page=${this.perPageCount}`)
            : (getContent.url = `/contents?type=${this.state.filterType.value
              }&page=${selected + 1}&per_page=${this.perPageCount}`);
        }
      } else if (this.state.searchRes) {
        if (!this.state.searchVal.url) {
          getContent.url = `/contents/search?search=${this.state.searchVal.value
            }&page=${selected + 1}&per_page=${this.perPageCount}`;
        } else if (this.state.searchVal.type === "Sponsors") {
          getContent.url = `/contents/search_sponsor_tag?sponsor_tags=${this.state.searchVal.value
            }&page=${selected + 1}&per_page=${this.perPageCount}`;
        } else if (this.state.searchVal.type === "Party") {
          getContent.url = `/contents/search_by_party?party_tags=${this.state.searchVal.value
            }&page=${selected + 1}&per_page=${this.perPageCount}`;
        } else if (this.state.searchVal.type === "Brand") {
          getContent.url = `/contents/search_by_brand?brand_tags=${this.state.searchVal.value
            }&page=${selected + 1}&per_page=${this.perPageCount}`;
        } else {
          getContent.url = `/contents/search_tag?tags=${this.state.searchVal.value
            }&page=${selected + 1}&per_page=${this.perPageCount}`;
        }
      } else {
        getContent.url = `/contents?page=${selected + 1}&per_page=${this.perPageCount
          }`;
      }
      this.props
        .Get(getContent)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  handleContentView = viewOption => {
    this.setState({ userListView: viewOption });
    this.props.setUserSetting("contentListView", viewOption);
  };

  render() {
    const { deleteModal, refreshing, userListView } = this.state;
    const { getUserSetting, setUserSetting } = this.props;

    return (
      <div className="mainPage">
        <div className="contentSection">
          <Dialog
            externalControl
            showHandler={false}
            modal={deleteModal.modal}
            body={deleteModal.body}
            title={deleteModal.title}
            toggleModel={this.toggleModel}
            primaryMethod={this.handleDelete}
          />
          <ReactTooltip />
          {this.state.isEditing && (
            <EditContent
              isEditing={this.state.isEditing}
              handleContentEditAndView={this.handleContentEditAndView}
              contentToBeEdited={this.state.contentToBeEdited}
              action={this.state.action}
              handleLoadContents={this.handleLoadContents}
              forcePage={this.state.forcePage}
              {...this.props}
            />
          )}
          {userListView === "lists" ? (
            <ContentTable
              {...this.props}
              tableRef={this.contentTableRefs}
              contentList={this.props.contentList}
              handleDelete={this.handleDeleteConfirmation}
              handleSort={this.handleSort}
              sortOrder={this.state.sortOrder}
              isLoading={this.state.isLoading}
              handleArchiveItem={this.handleArchiveItem}
              handleContentEditAndView={this.handleContentEditAndView}
              // below props used by table header
              searchRef={this.searchByTag}
              refreshing={refreshing}
              handleRefresh={this.handleRefresh}
              handleFilter={this.handleFilter}
              handleSearch={this.handleSearchValue}
              handleFilterWithType={this.handleFilterWithType}
              handleArchiveItems={this.handleArchiveItems}
              handleDeleteContents={this.handleDeleteContents}
              handleClearSearchValue={this.handleClearSearchValue}
              handleContentView={this.handleContentView}
              contentView={userListView}
              searchTag={this.state.searchTag}
              handleSearchTag={this.handleSearchTag}
              disableArchiveIcon={this.state.disableArchiveIcon}
            />
          ) : (
            <GridContentView
              {...this.props}
              contentList={this.props.contentList}
              handleDelete={this.handleDeleteConfirmation}
              handleSort={this.handleSort}
              sortOrder={this.state.sortOrder}
              isLoading={this.state.isLoading}
              handleArchiveItem={this.handleArchiveItem}
              handleContentEditAndView={this.handleContentEditAndView}
              // below props used by table header
              searchRef={this.searchByTag}
              refreshing={refreshing}
              handleRefresh={this.handleRefresh}
              handleFilter={this.handleFilter}
              handleSearch={this.handleSearchValue}
              handleFilterWithType={this.handleFilterWithType}
              handleArchiveItems={this.handleArchiveItems}
              handleDeleteContents={this.handleDeleteContents}
              handleClearSearchValue={this.handleClearSearchValue}
              handleContentView={this.handleContentView}
              contentView={userListView}
              searchTag={this.state.searchTag}
              handleSearchTag={this.handleSearchTag}
            />
          )}

          {!this.state.isLoading &&
            this.props.contentList &&
            this.props.contentList.length > 0 && (
              <div className="contentSectionPagination clearfix">
                <ReactPaginate
                  pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  marginPagesDisplayed={3}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  previousLinkClassName="pagination-label
                  "
                  nextLinkClassName="pagination-label"
                  previousLabel="<"
                  forcePage={this.state.forcePage}
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  disabledClassName="paginationDisable"
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list, status, loading, hasError, meta, url } = state.content;
  return {
    contentList: list !== undefined ? list : [],
    loading,
    hasError,
    meta,
    currentURL: url,
    status
  };
}

export default connect(
  mapStateToProps,
  {
    RemoveItemFromContentList,
    Logout,
    change
  }
)(Contents);
