import React from "react";
import {
  Label,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Button,
  Table,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  FormGroup,
  Form
} from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import classnames from "classnames";

import { Field, reduxForm } from "redux-form";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { Resources as CampaignResources } from "../../campaign/redux";
import "react-tabs/style/react-tabs.css";

// import SelectAsync from "../../../core/form/selectAsync";
import SelectAsync from "../../../core/form/selectAsyncClass";
import SelectAsyncCalendar from "../../../core/form/selectAsynCalendar";

export default props => (
  <div>
    <Nav tabs className="mb-3">
      <NavItem>
        <NavLink
          className={classnames({ active: props.activeTab === "1" })}
          onClick={() => {
            props.toggleTab("1");
          }}
        >
          CAMPAIGN
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: props.activeTab === "2" })}
          onClick={() => {
            props.toggleTab("2");
          }}
        >
          CALENDAR
        </NavLink>
      </NavItem>
    </Nav>
    <TabContent activeTab={props.activeTab}>
      <TabPane tabId="1">
        <Row>
          <Col sm="12">
            <ModalBody className="globalForm">
              <Form>
                <CampaignList
                  props={props}
                  campaignList={props.campaignList}
                  refetchCampaign={props.refetchCampaign}
                  selectedCampaign={props.selectedCampaign}
                />
                <SelectedDevicesTable
                  selectedDevices={props.selectedDevices}
                  handleDeleteCallback={props.handleDeleteCallback}
                />
                {props.selectedDevices < 1 && (
                  <span className="ml-2 valid-info">Please select devices</span>
                )}
              </Form>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn-outline-primary"
                onClick={props.toggle}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // eslint-disable-next-line no-lone-blocks
                  {
                    props.handleSendCampaignToPlayer(
                      props.selectedDevices,
                      props.selectedCampaign
                    );
                    props.toggle();
                    props.toggleTab("1");
                  }
                }}
                className="primaryButton mt-0"
                disabled={
                  props.selectedCampaign.length === 0 ||
                  props.selectedDevices.length === 0
                }
              >
                Send Campaign
              </button>
            </ModalFooter>
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="2">
        <Row>
          <Col sm="12">
            <ModalBody className="globalForm">
              <Form>
                <CalendarList calendarList={props.calendarList} />
              </Form>
              <SelectedDevicesTable
                selectedDevices={
                  props.selectedDevices || props.selectedDevicesFromGroup
                }
                handleDeleteCallback={props.handleDeleteCallback}
              />
              {props.selectedDevices < 1 && (
                <span className="ml-2 valid-info">Please select devices</span>
              )}
            </ModalBody>
            <ModalFooter>
              <button className="btn-outline-primary" onClick={props.toggle}>
                Cancel
              </button>
              <button
                onClick={() => {
                  props.handleSendCalendarToPlayer(
                    props.selectedDevices,
                    props.selectedCalendar
                  );
                  props.toggle();
                  props.toggleTab("1");
                }}
                className="primaryButton mt-0"
                disabled={
                  props.selectedCalendar.length === 0 ||
                  props.selectedDevices.length === 0
                }
              >
                Send Calendar
              </button>
            </ModalFooter>
          </Col>
        </Row>
      </TabPane>
    </TabContent>
  </div>
);

const CampaignListUI = () => (
  <React.Fragment>
    <FormGroup>
      <div className="globalInput">
        <Label>Select Campaign</Label>
        <Field component={SelectAsync} name="selectedCampaign" />
      </div>
    </FormGroup>
  </React.Fragment>
);
const CampaignList = reduxForm({
  form: "SendMediaToDevice"
})(CampaignListUI);

const CalendarListUI = ({ calendarList }) => (
  <React.Fragment>
    <FormGroup>
      <div className="globalInput">
        <Label>Select a Calendar</Label>
        <Field component={SelectAsyncCalendar} name="selectedCalendar" />
      </div>
    </FormGroup>
  </React.Fragment>
);

const CalendarList = reduxForm({
  form: "SendMediaToDevice"
})(CalendarListUI);

const SelectedDevicesTable = ({ selectedDevices, handleDeleteCallback }) => (
  <div className="sendMediaDeviceTable">
    {/* <h5 style={{ padding: "0 15px" }} className="my-2">
      Selected Devices List
      <Badge className="selected-device-badge"> {selectedDevices.length}</Badge>
    </h5> */}
    <Table responsive style={{ padding: "0 15px" }} className="tableFixHead">
      <thead>
        <tr>
          <th className="pl-2" style={{ width: "auto", padding: "0px" }}>
            Name
          </th>
          <th>Mac Address</th>
          <th style={{ width: "120px" }}>Host Name</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {selectedDevices.map(item => (
          <tr key={item.id}>
            <td className="word-break">
              {item.attributes.name || item.attributes.attributes.name || "-"}
            </td>
            <td>
              {item.attributes.mac_address ||
                item.attributes.attributes.mac_address}
            </td>
            <td className="word-break">
              {item.attributes.attributes
                ? item.attributes.attributes.hostname
                : item.attributes.hostname || "-"}
            </td>
            <td>
              <span
                role="presentation"
                className="fa fa-trash"
                onClick={() => handleDeleteCallback(item)}
                style={{
                  fontSize: "15px",
                  cursor: "pointer"
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <div className="deviceSelected">
      <span className="mt-2">
        {selectedDevices.length > 0 &&
          `${selectedDevices.length} ${
            selectedDevices.length === 1
              ? "device is selected"
              : "devices are selected"
          }`}
      </span>
    </div>
  </div>
);
