import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import {
  reduxForm,
  Field,
  formValueSelector,
  initialize as remoteInitialize,
  unregisterField,
  SubmissionError,
} from "redux-form";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { toLower, debounce, remove } from "lodash";

import { required } from "../../../helper/validation";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { Dialog, Input } from "../../../core";
import DomainPartyTable from "./domainPartyTable";
import { Resources, addPartyBody, updatePartyBody, bulkDeletePartiesBody } from "../redux/domainRedux";
import BrandModal from "./brandModal";
import { Get } from "../../../constant/thunk";
import { showNotification } from "../../../core/modal/toster";

// eslint-disable-next-line react/prefer-stateless-function
class DomainParty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      modal: false,
      isLoading: true,
      forcePage: 0,
      searchVal: "",
      dialogModal: false,
      removePartyID: null,
      removePartyName: null,
      brandModal: false,
      partyModal: false,
      selectedPartyId: null,
      brandList: [],
      newBrandList: [],
      updatedBrandList: [],
      editBrand: false,
      removeBrandDialog: false,
      removedBrands: [],
      selectedBrandId: null,
      bulkSelectedParties: [],
      isBulkDeleteVisible: false,
      deleteMultipleParties: false,
      sortBy: undefined,
      sortOrder: "desc",
      loadingNameValidation: false
    };
  }

  componentDidMount() {
    this.loadDomainParties();
  }

  toggledialogModal = () => {
    this.setState(state => ({
      dialogModal: !state.dialogModal,
      deleteMultipleParties: false
    }));
  };

  handleRemoveParty = (id, name) => {
    this.setState({
      dialogModal: true,
      removePartyID: id,
      removePartyName: name
    });
  };

  /** Delete request for server to remove user */
  removeExistingPartyFromDomain = async userId => {
    const { removePartyID } = this.state;
    /**  make a api call for removing user from domain with received id */
    const { deleteDomainParties } = Resources;
    deleteDomainParties.url = `domains/${this.props.match.params.id}/parties/${this.state.removePartyID}`;
    await this.props.Delete(deleteDomainParties).then(() => {
      this.setState({
        dialogModal: false,
        removePartyID: null,
        removePartyName: null
      });
      if (this.state.searchVal) {
        this.setState({ searchVal: "" }, () => {
          this.loadDomainParties(this.props.meta.page);
        });
      } else {
        this.loadDomainParties(this.props.meta.page)
      }
    });
  };

  toggleIsBulkDeleteVisible = (flag) => {
    this.setState({ isBulkDeleteVisible: flag })
  }

  handleBulkPartiesPush = (newData) => {
    newData = newData.map(elem => {
      return {
        type: elem.type,
        attributes: {
          id: elem.id,
        }
      }
    })
    this.setState({
      bulkSelectedParties: [...this.state.bulkSelectedParties, ...newData]
    })
  }

  handleBulkPartiesPop = (row) => {
    let { bulkSelectedParties } = this.state;
    remove(bulkSelectedParties, (elem => {
      return elem.attributes.id === row.id
    }))
  }

  handleBulkDeleteParties = () => {
    this.setState({
      dialogModal: true,
      removePartyName: "selected parties",
      deleteMultipleParties: true,
    });
  }

  bulkDeletePartiesFromDomain = async () => {
    const { bulkDeleteParties } = Resources;
    bulkDeleteParties.url = `domains/${this.props.match.params.id}/parties/bulk_destroy`;
    bulkDeleteParties.body = bulkDeletePartiesBody(this.state.bulkSelectedParties);
    await this.props.BulkDelete(bulkDeleteParties).then(() => {
      this.setState({
        dialogModal: false,
        bulkSelectedParties: [],
        deleteMultipleParties: false
      });
      if (this.state.searchVal) {
        this.setState({ searchVal: "" }, () => {
          this.loadDomainParties(this.props.meta.page);
        });
      } else {
        this.loadDomainParties(this.props.meta.page)
      }
    });
  }

  loadDomainParties = async (data = this.state.forcePage) => {
    const { getDomainParties } = Resources;
    if (this.state.sortBy) {
      getDomainParties.url = `domains/${this.props.match.params.id}/parties?page=${data}&sort=${this.state.sortBy}&sort_by=${this.state.sortOrder}`;
    }
    else {
      getDomainParties.url = `domains/${this.props.match.params.id}/parties?page=${data}`;
    }
    this.props.Get(getDomainParties).then(() => {
      this.setState({ isLoading: false, bulkSelectedParties: [] });
    });
  };

  handleSort = (sort, sortOrder) => {
    this.setState(
      {
        sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc",
        sortBy: sort
      },
      () => {
        this.loadDomainParties();
      }
    );
  };

  toggleAddParty = flag => {
    this.setState({ edit: false, modal: flag, partyModal: flag });
    this.props.remoteInitialize("DomainPartyForm", {
      partyName: ""
    });
  };

  setSelectedParty = partyId => {
    this.setState({
      selectedPartyId: this.props.domainParties.list[partyId - 1]
    });
  };

  handleEditParty = (partyName, id) => {
    this.props.remoteInitialize("DomainPartyForm", {
      partyName
    });
    this.setState({
      edit: true,
      modal: true,
      partyModal: true,
      selectedPartyId: id
    });
  };

  handleEditBrands = values => {
    const tempObj = {};
    const fieldArr = [
      "name",
      "client_email",
      "total_duration_minutes",
      "rate_per_minute",
      "is_negotiable",
      "start_date",
      "end_date"
    ];
    values.forEach(value => {
      fieldArr.forEach(field => {
        tempObj[`brand-${value.id}-${field}`] = value.attributes[field] || null;
        if (field == "rate_per_minute") {
          tempObj[`brand-${value.id}-${field}`] =
            value.attributes[field] || "0";
        }
        if (field == "is_negotiable") {
          tempObj[`brand-${value.id}-${field}`] =
            value.attributes[field] || false;
        }
      });
    });
    this.props.remoteInitialize("brandForm", { ...tempObj });
  };

  addParty = async values => {
    // this.toggleAddParty(false);
    this.setState(
      {
        sortBy: undefined,
        forcePage: 0,
        sortOrder: "desc"
      },
      () => {
        this.state.edit
          ? this.updatePartyToDomain(values)
          : this.addNewPartyToDomain(values);
      }
    );
  };

  addNewPartyToDomain = async values => {
    const { addDomainParties } = Resources;
    addDomainParties.url = `domains/${this.props.match.params.id}/parties`;
    addDomainParties.body = addPartyBody({
      ...values
    });

    await this.props.Post(addDomainParties).then(res => {
      const { data } = res;
      if (!data.data[0].attributes.errors.name) {
        this.toggleAddParty(false);
        this.loadDomainParties(0);
        this.props.dispatch(
          showNotification("Party Created Successfully", "success")
        );
      } else {
        this.props.dispatch(
          showNotification(data.data[0].attributes.errors.name[0], "danger")
        );
      }
    });
  };

  updatePartyToDomain = async values => {
    const { updateDomainParties } = Resources;
    updateDomainParties.url = `domains/${this.props.match.params.id}/parties/${this.state.selectedPartyId}`;
    updateDomainParties.body = updatePartyBody({
      ...values
    });
    await this.props.Patch(updateDomainParties).then(res => {
      const { data, status } = res;
      if (status !== 422) {
        this.toggleAddParty(false);
        this.loadDomainParties(0);
        this.props.dispatch(
          showNotification("Party updated successfully", "success")
        );
      } else {
        this.props.dispatch(showNotification(data.name[0], "danger"));
      }
    });
  };

  toggleBrandModal = (flag, id, brandEditState) => {
    const toggle = () => {
      this.setState({
        modal: flag,
        brandModal: flag,
        editBrand: brandEditState,
        selectedPartyId: id
      });
    };
    !flag
      ? toggle()
      : !brandEditState
      ? toggle()
      : this.loadPartyBrands(id).then(() => {
          toggle();
          this.handleEditBrands(this.state.brandList);
        });
  };

  loadPartyBrands = async partyId => {
    const { getPartyBrands } = Resources;
    getPartyBrands.url = `domains/${this.props.match.params.id}/parties/${partyId}/brands`;
    await this.props.Get(getPartyBrands).then(() => {
      this.setState({
        isLoading: false,
        brandList: this.props.selectedPartyBrands || []
      });
    });
  };

  handleRemoveBrand = id => {
    this.setState({
      dialogModal: true,
      removeBrandDialog: true,
      selectedBrandId: id
    });
  };

  removeBrandFromParty = async () => {
    const { removePartyBrands } = Resources;
    const { brandFormFields } = this.props;
    removePartyBrands.url = `domains/${this.props.match.params.id}/parties/${this.state.selectedPartyId}/brands/${this.state.selectedBrandId}`;
    await this.props.Delete(removePartyBrands).then(data => {
      if (data.status === 200) {
        const id = this.state.selectedBrandId;
        this.setState({
          dialogModal: false,
          removeBrandDialog: false,
          selectedBrandId: null,
          removedBrands: [...this.state.removedBrands, id]
        });
      }
      this.loadDomainParties(this.props.meta.page);
      this.loadPartyBrands(this.state.selectedPartyId);
    });
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true, forcePage: undefined });
    const { getDomainParties } = Resources;
    if (moveToPrevious) {
      if (this.state.searchVal) {
        getDomainParties.url = `domains/${
          this.props.match.params.id
        }/parties?page=${data.page - 1}`;
        this.props.Get(getDomainParties).then(() => {
          this.setState({ forcePage: data.page - 2, isLoading: false });
        });
      } else {
        getDomainParties.url = `domains/${
          this.props.match.params.id
        }/parties?page=${data.page - 1}`;
        this.props.Get(getDomainParties).then(() => {
          this.setState({ forcePage: data.page - 2, isLoading: false });
        });
      }
    } else {
      const { selected } = data;
      if (this.state.searchVal) {
        getDomainParties.url = `domains/${
          this.props.match.params.id
        }/parties?page=${selected + 1}`;
        this.props.Get(getDomainParties).then(() => {
          this.setState({ forcePage: selected, isLoading: false });
        });
      } else {
        getDomainParties.url = `domains/${
          this.props.match.params.id
        }/parties?page=${selected + 1}`;
        this.props
          .Get(getDomainParties)
          .then(() => this.setState({ forcePage: selected, isLoading: false }));
      }
    }
  };

  handleSearchValue = value => {
    this.setState({ searchVal: value, forcePage: 0 }, () => {
      this.handleSearch();
    });
  };

  handleSearch = debounce(() => {
    this.setState({ isLoading: true, forcePage: undefined });
    const { getDomainParties } = Resources;
    if (this.state.searchVal === "") {
      getDomainParties.url = `domains/${this.props.match.params.id}/parties`;
    } else {
      getDomainParties.url = `domains/${this.props.match.params.id}/parties/search?search=${this.state.searchVal}`;
    }
    this.props.Get(getDomainParties).then(() => {
      this.setState({ isLoading: false });
    });
  }, 3000);

  render() {
    return (
      <div>
        <DomainPartyTable
          currentUser={this.props.currentUser}
          currentUserRoles={this.props.currentUserRoles}
          parties={this.props.domainPartiesList}
          handleEditParty={this.handleEditParty}
          removeExistingPartyFromDomain={this.handleRemoveParty}
          isLoading={this.state.isLoading}
          // below props are used for domain user header component
          editDUH={this.state.edit}
          toggleAddPartyDUH={this.toggleAddParty}
          handleSearchValueDUH={this.handleSearchValue}
          searchValDUH={this.state.searchVal}
          brandModal={this.brandModal}
          toggleBrandModal={this.toggleBrandModal}
          handleEditBrands={this.handleEditBrands}
          handleBulkPartiesPush={this.handleBulkPartiesPush}
          handleBulkPartiesPop={this.handleBulkPartiesPop}
          bulkSelectedParties={this.state.bulkSelectedParties}
          isBulkDeleteVisible={this.state.isBulkDeleteVisible}
          toggleIsBulkDeleteVisible={this.toggleIsBulkDeleteVisible}
          handleBulkDeleteParties={this.handleBulkDeleteParties}
          sortOrder={this.state.sortOrder}
          handleSort={this.handleSort}
          {...this.props}
        />
        <div>
          <Modal
            isOpen={this.state.modal}
            className={this.props.className}
            style={{ maxWidth: this.state.editBrand ? "70%" : "500px" }}
          >
            {this.state.partyModal && (
              <>
                <ModalHeader toggle={() => this.toggleAddParty(false)}>
                  {this.state.edit ? "Edit Party" : "Add Party"}
                </ModalHeader>
                <ModalBody>
                  {" "}
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                    }}
                  >
                    <div className="globalForm">
                      <FormGroup>
                        <div className="globalInput">
                          <Field
                            label="Name"
                            component={Input}
                            name="partyName"
                            validate={[required]}
                            placeholder="Name"
                          />
                        </div>
                      </FormGroup>
                    </div>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    className=" btn-outline-primary"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleAddParty(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={this.props.handleSubmit(values => {
                      this.addParty(values);
                    })}
                    className="mt-0 primaryButton"
                    disabled={this.props.partyName === ""}
                  >
                    {this.state.edit ? "Update " : "Add "}
                  </button>
                </ModalFooter>
              </>
            )}
            {this.state.brandModal && (
              <>
                <ModalHeader
                  toggle={() =>
                    this.setState({
                      brandModal: false,
                      modal: false,
                      editBrand: false
                    })
                  }
                >
                  {this.state.editBrand ? "Edit Brands" : "Add Brands"}
                </ModalHeader>
                <BrandModal
                  Get={this.props.Get}
                  Post={this.props.Post}
                  Patch={this.props.Patch}
                  brands={
                    this.state.editBrand
                      ? this.state.brandList
                      : [
                          {
                            id: "new_brand",
                            attributes: {
                              name: "",
                              client_email: "",
                              is_negotiable: "",
                              total_duration_minutes: null,
                              rate_per_minute: null,
                              start_date: null,
                              end_date: null
                            }
                          }
                        ]
                  }
                  partiesId={this.state.selectedPartyId}
                  newBrandList={this.state.newBrandList}
                  updatedBrandList={this.state.updatedBrandList}
                  editBrand={this.state.editBrand}
                  toggleBrandModal={this.toggleBrandModal}
                  loadDomainParties={this.loadDomainParties}
                  handleRemoveBrand={this.handleRemoveBrand}
                  page={this.props.meta.page}
                  removedBrands={this.state.removedBrands}
                  currentUserRoles={this.props.currentUserRoles}
                  match={this.props.match}
                />
              </>
            )}
          </Modal>
          {this.props.domainPartiesList &&
            this.props.domainPartiesList.length > 0 && (
              <div className="contentSectionPagination clearfix">
                <ReactPaginate
                  pageCount={
                    this.props.meta.total_pages
                      ? this.props.meta.total_pages
                      : 0
                  }
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  marginPagesDisplayed={2}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  previousLabel="<"
                  forcePage={this.state.forcePage}
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  disabledClassName="paginationDisable"
                />
              </div>
            )}
        </div>
        <Dialog
          showHandler={false}
          externalControl
          modal={this.state.dialogModal}
          toggleModal={this.toggledialogModal}
          body={
            <span>
              <span>Are you sure you want to remove </span>
              <strong>{this.state.removePartyName}</strong>?
            </span>
          }
          primaryMethod={
            this.state.removeBrandDialog
              ? this.removeBrandFromParty
              : this.state.deleteMultipleParties
              ? this.bulkDeletePartiesFromDomain
              : this.removeExistingPartyFromDomain
          }
          toggleModel={this.toggledialogModal}
          title="Remove Party"
        />
      </div>
    );
  }
}
function mapState(state) {
  const partyFormSelector = formValueSelector("DomainPartyForm");
  // const brandFormSelector = formValueSelector("brandForm")
  const { list, meta } = state.domainParties || {};
  return {
    domainParties: list,
    partyName: partyFormSelector(state, "partyName") || "",
    domainPartiesList: list || [],
    meta,
    selectedPartyBrands: state.partyBrands.list,
    brandFormFields: state.form.brandForm
      ? state.form.brandForm.registeredFields
      : {}
  };
}

export default connect(
  mapState,
  { remoteInitialize }
)(
  reduxForm({
    form: "DomainPartyForm"
  })(DomainParty)
);
