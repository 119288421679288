import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Popover,
  PopoverBody,
  Label
} from "reactstrap";

import {
  SortableContainer,
  SortableElement,
  arrayMove
} from "react-sortable-hoc";
import { formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import { Tooltip } from "../../../core";
import VerifyLockedPlaylist from "../../../helper/verifyLockedPlaylist";

const SortableItem = SortableElement(
  ({
    value,
    index,
    handleMinuteChange,
    handleSecondChange,
    oldIndex,
    handleDelete,
    props
  }) => (
    <div style={{ zIndex: 99999999 }}>
      <div className="list-group-class" key={`${value.contentLocalID}`}>
        <span className="list-group-close">
          <VerifyLockedPlaylist
            roles={props.roles && props.roles}
            permission={props.permission && props.permission}
            row={{
              attributes: value.meta && value.meta.attributes,
              type: value.type
            }}
          >
            <i
              role="presentation"
              onClick={() =>
                setTimeout(() => {
                  handleDelete(value.contentLocalID, oldIndex);
                }, 600)
              }
              className="fa fa-times-circle ml-3 ml-auto"
              data-tip="Delete"
            />
          </VerifyLockedPlaylist>
        </span>
        <div className="d-flex">
          <div className="list-group-image ">
            <img
              className="cont-image"
              src={
                value.type === "playlist"
                  ? value.playlistThumbnail
                  : value.contentThumbnail
              }
              alt={value.contentName}
            />
            {value.type === "playlist" && (
              <div className="playlist-thumbnail-overlay">
                {value.meta.attributes.locked ? (
                  <img
                    src={require("../../../assets/images/playlist_locked.png")}
                    alt={value.contentName || undefined}
                    className="overlay-img content__thumb "
                    style={{ height: "25px", width: "25px", left: "42px" }}
                  />
                ) : (
                  <img
                    src={require("../../../assets/images/playlist_thumbnail.png")}
                    alt={`${value.contentName}-thumb`}
                    className="overlay-img content__thumb "
                    style={{ height: "25px", width: "25px", left: "42px" }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="list-group-duration mr-auto">
            <h5 className="list-group-item-studio__header">
              {value.contentName && value.contentName.length >= 30 ? (
                <Tooltip
                  item={`${value.contentName.slice(0, 30)}...`}
                  id={`viewname-${value.contentID}`}
                  tooltipContent={value.contentName}
                />
              ) : (
                <span>{value.contentName}</span>
              )}
            </h5>
            {/* <Input value={value.duration} /> */}
            <div className="inner-addon">
              {!value.usePlaylistDuration ? (
                <React.Fragment>
                  <div
                    className=" left-addon duration-inputs"
                    style={{
                      // backgroundImage: `url(${Background})`,
                      backgroundPosition: "-1px 2px",
                      backgroundRepeat: "no-repeat",
                      width: "100%"
                    }}
                  >
                    <input
                      type="number"
                      className="modal-duration form-control-sm"
                      placeholder="mm"
                      disabled={
                        (value.type.search("content") > -1 &&
                          value.meta.attributes.content_type.search("video") >
                            -1) ||
                        (!props.roles.includes("SuperAdmin") &&
                          value.type === "playlist" &&
                          value.meta.attributes.locked)
                      }
                      name="minute"
                      min={0}
                      max={59}
                      value={value.duration.minute}
                      onChange={e => handleMinuteChange(e, props, value)}
                      style={{ width: "55px" }}
                    />
                    <b className="ml-1 mr-1">:</b>
                    <input
                      type="number"
                      className="modal-duration mr-2 form-control-sm"
                      placeholder="ss"
                      disabled={
                        (value.type.search("content") > -1 &&
                          value.meta.attributes.content_type.search("video") >
                            -1) ||
                        (!props.roles.includes("SuperAdmin") &&
                          value.type === "playlist" &&
                          value.meta.attributes.locked)
                      }
                      name="second"
                      max={59}
                      min={0}
                      value={value.duration.second}
                      onChange={e => handleSecondChange(e, props, value)}
                      style={{ width: "55px" }}
                    />
                  </div>
                  <small className="text-primary mt-2">Format mm:ss</small>
                </React.Fragment>
              ) : (
                <span>This content uses playlist duration.</span>
              )}
            </div>

            {/* <InputGroup>
          <InputGroupAddon addonType="prepend">
            <i className="fa fa-stopwatch" />
          </InputGroupAddon>
          <Input placeholder="Duration" />
        </InputGroup> */}
          </div>
        </div>
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({
    items,
    id,
    handleMinuteChange,
    handleSecondChange,
    handleDelete,
    props
  }) => (
    <ul className="list-group">
      {items
        .filter(item => item.destroy !== 1)
        .map((value, index) => (
          <li
            className="list-group-item-studio"
            key={`${value.contentLocalID}`}
          >
            <SortableItem
              key={`item-${value.contentLocalID}`}
              index={index}
              oldIndex={index}
              handleMinuteChange={handleMinuteChange}
              handleSecondChange={handleSecondChange}
              handleDelete={() => handleDelete(value, props)}
              value={value}
              style={{ zIndex: 99999999 }}
              props={props}
            />
          </li>
        ))}
    </ul>
  )
);

class TimeLineContentSort extends Component {
  state = {
    modal: false,
    defaultState: [],
    isPopOverOpen: false
  };

  onSortEnd = (
    { oldIndex, newIndex },
    { screen, campaign, updateCampaign }
  ) => {
    const newContent = arrayMove(screen.contents, oldIndex, newIndex);
    campaign.setContents(screen.localID, newContent);
    updateCampaign(campaign);
  };

  handleDelete = (item, { screen, campaign, updateCampaign }) => {
    campaign.removeContentFromScreen(screen.localID, item.contentLocalID);
    updateCampaign(campaign);
  };

  handleMinuteChange = (
    { target: { value } },
    { screen, campaign, updateCampaign },
    item
  ) => {
    campaign.changeDurationOfContent(item.contentLocalID, screen.localID, {
      minute: value ? Number(value) : value,
      second: Number(item.duration.second)
    });
    updateCampaign(campaign);
  };

  handleSecondChange = (
    { target: { value } },
    { screen, campaign, updateCampaign },
    item
  ) => {
    campaign.changeDurationOfContent(item.contentLocalID, screen.localID, {
      minute: Number(item.duration.minute),
      second: value ? Number(value) : value
    });
    updateCampaign(campaign);
  };

  setDefaultState = () => {
    const {
      campaign,
      screen: { localID }
    } = this.props;
    const { defaultState } = this.state;
    campaign.setDefaultContentOfScreen(localID, defaultState);
    this.props.updateCampaign(campaign);
  };

  toggle = (cancelled = false) => {
    this.setState({ isPopOverOpen: false });
    if (cancelled === true) {
      this.setDefaultState();
    } else {
      this.setState({
        defaultState: JSON.parse(JSON.stringify(this.props.screen.contents))
      });
    }
    if (this.state.modal) {
      this.props.handleRemoveSelectedScreen();
    }
    this.setState(state => ({
      modal: !state.modal
    }));
  };

  togglePopOver = () => {
    if (!this.state.modal) {
      this.setState(state => ({ isPopOverOpen: !state.isPopOverOpen }));
    }
  };

  render() {
    const { modal, isPopOverOpen } = this.state;
    const { selectedScreen } = this.props;
    return (
      <div>
        <Tooltip
          item={
            <i
              role="presentation"
              onClick={this.props.screen ? this.toggle : this.togglePopOver}
              className={`fas fa-bars ${!selectedScreen && "deleteDisabled"}`}
              data-tip="Edit"
              id="Sort1"
            />
          }
          id="menu"
          tooltipContent="Sort"
        />
        {this.props.screen && (
          <Modal
            isOpen={modal}
            toggle={this.toggle}
            className="sortTimelineModal"
            style={{ maxWidth: "580px" }}
            backdrop="static"
            keyboard={false}
          >
            <ModalHeader toggle={() => this.toggle(true)}>
              Drag To Sort Content
            </ModalHeader>
            <ModalBody>
              {this.props.screen.contents.length > 0 ? (
                <SortableList
                  items={this.props.screen && this.props.screen.contents}
                  distance={10}
                  onSortEnd={newIndex => {
                    this.onSortEnd(newIndex, this.props);
                  }}
                  handleDelete={this.handleDelete}
                  handleMinuteChange={this.handleMinuteChange}
                  handleSecondChange={this.handleSecondChange}
                  style={{ zIndex: 99999999 }}
                  props={this.props}
                />
              ) : (
                <React.Fragment>
                  <p>There are not any contents in this region.</p>
                </React.Fragment>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={() => this.toggle(true)}>
                Cancel
              </Button>
              <Button className="primaryButton mt-0" onClick={this.toggle}>
                {"Done"}
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Popover
          placement="right"
          isOpen={isPopOverOpen}
          target="Sort1"
          toggle={this.togglePopOver}
          className="screenSetting"
          trigger="legacy"
        >
          <PopoverBody>
            <span>You must select a region.</span>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    if (ownProps.class) {
      const selector = formValueSelector(ownProps.screenName);
      return {
        screen: selector(state, "screen1") || []
      };
    }
    return {};
  },
  { change }
)(TimeLineContentSort);
