import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import { getLocalTime } from "../../../helper/helperFuncs";

export function columns(
  roles,
  permission,
  handleDelete,
  handleUnArchiveItem,
  handleSort,
  sortOrder,
  hiddenColumns
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <div className="thumb" style={{ cursor: "pointer" }}>
          <div className="content-thumb-image">
            <img
              src={
                row.attributes.playlist_thumbnail_url ||
                require("../../../assets/images/empty-img.png")
              }
              alt={name || undefined}
              className="content__thumb"
            />
            <div className="playlist-thumbnail-overlay">
              {row.attributes.locked ? (
                <img
                  src={require("../../../assets/images/playlist_locked.png")}
                  alt={name || undefined}
                  className="overlay-img content__thumb "
                  style={{ height: "25px", width: "25px", left: "26px" }}
                />
              ) : (
                <img
                  src={require("../../../assets/images/playlist_thumbnail.png")}
                  alt={name || undefined}
                  className="overlay-img content__thumb "
                  style={{ height: "25px", width: "25px" }}
                />
              )}
            </div>
          </div>
          <span className="content__titlename">
            {name.length >= 20 ? (
              <Tooltip
                item={
                  <span className="content__name">
                    {" "}
                    {`${name.slice(0, 20)}...`}{" "}
                  </span>
                }
                id={`viewname-${row.id}`}
                tooltipContent={name}
              />
            ) : (
              <span className="content__name">{name}</span>
            )}
          </span>
          <span className="contentType">
            {`${
              row.relationships.playlist_contents.data.length > 1
                ? "Contents"
                : "Content"
            }: `}
            {row.relationships.playlist_contents.data.length}
          </span>
        </div>
      ),
      style: { width: "550px" }
    },
    {
      dataField: "attributes.created_at",
      text: "Date Created",
      columnName: "date_created",
      hidden: hiddenColumns.date_created,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.created_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{getLocalTime(row)}</div>
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      columnName: "date_updated",
      hidden: hiddenColumns.date_updated,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{getLocalTime(row)}</div>
    },
    {
      dataField: "actionButtons",
      text: "",
      columnName: "isSelected",
      style: { position: "relative" },
      formatter: (name, row) => (
        <div className="d-flex">
          <div className="contentSectionTable__menu">
            <i className="fas fa-ellipsis-h" />
          </div>
          <div className="contentSectionTable__actionButtonWrapper">
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.archive.playlist}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() =>
                        handleUnArchiveItem(row.id, row.attributes.name)
                      }
                      className="fas fa-history"
                    />
                  }
                  id={`archive-${row.id}`}
                  tooltipContent="Restore"
                />
              </span>
            </RouteAllowed>{" "}
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.playlist.delete}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() => handleDelete(row.id, row.attributes.name)}
                      className="fa fa-trash action__trash"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  id={`delete-${row.id}`}
                  tooltipContent="Delete"
                />
              </span>
            </RouteAllowed>
          </div>
        </div>
      )
    }
  ];
}
