/** Import Node Modules */
import React, { Component } from "react";
import { change as ReduxFormChange, destroy, initialize } from "redux-form";
/** Import Redux Library */
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  calendarResources,
  RemoveItemFromCalendarList,
  postArchivedItems
} from "./redux";

/** Import Local Components */
import { CalendarHeaderForDesktop } from "./modules/calendarHeaderForDesktop";
import { CalendarHeaderForMobile } from "./modules/calendarHeaderForMobile";
import CalendarTable from "./modules/calendarTable";
import { Dialog, SearchTagRefresh } from "../../core";
import { Resources as CampaignResources } from "../campaign/redux";
import CreateCalender from "./pages/createCalender";
import EditCalendar from "./pages/editCalendar";
import CalendarInventory from "./pages/calendarInventory";
import DownloadCalendarForUSB from "./pages/downloadCalendar";
import PhoneBreakpoint from "../../context/phone_breakpoint";
import DesktopBreakpoint from "../../context/desktop_breakpoint";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    this.calendarTableRefs = React.createRef();
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      status: { active: false, sortBy: "desc" },
      created_at: { active: false, sortBy: "desc" },
      updated_at: { active: false, sortBy: "desc" }
    };
    this.state = {
      perPage: 20,
      refreshing: false,
      sortOrder: this.initialSortOrder,
      isLoading: true,
      sortValue: { isSortable: false },
      downloadModal: false,
      deleteModal: {
        id: undefined,
        modal: false,
        body: "Are you sure you want to delete this calendar.",
        title: "Confirm Delete"
      },
      refetch: true,
      searchVal: "",
      isEditing: true,
      isCreating: false,
      isViewingInventory: false,
      calendarId: undefined,
      dropdownOpen: false,
      filterDropdownOpen: false,
      action: "Edit",
      calendarToBeDownload: undefined,
      disableArchiveIcon: false,
      isCalendarListOpen: true,
      displayCreateInfo: true
    };
    this.handleLoadCalendar();
    this.calendarInputRef = React.createRef();
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  filterToggle = () => {
    this.setState(prevState => ({
      filterDropdownOpen: !prevState.filterDropdownOpen
    }));
  };

  handleLoadCalendar = () => {
    CampaignResources.getTotalCampaign.url = "/campaigns?page=1&per_page=20";
    if (this.state.searchVal) {
      calendarResources.getCalendar.url = `/calendars/search?search=${
        this.state.searchVal
      }&page=${1}&per_page=${this.perPageCount}`;
    } else {
      calendarResources.getCalendar.url = `/calendars?page=${1}&per_page=${
        this.perPageCount
      }`;
    }
    this.props
      .Get(calendarResources.getCalendar)
      .then(res => {
        if (res.status === 200) {
          if (res.data.data.length > 0) {
            this.setState({
              calendarId: res.data.data[0].id
            });
          }
          if (res.data.meta.totalPages <= res.data.meta.page) {
            this.setState({ refetch: false });
          }
        }
      })
      .then(() => {
        this.setState({
          isLoading: false
        });
      })
      .then(() => {
        this.props.Get(CampaignResources.getTotalCampaign);
      });
  };

  handleRefresh = () => {
    calendarResources.getCalendar.url = `/calendars?page=${1}&per_page=${
      this.perPageCount
    }`;
    this.setState(
      { refreshing: true, forcePage: 0, sortOrder: this.initialSortOrder },
      () => {
        const { getCalendar } = calendarResources;
        this.props
          .Get(getCalendar)
          .then(() => {
            setTimeout(() => {
              this.setState({ refreshing: false });
            }, 3000);
          })
          .then(() => this.props.showNotification("Refreshed", "info"));
      }
    );
  };

  handleSearchValue = value => {
    this.setState({ forcePage: 0, searchVal: value }, () => {
      this.handleSearch();
    });
  };

  handleSearch = debounce(() => {
    const { getCalendar } = calendarResources;
    if (this.state.searchVal === "") {
      getCalendar.url = `/calendars?page=${1}&per_page=${this.perPageCount}`;
      this.props.Get(getCalendar).then(() => this.setState({ refetch: true }));
    } else {
      getCalendar.url = `/calendars/search?search=${
        this.state.searchVal
      }&page=${1}&per_page=${this.perPageCount}`;
      this.props.Get(getCalendar).then(response => {
        if (response.data.meta.totalPages <= response.data.meta.page) {
          this.setState({ refetch: false });
        }
      });
    }
  }, 3000);

  handleSort = name => {
    this.setState({ forcePage: 0, isLoading: true });
    this.props.ReduxFormChange("SelectedCalendar", "calendarSelected", []);
    this.calendarTableRefs.current.selectionContext.selected = [];
    this.calendarTableRefs.current.setState({});
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getCalendar } = calendarResources;
        getCalendar.url = `/calendars/?sort=${name}&sort_by=${
          this.state.sortOrder[name].sortBy
        }&page=${1}&per_page=${this.perPageCount}`;
        this.props
          .Get(getCalendar)
          .then(() => this.setState({ isLoading: false }));
      }
    );
  };

  handleDownloadConfirmation = (flag, calendarToBeDownload = undefined) => {
    this.setState({ downloadModal: flag, calendarToBeDownload });
  };

  handleDeleteConfirmation = (id, name) => {
    const { deleteModal } = this.state;
    deleteModal.modal = true;
    deleteModal.id = id;
    deleteModal.body = (
      <span>
        <small>Are you sure you want to delete </small>
        <strong>{name}</strong>?
      </span>
    );

    this.setState({ deleteModal });
  };

  handleDelete = () => {
    const {
      deleteModal: { id }
    } = this.state;
    if (id) {
      this.props.Delete(calendarResources.deleteCalendar(id)).then(result => {
        if (result.status === 200) {
          this.props.RemoveItemFromCalendarList(id);
          this.handleLoadCalendar();
          // if (this.props.calendarsList.length === 0) {
          //   this.handlePageClick(this.props.meta, true);
          // }
        }
        const { deleteModal } = this.state;
        deleteModal.modal = false;
        deleteModal.id = undefined;
        this.setState({
          deleteModal
        });
      });
      return 0;
    }
    return 0;
  };

  handleArchiveItem = id => {
    const { postArchiveCalendar } = calendarResources;
    postArchiveCalendar.body = postArchivedItems({ filteredCalendarId: [id] });
    this.props.Put(postArchiveCalendar).then(
      () => this.props.Get(calendarResources.getCalendar)
      // then(result => {
      //   if (result.status === 200) {
      //     return (
      //       this.props.calendarsList.length === 0 &&
      //       this.handlePageClick(this.props.meta, true)
      //     );
      //   }
      // })
    );
  };

  handleArchiveItems = async (calendars, isDisabled) => {
    this.setState({ disableArchiveIcon: true });
    this.forceUpdate();
    if (!isDisabled) {
      const { postArchiveCalendar } = calendarResources;
      const filteredCalendarId = calendars.map(item => item.id);
      postArchiveCalendar.body = postArchivedItems({ filteredCalendarId });
      await this.props
        .Put(postArchiveCalendar)
        .then(
          () => this.props.Get(calendarResources.getCalendar)
          // .then(result => {
          //   if (result.status === 200) {
          //     return (
          //       this.props.calendarsList.length === 0 &&
          //       this.handlePageClick(this.props.meta, true)
          //     );
          //   }
          // })
        )
        .then(() =>
          this.props.ReduxFormChange("SelectedCalendar", "calendarSelected", [])
        )
        .then(() => {
          this.setState({ disableArchiveIcon: false });
        });
    }
  };

  handleDeleteCalendars = calendars => {
    const { removeBulkCalendar } = calendarResources;
    const filteredCalendarId = calendars.map(item => item.id);
    removeBulkCalendar.body = postArchivedItems({ filteredCalendarId });
    this.props.openGlobalModal({
      heading: "Delete Calendars",
      body: "Are you sure you want to delete?",
      actionFunc: () => {
        this.props
          .Put(removeBulkCalendar)
          .then(
            () => this.handleLoadCalendar()

            // .then(result => {
            //   if (result.status === 200) {
            //     return (
            //       this.props.calendarsList.length === 0 &&
            //       this.handlePageClick(this.props.meta, true)
            //     );
            //   }
            // })
          )
          .then(() =>
            this.props.ReduxFormChange(
              "SelectedCalendar",
              "calendarSelected",
              []
            )
          );
      }
    });
  };

  /**
   * @param {id}  calendar id
   */
  handleDuplicate = id => {
    this.props.Get(calendarResources.duplicateCalendar(id)).then(() => {
      this.props.Get(calendarResources.getCalendar);
    });
  };

  handleCalendarEdit = (type, id) => {
    this.activateIput();
    this.handleCalendarAction(type);
    this.setState({
      isCreating: false,
      isEditing: true,
      isViewingInventory: false,
      isCalendarListOpen: type === "Edit",
      calendarId: id
    });
    this.forceUpdate();
  };

  handleInventoryView = (type, id) => {
    this.handleCalendarAction(type);
    this.setState({
      isCreating: false,
      isEditing: false,
      isViewingInventory: true,
      isCalendarListOpen: true,
      calendarId: id
    });
    this.forceUpdate();
  };

  handleCalendarAction = action => {
    this.setState({ action });
    this.forceUpdate();
  };

  handleCalendarCreate = () => {
    this.handleCalendarAction("");
    // if (this.state.isCreating === false) {
    this.setState({
      isEditing: false,
      isCreating: true,
      isCalendarListOpen: false,
      isViewingInventory: false
    });
    this.forceUpdate();
    setTimeout(this.activateIput, 400);
    // }
  };

  activateIput = () => {
    if (this.calendarInputRef.current) {
      this.calendarInputRef.current.readOnly = false;
      this.calendarInputRef.current.focus();
      this.calendarInputRef.current.value = this.calendarInputRef.current.value;
    }
  };

  disableInput = () => {
    if (this.calendarInputRef.current) {
      this.calendarInputRef.current.readOnly = false;
      this.calendarInputRef.current.blur();
    }
  };

  toggleModel = flag => {
    const { deleteModal } = this.state;
    deleteModal.modal = flag;
    this.setState({ deleteModal });
  };

  handleNext = async () => {
    if (this.state.refetch) {
      this.setState(
        prevState => ({
          perPage: prevState.perPage + 20,
          page: prevState.page + 1
        }),
        () => {
          if (!this.state.sortValue.isSortable) {
            calendarResources.getCalendar.url = `/calendars?page=1&per_page=${this.state.perPage}`;
            this.props.Get(calendarResources.getCalendar).then(response => {
              if (response.data.meta.totalPages <= response.data.meta.page) {
                this.setState({ refetch: false });
              }
            });
          } else {
            calendarResources.getCalendar.url = `/calendars?sort=${this.state.sortValue.name}&sort_by=${this.state.sortValue.sortBy}&page=1&per_page=${this.state.perPage}`;
            this.props.Get(calendarResources.getCalendar).then(response => {
              if (response.data.meta.totalPages <= response.data.meta.page) {
                this.setState({ refetch: false });
              }
            });
          }
        }
      );
    }
    return true;
  };

  toggleCalendarList = flag => {
    this.setState({
      isCalendarListOpen: flag
    });
  };

  displayCreateInfo = () => {
    this.setState({ displayCreateInfo: false });
  };

  /* ------------- For Pagination ----------------

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    const { getCalendar } = calendarResources;
    if (moveToPrevious) {
      getCalendar.url = `/calendars?page=${data.page - 1}&per_page=${
        this.perPageCount
      }`;
      this.props.Get(getCalendar).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      this.setState({ forcePage: undefined });
      this.setState({ isLoading: true });
      const { selected } = data;
      if (this.state.sortValue.isSortable) {
        getCalendar.url = `/calendars?sort=${
          this.state.sortValue.name
        }&sort_by=${this.state.sortValue.sortBy}&page=${selected +
          1}&per_page=${this.perPageCount}`;
      } else {
        getCalendar.url = `/calendars?page=${selected + 1}&per_page=${
          this.perPageCount
        }`;
      }
      this.props
        .Get(getCalendar)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  */

  handleCalendarDisplay = () => {
    const {
      isEditing,
      isCreating,
      isViewingInventory,
      calendarId,
      action
    } = this.state;
    if (isEditing) {
      return this.props.calendarsList.length > 0 ? (
        <EditCalendar
          calendarId={calendarId}
          action={this.state.action}
          activateIput={this.activateIput}
          disableInput={this.disableInput}
          handleCalendarAction={this.handleCalendarAction}
          handleCalendarEdit={this.handleCalendarEdit}
          calendarInputRef={this.calendarInputRef}
          isCalendarListOpen={this.state.isCalendarListOpen}
          toggleCalendarList={this.toggleCalendarList}
          {...this.props}
        />
      ) : (
        <CreateCalender
          action={this.state.action}
          handleCalendarAction={this.handleCalendarAction}
          handleCalendarEdit={this.handleCalendarEdit}
          calendarsList={this.props.calendarsList}
          calendarInputRef={this.calendarInputRef}
          activateIput={this.activateIput}
          disableInput={this.disableInput}
          isCalendarListOpen={this.state.isCalendarListOpen}
          toggleCalendarList={this.toggleCalendarList}
          {...this.props}
        />
      );
    }
    if (isCreating) {
      return (
        <CreateCalender
          action={this.state.action}
          handleCalendarAction={this.handleCalendarAction}
          handleCalendarEdit={this.handleCalendarEdit}
          calendarsList={this.props.calendarsList}
          calendarInputRef={this.calendarInputRef}
          activateIput={this.activateIput}
          disableInput={this.disableInput}
          isCalendarListOpen={this.state.isCalendarListOpen}
          toggleCalendarList={this.toggleCalendarList}
          {...this.props}
        />
      );
    }
    if (isViewingInventory) {
      return (
        <CalendarInventory
          calendarId={calendarId}
          action={this.state.action}
          handleCalendarAction={this.handleCalendarAction}
          handleCalendarEdit={this.handleCalendarEdit}
          calendarsList={this.props.calendarsList}
          calendarInputRef={this.calendarInputRef}
          activateIput={this.activateIput}
          disableInput={this.disableInput}
          isCalendarListOpen={this.state.isCalendarListOpen}
          toggleCalendarList={this.toggleCalendarList}
          {...this.props}
        />
      );
    }
  };

  render() {
    const {
      deleteModal,
      downloadModal,
      isLoading,
      displayCreateInfo
    } = this.state;
    if (isLoading) {
      return (
        <div className="d-block text-center">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    return (
      <div className="mainPage">
        <div className="contentSection">
          {" "}
          <Dialog
            externalControl
            showHandler={false}
            modal={deleteModal.modal}
            body={deleteModal.body}
            title={deleteModal.title}
            toggleModel={this.toggleModel}
            primaryMethod={this.handleDelete}
          />
          <DownloadCalendarForUSB
            calendar={this.state.calendarToBeDownload}
            downloadModal={downloadModal}
            toggle={this.handleDownloadConfirmation}
            {...this.props}
          />
          {/* <CalendarHeader
            refreshing={refreshing}
            handleRefresh={this.handleRefresh}
            handleSearch={this.handleSearchValue}
            campaignList={this.props.campaignList || []}
            handleSubmitNetworkSelector={this.handleSubmitNetworkSelector}
            handleStatusFilter={this.handleStatusFilter}
            handleArchiveItems={this.handleArchiveItems}
            handleDeleteCalendars={this.handleDeleteCalendars}
            handleCalendarCreate={this.handleCalendarCreate}
            isCreating={this.state.isCreating}
            {...this.props}
          /> */}
          <ReactTooltip />
          <div
            className={`row calendarSection content-wrap ${this.state
              .isCalendarListOpen && "calendarListVisible"}`}
          >
            <div
              className={`col-md-3 calendarSection__leftSection hideLayoutCalendarLeftSection ${
                !this.state.isCalendarListOpen
                  ? "d-none displayLayoutCalendarLeftSection"
                  : ""
              }`}
            >
              <div className="calendarSection__leftSection__listWrapper">
                <PhoneBreakpoint>
                  {" "}
                  <i
                    role="presentation"
                    className="fa fa-times timesIcon"
                    onClick={() => this.toggleCalendarList(true)}
                  />
                </PhoneBreakpoint>
                <div className=" calendarSection__leftSection__header calendarHeaderSearch ">
                  <DesktopBreakpoint>
                    <CalendarHeaderForDesktop
                      handleCalendarCreate={this.handleCalendarCreate}
                      {...this.props}
                    />
                  </DesktopBreakpoint>
                  <PhoneBreakpoint>
                    <CalendarHeaderForMobile
                      handleCalendarCreate={this.handleCalendarCreate}
                      toggleCalendarList={this.toggleCalendarList}
                      {...this.props}
                    />{" "}
                  </PhoneBreakpoint>
                  <SearchTagRefresh
                    handleSearch={this.handleSearchValue}
                    {...this.props}
                  />
                </div>
                <InfiniteScroll
                  dataLength={this.props.calendarsList.length} // This is important field to render the next data
                  next={this.handleNext}
                  hasMore={this.state.refetch}
                  height={820}
                  loader={
                    <div className="d-block text-center">
                      <span>...Loading</span>
                    </div>
                  }
                  // endMessage={
                  //   <p style={{ textAlign: "center" }}>
                  //     <b>
                  //       {this.props.calendarsList.length > 0
                  //         ? "This is the end of Calendar Section."
                  //         : "No data"}
                  //     </b>
                  //   </p>
                  // }
                  style={{ overFlow: "visible" }}
                >
                  <CalendarTable
                    {...this.props}
                    calendarId={this.state.calendarId}
                    forwardRef={this.calendarTableRefs}
                    handleDelete={this.handleDeleteConfirmation}
                    handleDownload={this.handleDownloadConfirmation}
                    handleChange={this.handleChange}
                    handlePause={this.handlePause}
                    handleSort={this.handleSort}
                    sortOrder={this.state.sortOrder}
                    handleDuplicate={this.handleDuplicate}
                    isCreating={this.state.isCreating}
                    handleCalendarEdit={this.handleCalendarEdit}
                    // isLoading={this.state.isLoading}
                    calendarsList={this.props.calendarsList}
                    handleArchiveItem={this.handleArchiveItem}
                    dropdownOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                    handleArchiveItems={this.handleArchiveItems}
                    handleDeleteCalendars={this.handleDeleteCalendars}
                    filterDropdownOpen={this.state.filterDropdownOpen}
                    filterToggle={this.filterToggle}
                    disableArchiveIcon={this.state.disableArchiveIcon}
                    toogleCalandarList={this.toogleCalandarList}
                    handleInventoryView={this.handleInventoryView}
                  />
                </InfiniteScroll>
              </div>
            </div>{" "}
            <div
              className={`${
                !this.state.isCalendarListOpen ? "col-md-12" : "col-md-9"
              } big-calendar bigCalendarMobileDisplay`}
            >
              {this.handleCalendarDisplay()}
            </div>
          </div>
          {this.state.isCreating && displayCreateInfo && (
            <div className="calendarEventNotifier  animated fadeInRight">
              <i className="fas fa-info-circle info-icon" />
              <p>Click or Select the slot to create an event.</p>
              <i
                role="presentation"
                className="fa fa-times"
                onClick={this.displayCreateInfo}
              />
            </div>
          )}
          {/*
          {!this.state.isLoading &&
            this.props.calendarsList &&
            this.props.calendarsList.length > 0 && (
              <div className="contentSectionPagination clearfix">
                <ReactPaginate
                  pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  marginPagesDisplayed={2}
                  // activeClassName="activePage"
                  // containerClassName="contentPagination"
                  // subContainerClassName="pages pagination"
                  previousLabel="<"
                  forcePage={this.state.forcePage}
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  disabledClassName="paginationDisable"
                />
              </div>
            )} */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list, status, loading, hasError, meta } = state.calendars;
  return {
    calendarsList: list !== undefined ? list : [],
    loading,
    hasError,
    meta,
    status
  };
}

export default connect(
  mapStateToProps,
  { RemoveItemFromCalendarList, ReduxFormChange, destroy, initialize }
)(Calendar);
