import React, { Component } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { remove, differenceBy } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import overlayFactory from "react-bootstrap-table2-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { columns } from "../utils/playlistUtils";
import RouteAllowed from "../../../helper/isRouteAllowed";
import PlaylistHeader from "./playlistHeader";

// eslint-disable-next-line react/prefer-stateless-function
class PlaylistTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        date_created: false,
        date_updated: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      selectColumnStyle: { width: "40px" },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("playlistSelected", [
            ...this.props.playlistSelected,
            row
          ]);
        } else {
          const { playlistSelected } = this.props;
          remove(playlistSelected, { id: row.id });
          this.props.change("playlistSelected", playlistSelected);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("playlistSelected", [
            ...this.props.playlistSelected,
            ...rows
          ]);
        } else {
          const { playlistSelected } = this.props;
          const formattedPlaylist = differenceBy(playlistSelected, rows, "id");
          this.props.change("playlistSelected", formattedPlaylist);
        }
      }
    };

    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.tableRef}
          keyField="id"
          data={this.props.playlistList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleSort,
            this.props.sortOrder,
            this.props.handleDuplicate,
            this.props.handleArchiveItem,
            this.state.hiddenColumns,
            this.props.handleLockUnLockPlaylist
          )}
          columnToggle
        >
          {props => (
            <div>
              <PlaylistHeader
                forwardRef={this.props.searchRef}
                refreshing={this.props.refreshing}
                handleRefresh={this.props.handleRefresh}
                handleStatusFilter={this.props.handleStatusFilter}
                handleFilter={this.props.handleFilter}
                handleSearchValue={this.props.handleSearchValue}
                handleFilterWithType={this.props.handleFilterWithType}
                handleArchiveItems={this.props.handleArchiveItems}
                disableArchiveIcon={this.props.disableArchiveIcon}
                handleDeletePlaylists={this.props.handleDeletePlaylists}
                // below needed for col visibility toggler
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                {...this.props}
              />
              <BootstrapTable
                ref={this.props.tableRef}
                classes="contentSectionTable playlistTable"
                wrapperClasses="table-responsive content-wrap"
                loading={this.props.isLoading}
                bordered={false}
                selectRow={selectRow}
                hover
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                rowEvents={{
                  onClick: (e, row) => {
                    e.stopPropagation();
                    if (
                      row.attributes.locked &&
                      !this.props.permission.playlist.lock.some(r =>
                        this.props.roles.includes(r)
                      )
                    ) {
                      return null;
                    }
                    this.props.history.push(`/playlist/edit/${row.id}/`);
                  },
                  style: {
                    cursor: "pointer"
                  }
                }}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
export default connect(state => {
  const selector = formValueSelector("SelectedPlaylists");
  return {
    playlistSelected: selector(state, "playlistSelected") || []
  };
})(
  reduxForm({
    form: "SelectedPlaylists"
  })(PlaylistTable)
);
