const timeZone = [
  {
    Location: "Africa/Abidjan",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Accra",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Addis_Ababa",
    GMT: "GMT +03:00"
  },
  {
    Location: "Africa/Algiers",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Asmara",
    GMT: "GMT +03:00"
  },
  {
    Location: "Africa/Bamako",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Bangui",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Banjul",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Bissau",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Blantyre",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Brazzaville",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Bujumbura",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Cairo",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Casablanca",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Ceuta",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Conakry",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Dakar",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Dar_es_Salaam",
    GMT: "GMT +03:00"
  },
  {
    Location: "Africa/Djibouti",
    GMT: "GMT +03:00"
  },
  {
    Location: "Africa/Douala",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/El_Aaiun",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Freetown",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Gaborone",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Harare",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Johannesburg",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Juba",
    GMT: "GMT +03:00"
  },
  {
    Location: "Africa/Kampala",
    GMT: "GMT +03:00"
  },
  {
    Location: "Africa/Khartoum",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Kigali",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Kinshasa",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Lagos",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Libreville",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Lome",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Luanda",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Lubumbashi",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Lusaka",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Malabo",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Maputo",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Maseru",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Mbabane",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Mogadishu",
    GMT: "GMT +03:00"
  },
  {
    Location: "Africa/Monrovia",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Nairobi",
    GMT: "GMT +03:00"
  },
  {
    Location: "Africa/Ndjamena",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Niamey",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Nouakchott",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Ouagadougou",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Porto-Novo",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Sao_Tome",
    GMT: "GMT +00:00"
  },
  {
    Location: "Africa/Tripoli",
    GMT: "GMT +02:00"
  },
  {
    Location: "Africa/Tunis",
    GMT: "GMT +01:00"
  },
  {
    Location: "Africa/Windhoek",
    GMT: "GMT +02:00"
  },
  {
    Location: "America/Adak",
    GMT: "GMT -09:00"
  },
  {
    Location: "America/Anchorage",
    GMT: "GMT -08:00"
  },
  {
    Location: "America/Anguilla",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Antigua",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Araguaina",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/Buenos_Aires",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/Catamarca",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/Cordoba",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/Jujuy",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/La_Rioja",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/Mendoza",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/Rio_Gallegos",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/Salta",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/San_Juan",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/San_Luis",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/Tucuman",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Argentina/Ushuaia",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Aruba",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Asuncion",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Atikokan",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Bahia",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Bahia_Banderas",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Barbados",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Belem",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Belize",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Blanc-Sablon",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Boa_Vista",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Bogota",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Boise",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Cambridge_Bay",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Campo_Grande",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Cancun",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Caracas",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Cayenne",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Cayman",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Chicago",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Chihuahua",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Costa_Rica",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Creston",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Cuiaba",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Curacao",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Danmarkshavn",
    GMT: "GMT +00:00"
  },
  {
    Location: "America/Dawson",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Dawson_Creek",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Denver",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Detroit",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Dominica",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Edmonton",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Eirunepe",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/El_Salvador",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Fort_Nelson",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Fortaleza",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Glace_Bay",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Godthab",
    GMT: "GMT -02:00"
  },
  {
    Location: "America/Goose_Bay",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Grand_Turk",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Grenada",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Guadeloupe",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Guatemala",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Guayaquil",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Guyana",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Halifax",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Havana",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Hermosillo",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Indiana/Indianapolis",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Indiana/Knox",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Indiana/Marengo",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Indiana/Petersburg",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Indiana/Tell_City",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Indiana/Vevay",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Indiana/Vincennes",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Indiana/Winamac",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Inuvik",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Iqaluit",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Jamaica",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Juneau",
    GMT: "GMT -08:00"
  },
  {
    Location: "America/Kentucky/Louisville",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Kentucky/Monticello",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Kralendijk",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/La_Paz",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Lima",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Los_Angeles",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Lower_Princes",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Maceio",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Managua",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Manaus",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Marigot",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Martinique",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Matamoros",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Mazatlan",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Menominee",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Merida",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Metlakatla",
    GMT: "GMT -08:00"
  },
  {
    Location: "America/Mexico_City",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Miquelon",
    GMT: "GMT -02:00"
  },
  {
    Location: "America/Moncton",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Monterrey",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Montevideo",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Montserrat",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Nassau",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/New_York",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Nipigon",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Nome",
    GMT: "GMT -08:00"
  },
  {
    Location: "America/Noronha",
    GMT: "GMT -02:00"
  },
  {
    Location: "America/North_Dakota/Beulah",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/North_Dakota/Center",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/North_Dakota/New_Salem",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Ojinaga",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Panama",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Pangnirtung",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Paramaribo",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Phoenix",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Port-au-Prince",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Port_of_Spain",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Porto_Velho",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Puerto_Rico",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Punta_Arenas",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Rainy_River",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Rankin_Inlet",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Recife",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Regina",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Resolute",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Rio_Branco",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Santarem",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Santiago",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Santo_Domingo",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Sao_Paulo",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Scoresbysund",
    GMT: "GMT +00:00"
  },
  {
    Location: "America/Sitka",
    GMT: "GMT -08:00"
  },
  {
    Location: "America/St_Barthelemy",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/St_Johns",
    GMT: "GMT -02:30"
  },
  {
    Location: "America/St_Kitts",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/St_Lucia",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/St_Thomas",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/St_Vincent",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Swift_Current",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Tegucigalpa",
    GMT: "GMT -06:00"
  },
  {
    Location: "America/Thule",
    GMT: "GMT -03:00"
  },
  {
    Location: "America/Thunder_Bay",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Tijuana",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Toronto",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Tortola",
    GMT: "GMT -04:00"
  },
  {
    Location: "America/Vancouver",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Whitehorse",
    GMT: "GMT -07:00"
  },
  {
    Location: "America/Winnipeg",
    GMT: "GMT -05:00"
  },
  {
    Location: "America/Yakutat",
    GMT: "GMT -08:00"
  },
  {
    Location: "America/Yellowknife",
    GMT: "GMT -06:00"
  },
  {
    Location: "Antarctica/Casey",
    GMT: "GMT +08:00"
  },
  {
    Location: "Antarctica/Davis",
    GMT: "GMT +07:00"
  },
  {
    Location: "Antarctica/DumontDUrville",
    GMT: "GMT +10:00"
  },
  {
    Location: "Antarctica/Macquarie",
    GMT: "GMT +11:00"
  },
  {
    Location: "Antarctica/Mawson",
    GMT: "GMT +05:00"
  },
  {
    Location: "Antarctica/McMurdo",
    GMT: "GMT +12:00"
  },
  {
    Location: "Antarctica/Palmer",
    GMT: "GMT -03:00"
  },
  {
    Location: "Antarctica/Rothera",
    GMT: "GMT -03:00"
  },
  {
    Location: "Antarctica/Syowa",
    GMT: "GMT +03:00"
  },
  {
    Location: "Antarctica/Troll",
    GMT: "GMT +02:00"
  },
  {
    Location: "Antarctica/Vostok",
    GMT: "GMT +06:00"
  },
  {
    Location: "Arctic/Longyearbyen",
    GMT: "GMT +02:00"
  },
  {
    Location: "Asia/Aden",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Almaty",
    GMT: "GMT +06:00"
  },
  {
    Location: "Asia/Amman",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Anadyr",
    GMT: "GMT +12:00"
  },
  {
    Location: "Asia/Aqtau",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Aqtobe",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Ashgabat",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Atyrau",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Baghdad",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Bahrain",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Baku",
    GMT: "GMT +04:00"
  },
  {
    Location: "Asia/Bangkok",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Barnaul",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Beirut",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Bishkek",
    GMT: "GMT +06:00"
  },
  {
    Location: "Asia/Brunei",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Chita",
    GMT: "GMT +09:00"
  },
  {
    Location: "Asia/Choibalsan",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Colombo",
    GMT: "GMT +05:30"
  },
  {
    Location: "Asia/Damascus",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Dhaka",
    GMT: "GMT +06:00"
  },
  {
    Location: "Asia/Dili",
    GMT: "GMT +09:00"
  },
  {
    Location: "Asia/Dubai",
    GMT: "GMT +04:00"
  },
  {
    Location: "Asia/Dushanbe",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Famagusta",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Gaza",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Hebron",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Ho_Chi_Minh",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Hong_Kong",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Hovd",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Irkutsk",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Jakarta",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Jayapura",
    GMT: "GMT +09:00"
  },
  {
    Location: "Asia/Jerusalem",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Kabul",
    GMT: "GMT +04:30"
  },
  {
    Location: "Asia/Kamchatka",
    GMT: "GMT +12:00"
  },
  {
    Location: "Asia/Karachi",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Kathmandu",
    GMT: "GMT +05:45"
  },
  {
    Location: "Asia/Khandyga",
    GMT: "GMT +09:00"
  },
  {
    Location: "Asia/Kolkata",
    GMT: "GMT +05:30"
  },
  {
    Location: "Asia/Krasnoyarsk",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Kuala_Lumpur",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Kuching",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Kuwait",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Macau",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Magadan",
    GMT: "GMT +11:00"
  },
  {
    Location: "Asia/Makassar",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Manila",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Muscat",
    GMT: "GMT +04:00"
  },
  {
    Location: "Asia/Nicosia",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Novokuznetsk",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Novosibirsk",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Omsk",
    GMT: "GMT +06:00"
  },
  {
    Location: "Asia/Oral",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Phnom_Penh",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Pontianak",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Pyongyang",
    GMT: "GMT +09:00"
  },
  {
    Location: "Asia/Qatar",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Qostanay",
    GMT: "GMT +06:00"
  },
  {
    Location: "Asia/Qyzylorda",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Riyadh",
    GMT: "GMT +03:00"
  },
  {
    Location: "Asia/Sakhalin",
    GMT: "GMT +11:00"
  },
  {
    Location: "Asia/Samarkand",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Seoul",
    GMT: "GMT +09:00"
  },
  {
    Location: "Asia/Shanghai",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Singapore",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Srednekolymsk",
    GMT: "GMT +11:00"
  },
  {
    Location: "Asia/Taipei",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Tashkent",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Tbilisi",
    GMT: "GMT +04:00"
  },
  {
    Location: "Asia/Tehran",
    GMT: "GMT +04:30"
  },
  {
    Location: "Asia/Thimphu",
    GMT: "GMT +06:00"
  },
  {
    Location: "Asia/Tokyo",
    GMT: "GMT +09:00"
  },
  {
    Location: "Asia/Tomsk",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Ulaanbaatar",
    GMT: "GMT +08:00"
  },
  {
    Location: "Asia/Urumqi",
    GMT: "GMT +06:00"
  },
  {
    Location: "Asia/Ust-Nera",
    GMT: "GMT +10:00"
  },
  {
    Location: "Asia/Vientiane",
    GMT: "GMT +07:00"
  },
  {
    Location: "Asia/Vladivostok",
    GMT: "GMT +10:00"
  },
  {
    Location: "Asia/Yakutsk",
    GMT: "GMT +09:00"
  },
  {
    Location: "Asia/Yangon",
    GMT: "GMT +06:30"
  },
  {
    Location: "Asia/Yekaterinburg",
    GMT: "GMT +05:00"
  },
  {
    Location: "Asia/Yerevan",
    GMT: "GMT +04:00"
  },
  {
    Location: "Atlantic/Azores",
    GMT: "GMT +00:00"
  },
  {
    Location: "Atlantic/Bermuda",
    GMT: "GMT -03:00"
  },
  {
    Location: "Atlantic/Canary",
    GMT: "GMT +01:00"
  },
  {
    Location: "Atlantic/Cape_Verde",
    GMT: "GMT -01:00"
  },
  {
    Location: "Atlantic/Faroe",
    GMT: "GMT +01:00"
  },
  {
    Location: "Atlantic/Madeira",
    GMT: "GMT +01:00"
  },
  {
    Location: "Atlantic/Reykjavik",
    GMT: "GMT +00:00"
  },
  {
    Location: "Atlantic/South_Georgia",
    GMT: "GMT -02:00"
  },
  {
    Location: "Atlantic/St_Helena",
    GMT: "GMT +00:00"
  },
  {
    Location: "Atlantic/Stanley",
    GMT: "GMT -03:00"
  },
  {
    Location: "Australia/Adelaide",
    GMT: "GMT +09:30"
  },
  {
    Location: "Australia/Brisbane",
    GMT: "GMT +10:00"
  },
  {
    Location: "Australia/Broken_Hill",
    GMT: "GMT +09:30"
  },
  {
    Location: "Australia/Currie",
    GMT: "GMT +10:00"
  },
  {
    Location: "Australia/Darwin",
    GMT: "GMT +09:30"
  },
  {
    Location: "Australia/Eucla",
    GMT: "GMT +08:45"
  },
  {
    Location: "Australia/Hobart",
    GMT: "GMT +10:00"
  },
  {
    Location: "Australia/Lindeman",
    GMT: "GMT +10:00"
  },
  {
    Location: "Australia/Lord_Howe",
    GMT: "GMT +10:30"
  },
  {
    Location: "Australia/Melbourne",
    GMT: "GMT +10:00"
  },
  {
    Location: "Australia/Perth",
    GMT: "GMT +08:00"
  },
  {
    Location: "Australia/Sydney",
    GMT: "GMT +10:00"
  },
  {
    Location: "Europe/Amsterdam",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Andorra",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Astrakhan",
    GMT: "GMT +04:00"
  },
  {
    Location: "Europe/Athens",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Belgrade",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Berlin",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Bratislava",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Brussels",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Bucharest",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Budapest",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Busingen",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Chisinau",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Copenhagen",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Dublin",
    GMT: "GMT +01:00"
  },
  {
    Location: "Europe/Gibraltar",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Guernsey",
    GMT: "GMT +01:00"
  },
  {
    Location: "Europe/Helsinki",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Isle_of_Man",
    GMT: "GMT +01:00"
  },
  {
    Location: "Europe/Istanbul",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Jersey",
    GMT: "GMT +01:00"
  },
  {
    Location: "Europe/Kaliningrad",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Kiev",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Kirov",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Lisbon",
    GMT: "GMT +01:00"
  },
  {
    Location: "Europe/Ljubljana",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/London",
    GMT: "GMT +01:00"
  },
  {
    Location: "Europe/Luxembourg",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Madrid",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Malta",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Mariehamn",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Minsk",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Monaco",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Moscow",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Oslo",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Paris",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Podgorica",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Prague",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Riga",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Rome",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Samara",
    GMT: "GMT +04:00"
  },
  {
    Location: "Europe/San_Marino",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Sarajevo",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Saratov",
    GMT: "GMT +04:00"
  },
  {
    Location: "Europe/Simferopol",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Skopje",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Sofia",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Stockholm",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Tallinn",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Tirane",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Ulyanovsk",
    GMT: "GMT +04:00"
  },
  {
    Location: "Europe/Uzhgorod",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Vaduz",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Vatican",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Vienna",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Vilnius",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Volgograd",
    GMT: "GMT +04:00"
  },
  {
    Location: "Europe/Warsaw",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Zagreb",
    GMT: "GMT +02:00"
  },
  {
    Location: "Europe/Zaporozhye",
    GMT: "GMT +03:00"
  },
  {
    Location: "Europe/Zurich",
    GMT: "GMT +02:00"
  },
  {
    Location: "Indian/Antananarivo",
    GMT: "GMT +03:00"
  },
  {
    Location: "Indian/Chagos",
    GMT: "GMT +06:00"
  },
  {
    Location: "Indian/Christmas",
    GMT: "GMT +07:00"
  },
  {
    Location: "Indian/Cocos",
    GMT: "GMT +06:30"
  },
  {
    Location: "Indian/Comoro",
    GMT: "GMT +03:00"
  },
  {
    Location: "Indian/Kerguelen",
    GMT: "GMT +05:00"
  },
  {
    Location: "Indian/Mahe",
    GMT: "GMT +04:00"
  },
  {
    Location: "Indian/Maldives",
    GMT: "GMT +05:00"
  },
  {
    Location: "Indian/Mauritius",
    GMT: "GMT +04:00"
  },
  {
    Location: "Indian/Mayotte",
    GMT: "GMT +03:00"
  },
  {
    Location: "Indian/Reunion",
    GMT: "GMT +04:00"
  },
  {
    Location: "Pacific/Apia",
    GMT: "GMT +13:00"
  },
  {
    Location: "Pacific/Auckland",
    GMT: "GMT +12:00"
  },
  {
    Location: "Pacific/Bougainville",
    GMT: "GMT +11:00"
  },
  {
    Location: "Pacific/Chatham",
    GMT: "GMT +12:45"
  },
  {
    Location: "Pacific/Chuuk",
    GMT: "GMT +10:00"
  },
  {
    Location: "Pacific/Easter",
    GMT: "GMT -06:00"
  },
  {
    Location: "Pacific/Efate",
    GMT: "GMT +11:00"
  },
  {
    Location: "Pacific/Enderbury",
    GMT: "GMT +13:00"
  },
  {
    Location: "Pacific/Fakaofo",
    GMT: "GMT +13:00"
  },
  {
    Location: "Pacific/Fiji",
    GMT: "GMT +12:00"
  },
  {
    Location: "Pacific/Funafuti",
    GMT: "GMT +12:00"
  },
  {
    Location: "Pacific/Galapagos",
    GMT: "GMT -06:00"
  },
  {
    Location: "Pacific/Gambier",
    GMT: "GMT -09:00"
  },
  {
    Location: "Pacific/Guadalcanal",
    GMT: "GMT +11:00"
  },
  {
    Location: "Pacific/Guam",
    GMT: "GMT +10:00"
  },
  {
    Location: "Pacific/Honolulu",
    GMT: "GMT -10:00"
  },
  {
    Location: "Pacific/Kiritimati",
    GMT: "GMT +14:00"
  },
  {
    Location: "Pacific/Kosrae",
    GMT: "GMT +11:00"
  },
  {
    Location: "Pacific/Kwajalein",
    GMT: "GMT +12:00"
  },
  {
    Location: "Pacific/Majuro",
    GMT: "GMT +12:00"
  },
  {
    Location: "Pacific/Marquesas",
    GMT: "GMT -09:30"
  },
  {
    Location: "Pacific/Midway",
    GMT: "GMT -11:00"
  },
  {
    Location: "Pacific/Nauru",
    GMT: "GMT +12:00"
  },
  {
    Location: "Pacific/Niue",
    GMT: "GMT -11:00"
  },
  {
    Location: "Pacific/Norfolk",
    GMT: "GMT +11:00"
  },
  {
    Location: "Pacific/Noumea",
    GMT: "GMT +11:00"
  },
  {
    Location: "Pacific/Pago_Pago",
    GMT: "GMT -11:00"
  },
  {
    Location: "Pacific/Palau",
    GMT: "GMT +09:00"
  },
  {
    Location: "Pacific/Pitcairn",
    GMT: "GMT -08:00"
  },
  {
    Location: "Pacific/Pohnpei",
    GMT: "GMT +11:00"
  },
  {
    Location: "Pacific/Port_Moresby",
    GMT: "GMT +10:00"
  },
  {
    Location: "Pacific/Rarotonga",
    GMT: "GMT -10:00"
  },
  {
    Location: "Pacific/Saipan",
    GMT: "GMT +10:00"
  },
  {
    Location: "Pacific/Tahiti",
    GMT: "GMT -10:00"
  },
  {
    Location: "Pacific/Tarawa",
    GMT: "GMT +12:00"
  },
  {
    Location: "Pacific/Tongatapu",
    GMT: "GMT +13:00"
  },
  {
    Location: "Pacific/Wake",
    GMT: "GMT +12:00"
  },
  {
    Location: "Pacific/Wallis",
    GMT: "GMT +12:00"
  },
  {
    Location: "UTC",
    GMT: "GMT +00:00"
  }
];

export const timeZones = timeZone.map(item => ({
  value: item.Location,
  label: `(${item.GMT}) ${item.Location}`
}));

export const timeZonesList = timeZone;
