/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Row, Col } from "reactstrap";
import { change } from "redux-form";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import TimeLineContentSort from "../sortTimelineContent";
import { RangeSlider, PopOver } from "../../../../core";
import AddToTimeLine from "../addToTimeLine";
import { clearSelectedList } from "../../redux";
import { TextClipper } from "../../../../helper";

function handleMinutes(duration) {
  const second = Number(duration.minute * 60) + Number(duration.second);
  return (Math.pow(second / 100, 0.5) + 5) * 100;
}
const playlistImg = require("../../../../assets/images/playlist.png");
/**
 * There are code which was required in previous view but new view does not required any code.
 */
const ScreenNameBar = props => (
  <div className="splitscreen-section">
    {/* <i className="far fa-eye float-left mr-1 mt-1" /> */}
    <span className="splitscreen-section__textedit">
      <span className="input-edittext timelineScreenTitle ">
        {props.screenTitle}
      </span>
      <PopOver
        id={`sound-${props.id}`}
        target={
          <span
            id={`sound-${props.id}`}
            className={`screen-sound-icon ${
              props.volume === 0 ? "fas fa-volume-mute" : "fas fa-volume-up"
            }`}
          />
        }
      >
        <RangeSlider
          min={0}
          max={100}
          handleChange={value => {
            props.change("SplitScreen", `screen${props.id}`, {
              ...props.singleTimeLine,
              volume: value
            });
          }}
          value={props.volume}
        />
      </PopOver>
      {/* <i className="fa fa-pencil-alt mt-1" /> */}
    </span>
    {/* <i className="fa fa-eye-slash float-left mt-1" style={{ display: "none;" }} /> */}{" "}
    {/* need to display when the screen is unview */}
    {/*  */}
  </div>
);

const SortableItem = SortableElement(props => {
  if (props.value.type !== "playlists") {
    return (
      <li style={{ display: "inline-block" }}>
        <div
          style={{
            height: "100%",
            zIndex: 100000,
            width: `${handleMinutes(props.value.duration)}px`,
            minHeight: "50px",
            backgroundPostion: "center",
            backgroundSize: "auto 100px",
            backgroundImage: `url(${props.value.attributes
              .s3_thumbnail_medium_url || null})`
          }}
        />
      </li>
    );
  }
  return (
    <li style={{ display: "inline-block" }}>
      <div
        className="text-center"
        style={{
          height: "100%",
          zIndex: 100000,
          width: "150px",
          minHeight: "50px",
          paddingTop: "12px",
          border: "1px solid papayawhip",
          backgroundPostion: "center",
          backgroundSize: "auto 70px",
          backgroundImage: playlistImg
        }}
      >
        {TextClipper(props.value.attributes.name, 30)}
      </div>
    </li>
  );
});

const SortableList = SortableContainer(props => (
  <ul
    className="list-grp"
    style={{ overflowY: "hidden", whiteSpace: "nowrap" }}
  >
    {props.content.map((value, index) => (
      <SortableItem
        key={`item-${value.id}-${index}`}
        id={props.id}
        value={value}
        index={index}
        {...props}
      />
    ))}
  </ul>
));

function handleDragEnter(event) {
  if (
    event.target.className == "droppingZoneOfCampaign col-md-10" ||
    event.target.className == "list-grp"
  ) {
    event.target.style.setProperty("background-color", "#f2f3f7", "important");
  }
}

function handleDragLeave(event) {
  if (
    event.target.className == "droppingZoneOfCampaign col-md-10" ||
    event.target.className == "list-grp"
  ) {
    event.target.style.background = "";
  }
}

class SingleTimeLine extends React.Component {
  state = {
    modal: false
  };

  handleDragOver = e => {
    e.preventDefault();
  };

  handleOnDrop = e => {
    e.stopPropagation();
    if (
      e.target.className == "droppingZoneOfCampaign col-md-10" ||
      e.target.className == "list-grp"
    ) {
      e.target.style.background = "";
    }
    e.preventDefault();
    const { selectedList } = this.props;
    // if (selectedList[0].type === "playlists") {
    //   this.getDurationDetail({ minute: 0, second: 0 }, true);
    // } else {
    this.toggle(true);
    // }
  };

  toggle = toggleFlag => {
    this.setState({
      modal: toggleFlag
    });
  };

  getDurationDetail = (
    selectedListTime,
    playlist = false,
    usePlaylistContentDuration = false
  ) => {
    const { selectedList, id, singleTimeLine, content } = this.props;
    selectedList.map(item => {
      const temp = item;
      temp.duration = selectedListTime[temp.id];
      temp.usePlaylistContentDuration = usePlaylistContentDuration;
      return temp;
    });
    if (content.length === 0) {
      if (!playlist) {
        singleTimeLine.sourceImage =
          selectedList[0].attributes.s3_thumbnail_medium_url;
      }
    }
    singleTimeLine.content = [...singleTimeLine.content, ...selectedList];
    this.props.change("SplitScreen", `screen${id}`, singleTimeLine);
    this.toggle(false);
    this.props.updatePage();
    this.props.clearSelectedList();
  };

  render() {
    const { content, id, singleTimeLine } = this.props;
    return (
      <div
        className="campaignsplit-screen dropzone"
        onDragOver={this.handleDragOver}
        onDrop={this.handleOnDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <Row>
          <Col
            lg="2"
            md="3"
            className="screenName"
            style={{ backgroundColor: this.props.color }}
          >
            <ScreenNameBar {...this.props} />
          </Col>
          {this.state.modal && (
            <AddToTimeLine
              {...this.state}
              toggle={this.toggle}
              selectedList={this.props.selectedList}
              primaryMethod={this.getDurationDetail}
            />
          )}

          <Col
            className="droppingZoneOfCampaign"
            lg="10"
            md="9"
            style={{ width: "100%" }}
          >
            {this.props.content.length === 0 && (
              <small className="drag-text">
                Drag a content in the timeline
              </small>
            )}
            <div className="scroll-bar-wrap">
              <div className="scroll-box">
                <div
                  className={`float-left ${
                    this.props.content.length === 0 ? "invisible" : ""
                  } `}
                >
                  <TimeLineContentSort {...this.props} />
                </div>
                <div className="dragwrapper">
                  <ul
                    className="list-grp"
                    style={{ overflowY: "hidden", whiteSpace: "nowrap" }}
                  >
                    {/* {this.props.content.map((value, index) => (
                  <Items value={value} index={index} />
                ))} */}
                    <SortableList
                      axis="x"
                      content={this.props.content}
                      onSortEnd={(oldIndex, newIndex) =>
                        this.props.handleContentSortViaTimeline(
                          oldIndex,
                          newIndex,
                          {
                            content,
                            id,
                            singleTimeLine
                          }
                        )
                      }
                    />
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  state => {
    const { selectedList } = state.SelectedContent;
    return {
      selectedList: selectedList || undefined
    };
  },
  { change, clearSelectedList }
)(SingleTimeLine);
