import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import ToolkitProvider from "react-bootstrap-table2-toolkit";


import { columns } from "../utils/domainPartyUtils";
import DomainPartyHeader from "./domainPartyHeader";

export default class DomainPartyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        name: false,
        brands: false
      },
    };
  }

  componentWillUnmount() {

  }


  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      selectColumnStyle: { width: "40px" },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      onSelect: (row, isSelect,) => {
        if (isSelect) {
          this.props.handleBulkPartiesPush([row])
          this.props.toggleIsBulkDeleteVisible(true);
        } else {
          this.props.handleBulkPartiesPop(row);
          !this.props.bulkSelectedParties.length > 0 && this.props.toggleIsBulkDeleteVisible(false);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.handleBulkPartiesPush(rows)
          this.props.toggleIsBulkDeleteVisible(true);
        } else {
          rows.forEach(row => {
            this.props.handleBulkPartiesPop(row)
            this.props.toggleIsBulkDeleteVisible(false);
          })
        }
      }
    };
    const roles = this.props.currentUserRoles;
    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.parties}
          columns={columns(
            this.props.handleEditParty,
            this.props.removeExistingPartyFromDomain,
            this.props.currentUserRoles,
            this.state.hiddenColumns,
            this.props.toggleBrandModal,
            this.props.isLoading,
            this.props.sortOrder,
            this.props.handleSort
          )}
          columnToggle
        >
          {props => (
            <div>
              <DomainPartyHeader
                currentUserRoles={this.props.currentUserRoles}
                editDUH={this.props.editDUH}
                toggleAddPartyDUH={this.props.toggleAddPartyDUH}
                handleSearchValueDUH={this.props.handleSearchValueDUH}
                searchValDUH={this.props.searchValDUH}
                // below props are used by visible col toggler component
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                handleBulkDeleteParties={this.props.handleBulkDeleteParties}
                {...this.props}
              />
              <BootstrapTable
                hover
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                selectRow={selectRow}
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                keyField="id"
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
