import React from "react";
import { Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";

export function columns(
  roles,
  permission,
  handleDelete,
  handleUnArchiveItem,
  handleSort,
  sortOrder
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      style: { width: "350px" },
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.subwindow_number",
      text: "Screen",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("screen_size")}
            className="sort-by "
            style={{
              color: sortOrder.screen_size.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Screen
            <i
              role="presentation"
              className={`fa fa-sort-alpha-${
                sortOrder.screen_size.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.orientation",
      text: "Orientation",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("orientation")}
            className="sort-by "
            style={{
              color: sortOrder.orientation.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Orientation
            <i
              role="presentation"
              className={`fa fa-sort-alpha-${
                sortOrder.orientation.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.created_at",
      text: "Date Created",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.created_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.status",
      text: " Status",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("status")}
            className="sort-by "
            style={{
              color: sortOrder.status.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Status
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.status.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <span
          className={`content-text ${
            row.attributes.status === "offline"
              ? "status offline"
              : "status live"
          }`}
        >
          {row.attributes.status}
        </span>
      )
    },
    {
      dataField: "delete",
      text: "",
      formatter: (name, row) => (
        <div className="contentbutton campaignbutton action">
          <RouteAllowed
            roles={roles && roles}
            permission={permission && permission.content.delete}
          >
            <Tooltip
              item={
                <i
                  role="presentation"
                  onClick={() => handleUnArchiveItem(row.id, name)}
                  className="fas fa-history"
                />
              }
              id={`archive-${row.id}`}
              tooltipContent="Restore"
            />
            <Tooltip
              item={
                <i
                  role="presentation"
                  onClick={() => handleDelete(row.id, name)}
                  className="fa fa-trash action__trash"
                  style={{ cursor: "pointer" }}
                />
              }
              id={`delete-${row.id}`}
              tooltipContent="Delete"
            />
          </RouteAllowed>
        </div>
      )
    }
  ];
}
