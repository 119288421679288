import React, { Component } from "react";

import { CircularProgressbar } from "react-circular-progressbar";
import { Label } from "reactstrap";

// eslint-disable-next-line react/prefer-stateless-function
export default class DeviceInformation extends Component {
  render() {
    const { totalSpace, availableSpace } = this.props;
    return (
      <div className="networkInformation">
        <div className="row informationBlock">
          <div className="col-md-3 networkInformation__block">
            <i>Operating System</i>
            <span>N/A</span>
          </div>
          <div className="col-md-3 networkInformation__block">
            {" "}
            <i>Kernel Version</i>
            <span>N/A</span>
          </div>
          <div className="col-md-3 networkInformation__block">
            {" "}
            <i>Uptime</i>
            <span>N/A</span>
          </div>
          <div className="col-md-3 networkInformation__block">
            <div className="networkDetail__progressbar-info">
              <p>
                <i id="usedSpace" />
                <span>Used Space</span>{" "}
              </p>{" "}
              <p>
                <i id="freeSpace" />
                <span>Free Space</span>{" "}
              </p>{" "}
            </div>
          </div>
        </div>
        <div className="row mt-4 informationBlock">
          <div className="col-md-3 networkInformation__block">
            <i>Disk Usage</i>

            {isNaN(
              this.props.calculateDiskSpace(totalSpace, availableSpace)
            ) ? (
              <div>
                <p>N/A</p>
              </div>
            ) : (
              <div className="networkDetail__progressbar-wrapper">
                <CircularProgressbar
                  value={this.props.calculateDiskSpace(
                    totalSpace,
                    availableSpace
                  )}
                  text={`${this.props.calculateDiskSpace(
                    totalSpace,
                    availableSpace
                  )}%`}
                />
              </div>
            )}
          </div>
          <div className="col-md-3 networkInformation__block">
            <i>Swap Usage</i>

            {isNaN(
              this.props.calculateDiskSpace(totalSpace, availableSpace)
            ) ? (
              <div>
                <p>N/A</p>
              </div>
            ) : (
              <div className="networkDetail__progressbar-wrapper">
                <CircularProgressbar value={0} text="0%" />
              </div>
            )}
          </div>
          <div className="col-md-3 networkInformation__block">
            <i>Memory Usage</i>

            {isNaN(
              this.props.calculateDiskSpace(totalSpace, availableSpace)
            ) ? (
              <div>
                <p>N/A</p>
              </div>
            ) : (
              <div className="networkDetail__progressbar-wrapper">
                <CircularProgressbar value={0} text="0%" />
              </div>
            )}
          </div>
          <div className="col-md-3 networkInformation__block">
            <i>System Load</i>

            {isNaN(
              this.props.calculateDiskSpace(totalSpace, availableSpace)
            ) ? (
              <div>
                <p>N/A</p>
              </div>
            ) : (
              <div className="networkDetail__progressbar-wrapper">
                <CircularProgressbar value={0} text="0%" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
