/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import Split from "../pages/splitScreen/screen";
import { updateCampaign } from "../redux";
import Preview from "./preview/preview";
import { Tooltip } from "../../../core";
import CampaignStudioHeader from "../pages/campaignStudioHeader";

class StudioSplitScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { editButtonDisabled: false };
  }

  handleTitleChange = ({ target: { value } }) => {
    const { campaign } = this.props;
    campaign.campaignName = value;
    this.props.updateCampaign(campaign);
  };

  getPreview = () => <Preview key={new Date()} />;

  hideEditButton = hide => {
    if (hide) {
      this.setState(
        state => ({ editButtonDisabled: true }),
        () => {
          this.myInp.focus();
        }
      );
    } else {
      this.setState({ editButtonDisabled: false });
    }
  };

  render() {
    const { campaign } = this.props;
    return (
      // <div className="campaign-rightsection">
      <div
        className="campaignStudioWrapper__campaign-display-wrapper"
        // style={{
        //   padding:
        //     campaign.studioWidth > campaign.studioHeight
        //       ? "72px 0px"
        //       : "56px 0px"
        // }}
      >
        {!campaign.isZoomEnable && <CampaignStudioHeader {...this.props} />}

        <div
          className="campaignStudioWrapper__campaign-display"
          style={{
            width: `${campaign.studioWidth}px`,
            height: `${campaign.studioHeight}px`,
            // padding: `${campaign.padding}px`,
            padding: campaign.isZoomEnable && "8px 0px"
          }}
        >
          <div
            className="campaignStudioWrapper__studio-row-parent"
            style={{
              width: `${campaign.studioWidth}px`,
              height: `${campaign.studioHeight}px`,
              maxHeight: `${campaign.studioHeight}px`,
              maxWidth: `${campaign.studioWidth}px`
            }}
            id="parents"
          >
            {this.props.showPreview ? (
              this.getPreview()
            ) : (
              <Split {...this.props} />
            )}
          </div>
        </div>
      </div>
      // </div>
    );
  }
}

export default connect(
  null,
  { updateCampaign }
)(StudioSplitScreen);
