import React, { Component } from "react";

import { Col, Card } from "reactstrap";
import classNames from "classnames";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import moment from "moment";
import { getMinutesAndSeconds } from "../../../helper";

import { AddPlaylistStudioMobileViewHeader } from "../modules/addPlaylistMobileViewHeader";
import PlaylistContentSelector from "../modules/PlaylistContentSelector";
import { Dialog } from "../../../core";

import PlaylistStudioContentTable from "../modules/playlistStudioContentTable";

const uuidv4 = require("uuid/v4");

const ExampleCustomInput = ({ value, onClick }) => (
  <button
    className="btn-info contextButton contextButton--mod "
    onClick={onClick}
  >
    {value ? moment(value).format("DD-MM-YYYY") : "MM/DD/YY"}
  </button>
);

const CustomTimePicker = ({ value, onClick }) => (
  <button
    className="btn-info contextButton contextButton--mod contextButton--timePicker"
    onClick={onClick}
  >
    {value || "HH:MM"}
  </button>
);

class AddPlaylistMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = { mediaModal: false };
  }

  displayMediaModal = flag => {
    this.setState({ mediaModal: flag });
    if (!flag) {
      this.props.change("addPlaylistForm", "contentToBeDropped", []);
    }
  };

  primaryMethod = () => {
    const playListData = this.props.contentToBeDropped.map((i, index) => {
      const time = getMinutesAndSeconds(i.attributes.duration_seconds);
      return {
        ...i,
        order: index,
        uniqueKey: uuidv4(),
        durationMinute: time.minutes,
        durationSecond: time.seconds,
        weight: null
      };
    });
    const newList = [...this.props.playListData, ...playListData];
    this.props.change("addPlaylistForm", "playListData", newList);
    this.displayMediaModal(false);
    this.props.change("addPlaylistForm", "contentToBeDropped", []);
  };

  render() {
    const { mediaModal } = this.state;
    return (
      <Col md="12" sm="12" className=" ple_custom_height">
        <Card className="w-100">
          <Dialog
            externalControl
            showHandler={false}
            modal={mediaModal}
            body={
              <PlaylistContentSelector
                handleDragStart={this.props.handleDragStart}
                playlistTextLimit={15}
                {...this.props}
              />
            }
            title="Select Media"
            toggleModel={this.displayMediaModal}
            primaryMethod={this.primaryMethod}
            className="mediaModal"
          />{" "}
          <div className="playlistStudio">
            <AddPlaylistStudioMobileViewHeader
              popOne={this.props.popOne}
              random={this.props.random}
              playlistControl={this.props.playlistControl}
              playlistAttributeChange={this.props.playlistAttributeChange}
              playlistControlChange={this.props.playlistControlChange}
              handleControl={this.props.handleControl}
            />

            {this.props.playListData.length !== 0 ? (
              <div>
                <PlaylistStudioContentTable
                  setIsSubmtable={this.props.setIsSubmtable}
                  playListData={this.props.playListData}
                  change={this.props.change}
                  playlistControl={this.props.playlistControl}
                  sortableItemStyle={{ minWidth: "395px", marginLeft: "32px" }}
                  customDatePickerInput={<ExampleCustomInput />}
                  customTimePickerInput={<CustomTimePicker />}
                  displayMediaModal={this.displayMediaModal}
                  displayAddMediaIcon
                />
              </div>
            ) : (
              <div
                role="presentation"
                className={classNames(
                  `dropzone d-flex justify-content-center addcontent text-a`,
                  {
                    "dropzone--isActive": true
                  }
                )}
                onClick={() => this.displayMediaModal(true)}
              >
                <div className="groupicon">
                  <div className="center text-center">
                    <i className="bx bx-search bx-lg" />
                  </div>
                  <p>
                    <a href="#" onClick={() => this.displayMediaModal(true)}>
                      Browse
                    </a>{" "}
                    Media files
                  </p>
                </div>
              </div>
            )}
          </div>{" "}
        </Card>
      </Col>
    );
  }
}

export default connect(
  state => {
    const { list } = state.content;
    const selector = formValueSelector("addPlaylistForm");

    return {
      list: list || [],
      contentToBeDropped: selector(state, "contentToBeDropped") || []
    };
  },
  { change }
)(AddPlaylistMobileView);
