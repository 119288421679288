import React from "react";
import { toLower, startCase } from "lodash";
import { Tooltip } from "../../../core";

export function columns(
  currentDomainName,
  currentUserRoles,
  removeExistingDevicesFromDomain,
  hiddenColumns,
  removeExistingDevicesFromSystem
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      hidden: hiddenColumns.name
    },
    {
      dataField: "attributes.mac_address",
      text: "Mac Address",
      columnName: "mac_address",
      hidden: hiddenColumns.mac_address
    },
    {
      dataField: "attributes.orientation",
      text: "Orientation",
      columnName: "orientation",
      hidden: hiddenColumns.orientation
    },
    {
      dataField: "attributes.resolution",
      text: "Resolution",
      columnName: "resolution",
      hidden: hiddenColumns.resolution
    },
    {
      dataField: "actionButtons",
      text: "",
      columnName: "isSelected",
      style: { position: "relative" },
      formatter: (name, row) => (
        <div className="d-flex">
          <div className="contentSectionTable__actionButtonWrapper">
            {currentDomainName !== "gurzu" &&
              currentUserRoles.includes("SuperAdmin") && (
                <span className="contentSectionTable__actionButtonWrapper__buttons">
                  <Tooltip
                    item={
                      <i
                        className="fas fa-times-circle "
                        role="presentation"
                        style={{ cursor: "pointer" }}
                        onClick={e =>
                          removeExistingDevicesFromDomain(
                            row.id,
                            row.attributes.name
                          )
                        }
                      />
                    }
                    id={`remove-${row.id}`}
                    tooltipContent="Remove"
                  />
                </span>
              )}{" "}
            {/* {currentUserRoles.includes("SuperAdmin") && (
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                {" "}
                <Tooltip
                  item={
                    <i
                      className="fas fa-trash "
                      role="presentation"
                      style={{ cursor: "pointer" }}
                      onClick={e =>
                        removeExistingDevicesFromSystem(
                          row.id,
                          row.attributes.name
                        )
                      }
                    />
                  }
                  id={`delete-${row.id}`}
                  tooltipContent="Delete"
                />
              </span>
            )} */}
          </div>
        </div>
      )
    }
  ];
}
