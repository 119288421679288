import { Map } from "../../helper/routeMap";

/** Network */
import Ticket from "./ticket";
import TicketDetails from "./pages/ticketdetails";
import NoRoutes, { UpcomingFeatures } from "../../core/layout/noroutes";

const mainPath = "/tickets";
const pathCreator = `${mainPath}`;
const subPathCreator = subPath => `${pathCreator}${subPath}`;
const feature = "Ticket";

export default [
  {
    path: `${pathCreator}`,
    component: Map(Ticket),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/show/:id"),
    component: Map(TicketDetails),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/upcoming`,
    component: Map(UpcomingFeatures),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/*`,
    component: Map(NoRoutes),
    exact: true,
    secured: true,
    feature
  }
];
