import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import { getLocalTime } from "../../../helper/helperFuncs";
import VerifyLockedPlaylist from "../../../helper/verifyLockedPlaylist";

export function columns(
  roles,
  permission,
  handleDelete,
  handleSort,
  sortOrder,
  handleDuplicate,
  handleArchiveItem,
  hiddenColumns,
  handleLockUnLockPlaylist
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      hidden: hiddenColumns.name,
      headerStyle: { width: "400px" },
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <div className="thumb" style={{ cursor: "pointer" }}>
          <div className="content-thumb-image">
            <img
              src={
                row.attributes.playlist_thumbnail_url ||
                require("../../../assets/images/empty-img.png")
              }
              alt={name || undefined}
              className="content__thumb"
            />
            <div className="playlist-thumbnail-overlay">
              {row.attributes.locked ? (
                <img
                  src={require("../../../assets/images/playlist_locked.png")}
                  alt={name || undefined}
                  className="overlay-img content__thumb "
                  style={{ height: "25px", width: "25px", left: "26px" }}
                />
              ) : (
                <img
                  src={require("../../../assets/images/playlist_thumbnail.png")}
                  alt={name || undefined}
                  className="overlay-img content__thumb "
                  style={{ height: "25px", width: "25px" }}
                />
              )}
            </div>
          </div>

          <div className="tabletitle">
            <span className="content__titlename">
              {name.length >= 20 ? (
                <Tooltip
                  item={
                    <span className="content__name">
                      {" "}
                      {`${name.slice(0, 20)}...`}{" "}
                    </span>
                  }
                  id={`viewname-${row.id}`}
                  tooltipContent={name}
                />
              ) : (
                <span className="content__name">{name}</span>
              )}
            </span>
            <span className="contentType">
              Contents: {row.relationships.playlist_contents.data.length}
            </span>{" "}
          </div>
        </div>
      )
    },
    {
      dataField: "attributes.created_at",
      text: "Date Created",
      columnName: "date_created",
      headerStyle: { width: "250px" },
      hidden: hiddenColumns.date_created,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.created_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{getLocalTime(row)}</div>
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      columnName: "date_updated",
      hidden: hiddenColumns.date_updated,
      headerStyle: { width: "250px" },
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{getLocalTime(row)}</div>
    },
    {
      dataField: "actions",
      text: "",
      style: { position: "relative" },
      columnName: "isSelected",
      formatter: (name, row) => (
        <div className="d-flex">
          <div className="contentSectionTable__menu">
            <i className="fas fa-ellipsis-h" />
          </div>
          <div className="contentSectionTable__actionButtonWrapper">
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.playlist.edit}
            >
              <VerifyLockedPlaylist
                roles={roles && roles}
                permission={permission && permission}
                row={row}
              >
                <Link
                  to={{
                    pathname: `/playlist/edit/${row.id}/`,
                    state: { name: row.attributes.name }
                  }}
                  className="contentSectionTable__actionButtonWrapper__buttons"
                  onClick={e => e.stopPropagation()}
                >
                  <Tooltip
                    item={<i className="fa fa-edit" />}
                    id={`edit-${row.id}`}
                    tooltipContent="Edit"
                  />
                </Link>
              </VerifyLockedPlaylist>
            </RouteAllowed>
            <RouteAllowed
              roles={roles && roles}
              permission={permission.playlist.dublicate}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();
                        return handleDuplicate(row.id);
                      }}
                      className="fa fa-clone action__clone"
                    />
                  }
                  id={`clone-${row.id}`}
                  tooltipContent="Duplicate"
                />
              </span>
            </RouteAllowed>
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.playlist.delete}
            >
              <VerifyLockedPlaylist
                roles={roles && roles}
                permission={permission && permission}
                row={row}
              >
                <span className="contentSectionTable__actionButtonWrapper__buttons">
                  <Tooltip
                    item={
                      <i
                        role="presentation"
                        onClick={e => {
                          e.stopPropagation();
                          return handleDelete(row.id, row.attributes.name);
                        }}
                        className="fa fa-trash action__trash"
                        style={{ cursor: "pointer" }}
                      />
                    }
                    id={`delete-${row.id}`}
                    tooltipContent="Delete"
                  />
                </span>
              </VerifyLockedPlaylist>
            </RouteAllowed>
            {row.attributes.locked ? (
              <RouteAllowed
                roles={roles && roles}
                permission={permission && permission.playlist.lock}
              >
                <span className="contentSectionTable__actionButtonWrapper__buttons">
                  <Tooltip
                    item={
                      <i
                        role="presentation"
                        onClick={e => {
                          e.stopPropagation();
                          return handleLockUnLockPlaylist(
                            row.id,
                            row.attributes.name,
                            "unlock",
                            false
                          );
                        }}
                        className="fas fa-lock-open action__trash"
                        style={{ cursor: "pointer" }}
                      />
                    }
                    id={`unlock-${row.id}`}
                    tooltipContent="UnLock Playlist"
                  />
                </span>
              </RouteAllowed>
            ) : (
              <RouteAllowed
                roles={roles && roles}
                permission={permission && permission.playlist.lock}
              >
                <span className="contentSectionTable__actionButtonWrapper__buttons">
                  <Tooltip
                    item={
                      <i
                        role="presentation"
                        onClick={e => {
                          e.stopPropagation();
                          return handleLockUnLockPlaylist(
                            row.id,
                            row.attributes.name,
                            "lock",
                            true
                          );
                        }}
                        className="fas fa-lock action__trash"
                        style={{ cursor: "pointer" }}
                      />
                    }
                    id={`lock-${row.id}`}
                    tooltipContent="Lock Playlist"
                  />
                </span>
              </RouteAllowed>
            )}
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.archive.playlist}
            >
              <VerifyLockedPlaylist
                roles={roles && roles}
                permission={permission && permission}
                row={row}
              >
                <span className="contentSectionTable__actionButtonWrapper__buttons">
                  <Tooltip
                    item={
                      <i
                        role="presentation"
                        onClick={e => {
                          e.stopPropagation();
                          return handleArchiveItem(row.id, name);
                        }}
                        className="fas fa-archive action-archive"
                      />
                    }
                    id={`archive-${row.id}`}
                    tooltipContent="Archive"
                  />
                </span>
              </VerifyLockedPlaylist>
            </RouteAllowed>
          </div>
        </div>
      )
    }
  ];
}
