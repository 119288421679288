export default {
  OFF: "OFF",
  ON: "ON",
  VOLUP: "VOLUP",
  VOLDOWN: "VOLDOWN",
  HDMI1: "HDMI1",
  HDMI2: "HDMI2",
  HDMI3: "HDMI3",
  BRIGHTNESS25: "BRIGHTNESS25",
  BRIGHTNESS50: "BRIGHTNESS50",
  BRIGHTNESS75: "BRIGHTNESS75",
  BRIGHTNESS100: "BRIGHTNESS100",
  VOL25: "VOL25",
  VOL50: "VOL50",
  VOL75: "VOL75",
  VOL100: "VOL100",
  HDMITOGGLE: "HDMITOGGLE",
  MUTE: "MUTE",
  SLEEP: "SLEEP",
  AV: "AV",
  VGA: "VGA",
  UP: "UP",
  DOWN: "DOWN",
  MENU: "MENU",
  OK: "OK",
  LEFT: "LEFT",
  USB: "USB",
  PMODE: "PMODE",
  ZOOM: "ZOOM",
  SURROUNDED: "SURROUNDED",
  DTV: "DTV",
  SMODE: "SMODE",
  CHANNELPLUS: "CHANNELPLUS",
  CHANNELMINUS: "CHANNELMINUS",
  RIGHT: "RIGHT"
};
