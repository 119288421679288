import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import { Weather } from "../modules/weather";
import { weatherPreview } from "./weatherPreview";
import PlaylistContentSelector from "../utls/playlist/playlistContentSelector";
import {
  textPreview,
  urlPreview,
  hlsPreview,
  dateTimePreview,
  rssPreview,
  youtubePreview,
  picToScreenPreview,
  routePreview,
  transparentPreview
} from "./widgetPreview";
import pic_to_screen from "../../../assets/picture/pic_to_screen.png";
import { PicToScreen } from "../utls/components";
import youtube_widget from "../../../assets/picture/youtube_widget.png";

const weather = require("../../../assets/picture/weather.png");
const text = require("../../../assets/picture/text.png");
const url = require("../../../assets/picture/url.png");
const rss = require("../../../assets/picture/rss.png");
const datetime = require("../../../assets/picture/datetime.png");
const hls = require("../../../assets/picture/hls.png");
const youtube = require("../../../assets/picture/youtube_widget.png");
const picToScreen = require("../../../assets/picture/pic_to_screen.png");
const route = require("../../../assets/picture/route.png");
const transparent = require("../../../assets/picture/transparent.png");

const checkScale = scale => {
  if (scale === "fit") {
    return "fitImage";
  }
  if (scale === "fill") {
    return "fillImage";
  }

  return "stretchImage";
};

const composePlaylistContent = (selectedContent, key) => ({
  duration: selectedContent.duration,
  url: selectedContent.url,
  contentType: selectedContent.contentType,
  comp:
    selectedContent.contentType.search("ideo") > -1 ? (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        key={selectedContent.contentID}
        id="preview_video"
        autoPlay
        loop
        height="100%"
        width="100%"
        ref={key.forwardRef}
      >
        <source
          // key={selectedContent.contentID}
          src={selectedContent.preFetchUrl}
        />
      </video>
    ) : (
      <img
        key={selectedContent.contentID}
        src={selectedContent.preFetchUrl}
        alt="preview"
        className={checkScale(selectedContent.scale)}
      />
    )
});

export const mapContentData = item =>
  item.content.map((key, index) => {
    if (key.contentType.search("ideo") > -1) {
      const vid = (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          key={index}
          ref={key.forwardRef}
          autoPlay
          loop
          id="preview_video"
          height="100%"
          width="100%"
        >
          <source src={key.preFetchUrl} />
        </video>
      );
      return {
        duration: key.duration,
        url: key.url,
        contentType: key.contentType.search("ideo") > -1 ? "video" : "image",
        comp: vid
      };
    }
    if (key.contentType.search("weather") > -1) {
      const div = weatherPreview(item, key);
      return {
        duration: key.duration,
        url: weather,
        contentType: "weather",
        comp: div
      };
    }
    if (key.contentType.search("text") > -1) {
      const div = textPreview(item, key);
      return {
        duration: key.duration,
        url: text,
        contentType: "text",
        comp: div
      };
    }
    if (key.contentType.search("url") > -1) {
      const div = urlPreview(item, key);
      return {
        duration: key.duration,
        url,
        contentType: "url",
        comp: div
      };
    }

    if (key.contentType.search("hls") > -1) {
      const div = hlsPreview(item, key);
      return {
        duration: key.duration,
        hls,
        contentType: "hls",
        comp: div
      };
    }

    if (key.contentType.search("rss") > -1) {
      const div = rssPreview(item, key);
      return {
        duration: key.duration,
        url: rss,
        contentType: "rss",
        comp: div
      };
    }

    if (key.contentType.search("datetime") > -1) {
      const div = dateTimePreview(item, key);
      return {
        duration: key.duration,
        url: datetime,
        contentType: "datetime",
        comp: div
      };
    }

    if (key.contentType.search("laylist") > -1) {
      const plHook = new PlaylistContentSelector();
      plHook.loadPlaylist(key);

      return {
        plHook,
        async getContent() {
          const selectedContent = await this.plHook.getContent();

          if (selectedContent.length) {
            // it is burst content
            return selectedContent.map(plc =>
              composePlaylistContent(plc.content, key)
            );
          }
          return [composePlaylistContent(selectedContent, key)];
        }
      };
    }

    if (key.contentType.search("youtube") > -1) {
      const div = youtubePreview(item, key);
      return {
        duration: key.duration,
        youtube,
        contentType: "youtube",
        comp: div
      };
    }

    if (key.contentType.search("picToScreen") > -1) {
      const div = picToScreenPreview(item, key);
      return {
        duration: key.duration,
        picToScreen,
        contentType: "picToScreen",
        comp: div
      };
    }

    if (key.contentType.search("route") > -1) {
      const div = routePreview(item, key);
      return {
        duration: key.duration,
        route,
        contentType: "route",
        comp: div
      };
    }
    if (key.contentType.search("transparent") > -1) {
      const div = transparentPreview(
        { width: item.width, height: item.height },
        key
      );
      return {
        duration: key.duration,
        transparent,
        contentType: "transparent",
        comp: div
      };
    }
    const img = (
      <img
        key={index}
        src={key.preFetchUrl}
        alt="preview"
        className={checkScale(key.scale)}
      />
    );

    return {
      key: { index },
      duration: key.duration,
      url: key.url,
      contentType: key.contentType.search("ideo") > -1 ? "video" : "image",
      comp: img
    };
  });
