import React from "react";
import { BigCalendarHeaderTitle } from "./bigHeaderCalendarTitle";

export const BigCalendarHeaderForMobile = props => (
  <div className="flex-fill mainCalendar__wrapper big-calendar-header p-0">
    <BigCalendarHeaderTitle
      calendarTitle={props.calendarTitle}
      calendarTitleChange={props.calendarTitleChange}
      calendarInputRef={props.calendarInputRef}
      action={props.action}
      isCalendarListOpen={props.isCalendarListOpen}
      {...props}
    />
    {(props.action === "View" || props.action === "") && (
      <div className="d-flex">
        <button
          type="button"
          className="mobileHeaderIconWrapper"
          onClick={() => props.handleCancelChanges()}
        >
          <i className="far fa-times-circle" />{" "}
        </button>
        <button
          type="button"
          className="mobileHeaderImageWrapper"
          onClick={() => {
            props.disableInput();
            props.handleCalendarSave();
          }}
          disabled={
            props.calendarFormEventValues.length === 0 || props.disableCalendar
          }
        >
          <img src={require("../../../assets/images/save.png")} alt="Update" />{" "}
        </button>
        {/* <button
          type="button"
          className="mobileHeaderImageWrapper mobileHeaderImageWrapper--mod"
          onClick={() => {
            props.disableInput();
            props.handleCalendarSave("sync");
          }}
          disabled={
            props.calendarFormEventValues.length === 0 || props.disableCalendar
          }
          hidden={!props.editCalendar}
        >
          <img
            src={require("../../../assets/images/updateAndSync.png")}
            alt="Update & Sync"
          />{" "}
        </button> */}
      </div>
    )}
  </div>
);
