import React, { Component } from "react";
import { Button } from "reactstrap";
import Select from "react-select";
import { MultipleSelect } from "../../../core/form/searchSelect";
import {
  Resources,
  ContentTagResources,
  SponserTagResources,
  PartyTagListResources,
  BrandTagListResources
} from "../../contents/redux";
import { Resources as PlaylistResources } from "../redux";

export default class TabSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTag: [],
      searchByTagOptions: [],
      MediaSearchValue: "",
      showClearButtonMedia: false,
      selectedOption: "Tags",
      showSearchOptions: false
    };
    this.loadTags();
    this.loadSponserTag();
    this.loadPartyTag();
    this.loadBrandTag();
    this.searchByTagRef = React.createRef();
  }

  loadTags = async () => {
    const { getContent } = ContentTagResources;
    await this.props.Get(getContent);
    const searchByTagOptions = this.props.ContentTag.map(i => ({
      value: i,
      label: i
    }));
    this.setState({ searchByTagOptions });
  };

  loadSponserTag = async () => {
    const { getContent } = SponserTagResources;
    await this.props.Get(getContent);
  };
  loadPartyTag = async () => {
    const { getContent } = PartyTagListResources;
    await this.props.Get(getContent);
  };

  loadBrandTag = async () => {
    const { getContent } = BrandTagListResources;
    await this.props.Get(getContent);
  };
  handleRemove = tag => {
    const { searchTag } = this.state;
    searchTag.splice(tag, 1);
    if (searchTag.length === 0) {
      this.props.handleSearch("");
      this.setState({ showClearButtonMedia: false });
      this.props.toggleFetch(true);
    } else {
      this.setState({ searchTag }, () => {
        this.props.handleSearch(
          searchTag.toString(),
          true,
          this.state.selectedOption
        );
      });
    }
    this.searchByTagRef.current.state.value = "";
  };

  handleSelectedOption = () => {
    this.setState(state => ({ showSearchOptions: !state.showSearchOptions }));
  };

  handleChangeOptions = type => {
    this.setState({ selectedOption: type, showSearchOptions: false });
    if (this.state.searchTag.length > 0) {
      this.props.handleSearch(this.state.searchTag.toString(), true, type);
    }
  };

  handleTagChange = newTag => {
    this.handleRemove(newTag)
    this.state.searchVal !== "" && this.setState({ MediaSearchValue: "" });
    if (newTag) {
      const { searchTag } = this.state;
      if (!searchTag.includes(newTag.value.toString())) {
        searchTag.push(newTag.value.toString());
        this.setState(
          () => ({ searchTag, showClearButtonMedia: true }),
          () =>
            this.props.handleSearch(
              this.state.searchTag.toString(),
              true,
              this.state.selectedOption
            )
        );
        this.props.inputSearchTag(
          this.state.searchTag.toString(),
          this.state.selectedOption
        );
      }
    }
  };

  handleSearch = keyword => {
    this.searchByTagRef.current.state.value = "";
    this.props.inputSearchName(keyword);
    this.state.searchTag.length > 0 && this.setState({ searchTag: [] });
    this.props.handleSearch(keyword);
    this.setState({ showClearButtonMedia: true });
    if (keyword === "") {
      this.setState({ showClearButtonMedia: false });
    }
  };

  handleClear = () => {
    this.props.toggleFetch(true);
    this.props.handleSearch("");
    this.searchByTagRef.current.state.value = "";
    this.setState({
      showClearButtonMedia: false,
      MediaSearchValue: "",
      searchTag: []
    });
  };

  handleSponsorChange = e => {
    if (e.value === "tag") {
      this.handleChangeOptions("Tags");
    }
    if (e.value === "sponsor") {
      this.handleChangeOptions("Sponsors");
    }
    if (e.value === "party") {
      this.handleChangeOptions("Party");
    }
    if (e.value === "brand") {
      this.handleChangeOptions("Brand");
    }
  };

  render() {
    const { selectedOption, showSearchOptions } = this.state;
    const searchTagOptions =
      this.state.selectedOption === "Sponsors"
        ? this.props.SponserTag
        : this.state.selectedOption === "Tags"
        ? this.props.ContentTag
        : this.state.selectedOption === "Party"
        ? this.props.PartyTagList
        : this.props.BrandTagList;

    return (
      <div className="row campaignStudioWrapper__search">
        <div className="col-md-5 p-1 search">
          <span
            className="bx bx-search bx-sm search__searchIcon"
            width="100%"
          />
          <input
            type="text"
            className="search__input search__input--campaignEditor w-100"
            placeholder="Search by name"
            onChange={e => {
              this.setState({ MediaSearchValue: e.target.value });
              this.handleSearch(e.target.value);
              // this.props.inputSearchName(e.target.value);
            }}
            value={this.state.MediaSearchValue}
          />
        </div>

        <div className="d-flex col-md-7 p-1 search w-100">
          {/* <Button
            role="button"
            className={`select_box__button searchTypeSelect searchTypeSelect--playlist ${showSearchOptions &&
              "searchTypeSelect--active"}`}
            onClick={() => this.handleSelectedOption()}
          >
            {selectedOption} <i className="fas fa-chevron-down" />
          </Button> */}
          <div className="sponsor-tag" style={{ width: "35%" }}>
            <Select
              isSearchable={false}
              required
              onChange={e => this.handleSponsorChange(e)}
              defaultValue={[{ label: "Tags", value: "tag" }]}
              options={
                (!this.props.featureAllowed)
                  ?( [
                      { label: "Tags", value: "tag" },
                      { label: "Sponsors", value: "sponsor" }
                    ])
                  : [
                      { label: "Tags", value: "tag" },
                      { label: "Sponsors", value: "sponsor" },
                      { label: "Party", value: "party" },
                      { label: "Brand", value: "brand" }
                    ]
              }
            />
          </div>
          <div
            style={{ width: "65%" }}
            className={
              this.state.selectedOption === "Sponsors" && "searchSponser"
            }
          >
            <React.Fragment>
              <span className="bx bx-search bx-sm search__searchIcon " />{" "}
              <MultipleSelect
                forwardRef={this.searchByTagRef}
                style={{ width: "47%" }}
                searchable
                onChange={this.handleTagChange}
                className="header-tag-search header-tag-search--contentTagSearch"
                placeholder={
                  this.state.selectedOption === "Tags"
                    ? "Search by Tag"
                    : this.state.selectedOption === "Sponsors"
                    ? "Search by Sponsor"
                    : this.state.selectedOption === "Party"
                    ? "Search by Party"
                    : "Search by Brand"
                }
                isClearable
                value=""
                options={
                  searchTagOptions.map(item => ({
                    value: item,
                    label: item
                  })) || []
                }
              />
            </React.Fragment>
          </div>
        </div>
        <div className="pr-2 pl-2" style={{ width: "100%" }}>
          {this.state.searchTag.map((i, index) => (
            <div className="contentTag__wrapper">
              <span
                className={
                  this.state.selectedOption === "Sponsors"
                    ? "contentTag__badge contentTag__badge--mod"
                    : "contentTag__badge"
                }
                onClick={() => this.handleRemove(index)}
              >
                {`${i}  `}
                <span className="fa fa-times-circle" />
              </span>
            </div>
          ))}
          {this.state.showClearButtonMedia && (
            <span
              role="button"
              onClick={this.handleClear}
              className="float-right clearField"
            >
              Clear
            </span>
          )}
        </div>
      </div>
    );
  }
}
