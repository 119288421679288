import React from "react";
import { Label, Button } from "reactstrap";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Tooltip } from "../../../core";

const PortraitRight = require("../../../assets/images/rotate-right.png");
const Inverted = require("../../../assets/images/inverted.png");
const Normal = require("../../../assets/images/normal.png");
const PortraitLeft = require("../../../assets/images/rotate-left.png");

const OrientationControlForm = props => (
  <div className="row mt-3">
    <div className="col-md-2 informationBlock">
      <Label for="exampleSelect" className="mt-2">
        Orientation
      </Label>
    </div>
    <div className="col-md-10">
      <Field name="orientation" hidden component="input" />
      <div className="orientationWrapper">
        <div className="equalHWrap eqWrap">
          <div className="equalHW eq ">
            {" "}
            <Tooltip
              tooltipContent="Normal Screen"
              id="lright"
              item={
                <button
                  type="button"
                  className={`network-orientation-button network-orientation-button--sideBorder ${
                    props.orientation === "landscape_normal" ||
                    props.orientation === "normal"
                      ? "active"
                      : ""
                  } `}
                  onClick={() => props.change("orientation", "normal")}
                >
                  Normal
                </button>
              }
            />
          </div>
          <div className="equalHW eq">
            {" "}
            <Tooltip
              tooltipContent="Inverted"
              id="lleft"
              item={
                <button
                  type="button"
                  className={`network-orientation-button network-orientation-button--sideBorder ${
                    props.orientation === "landscape_inverted" ||
                    props.orientation === "inverted"
                      ? "active"
                      : ""
                  } `}
                  onClick={() => props.change("orientation", "inverted")}
                >
                  Inverted
                </button>
              }
            />
          </div>
          <div className="equalHW eq">
            {" "}
            <Tooltip
              tooltipContent="Turn Left"
              id="pleft"
              item={
                <button
                  type="button"
                  className={`network-orientation-button network-orientation-button--sideBorder ${
                    props.orientation === "portrait_left" ||
                    props.orientation === "left"
                      ? "active"
                      : ""
                  } `}
                  onClick={() => props.change("orientation", "left")}
                >
                  Left
                </button>
              }
            />
          </div>
          <div className="equalHW eq">
            {" "}
            <Tooltip
              tooltipContent="Turn Right"
              id="pright"
              item={
                <button
                  type="button"
                  className={`network-orientation-button ${
                    props.orientation === "portrait_right" ||
                    props.orientation === "right"
                      ? "active"
                      : ""
                  } `}
                  onClick={() => props.change("orientation", "right")}
                >
                  Right
                </button>
              }
            />
          </div>
        </div>
      </div>
      {/* <div className="d-flex btn-group orientationWrapper">
        <Tooltip
          tooltipContent="Normal Screen"
          id="lright"
          item={
            <button
              type="button"
              className={`${
                props.orientation === "landscape_normal"
                  ? "btn-outline-secondary orientation-padding active"
                  : "btn-outline-secondary orientation-padding"
              } btn-outline-secondary--sideBorder`}
              onClick={() => props.change("orientation", "normal")}
            >
              Normal
            </button>
          }
        />
        <Tooltip
          tooltipContent="Inverted"
          id="lleft"
          item={
            <button
              type="button"
              className={
                props.orientation === "landscape_inverted"
                  ? "btn-outline-secondary orientation-padding active"
                  : "btn-outline-secondary orientation-padding"
              }
              onClick={() => props.change("orientation", "inverted")}
            >
              Inverted
            </button>
          }
        />
        <Tooltip
          tooltipContent="Turn Left"
          id="pleft"
          item={
            <button
              type="button"
              className={
                props.orientation === "portrait_left"
                  ? "btn-outline-secondary orientation-padding active"
                  : "btn-outline-secondary orientation-padding"
              }
              onClick={() => props.change("orientation", "left")}
            >
              Left
            </button>
          }
        />
        <Tooltip
          tooltipContent="Turn Right"
          id="pright"
          item={
            <button
              type="button"
              className={`${
                props.orientation === "portrait_right"
                  ? "btn-outline-secondary orientation-padding active"
                  : "btn-outline-secondary orientation-padding"
              } btn-outline-secondary--rightSideBorder`}
              onClick={() => props.change("orientation", "right")}
            >
              Right
            </button>
          }
        />
      </div> */}
      <Button
        className="btn-info ml-2"
        disabled={props.submitting || props.pristine}
        onClick={props.handleSubmit(values =>
          props.handleOrientationChange(values)
        )}
      >
        {props.submitting ? "Saving" : "Save"}
      </Button>
    </div>
  </div>
);

function mapStateToProps(state) {
  const selector = formValueSelector("deviceOrientationForm");
  return {
    orientation: selector(state, "orientation")
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "deviceOrientationForm"
  })(OrientationControlForm)
);
