/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { uniq } from "lodash";
import { formValueSelector } from "redux-form";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { ContentTagResources, Resources, SponserTagResources } from "../redux";
import { SearchTagRefresh, Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

const selector = formValueSelector("SelectedContents");
class ContentHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTag: [],
      dropdownOpen: false,
      archiveDropDown: false,
      selectedOption: "Tags",
      showSearchOptions: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  toggleArchive = () => {
    this.setState(prevState => ({
      archiveDropDown: !prevState.archiveDropDown
    }));
  };

  clearSearchTag = () => {
    // this.setState((state, props) => ({ searchTag: [] }));
    this.props.handleSearchTag([]);
  };

  handleSelectedOption = () => {
    this.setState(state => ({ showSearchOptions: !state.showSearchOptions }));
  };

  handleChangeOptions = type => {
    this.setState({ selectedOption: type, showSearchOptions: false });
    if (this.props.searchTag.length > 0) {
      this.props.handleSearch(this.props.searchTag.toString(), true, type);
    }
  };

  handleSearchChange = value => {
    const { searchTag } = this.props;
    if (!searchTag.includes(value)) {
      searchTag.push(value);
      this.setState(
        () => ({ searchTag }),
        () => {
          this.props.handleSearchTag(searchTag);
          this.props.handleSearch(
            this.props.searchTag.toString(),
            true,
            this.state.selectedOption
          );
        }
      );
    }
  };

  handleRemove = tag => {
    const { searchTag } = this.props;
    searchTag.splice(tag, 1);
    if (searchTag.length === 0) {
      this.props.handleSearch("", true);
    } else {
      this.setState({ searchTag }, () => {
        this.props.handleSearchTag(searchTag);
        this.props.handleSearch(
          searchTag.toString(),
          true,
          this.state.selectedOption
        );
      });
    }
    this.props.forwardRef.current.state.value = "";
  };

  render() {
    const { refreshing } = this.props;
    const { roles } = this.props;
    const { permission } = this.props;
    const searchByTagOptions =
      this.state.selectedOption === "Sponsors"
        ? this.props.SponserTag
        : this.state.selectedOption === "Tags"
          ? this.props.ContentTag
          : this.state.selectedOption === "Party"
            ? this.props.PartyTagList
            : this.props.BrandTagList;
    return (
      <div>
        <div
          className="d-flex contentHeader"
          data-spy="affix"
          data-offset-top="197"
        >
          <div className="mr-auto">
            <RouteAllowed roles={roles} permission={permission.content.creates}>
              <Link to="/content/create">
                <button
                  className="primaryButton primaryButton--contentHeader"
                  // onClick={handleSubmit(values => this.handleLogin(values))}
                  type="submit"
                >
                  <i className="fa fa-plus mr-2" />
                  Add content
                </button>
              </Link>
            </RouteAllowed>
          </div>
          <div className="d-flex">
            {/* <div className="p-2"> </div> */}
            <SearchTagRefresh
              handleSearchChange={this.handleSearchChange}
              handleSearch={this.props.handleSearch}
              forwardRef={this.props.forwardRef}
              searchByTagOptions={searchByTagOptions.map(i => ({
                value: i,
                label: i,
                className: "someclass"
              }))}
              clearSearchTag={this.clearSearchTag}
              isClearable={false}
              selectedOption={this.state.selectedOption}
              showSearchOptions={this.state.showSearchOptions}
              handleSelectedOption={this.handleSelectedOption}
              handleChangeOptions={this.handleChangeOptions}
              {...this.props}
            />
            <div className="btn-group contentHeader__viewButtons ">
              <Tooltip
                item={
                  <div
                    className={`iconButton contentHeader__viewButtons__list ${this
                      .props.contentView === "lists" && "activeButton"}`}
                    onClick={() => {
                      this.props.handleContentView("lists");
                    }}
                  >
                    <div className="fa fa-bars" />
                  </div>
                }
                tooltipContent="List View"
                id="header-content-list"
              />
              <Tooltip
                item={
                  <div
                    className={`iconButton contentHeader__viewButtons__group ${this
                      .props.contentView === "groups" && "activeButton"}`}
                    onClick={() => {
                      this.props.handleContentView("groups");
                    }}
                  >
                    <div className="fas fa-th" />
                  </div>
                }
                tooltipContent="Grid View"
                id="header-content-grid"
              />
            </div>
            {this.props.selectedContents.length > 0 ? (
              <div className="d-flex">
                <RouteAllowed
                  roles={roles}
                  permission={permission.content.edit}
                >
                  <Tooltip
                    item={
                      <div>
                        <div
                          className={`${this.props.disableArchiveIcon
                            ? "iconButton iconButton--disabled"
                            : "iconButton "
                            } fa fa-archive`}
                          onClick={() =>
                            this.props.handleArchiveItems(
                              this.props.selectedContents,
                              this.props.disableArchiveIcon
                            )
                          }
                        />
                      </div>
                    }
                    id="header-content-archive"
                    tooltipContent="Archive"
                  />
                </RouteAllowed>
                <RouteAllowed
                  roles={roles}
                  permission={permission.content.delete}
                >
                  <Tooltip
                    item={
                      <div>
                        <div
                          className="iconButton fa fa-trash"
                          onClick={() =>
                            this.props.handleDeleteContents(
                              this.props.selectedContents
                            )
                          }
                        />
                      </div>
                    }
                    id="header-content-delete"
                    tooltipContent="Delete"
                  />
                </RouteAllowed>
              </div>
            ) : (
              <div className="d-flex">
                <Tooltip
                  item={
                    <div
                      className="iconButton"
                      onClick={() => this.props.handleRefresh()}
                    >
                      <i
                        className={`bx bx-revision ${refreshing ? "refresh-rotate " : ""
                          }`}
                      />
                    </div>
                  }
                  id="header-content-refresh"
                  tooltipContent="Refresh"
                />
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle className="iconButton" caret>
                    <i className="bx bx-filter" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          this.clearSearchTag();
                          this.props.handleClearSearchValue();
                          return this.props.handleFilterWithType("");
                        }}
                      >
                        All
                      </span>
                    </DropdownItem>
                    <DropdownItem>
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          this.clearSearchTag();
                          this.props.handleClearSearchValue();
                          return this.props.handleFilterWithType("video");
                        }}
                      >
                        Video
                      </span>
                    </DropdownItem>
                    <DropdownItem>
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          this.clearSearchTag();
                          this.props.handleClearSearchValue();
                          return this.props.handleFilterWithType("image");
                        }}
                      >
                        Image
                      </span>
                    </DropdownItem>
                    <DropdownItem>
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          this.clearSearchTag();
                          this.props.handleClearSearchValue();
                          return this.props.handleFilterWithType("audio");
                        }}
                      >
                        Audio
                      </span>
                    </DropdownItem>
                    <DropdownItem>
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          this.clearSearchTag();
                          this.props.handleClearSearchValue();
                          return this.props.handleFilterWithType("true");
                        }}
                      >
                        Filler
                      </span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {this.props.contentView === "lists" && (
              <VisibleColumnToggler
                hiddenColumns={this.props.hiddenColumns}
                toggleHiddenColumns={this.props.toggleHiddenColumns}
                columnToggleProps={this.props.columnToggleProps}
              />
            )}
          </div>
        </div>
        <div>
          {this.props.searchTag.map((i, index) => (
            <div className="contentTag__wrapper mb-3">
              <span
                className={
                  this.state.selectedOption === "Sponsors"
                    ? "contentTag__badge contentTag__badge--mod"
                    : "contentTag__badge"
                }
                onClick={() => this.handleRemove(index)}
              >
                {`${i}  `}
                <i className="fa fa-times-circle" />
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    ContentTag: state.ContentTag.list
      ? state.ContentTag.list.attributes.tags
      : [],
    selectedContents: selector(state, "contentSelected") || [],
    SponserTag: state.SponserTag.list ? state.SponserTag.list : [],
    PartyTagList: state.PartyTagList.partyList
      ? state.PartyTagList.partyList.attributes.tags
      : [],
    BrandTagList: state.BrandTagList.brandList
      ? state.BrandTagList.brandList.attributes.tags
      : [],
    featureAllowed: JSON.parse(
      window.localStorage.getItem("domain") !== "undefined"
        ? window.localStorage.getItem("domain")
        : null
    ).is_party_brand_feature_enabled
  }),
  { ContentTagResources }
)(ContentHeader);
