import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { remove, differenceBy } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { columns } from "../utls/contentUtils";
import RouteAllowed from "../../../helper/isRouteAllowed";
import ContentHeader from "./contentHeader";

class ContentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        size: false,
        dimension: false,
        duration: false,
        tags: false,
        party: false,
        brand: false,
        date_created: false,
        date_updated: false
      }
    };
  }

  handleNodata = () => <span className="null-message-info">No Data</span>;

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  render() {
    const selectRow = {
      mode: "checkbox",
      selectColumnStyle: { width: "40px" },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("contentSelected", [
            ...this.props.contentSelected,
            row
          ]);
        } else {
          const { contentSelected } = this.props;
          remove(contentSelected, { id: row.id });
          this.props.change("contentSelected", contentSelected);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("contentSelected", [
            ...this.props.contentSelected,
            ...rows
          ]);
        } else {
          const { contentSelected } = this.props;
          const formattedContents = differenceBy(contentSelected, rows, "id");
          this.props.change("contentSelected", formattedContents);
        }
      }
    };
    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.tableRef}
          keyField="id"
          data={this.props.contentList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleSort,
            this.props.sortOrder,
            this.props.handleArchiveItem,
            this.props.handleContentEditAndView,
            this.state.hiddenColumns
          )}
          columnToggle
        >
          {props => (
            <div>
              <ContentHeader
                forwardRef={this.props.searchRef}
                refreshing={this.props.refreshing}
                handleRefresh={this.props.handleRefresh}
                handleFilter={this.props.handleFilter}
                handleSearch={this.props.handleSearch}
                handleFilterWithType={this.props.handleFilterWithType}
                handleArchiveItems={this.props.handleArchiveItems}
                handleDeleteContents={this.props.handleDeleteContents}
                // the following props are for visibility column toggler
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                handleClearSearchValue={this.props.handleClearSearchValue}
                disableArchiveIcon={this.props.disableArchiveIcon}
                {...this.props}
              />
              <BootstrapTable
                ref={this.props.tableRef}
                classes="contentSectionTable"
                wrapperClasses="table-responsive content-wrap"
                loading={this.props.isLoading}
                bordered={false}
                selectRow={selectRow}
                filter={filterFactory()}
                hover
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                rowEvents={{
                  onClick: (e, row) => {
                    e.stopPropagation();
                    this.props.handleContentEditAndView(true, "view", row);
                  },
                  style: {
                    cursor: "pointer"
                  }
                }}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

// export default ContentTable;
export default connect(state => {
  const selector = formValueSelector("SelectedContents");
  return {
    contentSelected: selector(state, "contentSelected") || []
  };
})(
  reduxForm({
    form: "SelectedContents"
  })(ContentTable)
);
