import React from "react";
import { Tooltip } from "../core";

function TextClipper(id, text, subStrLen = 10, index, type, className) {
  if (text && text.length >= subStrLen) {
    return (
      <Tooltip
        item={`${text.substring(0, subStrLen)}...`}
        id={`${type}-${id}-${index}`}
        tooltipContent={text}
        className={className}
      />
    );
  }
  return text;
}

function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export { TextClipper, jsUcfirst };
