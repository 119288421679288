import React from "react";
import { Button } from "reactstrap";

import SearchTagRefresh from "../../../core/form/searchtagrefresh";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

const DomainDeviceHeader = props => (
  <div className="d-flex contentHeader">
    <div className="mr-auto">
      {props.currentDomainNameDDT !== "gurzu" &&
        (props.currentUserRolesDDT.includes("SuperAdmin") || props.editDDT) && (
          <Button
            className="primaryButton primaryButton--contentHeader"
            onClick={() => props.toggleModalDDT(true)}
            type="button"
          >
            <i className="fa fa-plus mr-2" />
            Add Device
          </Button>
        )}
    </div>
    <div className="d-flex flex-row-reverse">
      <SearchTagRefresh
        handleSearch={props.handleSearchValueDDT}
        placeholder="Search"
        searchVal={props.searchValDDT}
        {...props}
      />
    </div>
    <VisibleColumnToggler
      hiddenColumns={props.hiddenColumns}
      toggleHiddenColumns={props.toggleHiddenColumns}
      columnToggleProps={props.columnToggleProps}
    />
  </div>
);

export default DomainDeviceHeader;
