import React from "react";

export class StyledIframe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <iframe
        id={this.props.id}
        ref={this.props.forwardRef}
        title={this.props.title}
        src={this.props.dynamicUrl}
        width={this.props.width}
        height={this.props.height}
        scrolling="no"
        style={{
          border: "none",
          transform: `scale(${this.props.transformScale})`,
          transformOriginX: "left",
          transformOriginY: "top",
          WebkitTransform: `scale(${this.props.transformScale})`,
          WebkitTransformOriginX: "left",
          WebkitTransformOriginY: "top",
          MozTransformStyle: `scale(${this.props.transformScale})`,
          MozTransformOriginX: "left",
          MozTransformOriginY: "top"
        }}
      />
    );
  }
}
