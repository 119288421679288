import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { remove } from "lodash";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { columns } from "../utils/userListUtils";
import UserHeader from "../pages/userHeader";

class UserTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        name: false,
        email: false,
        roles: false,
        date_created: false,
        date_updated: false,
        confirmation: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      selectColumnStyle: { width: "20px" },
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("usersSelected", [
            ...this.props.usersSelected,
            row
          ]);
        } else {
          const { usersSelected } = this.props;
          remove(usersSelected, { id: row.id });
          this.props.change("usersSelected", usersSelected);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("usersSelected", [
            ...this.props.usersSelected,
            ...rows
          ]);
        } else {
          this.props.change("usersSelected", []);
        }
      }
    };

    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.userList}
          columns={columns(
            this.props.handleDelete,
            this.props.currentUser,
            this.props.handleSort,
            this.props.sortOrder,
            this.props.handleResendConfirmation,
            this.props.handleUserCreate,
            this.props.isSending,
            this.state.hiddenColumns
          )}
          columnToggle
        >
          {props => (
            <div>
              <UserHeader
                rolesUH={this.props.rolesUH}
                permissionUH={this.props.permissionUH}
                handleUserCreate={this.props.handleUserCreate}
                handleSearchValue={this.props.handleSearchValue}
                // below props are used by visibility column toggler
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                {...this.props}
              />
              <BootstrapTable
                classes="contentSectionTable"
                hover
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      width: "1412px",
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                keyField="id"
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                // selectRow={selectRow}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

// export default ContentTable;
export default connect(state => {
  const selector = formValueSelector("SelectedUsers");
  return {
    usersSelected: selector(state, "usersSelected") || []
  };
})(
  reduxForm({
    form: "SelectedUsers"
  })(UserTable)
);
