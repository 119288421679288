import React from "react";
import { connect } from "react-redux";
import {
  Card,
  Row,
  Col,
  CardImg,
  CardBody,
  CardSubtitle,
  CardTitle,
  CardText,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import moment from "moment";
import { toLower, startCase, debounce } from "lodash";
import { TextClipper } from "../../../helper/TextFormatter";

import { PopOver, Tooltip, SearchTagRefresh } from "../../../core";
import ContentHeader from "./contentHeader";

// import { Resources as deviceResources } from "../redux/deviceRedux";

/** Local import */

class CardImageWithLoad extends React.Component {
  constructor(props) {
    super(props);
    this.checkImages(props.url);
    this.state = {
      isValidUrl: false
    };
  }

  checkImages = url => {
    const img = new Image();
    img.onload = () => {
      this.setState({
        isValidUrl: true
      });
    };
    img.onerror = () => {
      this.setState({
        isValidUrl: false
      });
    };
    img.src = url;
  };

  render() {
    return (
      <img
        src={
          this.state.isValidUrl
            ? this.props.url
            : require("../../../assets/images/no-image.png")
        }
        alt="Cardcap"
      />
    );
  }
}
// eslint-disable-next-line react/no-multi-comp
class GridContentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showImageOverlay: false, currentPopoverId: undefined };
  }

  handleImageOverlayonPopoverToggle = id => {
    this.setState(prevState => ({
      showImageOverlay: !prevState.showImageOverlay,
      currentPopoverId: id
    }));
  };

  render() {
    return (
      <div>
        <ContentHeader
          forwardRef={this.props.searchRef}
          refreshing={this.props.refreshing}
          handleRefresh={this.props.handleRefresh}
          handleFilter={this.props.handleFilter}
          handleSearch={this.props.handleSearch}
          handleFilterWithType={this.props.handleFilterWithType}
          handleArchiveItems={this.props.handleArchiveItems}
          handleDeleteContents={this.props.handleDeleteContents}
          handleClearSearchValue={this.props.handleClearSearchValue}
          {...this.props}
        />
        <div className="screenShot_container ">
          <div className="row">
            {" "}
            {this.props.contentList.length > 0 ? (
              this.props.contentList.map(item => (
                <div
                  className="col-xs-12
                    col-sm-8
                    col-md-6
                    col-lg-3"
                  key={item.id}
                >
                  <Card className="screenShot_container__screen">
                    <CardBody>
                      <div
                        role="presentation"
                        className="screenShot_container__imageWrapper"
                        onClick={e => {
                          e.stopPropagation();
                          return this.props.handleContentEditAndView(
                            true,
                            "view",
                            item
                          );
                        }}
                        style={{
                          boxShadow:
                            (this.state.currentPopoverId ===
                              `content-tag-${item.id}` ||
                              this.state.currentPopoverId ===
                                `content-sponsor-${item.id}`) &&
                            this.state.showImageOverlay &&
                            "0px 0px 17px rgba(0, 0, 0, 0.25)"
                        }}
                      >
                        <div>
                          {item.attributes.is_filler_content &&
                          item.attributes.is_filler_content === true ? (
                            <>
                              <CardImageWithLoad
                                url={item.attributes.s3_thumbnail_large_url}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <i
                                  className="bx bxs-certification"
                                  style={{
                                    paddingTop: "20px",
                                    position: "absolute",
                                    paddingRight: "20px",
                                    color: "#e44b32"
                                  }}
                                ></i>
                              </div>
                            </>
                          ) : (
                            <CardImageWithLoad
                              url={item.attributes.s3_thumbnail_large_url}
                            />
                          )}
                        </div>
                        <div
                          className="imageOverlay imageOverlay--mod"
                          style={{
                            bottom:
                              (this.state.currentPopoverId ===
                                `content-tag-${item.id}` ||
                                this.state.currentPopoverId ===
                                  `content-sponsor-${item.id}`) &&
                              this.state.showImageOverlay &&
                              "0"
                          }}
                        >
                          <div className="overlayText">
                            <span className="deviceName">
                              {item.attributes.name}
                            </span>

                            <span className="macAddress">{`${
                              item.attributes.dimension
                            } | ${
                              item.attributes.updated_at
                                ? `Updated ${moment
                                    .utc(item.attributes.updated_at)
                                    .fromNow()}`
                                : "N/A"
                            } `}</span>
                            <div className="contentTag">
                              <ul>
                                <li>
                                  {item.attributes.tags &&
                                    item.attributes.tags
                                      .slice(0, 3)
                                      .map((i, index) => (
                                        <span
                                          key={`content-tag-${item.id} `}
                                          className="contentTag__badge"
                                        >
                                          {" "}
                                          {TextClipper(
                                            item.id,
                                            i,
                                            8,
                                            index,
                                            "tags",
                                            "contentTag__wrapper"
                                          )}
                                        </span>
                                      ))}
                                  <span>
                                    {item.attributes.tags &&
                                      item.attributes.tags.length > 3 && (
                                        <PopOver
                                          id={`content-tag-${item.id}`}
                                          handleImageOverlayonPopoverToggle={
                                            this
                                              .handleImageOverlayonPopoverToggle
                                          }
                                        >
                                          {item.attributes.tags.map(i => (
                                            <span
                                              key={`content-tag-${item.id}`}
                                              className="contentTag__wrapper contentTag__badge"
                                            >
                                              {i}
                                            </span>
                                          ))}
                                        </PopOver>
                                      )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="contentTag">
                              <ul>
                                <li>
                                  {item.attributes.sponsor_tags &&
                                    item.attributes.sponsor_tags
                                      .slice(0, 3)
                                      .map((i, index) => (
                                        <span
                                          key={`content-sponsor-${item.id} `}
                                          className="contentTag__badge contentTag__badge--mod"
                                        >
                                          {" "}
                                          {TextClipper(
                                            item.id,
                                            i,
                                            8,
                                            index,
                                            "sponsers",
                                            "contentTag__wrapper"
                                          )}
                                        </span>
                                      ))}
                                  <span>
                                    {item.attributes.sponsor_tags &&
                                      item.attributes.sponsor_tags.length >
                                        3 && (
                                        <PopOver
                                          id={`content-sponsor-${item.id}`}
                                          handleImageOverlayonPopoverToggle={
                                            this
                                              .handleImageOverlayonPopoverToggle
                                          }
                                        >
                                          {item.attributes.sponsor_tags.map(
                                            i => (
                                              <span
                                                key={`content-sponsor-${item.id} `}
                                                className="contentTag__wrapper contentTag__badge contentTag__badge--mod"
                                              >
                                                {i}
                                              </span>
                                            )
                                          )}
                                        </PopOver>
                                      )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="actionButtonWrapper">
                            <Tooltip
                              item={
                                <i
                                  role="presentation"
                                  onClick={e => {
                                    e.stopPropagation();
                                    return this.props.handleArchiveItem(
                                      item.id,
                                      item.attributes.name
                                    );
                                  }}
                                  className="fa fa-archive"
                                />
                              }
                              id={`archive-${item.id}`}
                              tooltipContent="Archive"
                            />
                            <Tooltip
                              item={
                                <i
                                  role="presentation"
                                  onClick={e => {
                                    e.stopPropagation();
                                    return this.props.handleDelete(
                                      item.id,
                                      item.attributes.name
                                    );
                                  }}
                                  className="ml-2 fa fa-trash"
                                />
                              }
                              id={`delete-${item.id}`}
                              tooltipContent="Delete"
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>{" "}
                  </Card>{" "}
                </div>
              ))
            ) : (
              <span className="null-message-info">No Data</span>
            )}{" "}
          </div>
        </div>{" "}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(GridContentView);
