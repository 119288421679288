/* eslint-disable camelcase */
import React, { Component } from "react";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import {
  Table,
  Card,
  Form,
  FormGroup,
  Button,
  Label,
  CardBody,
  Row,
  Col
} from "reactstrap";
import moment from "moment";
import { showNotification } from "../../../core/modal/toster";
import { Resources } from "../redux/campaignRedux";
// import { Resources as DomainResources } from "../redux/domainRedux";

import { MultipleSelect } from "../../../core/form/searchSelect";

class DefaultCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      name: undefined,
      created_at: undefined,
      status: undefined,
      orientation: undefined,
      isLoading: true,
      isDisabled: false
    };
    this.loadAllCampaignList();
    this.loadDefaultCampaign();
  }

  loadAllCampaignList = () => {
    Resources.getCampaign.url = `/campaigns`;
    this.props.Get(Resources.getCampaign);
  };

  handleSubmit = campaign => {
    this.setState({ isDisabled: true });
    const { value } = campaign.default_campaign;
    const { setDefaultCampaign } = Resources;
    this.props
      .Get(setDefaultCampaign(value))
      .then(result => {
        if (result.status === 200) {
          this.props.showNotification(
            "Default Campaign Set Successfully",
            "success"
          );
        } else {
          this.props.showNotification("Couldn't set default campaign", "error");
        }
      })
      .then(() => this.props.change("default_campaign", []))
      .then(() => this.loadDefaultCampaign())
      .then(() => this.setState({ isDisabled: false }));
  };

  loadDefaultCampaign = () => {
    const { getDefaultCampaign } = Resources;
    this.props
      .Get(getDefaultCampaign())
      .then(result => {
        if (result.status === 200) {
          const {
            data: { _id, name, created_at, status, orientation }
          } = result;
          this.setState({
            id: _id.$oid,
            name,
            created_at,
            status,
            orientation
          });
        } else if (result.status === 450) {
          this.setState({
            id: undefined,
            name: undefined,
            created_at: undefined,
            status: undefined,
            orientation: undefined
          });
        }
      })
      .then(() => this.setState({ isLoading: false }));
  };

  render() {
    const {
      id,
      name,
      created_at,
      status,
      orientation,
      isLoading,
      isDisabled
    } = this.state;
    if (isLoading) {
      return (
        <div className="d-block text-center">
          {/* <div className="lds-dual-ring" /> */}
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    return (
      <div className="contentwrapper">
        <section>
          {
            <div
              className="d-flex pr-0 contentheader"
              data-spy="affix"
              data-offset-top="197"
            >
              <h5 className="p-0 mr-auto mt-2">Default Campaign</h5>
              <Form className="mulitselectAction mulitselectAction--userRole ">
                <Field
                  component={MultipleSelect}
                  name="default_campaign"
                  options={this.props.campaignList
                    .filter(campaign => campaign.id !== id)
                    .map(item => ({
                      value: item.id,
                      label: item.attributes.name
                    }))}
                  isClearable
                />
              </Form>
              <Button
                type="button"
                onClick={this.props.handleSubmit(values =>
                  this.handleSubmit(values)
                )}
                color="secondary"
                className="ml-3"
                disabled={this.props.selectedCampaign.length <= 0 || isDisabled}
              >
                Set as Default
              </Button>
            </div>
          }

          {isDisabled && (
            <div>
              <div
                id="notification_toast-flash"
                className="notification_toast info animated fadeInUp"
              >
                <p>
                  Sending default campaign to all devices{" "}
                  <i className="fa fa-spinner fa-spin" />
                </p>
              </div>
            </div>
          )}
        </section>

        <div className="groupTable">
          <div className="defaultcampaign-BigWrapper">
            {id ? (
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Created Date</th>
                    <th>Orientation </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={id}>
                    <td>{name}</td>
                    <td>{status}</td>
                    <td>{moment(created_at).format("MM/DD/YYYY")}</td>
                    <td>{orientation}</td>
                  </tr>
                </tbody>
              </Table>
            ) : (
              <React.Fragment>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Created Date</th>
                      <th>Orientation </th>
                    </tr>
                  </thead>
                  <tbody />
                </Table>
                <div
                  className="drag-playlist"
                  style={{ width: "100%", height: "200px" }}
                >
                  <h5 className="pt-5">There is no default campaign yet. !!</h5>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list } = state.totalCampaign;
  const selector = formValueSelector("DefaultCampaignForm");
  return {
    campaignList: list !== undefined ? list : [],
    selectedCampaign: selector(state, "default_campaign") || []
  };
}

export default connect(
  mapStateToProps,
  { showNotification, change }
)(
  reduxForm({
    form: "DefaultCampaignForm"
  })(DefaultCampaign)
);
