import React from "react";
import moment from "moment";
import { Tooltip } from "../../../core";

export function columns(hiddenColumns) {
  return [
    {
      dataField: "attributes.message",
      text: "Activity",
      columnName: "activity_name",
      hidden: hiddenColumns.activity_name,
      formatter: (name, row) => (
        <span>
          {name.length >= 50 ? (
            <Tooltip
              item={
                <span className="content__name">
                  {" "}
                  {`${name.slice(0, 50)}...`}{" "}
                </span>
              }
              id={`viewname-${row.id}`}
              tooltipContent={name}
            />
          ) : (
            <span className="content__name">{name}</span>
          )}
        </span>
      )
    },
    {
      dataField: "attributes.created_at",
      text: "Time Stamp",
      columnName: "timeStamp",
      hidden: hiddenColumns.timeStamp,
      formatter: val => (
        <span>
          {moment
            .utc(val)
            .local()
            .format("lll")}
        </span>
      )
    },
    {
      dataField: "attributes.device_group_info",
      text: "Device Detail",
      columnName: "deviceDetail",
      hidden: hiddenColumns.deviceDetail,
      formatter: (val, row) => (
        <span>
          {row.attributes.device_info &&
          row.attributes.device_info.length >= 80 ? (
            <Tooltip
              item={
                <span className="content__name">
                  {" "}
                  {`${val} : ${row.attributes.device_info.slice(
                    0,
                    80
                  )}...`}{" "}
                </span>
              }
              id={`viewname-${row.id}`}
              tooltipContent={row.attributes.device_info}
            />
          ) : (
            <span className="content__name">
              {" "}
              {`${val}: ${row.attributes.device_info}` || "N/A"}
            </span>
          )}
          {/* {val ? `${val}: ${row.attributes.device_info}` : "N/A"} */}
        </span>
      )
    },
    {
      dataField: "attributes.ip_address",
      text: "IP Address",
      columnName: "ipAddress",
      hidden: hiddenColumns.ipAddress
    },
    {
      dataField: "attributes.browser",
      text: "Browser",
      columnName: "browser",
      hidden: hiddenColumns.browser,
      formatter: (name, row) => (
        <span>
          {name.length >= 50 ? (
            <Tooltip
              item={<span> {`${name.slice(0, 50)}...`} </span>}
              id={`browsername-${row.id}`}
              tooltipContent={name}
            />
          ) : (
            <span>{name}</span>
          )}
        </span>
      )
    }
  ];
}
