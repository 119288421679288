import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";

import { formatMetrics } from "./utils/formatMetrics";
import MetricsHeader from "./metricsHeader";
import NucMetrics from "./nucMetrics";
import { MetricsResources } from "./../../redux";

class Metrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingMetrics: true,
      selectedDevice: "",
      data: {},
      refetch: true,
      timeFrame: "15.minutes",
      isZoom: false,
      zoomedData: {}
    };
  }

  componentDidMount = () => {
    this.setState({ selectedDevice: this.props.deviceId });
    this.handleLoadMetricsData(this.props.deviceId);
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.history.location.state &&
      this.props.history.location.state.fromNotification &&
      prevProps.location.key !== this.props.location.key
    ) {
      this.setState({ selectedDevice: this.props.location.state.deviceID });
      this.handleLoadMetricsData(this.props.location.state.deviceID);
    }
  }

  handleLoadMetricsData = async id => {
    await this.handleLoadDeviceNucMetrics(id);
    await this.handleLatestDeviceMetrics(id);
    this.setState({
      isLoading: false
    });
  };

  handleLoadDeviceNucMetrics = deviceId => {
    this.setState({ isZoom: false });

    MetricsResources.getDeviceMetric.url = `devices/${deviceId}/device_system_metrics?time_frame=${this.state.timeFrame}`;
    return this.props.Get(MetricsResources.getDeviceMetric).then(response => {
      this.setState({ data: formatMetrics(this.props.deviceMetrics) });
      this.setState({ isLoadingMetrics: false });
    });
  };

  handleLoadDeviceNucMetricsWithRange = (min, max) => {
    if (
      !this.state.isZoom &&
      (this.state.timeFrame === "24.hours" || this.state.timeFrame === "1.week")
    ) {
      this.setState({ isLoadingMetrics: true, isZoom: true });
      MetricsResources.getSelectedRangeMetrics.url = `devices/${this.state.selectedDevice}/device_system_metrics?time_frame=${this.state.timeFrame}&start_timestamp=${min}&end_timestamp=${max}`;
      return this.props
        .Get(MetricsResources.getSelectedRangeMetrics)
        .then(() => {
          this.setState({ zoomedData: formatMetrics(this.props.zoomMetrics) });
          this.setState({ isLoadingMetrics: false });
        });
    }
  };

  toggleZoom = flag => {
    this.setState({ isZoom: flag });
  };

  handleLatestDeviceMetrics = deviceId => {
    this.props.Get(MetricsResources.getLatestDeviceMetric(deviceId));
  };

  setSelectedDevice = deviceId => {
    this.setState({ selectedDevice: deviceId, isLoadingMetrics: true });
    this.handleLoadDeviceNucMetrics(deviceId);
    this.handleLatestDeviceMetrics(deviceId);
  };

  changeSelectedDevice = (index, i) => {
    this.setState({ selectedDevice: i.id });
    this.setState({ selectedDevice: i.id, isLoadingMetrics: true });
    this.handleLoadDeviceNucMetrics(i.id);
    this.handleLatestDeviceMetrics(i.id);
  };

  handleRefetch = () => {
    this.setState({ refetch: false });
    this.forceUpdate();
  };

  handleChange = value => {
    this.setState({ timeFrame: value.value, isLoadingMetrics: true }, () => {
      this.handleLoadDeviceNucMetrics(this.state.selectedDevice);
    });
  };

  handleRefresh = () => {
    this.setState({ isLoadingMetrics: true, isZoom: false }, () => {
      this.handleLoadDeviceNucMetrics(this.state.selectedDevice);
    });
  };

  render() {
    if (this.props.isLoading || this.state.isLoading) {
      return (
        <div
          className="d-block justify-content-center text-center"
          style={{ marginTop: "100px" }}
        >
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    const { latestMetrics } = this.props;
    return (
      <div className="networkDetail__info row" style={{ width: "100%" }}>
        <div className="col-md-12">
          <div className="row" style={{ paddingLeft: 20 }}>
            <MetricsHeader
              isLoadingMetrics={this.state.isLoadingMetrics}
              handleChange={this.handleChange}
              handleRefresh={this.handleRefresh}
            />
            <div className="row calendarSection content-wrap">
              <div className="pl-0 pr-0">
                <NucMetrics
                  isLoadingMetrics={this.state.isLoadingMetrics}
                  data={
                    this.state.isZoom ? this.state.zoomedData : this.state.data
                  }
                  latestMetrics={latestMetrics}
                  handleLoadDeviceNucMetricsWithRange={
                    this.handleLoadDeviceNucMetricsWithRange
                  }
                  isZoom={this.state.isZoom}
                  toggleZoom={this.toggleZoom}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list: data } = state.deviceNucMetrics;
  const { list: latestMetrics } = state.latestDeviceNucMetrics;
  const { list: zoomMetrics } = state.deviceNucMetricsOfRange;
  return {
    deviceMetrics: data || [],
    latestMetrics: latestMetrics || [],
    zoomMetrics: zoomMetrics || []
  };
}

export default connect(
  mapStateToProps,
  {}
)(Metrics);
