/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

export const BigCalendarHeaderTitle = props => (
  <input
    id="calendarTitle"
    autoComplete="off"
    placeholder="Untitled Calendar"
    type="text"
    value={props.calendarTitle}
    onChange={text => props.calendarTitleChange(text.target.value)}
    ref={props.calendarInputRef}
    className={`${
      props.action === "Edit" || props.action === "Inventory"
        ? "headerViewTitle"
        : `mt-0 headerTitle ${props.isCalendarListOpen && "headerTitle--edit"}`
    } headerTitle--mod`}
  />
);
