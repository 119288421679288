import React from "react";
import { toLower, startCase } from "lodash";
import { Tooltip } from "../../../core";

export function columns(
  editUser,
  removeExistingUserFromDomain,
  currentUser,
  hiddenColumns
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      hidden: hiddenColumns.name
    },
    {
      dataField: "attributes.email",
      text: "Email",
      columnName: "email",
      hidden: hiddenColumns.email
    },
    {
      dataField: "attributes.roles",
      text: "Roles",
      columnName: "roles",
      hidden: hiddenColumns.roles,
      formatter: (roles, row) => (
        <div>
          {" "}
          {roles.map(item => (
            <span key={item} className={`roles ${toLower(item)} `}>
              {startCase(item)}
            </span>
          ))}
        </div>
      )
    },
    {
      dataField: "actionButtons",
      text: "",
      columnName: "isSelected",
      style: { position: "relative" },
      formatter: (name, row) => (
        <div className="d-flex">
          {currentUser.id !== row.id && (
            <div className="contentSectionTable__actionButtonWrapper">
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      className="fa fa-edit"
                      onClick={() =>
                        editUser(
                          row.id,
                          row.attributes.name,
                          row.attributes.roles
                        )
                      }
                      role="presentation"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  id={`edit-${row.id}`}
                  tooltipContent="Edit"
                />
              </span>
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      className="fas fa-times-circle "
                      role="presentation"
                      style={{ cursor: "pointer" }}
                      onClick={e =>
                        removeExistingUserFromDomain(
                          row.id,
                          row.attributes.name
                        )
                      }
                    />
                  }
                  id={`delete-${row.id}`}
                  tooltipContent="Remove"
                />
              </span>
            </div>
          )}{" "}
        </div>
      )
    }
  ];
}
