import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { toLower, debounce } from "lodash";
import ReactPaginate from "react-paginate";
import { required } from "../../../helper/validation";

import { MultipleSelect } from "../../../core/form/searchSelect";
import { Tooltip, Dialog } from "../../../core";
import DomainDeviceTable from "./domainDeviceTable";
import { Resources, removeDeviceFromDomain } from "../redux/domainRedux";
import { Resources as DeviceResources } from "../../network/redux/deviceRedux";

class DomainDevice extends Component {
  handleSearch = debounce((data = 1) => {
    this.setState({ isLoading: true, forcePage: undefined });
    const { getDomainDevices } = Resources;
    if (this.state.searchVal === "") {
      getDomainDevices.url = `domains/${this.props.match.params.id}/domaindevices_list?page=${data}&per_page=20`;
    } else {
      getDomainDevices.url = `/domains/${this.props.selectedDomain}/domaindevices_search/${this.state.searchVal}?page=${data}&per_page=20`;
    }
    this.props.Get(getDomainDevices).then(() => {
      this.setState({ isLoading: false });
    });
  }, 3000);

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      deleteModal: false,
      forcePage: undefined,
      isLoading: true,
      searchVal: "",
      removeDeviceID: undefined,
      removeDeviceName: undefined,
      removeModal: false
    };
  }

  componentDidMount() {
    this.loadDomainDevices();
  }

  handleRemoveDevice = (id, name) => {
    this.setState({
      removeModal: true,
      removeDeviceID: id,
      removeDeviceName: name
    });
  };

  toggleRemoveDeviceModal = () => {
    this.setState(state => ({ removeModal: !state.removeModal }));
  };

  loadDomainDevices = (data = 1) => {
    const { getDomainDevices } = Resources;
    if (this.state.searchVal) {
      getDomainDevices.url = `/domains/${this.props.selectedDomain}/domaindevices_search/${this.state.searchVal}?page=${data}&per_page=20`;
    } else {
      getDomainDevices.url = `domains/${this.props.match.params.id}/domaindevices_list?page=${data}&per_page=20`;
    }
    this.props.Get(getDomainDevices).then(() => {
      this.setState({ isLoading: false });
    });
  };

  removeExistingDevicesFromDomain = async id => {
    const { removeDeviceID } = this.state;
    /**  make a api call to remove device from domain with received id */
    const { updateDomain } = Resources;
    // /api/v1/domains/#{domain.id}/domains/#{domain.id}/remove_devices
    updateDomain.url = `/domains/${this.props.match.params.id}/remove_devices`;
    updateDomain.body = removeDeviceFromDomain(
      this.props.match.params.id,
      removeDeviceID
    );
    await this.props.Put(updateDomain).then(() => {
      this.setState({
        removeModal: false,
        removeDeviceID: null,
        removeDeviceName: null
      });
      if (this.state.searchVal) {
        this.setState({ searchVal: "" });
        this.loadDomainDevices();
      } else {
        this.loadDomainDevices(this.props.meta.page);
      }
      this.loadDomainDevices(this.props.meta.page);
      this.props.loadGurzuDevicesFromServer();
    });
  };

  handleSearchValue = value => {
    this.setState({ searchVal: value, forcePage: 0 }, () => {
      this.handleSearch();
    });
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true, forcePage: undefined });
    const { getDomainDevices } = Resources;
    if (moveToPrevious) {
      if (this.state.searchVal) {
        getDomainDevices.url = `/domains/${
          this.props.selectedDomain
        }/domaindevices_search/${this.state.searchVal}?page=${data.page -
          1}&per_page=20`;
        this.props.Get(getDomainDevices).then(() => {
          this.setState({ forcePage: data.page - 2, isLoading: false });
        });
      } else {
        getDomainDevices.url = `domains/${
          this.props.selectedDomain
        }/domaindevices_list?page=${data.page - 1}&per_page=20`;
        this.props.Get(getDomainDevices).then(() => {
          this.setState({ forcePage: data.page - 2, isLoading: false });
        });
      }
    } else {
      const { selected } = data;
      if (this.state.searchVal) {
        getDomainDevices.url = `/domains/${
          this.props.selectedDomain
        }/domaindevices_search/${this.state.searchVal}?page=${selected +
          1}&per_page=20`;
        this.props.Get(getDomainDevices).then(() => {
          this.setState({ forcePage: selected, isLoading: false });
        });
      } else {
        getDomainDevices.url = `domains/${
          this.props.selectedDomain
        }/domaindevices_list?page=${selected + 1}&per_page=20`;
        this.props
          .Get(getDomainDevices)
          .then(() => this.setState({ forcePage: selected, isLoading: false }));
      }
    }
  };

  toggleModal = flag => this.setState({ modal: flag });

  toggleDeleteModal = () => {
    this.setState(state => ({ deleteModal: !state.deleteModal }));
  };

  addDevice = async values => {
    this.toggleModal(false);
    await this.props.addDeviceToDomain(values);
    await this.loadDomainDevices();
    await this.props.loadGurzuDevicesFromServer();
  };

  handleDeleteDevice = (id, name) => {
    this.setState({
      deleteModal: true,
      removeDeviceID: id,
      removeDeviceName: name
    });
  };

  /** Delete device from system */
  removeExistingDevicesFromSystem = async () => {
    const { removeDeviceID } = this.state;
    const { deleteDevices } = DeviceResources;
    await this.props.Delete(deleteDevices(removeDeviceID)).then(() => {
      this.setState({
        deleteModal: false,
        removeDeviceID: null,
        removeDeviceName: null
      });
      this.loadDomainDevices(this.props.meta.page);
    });
  };

  render() {
    const {
      devices,
      currentUserRoles,
      allDevicesList,
      handleSubmit,
      removeExistingDevicesFromDomain,
      currentDomainName,
      domainDevices
    } = this.props;
    return (
      <div>
        <Modal isOpen={this.state.modal} className={this.props.className}>
          <ModalHeader toggle={() => this.toggleModal(false)}>
            Add Device
          </ModalHeader>
          <ModalBody>
            {" "}
            <Form className="globalForm">
              <FormGroup>
                <div className="globalInput">
                  <Field
                    component={MultipleSelect}
                    name="devices"
                    validate={[required]}
                    options={allDevicesList.map(item => ({
                      value: item.id,
                      label: `${item.attributes.name} | ${item.attributes.mac_address}`
                    }))}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                </div>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn-outline-primary"
              onClick={() => this.toggleModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit(values => this.addDevice(values))}
              className="mt-0 primaryButton"
              disabled={domainDevices.length === 0}
            >
              Add
            </button>
          </ModalFooter>
        </Modal>
        <DomainDeviceTable
          devices={this.props.domainDevicesList}
          currentUserRoles={currentUserRoles}
          currentDomainName={currentDomainName}
          removeExistingDevicesFromDomain={this.handleRemoveDevice}
          isLoading={this.state.isLoading}
          // following props are used for domain device table header component
          currentDomainNameDDT={this.props.currentDomainName}
          currentUserRolesDDT={this.props.currentUserRoles}
          editDDT={this.state.edit}
          toggleModalDDT={this.toggleModal}
          handleSearchValueDDT={this.handleSearchValue}
          searchValDDT={this.state.searchVal}
          removeExistingDevicesFromSystem={this.handleDeleteDevice}
          {...this.props}
        />
        {this.props.domainDevicesList &&
          this.props.domainDevicesList.length > 0 && (
            <div className="contentSectionPagination clearfix">
              <ReactPaginate
                pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                marginPagesDisplayed={2}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                previousLabel="<"
                forcePage={this.state.forcePage}
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                disabledClassName="paginationDisable"
              />
            </div>
          )}
        <Dialog
          showHandler={false}
          externalControl
          modal={this.state.removeModal}
          toggleModal={this.toggleRemoveDeviceModal}
          body={
            <span>
              <small>Are you sure you want to remove </small>
              <strong>{this.state.removeDeviceName}</strong>?
              <div>
                <strong>Remove Device</strong>{" "}
                <small>will remove the device from this domain only.</small>
              </div>
            </span>
          }
          primaryMethod={this.removeExistingDevicesFromDomain}
          toggleModel={this.toggleRemoveDeviceModal}
          title="Remove Device"
        />
        <Dialog
          showHandler={false}
          externalControl
          modal={this.state.deleteModal}
          toggleModal={this.toggleDeleteModal}
          body={
            <span>
              <small>Are you sure you want to delete </small>
              <strong>{this.state.removeDeviceName}</strong>?
              <div>
                <strong>Delete Device</strong>{" "}
                <small>
                  will delete the device from publish-network and the system.
                </small>
              </div>
            </span>
          }
          primaryMethod={this.removeExistingDevicesFromSystem}
          toggleModel={this.toggleDeleteModal}
          title="Delete Device"
        />
      </div>
    );
  }
}

function mapState(state) {
  const selector = formValueSelector("DomainDeviceForm");
  const { list, meta } = state.domainDevices;
  return {
    domainDevices: selector(state, "devices") || [],
    domainDevicesList: list || [],
    meta
  };
}

export default connect(mapState)(
  reduxForm({
    form: "DomainDeviceForm"
  })(DomainDevice)
);
