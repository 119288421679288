import moment from "moment";

export const checkOverlap = incommingEvents => {
  /** *
   * @params array of events in calendar
   * This method checks if there's overlap in time between events
   * Where each events must contain start and end date-time information for this function to work
   * And should contain Unique events. i.e. no duplicate events info to be supplied here
   * If there's overlap, it returns the - array of - two events that overlaps; the in first in chronological order
   * If there's no overlap, it returns false boolean value
   */

  const events = [...incommingEvents]; // to ensure there's no mutation in the source argument

  if (events.length === 1) return false;
  // first sort the events in ascending order, according to their date and time
  events.sort((e1, e2) => dateCompare(e1.start, e2.start)); // mutates the copied events
  // now on sorted events, check if there's overlap
  for (let i = 0; i < events.length - 1; i++) {
    const currentEndTime = events[i].end;
    const nextStartTime = events[i + 1].start;
    if (moment(currentEndTime).isAfter(moment(nextStartTime))) {
      return [events[i], events[i + 1]];
    }
  }
  return false;
};

// @utils
export const dateCompare = (reference, compare) => {
  // comparer for sorting event
  if (moment(reference).isBefore(moment(compare))) {
    // Negative when the referenceTime occurs before compareTime
    return Number(-1);
  }
  if (moment(reference).isAfter(moment(compare))) {
    // Positive when the referenceTime occurs after compareTime
    return Number(1);
  }
  // Returns 0 if they are equivalent
  return Number(0);
};
