import normalize from "json-api-normalizer";

const BulkListDevicesActions = devices => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        device_ids: devices.map(device => device.id)
      }
    }
  }
});

const BulkListVolumeBodyGenerator = (devices, volume) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        device_ids: devices.map(device => device.id),
        volume_level: volume
      }
    }
  }
});

const BULKRS232BodyGenerator = (devices, code) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        device_ids: devices.map(device => device.id),
        control_signal: code
      }
    }
  }
});

const BulkListEnvironmentBodyGenerator = (devices, env) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        device_ids: devices.map(device => device.id),
        electron_environment: env
      }
    }
  }
});

const BulkListElectronBodyGenerator = (devices, electronEnv) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        device_ids: devices.map(device => device.id),
        electron_tag: electronEnv
      }
    }
  }
});

const BulkListLegacyElectronBodyGenerator = (devices, legacyEnv) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        device_ids: devices.map(device => device.id),
        electron_tag: legacyEnv
      }
    }
  }
});

const BulkListResolutionBodyGenerator = (devices, resolution) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        device_ids: devices.map(device => device.id),
        resolution
      }
    }
  }
});

const BulkListOrientationBodyGenerator = (devices, orientation) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        device_ids: devices.map(device => device.id),
        orientation
      }
    }
  }
});
const BulkListTimeZoneBodyGenerator = (devices, timezone) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        device_ids: devices.map(device => device.id),
        timezone
      }
    }
  }
});

const PopBodyGenerator = flag => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        block_pop: flag
      }
    }
  }
});

const RejectDevicesBodyGenerator = macAddress => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        mac_address: macAddress
      }
    }
  }
});

const AcceptDevicesBodyGenerator = (macAddress, domain) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        mac_address: macAddress,
        domain_id: domain
      }
    }
  }
});

export const Resources = {
  getDeviceDetails: {
    url: "",
    asyncActions: {
      init: "getDevice_INIT",
      success: "getDevice_SUCCESS",
      error: "getDevice_ERROR"
    }
  },
  MonitorControlCommand: deviceID => ({
    url: `devices/${deviceID}/run_rs232_codes`,
    asyncActions: {
      init: "MonitorControlCommand_INIT",
      success: "MonitorControlCommand_SUCCESS",
      error: "MonitorControlCommand_ERROR"
    }
  }),
  checkMonitorSupport: deviceID => ({
    url: `devices/${deviceID}/rs232_support`,
    asyncActions: {
      init: "checkMonitorSupport_INIT",
      success: "checkMonitorSupport_SUCCESS",
      error: "checkMonitorSupport_ERROR",
      errorMsg: "nomsg"
    }
  }),
  setDeviceResolution: (deviceID, resolution) => ({
    url: `devices/${deviceID}/change_resolution?resolution=${resolution}`,
    asyncActions: {
      init: "setDeviceResolution_INIT",
      success: "getDevice_SUCCESS",
      error: "setDeviceResolution_ERROR",
      successMsg: "Device resolution changed successfully.",
      errorMsg: "Couldn't change device resolution. Make sure device is online."
    }
  }),
  setDeviceTimeZone: (deviceID, timezone) => ({
    url: `devices/${deviceID}/change_timezone?timezone=${timezone}`,
    asyncActions: {
      init: "setDeviceResolution_INIT",
      success: "setDeviceResolution_SUCCESS",
      error: "setDeviceResolution_ERROR",
      successMsg: "Device timezone changed successfully.",
      errorMsg: "Couldn't change device timezone. Make sure device is online."
    }
  }),
  sendLatestElectronAppToDevice: (deviceID, tag) => ({
    url: `devices/${deviceID}/update_electron?electron_tag=${tag}`,
    asyncActions: {
      init: "sendLatestElectronAppToDevice_INIT",
      success: "sendLatestElectronAppToDevice_SUCCESS",
      error: "sendLatestElectronAppToDevice_ERROR",
      successMsg: "Device software update was successfully.",
      errorMsg: "Couldn't update device software. Make sure device is online."
    }
  }),
  sendLegacyElectronAppToDevice: (deviceID, tag) => ({
    url: `devices/${deviceID}/update_legacy_electron?legacy_electron_tag=${tag}`,
    asyncActions: {
      init: "sendLegacyElectronAppToDevice_INIT",
      success: "sendLegacyElectronAppToDevice_SUCCESS",
      error: "sendLegacyElectronAppToDevice_ERROR",
      successMsg: "Device software update was successfully.",
      errorMsg: "Couldn't update device software. Make sure device is online."
    }
  }),
  sendElectronAppEnvironment: (deviceId, environment) => ({
    url: `devices/${deviceId}/update_electron_env?electron_environment=${environment}`,
    asyncActions: {
      init: "sendElectronAppEnvironment_INIT",
      success: "sendElectronAppEnvironment_SUCCESS",
      error: "sendElectronAppEnvironment_ERROR",
      successMsg: "Device software update was successfully.",
      errorMsg: "Couldn't update device software. Make sure device is online."
    }
  }),
  getDiskUsages: (deviceID = null) => ({
    url: `/devices/${deviceID}/disk_usage`,
    asyncActions: {
      init: "getDiskUsages_INIT",
      success: "getDiskUsages_SUCCESS",
      error: "getDiskUsages_ERROR",
      successMsg: "nomsg",
      errorMsg: "nomsg"
    }
  }),
  getDeviceSS: id => ({
    url: `devices/${id}/device_screenshot`,
    asyncActions: {
      init: "getDevice_INIT",
      success: "getDevice_SUCCESS",
      error: "getDevice_ERROR",
      successMsg: "Screenshot Updated"
    }
  }),
  stopDevice: id => ({
    url: `devices/${id}/stop`,
    asyncActions: {
      init: "stopDevice_INIT",
      success: "stopDevice_SUCCESS",
      error: "stopDevice_ERROR",
      successMsg: "Device Stopped."
    }
  }),
  deleteDevices: deviceID => ({
    url: `/devices/${deviceID}`,
    asyncActions: {
      init: "deleteDevices_INIT",
      success: "deleteDevices_SUCCESS",
      error: "deleteDevices_ERROR",
      successMsg: "Devices Deleted."
    }
  }),
  stopBulkListDevices: {
    url: "/devices/stop_all",
    bodyFunction: BulkListDevicesActions,
    asyncActions: {
      init: "stopBulkListDevice_INIT",
      success: "stopBulkListDevice_SUCCESS",
      error: "stopBulkListDevice_ERROR",
      successMsg: "Devices Stopped."
    }
  },

  restartBulkListMPS: {
    url: "/devices/bulk_restart_mps",
    bodyFunction: BulkListDevicesActions,
    asyncActions: {
      init: "restartBulkListMPS_INIT",
      success: "restartBulkListMPS_SUCCESS",
      error: "restartBulkListMPS_ERROR",
      successMsg: "MPS Restarted Successfully."
    }
  },

  restartBulkListDevices: {
    url: "/devices/bulk_restart_devices",
    bodyFunction: BulkListDevicesActions,
    asyncActions: {
      init: "restartBulkListDevices_INIT",
      success: "restartBulkListDevices_SUCCESS",
      error: "restartBulkListDevices_ERROR",
      successMsg: "Devices Restarted Succesfully."
    }
  },

  resyncBulkListDevices: {
    url: "/devices/resync_play",
    bodyFunction: BulkListDevicesActions,
    asyncActions: {
      init: "resyncBulkListDevices_INIT",
      success: "resyncBulkListDevices_SUCCESS",
      error: "resyncBulkListDevices_ERROR"
    }
  },

  updateBulkListDeviceVolume: {
    url: "/devices/bulk_devices_volume",
    bodyFunction: BulkListVolumeBodyGenerator,
    asyncActions: {
      init: "updateBulkListDeviceVolume_INIT",
      success: "updateBulkListDeviceVolume_SUCCESS",
      error: "updateBulkListDeviceVolume_ERROR"
    }
  },

  updateBulkListDevicesRS232: {
    url: "/devices/bulk_run_rs232_codes",
    bodyFunction: BULKRS232BodyGenerator,
    asyncActions: {
      init: "updateBulkListDevicesRS232_INIT",
      success: "updateBulkListDevicesRS232_SUCCESS",
      error: "updateBulkListDevicesRS232_ERROR"
    }
  },
  updateBulkListDevicesEnv: {
    url: "/devices/bulk_update_electron_env",
    bodyFunction: BulkListEnvironmentBodyGenerator,
    asyncActions: {
      init: "updateBulkListDevicesEnv_INIT",
      success: "updateBulkListDevicesEnv_SUCCESS",
      error: "updateBulkListDevicesEnv_ERROR"
    }
  },

  updateBulkListDevicesElectronEnv: {
    url: "/devices/bulk_update_electron",
    bodyFunction: BulkListElectronBodyGenerator,
    asyncActions: {
      init: "updateBulkListDevicesElectronEnv_INIT",
      success: "updateBulkListDevicesElectronEnv_SUCCESS",
      error: "updateBulkListDevicesElectronEnv_ERROR"
    }
  },
  updateBulkListDevicesLegacyEnv: {
    url: "/devices/bulk_update_legacy_electron",
    bodyFunction: BulkListLegacyElectronBodyGenerator,
    asyncActions: {
      init: "updateBulkListDevicesLegacyEnv_INIT",
      success: "updateBulkListDevicesLegacyEnv_SUCCESS",
      error: "updateBulkListDevicesLegacyEnv_ERROR"
    }
  },
  updateBulkListDevicesResolution: {
    url: "/devices/bulk_devices_resolution",
    bodyFunction: BulkListResolutionBodyGenerator,
    asyncActions: {
      init: "updateBulkListDevicesResolution_INIT",
      success: "updateBulkListDevicesResolution_SUCCESS",
      error: "updateBulkListDevicesResolution_ERROR"
    }
  },
  updateBulkListDevicesTimeZone: {
    url: "/devices/bulk_devices_timezone",
    bodyFunction: BulkListTimeZoneBodyGenerator,
    asyncActions: {
      init: "updateBulkListDevicesTimeZone_INIT",
      success: "updateBulkListDevicesTimeZone_SUCCESS",
      error: "updateBulkListDevicesTimeZone_ERROR"
    }
  },
  updateBulkListDevicesOrientation: {
    url: "/devices/bulk_devices_orientation",
    bodyFunction: BulkListOrientationBodyGenerator,
    asyncActions: {
      init: "updateBulkListDevicesOrientation_INIT",
      success: "updateBulkListDevicesOrientation_SUCCESS",
      error: "updateBulkListDevicesOrientation_ERROR"
    }
  },
  restartDevice: id => ({
    url: `devices/${id}/restart_device`,
    asyncActions: {
      init: " restartDevice_INIT",
      success: " restartDevice_SUCCESS",
      error: " restartDevice_ERROR",
      successMsg: "Device restarted."
    }
  }),
  restartElectronClient: id => ({
    url: `devices/${id}/restart_mps`,
    asyncActions: {
      init: "restartElectronClient_INIT",
      success: "restartElectronClient_SUCCESS",
      error: "restartElectronClient_ERROR",
      successMsg: "Electron Restarted"
    }
  }),
  setDeviceOrientation: (deviceID, orientation) => ({
    url: `devices/${deviceID}/change_orientation?orientation=${orientation}`,
    asyncActions: {
      init: "getDevice_INIT",
      success: "getDevice_SUCCESS",
      error: "getDevice_ERROR",
      successMsg: "Device orientation changed successfully.",
      errorMsg: "Couldn't update orientation. Please try again."
    }
  }),
  setDeviceVolume: (id, volume) => ({
    url: `devices/${id}/device_volume?volume=${volume}`,
    asyncActions: {
      init: "setDeviceVolume_INIT",
      success: "setDeviceVolume_SUCCESS",
      error: "setDeviceVolume_ERROR",
      successMsg: "Volume Updated",
      errorMsg: "Couldn't update volume. Please try again."
    }
  }),
  setDeviceHostName: (id, name) => ({
    url: `devices/${id}/change_hostname?hostname=${name}`,
    asyncActions: {
      init: "setDeviceHostName_INIT",
      success: "getDevice_SUCCESS",
      error: "setDeviceHostName_ERROR",
      successMsg: "Host Name Updated",
      errorMsg: "Couldn't update hostname. Please try again."
    }
  }),
  getElectronVersion: (deviceID = null) => ({
    url: `/devices/${deviceID}/electron_version`,
    asyncActions: {
      init: "getElectronVersion_INIT",
      success: "getElectronVersion_SUCCESS",
      error: "getElectronVersion_ERROR",
      successMsg: "nomsg",
      errorMsg: "nomsg"
    }
  }),
  getAcceptedDevices: {
    url: `/devices/accepted_minions_key_list`,
    asyncActions: {
      init: "getAcceptedDevices_INIT",
      success: "getAcceptedDevices_SUCCESS",
      error: "getAcceptedDevices_ERROR"
    }
  },
  getDeniedDevices: {
    url: `/devices/denied_minions_key_list`,
    asyncActions: {
      init: "getDeniedDevices_INIT",
      success: "getDeniedDevices_SUCCESS",
      error: "getDeniedDevices_ERROR"
    }
  },
  getRejectedDevices: {
    url: `/devices/rejected_minions_key_list`,
    asyncActions: {
      init: "getRejectedDevices_INIT",
      success: "getRejectedDevices_SUCCESS",
      error: "getRejectedDevices_ERROR"
    }
  },
  getUnacceptedDevices: {
    url: `/devices/unaccepted_minions_key_list`,
    asyncActions: {
      init: "getUnacceptedDevices_INIT",
      success: "getUnacceptedDevices_SUCCESS",
      error: "getUnacceptedDevices_ERROR"
    }
  },
  acceptDevices: {
    url: `/devices/provision_minion_keys`,
    bodyFunction: AcceptDevicesBodyGenerator,
    asyncActions: {
      init: "acceptDevices_INIT",
      success: "acceptDevices_SUCCESS",
      error: "acceptDevices_ERROR"
    }
  },
  rejectAcceptedDevices: {
    url: `/devices/reject_minion_keys?mac_address=`,
    asyncActions: {
      init: "rejectAcceptedDevices_INIT",
      success: "rejectAcceptedDevices_SUCCESS",
      error: "rejectAcceptedDevices_ERROR"
    }
  },
  deleteAcceptedDevices: {
    url: `/devices/delete_minion_key?mac_address=`,
    asyncActions: {
      init: "deleteAcceptedDevices_INIT",
      success: "deleteAcceptedDevices_SUCCESS",
      error: "deleteAcceptedDevices_ERROR"
    }
  },
  singleDevicePopOnOff: {
    url: `/devices/id/update_pop`,
    bodyFunction: PopBodyGenerator,
    asyncActions: {
      init: "deleteDevices_INIT",
      success: "deleteDevices_SUCCESS",
      error: "deleteDevices_ERROR",
      successMsg: "Instruction to turn on/off reporting sent."
    }
  }
};

export const RS232BodyGenerator = code => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        control_signal: code
      }
    }
  }
});

export function searchUnacceptedDevices(list) {
  return dispatch => {
    dispatch({
      type: "searchUnacceptedDevices",
      payload: list
    });
  };
}
export function searchAcceptedDevices(list) {
  return dispatch => {
    dispatch({
      type: "searchAcceptedDevices",
      payload: list
    });
  };
}
export function searchDeniedDevices(list) {
  return dispatch => {
    dispatch({
      type: "searchDeniedDevices",
      payload: list
    });
  };
}
export function searchRejectedDevices(list) {
  return dispatch => {
    dispatch({
      type: "searchRejectedDevices",
      payload: list
    });
  };
}

export function deleteAcceptedDevice(list) {
  return dispatch => {
    dispatch({
      type: "deleteUnacceptedDevice",
      payload: list
    });
  };
}

export function deleteUnacceptedDevice(list) {
  return dispatch => {
    dispatch({
      type: "deleteUnacceptedDevice",
      payload: list
    });
  };
}

export function deleteDeniedDevice(list) {
  return dispatch => {
    dispatch({
      type: "deleteDeniedDevice",
      payload: list
    });
  };
}
export function deleteRejectedDevice(list) {
  return dispatch => {
    dispatch({
      type: "deleteRejectedDevice",
      payload: list
    });
  };
}
const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined,
  diskUsages: undefined,
  filterStatus: undefined
};

export function deviceDetails(state = initialState, action = {}) {
  const { init, success, error } = Resources.getDeviceDetails.asyncActions;
  const getDiskUsagesSuccess = Resources.getDiskUsages().asyncActions.success;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case getDiskUsagesSuccess: {
      return {
        ...state,
        diskUsages: action.payload.data
      };
    }

    case "updateSingleDeviceDataFromWebSocket": {
      return {
        ...action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export function acceptedDevices(state = initialState, action = {}) {
  const { init, success, error } = Resources.getAcceptedDevices.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: {
          _jsonapi: { data },
          links,
          meta
        }
      } = action.payload;
      return {
        list: data.attributes.minions,
        formattedList: data.attributes.minions,
        links,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }
    case "searchAcceptedDevices": {
      return {
        ...state,
        list: action.payload
      };
    }

    case "deleteUnacceptedDevice": {
      return {
        ...state,
        formattedList: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export function unacceptedDevices(state = initialState, action = {}) {
  const { init, success, error } = Resources.getUnacceptedDevices.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: {
          _jsonapi: { data },
          links,
          meta
        }
      } = action.payload;
      return {
        list: data.attributes.minions,
        formattedList: data.attributes.minions,
        links,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case "searchUnacceptedDevices": {
      return {
        ...state,
        list: action.payload
      };
    }

    case "deleteUnacceptedDevice": {
      return {
        ...state,
        formattedList: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export function deniedDevices(state = initialState, action = {}) {
  const { init, success, error } = Resources.getDeniedDevices.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: {
          _jsonapi: { data },
          links,
          meta
        }
      } = action.payload;
      return {
        list: data.attributes.minions,
        formattedList: data.attributes.minions,
        links,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case "searchDeniedDevices": {
      return {
        ...state,
        list: action.payload
      };
    }

    case "deleteDeniedDevice": {
      return {
        ...state,
        formattedList: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export function rejectedDevices(state = initialState, action = {}) {
  const { init, success, error } = Resources.getRejectedDevices.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: {
          _jsonapi: { data },
          links,
          meta
        }
      } = action.payload;
      return {
        list: data.attributes.minions,
        formattedList: data.attributes.minions,
        links,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case "searchRejectedDevices": {
      return {
        ...state,
        list: action.payload
      };
    }

    case "deleteRejectedDevice": {
      return {
        ...state,
        formattedList: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
