import React from "react";
import { Label, Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { resolutions } from "../../../constant/resolution";

function ResolutionForm(props) {
  return (
    <div className="row mt-3">
      <div className="col-md-2 informationBlock">
        <Label className="mt-2">Resolution</Label>
      </div>
      <div className="col-md-10 informationTag">
        <Field
          component={MultipleSelect}
          name="resolution"
          options={resolutions}
          placeholder="Select device resolution"
          id="exampleSelect"
          className="mr-0 mb-0"
        />

        <Button
          className="btn-info ml-2"
          color="info"
          onClick={props.handleSubmit(props.handleResolutionChange)}
          disabled={props.submitting || props.pristine}
        >
          {props.submitting ? "Saving" : "Save"}
        </Button>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "resolutionChangeForm"
})(ResolutionForm);
