import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export default class RouteLeavingGuard extends Component {
  state = {
    modalVisible: false,
    confirmedNavigation: false,
    isDirty: true
  };

  showModal = () =>
    this.setState({
      modalVisible: true
    });

  closeModal = () => {
    this.setState({
      modalVisible: false
    });
  };

  shouldBlockNavigation = location => {
    const { isDirty } = this.state;
    const { campaign } = this.props;
    if (isDirty) {
      if (
        location.pathname === `/campaign/edit/${campaign.campaignId}` ||
        location.pathname === "/campaign/create"
      ) {
        return true;
      }
    }
    return false;
  };

  handleBlockedNavigation = nextLocation => {
    const { confirmedNavigation } = this.state;
    const blockNavigation = this.shouldBlockNavigation(nextLocation);
    if (!confirmedNavigation && blockNavigation) {
      this.showModal();
      return false;
    }
    return true;
  };

  handleConfirmNavigationClick = () => {
    this.props.history.push("/campaign");
    this.setState({ modalVisible: false });
  };

  render() {
    const { modalVisible } = this.state;

    return (
      <div>
        <Prompt message={this.handleBlockedNavigation} />
        <Modal isOpen={modalVisible}>
          <ModalHeader toggle={this.closeModal}>Confirm Leave</ModalHeader>
          <ModalBody>
            <span>Are you sure you want to leave this page?</span>
          </ModalBody>
          <ModalFooter>
            {" "}
            <Button className="btn-outline-primary" onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              className="primaryButton mt-0"
              onClick={this.handleConfirmNavigationClick}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
