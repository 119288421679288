/* eslint-disable react/no-multi-comp */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Resources } from "../redux/notificationRedux";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,

} from "reactstrap";
import classnames from "classnames";
import NetworkNotifications from "../modules/networkNotifications";
import AllBrandNotifications from "../modules/allBrandNotifications";
class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forcePage: undefined,
      isLoading: true,
      activeTab: "brand"
    };
    this.perPageCount = 10;
    this.getAllNotification();
  }

  getAllNotification = () => {
    const { getTotalNotifications } = Resources;
    getTotalNotifications.url = `notifications?page=${1}&per_page=${this.perPageCount
      }`;
    this.props
      .Get(getTotalNotifications)
      .then(() => this.setState({ isLoading: false }));
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    const { getTotalNotifications } = Resources;
    if (moveToPrevious) {
      getTotalNotifications.url = `notifications?page=${data.page -
        1}&per_page=${this.perPageCount}`;
      this.props.Get(getTotalNotifications).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      this.setState({ forcePage: undefined });
      const { selected } = data;
      getTotalNotifications.url = `notifications?page=${selected +
        1}&per_page=${this.perPageCount}`;
      this.props
        .Get(getTotalNotifications)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  markNotificationAsRead = id => {
    const { notificationMarkAsRead } = Resources;
    notificationMarkAsRead.url = `/notifications/${id}/read`;
    this.props.Post(notificationMarkAsRead);
  };

  handleIcon = type => {
    if (type.search("CPU") > -1) {
      return <i className="mr-2 fas fa-microchip"></i>;
    }
    if (type.search("Memory") > -1) {
      return <i className="mr-2 fas fa-memory" />;
    }
    if (type.search("Disk") > -1) {
      return <i className="mr-2 fas fa-compact-disc"></i>;
    }
    if (type === "Temperature") {
      return <i className="mr-2 fas fa-thermometer-half" />;
    }
  };

  toggleTabs = (tab) => {
    this.setState({ activeTab: tab })
  }

  render() {
    const { isLoading, activeTab } = this.state;
    const { roles } = this.props
    if (isLoading) {
      return (
        <div className="d-block text-center">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    return (
      <div className="mainPage">
        <div className="contentSection">
          <Nav tabs className="mb-3">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "brand"
                })}
                onClick={() => {
                  this.toggleTabs("brand");
                }}
              >
                Brand
              </NavLink>
            </NavItem>
            {roles.includes("SuperAdmin") &&
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "network"
                  })}
                  onClick={() => {
                    this.toggleTabs("network");
                  }}
                >
                  Network
                </NavLink>
              </NavItem>
            }
          </Nav>
          <TabContent
            activeTab={activeTab}>
            <TabPane tabId="brand">
              <AllBrandNotifications
                brandNotifications={this.props.brandNotifications}
                brandNotificationsMeta={this.props.brandNotificationsMeta}
                Get={this.props.Get}
                Post={this.props.Post}
              />
            </TabPane>
            {roles.includes("SuperAdmin") &&
              <TabPane tabId="network">
                <NetworkNotifications
                  notifications={this.props.networkNotifications}
                />
              </TabPane>
            }
          </TabContent>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    networkNotifications: state.totalNotificationList.list || [],
    brandNotifications: state.allBrandNotificationList.list || [],
    brandNotificationsMeta: state.allBrandNotificationList.meta || [],
    meta: state.totalNotificationList.meta || {}
  };
}

export default connect(
  mapStateToProps,
  {}
)(NotificationList);
