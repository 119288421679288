import React, { Component } from "react";
import Moment from "moment";

class TicketToastCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // hello: "hi"
    };
  }

  render() {
    const { data } = this.props.notification.message;
    return (
      <div className="ticket-notification--list">
        <div className="booking_request_info">
          Booking requested for{" "}
          {data.name.length > 30 ? (
            <b className="ticket__name">{`${data.name.slice(0, 30)}...`}</b>
          ) : (
              <b className="ticket__name">{data.name}</b>
            )}
        </div>
        <div className="booking_date_time_info">
          <span className="ticket_date">
            <i className="far fa-calendar" />{" "}
            {Moment.utc(data.starting_date).format("MMM DD, YYYY")}
            {" - "}
            {Moment.utc(data.ending_date).format("MMM DD, YYYY")}
          </span>{" "}
          <span className="ticket_time">
            <i className="far fa-clock" />{" "}
            {Moment.utc(data.start_time).format("hh:mm A")}
            {" - "}
            {Moment.utc(data.end_time).format("hh:mm A")}
          </span>
        </div>
        <div style={{ color: "darkgrey", fontSize: "12px" }}>
          {Moment.utc(data.created_at).fromNow()}
        </div>
      </div>
    );
  }
}
export default TicketToastCard;

// const Mohan = props => <p> hello Mohan dai</p>;
