import normalize from "json-api-normalizer";
import { uniq } from "lodash";

export const generateCreateCalendarData = (
  eventData,
  calendarName,
  id = undefined
) => ({
  _jsonapi: {
    data: {
      type: "calendars",
      attributes: {
        id,
        name: calendarName || "Untitled Calendar",
        events_attributes: eventData
      }
    }
  }
});
export const postArchivedItems = ({ filteredCalendarId }) => ({
  _jsonapi: {
    data: {
      type: "calendar",
      attributes: {
        calendar_ids: [...filteredCalendarId]
      }
    }
  }
});
export const unArchiveItems = ({ filteredCalendarId }) => ({
  _jsonapi: {
    data: {
      type: "calendar",
      attributes: {
        calendar_ids: [...filteredCalendarId]
      }
    }
  }
});

export const removeSingleArchivedCalendar = id => ({
  _jsonapi: {
    data: {
      type: "calendar",
      attributes: {
        calendar_ids: [id]
      }
    }
  }
});

export const Resources = {
  getCalendar: {
    url: "/calendars",
    asyncActions: {
      init: "getcalendar_INIT",
      success: "getcalendar_SUCCESS",
      error: "getcalendar_ERROR"
    },
    syncActions: {
      updateList: "getContent_Update"
    }
  },
  getTotalCalendar: {
    url: "/calendars",
    asyncActions: {
      init: "getTotalCalendar_INIT",
      success: "getTotalCalendar_SUCCESS",
      error: "getTotalCalendar_ERROR"
    },
    syncActions: {
      updateList: "getContent_Update"
    }
  },
  searchTotalCalendar: {
    url: "/campaigns",
    asyncActions: {
      init: "getTotalCalendar_INIT",
      success: "searchTotalCalendar_SUCCESS",
      error: "getTotalContent_ERROR"
    }
  },
  showCalendar: {
    url: `/calendars/id`,
    asyncActions: {
      init: "showcalendar_INIT",
      success: "showcalendar_SUCCESS",
      error: "showcalendar_ERROR"
    }
  },
  createCalendar: {
    url: "/calendars",
    asyncActions: {
      init: "createCalendar_INIT",
      success: "createCalendar_SUCCESS",
      error: "createCalendar_ERROR"
    }
  },
  getArchivedCalendar: {
    url: "/calendars/archive",
    asyncActions: {
      init: "getArchivedCalendar_INIT",
      success: "getArchivedCalendar_SUCCESS",
      error: "getArchivedCalendar_ERROR"
    }
  },
  unArchiveCalendar: {
    url: "/calendars/unarchive",
    asyncActions: {
      init: "unArchiveCalendar_INIT",
      success: "unArchiveCalendar_SUCCESS",
      error: "unArchiveCalendar_ERROR"
    }
  },
  postArchiveCalendar: {
    url: "calendars/bulk_archive",
    asyncActions: {
      init: "postArchiveCalendar_INIT",
      success: "postArchiveCalendar_SUCCESS",
      error: "postArchiveCalendar_ERROR"
    }
  },
  removeBulkCalendar: {
    url: "calendars/bulk_destroy",
    asyncActions: {
      init: "removeBulkCalendar_INIT",
      success: "removeBulkCalendar_SUCCESS",
      error: "removeBulkCalendar_ERROR"
    }
  },
  putCalendar: {
    url: "/calendars",
    asyncActions: {
      init: "putCalendar_INIT",
      success: "putCalendar_SUCCESS",
      error: "putCalendar_ERROR"
    }
  },
  getCalendarZIP: id => ({
    url: `calendars/${id}/calendar_zip`,
    asyncActions: {
      init: "getCalendarZIP_INIT",
      success: "getCalendarZIP_SUCCESS",
      error: "getCalendarZIP_ERROR"
    }
  }),
  deleteCalendar: id => ({
    url: `/calendars/${id}`,
    asyncActions: {
      init: "deleteCalendar_INIT",
      success: "deleteCalendar_SUCCESS",
      error: "deleteCalendar_ERROR"
    }
  }),
  duplicateCalendar: id => ({
    url: `/calendars/${id}/duplicate`,
    asyncActions: {
      init: "duplicateCalendar_INIT",
      success: "duplicateCalendar_SUCCESS",
      error: "duplicateCalendar_ERROR",
      successMsg: "Calendar cloned successfully.",
      errorMsg: "Couldn't clone. Please try again later."
    }
  })
};

export function RemoveItemFromCalendarList(id) {
  const { updateList } = Resources.getCalendar.syncActions;
  return (dispatch, getState) => {
    const { list } = getState().calendars;
    const newlist = list.filter(item => item.id !== id);
    dispatch({ type: updateList, payload: [...newlist] });
  };
}

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined
};

export function calendars(state = initialState, action = {}) {
  const { init, success, error } = Resources.getCalendar.asyncActions;
  const { updateList } = Resources.getCalendar.syncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case updateList: {
      return {
        ...state,
        list: [...action.payload]
      };
    }

    default: {
      return state;
    }
  }
}
export function archiveCalendars(state = initialState, action = {}) {
  const { init, success, error } = Resources.getArchivedCalendar.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function calendarDetails(state = initialState, action = {}) {
  const { init, success, error } = Resources.showCalendar.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { links, meta },
        status,
        statusText
      } = action.payload;
      const normalizedData = normalize(action.payload.data);
      return {
        list: normalizedData,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

function whichList(url, state, data) {
  if (url.search("page=1&") > -1) {
    return data;
  }
  const { list: oldList = [] } = state;
  return uniq([...oldList, ...data]);
}

export function totalCalendar(state = { initialState }, action = {}) {
  const { init, success, error } = Resources.getTotalCalendar.asyncActions;
  // const { updateList } = Resources.getTotalCalendar.syncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText,
        config: { url = "" }
      } = action.payload;
      return {
        list: whichList(url, state, data),
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case "searchTotalCalendar_SUCCESS": {
      const {
        data: { data, links, meta },
        status,
        statusText,
        config: { url = "" }
      } = action.payload;
      return {
        list: whichList(url, state, data),
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}
