import React, { Component } from "react";
import { connect } from "react-redux";
import Calendar from "../modules/calendar";

import {
  generateCreateCalendarData,
  Resources as CalendarResources
} from "../redux/calendarRedux";
import { convertToServerEvent } from "../modules/singleEventCreate";

class CreateCalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      eventData: [],
      calendarTitle: "",
      editCalendar: false,
      disableCalendar: false
    };
  }

  calendarTitleChange = calendarTitle => {
    this.setState({ calendarTitle });
  };

  handleCalendarSave = async () => {
    this.setState({ disableCalendar: true });
    const { state } = this;
    const { Post, calendarFormEventValues } = this.props;
    const { createCalendar } = CalendarResources;
    const eventDataForServer = calendarFormEventValues.map(i =>
      convertToServerEvent(i)
    );
    createCalendar.body = generateCreateCalendarData(
      eventDataForServer,
      state.calendarTitle
    );
    const result = await Post(createCalendar);

    if (result.status !== 422) {
      await this.props.Get(CalendarResources.getCalendar);
    }

    if (result.status === 201 || result.status === 200) {
      this.props.history.push("/calendar");
      this.props.handleCalendarAction("Edit");
      this.props.handleCalendarEdit("Edit", this.props.calendarsList[0].id);
      this.props.toggleCalendarList(true);
      this.setState({ disableCalendar: false });
      // window.location.reload();
    }
    this.setState({ disableCalendar: false });
    // this.props.initialize("createCalendar", {});
  };

  handleCancelChanges = () => {
    this.props.history.push("/calendar");
    this.props.handleCalendarAction("Edit");
    this.props.handleCalendarEdit("Edit", this.props.calendarsList[0].id);
    this.props.toggleCalendarList(true);
  };

  render() {
    const { events } = this.state;
    const { isCalendarListOpen } = this.props;
    return (
      <div>
        <div className="clearfix" />
        <div className="bg-white">
          <div className="calendar-block">
            <Calendar
              {...this.props}
              handleToggleEventForm={this.handleToggleEventForm}
              onSelectEvent={this.onSelectEvent}
              events={events}
              eventData={this.state.eventData}
              calendarTitleChange={this.calendarTitleChange}
              calendarInputRef={this.props.calendarInputRef}
              activateIput={this.props.activateIput}
              disableInput={this.props.disableInput}
              disableCalendar={this.state.disableCalendar}
              handleCalendarSave={this.handleCalendarSave}
              editCalendar={this.state.editCalendar}
              action={this.props.action}
              handleCalendarAction={this.props.handleCalendarAction}
              calendarFormEventValues={this.props.calendarFormEventValues}
              handleCancelChanges={this.handleCancelChanges}
              isCalendarListOpen={isCalendarListOpen}
              toggleCalendarList={this.props.toggleCalendarList}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    calendarFormEventValues:
      (state.form &&
        state.form.createCalendar &&
        state.form.createCalendar.values &&
        state.form.createCalendar.values.calendar) ||
      []
  }),
  null
)(CreateCalender);
