import React, { Component } from "react";
import ApexChart from "./utils/metricChart";

class NucMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartList: [
        {
          name: "CPU Usage",
          chart: "line",
          data: "cpu",
          attributes: "cpu_current",
          color: ["#008FFB", "#00E396", "#CED4DC"],
          marker: 0,
          unit: "%",
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
              formatter: (val, index) => {
                if (this.props.name === "Uptime") {
                  return val.toFixed(2);
                }
                return `${val}%`;
              }
            }
          }
        },
        {
          name: "Memory Usage",
          chart: "line",
          data: "ram",
          total: "memory_total",
          attributes: "memory_used",
          color: ["#FF7F00", "#00E396", "#CED4DC"],
          marker: 0,
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
              formatter: (val, index) => {
                if (this.props.name === "Uptime") {
                  return val.toFixed(2);
                }
                return `${Math.abs(val)}%`;
              }
            }
          },
          unit: "%"
        },
        {
          name: "Disk Usage",
          chart: "line",
          data: "disk",
          total: "disk_total",
          attributes: "disk_used",
          color: ["#FF7F00", "#00E396", "#CED4DC"],
          marker: 0,
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
              formatter: (val, index) => {
                if (this.props.name === "Uptime") {
                  return val.toFixed(2);
                }
                return `${Math.abs(val)}%`;
              }
            }
          },
          unit: "%"
        },
        {
          name: "Network Usage",
          chart: "area",
          data: "network_download",
          attributes: "network_download",
          total: "network_upload",
          color: ["#008FFB", "#FF7F00", "#CED4DC"],
          marker: 0,
          unit: "KBps",
          yaxis: {
            labels: {
              formatter: (val, index) => {
                if (val < 0) {
                  return `${val.toFixed(2) * -1} KBps`;
                }
                return `${val.toFixed(2)} KBps`;
              }
            }
          }
        },
        {
          name: "Temperature",
          chart: "line",
          data: "temperature",
          attributes: "temperature",
          color: ["#008FFB", "#00E396", "#CED4DC"],
          marker: 0,
          unit: "°C",
          yaxis: {
            labels: {
              formatter: (val, index) => `${val.toFixed(0)}°C`
            }
          }
        },
        {
          name: "CPU Load",
          chart: "area",
          load1: "load_avg_1m",
          load2: "load_avg_5m",
          load3: "load_avg_15m",
          data: "cpu load",
          attributes: "cpu load",
          color: ["#7FB56E", "#F0BD3A", "#71D6E6"],
          marker: 0,
          yaxis: {
            labels: {
              formatter: (val, index) => `${val.toFixed(2)}`
            }
          }
        },
        {
          name: "Swap Usage",
          chart: "line",
          data: "swap",
          total: "swap_total",
          attributes: "swap_used",
          color: ["#FF7F00", "#00E396", "#CED4DC"],
          marker: 0,
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
              formatter: (val, index) => `${val}%`
            }
          },
          unit: "%"
        },
        {
          name: "Uptime",
          chart: "scatter",
          data: "uptime",
          attributes: "cpu",
          color: ["#008FFB", "#00E396", "#CED4DC"],
          marker: 4,
          yaxis: {
            min: 0,
            max: 2,
            tickAmount: 4,
            labels: {
              formatter: (val, index) => val.toFixed(2)
            }
          }
        }
      ]
      // #7FB56E,#F0BD3A,#71D6E6
    };
  }

  optionGenerator = (chart, data) => {
    if (chart.data === "network_download") {
      return [
        { name: "Download", data: data[chart.data] },
        { name: "Upload", data: data[chart.total] }
      ];
    }
    if (chart.name === "CPU Load") {
      return [
        { name: "Load 1m", data: data[chart.load1] },
        { name: "Load 5m", data: data[chart.load2] },
        { name: "Load 15m", data: data[chart.load3] }
      ];
    }
    // if (chart.data === "disk_read") {
    //   return [
    //     { name: "Disk Read", data: data[chart.data] },
    //     { name: "Disk Write", data: data[chart.total] }
    //   ];
    // }
    // if (chart.total) {
    //   return [
    //     { name: "Used", data: data[chart.data] },
    //     { name: "Total", data: data[chart.total] }
    //   ];
    // }

    return [{ name: chart.name, data: data[chart.data] }];
  };

  latestData = (chart, data) => {
    switch (chart.data) {
      case "cpu":
        return data ? `${data.cpu_current}%` : "N/A";
      case "ram":
        return data
          ? `${data.memory_used_GB}/${data.memory_total_GB} GB`
          : "N/A";
      case "disk":
        return data ? `${data.disk_used_GB}/${data.disk_total_GB} GB` : "N/A";
      case "network_download":
        return data
          ? `Up: ${data.network_upload} KBps | Down: ${data.network_download} KBps`
          : "N/A";
      case "swap":
        return data ? `${data.swap_used_MB}/${data.swap_total_MB} MB` : "N/A";
      case "temperature":
        return data ? `${data.temperature} °C` : "N/A";
      case "uptime":
        return data ? `${data.uptime}` : "N/A";
      case "cpu load":
        return data
          ? `1m: ${data.load_avg_1m} | 5m: ${data.load_avg_5m} | 15m: ${data.load_avg_15m}`
          : "N/A";
      // case "disk_read":
      //   return data ? `${data.disk_read}/${data.disk_read}` : "N/A";
      default:
        return "N/A";
        break;
    }
  };

  render() {
    const {
      latestMetrics: { attributes }
    } = this.props || [];
    return (
      <div className="mainCalendar__wrapper m-3" style={{ padding: "20px" }}>
        {/* <h1>Details</h1>
        <hr style={{ margin: "1rem 0" }} /> */}
        <div className="row informationBlock metricesBlock">
          <div className="col-md-2">
            <i>Host Name</i>
            <span className="pb-1">
              {attributes ? attributes.hostname : "N/A"}
            </span>
          </div>
          {/* <div className="col-md-2">
            <i>MAC Address</i>
            <span className="pb-1">
              {attributes ? attributes.mac_address : "N/A"}
            </span>
          </div> */}
          <div className="col-md-2">
            <i>Kernel Version</i>
            <span className="pb-1">
              {attributes ? attributes.kernel : "N/A"}
            </span>
          </div>
          <div className="col-md-2">
            <i>CPU Count</i>
            <span className="pb-1">
              {attributes ? attributes.cpu_count : "N/A"}
            </span>
          </div>
          <div className="col-md-2">
            <i>Uptime</i>
            <span className="pb-1">
              {attributes ? attributes.uptime : "N/A"}
            </span>
          </div>
          <div className="col-md-2">
            <i>Orientation</i>
            <span className="pb-1">
              {attributes ? attributes.orientation : "N/A"}
            </span>
          </div>
          <div className="col-md-2">
            <i>Resolution</i>
            <span className="pb-1">
              {attributes ? attributes.resolution : "N/A"}
            </span>
          </div>
        </div>

        <div className="clearfix" />
        {/* <h1>Metrics</h1> */}
        <div className="row">
          {this.state.chartList.map(chart => (
            <div
              className="col-6 col-md-6"
              style={{
                "padding-right": "5px",
                "padding-left": "5px",
                marginBottom: "10px"
              }}
            >
              <ApexChart
                handleLoadDeviceNucMetricsWithRange={
                  this.props.handleLoadDeviceNucMetricsWithRange
                }
                toggleZoom={this.props.toggleZoom}
                isLoadingMetrics={this.props.isLoadingMetrics}
                name={chart.name}
                data={this.optionGenerator(chart, this.props.data)}
                timestamp={this.props.data.timestamp}
                chart={chart.chart}
                latestdata={this.latestData(chart, attributes)}
                color={chart.color}
                marker={chart.marker}
                yaxis={chart.yaxis}
                unit={chart.unit}
                isZoom={this.props.isZoom}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default NucMetrics;
