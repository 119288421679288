import React from "react";
import Dropzone from "react-dropzone";
import classNames from "classnames";

const uuidv4 = require("uuid/v4");

// for profile picture
class ImageUpload extends React.Component {
  state = { warningMsg: "" };

  onDrop = (a, rejected) => {
    this.props.input.onChange([...this.createDataWithUUID(a)]);
    this.props.enableUpdateButton();
    this.props.handleProfilePicFieldTouch();
  };

  createDataWithUUID = a =>
    a.map(item => ({
      file: item,
      id: uuidv4(),
      url: URL.createObjectURL(item),
      name: item.name,
      type: "Image"
    }));

  render() {
    const thumbs = (
      <div>
        {this.props.currentImage && this.props.currentImage.length > 0 && (
          <img src={this.props.currentImage[0].url} alt="profile" />
        )}
        <div className="profile-picture-overlay">
          <i className="fas fa-camera" />
          Change profile picture
        </div>
      </div>
    );

    return (
      <div className="">
        {/* <p>{this.state.warningMsg}</p> */}

        <Dropzone
          onDrop={this.onDrop}
          accept={[".jpeg", ".png", ".gif", ".jpg"]}
          onDropRejected={this.onDropRejected}
          multiple={false}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={classNames(
                `dropzone d-flex justify-content-center userProfile__profileImage text-a`,
                {
                  "dropzone--isActive": isDragActive
                }
              )}
            >
              <input {...getInputProps()} />
              {thumbs}
              {/* <p>
                Drag and Drop files here
                <span className="d-block">
                  or <a href="#">browse</a> file to upload
                </span>
              </p> */}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default ImageUpload;
