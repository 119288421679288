/* eslint-disable no-shadow */
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Resources } from "../redux";
import { required } from "../../../helper/validation";
import { Input, SelectOption } from "../../../core";
import { updateCampaign, resetCampaign } from "../redux/campaignStudioReducer";
import { getAttributes } from "../utls/mapUtils";

class CreateCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isEditing: false
    };
    this.mediaMatch = window.matchMedia(
      "(max-width: 1650px) and (min-width: 1350px)"
    );
    this.loadDataFromServer();
  }

  /** Destroy Previous Data */
  destroyPreviousData = () => {
    this.props.resetCampaign();
  };

  /** Bootstrap this pages. */
  loadDataFromServer = async () => {
    this.destroyPreviousData();
    this.loadResolutionList().then(() => {
      if (this.props.match.path === "/campaign/edit/:id") {
        this.loadCampaignToBeEdited(this.props.match.params.id);
      } else {
        this.setState({
          isLoading: false
        });
      }
    });
  };

  /** Load the resolution list from server */
  loadResolutionList = async () => {
    await this.props.Get(Resources.getResolutions);
  };

  /** Load campaign to be edited */
  loadCampaignToBeEdited = async id => {
    const { getEditCampaign } = Resources;
    const { campaign, resolutionList, updateCampaign } = this.props;
    getEditCampaign.url = `/campaigns/${id}/custom_get`;
    const result = await this.props.Get(getEditCampaign);
    if (result.status === 200) {
      const attributes = getAttributes(result);
      campaign.loadCampaignData(
        { ...attributes },
        resolutionList,
        this.mediaMatch.matches
      );
      updateCampaign(campaign);
      this.props.history.push("/campaign/studio", {
        editingCampaign: this.state.edit,
        editingCampaignId: this.state.id
      });
    }
  };

  /** Initiate API Calls */
  makeAPICall = async () => {
    const promises = [].push(this.loadResolutionList());
    await Promise.all([...promises]).then(() => {
      this.setState({ isLoading: false });
    });
  };

  createCampaign = () => {
    const { campaign } = this.props;
    campaign.handleScreenLayout(
      this.props.history.location.state.layout,
      this.props.resolutionList,
      this.mediaMatch
    );
    campaign.resolutionList = this.props.resolutionList;
    campaign.generateScreens();
    this.props.updateCampaign(campaign);
    this.props.history.push("/campaign/studio", {
      editingCampaign: this.state.edit,
      editingCampaignId: this.state.id
    });
  };

  render() {
    const { isLoading } = this.state;
    /** Is Loading  */
    if (isLoading) {
      return (
        <div
          className="d-block justify-content-center text-center"
          style={{ marginTop: "100px" }}
        >
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }

    return (
      <div className="container">
        {this.createCampaign()}
        {/* <div className="createCampaign">
          <h4 className="text-center pb-3">Create New Campaign</h4>
          <div className="createCampaign-wrapper">
            <Field
              component={Input}
              name="campaign_name"
              label="Campaign Name"
              validate={required}
              autofill="off"
            />
            <React.Fragment>
              <Field
                component={SelectOption}
                name="numberofScreen"
                validate={required}
                labelName="Sub-Window"
                options={[
                  { value: "1", option: "1" },
                  { value: "2", option: "2" },
                  { value: "3", option: "3" },
                  { value: "4", option: "4" },
                  { value: "5", option: "5" },
                  { value: "6", option: "6" }
                ]}
                optionParams={{ value: "value", name: "option" }}
              />

              <Field
                component={SelectOption}
                name="resolution"
                labelName="Resolution"
                validate={required}
                options={this.props.resolutionList}
                optionParams={{
                  name: "attributes",
                  value: "id",
                  subKey: "display"
                }}
              />
            </React.Fragment>
            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-outline-primary"
                onClick={() => this.props.history.push("/dashboard/campaign")}
                type="submit"
              >
                Cancel
              </button>
              <button
                onClick={this.props.handleSubmit(value =>
                  this.handleSubmit(value)
                )}
                className="btn btn-primary"
                type="submit"
              >
                Create
              </button>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default connect(
  state => {
    const { list } = state.resolutions;
    return {
      resolutionList: list || [],
      campaign: state.newCampaign.campaign
    };
  },
  { updateCampaign, resetCampaign }
)(
  reduxForm({
    form: "createCampaign"
  })(CreateCampaign)
);
