/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";

/** Import Redux Library */
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import ReactPaginate from "react-paginate";
// import { Resources, RemoveItemFromContentList } from "../redux";

import { Resources } from "../redux/archiveRedux";
import ArchiveTable from "../modules/contentArchiveTable";
import { Tooltip, Dialog } from "../../../core";
import {
  Resources as ContentResources,
  postArchivedItems,
  removeSingleArchiveItems,
  unArchiveContents
} from "../../contents/redux/contentRedux";

/** Import Local Components */

class ArchiveContent extends Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    this.initApi();
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      content_type: { active: false, sortBy: "desc" },
      dimension: { active: false, sortBy: "desc" },
      duration: { active: false, sortBy: "desc" },
      size: { active: false, sortBy: "desc" },
      kind: { active: false, sortBy: "desc" },
      status: { active: false, sortBy: "desc" },
      created_at: { active: false, sortBy: "desc" },
      updated_at: { active: false, sortBy: "desc" }
    };
    this.archiveTableRefs = React.createRef();
    this.state = {
      sortOrder: this.initialSortOrder,
      forcePage: undefined,
      isLoading: true,
      sortValue: { isSortable: false },
      deleteModal: {
        id: undefined,
        modal: false,
        body: "Are you Sure You Want To Delete? .",
        title: "Confirm Delete"
      }
    };
  }

  initApi = () => {
    this.setState({ isLoading: true });
    const api = [];
    Resources.getArchivedContent.url = `/contents/archive?page=${1}&per_page=${
      this.perPageCount
    }`;
    api.push(this.props.Get(Resources.getArchivedContent));
    Promise.all(api).then(() => {
      this.setState({
        isLoading: false
      });
    });
  };

  handleSort = name => {
    this.setState({ forcePage: 0, isLoading: true });
    this.props.change("SelectedContentsOnArchive", "selectedContents", []);
    this.archiveTableRefs.current.selectionContext.selected = [];
    this.archiveTableRefs.current.setState({});
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getArchivedContent } = Resources;
        getArchivedContent.url = `/contents/archive/?sort=${name}&sort_by=${
          this.state.sortOrder[name].sortBy
        }&page=${1}&per_page=${this.perPageCount}`;
        this.props.Get(Resources.getArchivedContent).then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handleUnArchiveItem = id => {
    const { unArchiveContent } = ContentResources;
    unArchiveContent.body = unArchiveContents({ filteredContentId: [id] });
    this.props
      .Put(unArchiveContent)
      .then(() => {
        this.props.Get(Resources.getArchivedContent).then(result => {
          if (result.status === 200) {
            return (
              this.props.archivedContentList.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        });
      })
      .then(() =>
        this.props.change("SelectedContentsOnArchive", "selectedContents", [])
      );
  };

  handleUnArchiveContents = () => {
    const { unArchiveContent } = ContentResources;
    const filteredContentId = this.props.selectedContents.map(item => item.id);
    unArchiveContent.body = unArchiveContents({ filteredContentId });
    this.props
      .Put(unArchiveContent)
      .then(() => {
        this.props.Get(Resources.getArchivedContent).then(result => {
          if (result.status === 200) {
            return (
              this.props.archivedContentList.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        });
      })
      .then(() =>
        this.props.change("SelectedContentsOnArchive", "selectedContents", [])
      )
      .then(() => {
        this.archiveTableRefs.current.selectionContext.selected = [];
        this.archiveTableRefs.current.setState({});
      });
  };

  handleDeleteConfirmation = (id, name) => {
    const { deleteModal } = this.state;
    deleteModal.id = id;
    deleteModal.modal = true;
    deleteModal.body = (
      <span>
        <small>Are you sure you want to delete </small>
        <strong>{name}</strong>
      </span>
    );

    this.setState({ deleteModal });
  };

  handleDelete = () => {
    const { deleteModal } = this.state;
    const filteredContentId = this.props.selectedContents.map(item => item.id);
    const { removeBulkContents } = ContentResources;
    if (deleteModal.id) {
      removeBulkContents.body = removeSingleArchiveItems(deleteModal.id);
    } else {
      removeBulkContents.body = postArchivedItems({ filteredContentId });
    }
    this.props
      .Put(removeBulkContents)
      .then(() => {
        this.props.Get(Resources.getArchivedContent).then(result => {
          if (result.status === 200) {
            return (
              this.props.archivedContentList.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        });
        deleteModal.modal = false;
        deleteModal.id = undefined;
        this.setState({
          deleteModal
        });
      })
      .then(() =>
        this.props.change("SelectedContentsOnArchive", "selectedContents", [])
      )
      .then(() => {
        this.archiveTableRefs.current.selectionContext.selected = [];
        this.archiveTableRefs.current.setState({});
      });
  };

  toggleModel = flag => {
    const { deleteModal } = this.state;
    deleteModal.modal = flag;
    this.setState({ deleteModal });
    this.forceUpdate();
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    const { getArchivedContent } = Resources;
    if (moveToPrevious) {
      getArchivedContent.url = `/contents/archive?page=${data.page -
        1}&per_page=${this.perPageCount}`;
      this.props.Get(getArchivedContent).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      this.setState({ forcePage: undefined });
      this.setState({ isLoading: true });
      const { selected } = data;
      if (this.state.sortValue.isSortable) {
        getArchivedContent.url = `/contents/archive?sort=${
          this.state.sortValue.name
        }&sort_by=${this.state.sortValue.sortBy}&page=${selected +
          1}&per_page=${this.perPageCount}`;
      } else {
        getArchivedContent.url = `/contents/archive?page=${selected +
          1}&per_page=${this.perPageCount}`;
      }

      this.props
        .Get(getArchivedContent)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  render() {
    const { deleteModal } = this.state;
    return (
      <div>
        <Dialog
          externalControl
          showHandler={false}
          modal={deleteModal.modal}
          body={deleteModal.body}
          title={deleteModal.title}
          toggleModel={this.toggleModel}
          primaryMethod={this.handleDelete}
        />
        <ArchiveTable
          {...this.props}
          archivedContentList={this.props.archivedContentList}
          forwardRef={this.archiveTableRefs}
          handleDelete={this.handleDeleteConfirmation}
          isLoading={this.state.isLoading}
          handleSort={this.handleSort}
          sortOrder={this.state.sortOrder}
          handleUnArchiveItem={this.handleUnArchiveItem}
          // following props used for bulk archive and delete
          handleUnArchiveContents={this.handleUnArchiveContents}
          handleDeleteConfirmation={this.handleDeleteConfirmation}
          selectedContentsLen={this.props.selectedContents.length}
        />
        {!this.state.isLoading &&
          this.props.archivedContentList &&
          this.props.archivedContentList.length > 0 && (
            <div className="contentSectionPagination clearfix">
              <ReactPaginate
                pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                marginPagesDisplayed={2}
                activeClassName="active"
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                previousLabel="<"
                forcePage={this.state.forcePage}
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                disabledClassName="paginationDisable"
              />
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list, status, loading, hasError, meta } = state.archivedContent;
  const selector = formValueSelector("SelectedContentsOnArchive");

  return {
    archivedContentList: list !== undefined ? list : [],
    selectedContents: selector(state, "selectedContents") || [],
    loading,
    hasError,
    meta,
    status
  };
}

export default connect(
  mapStateToProps,
  { change }
)(ArchiveContent);
