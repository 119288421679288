/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Form, Alert } from "reactstrap";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Input } from "../../core";
import { required, isPasswordStrong } from "../../helper/validation";
import { Resources, generateResetPassword } from "./redux";
import { Get, Patch } from "../../constant/thunk";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkExpired: false
    };
  }

  handlePasswordCreate = async values => {
    const formValues = values;
    if (formValues.confirm_password !== formValues.new_password) {
      throw new SubmissionError({
        confirm_password: "Password didn't match."
      });
    }
    const data = {
      new_password: formValues.new_password,
      token: this.props.match.params.token,
      action_type: this.props.match.path.includes("register")
        ? "register"
        : "forgot_password"
    };
    const { getResetPassword } = Resources;
    getResetPassword.body = generateResetPassword({ data });
    this.props
      .Patch(getResetPassword)
      .then(response => {
        if (response.data.success) {
          this.props.history.push("/");
        }
      })
      .catch(() => {
        this.setState({ linkExpired: true });
        setTimeout(() => {
          this.props.history.push("/");
        }, 1500);
      });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="login">
        <div className="row">
          <div className=" col-lg-6 login__col ">
            <div className="login__left">
              <video className="login__left__video" autoPlay muted loop>
                <source
                  src={require("../../assets/videos/timelapse.mp4")}
                  type="video/mp4"
                />
              </video>
              <div className="login__left__text">
                <div className=" d-flex h-100 justify-content-center">
                  <div className="align-self-center login__info ">
                    <span>Get started with </span>
                    <h1 className="login__headerFont">
                      Digital Signage Revolution
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 white-background">
            <div className="login__right d-flex h-100 justify-content-center">
              <div className="align-self-center">
                <Form className="login__form">
                  <img
                    src={require("../../assets/images/splashnode-logo.svg")}
                    alt="IMG"
                  />
                  {!this.state.linkExpired ? (
                    <div>
                      <h3 className="login__form__title pb-5">
                        Create New Password
                      </h3>

                      <div className="login__form__input">
                        <Field
                          component={Input}
                          name="new_password"
                          validate={[required, isPasswordStrong]}
                          type="password"
                          label="New Password"
                          inputClass="inputfield"
                        />
                      </div>
                      <div className="login__form__input">
                        <Field
                          component={Input}
                          name="confirm_password"
                          validate={[required]}
                          type="password"
                          label="Confirm Password"
                          inputClass="inputfield"
                        />
                      </div>

                      <div className="">
                        <button
                          onClick={handleSubmit(values =>
                            this.handlePasswordCreate(values)
                          )}
                          className="primaryButton btn-block mt-0"
                          type="submit"
                        >
                          {this.props.match.path.includes("register")
                            ? "Create Password"
                            : "Change Password"}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Alert color="danger">The link has expired.</Alert>
                    </div>
                  )}
                </Form>
              </div>
              <small className="login__policies">
                Terms of Use | Privacy policy
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { Get, Patch }
)(
  reduxForm({
    form: "ResetPasswordForm"
  })(ResetPassword)
);
