import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import DownloadCalendarForUSB from "../pages/downloadCalendar";
import { getLocalTime } from "../../../helper/helperFuncs";

export function columns(
  roles,
  permission,
  handleDelete,
  handleDownload,
  handleSort,
  sortOrder,
  handleDuplicate,
  handleArchiveItem,
  handleCalendarEdit,
  dropdownOpen,
  toggle,
  calendarSelected,
  handleArchiveItems,
  handleDeleteCalendars,
  filterDropdownOpen,
  filterToggle,
  disableArchiveIcon,
  handleInventoryView
) {
  return [
    {
      dataField: "attributes.name",
      text: "Calendar list",
      className: "calendarTableTitle",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleCalendarEdit("Edit", row.id);
        }
      },
      formatter: (name, row) => (
        <div>
          <span className="content__titlename" style={{ display: "block" }}>
            {name.length >= 19 ? (
              <Tooltip
                item={
                  <span className="content__name">
                    {" "}
                    {`${name.slice(0, 19)}...`}{" "}
                  </span>
                }
                id={`calendarViewName-${row.id}`}
                tooltipContent={name}
              />
            ) : (
              <span className="content__name">{name}</span>
            )}
          </span>{" "}
          <span className="content__dateCreated">
            {getLocalTime(row.attributes.created_at)}
          </span>
        </div>
      ),
      style: { cursor: "pointer" }
    },
    {
      dataField: "actions",
      text: "",
      style: { position: "relative" },
      headerFormatter: () => (
        <div className="clearfix calendarDropDown">
          <Dropdown
            isOpen={filterDropdownOpen}
            toggle={filterToggle}
            className="float-right"
          >
            <DropdownToggle className=" btn-outline-primary mr-0 " caret>
              <i
                className="bx bx-filter"
                style={{ fontSize: "24px", paddingLeft: "2px" }}
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header>Sort By</DropdownItem>
              <DropdownItem className="" onClick={() => handleSort("name")}>
                <span>Name</span>
              </DropdownItem>
              <DropdownItem className="" onClick={() => handleSort("status")}>
                <span>Status</span>
              </DropdownItem>
              <DropdownItem
                className=""
                onClick={() => handleSort("created_at")}
              >
                <span> Date Created</span>
              </DropdownItem>
              <DropdownItem
                className=""
                onClick={() => handleSort("updated_at")}
              >
                <span>Date Updated</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {calendarSelected.length > 0 && (
            <div className="d-flex calendarSection__leftButtons">
              <RouteAllowed roles={roles} permission={permission.calendar.edit}>
                <div
                  disabled={calendarSelected <= 0 || disableArchiveIcon}
                  className={`${disableArchiveIcon &&
                    "disabled"} fa fa-archive pt-2 mr-3`}
                  onClick={() =>
                    handleArchiveItems(calendarSelected, disableArchiveIcon)
                  }
                  style={{ fontSize: "18px" }}
                />
              </RouteAllowed>
              <RouteAllowed
                roles={roles}
                permission={permission.calendar.delete}
              >
                <div
                  disabled={calendarSelected <= 0}
                  className="fa fa-trash pt-2"
                  onClick={() => handleDeleteCalendars(calendarSelected)}
                  style={{ fontSize: "18px" }}
                />
              </RouteAllowed>
            </div>
          )}
        </div>
      ),
      formatter: (name, row) => (
        <div className="d-flex flex-row">
          <div className="d-flex flex-row flex-grow-1 contentSectionTable__actionButtonWrapperCalendar__actionButtons">
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.calendar.show}
            >
              <span className="contentSectionTable__actionButtonWrapperCalendar__calendarActionButtons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() =>
                        handleInventoryView("ViewInventory", row.id)
                      }
                      className="fa fa-eye"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  id={`inventory-${row.id}`}
                  tooltipContent="View Inventory"
                />
              </span>
            </RouteAllowed>
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.calendar.edit}
            >
              <span className="contentSectionTable__actionButtonWrapperCalendar__calendarActionButtons">
                <Tooltip
                  item={
                    <i
                      className="fa fa-edit"
                      role="presentation"
                      onClick={() => handleCalendarEdit("View", row.id)}
                    />
                  }
                  id={`edit-${row.id}`}
                  tooltipContent="Edit"
                />
              </span>
            </RouteAllowed>{" "}
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.calendar.dublicate}
            >
              <span className="contentSectionTable__actionButtonWrapperCalendar__calendarActionButtons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() => handleDuplicate(row.id)}
                      className="fa fa-clone action__clone"
                    />
                  }
                  id={`clone-${row.id}`}
                  tooltipContent="Duplicate"
                />
              </span>
            </RouteAllowed>{" "}
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.calendar.dublicate}
            >
              <span className="contentSectionTable__actionButtonWrapperCalendar__calendarActionButtons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() => handleDownload(true, row)}
                      className="fas fa-cloud-download-alt"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  id={`download-${row.id}`}
                  tooltipContent="Download Calendar"
                />
              </span>
            </RouteAllowed>{" "}
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.calendar.delete}
            >
              <span className="contentSectionTable__actionButtonWrapperCalendar__calendarActionButtons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() => handleDelete(row.id, row.attributes.name)}
                      className="fa fa-trash action__trash"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  id={`delete-${row.id}`}
                  tooltipContent="Delete"
                />
              </span>
            </RouteAllowed>{" "}
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.archive.calendar}
            >
              <span className="contentSectionTable__actionButtonWrapperCalendar__calendarActionButtons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() => handleArchiveItem(row.id, name)}
                      className="fas fa-archive action-archive"
                    />
                  }
                  id={`archive-${row.id}`}
                  tooltipContent="Archive"
                />
              </span>
            </RouteAllowed>
          </div>
          <div
            className={
              row.attributes.status === "live"
                ? "content__status__online"
                : "content__status__offline"
            }
            style={{ position: "absolute", right: "20px", top: "42%" }}
          />
        </div>
      )
    }
  ];
}
