import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { columns } from "../utils/domainUserUtils";
import DomainUserHeader from "./domainUserHeader";

export default class DomainUserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        name: false,
        email: false,
        roles: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.users}
          columns={columns(
            this.props.editUser,
            this.props.removeExistingUserFromDomain,
            this.props.currentUser,
            this.state.hiddenColumns
          )}
          columnToggle
        >
          {props => (
            <div>
              <DomainUserHeader
                currentUserRolesDUH={this.props.currentUserRolesDUH}
                editDUH={this.props.editDUH}
                handleAddUserDUH={this.props.handleAddUserDUH}
                handleSearchValueDUH={this.props.handleSearchValueDUH}
                searchValDUH={this.props.searchValDUH}
                // below props are used by visible col toggler component
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                {...this.props}
              />
              <BootstrapTable
                hover
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                keyField="id"
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
