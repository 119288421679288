/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Form } from "reactstrap";
import { reduxForm, Field } from "redux-form";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Input } from "../../core";
import { required, email } from "../../helper/validation";
import { Resources, generateForgotPassword } from "./redux";
import { Get, Patch } from "../../constant/thunk";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSuccess: null
    };
  }

  handleForgotPassword = async values => {
    const { getForgotPassword } = Resources;
    getForgotPassword.body = generateForgotPassword(values);
    this.props.Patch(getForgotPassword).then(response => {
      if (response.data.success) {
        this.setState({ emailSuccess: true });
      }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="login">
        <div className="row">
          <div className=" col-lg-6 login__col">
            <div className="login__left">
              <video className="login__left__video" autoPlay muted loop>
                <source
                  src={require("../../assets/videos/timelapse.mp4")}
                  type="video/mp4"
                />
              </video>
              <div className="login__left__text">
                <div className=" d-flex h-100 justify-content-center">
                  <div className="align-self-center login__info ">
                    <span>Get started with </span>
                    <h1 className="login__headerFont">
                      Digital Signage Revolution
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 white-background">
            <div className="login__right d-flex h-100 justify-content-center">
              <div className="align-self-center">
                <Form className="login__form">
                  <img
                    src={require("../../assets/images/splashnode-logo.svg")}
                    alt="IMG"
                  />
                  {!this.state.emailSuccess ? (
                    <div>
                      <h3 className="login__form__title text-center">
                        Forgot Password?
                      </h3>
                      <p className="login__form__subtitle pb-4 pt-2 text-center">
                        Enter your email and we will send you a password reset
                        link.
                      </p>
                      <div
                        className="login__form__input"
                        data-validate="Valid email is required: ex@abc.xyz"
                      >
                        <Field
                          component={Input}
                          name="email"
                          type="email"
                          validate={[required, email]}
                          label="Email"
                          inputClass="inputfield"
                          placeholder="someone@example.com"
                        />
                      </div>

                      <div className="">
                        <button
                          onClick={handleSubmit(values =>
                            this.handleForgotPassword(values)
                          )}
                          className="primaryButton btn-block mt-0"
                          type="submit"
                        >
                          Send Request
                        </button>
                      </div>
                      <div className="text-center mt-2">
                        Already have login and password?
                        <Link to="/" className="ml-1">
                          Sign in
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Redirect to="/" />
                    </div>
                  )}
                </Form>
              </div>
              <small className="login__policies">
                Terms of Use | Privacy policy
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { Get, Patch }
)(
  reduxForm({
    form: "ForgetPasswordForm"
  })(ForgotPassword)
);
