import moment from "moment";
import dateTimeFormatter from "./dateTimeFormatter";

// get a content
// check for followings

// NOTE: the paramaters of the below functions should be pre formatted in their respective standard (string) form

// if content has reached its restriction hour
const isBetweenActivationPeriod = (activationDate, deactivationDate) => {
  let state = false;
  //  let formattedNowDate = dateTimeFormatter.standardDate(moment().format().toString())
  let formattedNowDate = moment();
  let nowDate = moment(formattedNowDate);
  if (
    nowDate.isSameOrAfter(moment(activationDate)) &&
    nowDate.isSameOrBefore(moment(deactivationDate))
  ) {
    state = true;
  }
  return state;
};

const hasReachedRestrictionHour = startHour => {
  let state = false;
  let nowHour = moment();
  startHour = dateTimeFormatter.momentHour(startHour);
  if (nowHour.isSameOrAfter(startHour)) {
    state = true;
  }
  return state;
};

const hasExceededRestrictionHour = endHour => {
  let state = false;
  let nowHour = moment();
  endHour = dateTimeFormatter.momentHour(endHour);
  if (nowHour.isSameOrAfter(endHour)) {
    state = true;
  }
  return state;
};

const hasReachedRestrictionMinute = startMinute => {
  let state = false;
  let dt = dateTimeFormatter.stringDateTime(moment());
  let nowMinute = dateTimeFormatter.standardMinute(dt); // only minute information; in moment format
  if (Number(nowMinute) >= Number(startMinute)) {
    state = true;
  }
  return state;
};

const hasExceededRestrictionMinute = endMinute => {
  let state = false;
  let dt = dateTimeFormatter.stringDateTime(moment());
  let nowMinute = dateTimeFormatter.standardMinute(dt); // only minute information; in moment format
  if (Number(nowMinute) >= Number(endMinute)) {
    state = true;
  }
  return state;
};

const hasExceededStatedCount = (thisCount, maxCount) => {
  let state = false;
  if (Number(thisCount) >= Number(maxCount)) {
    state = true;
  }
  return state;
};

const hasExceededStatedPlayTime = (thisPlayTime, maxPlayTime) => {
  let state = false;
  if (Number(thisPlayTime) >= Number(maxPlayTime)) {
    state = true;
  }
  return state;
};

// if content has passed the restriction hour
// if content has reached its restriction minute
// if content has passed the restriction minute

// if content play count is below the stated count
// if content play time is below the stated time

export default {
  isBetweenActivationPeriod,
  hasReachedRestrictionHour,
  hasExceededRestrictionHour,
  hasReachedRestrictionMinute,
  hasExceededRestrictionMinute,
  hasExceededStatedCount,
  hasExceededStatedPlayTime
};
