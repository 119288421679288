import React from "react";
import RouteAllowed from "../../../helper/isRouteAllowed";
import { SearchTagRefresh } from "../../../core";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

const UserHeader = props => (
  <div className="d-flex contentHeader">
    <div className="mr-auto">
      <RouteAllowed roles={props.rolesUH} permission={props.permissionUH}>
        {/* <Link to="/dashboard/addUser" className="mr-auto"> */}
        <button
          className="mr-auto primaryButton primaryButton--contentHeader"
          onClick={() => props.handleUserCreate(true, "create")}
          // onClick={handleSubmit(values => this.handleLogin(values))}
          type="submit"
        >
          <i className="fa fa-plus mr-2" />
          Add user
        </button>
        {/* </Link> */}
      </RouteAllowed>{" "}
    </div>
    <div className="d-flex flex-row-reverse">
      <SearchTagRefresh handleSearch={props.handleSearchValue} {...props} />
    </div>
    <VisibleColumnToggler
      hiddenColumns={props.hiddenColumns}
      toggleHiddenColumns={props.toggleHiddenColumns}
      columnToggleProps={props.columnToggleProps}
    />
  </div>
);

export default UserHeader;
