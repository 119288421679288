import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { connect } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import { Resource as statResource } from "../redux/homeRedux";
import MapChart from "../modules/MapChart";
import WorldMap from "../modules/WorldMap";
import DonutChart from "../modules/DonutChart";
import { Resources as networkResources } from "../../network/redux";
import { formatNetworkGroups } from "../../network/utls/networkGroupUtls";
import { Tooltip } from "../../../core";
import { Patch } from "../../../constant";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeLoading: true
    };
    this.props.Get(statResource.getReport).then(() => {
      this.changeHomeLoading(false);
    });
    // this.handleLoadNetworkGroups();
  }

  handleLoadNetworkGroups = async () => {
    networkResources.getNetwork.url = `/device_groups?page=${1}&per_page=${5}`;
    await this.props.Get(networkResources.getNetwork);
  };

  handleFavouriteGroup = (id, flag) => {
    const { setFavouriteGroup } = networkResources;
    const request = setFavouriteGroup(id);
    request.body = request.bodyFunction(flag);
    this.props.Patch(request).then(res => {
      if (res.status === 200) {
        this.props.Get(statResource.getReport);
      }
    });
  };

  unitConvert = item => {
    switch (item) {
      case "B":
        return "B";
      case "KiB":
        return "KB";
      case "MiB":
        return "MB";
      case "GiB":
        return "GB";
      case "TiB":
        return "TB";
      case "PiB":
        return "PB";
      case "EiB":
        return "EB";
      default:
    }
  };

  changeHomeLoading = flag => {
    this.setState({ homeLoading: flag });
  };

  render() {
    const { homeList } = this.props;
    if (homeList) {
      const {
        campaign,
        network,
        user,
        content,
        event,
        group_list,
        device_locations
      } = homeList[0];
      const imageSizeInByte = content.images_size_byte;
      const videoSizeInByte = content.videos_size_byte;
      const image = parseFloat(content.image_content_size.split(" ")[0]);
      const video = parseFloat(content.video_content_size.split(" ")[0]);
      const unitImage = this.unitConvert(
        content.image_content_size.split(" ")[1]
      );
      const unitVideo = this.unitConvert(
        content.video_content_size.split(" ")[1]
      );

      if (this.state.homeLoading) {
        return (
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>
        );
      }
      return (
        <div className="mainPage">
          <div className="contentSection">
            <div className="row">
              <div className="col-lg-6">
                <div className="row-container">
                  <div className="row">
                    <div className="col-lg-6">
                      <Card body className="d-flex contentSection__card">
                        <div className="p-2">
                          <b className="contentSection__block">
                            {`${campaign.live}/${campaign.total}`}
                          </b>
                          <span className="contentSection__text">
                            Live Campaigns
                          </span>
                        </div>
                        <div className="ml-auto p-2 mt-3">
                          {" "}
                          <img
                            src={require("../../../assets/images/total_campaign.svg")}
                            alt="IMG"
                          />
                        </div>
                      </Card>
                    </div>
                    <div className="col-lg-6">
                      {" "}
                      <Card body className="d-flex contentSection__card">
                        <div className="p-2">
                          <b className="contentSection__block">
                            {`${network.live}/${network.total}`}
                          </b>
                          <span className="contentSection__text">
                            Online Devices
                          </span>
                        </div>
                        <div className="ml-auto p-2 mt-3">
                          {" "}
                          <img
                            src={require("../../../assets/images/total_devices.svg")}
                            alt="IMG"
                          />
                        </div>
                      </Card>
                    </div>
                    <div className="col-lg-6">
                      <Card body className="d-flex contentSection__card">
                        <div className="p-2">
                          <b className="contentSection__block">{user.total}</b>
                          <span className="contentSection__text">
                            Total Users
                          </span>
                        </div>
                        <div className="ml-auto p-2 mt-3">
                          {" "}
                          <img
                            src={require("../../../assets/images/total_users.svg")}
                            alt="IMG"
                          />
                        </div>
                      </Card>
                    </div>
                    <div className="col-lg-6">
                      {" "}
                      <Card body className="d-flex contentSection__card">
                        <div className="p-2">
                          <b className="contentSection__block">
                            {content.total}
                          </b>
                          <span className="contentSection__text">
                            Total Uploads
                          </span>
                        </div>
                        <div className="ml-auto p-2 mt-3">
                          {" "}
                          <img
                            src={require("../../../assets/images/total_uploads.svg")}
                            alt="IMG"
                          />
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row-container">
                  <Card
                    body
                    className="d-flex contentSection__card contentSection__chart"
                  >
                    <div style={{ width: "100%" }}>
                      <span className="titleHeader">Library Usage</span>
                      <Row>
                        <Col lg={6} md={6}>
                          <DonutChart
                            className="donut-chart"
                            image={imageSizeInByte}
                            video={videoSizeInByte}
                          />
                        </Col>
                        <Col lg={5} md={6} className="legend-con">
                          <ul className="mx-auto">
                            <li>
                              <span
                                className="chart-legend"
                                style={{ backgroundColor: "#FFDA83" }}
                              />
                              <span className="chart-legend-label-text">
                                Image
                              </span>{" "}
                              <span className="my-percent float-right pr-5">
                                {image} {unitImage}
                              </span>
                            </li>
                            <li>
                              <span
                                className="chart-legend"
                                style={{ backgroundColor: "#55D8FE" }}
                              />
                              <span className="chart-legend-label-text">
                                Video{" "}
                              </span>{" "}
                              <span className="my-percent float-right pr-5">
                                {video} {unitVideo}
                              </span>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 ">
                <div className="row-container">
                  <Card body className="contentSection__card ">
                    <div
                      className="d-flex flex-column"
                      style={{ margin: "auto", width: "100%" }}
                    >
                      <span
                        className="titleHeader"
                        style={{ marginBottom: "15px" }}
                      >
                        Network Locations
                      </span>
                      {/* <MapChart location={network.locations} /> */}
                      <WorldMap location={device_locations} />
                    </div>
                  </Card>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row-container">
                  <Card
                    body
                    className="contentSection__card contentSection__event"
                  >
                    <span className="titleHeader">Favourite Group Lists</span>
                    <ul className="dashboard__quickDetailWrapper clearfix">
                      {group_list.map(item => (
                        <li key={item.id.$oid} className="d-flex">
                          <div
                            className={`groupListNav__favourite mt-0 ${item.favourite &&
                              " fav-active"} `}
                          >
                            <i
                              role="presentation"
                              className="bx bxs-star"
                              onClick={e => {
                                e.stopPropagation();
                                this.handleFavouriteGroup(
                                  item.id,
                                  !item.favourite
                                );
                              }}
                            />
                            <i
                              role="presentation"
                              className="bx bx-star"
                              onClick={e => {
                                e.stopPropagation();
                                this.handleFavouriteGroup(
                                  item.id,
                                  !item.favourite
                                );
                              }}
                            />
                          </div>
                          <Link
                            to={{
                              pathname: "/networks",
                              state: { fromDashboard: true, group: item }
                            }}
                            className="mr-auto"
                          >
                            {item.name.length >= 30 ? (
                              <Tooltip
                                item={`${item.name.slice(0, 30)}...`}
                                id={`viewname-${item.id}`}
                                tooltipContent={item.name}
                              />
                            ) : (
                              <span>{item.name}</span>
                            )}
                          </Link>
                          <p>
                            {`${item.devicesOnline}/${item.devicesOnline +
                              item.devicesOffline}`}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div className="d-block text-center p-2 Link seeMore">
                      <Link to="/networks">See more</Link>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  const { list } = state.Home;
  return { homeList: list || undefined };
}

export default connect(
  mapStateToProps,
  { Patch }
)(Home);
