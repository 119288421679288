import React, { Component } from "react";
import { Nav, NavItem, NavLink, Label } from "reactstrap";
import { Link } from "react-router-dom";

//  styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tooltip } from "../../../core";
import NetworkGroupDeviceTable from "../modules/networkGroupDeviceTable";
import { NetworkGroupSearch } from "../modules/networkGroupSearch";

export class NetworkGroupTableDesktopView extends Component {
  render() {
    const { deviceGroups, groupOptions, groupType } = this.props;
    return (
      <div className="row calendarSection content-wrap">
        <div
          className={`col-md-3 calendarSection__leftSection ${
            !this.props.isGroupOpen ? "d-none" : ""
          }`}
        >
          <NetworkGroupSearch
            searchByText={this.props.searchByText}
            handleGroupSearchValue={this.props.handleGroupSearchValue}
            searchVal={this.props.searchVal}
          />

          <div className="network-group__heading p-3">
            <strong
              role="presentation"
              onClick={this.props.handleShowGroupOptions}
            >
              {groupType === "fav" ? "Favourites" : "Group List"}
              <span className="network-group-header-title " />
            </strong>
            <span className="float-right calendarSection__total-online">
              {`Total Online: ${this.props.meta.devices_online}/${this.props
                .meta.devices_online + this.props.meta.devices_offline} `}
            </span>
            {groupOptions && (
              <div className="customSelectOptions customSelectOptions--networkGroupheader">
                <ul>
                  <li
                    role="presentation"
                    className={groupType === "all" && "activeResolution"}
                    onClick={() => this.props.handleGroupType("all")}
                  >
                    All
                  </li>
                  <li
                    role="presentation"
                    className={groupType === "fav" && "activeResolution"}
                    onClick={() => this.props.handleGroupType("fav")}
                  >
                    Favourites
                  </li>
                </ul>
              </div>
            )}
          </div>
          <InfiniteScroll
            dataLength={deviceGroups.length} // This is important field to render the next data
            next={this.props.handleNext}
            hasMore={this.props.refetch}
            height={700}
            loader={
              <div className="d-block text-center">
                <span>...Loading</span>
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center", "margin-top": "15px" }}>
                <b>{deviceGroups.length === 0 && "No data"}</b>
              </p>
            }
            style={{ overFlow: "visible" }}
          >
            <Nav vertical>
              {deviceGroups &&
                deviceGroups.map((i, index) => (
                  <div>
                    <NavItem className="groupListNav" key={index}>
                      <NavLink
                        className={classnames({
                          active:
                            this.props.newSelectedGroup.device_group_id === i.id
                        })}
                        onClick={() => {
                          this.props.changeSelectedGroup(i);
                        }}
                      >
                        <div
                          className={`groupListNav__favourite ${i.attributes
                            .favourite && " fav-active"} `}
                        >
                          <i
                            role="presentation"
                            className="bx bxs-star"
                            onClick={e => {
                              e.stopPropagation();
                              this.props.handleFavouriteGroup(
                                i.id,
                                !i.attributes.favourite
                              );
                            }}
                          />
                          <i
                            role="presentation"
                            className="bx bx-star"
                            onClick={e => {
                              e.stopPropagation();
                              this.props.handleFavouriteGroup(
                                i.id,
                                !i.attributes.favourite
                              );
                            }}
                          />
                        </div>
                        <div>
                          <div className="d-flex">
                            {/* <div className="groupListNav__inputWrapper"> */}
                            {/* <Label className="groupListNav__inputWrapper mr-4">
                            <input
                              type="checkbox"
                              onChange={e =>
                                this.props.handleFavouriteGroup(
                                  e,
                                  i.id,
                                  !i.attributes.favourite
                                )
                              }
                              checked={i.attributes.favourite}
                            />
                            <span className="checkmark" />
                          </Label> */}
                            {/* </div> */}
                            {i.attributes.name.length >= 25 ? (
                              <Tooltip
                                item={
                                  <span className="content__name">
                                    {" "}
                                    {`${i.attributes.name.slice(
                                      0,
                                      25
                                    )}...`}{" "}
                                  </span>
                                }
                                id={`viewname-${index}`}
                                tooltipContent={i.attributes.name}
                              />
                            ) : (
                              <span className="content__name">
                                {i.attributes.name}
                              </span>
                            )}
                            <div
                              className="contentSectionTable__actionButtonWrapper"
                              style={{ "margin-left": "auto" }}
                            >
                              {i.attributes.name !== "Unassigned" && (
                                <span>
                                  <Tooltip
                                    item={
                                      <i
                                        className="bx bx-tv "
                                        role="presentation"
                                        style={{
                                          "margin-right": "8px",
                                          "margin-top": "1px",
                                          "font-size": "20px"
                                        }}
                                        onClick={e => {
                                          e.stopPropagation();
                                          this.props.handleGroupDefaultCampaignSave(
                                            true,
                                            "edit",
                                            this.props.campaignList
                                          );
                                        }}
                                      />
                                    }
                                    id={`defaultCampaign-${i.id}`}
                                    tooltipContent="Default Campaign"
                                  />
                                </span>
                              )}
                              {i.attributes.name !== "Unassigned" && (
                                <span>
                                  <Tooltip
                                    item={
                                      <i
                                        className="fa fa-edit"
                                        role="presentation"
                                        style={{
                                          "margin-right": "8px",
                                          "font-size": "16px"
                                        }}
                                        onClick={() => {
                                          this.props.handleGroupCreate(
                                            true,
                                            "edit",
                                            i
                                          );
                                        }}
                                      />
                                    }
                                    id={`edit-${i.id}`}
                                    tooltipContent="Edit"
                                  />
                                </span>
                              )}
                              <span>
                                <Tooltip
                                  item={
                                    <Link
                                      to={{
                                        pathname: `/networks/screen_shot_view/${i.id}`,
                                        state: {
                                          device: i,
                                          id: i.id,
                                          name: i.attributes.name
                                        }
                                      }}
                                      className="cameraIconLink"
                                    >
                                      <i
                                        className="fas fa-camera"
                                        role="presentation"
                                        style={{
                                          "margin-right": "8px",
                                          "font-size": "16px"
                                        }}
                                      />
                                    </Link>
                                  }
                                  tooltipContent="Screenshots"
                                  id={`header-campaign-ss-${i.id}`}
                                />
                              </span>
                              {i.name !== "Unassigned" && (
                                <span>
                                  <Tooltip
                                    item={
                                      <i
                                        role="presentation"
                                        onClick={() =>
                                          this.props.handleGroupDelete(i.id)
                                        }
                                        className="fa fa-trash action__trash"
                                        style={{ cursor: "pointer" }}
                                      />
                                    }
                                    id={`delete-${i.id}`}
                                    tooltipContent="Delete"
                                  />
                                </span>
                              )}
                              {/* </RouteAllowed> */}
                            </div>
                          </div>
                          <div>
                            Online:{" "}
                            <b>{`          ${i.attributes.devices_online}/${i
                              .attributes.devices_online +
                              i.attributes.devices_offline}
`}</b>
                          </div>
                        </div>
                      </NavLink>
                    </NavItem>
                  </div>
                ))}
            </Nav>
          </InfiniteScroll>
        </div>
        <div
          className={`pl-0 pr-0 ${
            !this.props.isGroupOpen ? "col-md-12" : "col-md-9"
          }`}
        >
          {deviceGroups.length === 0 ? (
            <NetworkGroupDeviceTable
              devices={[]}
              forwardRef={this.props.createRefsFromGroupDeviceTables(
                this.props.newSelectedGroup.device_group_id
              )}
              deviceSearchRef={this.props.searchDevice}
              remoteFormChange={this.props.remoteFormChange}
              selectedDeviceInGroup={this.props.selectedDeviceInGroup}
              stopPlayingDevice={this.props.stopPlayingDevice}
              toggleSendToMediaModal={this.props.toggleSendToMediaModal}
              selectedGroupID={null}
              selectedGroupName={null}
              meta={this.props.selectedGroupMeta}
              removeDevice={this.props.removeDevice}
              toggledeviceControlModal={this.props.toggledeviceControlModal}
              setSingleDevice={this.props.setSingleDevice}
              isSingleDevice={this.props.isSingleDevice}
              toggleSingleDevice={this.props.toggleSingleDevice}
              toggleGroup={this.props.toggleGroup}
              isGroupOpen={this.props.isGroupOpen}
              isGroupLoading={this.props.isGroupLoading}
              handlePageClick={this.props.handlePageClick}
              forcePage={this.props.deviceForcePage}
              sortOrder={this.props.sortOrder}
              handleDeviceSearch={this.props.handleDeviceSearch}
              deviceSearch={this.props.deviceSearch}
              setDeviceSearch={this.props.setDeviceSearch}
              handleSort={this.props.handleDeviceSort}
              deviceSortOrder={this.props.deviceSortOrder}
              refetchGroupDevices={this.props.refetchGroupDevices}
              newSelectedGroup={this.props.newSelectedGroup}
              userDetail={this.props.userDetail}
              Put={this.props.Put}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
            />
          ) : (
            <NetworkGroupDeviceTable
              devices={this.props.devices ? this.props.devices : []}
              forwardRef={this.props.createRefsFromGroupDeviceTables(
                this.props.newSelectedGroup.device_group_id
              )}
              deviceSearchRef={this.props.searchDevice}
              {...this.props}
              remoteFormChange={this.props.remoteFormChange}
              selectedDeviceInGroup={this.props.selectedDeviceInGroup}
              stopPlayingDevice={this.props.stopPlayingDevice}
              toggleSendToMediaModal={this.props.toggleSendToMediaModal}
              selectedGroupID={
                this.props.newSelectedGroup
                  ? this.props.newSelectedGroup.device_group_id
                  : null
              }
              selectedGroupName={
                this.props.newSelectedGroup
                  ? this.props.newSelectedGroup.device_group_name
                  : null
              }
              meta={this.props.selectedGroupMeta}
              removeDevice={this.props.removeDevice}
              toggledeviceControlModal={this.props.toggledeviceControlModal}
              setSingleDevice={this.props.setSingleDevice}
              isSingleDevice={this.props.isSingleDevice}
              toggleSingleDevice={this.props.toggleSingleDevice}
              toggleGroup={this.props.toggleGroup}
              isGroupOpen={this.props.isGroupOpen}
              isGroupLoading={this.props.isGroupLoading}
              handlePageClick={this.props.handlePageClick}
              forcePage={this.props.deviceForcePage}
              sortOrder={this.props.sortOrder}
              handleDeviceSearch={this.props.handleDeviceSearch}
              deviceSearch={this.props.deviceSearch}
              setDeviceSearch={this.props.setDeviceSearch}
              handleSort={this.props.handleDeviceSort}
              deviceSortOrder={this.props.deviceSortOrder}
              refetchGroupDevices={this.props.refetchGroupDevices}
              newSelectedGroup={this.props.newSelectedGroup}
              userDetail={this.props.userDetail}
              Put={this.props.Put}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
            />
          )}
        </div>
      </div>
    );
  }
}
