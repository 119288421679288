import React, { Component } from "react";
import { Table, Badge } from "reactstrap";

export default class SelectedDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeviceList: true
    };
  }

  handleViewSelectedDeviceList = () => {
    this.setState(prevState => ({ showDeviceList: !prevState.showDeviceList }));
  };

  render() {
    return (
      <div>
        {/* <button className="btn btn-link" type="button" onClick={this.handleViewSelectedDeviceList}> */}
        <h5 style={{ padding: "0 15px" }} className="my-2">
          Selected Devices List
          <Badge className="selected-device-badge"> {this.props.selectedDevice.length}</Badge>
        </h5>
        {/* </button> */}

        <Table
          responsive
          className={`deviceListTable ${this.state.showDeviceList ? "showDeviceList" : ""}`}
          style={{ padding: "0 15px" }}
        >
          <thead>
            <tr>
              <th className="pl-2" style={{ width: "auto", padding: "0px" }}>
                Name
              </th>
              <th>Mac Address</th>
              <th>Host Name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.selectedDevice.map(i => {
              const { id, macAddress, serial_number, name, hostname } = i;
              return (
                <tr key={id}>
                  <td>{name || "-"}</td>
                  <td>{macAddress}</td>
                  <td>{hostname || "-"}</td>
                  <td>
                    <span
                      role="button"
                      className="fa fa-trash action__trash"
                      onClick={() =>
                        this.props.handleSelectedDeviceChange(
                          { target: { checked: false } },
                          id,
                          macAddress,
                          serial_number,
                          name,
                          hostname
                        )
                      }
                      style={{
                        fontSize: "15px",
                        cursor: "pointer"
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
