import normalize from "json-api-normalizer";

export const Resources = {
  getSingleDeviceGroup: {
    url: `/device_groups/`,
    asyncActions: {
      init: "getSingleDeviceGroup_INIT",
      success: "getSingleDeviceGroup_SUCCESS",
      error: "getSingleDeviceGroup_ERROR"
    }
  }
};

const initialState = {
  list: []
};

export const deviceGroupDetails = (state = initialState, { type, payload }) => {
  // const {
  //   asyncActions: { success }
  // } = Resources.getSingleDeviceGroup;
  const { init, success, error } = Resources.getSingleDeviceGroup.asyncActions;

  switch (type) {
    case success:
      return { list: normalize(payload.data) };

    // case success: {
    //   const {
    //     data: { data }
    //   } = payload;

    //   return {
    //     ...state,
    //     list: data
    //   };
    // }
    default:
      return state;
  }
};
