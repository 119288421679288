import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import { connect } from "react-redux";
import { Resources } from "../../redux";
import { Get } from "../../../../constant/thunk";

class GetRouteWithPromises extends Component {
  componentDidMount() {
    this.getRoute(this.props.startingLocation, this.props.destinationLocation);
  }

  getRoute = async (startingLocation, destinationLocation, routeWidget) => {
    const startingGeoLocation =
      routeWidget && routeWidget.starting_geo_location
        ? routeWidget.starting_geo_location
        : { lat: "", lon: "" };
    const destinationGeoLocation =
      routeWidget && routeWidget.destination_geo_location
        ? routeWidget.destination_geo_location
        : { lat: "", lon: "" };

    const result = await this.props.Get(
      Resources.getRouteDuration(
        startingLocation,
        destinationLocation,
        startingGeoLocation,
        destinationGeoLocation
      )
    );
    if (result.status === 200 || result.status === 204) {
      const duration =
        result.data.routing_data.rows[0].elements[0].duration.value;

      this.props.updateGeoLocation(
        result.data.starting_location_geo,
        result.data.destination_location_geo
      );
      const durationInMinute = Math.round(duration / 60);
      this.props.setRouteDistance(durationInMinute);
    } else {
      this.props.updateGeoLocation(null, null);
      this.props.setRouteDistance(
        "Routing data could not be fetched for these location."
      );
    }
  };

  handleChange = value => {
    // const location = value.value.split(",")[0].trim();
    this.props.updateLocation(value.value);
    if (this.props.isActive === "start") {
      this.getRoute(value.value, this.props.destinationLocation);
      return;
    }
    if (this.props.isActive === "destination") {
      this.getRoute(this.props.startingLocation, value.value);
    }
  };

  promiseOptions = async inputValue => {
    const result = await this.props.Get(
      Resources.autoCompleteForRoute(inputValue)
    );
    if (result.status === 200) {
      const {
        data: { predictions }
      } = result;
      return predictions.map(item => ({
        label: item.description,
        value: item.description
      }));
    }
  };

  render() {
    return (
      <AsyncSelect
        onChange={this.handleChange}
        cacheOptions
        value={{ value: this.props.location, label: this.props.location }}
        defaultOptions={{
          value: this.props.location,
          label: this.props.location
        }}
        loadOptions={this.promiseOptions}
      />
    );
  }
}

export default connect(
  null,
  { Get }
)(GetRouteWithPromises);
