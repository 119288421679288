/* eslint-disable no-shadow */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { updateCampaign } from "../../redux";
import YoutubePlayer from "../../utls/youtubePlayer";

function handleFieldValueChange(e, data, node, campaign, fn) {
  const {
    contentLocalID,
    screen: { localID }
  } = data;
  const {
    target: { value }
  } = e;
  campaign.updateWidgetProperty(localID, contentLocalID, node, value);
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "url",
    defaultState.url
  );
  updateCampaign(campaign);
  toggle(false);
}

function handleContentDurationChange(
  screenID,
  contentID,
  minute,
  second,
  campaign,
  updateCampaign
) {
  const duration = { minute, second };
  const newCampaign = campaign;
  newCampaign.changeDurationOfContent(contentID, screenID, duration);
  updateCampaign(newCampaign);
  return 0;
}

const YTWidget = props => (
  <Modal
    isOpen={props.open}
    toggle={() => props.toggle(false)}
    backdrop
    className="widgetScreenModal "
  >
    <ModalHeader toggle={() => cancelChanges(props)}>{props.type}</ModalHeader>
    <ModalBody>
      <div
        className="url-widget-background d-flex justify-content-center"
        style={{
          height: props.data.screen.height,
          width: props.data.screen.width,
          background: props.data.screen.fill
        }}
      >
        <YoutubePlayer
          url={props.URLData.url}
          height={props.data.screen.height}
          width={props.data.screen.width}
        />
      </div>
      <Form>
        <FormGroup>
          <div className="globalInput">
            <Label for="examplePassword">URL</Label>
            <Input
              type="text"
              value={props.URLData.url}
              name="url"
              onChange={e =>
                handleFieldValueChange(
                  e,
                  props.data,
                  "url",
                  props.campaign,
                  props.updateCampaign
                )
              }
              onKeyPress={e => {
                if (e.charCode === 13) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              placeholder="Enter Youtube link."
            />
          </div>
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button
        className="btn-outline-primary"
        onClick={() => cancelChanges(props)}
      >
        Cancel
      </Button>
      <Button
        className="mt-0 primaryButton"
        onClick={() => props.toggle(false)}
      >
        Done
      </Button>
    </ModalFooter>
  </Modal>
);

export default connect(
  (state, props) => {
    const { campaign } = state.newCampaign;
    const {
      contentLocalID,
      screen: { localID }
    } = props.data;
    const URLData = campaign.getContent(localID, contentLocalID);
    return {
      campaign,
      URLData: URLData || []
    };
  },
  { updateCampaign }
)(YTWidget);
