import React from "react";
import { toLower, startCase, capitalize } from "lodash";
import { Button } from "reactstrap";
import moment from "moment";
import { PopOver, Tooltip } from "../../../core";

export function columns(
  editParty,
  removeExistingPartyFromDomain,
  currentUserRoles,
  hiddenColumns,
  toggleBrandModal,
  isLoading,
  sortOrder,
  handleSort
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      hidden: hiddenColumns.name,
      headerFormatter: () => {
        return <div>
          <span
            role="presentation"
            onClick={() => {
              handleSort("name", "asc")
            }}
            className="sort-by "
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${sortOrder === "desc" ? "up" : "down"
                }`} />
          </span>
        </div>

      },

    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      columnName: "date_updated",
      headerStyle: { width: "250px" },
      hidden: hiddenColumns.date_updated,
      formatter: (date_updated, row) => (
        <span
          role="presentation"
          className="sort-by "
          style={{
            color: "#000"
          }}
        >
          {moment
            .utc(date_updated)
            .local()
            .format("MMM DD YYYY hh:mm A")}
        </span>
      )
    },

    {
      dataField: "attributes.brands",
      text: "Brands",
      columnName: "brands",
      hidden: hiddenColumns.brands,
      formatter: (brands = [], row) => (
        <div>
          {brands &&
            brands.slice(0, 3).map((item, index) => (
              <span key={index} className={`roles general `}>
                {capitalize(item.name)}
              </span>
            ))}
          <span className="tagmenu-icon">
            {brands && brands.length > 3
              && (
                <PopOver id={`brands-${row.id}`}>
                  {
                    brands.map((item, index) => {
                      return <span key={index} className={`roles general `}>
                        {capitalize(item.name)}
                      </span>
                    })
                  }
                </PopOver>
              )}
          </span>
        </div>
      )
    },
    {
      dataField: "buttons",
      text: "",
      columnName: "isSelected",
      formatter: (name, row) => (
        <div className="switchButtons">
          <Button
            className="btn-outline-primary"
            type="button"
            style={{ margin: ".1em" }}
            onClick={() => {
              toggleBrandModal(true, row.id, false);
            }}
          >
            Add Brand
          </Button>
          <Button
            className="btn-outline-primary"
            type="button"
            style={{ margin: ".1em" }}

            onClick={() => {
              toggleBrandModal(true, row.id, true);
            }}
          >
            Manage Brands
          </Button>
        </div>
      )
    },
    {
      dataField: "actionButtons",
      text: "",
      columnName: "isSelected",
      style: { position: "relative" },
      formatter: (name, row) => (
        <div className="d-flex">
          <div className="contentSectionTable__actionButtonWrapper">
            <span className="contentSectionTable__actionButtonWrapper__buttons">
              <Tooltip
                item={
                  <i
                    className="fa fa-edit"
                    onClick={() =>
                      editParty(
                        row.attributes.name,
                        row.id
                      )
                    }
                    role="presentation"
                    style={{ cursor: "pointer" }}
                  />
                }
                id={`edit-${row.id}`}
                tooltipContent="Edit"
              />
            </span>
            <span className="contentSectionTable__actionButtonWrapper__buttons">
              <Tooltip
                item={
                  <i
                    className="fas fa-times-circle "
                    role="presentation"
                    style={{ cursor: "pointer" }}
                    onClick={e =>
                      removeExistingPartyFromDomain(
                        row.id,
                        row.attributes.name
                      )
                    }
                  />
                }
                id={`delete-${row.id}`}
                tooltipContent="Remove"
              />
            </span>
          </div>
          {" "}
        </div>
      )
    }
  ];
}
