/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { Fragment } from "react";
import { Tooltip } from "reactstrap";

export default class TooltipItem extends React.Component {
  static defaultProps = {
    tooltipContent: "Text"
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen
    }));
  }

  render() {
    return (
      <Fragment>
        {this.props.item}
        <Tooltip
          placement={this.props.placement ? this.props.placement : "top"}
          isOpen={this.state.tooltipOpen}
          target={this.props.target}
          toggle={this.toggle}
        >
          {this.props.tooltipContent
            ? this.props.tooltipContent
            : this.props.children}
        </Tooltip>
      </Fragment>
    );
  }
}
