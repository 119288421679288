/**
 *
 * @param {*} name Campaign Name
 * @param {*} subwindow_number Number of window
 * @param {*} resolutionId number required
 */

import normalize from "json-api-normalizer";
import { uniq } from "lodash";
import { getFilterStatus } from "../../../helper/helperFuncs";

export const postArchivedItems = ({ filteredCampaignId }) => ({
  _jsonapi: {
    data: {
      type: "campaigns",
      attributes: {
        campaign_ids: [...filteredCampaignId]
      }
    }
  }
});
export const unArchiveItems = ({ filteredCampaignId }) => ({
  _jsonapi: {
    data: {
      type: "campaigns",
      attributes: {
        campaign_ids: [...filteredCampaignId]
      }
    }
  }
});

export const removeSingleArchivedCampaign = id => ({
  _jsonapi: {
    data: {
      type: "campaigns",
      attributes: {
        campaign_ids: [id]
      }
    }
  }
});

export const defaultCamapignSetterBodyFormatter = id => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        campaign_id: String(id)
      }
    }
  }
});

export const Resources = {
  getCampaign: {
    url: "/campaigns",
    asyncActions: {
      init: "getCampaign_INIT",
      success: "getCampaign_SUCCESS",
      error: "getContent_ERROR"
    },
    syncActions: {
      updateList: "getCampaign_Update"
    }
  },
  getTotalCampaign: {
    url: "/campaigns",
    asyncActions: {
      init: "getTotalCampaign_INIT",
      success: "getTotalCampaign_SUCCESS",
      error: "getTotalContent_ERROR"
    }
  },
  searchTotalCampaign: {
    url: "/campaigns",
    asyncActions: {
      init: "getTotalCampaign_INIT",
      success: "searchTotalCampaign_SUCCESS",
      error: "getTotalContent_ERROR"
    }
  },
  getGoogleAuth: {
    url: "googleauth",
    asyncActions: {
      init: "googleauth_INIT",
      success: "googleauth_SUCCESS",
      error: "googleauth_ERROR"
    }
  },
  // getGoogleLogin: url => ({
  //   url,
  //   asyncActions: {
  //     init: "googlelogin_INIT",
  //     success: "googlelogin_SUCCESS",
  //     error: "googlelogin_ERROR"
  //   }
  // }),
  getDefaultCampaign: (campaignID = undefined) => ({
    url: `campaigns/get_default_campaign`,
    asyncActions: {
      init: "getDefaultCampaign_INIT",
      success: "getDefaultCampaign_SUCCESS",
      error: "getDefaultCampaign_ERROR"
    }
  }),
  setDefaultCampaign: (campaignID = undefined) => ({
    url: `/campaigns/${campaignID}/set_default_campaign`,
    asyncActions: {
      init: "setDefaultCampaign_INIT",
      success: "setDefaultCampaign_SUCCESS",
      error: "setDefaultCampaign_ERROR"
    }
  }),
  setGroupDefaultCampaign: (
    campaignID = undefined,
    deviceGroupID = undefined
  ) => ({
    url: `/device_groups/${deviceGroupID}/set_default_campaign`,
    body: defaultCamapignSetterBodyFormatter(campaignID),
    asyncActions: {
      init: "setGroupDefaultCampaign_INIT",
      success: "setGroupDefaultCampaign_SUCCESS",
      error: "setGroupDefaultCampaign_ERROR"
    }
  }),
  removeGroupDefaultCampaign: (deviceGroupID = undefined) => ({
    url: `/device_groups/${deviceGroupID}/remove_default_campaign`,
    asyncActions: {
      init: "setGroupDefaultCampaign_INIT",
      success: "setGroupDefaultCampaign_SUCCESS",
      error: "setGroupDefaultCampaign_ERROR"
    }
  }),
  getResolutions: {
    url: "/resolutions",
    asyncActions: {
      init: "getResolution_INIT",
      success: "getResolution_SUCCESS",
      error: "getResolutions_ERROR"
    },
    syncActions: {
      updateList: "getResolution_Update"
    }
  },
  postCampaign: {
    url: "/campaigns",
    asyncActions: {
      init: "postCampaign_INIT",
      success: "postCampaign_SUCCESS",
      error: "postCampaign_ERROR"
    },
    syncActions: {
      add: "postCampaign_ADD"
    }
  },
  putCampaign: {
    url: "/campaigns",
    asyncActions: {
      init: "postCampaign_INIT",
      success: "postCampaign_SUCCESS",
      error: "postCampaign_ERROR"
    },
    syncActions: {
      add: "postCampaign_ADD"
    }
  },
  getArchivedCampaign: {
    url: "/campaigns/archive",
    asyncActions: {
      init: "getArchivedCampaign_INIT",
      success: "getArchivedCampaign_SUCCESS",
      error: "getArchivedCampaign_ERROR"
    }
  },
  postArchiveCampaign: {
    url: "campaigns/bulk_archive",
    asyncActions: {
      init: "postArchiveCampaign_INIT",
      success: "postArchiveCampaign_SUCCESS",
      error: "postArchiveCampaign_ERROR"
    }
  },
  unArchiveCampaign: {
    url: "campaigns/unarchive",
    asyncActions: {
      init: "unArchiveCampaign_INIT",
      success: "unArchiveCampaign_SUCCESS",
      error: "unArchiveCampaign_ERROR"
    }
  },
  removeBulkCampaign: {
    url: "campaigns/bulk_destroy",
    asyncActions: {
      init: "removeBulkCampaign_INIT",
      success: "removeBulkCampaign_SUCCESS",
      error: "removeBulkCampaign_ERROR"
    }
  },
  deleteCampaign: id => ({
    url: `/campaigns/${id}`,
    asyncActions: {
      init: "deleteCampaign_INIT",
      success: "deleteCampaign_SUCCESS",
      error: "deleteCampaign_ERROR"
    }
  }),
  playCampaign: id => ({
    url: `/campaigns/${id}/run_toggle`,
    asyncActions: {
      init: "playCampaign_INIT",
      success: "playCampaign_SUCCESS",
      error: "playCampaign_ERROR"
    }
  }),

  duplicateCampaign: id => ({
    // /api/v1/campaigns/:campaign_id/duplicate
    url: `/campaigns/${id}/duplicate`,
    asyncActions: {
      init: "duplicateCampaign_INIT",
      success: "duplicateCampaign_SUCCESS",
      error: "duplicateCampaign_ERROR",
      successMsg: "Campaign cloned successfully.",
      errorMsg: "Couldn't clone. Please try again later."
    }
  }),
  showCampaign: {
    url: "campaigns/id/custom_get",
    asyncActions: {
      init: "showCampaign_INIT",
      success: "showCampaign_SUCCESS",
      error: "showCampaign_ERROR"
    }
  },
  getEditCampaign: {
    url: "id/custom_get",
    asyncActions: {
      init: "getEditCampaign_INIT",
      success: "getEditCampaign_SUCCESS",
      error: "getEditCampaign_ERROR"
    }
  },
  previewCampaign: {
    url: "id/custom_get",
    asyncActions: {
      init: "getPreviewCampaign_INIT",
      success: "getPreviewCampaign_SUCCESS",
      error: "getPreviewCampaign_ERROR"
    }
  },
  getPreviewUrl: {
    url: "id/custom_get",
    asyncActions: {
      init: "getPreviewUrl_INIT",
      success: "getPreviewUrl_SUCCESS",
      error: "getPreviewUrl_ERROR"
    }
  },
  getCampaignJSON: id => ({
    url: `campaigns/${id}/json_file`,
    asyncActions: {
      init: "getCampaignJSON_INIT",
      success: "getCampaignJSON_SUCCESS",
      error: "getCampaignJSON_ERROR"
    }
  }),
  getCampaignZIP: id => ({
    url: `campaigns/${id}/campaign_zip`,
    asyncActions: {
      init: "getCampaignZIP_INIT",
      success: "getCampaignZIP_SUCCESS",
      error: "getCampaignZIP_ERROR"
    }
  }),

  autoComplete: text => ({
    url: `/campaigns/autocomplete_places?search_params=${text}`,
    asyncActions: {
      init: "autoComplete_INIT",
      success: "autoComplete_SUCCESS",
      error: "autoComplete_ERROR"
    }
  }),
  autoCompleteForRoute: text => ({
    url: `/campaigns/autocomplete_addresses?search_params=${text}`,
    asyncActions: {
      init: "autoCompleteForRoute_INIT",
      success: "autoCompleteForRoute_SUCCESS",
      error: "autoCompleteForRoute_ERROR"
    }
  }),
  getWeather: (location, days = 1, geo_location = { lat: "", lon: "" }) => ({
    url: `/campaigns/weather_api?city_name=${location}&forecast_days=${days}&lat=${geo_location.lat}&lon=${geo_location.lon}`,
    asyncActions: {
      init: "getWeather_INIT",
      success: "getWeather_SUCCESS",
      error: "getWeather_ERROR",
      errorMsg: "Weather for selected location is not available"
    }
  }),
  getRouteDuration: (
    startingLocation,
    destinationLocation,
    startingGeoLocation,
    destinationGeoLocation
  ) => ({
    url: `/campaigns/routing_api?starting_geo_location_lat=${startingGeoLocation.lat}&starting_geo_location_lon=${startingGeoLocation.lon}&destination_geo_location.lat=${destinationGeoLocation.lat}&destination_geo_location.lon=${destinationGeoLocation.lon}&starting_location=${startingLocation}&destination_location=${destinationLocation}`,
    asyncActions: {
      init: "getRouteDuration_INIT",
      success: "getRouteDuration_SUCCESS",
      error: "getRouteDuration_ERROR"
    }
  }),
  getRSSData: url => ({
    url: `/campaigns/fetch_rss?url=${url}`,
    asyncActions: {
      init: "getRSSData_INIT",
      success: "getRSSData_SUCCESS",
      error: "getRSSData_ERROR"
    }
  })
};

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined,
  filterStatus: undefined
};

export function campaign(state = { initialState }, action = {}) {
  const { init, success, error } = Resources.getCampaign.asyncActions;
  const { postCampaign } = Resources;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        filterStatus: getFilterStatus(action.payload.config.url)
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case postCampaign.init: {
      return state;
    }
    case postCampaign.success: {
      return state;
    }
    case postCampaign.error: {
      return state;
    }

    default: {
      return state;
    }
  }
}
export function archiveCampaign(state = { initialState }, action = {}) {
  const { init, success, error } = Resources.getArchivedCampaign.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        filterStatus: getFilterStatus(action.payload.config.url)
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function resolutions(state = initialState, { type, payload }) {
  const { success, error } = Resources.getResolutions.asyncActions;
  switch (type) {
    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default:
      return state;
  }
}

export function campaignDetails(state = initialState, action) {
  const { success, error } = Resources.showCampaign.asyncActions;
  switch (action.type) {
    case success: {
      const {
        data: {
          _jsonapi: { data }
        }
      } = action.payload;
      return {
        list: data,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default:
      return state;
  }
}

export function campaignEdit(state = initialState, action) {
  const { success, error } = Resources.getEditCampaign.asyncActions;
  switch (action.type) {
    case success: {
      const {
        data: {
          _jsonapi: { data }
        },
        status
      } = action.payload;

      return {
        list: data,
        status
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default:
      return state;
  }
}

function whichList(url, state, data) {
  if (url.search("page=1&") > -1) {
    return data;
  }
  const { list: oldList = [] } = state;
  return uniq([...oldList, ...data]);
}

export function totalCampaign(state = { initialState }, action = {}) {
  const { init, success, error } = Resources.getTotalCampaign.asyncActions;
  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText,
        config: { url = "" }
      } = action.payload;

      return {
        list: whichList(url, state, data),
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        filterStatus: getFilterStatus(action.payload.config.url)
      };
    }

    case "searchTotalCampaign_SUCCESS": {
      const {
        data: { data, links, meta },
        status,
        statusText,
        config: { url = "" }
      } = action.payload;
      return {
        list: whichList(url, state, data),
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        filterStatus: getFilterStatus(action.payload.config.url)
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function groupDefaultCampaignSetter(
  state = { initialState },
  action = {}
) {
  const {
    init,
    success,
    error
  } = Resources.setGroupDefaultCampaign().asyncActions;
  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      // nothing to do here
      return {
        ...state,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return { ...state };
    }
  }
}

export function groupDefaultCampaignRemover(
  state = { initialState },
  action = {}
) {
  const {
    init,
    success,
    error
  } = Resources.removeGroupDefaultCampaign().asyncActions;
  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      // nothing to do here
      return {
        ...state,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return { ...state };
    }
  }
}
