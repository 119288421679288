export const resolutions = [
  { resolution: "1024x576", label: "1024x576", value: "1024x576" },
  { resolution: "1152x648", label: "1152x648", value: "1152x648" },
  { resolution: "1280x720", label: "1280x720", value: "1280x720" },
  { resolution: "1366x768", label: "1366x768", value: "1366x768" },
  { resolution: "1600x900", label: "1600x900", value: "1600x900" },
  { resolution: "1040x1320", label: "1040x1320", value: "1040x1320" },
  { resolution: "1600x1248", label: "1600x1248", value: "1600x1248" },
  { resolution: "1920x1080", label: "1920x1080", value: "1920x1080" },
  { resolution: "2560x1440", label: "2560x1440", value: "2560x1440" },
  { resolution: "3840x2160", label: "3840x2160", value: "3840x2160" }
];
