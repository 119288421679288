import React from "react";
import Slider from "react-rangeslider";

import "react-rangeslider/lib/index.css";
import "../../assets/stylesheet/rangerslider.css";

const RangeSlider = props => (
  <div className="slider orientation-reversed">
    <div className="slider-vertical">
      <Slider orientation="vertical" {...props.input} {...props} />
    </div>
  </div>
);

RangeSlider.defaultProps = {
  input: { value: 50, onChange: undefined }
};

export default RangeSlider;
