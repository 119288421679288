import React, { Component, Fragment } from "react";
import moment from "moment";
import Screen from "./screen";
import PausedScreen from "./pausedScreen";
import { prop } from "ramda";

class PreviewScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: props.startTime || moment(),
      isStart: 0,
      currentIndex: 0,
      isPlaylist: false,
      selectedPLC: 0,
      playlistIndex: 0,
      pausedIndex: null,
      forwardIndex: null,
      backwardIndex: null,
      pausedPlaylistIndex: null,
      playedDuration: 0,
      playlistPlayedDuration: 0,
      isRepeat: false,
      playlistStartTime: "",
      forward: false
    };
  }

  toggleStart = () => {
    this.setState({ isStart: 1 });
  };

  setStartTime = () => {
    this.setState({ startTime: moment() });
  };

  handleContentExceptPlaylist = (oldIndex, selectedPLC) => {
    this.setState({
      currentIndex: oldIndex,
      selectedPLC,
      playlistIndex: 0,
      pausedPlaylistIndex: 0,
      isStart: 1
    });
  };

  setSelectedPlc = selectedPLC => {
    this.setState({
      selectedPLC,
      playlistIndex: 0
    });
  };

  resetSelectedPlaylist = () => {
    this.setState({
      selectedPLC: undefined,
      playlistIndex: 0,
      pausedPlaylistIndex: 0
    });
  };

  handlePlaylist = (oldIndex, selectedPLC, playlistIndex) => {
    this.setState({
      currentIndex: oldIndex,
      selectedPLC,
      playlistIndex,
      isStart: 1
    });
  };

  handlePlaylistIndex = index => {
    this.setState({ playlistIndex: index });
    this.setPlaylistStartTime();
  };

  resetPlaylistIndex = () => {
    this.setState({ playlistIndex: 0, pausedPlaylistIndex: null });
  };

  setPausedIndex = (index, playlistIndex) => {
    this.setState(
      { pausedIndex: index, pausedPlaylistIndex: playlistIndex },
      () => {
        this.setPlayedTime();
      }
    );
  };

  setPausedIndexForForward = (index, playlistIndex) => {
    this.setState({ pausedIndex: index, pausedPlaylistIndex: playlistIndex });
  };

  setForwardIndex = (index, playlistIndex) => {
    this.setState(
      { forwardIndex: index, forwardPlaylistIndex: playlistIndex },
      () => {
        this.setPlayedTimeForForward();
      }
    );
  };

  setNewIndexAndPlayedDuration = (newIndex, newPlayDuration) => {
    this.setState({
      currentIndex: newIndex,
      playedDuration: newPlayDuration
    });
  };

  setPlayedTimeForForward = () => {
    if (this.state.currentIndex === this.state.forwardIndex) {
      const playedDuration =
        this.props.forwardTime.diff(this.state.startTime, "second") + 5;
      this.setState({ playedDuration }, () => {
        this.calculateDuration(this.props.content);
      });
    }
  };

  calculateDuration = async content => {
    let i = 0;
    while (i < 5) {
      if (
        content[this.state.currentIndex].duration < this.state.playedDuration
      ) {
        const shouldBeZero = content.length - 1 === this.state.currentIndex;
        const newIndex = shouldBeZero ? 0 : this.state.currentIndex + 1;
        const newPlayDuration =
          this.state.playedDuration - content[this.state.currentIndex].duration;
        this.setState({
          currentIndex: newIndex,
          playedDuration: newPlayDuration
        });
      }
      // eslint-disable-next-line no-plusplus
      i++;
    }
  };

  setPlayedTime = () => {
    if (this.state.currentIndex === this.state.pausedIndex) {
      const playedDuration =
        this.state.playedDuration +
        this.props.pausedTime.diff(this.state.startTime, "second");
      this.setState({ playedDuration }, () => {
        // console.error("total", this.state);
      });

      if (this.state.pausedPlaylistIndex === this.state.playlistIndex) {
        const playlistPlayedDuration =
          this.state.playlistPlayedDuration +
          this.props.pausedTime.diff(this.state.playlistStartTime, "second");
        this.setState({ playlistPlayedDuration }, () => {
          // console.error("playlist-ztotal", playlistPlayedDuration);
        });
      }
    }
  };

  resetPlayedDuration = () => {
    this.setState({ playedDuration: 0 });
  };

  resetPlaylistPlayedDuration = () => {
    this.setState({ playlistPlayedDuration: 0 });
  };

  setPlaylistStartTime = () => {
    this.setState({ playlistStartTime: moment() });
  };

  setPlaylistPlayedTime = durn => {
    this.setState({ playlistPlayedDuration: durn });
  };

  render() {
    if (this.props.isPaused) {
      return (
        <PausedScreen
          {...this.props}
          currentIndex={this.state.currentIndex}
          selectedPLC={this.state.selectedPLC}
          playlistIndex={this.state.playlistIndex}
          setPausedIndex={this.setPausedIndex}
          setPlayedTime={this.setPlayedTime}
          playedDuration={this.state.playedDuration}
          setStartTime={this.setStartTime}
          playlistPlayedDuration={this.state.playlistPlayedDuration}
          handlePlaylistIndex={this.handlePlaylistIndex}
          setPausedIndexForForward={this.setPausedIndexForForward}
          resetSelectedPlaylist={this.resetSelectedPlaylist}
          // Forward and backward
          isForward={this.props.isForward}
          isBackward={this.props.isBackward}
          resetForwardAndBackward={this.props.resetForwardAndBackward}
          startTime={this.state.startTime}
          forwardTime={this.props.forwardTime}
          backwardTime={this.props.backwardTime}
          setForwardIndex={this.setForwardIndex}
          removeForwardTime={this.props.removeForwardTime}
          setNewIndexAndPlayedDuration={this.setNewIndexAndPlayedDuration}
          setPlaylistPlayedTime={this.setPlaylistPlayedTime}
          setSelectedPlc={this.setSelectedPlc}
        />
      );
    }
    return (
      <Screen
        {...this.props}
        isStart={this.state.isStart}
        toggleStart={this.toggleStart}
        currentIndex={this.state.currentIndex}
        pausedIndex={this.state.pausedIndex}
        selectedPLC={this.state.selectedPLC}
        playlistIndex={this.state.playlistIndex}
        playedDuration={this.state.playedDuration}
        playlistPlayedDuration={this.state.playlistPlayedDuration}
        pausedPlaylistIndex={this.state.pausedPlaylistIndex}
        pausedTime={this.props.pausedTime}
        handleContentExceptPlaylist={this.handleContentExceptPlaylist}
        handlePlaylist={this.handlePlaylist}
        handlePlaylistIndex={this.handlePlaylistIndex}
        resetPlayedDuration={this.resetPlayedDuration}
        resetPlaylistPlayedDuration={this.resetPlaylistPlayedDuration}
        setPlaylistStartTime={this.setPlaylistStartTime}
        resetPlaylistIndex={this.resetPlaylistIndex}
        setStartTime={this.setStartTime}
        // For Forward and Backward
        isForward={this.props.isForward}
        isBackward={this.props.isBackward}
        resetForwardAndBackward={this.props.resetForwardAndBackward}
        startTime={this.state.startTime}
        forwardTime={this.props.forwardTime}
        backwardTime={this.props.backwardTime}
        setForwardIndex={this.setForwardIndex}
        removeForwardTime={this.props.removeForwardTime}
        setNewIndexAndPlayedDuration={this.setNewIndexAndPlayedDuration}
        setPlaylistPlayedTime={this.setPlaylistPlayedTime}
        resetSelectedPlaylist={this.resetSelectedPlaylist}
        setSelectedPlc={this.setSelectedPlc}
      />
    );
  }
}

export default PreviewScreen;
