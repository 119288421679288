import React from "react";

import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import AppDatePicker from "./datePicker";

export default function UploadNewContent() {
  return (
    <div className="container wrapper">
      <h5>Upload new content</h5>

      <div className="upload-content">
        <Form inline>
          <img src="" className="img-fluid" alt="" />
          <FormGroup className="mb-2 mr-4 tagup">
            <Label for="name" className="mr-sm-2">
              Name
            </Label>
            <Input
              type="text"
              name="MediaName"
              id="mediaName"
              placeholder="Content Name"
            />
          </FormGroup>
          <FormGroup className="mb-2 uptag">
            <Label for="examplePassword" className="mr-sm-2 ml-4">
              Tag
            </Label>
            <Input
              type="text"
              disabled
              className="uptag1"
              name="tag"
              id="tag"
              placeholder="Up-comming feature"
            />
          </FormGroup>
          <button type="button" className="d-flex btn-outline-primary remove">
            Remove
          </button>
        </Form>
      </div>
    </div>
  );
}
