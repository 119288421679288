/** Import library from nodes modules */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { remove, differenceBy } from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";
import filterFactory from "react-bootstrap-table2-filter";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
/** Import Local Utils from utils folders */
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactPaginate from "react-paginate";
import { columns as columns1 } from "../utls/groupDeviceUtls";
import { Tooltip } from "../../../core";
import {
  Resources as userResources,
  handleNetworkViewPreference
} from "../../auth/redux/loginRedux";
import { CustomToggleList } from "../utls/customToggle";

// const CustomToggleList = ({
//   columns,
//   onColumnToggle,
//   toggles,
//   hiddenColumn,
//   handleChangeColumn
// }) => {
//   const formattedCol = columns
//     .filter(
//       column =>
//         column.columnName !== "name" && column.columnName !== "action_buttons"
//     )
//     .map(column => ({
//       ...column,
//       toggle: toggles[column.dataField]
//     }));
//   // const colOnTheTable = formattedCol.filter(item => item.toggle);
//   // const random = Math.floor(Math.random() * 4);
//   // if (colOnTheTable.length > 4) {
//   //   const toggleList = colOnTheTable[random];
//   //   onColumnToggle(toggleList.dataField);
//   // }
//   function handleChangeToggle(column) {
//     handleChangeColumn(column, !hiddenColumn[column]);
//   }

//   return (
//     <UncontrolledDropdown>
//       <DropdownToggle className="iconButton" caret>
//         <Tooltip
//           item={<i className="bx bx-list-ul" style={{ color: "#8e8e8e" }} />}
//           id="togglecol"
//           tooltipContent="Toggle Column"
//         />
//       </DropdownToggle>
//       <DropdownMenu right>
//         {formattedCol.map(column => (
//           <DropdownItem toggle={false}>
//             <span
//               role="button"
//               key={column.dataField}
//               // style={{ background: column.toggle ? "red" : "blue" }}
//               // aria-pressed={column.toggle ? "true" : "false"}
//               onMouseDown={() => onColumnToggle(column.columnName)}
//               onClick={() => handleChangeToggle(column.columnName)}
//             >
//               <i
//                 className="fa fa-check"
//                 style={{ opacity: column.toggle ? 1 : 0 }}
//               />
//               {column.text}
//             </span>
//           </DropdownItem>
//         ))}
//       </DropdownMenu>
//     </UncontrolledDropdown>
//   );
// };

const rowClasses = (row, rowIndex) => {
  let classes = null;
  // console.error(row.att);

  if (row.attributes.status === "offline") {
    classes = "offlineClass";
  } else {
    classes = "onlineClass";
  }

  return classes;
};

class NetworkGroupDeviceTable extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      name: false,
      mac_address: false,
      currently_playing: false,
      last_downloaded_name: false,
      tags: true,
      hostname: true,
      electron_version: true,
      timezone: true,
      orientation: true,
      status: false,
      resolution: true,
      action_buttons: false,
      group: true,
      block_pop: false
    };
    this.state = {
      forcePage: undefined,
      visibleColumn: 4,
      hiddenColumn: this.initialState,
      hiddenColumnGroup: {
        mac_address: false,
        currentlyPlaying: false,
        lastDownloadedName: false,
        tags: false,
        hostname: false,
        electron_version: false,
        timezone: false,
        orientation: false,
        status: false,
        searchVal: "",
        resolution: false
      }
    };
  }

  handleSearchVal = value => {
    this.setState({ searchVal: value });
    this.props.setDeviceSearch(value);
    this.props.handleDeviceSearch(value);
  };

  handleChangeColumn = (name, flag) => {
    let formattedCol;
    if (this.props.userDetail.network_preference) {
      formattedCol = {
        ...this.props.userDetail.network_preference,
        [name]: flag
      };
    } else {
      formattedCol = {
        ...this.initialState,
        [name]: flag
      };
    }
    const { updateUser } = userResources;
    updateUser.url = `/users/${this.props.userDetail.id}`;
    updateUser.body = handleNetworkViewPreference(formattedCol);
    this.props.Put(updateUser);
  };

  changeVisibleColumn = col => {
    this.setState({ visibleColumn: col });
  };

  /** Return a view which is shown when there is no data. */
  handleNodata = () => <span className="null-message-info">No Data</span>;

  selectRowData = () => ({
    mode: "checkbox",
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <label htmlFor="input" className="contentSection__checkBoxWrapper">
        <input type={mode} {...rest} />
        <span className="checkmark" />
      </label>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <label htmlFor="input" className="contentSection__checkBoxWrapper">
        <input type={mode} {...rest} />
        <span className="checkmark" />
      </label>
    ),
    onSelect: (row, isSelect) => {
      if (isSelect) {
        this.props.remoteFormChange("selectedDevicesInGroup", [
          ...(this.props.selectedDeviceInGroup
            ? this.props.selectedDeviceInGroup
            : []),
          row
        ]);
      } else {
        const { selectedDeviceInGroup } = this.props;
        remove(selectedDeviceInGroup, { id: row.id });
        this.props.remoteFormChange(
          "selectedDevicesInGroup",
          selectedDeviceInGroup
        );
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        this.props.remoteFormChange("selectedDevicesInGroup", [
          ...this.props.selectedDeviceInGroup,
          ...rows
        ]);
      } else {
        const { selectedDeviceInGroup } = this.props;
        const formattedNetworks = differenceBy(
          selectedDeviceInGroup,
          rows,
          "id"
        );
        this.props.remoteFormChange(
          "selectedDevicesInGroup",
          formattedNetworks
        );
      }
    }
  });

  toggleDrawer = item => {
    this.props.toggledeviceControlModal(this.props.selectedGroupID);
    this.props.setSingleDevice(item);
  };

  toggleSendToDevice = item => {
    this.props.toggleSendToMediaModal(this.props.selectedGroupID);
    this.props.setSingleDevice(item);
  };

  render() {
    return (
      <ToolkitProvider
        forwardRef={this.props.forwardRef || []}
        // hover
        keyField="id"
        data={
          (this.props.devices &&
            this.props.devices.map(item => {
              const temp = item;
              temp.action_buttons = this.props.selectedDeviceInGroup.length > 0;
              return temp;
            })) ||
          []
        }
        columns={columns1(
          this.props.stopPlayingDevice,
          this.toggleSendToDevice,
          this.props.removeDevice,
          this.toggleDrawer,
          this.props.userDetail.network_preference
            ? this.props.userDetail.network_preference
            : this.initialState,
          this.handleChangeColumn,
          this.state.isGroupOpen,
          this.props.handleSort,
          this.props.deviceSortOrder,
          this.props.selectedGroupID,
          this.props.selectedGroupName,
          this.props.isMobileView
        )}
        search
        columnToggle
      >
        {props => (
          <div>
            <div
              className="d-flex  p-3 networkGroupSearch"
              style={{
                borderBottom: "1px solid #dddbda",
                position: "relative"
              }}
            >
              <div className="pt-2 deviceGroupHeader">
                <div
                  style={{
                    display: "inline",
                    cursor: "pointer",
                    float: "left"
                  }}
                  onClick={() => {
                    this.props.toggleGroup();
                  }}
                >
                  <Tooltip
                    className="collapse-tooltip"
                    item={
                      <img
                        style={{ height: "24px", color: "#A5A4BF" }}
                        src={require("../../../assets/images/menu.svg")}
                        alt="IMG"
                      />
                    }
                    id="collapse"
                    tooltipContent={
                      this.props.isGroupOpen
                        ? "Hide Network Group"
                        : "Show Network Group"
                    }
                  />
                </div>

                <div className=" pl-3 ">
                  {this.props.selectedGroupName &&
                  this.props.selectedGroupName.length >= 35 ? (
                    <Tooltip
                      item={
                        <span className="networkTitle">
                          {" "}
                          {`${this.props.selectedGroupName.slice(
                            0,
                            35
                          )}...`}{" "}
                        </span>
                      }
                      id="groupNAme"
                      tooltipContent={this.props.selectedGroupName}
                    />
                  ) : (
                    <span className="networkTitle">
                      {this.props.selectedGroupName}
                    </span>
                  )}
                </div>
              </div>
              <small className="flex-fill networkDeviceSelector">
                {this.props.selectedDeviceInGroup.length > 0 &&
                  `${this.props.selectedDeviceInGroup.length} ${
                    this.props.selectedDeviceInGroup.length === 1
                      ? "device is selected"
                      : "devices are selected"
                  }`}
              </small>
              <div className="networkSearch">
                {" "}
                <span
                  className="bx bx-search bx-sm search__searchIcon"
                  width="100%"
                />
                <input
                  ref={this.props.deviceSearchRef}
                  type="text"
                  className="search__input"
                  placeholder=" Search Device"
                  style={{ border: "1px solid #dddbda" }}
                  onChange={e => {
                    this.handleSearchVal(e.target.value);
                  }}
                  value={this.state.searchVal}
                />
              </div>
              <CustomToggleList
                hiddenColumn={
                  this.props.userDetail.network_preference
                    ? this.props.userDetail.network_preference
                    : this.initialState
                }
                handleChangeColumn={this.handleChangeColumn}
                {...props.columnToggleProps}
              />
            </div>
            {/* <InfiniteScroll
              dataLength={
                this.props.newSelectedGroup &&
                this.props.newSelectedGroup.devices.length
              } // This is important field to render the next data
              next={this.props.handlePageClick}
              hasMore={this.props.refetchGroupDevices}
              height={760}
              loader={
                <div className="d-block text-center">
                  <span>...Loading</span>
                </div>
              }
              style={{ overFlow: "visible", boxShadow: "0 2px 4px #ddd" }}
              scrollThreshold={0.5}
            > */}
            <div className="networkTableWrapper">
              <BootstrapTable
                ref={this.props.forwardRef}
                classes={
                  Object.keys(props.columnToggleProps.toggles).filter(
                    key => props.columnToggleProps.toggles[key] === true
                  ).length > 6 && "networkGroupColumn"
                }
                loading={this.props.isGroupLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                wrapperClasses="table-responsive content-wrap network-table-height "
                bordered={false}
                hover
                noDataIndication={this.handleNodata}
                selectRow={this.selectRowData()}
                filter={filterFactory()}
                rowClasses={rowClasses}
                rowEvents={
                  !this.props.isMobileView && {
                    onClick: (e, row) => {
                      e.stopPropagation();
                      const win = window.open(
                        `/networks/device/${row.id}?id=${this.props.selectedGroupID}&gn=${this.props.selectedGroupName}&n=${row.attributes.name}&view=groups`,
                        "_blank"
                      );
                      win.focus();
                    },
                    style: {
                      cursor: "pointer"
                    }
                  }
                }
                {...props.baseProps}
              />
            </div>
            <div
              className="d-flex networkTableButtonWrapper"
              style={{
                height: "50px",
                marginTop: this.props.isGroupOpen ? "14px" : "10px"
              }}
            >
              <span className="mr-auto mt-1 networkDeviceCountSelector">
                {this.props.meta &&
                  `Showing ${this.props.devices.length *
                    this.props.meta.page} of ${
                    this.props.meta.totalDevices
                  } devices`}
              </span>
              {this.props.devices.length > 0 && (
                <div className="contentSectionPagination contentSectionPagination--mod clearfix">
                  <ReactPaginate
                    pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                    pageRangeDisplayed={this.props.pageRangeDisplayed}
                    onPageChange={this.props.handlePageClick}
                    marginPagesDisplayed={this.props.marginPagesDisplayed}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                    previousLinkClassName="pagination-label
                  "
                    nextLinkClassName="pagination-label"
                    disabledClassName="paginationDisable"
                    previousLabel="<"
                    forcePage={this.props.forcePage}
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="break-me"
                  />
                </div>
              )}
            </div>

            {/* </InfiniteScroll> */}
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default NetworkGroupDeviceTable;

// eslint-disable-next-line no-lone-blocks
{
  /* <Field name="selectedDevicesInGroup" component="input" hidden type="text" /> */
}

/**
 * assetId: 123132
calendarId: null
calendarName: null
campaignId: null
campaignName: null
height: null
hostname: "gurzu-nuc"
id: "5c87885e39b60b5fa2aa4738"
loc: (2) [85.3206, 27.7111]
macAddress: "94:c6:91:a2:aa:22"
name: "test nuc"
orientation: "-"
resolution: "x"
rs232Support: false
s3ScreenshotUrl: "https://staging-mps-content.s3.us-east-2.amazonaws.com/94c691a2aa22.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXBLYVCCCZSOSSIA%2F20190626%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20190626T074240Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=ef71676a352fe3c5e2c775c99e739d8d64f0184b32bbbcb9b2421a5b18a786f2"
status: "offline"
tags: ["chocolate"]
timezone: null
type: "devices"
volume: 39
width: null
 */
