export const generateUserLogin = formValues => ({
  _jsonapi: {
    data: {
      type: "users",
      attributes: {
        ...formValues
      }
    }
  }
});

export const generateForgotPassword = formValues => ({
  _jsonapi: {
    data: {
      type: "users",
      attributes: {
        ...formValues
      }
    }
  }
});

export const generateResetPassword = ({ data }) => ({
  _jsonapi: {
    data: {
      type: "users",
      attributes: {
        ...data
      }
    }
  }
});

export const generateResendConfirmation = data => ({
  _jsonapi: {
    data: {
      type: "users",
      attributes: {
        id: data
      }
    }
  }
});

export const createUser = ({ formValues, domain }) => ({
  _jsonapi: {
    data: {
      type: "users",
      attributes: {
        name: formValues.name,
        password: formValues.password,
        email: formValues.email,
        // ...formValues,
        domain_users_attributes: [
          {
            roles: formValues.roles,
            domain_id: domain.id
          }
        ]
      }
    }
  }
});

export const generateUserUpdate = (id, { data }) => ({
  _jsonapi: {
    data: {
      type: "user",
      attributes: {
        id,
        ...data
        // domain_users_attributes: [
        //   {
        //     roles: formValues.roles,
        //     domain_id: domain.id
        //   }
        // ]
      }
    }
  }
});

export const generateDefaultDomain = (id, domainID) => ({
  _jsonapi: {
    data: {
      type: "user",
      attributes: {
        id,
        default_domain: domainID
      }
    }
  }
});

export const handleNetworkViewPreference = preference => ({
  _jsonapi: {
    data: {
      type: "users",
      attributes: {
        network_preference: preference
      }
    }
  }
});

export const handleSendReport = attributes => ({
  _jsonapi: {
    data: {
      type: "supports",
      attributes: {
        ...attributes
      }
    }
  }
});

export const Resources = {
  getUserLogin: {
    url: "/users/login",
    asyncActions: {
      init: "sign_in_INIT",
      success: "sign_in_SUCCESS",
      error: "sign_in_ERROR",
      successMsg: "Logged in Successfully ✓",
      errorMsg: "Error Message",
      warningMsg: ""
    },
    body: ""
  },
  addUser: {
    url: "/users/register",
    asyncActions: {
      init: "addUser_INIT",
      success: "addUser_SUCCESS",
      error: "addUser_ERROR",
      successMsg: "User added Successfully ✓",
      errorMsg: "Error Message"
    }
  },
  getForgotPassword: {
    url: "/users/forgot_password",
    asyncActions: {
      init: "getForgotPassword_INIT",
      success: "getForgotPassword_SUCCESS",
      error: "getForgotPassword_ERROR",
      successMsg: "email sent",
      errorMsg: "Error"
    }
  },
  getResendConfirmation: {
    url: "/users/resend_confirmation",
    asyncActions: {
      init: "getResendConfirmation_INIT",
      success: "getResendConfirmation_SUCCESS",
      error: "getResendConfirmation_ERROR",
      successMsg: "Confirmation Email Sent",
      errorMsg: "Confirmation Email Not Sent"
    }
  },
  getResetPassword: {
    url: "/users/reset_password",
    asyncActions: {
      init: "getResetPassword_INIT",
      success: "getResetPassword_SUCCESS",
      error: "getResetPassword_ERROR",
      successMsg: "Password Successfully Reset",
      errorMsg: "Error"
    }
  },
  getProfile: {
    url: `/users/id`,
    asyncActions: {
      init: "getProfile_INIT",
      success: "getProfile_SUCCESS",
      error: "getProfile_ERROR"
    }
  },
  updateUser: {
    asyncActions: {
      init: "updateUser_INIT",
      success: "updateUser_SUCCESS",
      error: "updateUser_ERROR",
      successMsg: "User updated Successfully ✓"
    }
  },
  updateUserRoles: {
    url: `/users/id`,
    asyncActions: {
      init: "updateUserRoles_INIT",
      success: "updateUserRoles_SUCCESS",
      error: "updateUserRoles_ERROR",
      successMsg: "User information has been updated Successfully ✓"
    }
  },
  changePassword: {
    url: `/users/change_password`,
    asyncActions: {
      init: "changePassword_INIT",
      success: "changePassword_SUCCESS",
      error: "changePassword_ERROR",
      successMsg: "Password Changed Successfully ✓"
    }
  },
  getUsers: {
    url: `/users`,
    asyncActions: {
      init: "getUsers_INIT",
      success: "getUsers_SUCCESS",
      error: "getUsers_ERROR"
    }
  },
  deleteUser: {
    url: `/users/id`,
    asyncActions: {
      init: "deleteUser_INIT",
      success: "deleteUser_SUCCESS",
      error: "deleteUser_ERROR"
    }
  },
  getUserDetails: {
    url: `/users`,
    asyncActions: {
      init: "getUserssDetails_INIT",
      success: "getUsersDetails_SUCCESS",
      error: "getUserssDetails_ERROR"
    }
  },
  getUserProfileImage: {
    url: `/users/fetch_profile`,
    asyncActions: {
      init: "getUserProfileImage_INIT",
      success: "getUserProfileImage_SUCCESS",
      error: "getUserProfileImage_ERROR"
    }
  },
  setDefaultDomain: {
    asyncActions: {
      init: " setDefaultDomain_INIT",
      success: "setDefaultDomain_SUCCESS",
      error: " setDefaultDomain_ERROR",
      successMsg: "Changed Default Domain Successfully ✓"
    }
  },
  getGurzuUsers: {
    url: `/users/gurzu_users`,
    asyncActions: {
      init: "getGruzuUsers_INIT",
      success: "getGurzuUsers_SUCCESS",
      error: "getGurzuUsers_ERROR"
    }
  },
  userSupport: {
    url: `/support`,
    bodyFunction: handleSendReport,
    asyncActions: {
      init: "userSupport_INIT",
      success: "userSupport_SUCCESS",
      error: "userSupport_ERROR"
    }
  }
};

const initialState = () => {
  const localStore = window.localStorage.getItem("user");
  if (localStore == null) {
    return {
      auth: false,
      user: undefined,
      token: undefined,
      domains: undefined,
      hasList: undefined,
      list: undefined,
      loading: undefined,
      hasError: undefined
    };
  }
  const data = JSON.parse(localStore);
  const { user, token, list, domains } = data;
  return {
    auth: true,
    user,
    token,
    domains,
    hasList: true,
    list,
    loading: false,
    hasError: false
  };
};
export function Logout() {
  return dispatch => {
    dispatch({ type: "LOGOUT" });
    window.location.replace("/");
    window.localStorage.clear();
  };
}

export function userDetails(state = { ...initialState() }, action) {
  const { updateUser } = Resources;
  const { changePassword } = Resources;
  const { setDefaultDomain } = Resources;
  const { init, success, error } = Resources.getUserLogin.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const localUser = action.payload.data;
      window.localStorage.setItem("user", JSON.stringify(localUser));
      const defaultDomain = localUser.domains.filter(
        item => localUser.user.default_domain === item.id
      );
      if (defaultDomain.length > 0) {
        window.localStorage.setItem("domain", JSON.stringify(defaultDomain[0]));
      } else {
        window.localStorage.setItem(
          "domain",
          JSON.stringify(localUser.domains[0])
        );
      }
      const { user, token, domains } = action.payload.data;
      return {
        auth: true,
        user,
        token,
        domains,
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case updateUser.asyncActions.success: {
      window.localStorage.setItem("user", JSON.stringify(action.payload.data));
      const { user, token } = action.payload.data;
      return {
        ...state,
        user,
        token
      };
    }

    case changePassword.asyncActions.success: {
      window.localStorage.setItem("user", JSON.stringify(action.payload.data));
      const { user, token } = action.payload.data;
      return {
        ...state,
        user,
        token
      };
    }
    case setDefaultDomain.asyncActions.success: {
      window.localStorage.setItem("user", JSON.stringify(action.payload.data));
      const { user, token } = action.payload.data;
      return {
        ...state,
        user,
        token
      };
    }

    default: {
      return state;
    }
  }
}

export function userList(
  state = {
    list: undefined,
    links: undefined,
    status: undefined,
    statusText: undefined,
    meta: undefined,
    deleted: undefined,
    edited: undefined,
    hasList: undefined,
    loading: undefined,
    hasError: undefined
  },
  action
) {
  const { init, success, error } = Resources.getUsers.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function gurzuUserList(
  state = {
    list: undefined,
    links: undefined,
    status: undefined,
    statusText: undefined,
    meta: undefined,
    deleted: undefined,
    edited: undefined,
    hasList: undefined,
    loading: undefined,
    hasError: undefined
  },
  action
) {
  const { init, success, error } = Resources.getGurzuUsers.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}
export function userDomainDetails(state = { ...initialState }, action) {
  const { init, success, error } = Resources.getUserDetails.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }
    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function userProfileImage(state = { ...initialState }, action) {
  const { init, success, error } = Resources.getUserProfileImage.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }
    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}
