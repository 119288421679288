/* eslint-disable no-undef */
import ActionCable from "actioncable";

export function deviceDownloadStatus(
  url,
  updateDataFromWebSocket,
  showTicketToast,
  updateScreenShotDataFromWebSocket,
  updateNotificationCountFromWebSocket,
  updateHeaderNotification
) {
  const cable = ActionCable.createConsumer(url);
  const getDomain = window.localStorage.getItem("domain");
  if (getDomain === "undefined") {
    return;
  }
  const domain = JSON.parse(getDomain);
  cable.subscriptions.create(
    { channel: `MessagesChannel`, domainID: domain.id },
    {
      connected() {
      },
      disconnected() { },
      received(data) {
        const socketData = data.message;
        const { ticket } = socketData;
        // check for brand notification
        if (socketData.type === "brand_notification") {
          updateHeaderNotification(data.message)
        }
        if (data.message.notification) {
          updateNotificationCountFromWebSocket(data.message.notification);
        }
        if (ticket) {
          showTicketToast(ticket);
        } else {
          const { status } = socketData;
          // updateDataFromWebSocket("downloaded", socketData);
          switch (status) {
            case "playing": {
              const {
                currentPlayingType,
                currentPlayingName,
                lastDownloadedType,
                lastDownloadedName,
                mac
              } = socketData;
              const newData = {
                mac,
                currently_playing: data.currentPlayingName,
                campaign_name:
                  currentPlayingType === "campaign" ? currentPlayingName : "",
                calendar_name:
                  currentPlayingType === "calendar" ? currentPlayingName : "",
                last_downloaded_type: lastDownloadedType,
                last_downloaded_name: lastDownloadedName
              };
              updateDataFromWebSocket("playing", newData);
              break;
            }
            case "stopped": {
              const { mac } = socketData;
              const newData = {
                mac,
                currently_playing: undefined,
                campaign_name: undefined,
                calendar_name: undefined
              };
              updateDataFromWebSocket("stopped", newData);
              break;
            }

            case "download": {
              const {
                totalContentToBeDownloaded,
                totalDownloaded,

                mac
              } = socketData;
              const newData = {
                total_downloaded: totalDownloaded,
                total_content_to_be_downloaded: totalContentToBeDownloaded,
                mac
              };
              updateDataFromWebSocket("downloaded", newData);
              break;
            }
            case "lastDownloaded": {
              const {
                lastDownloadedType,
                lastDownloadedName,
                mac
              } = socketData;
              const newData = {
                mac,
                last_downloaded_type: lastDownloadedType,
                last_downloaded_name: lastDownloadedName
              };
              updateDataFromWebSocket("downloaded", newData);
              break;
            }
            case "received": {
              const { receivedMediaType, receivedMediaName, mac } = socketData;
              const newData = {
                mac,
                received_media_type: receivedMediaType,
                received_media_name: receivedMediaName
              };
              updateDataFromWebSocket("downloaded", newData);
              break;
            }
            case "deviceStatus": {
              const { deviceStatus, mac } = socketData;
              const newData = { mac, status: deviceStatus };
              updateDataFromWebSocket("deviceStatus", newData);
              updateScreenShotDataFromWebSocket("deviceStatus", newData);
              break;
            }
            case "blockPop": {
              const { block_pop, mac } = socketData;
              const newData = { mac, block_pop };
              updateDataFromWebSocket("blockPop", newData);
              break;
            }
            case "appVersionInfo": {
              const { version, mac } = socketData;
              const newData = { mac, electron_version: version };
              updateDataFromWebSocket("appVersionInfo", newData);
              updateScreenShotDataFromWebSocket("appVersionInfo", newData);
              break;
            }
            default: {
              break;
            }
          }
        }
      }
    }
  );
}
