import { Campaign } from "../utls/campaign";

export const Resources = {
  localCampaign: {
    syncActions: {
      update: "localCampaign_update",
      reset: "localCampaign_delete"
    }
  }
};

export function updateCampaign(campaign) {
  const {
    syncActions: { update }
  } = Resources.localCampaign;
  return dispatch => {
    dispatch({ type: update, payload: { campaign } });
  };
}

export function resetCampaign() {
  const {
    syncActions: { reset }
  } = Resources.localCampaign;
  return dispatch => {
    dispatch({ type: reset, payload: { campaign: new Campaign() } });
  };
}

const initialState = {
  campaign: new Campaign()
};

export const newCampaign = (state = initialState, { type, payload }) => {
  const {
    syncActions: { update, reset }
  } = Resources.localCampaign;
  switch (type) {
    case update:
      return { ...payload };

    case reset: {
      return { ...payload };
    }

    default:
      return state;
  }
};
