/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";

export default class RadioButton extends Component {
  componentDidMount = () => {
    this.props.input.onChange("Landscape");
  };

  render() {
    return (
      <div className="radiobutton">
        <label className="radiobutton__title">Orientation</label>
        <div className="d-flex">
          <div className="radio">
            <input
              id="radio-1"
              checked={this.props.input.value === "Landscape"}
              onChange={() => this.props.input.onChange("Landscape")}
              name="radio"
              type="radio"
            />

            <label htmlFor="radio-1" className="radio-label">
              Landscape
            </label>
          </div>

          <div className="radio">
            <input
              id="radio-2"
              name="radio"
              checked={this.props.input.value === "Potrait"}
              onChange={() => this.props.input.onChange("Potrait")}
              type="radio"
            />
            <label htmlFor="radio-1" className="radio-label">
              Potrait
            </label>
          </div>
        </div>
      </div>
    );
  }
}
