/* eslint-disable no-underscore-dangle */
export function SecondAdded(contents) {
  let total = 0;
  function toMinuteSecond(seconds) {
    return {
      minute: parseInt(Number(seconds) / 60, 10),
      second: parseInt(Number(seconds) % 60, 10)
    };
  }
  contents.map(item => {
    if (item.duration) {
      const {
        duration: { minute, second }
      } = item;
      if (
        [
          "Weather",
          "Text",
          "URL",
          "HLS",
          "RSS",
          "DateTime",
          "Youtube",
          "PicToScreen",
          "Route"
        ].includes(item.type) &&
        item.destroy === 1
      ) {
        return 0;
      }
      if (item.type === "playlist" && item.usePlaylistDuration) {
        total += item.estimatedDuration;
        return 0;
      }

      if (minute === undefined) {
        total = total + minute * 60 + second;
        return 0;
      }

      if (second === undefined) {
        total = total + minute * 60 + second;
        return 0;
      }

      total = total + minute * 60 + second;
      return 0;
    }
  });

  return toMinuteSecond(total);
}

/**
 *
 * @param {*} contents
 * @param {*} contentID
 * URLWidgetID
 * textWidgetID
 * weatherWidgetID
 */

export function removeContentFromContentList(contents, contentID) {
  const newContent = [];
  contents.map(content => {
    if (contentID === content.contentLocalID && content.type === "content") {
      return 0;
    }
    if (contentID === content.contentLocalID && content.type === "playlist") {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "URL" &&
      content.URLWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "URL" &&
      content.URLWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "HLS" &&
      content.HLSWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "HLS" &&
      content.HLSWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "Weather" &&
      content.weatherWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "Weather" &&
      content.weatherWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "Text" &&
      content.textWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "Text" &&
      content.textWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "RSS" &&
      content.RSSWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "RSS" &&
      content.RSSWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "DateTime" &&
      content.DateTimeWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "DateTime" &&
      content.DateTimeWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "Youtube" &&
      content.YoutubeWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "Youtube" &&
      content.YoutubeWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }

    if (
      contentID === content.contentLocalID &&
      content.type === "PicToScreen" &&
      content.PicToScreenWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "PicToScreen" &&
      content.PicToScreenWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }

    if (
      contentID === content.contentLocalID &&
      content.type === "Route" &&
      content.RouteWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "Route" &&
      content.RouteWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }

    if (
      contentID === content.contentLocalID &&
      content.type === "Transparent" &&
      content.TransparentWidgetID === undefined
    ) {
      return 0;
    }
    if (
      contentID === content.contentLocalID &&
      content.type === "Transparent" &&
      content.TransparentWidgetID !== undefined
    ) {
      const temp = content;
      temp.destroy = 1;
      newContent.push(temp);
      return 0;
    }

    newContent.push(content);
    return 0;
  });
  return newContent;
}

export function newCoordinate(x, y, height, width, stageWidth, stageHeight) {
  const newCord = { x, y };
  if (x <= 0) {
    newCord.x = 0;
  }
  if (y <= 0) {
    newCord.y = 0;
  }

  if (x + width >= stageWidth) {
    newCord.x = Math.abs(stageWidth - width);
  }

  if (y + height >= stageHeight) {
    newCord.y = Math.abs(stageHeight - height);
  }
  return { ...newCord };
}

export function totalSeconds(contents) {
  let total = 0;

  contents.map(item => {
    if (item.duration) {
      const {
        duration: { minute, second }
      } = item;
      if (
        [
          "Weather",
          "Text",
          "URL",
          "HLS",
          "RSS",
          "DateTime",
          "Youtube",
          "PicToScreen"
        ].includes(item.type) &&
        item.destroy === 1
      ) {
        return 0;
      }
      if (item.type === "playlist" && item.usePlaylistDuration) {
        total += item.estimatedDuration;
        return 0;
      }

      if (minute === undefined) {
        total = total + minute * 60 + second;
        return 0;
      }

      if (second === undefined) {
        total = total + minute * 60 + second;
        return 0;
      }

      total = total + Number(minute) * 60 + Number(second);
      return 0;
    }
  });

  return total;
}
