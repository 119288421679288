import { uniqBy } from "lodash";

export function createUniqueTags(formItems, bulkItems) {
  /** formItems is from from */
  /**
    Remove is bulk from formItems,
    Merge form items bulk item
    Run unique by
    return
  */

  bulkItems.forEach(item => {
    item.isBulk = true;
  });
  const filteredBulkItems = formItems.filter(item => !item.isBulk);
  const newTags = [...filteredBulkItems, ...bulkItems];
  const allTags = uniqBy(newTags, "value");
  return allTags;
}

export const unitConvert = item => {
  const unitSize = item && parseFloat(item.split(" ")[0]);
  const unitByte = item && changeUnit(item.split(" ")[1]);
  return `${unitSize} ${unitByte}`;
};

export const changeUnit = item => {
  switch (item) {
    case "B":
      return "B";
    case "KiB":
      return "KB";
    case "MiB":
      return "MB";
    case "GiB":
      return "GB";
    case "TiB":
      return "TB";
    case "PiB":
      return "PB";
    case "EiB":
      return "EB";
    default:
  }
};
