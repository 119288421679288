import React, { Component } from "react";
import { reduxForm, Field, SubmissionError } from "redux-form";
import {
  Label,
  FormGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form
} from "reactstrap";
import { connect } from "react-redux";
import { remove, toLower } from "lodash";
import { required, email } from "../../../helper/validation";
import { Resources, generateUserUpdate } from "../redux";
import { Resources as DomainData } from "../redux/domainRedux";
import { Input } from "../../../core";
import { createUser } from "../redux/loginRedux";
import { initRoles } from "../utils/const";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.props.Get(DomainData.getDomains);
    this.state = {
      currentDomain: JSON.parse(window.localStorage.getItem("domain")),
      roles: [
        { id: 192, value: "General", name: "General", checked: true },
        {
          id: 194,
          value: "ContentManager",
          name: "Content Manager",
          checked: false
        },
        {
          id: 422,
          value: "CampaignManager",
          name: "Campaign Manager",
          checked: false
        },
        {
          id: 123,
          value: "NetworkManager",
          name: "Network Manager",
          checked: false
        },
        {
          id: 143,
          value: "CalendarManager",
          name: "Calendar Manager",
          checked: false
        },
        {
          id: 145,
          value: "PlaylistManager",
          name: "Playlist Manager",
          checked: false
        },
        {
          id: 432,
          value: "ReportManager",
          name: "Report Manager",
          checked: false
        },
        { id: 400, value: "Admin", name: "Admin", checked: false },
        { id: 233, value: "SuperAdmin", name: "Super Admin", checked: false }
      ],
      roleError: false,
      edit: false,
      id: null
    };
  }

  componentDidMount() {
    const { path } = this.props.match;
    const { params } = this.props.location;
    if (path === "/dashboard/edituser/:id" && params) {
      const { roles } = this.state;
      const userRoles = roles.map(item => {
        if (params.attributes.roles.includes(item.value)) {
          const temp = item;
          temp.checked = true;
          return temp;
        }
        const temp = item;
        temp.checked = false;
        return temp;
      });
      this.setState({
        roles: userRoles,
        edit: true,
        id: params.id
      });
      const api = [];
      const { getUserDetails } = Resources;
      getUserDetails.url = `/users/${params.id}`;
      api.push(this.props.Get(getUserDetails));
      Promise.all([...api]).then(() => {
        this.props.initialize({
          name: params.attributes.name,
          email: params.attributes.email
          // domains: this.showDomainOptions()
        });
      });
    } else if (path === "/dashboard/edituser/:id" && !params) {
      this.props.history.goBack();
    }
  }

  reset = () => {
    this.setState({
      roles: [
        { id: 192, value: "General", name: "General", checked: true },
        {
          id: 194,
          value: "ContentManager",
          name: "Content Manager",
          checked: false
        },
        {
          id: 422,
          value: "CampaignManager",
          name: "Campaign Manager",
          checked: false
        },
        {
          id: 123,
          value: "NetworkManager",
          name: "Network Manager",
          checked: false
        },
        {
          id: 143,
          value: "CalendarManager",
          name: "Calendar Manager",
          checked: false
        },
        {
          id: 145,
          value: "PlaylistManager",
          name: "Playlist Manager",
          checked: false
        },
        {
          id: 432,
          value: "ReportManager",
          name: "Report Manager",
          checked: false
        },
        { id: 400, value: "Admin", name: "Admin", checked: false },
        { id: 233, value: "SuperAdmin", name: "Super Admin", checked: false }
      ]
    });
  };

  onRoleChange = ({ target: { name, checked } }) => {
    const { roles } = this.state;
    if (name === "Super Admin" && checked) {
      this.setState({
        roles: roles.map(item => {
          const temp = item;
          temp.checked = true;
          return temp;
        })
      });
      return 0;
    }

    roles.filter(role => {
      if (role.name === name) {
        const temp = role;
        temp.checked = checked;
        return temp;
      }
      return role;
    });
    this.setState({
      roles,
      roleError: false
    });
  };

  handleSubmit = async values => {
    this.props.closeAddUserModal();
    this.props.setLoading(true);
    const domain = JSON.parse(window.localStorage.getItem("domain"));
    const formValues = values;
    formValues.roles = [];
    this.state.roles.map(role => {
      if (role.checked) {
        formValues.roles.push(role.value);
      }
    });

    if (formValues.roles.length === 0) {
      this.setState({
        roleError: true
      });
      return 0;
    }
    if (this.props.action === "edit") {
      const { updateUserRoles } = Resources;
      updateUserRoles.url = `/users/${this.props.contentToBeEdited.id}`;
      updateUserRoles.body = generateUserUpdate(
        this.props.contentToBeEdited.id,
        {
          data: formValues
        }
      );
      const result = await this.props.Put(updateUserRoles);
      if (result.status === 200) {
        this.props.handleUsersList(this.props.meta.page);
        this.props.handleUserCreate(false);
        this.props.setLoading(false);
        this.props.handleUserCreate(false, this.props.action);
        this.reset();
        return 0;
      }
      if (result.data.hasOwnProperty("error")) {
        this.setLoading(false);
        this.props.handleUserCreate(false, this.props.action);
        this.reset();
        return 0;
      }
      throw new SubmissionError({
        email: "Something Went Wrong"
      });
    } else {
      const { addUser } = Resources;
      addUser.body = createUser({ formValues, domain });
      const result = await this.props.Post(addUser);
      if (result.status === 201) {
        this.reset();
        this.props.handleUsersList(this.props.meta.page);
        this.props.handleUserCreate(false, this.props.action);
        return 0;
      }
      if (result.data.hasOwnProperty("error")) {
        this.props.handleUsersList(this.props.meta.page);
        this.reset();
        this.props.handleUserCreate(false, this.props.action);
      }
    }
  };

  renderRoles() {
    const { roles } = this.state;
    if (toLower(this.state.currentDomain.name) !== "gurzu") {
      remove(roles, { name: "Super Admin" });
    }
    return roles.map(role => (
      <Col md="4" key={role.id}>
        <FormGroup check inline>
          <Label className="checkBoxWrapper">
            <input
              type="checkbox"
              name={role.name}
              onChange={this.onRoleChange}
              checked={role.checked}
            />
            <p className="mt-1">{role.name}</p>
            <span className="checkmark" />
          </Label>
        </FormGroup>
      </Col>
    ));
  }

  handleCancel = () => {
    this.reset();
    this.props.handleUserCreate(false, this.props.action);
  };

  render() {
    const { handleSubmit, domainList } = this.props;
    return (
      <Modal
        isOpen={this.props.createUserModal}
        toggle={() => this.props.handleUserCreate(false)}
        className="editContentModal"
      >
        <ModalHeader
          toggle={() => {
            this.reset();
            this.props.handleUserCreate(false);
          }}
        >
          {this.props.action === "edit" ? "Edit User" : "Add User"}
          {/* {this.props.action === "create " ? "Edit Domain" : "Add Domain"} */}
        </ModalHeader>
        <ModalBody>
          <Form className="validate-form">
            <div className="globalForm">
              <FormGroup>
                <div className="globalInput form-validation-arrange adduserinput">
                  <Field
                    component={Input}
                    name="name"
                    type="text"
                    validate={required}
                    placeholder="Name"
                    label="Name"
                    inputClass="mt-2"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div
                  className="globalInput form-validation-arrange adduserinput validate-input"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <Field
                    component={Input}
                    name="email"
                    type="email"
                    placeholder="Email"
                    validate={[required, email]}
                    label="Email"
                    autoComplete="off"
                    disabled={this.props.action === "edit"}
                    inputClass="mt-2"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                {this.props.action !== "edit" && (
                  <div className="globalInput form-validation-arrange adduserinput">
                    <Label>Roles</Label>
                    <Row>
                      {this.renderRoles()}
                      {this.state.roleError && (
                        <small style={{ color: "red" }}>
                          At least One role is required.
                        </small>
                      )}
                    </Row>
                  </div>
                )}
              </FormGroup>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button className="btn-outline-primary" onClick={this.handleCancel}>
            Cancel
          </button>
          <button
            className="primaryButton mt-0"
            type="submit"
            onClick={handleSubmit(values => this.handleSubmit(values))}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

const AddUserComponent = reduxForm({
  form: "AddUserForm"
})(AddUser);

function mapStateToProps(state) {
  const currentUser = state.userDomainDetails.list;
  const { list } = state.domainList;
  return { domainList: list || [], currentUser: currentUser || [] };
}
export default connect(
  mapStateToProps,
  null
)(AddUserComponent);
