/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import {
  reduxForm,
  formValueSelector,
  initialize as remoteInitialize
} from "redux-form";
import { remove, debounce, capitalize } from "lodash";

//  styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tooltip } from "../../../core";
import NetworkGroupDeviceTable from "./networkGroupDeviceTable";
import DeviceGroupControlView from "./deviceGroupControlView";
import { Resources, updateGroupInfiniteScroll } from "../redux/networkRedux";
import { Resources as DeviceResources } from "../redux/deviceRedux";
import { Get, Patch } from "../../../constant/thunk";
import NetworkListView from "./networkListView";
import { timeZones } from "../../../constant/timeZoneNUCs";
import { resolutions } from "../../../constant/resolution";

class NetworkListTable extends Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      mac_address: { active: false, sortBy: "desc" },
      currently_playing: { active: false, sortBy: "desc" },
      device_group_name: { active: false, sortBy: "desc" },
      hostname: { active: false, sortBy: "desc" },
      last_downloaded_name: { active: false, sortBy: "desc" },
      orientation: { active: false, sortBy: "desc" },
      status: { active: false, sortBy: "desc" },
      electron_version: { active: false, sortBy: "desc" },
      timezone: { active: false, sortBy: "desc" },
      resolution: { active: false, sortBy: "desc" },
      block_pop: { active: false, sortBy: "desc" }
    };
    this.searchDevice = React.createRef();
    this.state = {
      forcePage: undefined,
      sendMediaModal: false,
      sortValue: { isSortable: false },
      sortOrder: this.initialSortOrder,
      addToGroupModal: 0,
      isRestrating: false,
      isRebooting: false,
      isUpdating: false,
      isSendingEnv: false,
      isSendingRes: false,
      isSendingTimeZone: false,
      isSendingOrientation: false,
      perPage: 20,
      sendingEnvironment: false,
      action: "",
      createGroupModal: false,
      searchVal: "",
      selectedSingleDevice: [],
      isGroupOpen: true,
      defaultCampaignSubmitModal: false,
      searchVal: "",
      enableNotification: false,
      notificationMessage: "",
      deviceControlModalFromList: false,
      isSendingPopRequest: false
    };
    this.devicesPerPage = 20;
    this.networkListTableRefs = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetSort === true) {
      this.setState({ deviceSortOrder: this.initialDeviceSortOrder });
      this.props.toggleSort(false);
    }
  }

  handleSort = name => {
    this.setState({ forcePage: 0 });
    this.props.handleListNetworkLoading(true);
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getNetwork } = Resources;
        getNetwork.url = `/devices/devices_list?sort=${name}&sort_by=${
          this.state.sortOrder[name].sortBy
        }&page=${1}&per_page=${this.perPageCount}`;
        this.props
          .Get(Resources.getNetwork)
          .then(() => this.props.handleListNetworkLoading(false));
      }
    );
  };

  toggleGroup = () => {
    this.setState(prevState => ({
      isGroupOpen: !prevState.isGroupOpen
    }));
  };

  handleListSearchValue = value => {
    this.setState({ searchVal: value });
    this.handleListSearch();
  };

  handleListSearch = debounce(() => {
    const { getNetwork } = Resources;
    this.setState({ deviceSortOrder: this.initialDeviceSortOrder });
    if (this.state.searchVal === "") {
      getNetwork.url = `/devices/devices_list?search=${
        this.state.searchVal
      }&page=${1}&per_page=${this.state.perPage}`;
    } else {
      getNetwork.url = `/devices/search_devices_list?search=${
        this.state.searchVal
      }&page=${1}&per_page=${this.state.perPage}`;
    }
    this.props.Get(getNetwork);
  }, 3000);

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.props.handleListNetworkLoading(true);
    if (moveToPrevious) {
      Resources.getNetwork.url = `/devices/devices_list?page=${data.page -
        1}&per_page=${this.devicesPerPage}`;
      this.props.Get(Resources.getNetwork).then(response => {
        this.setState({ forcePage: data.page - 2 });
        this.props.handleListNetworkLoading(false);
      });
    } else {
      this.setState({ forcePage: undefined });
      this.props.handleListNetworkLoading(true);
      const { selected } = data;
      if (this.state.searchVal) {
        Resources.getNetwork.url = `/devices/search_devices_list?search=${
          this.state.searchVal
        }&page=${selected + 1}&per_page=${this.devicesPerPage}`;
      } else if (this.state.sortValue.isSortable) {
        Resources.getNetwork.url = `/devices/devices_list?sort=${
          this.state.sortValue.name
        }&sort_by=${this.state.sortValue.sortBy}&page=${selected +
          1}&per_page=${this.devicesPerPage}`;
      } else {
        Resources.getNetwork.url = `/devices/devices_list?page=${selected +
          1}&per_page=${this.devicesPerPage}`;
      }
      this.props
        .Get(Resources.getNetwork)
        .then(() => this.props.handleListNetworkLoading(false))
        .then(() => this.setState({ forcePage: selected }));
    }
  };

  /** Toggle send media modal */
  toggleSendToMediaModal = () => {
    this.setState(prevState => ({ sendMediaModal: !prevState.sendMediaModal }));
  };

  toggleAddToGroupModal = data => {
    this.setState({ addToGroupModal: data });
  };

  toggledeviceControlModal = data => {
    if (data === "cancel") {
      this.clearSelectedListDevices();
      this.setState({ deviceControlModalFromList: false });
      return true;
    }
    this.setState({ deviceControlModalFromList: data });
    this.handleLoadAllTags();
    this.props.handleLoadAllNetworkGroup();
  };

  handleDeviceGroupState = () => {
    this.setState({ deviceGroupPage: 1 });
    this.forceUpdate();
  };

  /* run campaign and calendar to devices */
  handleSubmitNetworkSelector = (devices, campaign) => {
    this.setState((state, props) => ({
      enableNotification: true,
      isUpdating: true,
      notificationMessage: `Sending ${campaign.metaOptions.attributes.name} Campaign to Devices`
    }));
    const { playCampaignInNetwork } = Resources;
    const request = playCampaignInNetwork(campaign.value, "run");
    request.body = JSON.stringify({
      _jsonapi: {
        data: {
          type: "campaigns",
          attributes: {
            device_ids: devices.map(item => item.id)
          }
        }
      }
    });
    this.props
      .Post(request)
      .then(() => {
        this.props.change("deviceSelectedInListView", []);
        this.setState({
          enableNotification: false,
          notificationMessage: ""
        });
      })
      .then(() => {
        this.networkListTableRefs.current.selectionContext.selected = [];
        this.networkListTableRefs.current.setState({});
      });
  };

  handleSubmitNetworkCalendarSelector = (devices, calendar) => {
    this.setState((state, props) => ({
      enableNotification: true,
      isUpdating: true,
      notificationMessage: `Sending ${calendar.metaOptions.attributes.name} Calendar to Devices`
    }));
    const { playCalendarInNetwork } = Resources;
    const request = playCalendarInNetwork(calendar.value, "run");
    request.body = JSON.stringify({
      _jsonapi: {
        data: {
          type: "calendar",
          attributes: {
            device_ids: devices.map(item => item.id)
          }
        }
      }
    });
    this.props
      .Post(request)
      .then(() => {
        this.props.change("deviceSelectedInListView", []);
        this.setState({
          enableNotification: false,
          notificationMessage: ""
        });
      })
      .then(() => {
        this.networkListTableRefs.current.selectionContext.selected = [];
        this.networkListTableRefs.current.setState({});
      });
  };

  /* ------- */
  /* stop bulk list devices */

  stopBulkListDevices = async (id, values, status) => {
    const request = DeviceResources.stopBulkListDevices;
    request.body = request.bodyFunction(values);
    this.setState(() => ({
      enableNotification: true,
      notificationMessage: `Sending Stop Signal to Devices`
    }));
    if (status === "advance") {
      this.props.Patch(request).then(() => {
        this.setState(() => ({
          enableNotification: false,
          notificationMessage: ""
        }));
      });
    } else {
      this.props
        .Patch(request)
        .then(() => {
          this.props.change("deviceSelectedInListView", []);
          this.setState({
            enableNotification: false,
            notificationMessage: ""
          });
        })
        .then(() => {
          this.networkListTableRefs.current.selectionContext.selected = [];
          this.networkListTableRefs.current.setState({});
        });
    }
  };

  /** restart mps bulk devices */
  restartGroupDevices = (id, devices) => {
    this.toggledeviceControlModal(false);
    const request = DeviceResources.restartBulkListMPS;
    request.body = request.bodyFunction(devices);
    this.setState((state, props) => ({
      isRestrating: true,
      enableNotification: true,
      notificationMessage: "Restarting MPS"
    }));
    this.props.Patch(request).then(() => {
      this.setState((state, props) => ({
        isRestrating: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedListDevices();
    });
  };

  /** *restart Devices  ** */

  rebootGroupDevices = async (id, devices) => {
    this.toggledeviceControlModal(false);
    const request = DeviceResources.restartBulkListDevices;
    request.body = request.bodyFunction(devices);
    this.setState({
      enableNotification: true,
      isRebooting: true,
      notificationMessage: "Restarting Devices"
    });
    this.props.Patch(request).then(() => {
      this.setState(() => ({
        isRebooting: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedListDevices();
    });
  };
  /* resync devices */

  resyncGroupDevices = (id, values) => {
    this.toggledeviceControlModal(false);
    const request = DeviceResources.resyncBulkListDevices;
    // console.error("asas", request);
    request.body = request.bodyFunction(values);
    this.setState({
      enableNotification: true,
      notificationMessage: "Syncing Media to Devices"
    });
    this.forceUpdate();
    this.props.Patch(request).then(() => {
      this.clearSelectedListDevices();
    });
    // this.refreshNetworkList();
  };

  /* adjust bulk volume */
  handleGroupVolume = (id, values, volume) => {
    this.toggledeviceControlModal(false);
    this.setState({
      enableNotification: true,
      notificationMessage: "Updating the volume of Devices"
    });
    const request = DeviceResources.updateBulkListDeviceVolume;
    request.body = request.bodyFunction(values, volume);
    this.props.Patch(request).then(() => {
      this.clearSelectedListDevices();
    });
  };

  /** RS232 commands requeest */
  triggerRS232Command = (id, action, devices) => {
    this.toggledeviceControlModal(false);
    this.setState({
      enableNotification: true,
      notificationMessage: `Sending ${action} signal to RS232`
    });
    const request = DeviceResources.updateBulkListDevicesRS232;
    request.body = request.bodyFunction(devices, action);
    this.props.Patch(request).then(() => {
      this.setState({
        enableNotification: false,
        notificationMessage: ""
      });
      this.clearSelectedListDevices();
    });
  };

  /** update electron environment */
  updateGroupDevices = async (id, devices, tag) => {
    const request = DeviceResources.updateBulkListDevicesElectronEnv;
    request.body = request.bodyFunction(devices, tag);
    this.setState({
      enableNotification: true,
      isUpdating: true,
      updatingGroup: id,
      notificationMessage: `Sending ${tag} Version`
    });
    this.setState({ isUpdating: true });
    this.props.Patch(request).then(() => {
      this.props.remoteInitialize("DeviceGroupForm", {
        electronTag: [],
        electronEnv: []
      });
      this.setState(() => ({
        isUpdating: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedListDevices();
    });
  };

  legacyUpdateGroupDevices = async (id, devices, tag) => {
    const request = DeviceResources.updateBulkListDevicesLegacyEnv;
    request.body = request.bodyFunction(devices, tag);
    this.setState((state, props) => ({
      enableNotification: true,
      isUpdating: true,
      notificationMessage: `Sending ${tag} Legacy Version`
    }));
    this.props.Patch(request).then(() => {
      this.props.remoteInitialize("DeviceGroupForm", {
        electronTag: [],
        electronEnv: []
      });
      this.setState(() => ({
        isUpdating: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedListDevices();
    });
  };
  /* send environment */

  sendGroupElectronAppEnvironment = async (id, devices, env) => {
    const request = DeviceResources.updateBulkListDevicesEnv;
    request.body = request.bodyFunction(devices, env);
    this.setState((state, props) => ({
      enableNotification: true,
      isSendingEnv: true,
      notificationMessage: `Sending ${env} Environment to Devices`
    }));
    this.props.Patch(request).then(() => {
      this.props.remoteInitialize("DeviceGroupForm", {
        electronTag: [],
        electronEnv: []
      });
      this.setState(() => ({
        isSendingEnv: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedListDevices();
    });
  };

  sendGroupResolution = async (id, devices, res) => {
    const request = DeviceResources.updateBulkListDevicesResolution;
    request.body = request.bodyFunction(devices, res);
    this.setState((state, props) => ({
      enableNotification: true,
      isSendingRes: true,
      notificationMessage: `Setting devices reolution to ${res}`
    }));
    this.props.Patch(request).then(() => {
      this.props.remoteInitialize("DeviceGroupForm", {
        resolution: []
      });
      this.setState(() => ({
        isSendingRes: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedListDevices();
    });
  };

  sendGroupOrientation = async (id, devices, orientation) => {
    const request = DeviceResources.updateBulkListDevicesOrientation;
    request.body = request.bodyFunction(devices, orientation);
    this.setState((state, props) => ({
      enableNotification: true,
      isSendingOrientation: true,
      notificationMessage: `Setting devices orientation to ${orientation} `
    }));
    this.props.Patch(request).then(() => {
      this.props.remoteInitialize("DeviceGroupForm", {
        orientation: {}
      });
      this.setState(() => ({
        isSendingOrientation: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedListDevices();
    });
  };

  sendGrouptimeZone = async (id, devices, timezone) => {
    const request = DeviceResources.updateBulkListDevicesTimeZone;
    request.body = request.bodyFunction(devices, timezone);
    this.setState((state, props) => ({
      enableNotification: true,
      isSendingTimeZone: true,
      notificationMessage: `Setting devices timezone to ${timezone}`
    }));
    this.props.Patch(request).then(() => {
      this.props.remoteInitialize("DeviceGroupForm", {
        timezone: []
      });
      this.setState(() => ({
        isSendingTimeZone: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedListDevices();
    });
  };

  clearSelectedListDevices = () => {
    if (this.state.searchVal) {
      Resources.getNetwork.url = `/devices/search_devices_list?search=${
        this.state.searchVal
      }&page=${1}&per_page=${20}`;
    } else {
      Resources.getNetwork.url = `/devices/devices_list?page=${1}&per_page=${20}`;
    }
    this.props
      .Get(Resources.getNetwork)
      .then(() => {
        this.props.change("deviceSelectedInListView", []);
        this.setState({
          enableNotification: false,
          notificationMessage: ""
        });
      })
      .then(() => {
        this.networkListTableRefs.current.selectionContext.selected = [];
        this.networkListTableRefs.current.setState({});
      });
    this.toggledeviceControlModal(false);
  };

  handleLoadAllTags = () => {
    const { getElectronTags, getLegacyElectronTags } = Resources;
    this.props.Get(getElectronTags);
    this.props.Get(getLegacyElectronTags);
  };

  handleAddAndChangeGroup = (values, devices) => {
    this.toggledeviceControlModal(false);
    this.handleDeviceGroupState();
    this.setState({
      enableNotification: true,
      notificationMessage: `Updating Device Group`
    });
    const { changeGroup, getSelectedGroup, getNetwork } = Resources;
    const { selectedGroup } = values;
    const id = selectedGroup.value;
    const request = changeGroup(id);
    // request.body = request.bodyFunction(this.props.selectedDeviceInGroup);
    request.body = request.bodyFunction(devices);
    this.props
      .Put(request)

      .then(() => {
        this.props.remoteInitialize("AddAndChangeGroupForm", {
          selectedGroup: []
        });
        this.setState({
          enableNotification: false,
          notificationMessage: ""
        });
        this.clearSelectedListDevices();
      });
    this.props.handleRefetch(true);
  };

  loadUnassignedDevices = () => {
    const { getUnassignedDevices } = Resources;
    this.props.Get(getUnassignedDevices);
  };

  toggleEditModal = flag => {
    this.setState({
      isModalOpen: flag
    });
  };

  addGroup = values => {
    this.handleCreate(values);
  };

  toggleDrawer = item => {
    this.toggledeviceControlModal(true);
    this.setSingleDevice(item);
    this.props.remoteInitialize("DeviceGroupForm", {
      volume: 85,
      timezone: timeZones.filter(
        timezone => timezone.value === item.attributes.timezone
      ),
      resolution: resolutions.filter(
        res => res.resolution === item.attributes.resolution
      ),
      orientation: item.attributes.orientation,
      electronEnv: {
        value: item.attributes.electron_environment,
        label: capitalize(item.attributes.electron_environment)
      },
      proofOfPlay: !item.attributes.block_pop
    });
  };

  toggleSendToDevice = item => {
    this.toggleSendToMediaModal(true);
    this.setSingleDevice(item);
  };

  setSingleDevice = item => {
    const singleDevice = [];
    singleDevice.push(item);
    this.setState({ selectedSingleDevice: singleDevice });
  };

  handleChange = item => {
    this.props.handleChange(item);
    this.setState({ selectedSingleDevice: [] });
  };

  handleProofOfPlay = (devices, flag) => {
    const devicesMacAddress = devices.map(
      device => device.attributes.mac_address
    );
    const request = Resources.bulkDevicesPopOnOff;
    request.body = request.bodyFunction(devicesMacAddress, flag);
    this.setState((state, props) => ({
      isSendingPopRequest: true,
      enableNotification: true,
      notificationMessage: `Turning pop data ${!flag ? "on" : "off"}`
    }));
    this.props.Patch(request).then(() => {
      this.setState((state, props) => ({
        isSendingPopRequest: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedListDevices();
    });
  };

  render() {
    if (this.props.isLoading || this.state.isLoading) {
      return (
        <div
          className="d-block justify-content-center text-center"
          style={{ marginTop: "100px" }}
        >
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    return (
      <div>
        <NetworkListView
          forwardRef={this.networkListTableRefs}
          // devicesList={this.props.devicesList}
          openGlobalModal={this.props.openGlobalModal}
          closeGlobalModal={this.closeGlobalModal}
          stopPlayingDevice={this.props.stopPlayingDevice}
          handleSort={this.handleSort}
          isListNetworkLoading={this.props.isListNetworkLoading}
          sortOrder={this.state.sortOrder}
          forcePage={this.state.forcePage}
          handlePageClick={this.handlePageClick}
          meta={this.props.meta}
          campaignList={this.props.campaignList}
          calendarList={this.props.calendarList}
          deviceGroups={this.props.deviceGroups}
          handleDeleteCallback={this.props.handleChange}
          handleSendCampaignToPlayer={this.handleSubmitNetworkSelector}
          handleSendCalendarToPlayer={this.handleSubmitNetworkCalendarSelector}
          modalSendToMedia={this.state.sendMediaModal}
          modalAddGroupModal={this.state.addToGroupModal}
          deviceControlModal={this.state.deviceControlModalFromList}
          toggleSendToMediaModal={this.toggleSendToMediaModal}
          toggleAddToGroupModal={this.toggleAddToGroupModal}
          toggledeviceControlModal={this.toggledeviceControlModal}
          deviceList={this.props.deviceSelectedInListView}
          unassignedDevicesList={this.props.unassignedDevicesList}
          handleGroupVolume={this.handleGroupVolume}
          triggerRS232Command={this.triggerRS232Command}
          stopGroupDevices={this.stopBulkListDevices}
          resyncGroupDevices={this.resyncGroupDevices}
          restartGroupDevices={this.restartGroupDevices}
          isRestrating={this.state.isRestrating}
          isRebooting={this.state.isRebooting}
          isUpdating={this.state.isUpdating}
          isSendingEnv={this.state.isSendingEnv}
          isSendingRes={this.state.isSendingRes}
          isSendingOrientation={this.state.isSendingOrientation}
          isSendingTimeZone={this.state.isSendingTimeZone}
          rebootGroupDevices={this.rebootGroupDevices}
          updateGroupDevices={this.updateGroupDevices}
          legacyUpdateGroupDevices={this.legacyUpdateGroupDevices}
          handleAddAndChangeGroup={this.handleAddAndChangeGroup}
          handleLoadNetworkGroups={this.props.handleLoadNetworkGroups}
          refreshing={this.props.refreshing}
          Get={this.props.Get}
          Put={this.props.Put}
          Post={this.props.Post}
          Patch={this.props.Patch}
          match={this.props.match}
          getUserSetting={this.props.getUserSetting}
          sendingEnvironment={this.state.sendingEnvironment}
          sendGroupElectronAppEnvironment={this.sendGroupElectronAppEnvironment}
          sendGroupResolution={this.sendGroupResolution}
          sendGroupOrientation={this.sendGroupOrientation}
          sendGrouptimeZone={this.sendGrouptimeZone}
          handleGroupCreate={this.handleGroupCreate}
          handleGroupDefaultCampaignSave={this.handleGroupDefaultCampaignSave}
          action={this.state.action}
          createGroupModal={this.state.createGroupModal}
          defaultCampaignSubmitModal={this.state.defaultCampaignSubmitModal}
          addGroup={this.addGroup}
          electronTags={this.props.electronTags}
          handleRefresh={this.props.handleRefresh}
          handleSystemRefresh={this.props.handleSystemRefresh}
          updateGroupDeviceAfterGroupChange={
            this.updateGroupDeviceAfterGroupChange
          }
          refetchCampaign={this.props.refetchCampaign}
          setSelectedGroup={this.props.setSelectedGroup}
          handleRefetch={this.props.handleRefetch}
          handleDeviceGroupState={this.handleDeviceGroupState}
          handleLoadNetworkList={this.props.handleLoadNetworkList}
          setUserSetting={this.props.setUserSetting}
          handleSearch={this.handleListSearchValue}
          toggleDrawer={this.toggleDrawer}
          toggleSendToDevice={this.toggleSendToDevice}
          change={this.props.change}
          history={this.props.history}
          handleProofOfPlay={this.handleProofOfPlay}
          isSendingPopRequest={this.state.isSendingPopRequest}
          roles={this.props.roles}
        />
        <div style={{ display: "none" }}>
          {!this.props.deviceSelectedInListView.length > 0 && (
            <DeviceGroupControlView
              updateGroupDeviceAfterGroupChange={
                this.updateGroupDeviceAfterGroupChange
              }
              campaignList={this.props.campaignList}
              calendarList={this.props.calendarList}
              deviceGroups={this.props.deviceGroups}
              handleDeleteCallback={this.handleChange}
              handleSendCampaignToPlayer={this.handleSubmitNetworkSelector}
              handleSendCalendarToPlayer={
                this.handleSubmitNetworkCalendarSelector
              }
              selectedDevices={this.state.selectedSingleDevice}
              modalSendToMedia={this.state.sendMediaModal}
              modalAddGroupModal={this.state.addToGroupModal}
              deviceControlModal={this.state.deviceControlModalFromList}
              toggleSendToMediaModal={this.toggleSendToMediaModal}
              toggleAddToGroupModal={this.toggleAddToGroupModal}
              toggledeviceControlModal={this.toggledeviceControlModal}
              unassignedDevicesList={this.props.unassignedDevicesList}
              handleGroupVolume={this.handleGroupVolume}
              triggerRS232Command={this.triggerRS232Command}
              stopGroupDevices={this.stopBulkListDevices}
              resyncGroupDevices={this.resyncGroupDevices}
              restartGroupDevices={this.restartGroupDevices}
              isRestrating={this.state.isRestrating}
              isRebooting={this.state.isRebooting}
              isUpdating={this.state.isUpdating}
              isSendingEnv={this.state.isSendingEnv}
              isSendingRes={this.state.isSendingRes}
              isSendingOrientation={this.state.isSendingOrientation}
              isSendingTimeZone={this.state.isSendingTimeZone}
              rebootGroupDevices={this.rebootGroupDevices}
              updateGroupDevices={this.updateGroupDevices}
              legacyUpdateGroupDevices={this.legacyUpdateGroupDevices}
              handleAddAndChangeGroup={this.handleAddAndChangeGroup}
              handleLoadNetworkGroups={this.props.handleLoadNetworkGroups}
              Get={this.props.Get}
              Put={this.props.Put}
              Post={this.props.Post}
              getUserSetting={this.props.getUserSetting}
              sendingEnvironment={this.state.sendingEnvironment}
              sendGroupResolution={this.sendGroupResolution}
              sendGroupOrientation={this.sendGroupOrientation}
              sendGrouptimeZone={this.sendGrouptimeZone}
              sendGroupElectronAppEnvironment={
                this.sendGroupElectronAppEnvironment
              }
              handleGroupCreate={this.handleGroupCreate}
              action={this.state.action}
              createGroupModal={this.state.createGroupModal}
              defaultCampaignSubmitModal={this.state.defaultCampaignSubmitModal}
              addGroup={this.addGroup}
              handleRefresh={this.props.handleRefresh}
              handleSystemRefresh={this.props.handleSystemRefresh}
              increaseCampaignCount={this.props.increaseCampaignCount}
              refetchCampaign={this.props.refetchCampaign}
              handleRefetch={this.props.handleRefetch}
              handleDeviceGroupState={this.handleDeviceGroupState}
              updatingGroup={this.state.updatingGroup}
              handleProofOfPlay={this.handleProofOfPlay}
              isSendingPopRequest={this.state.isSendingPopRequest}
              {...this.props}
            />
          )}
        </div>
        {this.state.enableNotification && (
          <div
            id="notification_toast-flash"
            className="notification_toast info animated fadeInUp"
          >
            <p>
              {this.state.notificationMessage}{" "}
              <i className="fa fa-spinner fa-spin" />
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    // const selector = formValueSelector("deviceSelectFormForGroup");
    const { meta } = state.networks;
    const selectedGroup = state.selectedGroup.list || [];
    const selectedGroupMeta = state.selectedGroup.meta;
    const { unassignedDevicesList } = state.unAssignedDevices;
    const selector = formValueSelector("SelectedDeviceInListView");
    return {
      unassignedDevicesList: unassignedDevicesList || [],
      deviceSelectedInListView:
        selector(state, "deviceSelectedInListView") || [],
      devicesList:
        state.networks.formatedList && state.networks.formatedList.devices
          ? Object.values(state.networks.formatedList.devices)
          : [],
      // selectedDeviceInGroup: selector(state, "selectedDevicesInGroup") || [],
      // groupSelected: selector(state, "selectedGroup") || props.deviceGroups[0],
      meta,
      devices: selectedGroup,
      selectedGroupMeta
    };
  },
  { Patch, Get, remoteInitialize, updateGroupInfiniteScroll }
)(
  reduxForm({
    form: "SelectedDeviceInListView"
  })(NetworkListTable)
);
