/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { remove, uniq } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { required } from "../../../helper/validation";

import { MultipleSelect } from "../../../core/form/searchSelect";

import { NetworkTagResources } from "../redux/TagReducer";

// import PlayCampaign from "./playCampaign";
// import StatusButton from "../../../core/buttons/statusbutton";
import { SearchTagRefresh, Tooltip, Input } from "../../../core";
import { Resources } from "../redux/networkRedux";
import SendToDevice from "./sendToDeviceModal";
import DeviceGroupControlView from "./deviceGroupControlView";
import SelectAsync from "../../../core/form/selectAsyncClass";
import { Resources as campaignResources } from "../../campaign/redux";
// import VisibleColumnToggler from "../../../helper/visibleColumnToggler";
import { CustomToggleList } from "../utls/customToggle";

const CampaignListUI = () => (
  <React.Fragment>
    <FormGroup>
      <div className="globalInput">
        <Label>Select Campaign</Label>
        <Field component={SelectAsync} name="selectedDefaultCampaignForGroup" />
      </div>
    </FormGroup>
  </React.Fragment>
);

const CampaignList = reduxForm({
  form: "DefaultCampaignForGroup"
})(CampaignListUI);

class NetworkHeader extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-unused-expressions
    this.perPageCount = 20;
    this.state = {
      searchTag: [],
      searchByTagOptions: [],
      mediaPlayModal: false,
      dropdownOpen: false,
      resyncButton: false
    };
    this.loadTags();
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.node || this.parentNode) {
      if (this.node.contains(e.target) || this.parentNode.contains(e.target)) {
        return;
      }
      this.setState({
        resyncButton: false
      });
    }
  };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  triggerHelpDialog = () => {
    this.props.openGlobalModal({ ...dialogs.openHelp });
  };

  /** Toggle media play modal */
  toggleMediaPlayModal = () => {
    this.setState(state => ({ mediaPlayModal: !state.mediaPlayModal }));
  };

  clearSearchTag = () => {
    this.setState((state, props) => ({ searchTag: [] }));
  };

  handleSearchChange = value => {
    const { searchTag } = this.state;
    if (!searchTag.includes(value)) {
      searchTag.push(value);
      this.setState(
        prevState => ({ searchTag }),
        () => {
          this.props.handleSearch(this.state.searchTag.toString(), true);
        }
      );
    }
  };

  handleResync = () => {
    this.setState(prevState => ({
      resyncButton: !prevState.resyncButton
    }));
  };

  checkSuperAdmin = () => {
    const { roles } = JSON.parse(window.localStorage.getItem("domain"));
    return roles.includes("SuperAdmin");
  };

  loadTags = async () => {
    const { getNetwork } = NetworkTagResources;
    await this.props.Get(getNetwork);
    const searchByTagOptions = this.props.NetworkTag.map(i => ({
      value: i,
      label: i
    }));
    this.setState({ searchByTagOptions });
  };

  handleRemove = tag => {
    const { searchTag } = this.state;
    searchTag.splice(tag, 1);
    if (searchTag.length === 0) {
      this.props.handleSearch("", true);
    } else {
      this.setState({ searchTag }, () => {
        this.props.handleSearch(this.state.searchTag.toString(), true);
      });
    }
    this.props.forwardRef.current.state.value = "";
  };

  handleSystemRefresh = () => {
    this.setState({
      dropdownOpen: false,
      resyncButton: false
    });
    this.props.handleSystemRefresh();
  };

  handleDomainRefresh = () => {
    this.setState({
      dropdownOpen: false,
      resyncButton: false
    });
    this.props.handleRefresh();
  };

  handleGroupDefaultCampaignSubmit = (campaignId, groupId) => {
    const formattedGroup = {
      id: groupId,
      attributes: {
        name: this.props.selectedGroupName,
        default_campaign: this.props.selectedDefaultGroupCampaign.label
      }
    };
    this.props
      .Patch(campaignResources.setGroupDefaultCampaign(campaignId, groupId))
      .then(() => this.props.Get(Resources.getNetwork))
      .then(() => this.props.setSelectedGroup(formattedGroup))
      .then(() => this.props.handleGroupDefaultCampaignSave(false));
  };

  handleGroupDefaultCampaignRemove = groupId => {
    const formattedGroup = {
      id: groupId,
      attributes: {
        name: this.props.selectedGroupName,
        default_campaign: ""
      }
    };
    this.props
      .Patch(campaignResources.removeGroupDefaultCampaign(groupId))
      .then(() => this.props.Get(Resources.getNetwork))
      .then(() => this.props.setSelectedGroup(formattedGroup))
      .then(() => this.props.handleGroupDefaultCampaignSave(true, "remove"));
  };

  /** **** network header  */

  render() {
    const { refreshing } = this.props;
    const { roles } = JSON.parse(window.localStorage.getItem("domain"));
    const isSuperAdmin = roles.includes("SuperAdmin");
    return (
      <div
        className="d-flex contentHeader"
        data-spy="affix"
        data-offset-top="197"
      >
        <Modal
          isOpen={this.props.defaultCampaignSubmitModal}
          toggle={() => this.props.handleGroupDefaultCampaignSave(false)}
        >
          <ModalHeader
            toggle={() => this.props.handleGroupDefaultCampaignSave(false)}
          >
            Set Default Campaign
          </ModalHeader>
          <ModalBody>
            <Form>
              <div className="globalForm">
                <FormGroup>
                  <Label>Default campaign</Label>
                  <span className="">
                    {this.props.groupDefaultCampaign || "N/A"}
                  </span>
                  <CampaignList
                    props={this.props}
                    campaignList={this.props.campaignList}
                    refetchCampaign={this.props.refetchCampaign}
                    // selectedCampaign={props.selectedCampaign}
                  />
                </FormGroup>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={() => {
                this.props.handleGroupDefaultCampaignSave(false);
              }}
              className="btn-outline-primary"
            >
              Cancel
            </button>
            <button
              type="button"
              className="primaryButton mt-0"
              onClick={() => {
                if (
                  this.props.groupDefaultCampaign &&
                  !this.props.selectedDefaultGroupCampaign.value
                ) {
                  return this.handleGroupDefaultCampaignRemove(
                    this.props.selectedGroup
                  );
                }
                return this.handleGroupDefaultCampaignSubmit(
                  this.props.selectedDefaultGroupCampaign.value,
                  this.props.selectedGroup
                );
              }}
              disabled={
                !this.props.selectedDefaultGroupCampaign.value &&
                !this.props.groupDefaultCampaign
              }
            >
              {this.props.groupDefaultCampaign &&
              !this.props.selectedDefaultGroupCampaign.value
                ? "Clear previous default"
                : "Set as default"}
            </button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.props.createGroupModal}
          // toggle={() => this.props.handleGroupCreate(false)}
          className={this.props.className}
        >
          <ModalHeader
            toggle={() =>
              this.props.handleGroupCreate(false, this.props.action)
            }
          >
            {this.props.action === "edit" ? "Edit Group" : "Create Group"}
          </ModalHeader>
          <ModalBody>
            {" "}
            <Form>
              <FormGroup>
                {/* <Label>Group Name</Label> */}
                <Field
                  component={Input}
                  type="text"
                  name="deviceGroupName"
                  placeholder="Group Name"
                  validate={required}
                  label="Group Name"
                  inputClass="noLabel"
                />
              </FormGroup>
              <FormGroup>
                <Label>Devices</Label>
                <Field
                  component={MultipleSelect}
                  name="devices"
                  isSearchable
                  clearable
                  placeholder="Select Device"
                  options={this.props.unassignedDevicesList
                    .filter(
                      allDevices =>
                        this.props.devices.filter(
                          device => device.meta.id === allDevices.id
                        ).length === 0
                    )
                    .map(item => ({
                      value: item.id,
                      label: `${item.attributes.name} | ${item.attributes.mac_address}`,
                      meta: item
                    }))}
                  closeMenuOnSelect={false}
                  isMulti
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn-outline-primary"
              onClick={() =>
                this.props.handleGroupCreate(false, this.props.action)
              }
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.props.handleSubmit(values =>
                this.props.addGroup(values)
              )}
              className="primaryButton mt-0"
            >
              {this.props.action === "edit" ? "Update" : "Create"}
            </button>
          </ModalFooter>
        </Modal>

        <div
          className={`mr-auto ${this.props.deviceList.length > 0 &&
            "hideCreateButton"}`}
        >
          {this.props.getUserSetting().deviceListView === "groups" && (
            <Button
              color="primary"
              className="primaryButton primaryButton--contentHeader"
              onClick={() => this.props.handleGroupCreate(true)}
            >
              <i className="fa fa-plus mr-2" />
              Create Group
            </Button>
          )}
        </div>
        {this.props.getUserSetting().deviceListView === "lists" && (
          <React.Fragment>
            <small className="ml-3 flex-fill networkDeviceSelector">
              {this.props.deviceList.length > 0 &&
                `${this.props.deviceList.length} ${
                  this.props.deviceList.length === 1
                    ? "device is selected"
                    : "devices are selected"
                }`}
            </small>

            <SearchTagRefresh
              forwardRef={this.props.forwardRef}
              handleSearchChange={this.handleSearchChange}
              handleSearch={this.props.handleSearch}
              searchByTagOptions={this.state.searchByTagOptions}
              clearSearchTag={this.clearSearchTag}
              placeholder="Search Device"
              {...this.props}
            />
          </React.Fragment>
        )}

        <div
          className={`d-flex btn-group ${this.props.deviceList.length > 0 &&
            "showControlButtons"}`}
        >
          {this.props.deviceList.length > 0 && (
            <div className="ml-2">
              <DeviceGroupControlView
                selectedDevices={this.props.deviceList}
                handleAddAndChangeGroup={this.props.handleAddAndChangeGroup}
                electronTags={this.props.electronTags}
                handleRefetch={this.props.handleRefetch}
                handleDeviceGroupState={this.props.handleDeviceGroupState}
                {...this.props}
              />
            </div>
          )}
        </div>
        <div className="btn-group contentHeader__viewButtons ">
          <Tooltip
            item={
              <div
                className={`iconButton contentHeader__viewButtons__list ${this.props.getUserSetting()
                  .deviceListView === "lists" && "activeButton"}`}
                onClick={() => {
                  this.props.setUserSetting("deviceListView", "lists");
                  this.props.handleLoadNetworkList("lists");
                }}
              >
                <div className="fa fa-bars" />
              </div>
            }
            tooltipContent="List View"
            id="header-campaign-list"
          />

          <Tooltip
            item={
              <div
                className={`iconButton  contentHeader__viewButtons__group ${this.props.getUserSetting()
                  .deviceListView === "groups" && "activeButton"}`}
                onClick={() => {
                  this.props.setUserSetting("deviceListView", "groups");
                  this.props.handleLoadNetworkGroups("groups");
                }}
              >
                <div className="fas fa-th" />
              </div>
            }
            tooltipContent="Group View"
            id="header-campaign-grid"
          />
        </div>
        <Dropdown
          className="d-inline-block"
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
        >
          <DropdownToggle className="iconButton" caret>
            <i className="bx bx-filter" />
          </DropdownToggle>
          <DropdownMenu right>
            {isSuperAdmin ? (
              <DropdownItem>
                <DropdownItem className="p-0 network-header-dropdown ">
                  <span
                    role="button"
                    onClick={() => this.handleResync()}
                    className="resyncDropdown"
                    ref={parentNode => (this.parentNode = parentNode)}
                  >
                    Resync Devices
                    <i
                      className="fas fa-angle-right"
                      style={{ marginLeft: "60px" }}
                    />
                  </span>
                  <div
                    ref={node => (this.node = node)}
                    className="resyncDropdown-content"
                    style={{
                      display: this.state.resyncButton ? "block" : "none"
                    }}
                  >
                    <ul>
                      <li
                        onClick={() => this.handleSystemRefresh()}
                        className="dropdown-item"
                      >
                        Global
                      </li>
                      <li
                        onClick={() => this.handleDomainRefresh()}
                        className="dropdown-item"
                      >
                        Domain
                      </li>
                    </ul>
                  </div>
                </DropdownItem>
              </DropdownItem>
            ) : (
              <DropdownItem>
                <span role="button" onClick={() => this.props.handleRefresh()}>
                  Resync Devices
                </span>
              </DropdownItem>
            )}
            <DropdownItem>
              <Link
                to={{
                  pathname: "/networks/screen_shot_view_all",
                  state: { name: "Screenshots" }
                }}
              >
                <span>ScreenShots</span>
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {this.props.getUserSetting().deviceListView === "lists" && (
          <CustomToggleList
            hiddenColumn={this.props.hiddenColumns}
            handleChangeColumn={this.props.toggleHiddenColumns}
            // columnToggleProps={this.props.columnToggleProps}
            columns={
              this.props.columnToggleProps &&
              this.props.columnToggleProps.columns
            }
            onColumnToggle={
              this.props.columnToggleProps &&
              this.props.columnToggleProps.onColumnToggle
            }
            toggles={
              this.props.columnToggleProps &&
              this.props.columnToggleProps.toggles
            }
          />
        )}
        {/* <div>
          {this.state.searchTag.map((i, index) => (
            <div className="mb-3" style={{ display: "inline-block" }}>
              <span
                className="search-tag-btn-wrapper"
                onClick={() => this.handleRemove(index)}
              >
                {`${i}  `}
                <span className="fa fa-times-circle " />
              </span>
            </div>
          ))}
        </div> */}
        {refreshing && (
          <div>
            <div
              id="notification_toast-flash"
              className="notification_toast info animated fadeInUp"
            >
              <p>
                Refreshing <i className="fa fa-spinner fa-spin" />
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { singleGroup, unassignedDevicesList } = state.networks;
  const selector = formValueSelector("CreateGroupForm");
  const defaultCampaignSelector = formValueSelector("DefaultCampaignForGroup");
  return {
    // id,
    singleGroup,
    deviceGroupName: selector(state, "deviceGroupName") || [],
    device: selector(state, "device") || [],
    devices: selector(state, "devices") || [],
    NetworkTag: state.NetworkTag.list
      ? state.NetworkTag.list.attributes.tags
      : [],
    selectedDefaultGroupCampaign:
      defaultCampaignSelector(state, "selectedDefaultCampaignForGroup") || {}
  };
}
export default connect(
  mapState,
  { NetworkTagResources }
)(
  reduxForm({
    form: "CreateGroupForm"
  })(NetworkHeader)
);

export const dialogs = {
  openHelp: {
    heading: "Search Help",
    body:
      "Search field in the header can be use to perform queries in the table.",
    actionFunc: () => {},
    event: {}
  }
};
