/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Row, Col } from "reactstrap";
import { findDOMNode } from "react-dom";
import { sort } from "ramda";
import { ContentModal } from "./modal";
import { updateCampaign } from "../../redux";
import WeatherModal from "./wModal";
import URLWidget from "./urlWidget";
import HLSWidget from "./hlsWidget";
import TextWidget from "./textWidget";
import DateTimeWidget from "./dateTimeWidget";
import RSSWidget from "./rss";
import YTWidget from "./ytWidget";
import RouteWidget from "./routeModal";
import PicToScreenWidget from "./picToScreenWidget";
import TransparentModal from "./transparentW";
import PlaylistModal from "./playlistModal";
import { SecondAdded } from "../../utls/helper";
import Sortable from "./Sortable";
import { openGlobalModal } from "../../../../core/modal/globalDialog";
import { Tooltip } from "../../../../core";
import TimeLineContentSort from "../sortTimelineContent";
import DesktopBreakpoint from "../../../../context/desktop_breakpoint";
import PhoneBreakpoint from "../../../../context/phone_breakpoint";

function handleChange(value, fn, screen, campaign) {
  const temp = screen;
  temp.screenName = value;
  campaign.updateScreen(temp.localID, screen);
  fn(campaign);
}

function calculateTimeInSecond(
  duration,
  divider = 15,
  playlistDuration = false
) {
  let seconds = 0;
  if (playlistDuration) {
    seconds = duration;
  } else {
    seconds = Number(duration.minute) * 60 + Number(duration.second);
  }
  const width = ((seconds * 1.0) / divider) * 130;
  return width;
}

function handleSorting(campaign, fn, oldIndex, action) {
  if (campaign.screens.length === 0) {
    throw new Error("Screen length cannot be zero");
  } else if (typeof fn !== "function") {
    throw Error(
      `Second argument is aspect to be function but receive ${typeof fn}`
    );
  } else if (action !== "UP" && action !== "DOWN") {
    throw new Error("Invalid action is provided");
  } else if (oldIndex > campaign.screens.length) {
    throw new Error("Invalid index is provided.");
  } else if (oldIndex < 1) {
    throw new Error("Index cannot be less then 1 Negative or Zero is provided");
  } else if (action === "UP" && oldIndex - 1 === 0) {
    return null;
  } else if (action === "DOWN" && oldIndex + 1 > campaign.screens.length) {
    return null;
  } else if (action === "UP") {
    const newIndex = Number(oldIndex) - 1;
    campaign.swapScreens(oldIndex, newIndex);
    fn(campaign);
  } else if (action === "DOWN") {
    const newIndex = Number(oldIndex) + 1;
    campaign.swapScreens(oldIndex, newIndex);
    fn(campaign);
  }
}

function handleDragOver(e) {
  e.preventDefault();
}

function SortableElement(element, index) {
  function setIndex(index) {
    sortable.index = index;
  }
  const sortable = {
    element,
    index,
    setIndex
  };

  return sortable;
}
function getIndicator(e, sortables, screenID, list) {
  let sortableItem;
  for (let i = 0; i < sortables.length; i += 1) {
    const elementAttr = sortables[i].element.getBoundingClientRect();

    if (e.nativeEvent.x < elementAttr.x + elementAttr.width) {
      sortableItem = sortables[i];
      break;
    } else {
      sortables[i].element.classList.remove("sortableActive");
    }
  }
  if (sortableItem) {
    sortableItem.element.classList.add("sortableActive");
    // sortableItem.element.style.borderLeft = "3px solid #e87542";
    const arr = sortables.filter(item => item.index !== sortableItem.index);
    for (let j = 0; j < arr.length; j += 1) {
      if (arr[j].element.classList.contains("sortableActive")) {
        arr[j].element.classList.remove("sortableActive");
      }
    }
  }
  const arr2 = list.filter(item => item.element.id !== `item-${screenID}`);
  for (let k = 0; k < arr2.length; k += 1) {
    if (arr2[k].element.classList.contains("sortableActive")) {
      arr2[k].element.classList.remove("sortableActive");
    }
  }
}

function getIndex(e, sortables) {
  let newIndex = 0;
  for (let i = 0; i < sortables.length; i += 1) {
    const elementAttr = sortables[i].element.getBoundingClientRect();
    if (e.nativeEvent.x < elementAttr.x + elementAttr.width) {
      newIndex = sortables[i].index;
      sortables[i].element.classList.remove("sortableActive");
      break;
    }
    if (sortables.length === 0) {
      newIndex = 0;
    }
    if (sortables.length - i === 1) {
      newIndex = sortables[i].index + 1;
    }
  }
  return newIndex;
}

function handleEnter(e, screenID) {
  const allList = Array.from(document.querySelectorAll(".item"));
  const totalList = allList.map(SortableElement);
  const listItems = Array.from(document.querySelectorAll(`#item-${screenID}`));
  const sortables = listItems.map(SortableElement);
  getIndicator(e, sortables, screenID, totalList);
}

function handleDragEnd(e, campaign, fn, screenID) {
  const element = document.getElementById(`singleTimeLine-${screenID}`);
  const listItems = Array.from(document.querySelectorAll(`#item-${screenID}`));
  const sortables = listItems.map(SortableElement);

  const elementAttr = element.getBoundingClientRect();
  if (elementAttr.y < e.nativeEvent.y) {
    if (e.nativeEvent.y >= elementAttr.bottom) {
      for (let j = 0; j < sortables.length; j += 1) {
        if (sortables[j].element.classList.contains("sortableActive")) {
          sortables[j].element.classList.remove("sortableActive");
        }
      }
    }
  }
  if (e.nativeEvent.x - 29 < elementAttr.x) {
    for (let j = 0; j < sortables.length; j += 1) {
      if (sortables[j].element.classList.contains("sortableActive")) {
        sortables[j].element.classList.remove("sortableActive");
      }
    }
  }
  if (e.nativeEvent.y < elementAttr.y) {
    for (let j = 0; j < sortables.length; j += 1) {
      if (sortables[j].element.classList.contains("sortableActive")) {
        sortables[j].element.classList.remove("sortableActive");
      }
    }
  }
  if (!e.nativeEvent.relatedTarget) {
    for (let j = 0; j < sortables.length; j += 1) {
      if (sortables[j].element.classList.contains("sortableActive")) {
        sortables[j].element.classList.remove("sortableActive");
      }
    }
  }
}
function onDrop(e, campaign, fn, screenID) {
  const listItems = Array.from(document.querySelectorAll(`#item-${screenID}`));
  const sortables = listItems.map(SortableElement);
  const index = getIndex(e, sortables);
  if (
    campaign.contentIsBeingDragged &&
    campaign.contentToBeDropped.length > 0
  ) {
    campaign.unLoadContentToBeDropped(screenID, true, index);
    fn(campaign);
  } else if (
    campaign.playlistIsBeingDragged &&
    campaign.playlistToBeDropped.length > 0
  ) {
    campaign.addPlaylist(screenID, true, index);
    fn(campaign);
  } else if (campaign.WeatherIsBeingDragged && campaign.WidgetIsBeingDragged) {
    campaign.addWeather(screenID, true, index);
    fn(campaign);
  } else if (campaign.TextIsBeingDragged && campaign.WidgetIsBeingDragged) {
    campaign.addText(screenID, true, index);
    fn(campaign);
  } else if (campaign.URLIsBeingDragged && campaign.WidgetIsBeingDragged) {
    campaign.addURL(screenID, true, index);
    fn(campaign);
  } else if (campaign.HLSIsBeingDragged && campaign.WidgetIsBeingDragged) {
    campaign.addHLS(screenID, true, index);
    fn(campaign);
  } else if (campaign.RSSIsBeingDragged && campaign.WidgetIsBeingDragged) {
    campaign.addRSS(screenID, true, index);
    fn(campaign);
  } else if (campaign.DateTimeIsBeingDragged && campaign.WidgetIsBeingDragged) {
    campaign.addDateTime(screenID, true, index);
    fn(campaign);
  } else if (campaign.YoutubeIsBeingDragged && campaign.WidgetIsBeingDragged) {
    campaign.addYoutube(screenID, true, index);
    fn(campaign);
  } else if (
    campaign.PicToScreenIsBeingDragged &&
    campaign.WidgetIsBeingDragged
  ) {
    campaign.addPicToScreen(screenID, true, index);
    fn(campaign);
  } else if (campaign.RouteIsBeingDragged && campaign.WidgetIsBeingDragged) {
    campaign.addRoute(screenID, true, index);
    fn(campaign);
  } else if (
    campaign.TransparentIsBeingDragged &&
    campaign.WidgetIsBeingDragged
  ) {
    campaign.addTransparent(screenID, true, index);
    fn(campaign);
  } else {
    // :D
  }
  e.preventDefault();
  e.stopPropagation();
}

/** Feature to be added */
function handleDragContentFromTimeline() {}
function handleDragWidgetFromTimeline() {}
function handleplaylistWidgetFromTimeline() {}

function handleModalToggle(
  toggleStatus,
  setContentModalToggle,
  setContentModalData,
  data,
  screen,
  campaign,
  fn
) {
  const defaultState = JSON.parse(JSON.stringify(data));
  setContentModalData({
    ...data,
    screen,
    campaign,
    updateCampaign: fn,
    defaultState
  });
  setContentModalToggle(toggleStatus);
}

function toggleWidgetModal(dataToBeLoaded, dataLoader, modalToggler) {
  dataLoader(dataToBeLoaded);
  modalToggler(true);
}

function handleContentTileClick(e, item, setIsSelected) {
  e.preventDefault();
  setIsSelected({ ...item });
}

const SingleTime = ({
  screen,
  updateCampaign,
  campaign,
  Get,
  forwardRef,
  openGlobalModal,
  userRoles,
  permission,
  displayMediaModal
}) => {
  const [contentModalToggle, setContentModalToggle] = useState(false);
  const [contentModalData, setContentModalData] = useState({});

  const [weatherModalData, setWeatherModalData] = useState({});
  const [weatherModalToggle, setWeatherModalToggle] = useState(false);

  const [rssModalData, setRSSModalData] = useState({});
  const [rssModalToggle, setRSSModalToggle] = useState(false);

  const [textModalData, setTextModalData] = useState({});
  const [textModalToggle, setTextModalToggle] = useState(false);

  const [dateTimeModalData, setDateTimeModalData] = useState({});
  const [dateTimeModalToggle, setDateTimeModalToggle] = useState(false);

  const [urlModalData, setURLModalData] = useState({});
  const [urlModalToggle, setURLModalToggle] = useState(false);

  const [hlsModalData, setHLSModalData] = useState({});
  const [hlsModalToggle, setHLSModalToggle] = useState(false);

  const [ytModalData, setYTModalData] = useState({});
  const [ytModalToggle, setYTModalToggle] = useState(false);

  const [p2sModalData, setP2SModalData] = useState({});
  const [p2sModalToggle, setP2SModalToggle] = useState(false);

  const [playlistModalData, setPlaylistData] = useState({});
  const [playlistModalToggle, setPlaylistToggle] = useState(false);

  const [routeModalData, setRouteModalData] = useState({});
  const [routeModalToggle, setRouteModalToggle] = useState(false);
  const [transparentModalData, setTransparentModalData] = useState({});
  const [transparentModalToggle, setTransparentModalToggle] = useState(false);

  const [isSelected, setIsSelected] = useState({ id: undefined });

  const [currentlySelected, setCurrentlySelected] = useState(undefined);

  const [popOverOpenToggle, setPopOverToggle] = useState(false);
  const [popOverContentID, setPopOverContentID] = useState("");

  function toggleDeletePopOver(flag, id) {
    setPopOverToggle(flag);
    setPopOverContentID(id);
  }

  function handleToggleWidgetEdit(item, screen) {
    const defaultState = JSON.parse(JSON.stringify(item));
    switch (item.type) {
      case "Weather": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setWeatherModalData,
          setWeatherModalToggle
        );
        break;
      }
      case "Text": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setTextModalData,
          setTextModalToggle
        );

        break;
      }
      case "URL": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setURLModalData,
          setURLModalToggle
        );
        break;
      }
      case "HLS": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setHLSModalData,
          setHLSModalToggle
        );
        break;
      }
      case "RSS": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setRSSModalData,
          setRSSModalToggle
        );
        break;
      }

      case "DateTime": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setDateTimeModalData,
          setDateTimeModalToggle
        );
        break;
      }

      case "Youtube": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setYTModalData,
          setYTModalToggle
        );
        break;
      }

      case "PicToScreen": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setP2SModalData,
          setP2SModalToggle
        );
        break;
      }
      case "Route": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setRouteModalData,
          setRouteModalToggle
        );
        break;
      }
      case "playlist": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setPlaylistData,
          setPlaylistToggle
        );
        break;
      }

      case "Transparent": {
        toggleWidgetModal(
          { ...item, screen, defaultState },
          setTransparentModalData,
          setTransparentModalToggle
        );
        break;
      }

      default:
        break;
    }
  }
  return (
    <div
      className="campaignStudioWrapper__singleTimeLine stage-area"
      id={`singleTimeLine-${screen.localID}`}
      onClick={e => {
        displayMediaModal(true, screen.localID);
      }}
    >
      {" "}
      <div className="">
        {/* <TimeLineContentSort
          screen={screen}
          campaign={campaign}
          updateCampaign={updateCampaign}
        /> */}
        {/* <PerfectScrollbar> */}
        <div
          style={{
            // display: "flex",
            height: "60px",
            maxHeight: "60px",
            width: "100%",
            // width: window.innerWidth - 0.19 * window.innerWidth,
            maxWidth: "100%"
          }}
          onDragOver={e => handleDragOver(e)}
          onDrop={e =>
            onDrop(e, campaign, updateCampaign, screen.localID, openGlobalModal)
          }
          onDragEnter={e => handleEnter(e, screen.localID)}
          onDragLeave={e =>
            handleDragEnd(e, campaign, updateCampaign, screen.localID)
          }
        >
          {screen.contents.length > 0 ? (
            <Sortable
              screen={screen}
              transitionDuration={0}
              axis="x"
              campaign={campaign}
              updateCampaign={updateCampaign}
              calculateTimeInSecond={calculateTimeInSecond}
              setIsSelected={setIsSelected}
              handleContentTileClick={handleContentTileClick}
              setCurrentlySelected={setCurrentlySelected}
              currentlySelected={currentlySelected}
              handleModalToggle={handleModalToggle}
              setContentModalData={setContentModalData}
              setContentModalToggle={setContentModalToggle}
              handleToggleWidgetEdit={handleToggleWidgetEdit}
              lockToContainerEdges
              helperContainer={forwardRef.current}
              popOverOpenToggle={popOverOpenToggle}
              toggleDeletePopOver={toggleDeletePopOver}
              popOverContentID={popOverContentID}
              userRoles={userRoles}
              permission={permission}
            />
          ) : (
            <small className="drag-text">
              <DesktopBreakpoint>
                Drag a content in the timeline
              </DesktopBreakpoint>
              <PhoneBreakpoint>Click to browse the contents</PhoneBreakpoint>
            </small>
          )}

          {/* {screen.contents
                  .filter(item => item.destroy !== 1)
                  .map(item => {
                    if (item.type === "content") {
                      return (
                        <div
                          className="mr-1"
                          key={item.contentLocalID}
                          style={{
                            width: `${130 * calculateTimeInSecond(item.duration, 15)}px`,
                            height: "60px"
                          }}
                          onClick={e => handleContentTileClick(e, item, setIsSelected)}
                          role="presentation"
                        >
                          <Resize
                            contentID={item.contentLocalID}
                            screenID={screen.localID}
                            setCurrentlySelected={setCurrentlySelected}
                            currentlySelected={currentlySelected}
                          >
                            <span
                              className="mt-3 pl-1 pr-1 pb-1"
                              style={{
                                color: "#fff",
                                backgroundColor: "#000",
                                borderRadius: "2px"
                              }}
                            >
                              <i
                                role="presentation"
                                onClick={() =>
                                  handleModalToggle(
                                    true,
                                    setContentModalToggle,
                                    setContentModalData,
                                    item,
                                    screen,
                                    campaign,
                                    updateCampaign
                                  )
                                }
                                className="fa fa-edit ml-1 mr-2"
                                style={{ color: "white" }}
                              />

                              <i
                                role="presentation"
                                onClick={() => {
                                  campaign.removeContentFromScreen(
                                    screen.localID,
                                    item.contentLocalID
                                  );
                                  updateCampaign(campaign);
                                }}
                                className="fa fa-trash ml-1 mr-2"
                                style={{ color: "white" }}
                              />
                              {`${item.duration.minute}:${item.duration.second}`}
                            </span>
                          </Resize>
                        </div>
                      );
                    }
                    if (item.type === "Weather" || item.type === "Text" || item.type === "URL") {
                      return (
                        <div
                          key={item.contentLocalID}
                          className="mr-1"
                          style={{
                            width: `${96 * 2}px`,
                            height: "60px"
                          }}
                        >
                          <div
                            style={{
                              width: `${92 * 2}px`,
                              height: "56px",
                              marginTop: "3px",
                              borderRadius: "3px",
                              // backgroundImage: `url($)`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                              backgroundColor: item.color
                            }}
                          >
                            <span
                              className="mt-3 pl-1 pr-1 pb-1"
                              style={{
                                color: "#fff",
                                borderRadius: "2px"
                              }}
                            >
                              <i
                                role="presentation"
                                onClick={() => handleToggleWidgetEdit(item, screen)}
                                className="fa fa-edit ml-1 mr-2"
                                style={{ color: "white" }}
                              />

                              <i
                                role="presentation"
                                onClick={() => {
                                  campaign.removeContentFromScreen(
                                    screen.localID,
                                    item.contentLocalID
                                  );
                                  updateCampaign(campaign);
                                }}
                                className="fa fa-trash ml-1 mr-2"
                                style={{ color: "white" }}
                              />
                            </span>
                            <span
                              className="d-flex justify-content-center align-item-center"
                              style={{ color: "#fff" }}
                            >
                              <i className={`${item.icon} mr-1 mt-1`} />
                              {item.type}
                            </span>
                          </div>
                        </div>
                      );
                    }

                    if (item.type === "playlist") {
                      return (
                        <div
                          key={item.contentLocalID}
                          className="mr-1"
                          style={{
                            width: `${96 * 2}px`,
                            height: "60px"
                          }}
                        >
                          <div
                            style={{
                              width: `${92 * 2}px`,
                              height: "56px",
                              marginTop: "3px",
                              borderRadius: "3px",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                              backgroundColor: item.color
                            }}
                          >
                            <span
                              className="mt-3 pl-1 pr-1 pb-1"
                              style={{
                                color: "#fff",
                                borderRadius: "2px"
                              }}
                            >
                              <i
                                role="presentation"
                                onClick={() => handleToggleWidgetEdit(item, screen)}
                                className="fa fa-edit ml-1 mr-2"
                                style={{ color: "white" }}
                              />
                              <i
                                role="presentation"
                                onClick={() => {
                                  campaign.removeContentFromScreen(
                                    screen.localID,
                                    item.contentLocalID
                                  );
                                  updateCampaign(campaign);
                                }}
                                className="fa fa-trash ml-1 mr-2"
                                style={{ color: "white" }}
                              />
                            </span>
                            <span
                              className="d-flex justify-content-center align-item-center"
                              style={{ color: "#fff" }}
                            >
                              <i className={`${item.icon} mr-1 mt-1`} />
                              {"Playlist"}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  })} */}
        </div>
        {/* </PerfectScrollbar> */}
      </div>
      {/* </div> */}
      {contentModalToggle && (
        <ContentModal
          {...contentModalData}
          toggle={setContentModalToggle}
          open={contentModalToggle}
        />
      )}
      {weatherModalToggle && (
        <WeatherModal
          {...weatherModalData}
          toggle={setWeatherModalToggle}
          open={weatherModalToggle}
          Get={Get}
        />
      )}
      {urlModalToggle && (
        <URLWidget
          {...urlModalData}
          data={urlModalData}
          toggle={setURLModalToggle}
          setData={setURLModalData}
          open={urlModalToggle}
        />
      )}
      {hlsModalToggle && (
        <HLSWidget
          {...hlsModalData}
          data={hlsModalData}
          toggle={setHLSModalToggle}
          setData={setHLSModalData}
          open={hlsModalToggle}
        />
      )}
      {dateTimeModalToggle && (
        <DateTimeWidget
          {...dateTimeModalData}
          data={dateTimeModalData}
          toggle={setDateTimeModalToggle}
          setData={setDateTimeModalData}
          open={setDateTimeModalToggle}
        />
      )}
      {textModalToggle && (
        <TextWidget
          {...textModalData}
          data={textModalData}
          toggle={setTextModalToggle}
          open={textModalToggle}
        />
      )}
      {rssModalToggle && (
        <RSSWidget
          {...rssModalData}
          data={rssModalData}
          toggle={setRSSModalToggle}
          open={rssModalToggle}
        />
      )}
      {playlistModalToggle && (
        <PlaylistModal
          {...playlistModalData}
          data={playlistModalData}
          toggle={setPlaylistToggle}
          open={playlistModalToggle}
        />
      )}
      {ytModalToggle && (
        <YTWidget
          {...ytModalData}
          data={ytModalData}
          toggle={setYTModalToggle}
          setData={setYTModalData}
          open={ytModalToggle}
        />
      )}
      {p2sModalToggle && (
        <PicToScreenWidget
          {...p2sModalData}
          data={p2sModalData}
          toggle={setP2SModalToggle}
          setData={setP2SModalData}
          open={p2sModalToggle}
        />
      )}
      {routeModalToggle && (
        <RouteWidget
          {...routeModalData}
          data={routeModalData}
          toggle={setRouteModalToggle}
          setData={setRouteModalData}
          open={routeModalToggle}
        />
      )}
      {transparentModalToggle && (
        <TransparentModal
          {...transparentModalData}
          data={transparentModalData}
          toggle={setTransparentModalToggle}
          setData={setTransparentModalData}
          open={transparentModalToggle}
        />
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    campaign: state.newCampaign.campaign
  };
}
export default connect(
  mapStateToProps,
  { updateCampaign, openGlobalModal }
)(SingleTime);
