/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Calendar from "react-calendar-multiday";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Form
} from "reactstrap";
import { remove, findIndex } from "lodash";

/** Local Import */
import DatePicker from "react-datepicker";

import { MultipleSelect } from "../../../core/form/searchSelect";
import SelectAsync from "../../../core/form/selectAsyncClass";
import { validateForm, getStartDate } from "./eventFormValidator";
import { Input } from "../../../core";
import { repeatEventOptions } from "../modules/staticDataForCalendar";

const uuidv4 = require("uuid/v4");

function requiredData() {
  return JSON.parse(window.localStorage.getItem("eventDate"));
}

const CustomInput = ({ value, onClick, onChange }) => (
  <div className="expirationDateWrapper">
    <input onClick={onClick} value={value} onChange={onChange} />
    <i
      className="expirationDateWrapper__calendar fa fa-calendar-alt"
      onClick={onClick}
    />
  </div>
);

class EventForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.selectedData,
      allEvent: true,
      campaignNameHasError: undefined,
      eventTitleHasError: undefined,
      startHasError: undefined,
      endHasError: undefined,
      endTouched: false,
      startTouched: false,
      calendarOpen: undefined
    };
  }

  toggle = () => {
    this.props.toggleModal();
  };

  handleCalendarOpen = (type, calType) => {
    if (calType === "start") {
      this.setState({ calendarOpenStart: type, calendarOpenEnd: undefined });
    } else {
      this.setState({ calendarOpenEnd: type, calendarOpenStart: undefined });
    }
  };

  handleTimeSelectorOpen = (flag, type) => {
    if (this.state[type]) {
      this.setState({ [type]: undefined });
    } else {
      this.setState({
        [type]: flag
      });
    }
  };

  /** Form Change Sections */
  handleFormDataChange = (value, name) => {
    if (name === "end") {
      this.setState({
        [name]: value,
        endTouched: true
      });
    } else if (name === "start") {
      this.setState({
        [name]: value,
        startTouched: true
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  onExcludeDateChange = ob => {
    this.setState({ excludedDates: ob.selected });
  };
  /** Form Change Section End */

  handleDelete = () => {
    const { allEvent, start, parent, hasManyEvents } = this.state;
    const { allEvents } = this.props;
    this.props.handleDeleteConfirmation(
      {
        allEvent,
        start,
        parent,
        allEvents,
        hasManyEvents,
        toggle: this.toggle
      },
      allEvent ? "all events" : "this event"
    );
    // this.props.handleCalendarAction("View");
  };

  /**
   * handleEventSave gives the form data to createCalendar.js
   */
  handleSaveEvent = () => {
    const validateResult = validateForm(this.state, this.props.allEvents);
    this.setState({ ...validateResult }, () => {
      if (this.state.formValid) {
        if (this.state.action === "Create") {
          this.handleCreateEvent();
        } else {
          this.handleEdit();
        }
      }
    });
  };

  handleEdit = () => {
    const {
      eventId,
      campaignName,
      title,
      repeat,
      repeatFreq,
      excludedDates,
      start,
      end,
      allEvent,
      parent,
      endTouched,
      startTouched,
      hasManyEvents,
      duration
    } = this.state;
    const { allEvents } = this.props;
    if (allEvent) {
      const old = remove(allEvents, { eventId: parent.eventId });
      const newParent = {
        ...parent,
        campaignName,
        title,
        repeat,
        repeatFreq,
        excludedDates,
        start: getStartDate(
          repeat.value,
          hasManyEvents,
          start,
          parent.start,
          this.props.selectedData.start
        ),
        end: getStartDate(
          repeat.value,
          hasManyEvents,
          end,
          parent.end,
          this.props.selectedData.end
        ),
        duration
      };
      const validateResult = validateForm(newParent, allEvents);
      this.setState({ ...validateResult });
      if (validateResult.formValid) {
        this.props.change("calendar", [
          ...this.props.allEvents,
          { ...newParent }
        ]);
        this.toggle();
      } else {
        this.props.change("calendar", [...this.props.allEvents, { ...parent }]);
      }
    } else {
      // checking if the event have many events
      if (hasManyEvents) {
        /**
         * keeping the selected event in excluded date and
         * making new event of that data
         */
        const index = findIndex(allEvents, { eventId: parent.eventId });
        allEvents[index].excludedDates = [
          ...allEvents[index].excludedDates,
          `${moment(
            JSON.parse(window.localStorage.getItem("eventDate")).start
          ).format("YYYY-MM-DDThh:mm:ssZ")}`
        ];
        const data = {
          eventId: uuidv4(),
          campaignName,
          title,
          repeat,
          repeatFreq,
          excludedDates,
          start,
          end,
          duration
        };
        this.props.change("calendar", [{ ...data }, ...allEvents]);
      } else {
        /**
         * deleting the selected event and creating new event
         */
        const old = remove(allEvents, { eventId: parent.eventId });
        const newParent = {
          ...parent,
          campaignName,
          title,
          repeat,
          repeatFreq,
          excludedDates,
          duration,
          start: startTouched ? start : parent.start,
          end: endTouched ? end : parent.end
        };

        this.props.change("calendar", [
          ...this.props.allEvents,
          { ...newParent }
        ]);
      }
      this.toggle();
    }
    this.props.handleCalendarAction("View");
  };

  handleCreateEvent = () => {
    const {
      eventId,
      campaignName,
      title,
      repeat,
      repeatFreq,
      excludedDates,
      start,
      end,
      duration
    } = this.state;
    const data = {
      eventId,
      campaignName,
      title,
      repeat,
      repeatFreq,
      excludedDates,
      start,
      end,
      duration
    };
    this.props.change("calendar", [...this.props.allEvents, { ...data }]);
    this.props.handleCalendarAction("View");
    this.toggle();
  };

  handleExcludeCheck = () => {
    if (this.state.exclude) {
      this.setState({ exclude: false, excludedDates: [] });
      return;
    }
    this.setState({ exclude: true });
  };

  showError = showErrorOfInput => (
    <small style={{ color: "red" }} className="d-block">
      {showErrorOfInput || ""}
    </small>
  );

  endTimeFormatter = (start, end) => {
    const endTime = moment(end).format("HH:mm");
    const endDate = moment(start).format("YYYY-MM-DDT");
    return `${endDate}${endTime}`;
  };

  handleStartAndEndDate = value => {
    if (!value || value === "") {
      this.handleFormDataChange(this.state.start, "start");
      return;
    }
    /* to preserve the start time of the event for the single day even if the start date gets altered  */
    const startDuration = this.endTimeFormatter(value, this.state.start);
    /* to preserve the end time of the event for the single day even if the start date gets altered  */
    const endDuration = this.endTimeFormatter(value, this.state.end);

    this.handleFormDataChange(endDuration, "end");
    this.handleFormDataChange(startDuration, "start");
  };

  getTimeValue = (value, currentTime) => {
    const time = moment(value, "h:mm a").format("h:mm A");

    const date = moment(currentTime).format("MM/DD/YYYY");
    const combineDate = moment(`${date} ${time}`).toDate();
    const isValidTime = moment(combineDate).isValid();
    const validDate = isValidTime ? combineDate : moment(`${date}`).toDate();

    return validDate;
  };

  render() {
    const { campaignList } = this.props;
    const { action } = JSON.parse(window.localStorage.getItem("eventDate"));
    const startDate = moment(this.state.start).toDate();
    const endDate = moment(this.state.end).toDate();
    const isMobileView = window.matchMedia("(max-width: 1024px)");

    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={this.toggle}
          className="calendarEventModal"
        >
          <ModalHeader toggle={this.toggle}>Event Form </ModalHeader>
          <ModalBody className="globalForm">
            <Form className="form-group">
              <FormGroup>
                <div className="row globalInput">
                  <Label className="mt-2 col-md-3">Title</Label>
                  <div className="globalInput__inputMod col-md-9">
                    <Input
                      input={{
                        onChange: value =>
                          this.handleFormDataChange(value, "title"),
                        value: this.state.title
                      }}
                    />
                    {this.showError(this.state.titleHasError)}
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="row globalInput">
                  <Label className="mt-2 col-md-3">Campaign</Label>
                  <div className="col-md-9">
                    <SelectAsync
                      className="mb-2  calendarselect"
                      input={{ value: this.state.campaignName }}
                      onChange={value =>
                        this.handleFormDataChange(value, "campaignName")
                      }
                    />
                    {this.showError(this.state.campaignNameHasError)}
                  </div>
                </div>
              </FormGroup>

              <FormGroup>
                <div className="row globalInput mb-1">
                  <Label className="mt-2 col-md-3">Start/End</Label>
                  <div className="col-md-4 col-12 pr-0 d-flex">
                    <div
                      role="presentation"
                      className="mr-2 datePicker  globalInput__inputMod"
                    >
                      <div
                        role="presentation"
                        onClick={() =>
                          isMobileView.matches &&
                          this.handleTimeSelectorOpen(true, "calendarOpenStart")
                        }
                        className="enableDatePickerForMobile"
                      />
                      <DatePicker
                        selected={startDate || null}
                        dateFormat=" MM/dd/yyyy"
                        onChange={value => {
                          this.handleStartAndEndDate(value);
                          if (this.state.calendarOpenStart) {
                            this.handleTimeSelectorOpen(
                              false,
                              "calendarOpenStart"
                            );
                          }
                        }}
                        open={this.state.calendarOpenStart}
                      />
                      <i
                        className="expirationDateWrapper__calendar fa fa-calendar-alt"
                        onClick={() =>
                          this.handleTimeSelectorOpen(true, "calendarOpenStart")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-5 pl-md-0 pl-3 d-flex">
                    <div
                      tabIndex="0"
                      role="presentation"
                      className="timePicker globalInput__inputMod"
                      onClick={() =>
                        isMobileView.matches &&
                        this.handleTimeSelectorOpen(true, "timeSelectorStart")
                      }
                    >
                      <DatePicker
                        selected={startDate || null}
                        onChange={value => {
                          if (!value || value === "") {
                            this.handleFormDataChange(
                              this.state.start,
                              "start"
                            );
                            return;
                          }
                          const startTime = this.getTimeValue(
                            value,
                            this.state.start
                          );
                          this.handleFormDataChange(startTime, "start");
                          if (this.state.timeSelectorStart) {
                            this.handleTimeSelectorOpen(
                              false,
                              "timeSelectorStart"
                            );
                          }
                        }}
                        onChangeRaw={event => {
                          if (
                            !event.target.value &&
                            event.target.value === ""
                          ) {
                            this.handleFormDataChange(
                              this.state.start,
                              "start"
                            );
                            return;
                          }
                          const startTime = this.getTimeValue(
                            event.target.value,
                            this.state.start
                          );
                          this.handleFormDataChange(startTime, "start");
                          if (this.state.timeSelectorStart) {
                            this.handleTimeSelectorOpen(
                              false,
                              "timeSelectorStart"
                            );
                          }
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        open={this.state.timeSelectorStart}
                      />
                      <i
                        className="expirationDateWrapper__clock fa fa-clock"
                        onClick={() =>
                          this.handleTimeSelectorOpen(true, "timeSelectorStart")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-1 col-2 pl-0">
                    <i className="mt-2 ml-2 bx bx-minus" />{" "}
                  </div>
                  <div className="col-md-2 col-5 pl-md-0 pl-3 d-flex">
                    <div
                      role="presentation"
                      tabIndex="0"
                      className="timePicker globalInput__inputMod"
                      onClick={() =>
                        isMobileView.matches &&
                        this.handleTimeSelectorOpen(true, "timeSelectorEnd")
                      }
                    >
                      <DatePicker
                        selected={endDate || null}
                        onChange={value => {
                          if (!value || value === "") {
                            this.handleFormDataChange(this.state.end, "end");
                            return;
                          }
                          const endTime = this.getTimeValue(
                            value,
                            this.state.end
                          );

                          this.handleFormDataChange(endTime, "end");
                          if (this.state.timeSelectorEnd) {
                            this.handleTimeSelectorOpen(
                              false,
                              "timeSelectorEnd"
                            );
                          }
                        }}
                        onChangeRaw={event => {
                          if (
                            !event.target.value ||
                            event.target.value === ""
                          ) {
                            this.handleFormDataChange(this.state.end, "end");
                            return;
                          }
                          const endTime = this.getTimeValue(
                            event.target.value,
                            this.state.end
                          );
                          this.handleFormDataChange(endTime, "end");
                          if (this.state.timeSelectorEnd) {
                            this.handleTimeSelectorOpen(
                              false,
                              "timeSelectorEnd"
                            );
                          }
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        open={this.state.timeSelectorEnd}
                      />
                      <i
                        className="expirationDateWrapper__clock fa fa-clock"
                        onClick={() =>
                          this.handleTimeSelectorOpen(true, "timeSelectorEnd")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "180px" }}>
                  {this.showError(this.state.startHasError)}
                  {this.showError(this.state.endHasError)}
                  {/* <Label className="d-flex checkBoxWrapper mr-4">
                    <input
                      type="checkbox"
                      checked={this.state.multiday}
                      onChange={this.handleMultidayFlag}
                    />

                    <span className="checkmark" style={{ left: 0 }} />
                  </Label>
                  <p className="mt-1" style={{ marginLeft: "24px" }}>
                    MultiDay
                  </p> */}
                </div>
              </FormGroup>

              {/* <FormGroup>
                <div className="d-flex globalInput">
                  <Label>End date:</Label>
                  <div className="globalInput__inputMod">
                    <Input
                      className="mb-2"
                      type="datetime-local"
                      input={{
                        onChange: date =>
                          this.handleFormDataChange(date, "end"),
                        value: this.state.end
                      }}
                    />
                    {this.showError(this.state.endHasError)}
                  </div>
                </div>
              </FormGroup> */}
              <FormGroup>
                <div className="row globalInput">
                  <Label className="mt-2 col-md-3">Repeat</Label>
                  <div className="col-md-9">
                    <MultipleSelect
                      className="mb-2  css-1pcexqc-container mb-2 calendarselect"
                      input={{ value: this.state.repeat }}
                      options={repeatEventOptions}
                      onChange={date =>
                        this.handleFormDataChange(date, "repeat")
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              {this.state.repeat.value !== "not_repeat" && (
                <FormGroup>
                  <div className="row globalInput mb-1">
                    <Label className="mt-2 col-md-3">Repetition Until</Label>
                    <div className="col-md-4 pr-0 d-flex">
                      <div
                        role="presentation"
                        className=" mr-2 datePicker globalInput__inputMod"
                      >
                        <div
                          role="presentation"
                          onClick={() =>
                            isMobileView.matches &&
                            this.handleTimeSelectorOpen(true, "calendarOpenEnd")
                          }
                          className="enableDatePickerForMobile"
                        />
                        <DatePicker
                          selected={endDate || null}
                          dateFormat=" MM/dd/yyyy  "
                          onChange={value => {
                            if (!value || value === "") {
                              this.handleFormDataChange(this.state.end, "end");
                              return;
                            }
                            const endDuration = this.endTimeFormatter(
                              value,
                              this.state.end
                            );
                            this.handleFormDataChange(endDuration, "end");
                            if (this.state.calendarOpenEnd) {
                              this.handleTimeSelectorOpen(
                                false,
                                "calendarOpenEnd"
                              );
                            }
                          }}
                          open={this.state.calendarOpenEnd}
                        />
                        <i
                          role="presentation"
                          className="expirationDateWrapper__calendar fa fa-calendar-alt"
                          onClick={() =>
                            this.handleTimeSelectorOpen(true, "calendarOpenEnd")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </FormGroup>
              )}
              <div className="form-validation-arrange adduserinput">
                <FormGroup check inline className="mb-2">
                  {action !== "Create" && (
                    <Label className="checkBoxWrapper mr-4">
                      <input
                        type="checkbox"
                        onChange={e =>
                          this.handleFormDataChange(
                            e.target.checked,
                            "allEvent"
                          )
                        }
                        checked={this.state.allEvent}
                      />
                      <p>Change all event</p>
                      <span className="checkmark" />
                    </Label>
                  )}
                </FormGroup>
                <FormGroup check inline>
                  {/* checkbox to toggle exclude calendar */}
                  {this.state.repeat.value !== "not_repeat" && (
                    <div>
                      <Label className="checkBoxWrapper mr-4">
                        <input
                          type="checkbox"
                          checked={this.state.exclude}
                          onChange={this.handleExcludeCheck}
                        />
                        <p>Exclude events for certain dates</p>
                        <span className="checkmark" />
                      </Label>

                      {this.state.exclude && (
                        <Calendar
                          isMultiple
                          selected={this.state.excludedDates}
                          onChange={this.onExcludeDateChange}
                        />
                      )}
                    </div>
                  )}
                </FormGroup>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter className="d-flex">
            {action !== "Create" && (
              <i
                role="button"
                className="p-2 flex-grow-1 fa fa-trash"
                onClick={this.handleDelete}
              />
            )}

            <button className="btn-outline-primary" onClick={this.toggle}>
              Cancel
            </button>
            <button
              className="primaryButton mt-0"
              onClick={this.handleSaveEvent}
            >
              Save
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const campaignList = state.campaign.list;

  const selectedData = requiredData(props.action);

  return {
    campaignList: campaignList !== undefined ? campaignList : [],
    selectedData
  };
}
export default connect(
  mapStateToProps,
  {}
)(EventForm);
