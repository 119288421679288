import React, { Component, Fragment } from "react";
import {
  Form,
  FormGroup,
  Label,
  Col,
  Table,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import { change } from "redux-form";
import { connect } from "react-redux";
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";
// import dragContent from "../../../assets/images/drag.png";
import { Tooltip } from "../../../core";

import AddPlaylistSingleItem from "./addPlaylistSingleItem";
import { getMinutesAndSeconds } from "../../../helper";
import { AddPlaylistStudioheader } from "./addPlaylistStudioHeader";
import PlaylistStudioContentTable from "./playlistStudioContentTable";

const uuidv4 = require("uuid/v4");
/**
@TODO*
 drag and drop browser support have different message;
*/

const DragHandle = sortableHandle(() => (
  <Tooltip
    item={<i className="fas fa-bars dragHandle" />}
    tooltipContent="Sort Playlist"
    id="sort"
  />
));

const SortableItem = SortableElement(props => (
  <li style={{ position: "relative", width: "100%", listStyle: "none" }}>
    <DragHandle />
    <AddPlaylistSingleItem
      setIsSubmtable={props.setIsSubmtable}
      key={props.item.uniqueKey}
      data={props.item}
      playListData={props.playListData}
      change={props.change}
      playlistControl={props.playlistControl}
    />
  </li>
));

const SortableList = SortableContainer(
  props =>
    props.playListData.length !== 0 && (
      <ul style={{ border: "1px solid red;" }}>
        {props.playListData.map((item, index) => (
          <SortableItem
            setIsSubmtable={props.setIsSubmtable}
            key={item.uniqueKey}
            index={index}
            item={item}
            playListData={props.playListData}
            change={props.change}
            playlistControl={props.playlistControl}
          />
        ))}
      </ul>
    )
);

class AddPlaylistStudio extends Component {
  constructor(props) {
    super(props);
    this.state = { dropdownOpen: false };
  }

  handleDragEnter = event => {
    if (event.target.className === "playlistDropzone") {
      event.target.classList.add("playlistDropzoneActive");
    }
  };

  handleDragLeave = event => {
    if (event.target.className === "playlistDropzone ") {
      event.target.classList.remove("playlistDropzoneActive");
    }
    this.props.showDropZoneFunc(false);
  };

  handleDragOver = e => {
    e.preventDefault();
  };

  handleOnDrop = e => {
    e.stopPropagation();
    if (e.target.className === "playlistDropzone playlistDropzoneActive") {
      e.target.classList.remove("playlistDropzoneActive");
    }
    e.preventDefault();
    this.props.showDropZoneFunc(false);
    this.props.change("addPlaylistForm", "contentToBeDropped", []);
    const playListData = this.props.selectedContent.map((i, index) => {
      const time = getMinutesAndSeconds(i.attributes.duration_seconds);
      return {
        ...i,
        order: index,
        uniqueKey: uuidv4(),
        durationMinute: time.minutes,
        durationSecond: time.seconds,
        weight: null
      };
    });

    const newList = [...this.props.playListData, ...playListData];
    this.props.change("addPlaylistForm", "playListData", newList);
  };

  playlistStudioResponsive = () => {};

  componentDidUpdate() {
    const parentHeight = document.querySelector(".playlistStudio").clientHeight;
    const headerHeight = document.querySelector(".playlistStudio__header")
      .clientHeight;
    const diff = parentHeight - headerHeight;
    const tableReponsive = document.querySelector(
      ".playlistStudio > .table-responsive"
    );
    if (tableReponsive) {
      tableReponsive.style.height = `${diff}px`;
    }
  }

  render() {
    this.playlistStudioResponsive();
    const { playlistControl } = this.props;
    return (
      <Col md="8" sm="12" className="pl-0 ple_custom_height">
        <Card className="ml-2">
          {this.props.showDropZone && (
            <div
              className="playlistDropzone"
              onDragEnter={this.handleDragEnter}
              onDragLeave={this.handleDragLeave}
              onDragOver={this.handleDragOver}
              onDrop={this.handleOnDrop}
            />
          )}

          <div className="playlistStudio">
            <AddPlaylistStudioheader
              popOne={this.props.popOne}
              random={this.props.random}
              playlistControl={playlistControl}
              playlistAttributeChange={this.props.playlistAttributeChange}
              playlistControlChange={this.props.playlistControlChange}
              handleControl={this.props.handleControl}
            />
            {this.props.playListData.length !== 0 ? (
              <PlaylistStudioContentTable
                setIsSubmtable={this.props.setIsSubmtable}
                playListData={this.props.playListData}
                change={this.props.change}
                playlistControl={this.props.playlistControl}
                sortableItemStyle={{ minWidth: "280px", marginLeft: "16px" }}
              />
            ) : (
              <div className="drag-playlist">
                <img
                  src={require("../../../assets/images/drag.png")}
                  alt="drag content here"
                  width="500px"
                />
              </div>
            )}
          </div>
        </Card>
      </Col>
    );
  }
}

export default connect(
  null,
  { change }
)(AddPlaylistStudio);
