import React from "react";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import SendToDevice from "./sendToDeviceModal";
import AddToGroup from "./addToGroup";
import DeviceControlModal from "./deviceControlModal";
import { Tooltip } from "../../../core";

export default class DeviceGroupControlView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      volume: 100,
      isMute: true,
      showRemoteControl: false
    };
  }

  handleVolumeMute = () => {
    this.setState(state => ({ isMute: !state.isMute }));
  };

  handleRemoteController = () => {
    this.setState(state => ({ showRemoteControl: !state.showRemoteControl }));
  };

  render() {
    return (
      <div className="btn-group">
        <SendToDevice
          Get={this.props.Get}
          campaignList={this.props.campaignList}
          calendarList={this.props.calendarList}
          selectedDevices={this.props.selectedDevices}
          handleDeleteCallback={this.props.handleDeleteCallback}
          handleSendCampaignToPlayer={this.props.handleSendCampaignToPlayer}
          handleSendCalendarToPlayer={this.props.handleSendCalendarToPlayer}
          modal={this.props.modalSendToMedia}
          selectedGroup={this.props.selectedGroup}
          toggle={this.props.toggleSendToMediaModal}
        >
          <Tooltip
            item={
              <div
                className="iconButton iconButton--first ml-0"
                onClick={() =>
                  this.props.toggleSendToMediaModal(this.props.selectedGroup)
                }
              >
                <i className="fas fa-play" style={{ fontSize: "18px" }} />
              </div>
            }
            id="header-content-play"
            tooltipContent="Play"
          />
          {/* <Button
            type="button"
            // id="play"
            onClick={() =>
              this.props.toggleSendToMediaModal(this.props.selectedGroup)
            }
            disabled={this.props.disabled}
          >
            <i
              className="iconButton bx bx-play bx-md"
              // onClick={this.toggleMediaPlayModal}
            />
          </Button> */}
        </SendToDevice>

        <Tooltip
          item={
            <div
              className="iconButton  iconButton--middle ml-0"
              onClick={() => {
                this.props.stopGroupDevices(
                  this.props.selectedGroup,
                  this.props.selectedDevices
                );
              }}
            >
              <i
                className="fas fa-stop"
                style={{ fontSize: "18px", borderRadius: "0" }}
              />
            </div>
          }
          id="header-content-stop"
          tooltipContent="Stop"
        />

        {/* <AddToGroup
          {...this.props}
          selectedGroupName={this.selectedGroupName}
          Get={this.props.Get}
          Put={this.props.Put}
          deviceGroups={this.props.deviceGroups}
          modalAddGroupModal={this.props.modalAddGroupModal}
          toggleAddToGroupModal={this.props.toggleAddToGroupModal}
          handleAddAndChangeGroup={this.props.handleAddAndChangeGroup}
        >
          <Button
            size="sm"
            onClick={() =>
              this.props.toggleAddToGroupModal(this.props.selectedGroup)
            }
            disabled={this.props.disabled}
            className="float-left singleActionButton ml-0"
          >
            {this.props.selectedGroupName === "Unassigned" ||
            this.props.selectedGroupName === "unassigned"
              ? "Add to Group"
              : "Change Group"}
          </Button>
        </AddToGroup> */}
        <DeviceControlModal
          updateGroupDeviceAfterGroupChange={
            this.props.updateGroupDeviceAfterGroupChange
          }
          deviceControlModal={this.props.deviceControlModal}
          toggledeviceControlModal={this.props.toggledeviceControlModal}
          selectedDevices={this.props.selectedDevices}
          selectedGroup={this.props.selectedGroup}
          handleGroupVolume={this.props.handleGroupVolume}
          selectedDeviceInGroup={this.props.selectedDeviceInGroup}
          resyncGroupDevices={this.props.resyncGroupDevices}
          restartGroupDevices={this.props.restartGroupDevices}
          isRestrating={this.props.isRestrating}
          isRebooting={this.props.isRebooting}
          isUpdating={this.props.isUpdating}
          isSendingRes={this.props.isSendingRes}
          isSendingOrientation={this.props.isSendingOrientation}
          isSendingTimeZone={this.props.isSendingTimeZone}
          updatingGroup={this.props.updatingGroup}
          isSendingEnv={this.props.isSendingEnv}
          rebootGroupDevices={this.props.rebootGroupDevices}
          updateGroupDevices={this.props.updateGroupDevices}
          legacyUpdateGroupDevices={this.props.legacyUpdateGroupDevices}
          stopGroupDevices={this.props.stopGroupDevices}
          triggerRS232Command={this.props.triggerRS232Command}
          handleAddAndChangeGroup={this.props.handleAddAndChangeGroup}
          electronTags={this.props.electronTags}
          sendingEnvironment={this.props.sendingEnvironment}
          sendGroupElectronAppEnvironment={
            this.props.sendGroupElectronAppEnvironment
          }
          sendGroupResolution={this.props.sendGroupResolution}
          sendGroupOrientation={this.props.sendGroupOrientation}
          sendGrouptimeZone={this.props.sendGrouptimeZone}
          handleRefetch={this.props.handleRefetch}
          Put={this.props.Put}
          Get={this.props.Get}
          handleDeviceGroupState={this.props.handleDeviceGroupState}
          deviceControlModalFromList={this.props.deviceControlModalFromList}
          handleProofOfPlay={this.props.handleProofOfPlay}
          isSendingPopRequest={this.props.isSendingPopRequest}
          roles={this.props.roles}
          // {...this.props}
        >
          <Tooltip
            item={
              <div
                className="iconButton iconButton--last ml-0"
                onClick={() =>
                  this.props.toggledeviceControlModal(this.props.selectedGroup)
                }
              >
                <i className="bx bxs-cog" />
              </div>
            }
            id="header-content-advance-setting"
            tooltipContent="Advanced Setting"
          />

          {/* <Button
            className="singleActionButton"
            disabled={this.props.disabled}
            onClick={() =>
              this.props.toggledeviceControlModal(this.props.selectedGroup)
            }
          >
            Advance Settings
          </Button> */}
        </DeviceControlModal>
      </div>
    );
  }
}
