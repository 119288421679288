export const BrandTagListResources = {
    getContent: {
      url: "/contents/brand_tag_list",
      asyncActions: {
        init: "getBrandList_INIT",
        success: "getBrandList_SUCCESS",
        error: "getBrandList_ERROR"
      }
    }
  };
  
  const initialState = {
    brandList: undefined,
    links: undefined,
    status: undefined,
    statusText: undefined,
    meta: undefined,
    deleted: undefined,
    edited: undefined,
    hasList: undefined,
    loading: undefined,
    hasError: undefined
  };
  
  export function BrandTagList(state = { initialState }, action = {}) {
    const { init, success, error } = BrandTagListResources.getContent.asyncActions;
  
    switch (action.type) {
      case init: {
        return {
          ...state,
          loading: true,
          hasError: undefined
        };
      }
  
      case success: {
        const {
          data: {
            _jsonapi: { data },
            links,
            meta
          },
          status,
          statusText
        } = action.payload;
        return {
          brandList: data,
          links,
          status,
          statusText,
          meta,
          deleted: [],
          edited: [],
          hasList: true,
          loading: false,
          hasError: false
        };
      }
  
      case error: {
        return {
          ...state,
          loading: false,
          hasError: true
        };
      }
  
      default: {
        return state;
      }
    }
  }
  