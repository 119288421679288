import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { remove, differenceBy } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import overlayFactory from "react-bootstrap-table2-overlay";
import { connect } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Tooltip } from "../../../core";

import { columns } from "../utils/archivePlaylistUtils";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

class PlaylistArchiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hiddenColumns: {
        date_created: false,
        date_updated: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      ...prevState,
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  onPageChange = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState(() => ({
        loading: false
      }));
    }, 2000);
  };

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      selectColumnStyle: { width: "40px" },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("selectedPlaylist", [
            ...this.props.selectedPlaylist,
            row
          ]);
        } else {
          const { selectedPlaylist } = this.props;
          remove(selectedPlaylist, { id: row.id });
          this.props.change("selectedPlaylist", selectedPlaylist);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("selectedPlaylist", [
            ...this.props.selectedPlaylist,
            ...rows
          ]);
        } else {
          const { selectedPlaylist } = this.props;
          const formattedPlaylist = differenceBy(selectedPlaylist, rows, "id");
          this.props.change("selectedPlaylist", formattedPlaylist);
        }
      }
    };

    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.playlistList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleUnArchiveItem,
            this.props.handleSort,
            this.props.sortOrder,
            this.state.hiddenColumns
          )}
          columnToggle
        >
          {props => (
            <div>
              <div className="d-flex mt-3 mb-3">
                {this.props.selectedContentsLen > 0 && (
                  <div className="d-flex">
                    {" "}
                    <Tooltip
                      item={
                        <div>
                          <div
                            role="presentation"
                            className="iconButton fas fa-history"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.handleUnArchivePlaylists();
                            }}
                          />
                        </div>
                      }
                      tooltipContent="Restore"
                      id="Unarchive-content"
                    />
                    <Tooltip
                      item={
                        <div>
                          <div
                            role="presentation"
                            className="iconButton fa fa-trash"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.handleDeleteConfirmation();
                            }}
                          />
                        </div>
                      }
                      tooltipContent="Delete"
                      id="delete-archive-content"
                    />
                  </div>
                )}
                <div className="ml-auto">
                  <VisibleColumnToggler
                    hiddenColumns={this.state.hiddenColumns}
                    toggleHiddenColumns={this.toggleHiddenColumns}
                    columnToggleProps={{ ...props.columnToggleProps }}
                  />
                </div>
              </div>

              <BootstrapTable
                ref={this.props.forwardRef}
                classes="contentSectionTable"
                wrapperClasses="table-responsive content-wrap"
                loading={this.props.isLoading}
                bordered={false}
                selectRow={selectRow}
                hover={this.props.playlistList.length > 0}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

// export default ContentTable;
export default connect(state => {
  const selector = formValueSelector("SelectedPlaylistOnArchive");
  return {
    selectedPlaylist: selector(state, "selectedPlaylist") || []
  };
})(
  reduxForm({
    form: "SelectedPlaylistOnArchive"
  })(PlaylistArchiveTable)
);
