/* eslint-disable no-undef */
export function downloader({
  url,
  fetchCallback,
  progressCallback,
  screenID,
  contentThumbnail,
  contentID,
  duration,
  contentType,
  position,
  backgroundColor,
  textColor,
  delay,
  direction,
  fontFamily,
  fontSize,
  dynamicUrl,
  marquee,
  text,
  textAlign,
  timezone,
  title,
  scaleFactor,
  routeDuration,
  playlistThumbnailType
}) {
  // eslint-disable-next-line no-undef
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.setRequestHeader(
    "cache-control",
    "no-cache, must-revalidate, post-check=0, pre-check=0"
  );
  xhr.addEventListener(
    "load",
    () => {
      if (xhr.status === 200) {
        const URL = window.URL || window.webkitURL;
        const blobURL = URL.createObjectURL(xhr.response);
        fetchCallback(
          blobURL,
          screenID,
          contentThumbnail,
          contentID,
          duration,
          contentType,
          position,
          backgroundColor,
          textColor,
          delay,
          direction,
          fontFamily,
          fontSize,
          dynamicUrl,
          marquee,
          text,
          textAlign,
          timezone,
          title,
          scaleFactor,
          routeDuration,
          playlistThumbnailType
        );
      } else {
        error_callback();
      }
    },
    false
  );
  let prev_pc = 0;
  xhr.addEventListener("progress", event => {
    if (event.lengthComputable) {
      const pc = Math.round((event.loaded / event.total) * 100);
      if (pc != prev_pc) {
        prev_pc = pc;
        progressCallback(pc, screenID, contentID);
      }
    }
  });
  xhr.send();
}
