function toDeg(r) {
  const pi = 22 / 7;
  return (180 / pi) * r;
}

function getOrientation(w, h) {
  const angle = Math.round(toDeg(Math.atan(h / w)));
  if (angle > 65) {
    return "verticalLong";
    // eslint-disable-next-line no-else-return
  } else if (angle < 20) {
    return "horizontalLong";
  } else if (angle >= 20 && angle <= 30) {
    return "especialCase";
  } else {
    return "normalized";
  }
}

export function validateWidgetResolution(
  { width, height, verticalScale, horizontalScale },
  value
) {
  const normalWidth = (width * horizontalScale).toFixed(0);
  const normalHeight = (height * verticalScale).toFixed(0);
  const orientation = getOrientation(normalWidth, normalHeight);
  switch (orientation) {
    case "normalized":
      if (value === "3" && (normalWidth < 500 || normalHeight < 330)) {
        return false;
      }
      if (value === "5" && (normalWidth < 500 || normalHeight < 330)) {
        return false;
      }
      if (value === "1" && (normalWidth < 200 || normalHeight < 140)) {
        return false;
      }
      return true;
      break;
    case "horizontalLong":
      if (value === "3" && (normalWidth < 600 || normalHeight < 160)) {
        return false;
      }
      if (value === "5" && (normalWidth < 850 || normalHeight < 160)) {
        return false;
      }
      if (value === "1" && (normalWidth < 200 || normalHeight < 140)) {
        return false;
      }
      return true;
      break;
    case "verticalLong":
      if (value === "3" && (normalWidth < 160 || normalHeight < 450)) {
        return false;
      }
      if (value === "5" && (normalWidth < 180 || normalHeight < 800)) {
        return false;
      }
      if (value === "1" && (normalWidth < 120 || normalHeight < 300)) {
        return false;
      }
      return true;
      break;
    default:
      return true;
  }
}
