import { Component } from "react";

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.content.length !== 0) {
      this.startTimeOut(this.props.currentIndex);
    }
    if (this.props.forwardRef.current) {
      if (this.props.playlistPlayedDuration) {
        this.props.forwardRef.current.currentTime = this.props.playlistPlayedDuration;
      } else {
        this.props.forwardRef.current.currentTime = this.props.playedDuration;
      }
      this.props.forwardRef.current.play();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isForward === true) {
      this.stopTimeOut();
      const duration = this.calculateDuration(this.props.content, nextProps);
      this.props.resetForwardAndBackward();
    }
    if (nextProps.isBackward === true) {
      this.stopTimeOut();
      const duration = this.backwardCalculateDuration(
        this.props.content,
        nextProps
      );
      this.props.resetForwardAndBackward();
    }
  }

  componentWillUnmount() {
    this.stopTimeOut();
  }

  stopTimeOut = () => {
    clearTimeout(this.timeoutID);
    if (this.props.selectedPLC) {
      this.props.selectedPLC.forEach((i, index) => {
        clearTimeout(this[`playlistTimeOut-${index}`]);
      });
    }
  };

  timer = (newIndex, playDurn) =>
    setTimeout(() => {
      this.props.resetPlaylistIndex();
      this.props.resetPlayedDuration();
      this.startTimeOut(newIndex);
    }, playDurn * 1000);

  playlistTimer = (index, total, selectedPLC) =>
    setTimeout(() => {
      if (index > 0) {
        this.props.handlePlaylistIndex(index);
        // if (index === selectedPLC.length - 1) {
        //   this.props.resetSelectedPlaylist();
        // }
      }
    }, total * 1000);

  calculateDuration = async (content, { startTime, forwardTime }) => {
    let playedDuration;
    let selectedPLC = this.props.selectedPLC;
    playedDuration =
      this.props.playedDuration + forwardTime.diff(startTime, "second") + 5;
    this.props.setNewIndexAndPlayedDuration(
      this.props.currentIndex,
      playedDuration
    );
    let playlistDuration;
    let contentDuration;
    let newIndex = this.props.currentIndex;

    if (content[newIndex].plHook) {
      if (selectedPLC) {
        selectedPLC = content[newIndex].plHook && selectedPLC;
      } else {
        this.props.resetSelectedPlaylist();
        selectedPLC =
          content[newIndex].plHook && (await content[newIndex].getContent());
      }
      this.props.setSelectedPlc(selectedPLC);
      playlistDuration = selectedPLC.reduce((total, plc) => {
        return total + plc.duration;
      }, 0);

      contentDuration = playlistDuration;
    } else {
      contentDuration = content[newIndex].duration;
    }

    while (contentDuration < playedDuration) {
      const shouldBeZero = content.length - 1 === newIndex;
      playedDuration -= contentDuration;
      newIndex = shouldBeZero ? 0 : newIndex + 1;
      if (content[newIndex].plHook) {
        selectedPLC =
          content[newIndex].plHook && (await content[newIndex].getContent());

        playlistDuration = selectedPLC.reduce((total, plc) => {
          return total + plc.duration;
        }, 0);

        contentDuration = playlistDuration;
        this.props.setSelectedPlc(selectedPLC);
      } else {
        this.props.resetSelectedPlaylist();
        contentDuration = content[newIndex].duration;
      }
      this.props.setNewIndexAndPlayedDuration(newIndex, playedDuration);
    }

    if (this.props.content.length !== 0) {
      this.startTimeOut(newIndex, playedDuration);
    }
    this.setForwardRef(playedDuration);
    return { playedDuration, newIndex };
  };

  calculatePlaylistDuration = (content, pDuration) => {
    let playlistPlayedDuration = pDuration;
    let newIndex = this.props.playlistIndex;
    let i = 0;

    while (content.length > i) {
      if (content[i].duration < playlistPlayedDuration) {
        newIndex = i + 1;
        playlistPlayedDuration -= content[i].duration;
        // this.props.setNewPlaylistIndex(newIndex);
      }
      i++;
    }
    this.props.setPlaylistPlayedTime(playlistPlayedDuration);
    content.reduce((total, plc, index) => {
      const { duration } = plc;
      let nextPlayTime;
      if (index >= newIndex) {
        let newPlaylistDuration;
        if (newIndex === index) {
          newPlaylistDuration = duration - playlistPlayedDuration;
        } else {
          this.props.resetPlaylistPlayedDuration();
          newPlaylistDuration = duration;
        }
        nextPlayTime = total + newPlaylistDuration;
        this[`playlistTimeOut-${index}`] = this.playlistTimer(index, total);
        return nextPlayTime;
      }
      return 0;
    }, 0);
  };

  backwardCalculateDuration = async (content, { startTime, backwardTime }) => {
    let playedDuration;
    let selectedPLC = this.props.selectedPLC;
    const contentPlayed =
      this.props.playedDuration + backwardTime.diff(startTime, "second");

    this.props.setNewIndexAndPlayedDuration(
      this.props.currentIndex,
      playedDuration
    );

    let newIndex = this.props.currentIndex;
    let playlistDuration;
    let contentDuration;
    if (contentPlayed > 5) {
      playedDuration = contentPlayed - 5;
    } else {
      let remainingDuration = 5 - contentPlayed;
      newIndex =
        newIndex === 0 ? content.length - 1 : this.props.currentIndex - 1;

      // Find if the content is playlist or not
      if (content[newIndex].plHook) {
        if (selectedPLC) {
          // selectedPLC = content[newIndex].plHook && selectedPLC;
          selectedPLC =
            content[newIndex].plHook && (await content[newIndex].getContent());
        } else {
          this.props.resetSelectedPlaylist();
          selectedPLC =
            content[newIndex].plHook && (await content[newIndex].getContent());
        }
        this.props.setSelectedPlc(selectedPLC);
        playlistDuration = selectedPLC.reduce((total, plc) => {
          return total + plc.duration;
        }, 0);

        contentDuration = playlistDuration;
      } else {
        contentDuration = content[newIndex].duration;
      }

      while (contentDuration < remainingDuration) {
        newIndex = newIndex === 0 ? content.length - 1 : newIndex - 1;
        remainingDuration -= contentDuration;
        // Get Playlist Index

        if (content[newIndex].plHook) {
          selectedPLC =
            content[newIndex].plHook && (await content[newIndex].getContent());

          playlistDuration = selectedPLC.reduce((total, plc) => {
            return total + plc.duration;
          }, 0);

          contentDuration = playlistDuration;
          this.props.setSelectedPlc(selectedPLC);
        } else {
          this.props.resetSelectedPlaylist();
          contentDuration = content[newIndex].duration;
        }
      }
      playedDuration = contentDuration - remainingDuration;
    }
    this.props.setNewIndexAndPlayedDuration(newIndex, playedDuration);
    if (this.props.content.length !== 0) {
      this.startTimeOut(newIndex, playedDuration);
    }
    this.setForwardRef(playedDuration);
    return { playedDuration, newIndex };
  };

  // For Video Timing while forward backward or play
  setForwardRef = duration => {
    if (this.props.forwardRef.current) {
      if (this.props.playlistPlayedDuration) {
        this.props.forwardRef.current.currentTime = this.props.playlistPlayedDuration;
      } else {
        this.props.forwardRef.current.currentTime = duration;
      }
      this.props.forwardRef.current.play();
    }
  };

  startTimeOut = async (oldIndex, durn = null) => {
    const s = this.props.content;
    const shouldBeZero = s.length - 1 === oldIndex;
    const newIndex = shouldBeZero ? 0 : oldIndex + 1;
    this.props.setStartTime();
    // const selectedPLC = s[oldIndex].plHook && (await s[oldIndex].getContent());
    let selectedPLC;
    if (this.props.pausedTime || this.props.selectedPLC) {
      selectedPLC = s[oldIndex].plHook && this.props.selectedPLC;
    } else {
      selectedPLC = s[oldIndex].plHook && (await s[oldIndex].getContent());
    }

    let playlistDuration = null;
    // For Playlist
    if (selectedPLC) {
      this.props.setPlaylistStartTime();
      this.props.handlePlaylist(
        oldIndex,
        selectedPLC,
        this.props.playlistIndex
      );
      playlistDuration = selectedPLC.reduce((total, plc) => {
        return total + plc.duration;
      }, 0);

      if (durn) {
        this.calculatePlaylistDuration(selectedPLC, durn);
      } else {
        selectedPLC.reduce((total, plc, index) => {
          const { duration } = plc;
          let nextPlayTime;
          if (index >= this.props.playlistIndex) {
            this.props.setPlaylistStartTime();
            let newPlaylistDuration;
            if (this.props.pausedPlaylistIndex === index) {
              newPlaylistDuration =
                duration - this.props.playlistPlayedDuration;
            } else {
              this.props.resetPlaylistPlayedDuration();
              newPlaylistDuration = duration;
            }
            nextPlayTime = total + newPlaylistDuration;
            this[`playlistTimeOut-${index}`] = this.playlistTimer(
              index,
              total,
              selectedPLC
            );
            return nextPlayTime;
          }
          return 0;
        }, 0);
      }
    } else {
      this.props.handleContentExceptPlaylist(oldIndex, selectedPLC, 0);
    }

    const playDurn = selectedPLC ? playlistDuration : s[oldIndex].duration;
    if (this.props.pausedTime && this.props.pausedIndex === oldIndex) {
      const remainingDuration = playDurn - this.props.playedDuration;
      this.timeoutID = this.timer(newIndex, remainingDuration);
    } else if (durn) {
      const remainingDuration = playDurn - durn;
      this.timeoutID = this.timer(newIndex, remainingDuration);
      this.props.removeForwardTime();
    } else {
      this.props.resetPlayedDuration();
      this.timeoutID = this.timer(newIndex, playDurn);
    }
  };

  render() {
    if (
      this.props.content.length === 0 ||
      this.props.currentIndex === null ||
      this.props.isStart === 0
    ) {
      return null;
    }
    if (this.props.selectedPLC && this.props.playlistIndex !== null) {
      return this.props.selectedPLC[this.props.playlistIndex].comp;
    }
    return this.props.content[[this.props.currentIndex]].comp;
  }
}

export default Screen;
