/** Library Import */
import React, { Component } from "react";
import moment from "moment";
import { remove, findIndex } from "lodash";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import BigCalendar from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { checkOverlap, dateCompare } from "../utils/eventOverlapChecker";

import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { Dialog } from "../../../core";
import { allEventsViewDataGenerator } from "./rrules";
import EventForm from "../forms/eventForm";

import { createEvent } from "./singleEventCreate";
import { showNotification } from "../../../core/modal/toster";

/** CSS IMPORT */
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

// import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../../../assets/stylesheet/big-calendar.css";
import { customToolbar } from "./customToolBarForCalendar";

const uuidv4 = require("uuid/v4");

const CustomEvent = ({ event }) => (
  <div>
    <span style={{ marginRight: event.parent.multiday && "16px" }}>
      {event.title}
    </span>
    {event.parent.multiday && (
      <span style={{ fontSize: "80%" }}>
        {moment(event.start).format("ddd hh:mm A")}
        <i className="ml-1 mr-1 bx bx-minus" />
        {moment(event.end).format("ddd hh:mm A")}
      </span>
    )}
  </div>
);

/** Local Input */
const localizer = BigCalendar.momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

class Calendar extends Component {
  constructor(props) {
    super(props);
    // this.props.Get(campaignResources.getCampaign);

    this.state = {
      currentView: "week",
      multipleEventDrag: false,
      modal: false,
      isResize: true,
      deleteModal: {
        id: undefined,
        modal: false,
        body: "Are you sure you want to delete this calendar.",
        title: "Confirm Delete"
      },
      dragValue: "",
      selectedOption: false
    };
    this.eventView = [];
    props.change("calendar", props.allEvents);
  }

  toggleModal = () => {
    this.setState(state => ({ modal: !state.modal }));
    // const element = document.getElementById("clickToAddEvent");
    // if (this.props.action === "View" && this.state.modal === true && element) {
    //   element.parentNode.removeChild(element);
    // }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allEvents != this.props.allEvents) {
      this.handleCalendarChange(nextProps.allEvents);
    }
  }

  handleCalendarChange = event => {
    this.props.change("calendar", event);
  };

  /** Create Event in Calender */
  handleSelectEvent = ({ start, end }) => {
    // eslint-disable-next-line prettier/prettier
    window.localStorage.setItem(
      "eventDate",
      JSON.stringify({ ...createEvent({ start, end }), action: "Create" })
    );
    this.toggleModal();
  };

  toggleModalForMultipleDrag = flag => {
    if (flag === "resize") {
      this.setState({ isResize: true });
    } else {
      this.setState({ isResize: false });
    }
    this.setState(state => ({ multipleEventDrag: !state.multipleEventDrag }));
    this.setState({ selectedOption: false });
  };

  moveEvent = value => {
    const allTotalEventsForValidation = allEventsViewDataGenerator(
      this.props.totalEvents
    );
    const formattedValue = value;
    formattedValue.title = value.event.title;
    const latestEvents = [...allTotalEventsForValidation].map(event => {
      const checkEventStartDate = dateCompare(
        event.start,
        formattedValue.event.start
      );
      const checkEventEndDate = dateCompare(
        event.end,
        formattedValue.event.end
      );

      if (
        event.eventId === formattedValue.event.eventId &&
        checkEventStartDate === 0 &&
        checkEventEndDate === 0
      ) {
        return formattedValue;
      }
      return event;
    });
    const result = checkOverlap(latestEvents);
    // if theres error show the error
    if (result.length > 1) {
      this.props.showNotification(
        `${result[0].title} event and ${result[1].title} event cannot be played at the same time.`,
        "danger"
      );
      return 0;
    }
    this.props.handleCalendarAction("View");
    this.setState({ dragValue: value });
    const { event } = value;
    const allEvents = this.props.totalEvents;
    const newStart = moment(value.start).format("YYYY-MM-DDTHH:mm");
    const newEnd = moment(value.end).format("YYYY-MM-DDTHH:mm");
    const {
      id,
      title,
      start,
      end,
      campaignName,
      eventId,
      _destroy,
      parent,
      hasManyEvents
    } = event;
    const { repeat, repeatFreq, excludedDates } = parent;
    if (!hasManyEvents) {
      const old = remove(allEvents, { eventId: parent.eventId });
      const newParent = {
        ...parent,
        campaignName,
        title,
        repeat,
        repeatFreq,
        excludedDates,
        start: newStart,
        end: newEnd
      };
      this.props.change("calendar", [...allEvents, { ...newParent }]);
    } else {
      this.toggleModalForMultipleDrag("move");
    }
  };

  multipleEventChangeMove = () => {
    this.toggleModalForMultipleDrag();
    const allEvents = this.props.totalEvents;
    const value = this.state.dragValue;
    const { event } = value;
    const newStart = moment(value.start).format("YYYY-MM-DDTHH:mm");
    const newEnd = moment(value.end).format("YYYY-MM-DDTHH:mm");
    const {
      id,
      title,
      start,
      end,
      campaignName,
      eventId,
      _destroy,
      parent,
      hasManyEvents
    } = event;
    const { repeat, repeatFreq, excludedDates } = parent;
    let newStartDate = "";
    let newEndDate = "";
    if (this.state.selectedOption) {
      const index = findIndex(allEvents, { eventId: parent.eventId });
      allEvents[index].excludedDates = [
        ...allEvents[index].excludedDates,
        `${moment(start).format("YYYY-MM-DDThh:mm:ssZ")}`
      ];
      const data = {
        eventId: uuidv4(),
        campaignName,
        title,
        repeat,
        repeatFreq,
        excludedDates,
        start: newStart,
        end: newEnd
      };
      this.props.change("calendar", [{ ...data }, ...allEvents]);
    } else {
      const currentEvent = allEvents.filter(a =>
        a.id ? a.id === eventId : a.eventId === eventId
      );
      const a = moment(currentEvent[0].start).format("YYYY-MM-DD");
      const b = moment(start).format("YYYY-MM-DD");
      const currentEventStartDate = moment(currentEvent[0].start).startOf(
        "day"
      );
      const currentEventEndDate = moment(currentEvent[0].end).startOf("day");
      if (a === b) {
        const diffBetweenStartAndEnd = currentEventEndDate.diff(
          currentEventStartDate,
          "days"
        );
        newStartDate = moment(newStart).format("YYYY-MM-DD");
        newEndDate = moment(newStart)
          .add(diffBetweenStartAndEnd, "days")
          .format("YYYY-MM-DD");
      } else {
        const forwardOffset = moment(b)
          .startOf("day")
          .diff(currentEventStartDate, "days");
        const backwardOffset = currentEventEndDate.diff(
          moment(b).startOf("day"),
          "days"
        );
        newStartDate = moment(newStart)
          .subtract(forwardOffset, "days")
          .format("YYYY-MM-DD");
        newEndDate = moment(newStart)
          .add(backwardOffset, "days")
          .format("YYYY-MM-DD");
      }
      const startTime = moment(value.start).format("HH:mm");
      const endTime = moment(value.end).format("HH:mm");
      const old = remove(allEvents, { eventId: parent.eventId });
      const newParent = {
        ...parent,
        campaignName,
        title,
        repeat,
        repeatFreq,
        excludedDates,
        start: `${newStartDate}T${startTime}`,
        end: `${newEndDate}T${endTime}`
      };
      const allTotalEventsForValidation = allEventsViewDataGenerator(allEvents);
      const newParentEventsForValidation = allEventsViewDataGenerator([
        newParent
      ]);
      const latestEvents = [
        ...allTotalEventsForValidation,
        ...newParentEventsForValidation
      ];

      const result = checkOverlap(latestEvents);
      // if theres error show the error
      if (result.length > 1) {
        this.props.showNotification(
          `${result[0].title} event and ${result[1].title} event cannot be played at the same time.`,
          "danger"
        );
        this.props.change("calendar", [...allEvents, ...currentEvent]);
        return 0;
      }
      this.props.change("calendar", [...allEvents, { ...newParent }]);
    }
  };

  resizeEvent = value => {
    const allTotalEventsForValidation = allEventsViewDataGenerator(
      this.props.totalEvents
    );
    const formattedValue = value;
    formattedValue.title = value.event.title;
    const latestEvents = [...allTotalEventsForValidation].map(event => {
      const checkEventStartDate = dateCompare(
        event.start,
        formattedValue.event.start
      );
      const checkEventEndDate = dateCompare(
        event.end,
        formattedValue.event.end
      );

      if (
        event.eventId === formattedValue.event.eventId &&
        checkEventStartDate === 0 &&
        checkEventEndDate === 0
      ) {
        return formattedValue;
      }
      return event;
    });
    const result = checkOverlap(latestEvents);
    // if theres error show the error
    if (result.length > 1) {
      this.props.showNotification(
        `${result[0].title} event and ${result[1].title} event cannot be played at the same time.`,
        "danger"
      );
      return 0;
    }
    if (this.state.currentView !== "month") {
      this.props.handleCalendarAction("View");
      this.setState({ dragValue: value });
      const { event } = value;
      const allEvents = this.props.totalEvents;
      const newStart = moment(value.start).format("YYYY-MM-DDTHH:mm");
      const newEnd = moment(value.end).format("YYYY-MM-DDTHH:mm");
      const {
        id,
        title,
        start,
        end,
        campaignName,
        eventId,
        _destroy,
        parent,
        hasManyEvents
      } = event;
      const { repeat, repeatFreq, excludedDates } = parent;
      if (!hasManyEvents) {
        const old = remove(allEvents, { eventId: parent.eventId });
        const newParent = {
          ...parent,
          campaignName,
          title,
          repeat,
          repeatFreq,
          excludedDates,
          start: newStart,
          end: newEnd
        };
        this.props.change("calendar", [...allEvents, { ...newParent }]);
      } else {
        this.toggleModalForMultipleDrag("resize");
      }
    }
  };

  multipleEventChangeResize = () => {
    this.toggleModalForMultipleDrag();
    const allEvents = this.props.totalEvents;
    const value = this.state.dragValue;
    const { event } = value;
    const newStart = moment(value.start).format("YYYY-MM-DDTHH:mm");
    const newEnd = moment(value.end).format("YYYY-MM-DDTHH:mm");
    const {
      id,
      title,
      start,
      end,
      campaignName,
      eventId,
      _destroy,
      parent,
      hasManyEvents
    } = event;
    const { repeat, repeatFreq, excludedDates } = parent;

    if (this.state.selectedOption) {
      const index = findIndex(allEvents, { eventId: parent.eventId });
      allEvents[index].excludedDates = [
        ...allEvents[index].excludedDates,
        `${moment(start).format("YYYY-MM-DDThh:mm:ssZ")}`
      ];
      const data = {
        eventId: uuidv4(),
        campaignName,
        title,
        repeat,
        repeatFreq,
        excludedDates,
        start: newStart,
        end: newEnd
      };
      this.props.change("calendar", [{ ...data }, ...allEvents]);
    } else {
      const currentEvent = allEvents.filter(a =>
        a.id ? a.id === eventId : a.eventId === eventId
      );
      const startTime = moment(value.start).format("HH:mm");
      const endTime = moment(value.end).format("HH:mm");
      const old = remove(allEvents, { eventId: parent.eventId });
      const newParent = {
        ...parent,
        campaignName,
        title,
        repeat,
        repeatFreq,
        excludedDates,
        start: `${moment(currentEvent[0].start).format(
          "YYYY-MM-DD"
        )}T${startTime}`,
        end: `${moment(currentEvent[0].end).format("YYYY-MM-DD")}T${endTime}`
      };
      const allTotalEventsForValidation = allEventsViewDataGenerator(allEvents);
      const newParentEventsForValidation = allEventsViewDataGenerator([
        newParent
      ]);
      const latestEvents = [
        ...allTotalEventsForValidation,
        ...newParentEventsForValidation
      ];
      const result = checkOverlap(latestEvents);
      // if theres error show the error
      if (result.length > 1) {
        this.props.showNotification(
          `${result[0].title} event and ${result[1].title} event cannot be played at the same time.`,
          "danger"
        );
        this.props.change("calendar", [...allEvents, ...currentEvent]);
        return 0;
      }
      this.props.change("calendar", [...allEvents, { ...newParent }]);
    }
  };

  /** Edit event in calendar */
  onSelectEvent = ({
    id,
    title,
    start,
    end,
    campaignName,
    eventId,
    _destroy,
    parent,
    hasManyEvents
  }) => {
    window.localStorage.setItem(
      "eventDate",
      JSON.stringify({
        id,
        title,
        start: moment(start).format("YYYY-MM-DDTHH:mm"),
        end: moment(end).format("YYYY-MM-DDTHH:mm"),
        campaignName,
        eventId,
        _destroy,
        repeat: parent.repeat,
        excludedDates: parent.excludedDates,
        exclude: parent.excludedDates.length > 0,
        repeatFreq: parent.repeatFreq,
        parent,
        hasManyEvents,
        action: "Edit"
      })
    );
    this.toggleModal();
  };

  handleDeleteConfirmation = (event, name) => {
    const { deleteModal } = this.state;
    deleteModal.modal = true;
    deleteModal.id = event;
    deleteModal.body = (
      <span>
        <small>Are you sure you want to delete </small>
        <strong>{name}</strong>?
      </span>
    );

    this.setState({ deleteModal });
  };

  toggleDeleteModal = flag => {
    const { deleteModal } = this.state;
    deleteModal.modal = flag;
    this.setState({ deleteModal });
  };

  handleDelete = () => {
    this.props.handleCalendarAction("View");
    const allEvents = this.props.totalEvents;
    const {
      deleteModal: {
        id: { allEvent, start, parent, toggle, hasManyEvents }
      },
      deleteModal
    } = this.state;
    if (allEvent) {
      // checking if user wants to delete a single event or all of the associate events
      remove(allEvents, { eventId: parent.eventId });
      parent._destroy = "1";
      this.props.change("calendar", [...allEvents, { ...parent }]);
      deleteModal.modal = false;
      deleteModal.id = undefined;
      this.setState({
        deleteModal
      });
      toggle(); // to remove confirmation popup
    } else {
      // checking if the event is assosciated with multiple events
      if (hasManyEvents) {
        const index = findIndex(allEvents, { eventId: parent.eventId });
        allEvents[index].excludedDates = [
          ...allEvents[index].excludedDates,
          `${moment(start).format("YYYY-MM-DDThh:mm:ssZ")}`
        ];
        this.props.change("calendar", [...allEvents]);
      }
      // if the event is non-recurring event
      else {
        remove(allEvents, { eventId: parent.eventId });
        parent._destroy = "1";
        this.props.change("calendar", [...allEvents, { ...parent }]);
      }

      deleteModal.modal = false;
      deleteModal.id = undefined;
      this.setState({
        deleteModal
      });
      toggle();
    }
    return 0;
  };

  handleChangingView = view => {
    this.setState({ currentView: view });
  };

  render() {
    const { multipleEventDrag, modal, deleteModal } = this.state;
    this.eventView = allEventsViewDataGenerator(this.props.totalEvents);
    return (
      <React.Fragment>
        <Dialog
          externalControl
          showHandler={false}
          modal={deleteModal.modal}
          body={deleteModal.body}
          title={deleteModal.title}
          toggleModel={this.toggleDeleteModal}
          primaryMethod={this.handleDelete}
        />
        <Field component="input" hidden name="calendar" />
        <DragAndDropCalendar
          step={15}
          selectable
          localizer={localizer}
          events={allEventsViewDataGenerator(this.props.totalEvents)}
          resizable
          onView={e => this.handleChangingView(e)}
          // onDragStart={console.log}
          onEventDrop={this.moveEvent}
          onEventResize={e =>
            e.event.parent.multiday ? undefined : this.resizeEvent(e)
          }
          defaultView={BigCalendar.Views.WEEK}
          defaultDate={this.currentDate}
          // showMultiDayTimes
          onSelectEvent={this.onSelectEvent}
          onSelectSlot={this.handleSelectEvent}
          views={["month", "week", "day"]}
          scrollToTime={new Date()}
          components={{
            event: CustomEvent,
            toolbar: props =>
              customToolbar(
                props,
                this.props.calendarTitleChange,
                this.props.calendarTitle,
                this.props.activateIput,
                this.props.disableInput,
                this.props.calendarInputRef,
                this.props.handleCalendarSave,
                this.props.disableCalendar,
                this.props.editCalendar,
                this.props.handleCalendarAction,
                this.props.calendarId,
                this.props.action,
                this.props.handleCancelChanges,
                this.props.calendarFormEventValues,
                this.props.toggleCalendarList,
                this.props.isCalendarListOpen
              )
          }}
        />
        {multipleEventDrag && (
          <Modal
            isOpen={multipleEventDrag}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={() => this.toggleModalForMultipleDrag()}>
              Edit recurring event{" "}
            </ModalHeader>
            <ModalBody className="globalForm">
              <form>
                <div className="radio-toolbar mt-3 mb-3">
                  <div className="mb-2">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={this.state.selectedOption === true}
                      onChange={e => this.setState({ selectedOption: true })}
                    />
                    <Label htmlFor="single" className="label pl-4">
                      This event
                    </Label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="all"
                      value="all"
                      checked={this.state.selectedOption === false}
                      onChange={e => this.setState({ selectedOption: false })}
                    />
                    <Label htmlFor="all" className="label pl-4">
                      This and following events
                    </Label>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter className="d-flex">
              <Button
                className="btn-outline-primary"
                onClick={this.toggleModalForMultipleDrag}
              >
                Cancel
              </Button>
              <Button
                className="primaryButton mt-0"
                onClick={
                  this.state.isResize
                    ? this.multipleEventChangeResize
                    : this.multipleEventChangeMove
                }
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        )}
        {modal && (
          <EventForm
            modal={modal}
            change={this.props.change}
            toggleModal={this.toggleModal}
            handleDeleteConfirmation={this.handleDeleteConfirmation}
            allEvents={this.props.totalEvents}
            handleCalendarAction={this.props.handleCalendarAction}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  const { typeOfAction, events = [] } = props;

  const c = formValueSelector("createCalendar");
  const allEvents = events || [];
  const formValue = c(state, "calendar") || [];

  return {
    typeOfAction: typeOfAction || "create",
    allEvents,
    totalEvents: formValue
  };
}

export default connect(
  mapStateToProps,
  { showNotification, change }
)(
  reduxForm({
    form: "createCalendar"
  })(Calendar)
);
