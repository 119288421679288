import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import Resize from "../../utls/resize";
// import { Tooltip } from "../../../../core";
import { validateWidgetResolution } from "../../../../helper/widgetValidation";
import { SecondAdded } from "../../utls/helper";

function checkedDimension(screen, widget) {
  const { width, height, verticalScale, horizontalScale } = screen;
  const { days } = widget;

  return validateWidgetResolution(
    { width, height, verticalScale, horizontalScale },
    days.toString()
  );
}
const handleSnapMessage = (item, screen, campaign, fn) => {
  setTimeout(() => {
    campaign.setSnapEnableForContent(
      screen.localID,
      item.contentLocalID,
      false
    );
    fn(campaign);
  }, 4000);
};

const TimeParser = contents => {
  const d = SecondAdded(contents);
  return (
    <span className="campaignStudioWrapper__content-duration">
      {`0${d.minute}`.slice(-2)}:{`0${d.second}`.slice(-2)}
    </span>
  );
};

export default function Widget({
  item,
  screen,
  campaign,
  calculateTimeInSecond,
  handleContentTileClick,
  setIsSelected,
  updateCampaign,
  handleToggleWidgetEdit,
  setCurrentlySelected,
  currentlySelected
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div
      id="mps-unique-button-inside-container-important"
      key={item.contentLocalID}
      style={{
        width: "100%",
        height: "60px",
        paddingRight: "2px",
        paddingLeft: "2px",
        paddingTop: "2px",
        cursor: "grabbing"
      }}
      onClick={e => handleContentTileClick(e, item, setIsSelected)}
      role="presentation"
    >
      {/* <Resize
        contentID={item.contentLocalID}
        screenID={screen.localID}
        setCurrentlySelected={setCurrentlySelected}
        currentlySelected={currentlySelected}
      > */}
      <div
        style={{
          // width: "75%",
          height: "80%",
          borderRadius: "3px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundColor: item.color
        }}
      >
        {TimeParser([item])}
        {item.isSnapped && (
          <span
            className="campaignStudioWrapper__snapMessage"
            style={{ right: "34px" }}
          >
            Snapped!
          </span>
        )}
        {item.isSnapped &&
          handleSnapMessage(item, screen, campaign, updateCampaign)}
        <span className="d-flex justify-content-center align-item-center campaignStudioWrapper__singleTimeLine__widget-title">
          <i className={`${item.icon} mt-1 mr-1`} />
          {item.type === "PicToScreen" ? "pic2screen" : item.type}
        </span>
        {item.type === "Weather" && !checkedDimension(screen, item) && (
          <span
            id={`TooltipExample-${item.contentLocalID}`}
            style={{
              fontSize: "14px",
              position: "absolute",
              top: "0px",
              right: "2px",
              borderRadius: "3px",
              cursor: "pointer",
              padding: "1px"
            }}
          >
            <i
              className="fas fa-info-circle"
              style={{ color: "yellow", fontSize: "20px" }}
            />
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target={`TooltipExample-${item.contentLocalID}`}
              toggle={toggle}
            >
              Screen size is too small.
            </Tooltip>{" "}
          </span>
        )}
      </div>
      {/* </Resize> */}
    </div>
  );
}
