import React, { Component } from "react";

class PausedScreen extends Component {
  async componentDidMount() {
    await this.props.setPausedIndex(
      this.props.currentIndex,
      this.props.playlistIndex
    );
    if (this.props.forwardRef.current) {
      this.pauseVideo();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isForward === true) {
      this.props.resetForwardAndBackward();
      const duration = this.calculateDuration(this.props.content, nextProps);
      // this.setForwardRef(duration.playedDuration);
    }
    if (nextProps.isBackward === true) {
      const duration = this.backwardCalculateDuration(
        this.props.content,
        nextProps
      );
      // this.setForwardRef(duration.playedDuration);
      this.props.resetForwardAndBackward();
    }

    this.setForwardRef();
  }

  calculateDuration = async (content, { startTime, forwardTime }) => {
    this.props.resetForwardAndBackward();
    let playedDuration = 0;
    let selectedPLC = this.props.selectedPLC;
    playedDuration = this.props.playedDuration + 5;
    this.props.setNewIndexAndPlayedDuration(
      this.props.currentIndex,
      playedDuration
    );

    let newIndex = this.props.currentIndex;
    let playlistDuration;
    let contentDuration;

    // Find Content Duration (playlist or image)
    if (content[newIndex].plHook) {
      if (selectedPLC) {
        selectedPLC = content[newIndex].plHook && selectedPLC;
      } else {
        this.props.resetSelectedPlaylist();
        selectedPLC =
          content[newIndex].plHook && (await content[newIndex].getContent());
      }
      this.props.setSelectedPlc(selectedPLC);
      playlistDuration = selectedPLC.reduce((total, plc) => {
        return total + plc.duration;
      }, 0);

      contentDuration = playlistDuration;
    } else {
      contentDuration = content[newIndex].duration;
    }

    // New Index of content
    while (contentDuration < playedDuration) {
      const shouldBeZero = content.length - 1 === newIndex;
      playedDuration -= contentDuration;
      newIndex = shouldBeZero ? 0 : newIndex + 1;

      // Check if playlist or Not
      if (content[newIndex].plHook) {
        selectedPLC =
          content[newIndex].plHook && (await content[newIndex].getContent());

        playlistDuration = selectedPLC.reduce((total, plc) => {
          return total + plc.duration;
        }, 0);

        contentDuration = playlistDuration;
        this.props.setSelectedPlc(selectedPLC);
      } else {
        this.props.resetSelectedPlaylist();
        contentDuration = content[newIndex].duration;
      }
      this.props.setNewIndexAndPlayedDuration(newIndex, playedDuration);
      this.setForwardRef(playedDuration);
    }

    // Get playlist Value and Index
    let playlistValue;
    if (selectedPLC) {
      playlistValue = this.calculatePlaylistDuration(
        selectedPLC,
        playedDuration
      );
      this.props.setPausedIndexForForward(newIndex, playlistValue.newIndex);
      this.props.handlePlaylistIndex(playlistValue.newIndex);
    } else {
      this.props.setPausedIndexForForward(newIndex, 0);
      this.setForwardRef(playedDuration);
    }

    this.setForwardRef(playedDuration);

    return { playedDuration, newIndex };
  };

  calculatePlaylistDuration = (content, pDuration) => {
    let playlistPlayedDuration = pDuration;
    let newIndex = this.props.playlistIndex;
    let i = 0;

    while (content.length > i) {
      if (content[i].duration < playlistPlayedDuration) {
        newIndex = i + 1;
        playlistPlayedDuration -= content[i].duration;
        // this.props.setNewPlaylistIndex(newIndex);
      }
      i++;
    }
    this.props.setPlaylistPlayedTime(playlistPlayedDuration);
    return { newIndex, playlistPlayedDuration };
  };

  backwardCalculateDuration = async (content, { startTime, backwardTime }) => {
    this.props.resetForwardAndBackward();
    let playedDuration;
    let selectedPLC = this.props.selectedPLC;
    const contentPlayed = this.props.playedDuration;

    this.props.setNewIndexAndPlayedDuration(
      this.props.currentIndex,
      playedDuration
    );

    let newIndex = this.props.currentIndex;
    let playlistDuration;
    let contentDuration;
    // Get Index Of Content
    if (contentPlayed > 5) {
      playedDuration = contentPlayed - 5;
    } else {
      let remainingDuration = 5 - contentPlayed;
      newIndex =
        newIndex === 0 ? content.length - 1 : this.props.currentIndex - 1;
      // Find if the content is playlist or not
      if (content[newIndex].plHook) {
        if (selectedPLC) {
          selectedPLC =
            content[newIndex].plHook && (await content[newIndex].getContent());
        } else {
          this.props.resetSelectedPlaylist();
          selectedPLC =
            content[newIndex].plHook && (await content[newIndex].getContent());
        }
        this.props.setSelectedPlc(selectedPLC);
        playlistDuration = selectedPLC.reduce((total, plc) => {
          return total + plc.duration;
        }, 0);

        contentDuration = playlistDuration;
      } else {
        this.props.resetSelectedPlaylist();
        contentDuration = content[newIndex].duration;
      }

      while (contentDuration < remainingDuration) {
        newIndex = newIndex === 0 ? content.length - 1 : newIndex - 1;
        remainingDuration -= contentDuration;
        // Get Playlist Index

        if (content[newIndex].plHook) {
          selectedPLC =
            content[newIndex].plHook && (await content[newIndex].getContent());

          playlistDuration = selectedPLC.reduce((total, plc) => {
            return total + plc.duration;
          }, 0);

          contentDuration = playlistDuration;
          this.props.setSelectedPlc(selectedPLC);
        } else {
          this.props.resetSelectedPlaylist();
          contentDuration = content[newIndex].duration;
        }
      }

      playedDuration = contentDuration - remainingDuration;
    }
    this.setForwardRef(playedDuration);
    this.props.setNewIndexAndPlayedDuration(newIndex, playedDuration);

    // Get Playlist Index and played Duration
    let playlistValue;
    if (selectedPLC) {
      playlistValue = this.calculatePlaylistDuration(
        selectedPLC,
        playedDuration
      );
      this.props.setPausedIndexForForward(newIndex, playlistValue.newIndex);
      this.props.handlePlaylistIndex(playlistValue.newIndex);
    } else {
      this.props.setPausedIndexForForward(newIndex, 0);
    }
    this.setForwardRef(playedDuration);
    return { playedDuration, newIndex };
  };

  setForwardRef = duration => {
    if (this.props.forwardRef.current) {
      if (this.props.playlistPlayedDuration) {
        this.props.forwardRef.current.currentTime = this.props.playlistPlayedDuration;
      } else {
        this.props.forwardRef.current.currentTime =
          duration || this.props.playedDuration;
      }
      this.props.forwardRef.current.pause();
    }
  };

  pauseVideo = () => {
    if (this.props.playlistPlayedDuration) {
      this.props.forwardRef.current.currentTime = this.props.playlistPlayedDuration;
    } else {
      this.props.forwardRef.current.currentTime = this.props.playedDuration;
    }
    // this.props.forwardRef.current.currentTime = this.props.playedDuration;
    this.props.forwardRef.current.pause();
  };

  render() {
    if (this.props.content.length === 0 || this.props.currentIndex === null) {
      return null;
    }
    if (this.props.selectedPLC && this.props.playlistIndex !== null) {
      return this.props.selectedPLC[this.props.playlistIndex].comp;
    }
    return this.props.content[[this.props.currentIndex]].comp;
  }
}

export default PausedScreen;
