/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import RouteAllowed from "../../../helper/isRouteAllowed";

export const CalendarHeaderForDesktop = props => (
  <div className=" contentHeader calendarHeaderForMobile">
    <RouteAllowed
      roles={props.roles}
      permission={props.permission.calendar.creates}
    >
      <button
        className="mr-auto primaryButton primaryButton--contentHeader"
        onClick={() => props.handleCalendarCreate()}
        type="submit"
      >
        <i className="fa fa-plus calendarCreatePlusIcon" />
        Create
      </button>
    </RouteAllowed>{" "}
  </div>
);
