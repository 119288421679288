import React, { Component } from "react"
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";
import { Link } from "react-router-dom"
import { Resources } from "../redux/headerNotificationRedux";
import moment from "moment";
import ReactPaginate from "react-paginate";


import BrandNotificationHeader from "./brandNotificationHeader"
import { columns } from "./brandNotificationUtils"




export default class brandNotificationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hiddenColumns: {
                title: false,
                brandName: false,
                startDate: false,
                endDate: false,
                totalDuration: false,
                ratePerMinute: false,
                createdAt: false,
            },
            filterParams: {
                gte_date: undefined,
                lte_date: undefined,
                is_checked: "",
            },
            activeFilter: false,
            selectedRows: [],
            isLoading: false,
            forcePage: 0,
        };
    }


    getFilterUrl = () => {
        let url = `brand_notifications?`
        const { filterParams } = this.state;
        for (const key in filterParams) {
            if (key.split("_")[1] === "date") {
                if (filterParams[key]) {
                    let date = moment(filterParams[key]).format("YYYY-MM-DD");
                    url += `${key}=${date}&`
                }
            } else {
                if (filterParams[key] !== "") {
                    url += `${key}=${filterParams[key]}&`
                }
            }
        }
        return url.slice(0, -1)
    }

    handleDateChange = (input, is_startDate) => {
        this.setState((prevState) => {
            const { filterParams } = prevState
            return {
                filterParams: {
                    gte_date: is_startDate ? input : filterParams.gte_date,
                    lte_date: !is_startDate ? input : filterParams.lte_date
                }
            }
        }, this.getFilteredNotifications)
    }

    setFilterParams = (params, getNotifications) => {
        this.setState(prevState => {
            const { filterParams } = prevState;
            return {
                filterParams: {
                    ...filterParams,
                    ...params
                },
                activeFilter: true,
            }
        }, () => {
            this.getFilteredNotifications()
        })
    }

    getFilteredNotifications = () => {
        const url = this.getFilterUrl();
        this.props.getAllNotifications(url);
    }



    toggleHiddenColumns = (colName, flag) => {
        this.setState(prevState => ({
            hiddenColumns: {
                ...prevState.hiddenColumns,
                [colName]: flag
            }
        }));
    }

    handleNodata = () => <span className="null-message-info">No Notification</span>;

    toggleCheckMark = async (notificationId, value) => {
        const { postIsChecked } = Resources;
        postIsChecked.url = `brand_notifications/${notificationId}/checked?value=${value}`
        this.setState({ [`loading-${notificationId}-checkbox`]: true })
        await this.props.Post(postIsChecked)
            .then(data => {
                this.state.activeFilter ? this.getFilteredNotifications() : this.props.getAllNotifications()
                this.setState({ [`loading-${notificationId}-checkbox`]: false })
            });
    }

    handlePageClick = (data, moveToPrevious = false) => {
        window.scrollTo(0, 0);
        this.setState({ isLoading: true, forcePage: undefined });
        const { getAllBrandNotifications } = Resources;
        if (moveToPrevious) {
            if (this.state.activeFilter) {
                getAllBrandNotifications.url = this.getFilterUrl() + `&page=${data.page - 1}&per_page=20`
                this.props.Get(getAllBrandNotifications).then(() => {
                    this.setState({ forcePage: data.page - 2, isLoading: false });
                });
            } else {
                getAllBrandNotifications.url = `brand_notifications?page=${data.page - 1}&per_page=20`;
                this.props.Get(getAllBrandNotifications).then(() => {
                    this.setState({ forcePage: data.page - 2, isLoading: false });
                });
            }
        } else {
            const { selected } = data;
            if (this.state.activeFilter) {
                getAllBrandNotifications.url = `${this.getFilterUrl}&page=${selected + 1}&per_page=20`;
                this.props.Get(getAllBrandNotifications).then(() => {
                    this.setState({ forcePage: selected, isLoading: false });
                });
            } else {
                getAllBrandNotifications.url = `brand_notifications?page=${selected + 1}&per_page=20`;
                this.props
                    .Get(getAllBrandNotifications)
                    .then(() => this.setState({ forcePage: selected, isLoading: false }));
            }
        }
    };



    render() {
        const expandRow = {
            renderer: (row, rowIndex) => (
                <ListGroup className="h-auto">
                    <h4>Contents</h4>
                    {row.attributes.contents.map(content => (
                        <ListGroupItem>
                            <ListGroupItemHeading>{content.name}</ListGroupItemHeading>
                            <ListGroupItemText>{content.display_name}</ListGroupItemText>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            )
        }
        const selectRow = {
            mode: "checkbox",
            selectColumnStyle: { width: "40px" },
            onSelect: (row, isSelect,) => {
                this.toggleCheckMark(row.id, isSelect)
            },
            hideSelectAll: true,
            selected: this.props.checkedList,
            clickToExpand: true,
            clickToSelect: true,
            bgColor: '#f4f4f4'
        }

        return (
            <div >
                <ToolkitProvider
                    forwardRef={this.props.forwardRef}
                    keyField="id"
                    data={this.props.brandNotifications}
                    columns={columns(
                        this.state.hiddenColumns,
                    )}
                    columnToggle
                >
                    {props => (
                        <div>
                            <BrandNotificationHeader
                                getAllNotifications={this.props.getAllNotifications}
                                getFilteredNotifications={this.getFilteredNotifications}
                                getFilterUrl={this.getFilterUrl}
                                handleDateChange={this.handleDateChange}
                                setFilterParams={this.setFilterParams}
                                handleSubmit={this.getFilteredNotifications}
                                filterParams={this.state.filterParams}
                            />
                            {/* {this.props.brandNotifications.length <= 0
                                ? (
                                    <div id="notfound">
                                        <div className="notfound">
                                            <div className="notfound-404">
                                                <i className="fas fa-bell fa-4x" />

                                                <h3 className="mt-3"> No notification found!</h3>
                                            </div>
                                            <Link to="/dashboard">
                                                Go to Homepage
                                                <i className="fa fa-arrow-right" />
                                            </Link>
                                        </div>
                                    </div>
                                )
                                : */}
                            <BootstrapTable
                                hover
                                wrapperClasses="table-responsive content-wrap"
                                // ref={this.props.forwardRef}
                                bordered={false}
                                //   selectRow={selectRow}
                                loading={this.props.isLoading}
                                overlay={overlayFactory({
                                    spinner: true,
                                    styles: {
                                        overlay: base => ({
                                            ...base,
                                            background: "rgba(221,221,221,0.3)"
                                        }),
                                        spinner: base => ({
                                            ...base,
                                            "& svg circle": {
                                                stroke: "#e44b32",
                                                strokeWidth: "4"
                                            }
                                        })
                                    }
                                })}
                                keyField="id"
                                noDataIndication={() =>
                                    this.props.isLoading ? (
                                        <span style={{ minHeight: "100px" }} />
                                    ) : (
                                        <span>No Notification</span>
                                    )
                                }
                                selectRow={selectRow}
                                expandRow={expandRow}
                                {...props.baseProps}
                            />
                            {/* } */}
                        </div>
                    )}
                </ToolkitProvider>
                <div className="contentSectionPagination clearfix">
                    <ReactPaginate
                        pageCount={
                            this.props.brandNotificationsMeta.totalPages
                                ? this.props.brandNotificationsMeta.totalPages
                                : 0
                        }
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageClick}
                        marginPagesDisplayed={2}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                        previousLabel="<"
                        forcePage={this.state.forcePage}
                        nextLabel=">"
                        breakLabel="..."
                        breakClassName="break-me"
                        disabledClassName="paginationDisable"
                    />
                </div>
            </div>
        );
    }
}
