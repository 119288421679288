import React, { Component } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default class AppDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });
    this.props.input && this.props.input.onChange(date);
  }

  render() {
    const meta = this.props.meta || {};

    return (
      <div className={`cms-date-picker`}>
        <DatePicker
          selected={
            this.props.input && typeof this.props.input.value === "object"
              ? this.props.input.value
              : undefined
          }
          onChange={this.handleChange}
          customInput={this.props.customInput}
          {...this.props}
          className={`${meta.touched && meta.error && "is-invalid form-control border-danger"}`}
        />
        {meta.touched && meta.error &&
          <div className="invalid-feedback" style={{ display: "block" }}>
            {meta.error}
          </div>}
      </div>
    );
  }
}
