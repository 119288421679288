import normalize from "json-api-normalizer";

export const Resources = {
  getDeviceMetric: {
    url: `/devices`,
    asyncActions: {
      init: "getDeviceMetric_INIT",
      success: "getDeviceMetric_SUCCESS",
      error: "getDeviceMetric_ERROR"
    }
  },
  getLatestDeviceMetric: id => ({
    url: `/devices/${id}/device_last_system_metrics`,
    asyncActions: {
      init: "getLatestDeviceMetric_INIT",
      success: "getLatestDeviceMetric_SUCCESS",
      error: "getLatestDeviceMetric_ERROR"
    }
  }),
  getSelectedRangeMetrics: {
    url: `/devices`,
    asyncActions: {
      init: "getSelectedRangeMetrics_INIT",
      success: "getSelectedRangeMetrics_SUCCESS",
      error: "getSelectedRangeMetrics_ERROR"
    }
  }
};

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined,
  diskUsages: undefined,
  filterStatus: undefined
};

export function deviceNucMetrics(state = { ...initialState }, action = {}) {
  const { init, success, error } = Resources.getDeviceMetric.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, meta },
        status,
        statusText
      } = action.payload;
      const test = normalize(action.payload.data);
      return {
        list: data,
        status,
        statusText,
        meta,
        test,
        hasList: true,
        loading: false,
        hasError: false
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }
    default: {
      return state;
    }
  }
}

export function latestDeviceNucMetrics(
  state = { ...initialState },
  action = {}
) {
  const {
    init,
    success,
    error
  } = Resources.getLatestDeviceMetric().asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, meta },
        status,
        statusText
      } = action.payload;
      const test = normalize(action.payload.data);
      return {
        list: data,
        status,
        statusText,
        meta,
        test,
        hasList: true,
        loading: false,
        hasError: false
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }
    default: {
      return state;
    }
  }
}

export function deviceNucMetricsOfRange(
  state = { ...initialState },
  action = {}
) {
  const {
    init,
    success,
    error
  } = Resources.getSelectedRangeMetrics.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        status,
        statusText,
        meta,
        hasList: true,
        loading: false,
        hasError: false
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }
    default: {
      return state;
    }
  }
}
