import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export function ExitCampaignStudio({
  onExitCampaignStudio,
  handleCampaignPageExit,
  handleConfirmNavigationClick,
  history,
  setModal
}) {
  return (
    <Modal isOpen={onExitCampaignStudio}>
      <ModalHeader toggle={() => handleCampaignPageExit(false, setModal)}>
        Confirm Leave
      </ModalHeader>
      <ModalBody>
        <span>Are you sure you want to leave this page?</span>
      </ModalBody>
      <ModalFooter>
        {" "}
        <Button
          className="btn-outline-primary"
          onClick={() => handleCampaignPageExit(false, setModal)}
        >
          Cancel
        </Button>
        <Button
          className="primaryButton mt-0"
          onClick={() => handleConfirmNavigationClick(history)}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
}
