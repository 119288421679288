import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Progress, Badge } from "reactstrap";
import { removeProgress } from "../redux/uploadModalReducer";

class UploadModal extends Component {
  state = {};

  abortContentS3Upload = item => {
    item.request.abort();
    this.props.removeProgress(item.id);
  };

  render() {
    const { showModal, data, totalFiles } = this.props.uploadProgress;
    if (showModal && data !== []) {
      return (
        <div className="contentUploadWrapper">
          <div className="mb-3 contentUploadWrapper__title">
            <span>{`Total Files: ${totalFiles}`}</span>
          </div>
          <div className="contentUploadWrapper__body">
            {data.map(item => (
              <div key={item.id} className="mb-3">
                <p className="clearfix">
                  <span>{item.name.slice(0, 30)}</span>
                  {item.progress < 100 && (
                    <i
                      className="fa fa-times"
                      onClick={() => this.abortContentS3Upload(item)}
                    />
                  )}
                </p>
                <Progress value={item.progress} />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }
}

export default connect(
  state => ({ uploadProgress: state.ContentUploadModal }),
  { removeProgress }
)(UploadModal);
