import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import {
  reduxForm,
  Field,
  formValueSelector,
  initialize as remoteInitialize
} from "redux-form";
import { Label } from "react-konva";
import { connect } from "react-redux";
import { toLower } from "lodash";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { Resources } from "../redux/networkRedux";
import { formatNetworkGroups } from "../utls/networkGroupUtls";
// eslint-disable-next-line react/prefer-stateless-function
class AddToGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        {this.props.children}

        <h5 className="mb-3">Select a Group</h5>
        <div className="d-flex">
          <Field
            component={MultipleSelect}
            options={this.props.totalGroupList
              .filter(item => item.id !== this.props.selectedGroup)
              .map(item => ({
                value: item.id,
                label: item.attributes.name,
                meta: item
              }))}
            name="selectedGroup"
            className="mr-2"
            selectWrapperClass="w-100"
          />
          <Button
            className="btn-info mr-0 ml-auto"
            onClick={handleSubmit(values =>
              this.props.handleAddAndChangeGroup(
                values,
                this.props.selectedDevices
              )
            )}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { list } = state.totalGroup;
  const selector = formValueSelector("deviceSelectFormForGroup");
  return {
    selectedDeviceInGroup: selector(state, "selectedDevicesInGroup") || [],
    totalGroupList: list || []
  };
}
const Networks = reduxForm({
  form: "AddAndChangeGroupForm"
})(AddToGroup);

export default connect(
  mapStateToProps,
  { remoteInitialize }
)(Networks);
