import React from "react";
import { connect } from "react-redux";

const Resources = {
  syncAction: {
    show: "notificationTicketShow",
    remove: "notificationTicketRemove"
  },
  type: ["info", "success", "error", "warning"]
};

function RemoveTicketNotification() {
  const {
    syncAction: { remove }
  } = Resources;
  return dispatch => {
    dispatch({ type: remove });
  };
}

export function showTicketNotification(message, type) {
  const {
    syncAction: { show }
  } = Resources;
  return dispatch => {
    dispatch({ type: show, payload: { message, type, show: true } });
  };
}

function TicketNotificationToast(props) {
  function remove() {
    setTimeout(props.RemoveTicketNotification, 5 * 1000);
  }
  if (props.notification.show) {
    remove();
    return (
      <div
        id="notification_toast-flash"
        className={`ticket_notification_toast ${props.notification.type} animated fadeInUp`}
      >
        <props.ToasterComponent {...props} />
      </div>
    );
  }
  return null;
}

export default connect(
  state => ({ notification: state.TicketNotificationReducer }),
  { RemoveTicketNotification }
)(TicketNotificationToast);

const initialState = {
  show: false,
  message: "hello",
  type: "info"
};

export function TicketNotificationReducer(
  state = initialState,
  { type, payload }
) {
  const { show, remove } = Resources.syncAction;
  switch (type) {
    case show:
      return {
        ...payload
      };

    case remove:
      return {
        show: false,
        message: undefined,
        type: "info"
      };

    default:
      return state;
  }
}
