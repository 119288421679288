/**
 * TODO: Nothing is done for tag.
 * Image is over floating
 */

import React from "react";
import { Field, change, formValueSelector } from "redux-form";
import { Form, FormGroup, Button, Label } from "reactstrap";
/** Local Components */
import DatePicker from "react-datepicker";
import { Input } from "../../../core";
import { TagSelect } from "../../../core/form/tagSelect";
import { SingleTagSelect } from "../../../core/form/singleTagSelect";
import { required, fileName } from "../../../helper/validation";
import { BrandTagResources } from "../redux";
import { connect } from "react-redux";
import FeatureAllowed from "../../../helper/isFeaturedAllowed";
import RouteAllowed from "../../../helper/isRouteAllowed";

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength90 = maxLength(90);
const selector = formValueSelector("Contents");
const CustomInput = ({ value, onClick }) => (
  <div className="expirationDateWrapper">
    <input onClick={onClick} value={value} />
    <i className="expirationDateWrapper__calendar fa fa-calendar-alt" />
  </div>
);

class SingleContentUploadForm extends React.Component {
  componentDidMount = () => {
    const { props } = this;

    this.props.dispatch(
      change("Contents", `media-${props.id}-name`, props.item.name)
    );
    this.props.dispatch(
      change("Contents", `media-${props.id}-tag`, props.item.tags)
    );
    this.props.dispatch(
      change("Contents", `media-${props.id}-sponser`, props.item.tags)
    );
    this.props.dispatch(
      change("Contents", `media-${props.id}-party`, undefined)
    );
    this.props.dispatch(change("Contents", `media-${props.id}-brand`, null));
    this.props.dispatch(
      change("Contents", `media-${props.id}-expiration_date`, undefined)
    );
  };

  handleTagChange = (tags, type) => {
    const files = this.props.data.map(item => {
      const temp = item;
      if (item.id === this.props.id) {
        temp[type] = tags;
        return temp;
      }
      return temp;
    });
  };

  // handle tag change for party
  handlePartyTagChange = (tags, type) => {
    this.props.dispatch(
      change("Contents", `media-${this.props.id}-brand`, null)
    );
    const files = this.props.data.map(item => {
      const temp = item;
      if (item.id === this.props.id) {
        temp[type] = tags;
        /** if party tag has no value or is null then the brand is nullified.
         * As brand is related to a particular party so on change of
         * party value of brnad is also returned as null
         */
        if (tags == null) temp["brands"] = null;
        return temp;
      }
      return temp;
    });
    if (type === "parties" && tags) {
      let brand = BrandTagResources.getContent(tags.key);
      this.props.Get(brand);
    }
    this.props.dispatch(change("ContentUploadForms", `Files`, files));
  };

  handleFillerContentToggle = () => {
    this.props.dispatch(
      change("Contents", `media-${this.props.id}-party`, null)
    );
    this.props.dispatch(
      change("Contents", `media-${this.props.id}-brand`, null)
    );
  };
  handleInputChange = input => {
    const files = this.props.data.map(item => {
      if (item.id === this.props.id) {
        const temp = item;
        temp.name = input;
        return temp;
      }
      return item;
    });
    this.props.dispatch(change("ContentUploadForms", `Files`, files));
  };

  handleDisplayNameChange = input => {
    const files = this.props.data.map(item => {
      if (item.id === this.props.id) {
        const temp = item;
        temp.display_name = input;
        return temp;
      }
      return item;
    });
    this.props.dispatch(change("ContentUploadForms", `Files`, files));
  };

  handleExpirationDateChange = input => {
    const files = this.props.data.map(item => {
      if (item.id === this.props.id) {
        const temp = item;
        temp.expiration_date = input;
        return temp;
      }
      return item;
    });
    this.props.dispatch(change("ContentUploadForms", `Files`, files));
  };

  getIconForFile = item => {
    switch (item.type) {
      case "Video":
        return (
          <img
            src={require("../../../assets/images/play-button.png")}
            className="img-fluid "
            alt=""
          />
        );
      case "Audio":
        return <img src="" className="img-fluid " alt="" />;
      default:
        return <img src={item.url} alt="file" className="float-center" />;
    }
  };

  render() {
    const { props } = this;
    const { roles, permission } = props
    return (
      <div className="upload-content rounded">
        <Form inline>
          <div className="media d-flex justify-content-center" style={{ marginBottom: "0px" }}>
            {this.getIconForFile(props.item)}
          </div>
          <FormGroup className="mb-2 tagup">
            <Field
              type="text"
              component={Input}
              name={`media-${props.id}-name`}
              value={props.item.name}
              validate={[required, fileName, maxLength90]}
              handleInputChange={this.handleInputChange}
              id="mediaName"
              label="Name"
              placeholder="Content Name"
            />
          </FormGroup>
          <FormGroup className="mb-2 tagup" style={{ width: "18%" }}>
            <Field
              type="text"
              component={Input}
              name={`display-${props.id}-name`}
              value={props.item.name}
              validate={[maxLength90]}
              handleInputChange={this.handleDisplayNameChange}
              id="mediaName"
              label="Display Name"
              placeholder="Display Name"
            />
          </FormGroup>
          <FormGroup className="mb-2 tagup" style={{ width: "18%" }}>
            <Label>Tag</Label>
            <div className="autofilltag">
              <Field
                component={TagSelect}
                name={`media-${props.id}-tag`}
                label="Tag"
                className="header-tag-search form-group--borderless"
                onChange={e => this.handleTagChange(e, "tags")}
                options={this.props.ContentTag.map(i => ({
                  value: i,
                  label: i
                }))}
                handleInputChange={this.props.handleInputChange}
              />
            </div>
          </FormGroup>
          <FormGroup className="mb-2 tagup" style={{ width: "18%" }}>
            <Label>Sponsor</Label>
            <div className="autofilltag">
              <Field
                component={TagSelect}
                name={`media-${props.id}-sponser`}
                label="Sponser"
                className="header-tag-search form-group--borderless"
                onChange={e => this.handleTagChange(e, "sponsers")}
                options={this.props.SponserTag.map(i => ({
                  value: i,
                  label: i
                }))}
                placeholder="Add Sponsors"
              />
            </div>
          </FormGroup>
          <RouteAllowed roles={roles} permission={permission.content.contentPartyBrand}>
            <FeatureAllowed feature={"is_party_brand_feature_enabled"}>
              {" "}
              <FormGroup className="mb-2 tagup " style={{ width: "18%" }}>
                <Label>Party</Label>
                <div className="autofilltag">
                  <Field
                    component={SingleTagSelect}
                    name={`media-${props.id}-party`}
                    label="Party"
                    className="header-tag-search form-group--borderless"
                    onChange={e => this.handlePartyTagChange(e, "parties")}
                    options={this.props.PartyTag.map(i => ({
                      key: i.id,
                      value: i.name,
                      label: i.name
                    }))}
                    placeholder="Add Party"
                    disabled={props.fillerTag === true}
                  />
                </div>
              </FormGroup>
            </FeatureAllowed>
            <FeatureAllowed feature={"is_party_brand_feature_enabled"}>
              <FormGroup className="mb-2 tagup" style={{ width: "18%" }}>
                <Label>Brand</Label>
                <div className="autofilltag">
                  <Field
                    component={SingleTagSelect}
                    name={`media-${props.id}-brand`}
                    label="Brand"
                    className="header-tag-search form-group--borderless"
                    onChange={e => this.handleTagChange(e, "brands")}
                    options={
                      this.props.BrandTag &&
                      this.props.BrandTag.map(i => {
                        return {
                          key: i.id,
                          value: i.name,
                          label: i.name
                        };
                      })
                    }
                    placeholder="Add Brand"
                    disabled={
                      props.singleContentPartyTag === undefined ||
                      props.singleContentPartyTag == null ||
                      props.fillerTag === true
                    }
                  />
                </div>
              </FormGroup>
            </FeatureAllowed>
          </RouteAllowed>
          <FormGroup>
            <Field
              hidden
              component="input"
              name={`media-${props.id}-bulk-tag`}
            />
          </FormGroup>
          <FormGroup className="mb-2" style={{ width: "18%" }}>
            <Label>Expiration Date</Label>
            <DatePicker
              selected={props.item.expiration_date}
              onChange={this.handleExpirationDateChange}
              minDate={new Date()}
              customInput={<CustomInput />}
            />
          </FormGroup>
          <RouteAllowed roles={roles} permission={permission.content.fillerContent}>
            <FormGroup className="mb-3 tagup--chkbox checkBoxWrapper--add mark-as-filler">
              <div className=" ml-3 ">
                <Label
                  className="d-flex fillerCheckbox"
                  style={{ width: "290px" }}
                >
                  <Field
                    type="checkbox"
                    component="input"
                    name={`media-${props.id}-isFillerContent`}
                    onChange={this.handleFillerContentToggle}
                  />{" "}
                  <p>Mark as Filler Content</p>
                  <span
                    className="checkmark filler-check"
                    style={{
                      background: "transparent",
                      border: "none",
                      top: "0px",
                      marginLeft: "85px"
                    }}
                  />
                </Label>{" "}
              </div>
            </FormGroup>
          </RouteAllowed>
          <Button
            outline
            color="primary"
            onClick={() =>
              this.props.handleRemove(this.props.files, this.props.item)
            }
            className="d-flex ml-auto remove mt-2"
          >
            Remove
          </Button>
        </Form>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    // files: selector(state, "Files") || [],
    // newTags: state.form.Contents,
    // ContentTag: state.ContentTag.list
    // ? state.ContentTag.list.attributes.tags
    // : [],
    // SponserTag: state.SponserTag.list ? state.SponserTag.list : [],
    // PartyTag: state.PartyTag.list.parties_list.length > 0 ? state.PartyTag.list.parties_list : [],
    // BrandTag: state.BrandTag.list ? state.BrandTag.list.brands_list : [],
    singleContentPartyTag: selector(state, `media-${props.id}-party`),
    contentBrandTag: selector(state, `media-${props.id}-brand`),
    fillerTag: selector(state, `media-${props.id}-isFillerContent`)
  }),
  {}
)(SingleContentUploadForm);
