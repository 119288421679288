import * as funcs from "./funcs";

const uuidv4 = require("uuid/v4");

function Campaign() {
  this.screens = [];
  this.isEditing = false;
  this.localID = uuidv4();
  this.contentToBeDropped = [];
  this.playlistToBeDropped = [];
  this.isCampaignInitialized = false;
  this.campaignName = "";
  this.isZoomEnable = false;
  this.selectedScreen = undefined;
  this.isSnapEnabled = false;
  this.isPaused = false;
  this.currentIndex = 0;
  this.initialTimeLineZoom = 5;
  this.initialTimeLineZoomPercentage = 100;
  this.totalSecondsinContainer = [];
  this.timelineTotalWidth = undefined;
  this.restrictedCampaign = false;
  this.restrictedCampaignDuration = 0;
  this.showExceededMessage = false;
  this.layout = "landscape";
}

Object.keys(funcs).map(item => {
  Campaign.prototype[item] = funcs[item];
  return item;
});

export { Campaign };
