/* eslint-disable no-shadow */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import HLSPlayer from "../../utls/hlsPlayer";
import { updateCampaign } from "../../redux";

function handleFieldValueChange(e, data, node, campaign, fn) {
  const {
    contentLocalID,
    screen: { localID }
  } = data;
  const {
    target: { value }
  } = e;
  campaign.updateWidgetProperty(localID, contentLocalID, node, value);
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  // debugger;
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "url",
    defaultState.url
  );
  campaign.changeDurationOfContent(
    defaultState.contentLocalID,
    screen.localID,
    {
      minute: defaultState.duration.minute,
      second: defaultState.duration.second
    }
  );
  updateCampaign(campaign);
  toggle(false);
}

function handleContentDurationChange(
  screenID,
  contentID,
  minute,
  second,
  campaign,
  updateCampaign
) {
  const duration = { minute, second };
  const newCampaign = campaign;
  newCampaign.changeDurationOfContent(contentID, screenID, duration);
  updateCampaign(newCampaign);
  return 0;
}

const HLSWidget = props => (
  <Modal
    isOpen={props.open}
    toggle={() => props.toggle(false)}
    backdrop
    className="widgetScreenModal "
  >
    <ModalHeader toggle={() => cancelChanges(props)}>{props.type}</ModalHeader>
    <ModalBody>
      <div
        className="url-widget-background d-flex justify-content-center"
        style={{
          height: "100%",
          width: "100%",
          background: props.data.screen.fill
        }}
      >
        <HLSPlayer
          props={props.data.screen.height}
          width={props.data.screen.width}
          src={props.HLSData.url}
        />
      </div>
      <Form>
        <FormGroup>
          <div className="globalInput">
            <Label for="examplePassword">HLS</Label>
            <Input
              type="text"
              value={props.HLSData.url}
              name="url"
              onChange={e =>
                handleFieldValueChange(
                  e,
                  props.data,
                  "url",
                  props.campaign,
                  props.updateCampaign
                )
              }
              onKeyPress={e => {
                if (e.charCode === 13) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              placeholder="Enter hls url."
            />
          </div>
        </FormGroup>
        {/* <FormGroup row>
          <Label for="examplePassword" sm={3}>
            Duration
          </Label>
          <Col sm={3}>
            <Input
              type="number"
              min="0"
              max="60"
              value={props.URLData.duration.minute}
              name="minute"
              onChange={e =>
                handleContentDurationChange(
                  props.data.screen.localID,
                  props.data.contentLocalID,
                  e.target.value,
                  props.URLData.duration.second,
                  props.campaign,
                  props.updateCampaign
                )
              }
              placeholder="Enter Duration"
            />
            <FormText>Minutes</FormText>
          </Col>
          {":"}
          <Col sm={3}>
            <Input
              type="number"
              value={props.URLData.duration.second}
              name="url"
              onChange={e =>
                handleContentDurationChange(
                  props.data.screen.localID,
                  props.data.contentLocalID,
                  props.URLData.duration.minute,
                  e.target.value,
                  props.campaign,
                  props.updateCampaign
                )
              }
              placeholder="Enter width of the box."
            />
            <FormText>Seconds</FormText>
          </Col>
        </FormGroup> */}
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button
        className="btn-outline-primary"
        onClick={() => cancelChanges(props)}
      >
        Cancel
      </Button>
      <Button
        className="mt-0 primaryButton"
        onClick={() => props.toggle(false)}
      >
        Done
      </Button>
    </ModalFooter>
  </Modal>
);

export default connect(
  (state, props) => {
    const { campaign } = state.newCampaign;
    const {
      contentLocalID,
      screen: { localID }
    } = props.data;
    const HLSData = campaign.getContent(localID, contentLocalID);
    return {
      campaign,
      HLSData: HLSData || []
    };
  },
  { updateCampaign }
)(HLSWidget);
