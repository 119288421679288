import { Component } from "react";

export default class VerifyLockedPlaylist extends Component {
  render() {
    if (
      this.props.row.type.search("playlist") > -1 &&
      this.props.row.attributes.locked &&
      !this.props.permission.playlist.lock.some(r =>
        this.props.roles.includes(r)
      )
    ) {
      return null;
    }
    return this.props.children;
  }
}
