import React from "react";
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { connect } from "react-redux";
import { element } from "prop-types";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import Switcher from "./switcher";
import Resize from "../../utls/resize";
import VerifyLockedPlaylist from "../../../../helper/verifyLockedPlaylist";

const onSortEnd = (
  { oldIndex, newIndex },
  { screen, campaign, updateCampaign }
) => {
  const newContent = arrayMove(screen.contents, oldIndex, newIndex);
  campaign.setContents(screen.localID, newContent);
  updateCampaign(campaign);
};
const DragHandle = sortableHandle(props => (
  <Switcher {...props} item={props.value} />
));

const checkWidthOfVideoAndPlaylist = (item, fn, campaign) => {
  let width = 0;
  if (item.type === "playlist" && item.usePlaylistDuration) {
    width = fn(
      item.estimatedDuration,
      campaign.initialTimeLineZoom,
      item.usePlaylistDuration
    );
  } else {
    width = fn(
      item.duration ? item.duration : { minute: 0, second: 0 },
      campaign.initialTimeLineZoom
    );
  }
  return width;
};

const handleModal = props => {
  if (
    props.value.contentType === "image" ||
    props.value.contentType === "video"
  ) {
    return props.handleModalToggle(
      true,
      props.setContentModalToggle,
      props.setContentModalData,
      props.value,
      props.screen,
      props.campaign,
      props.updateCampaign
    );
  }
  return props.handleToggleWidgetEdit(props.value, props.screen);
};

const SortableList = SortableContainer(({ items }) => (
  <div className="ml-1 campaignContainer">
    {items.screen.contents
      .filter(item => item.destroy !== 1)
      .map((value, index) => (
        <SortableItem
          key={`item-${value.contentLocalID}`}
          index={index}
          value={value}
          {...items}
        />
      ))}
  </div>
));

const SortableItem = SortableElement(props => (
  <div
    className="item"
    id={`item-${props.screen.localID}`}
    onClick={e => e.stopPropagation()}
  >
    <VerifyLockedPlaylist
      roles={props.userRoles && props.userRoles}
      permission={props.permission && props.permission}
      row={{
        attributes: props.value.meta && props.value.meta.attributes,
        type: props.value.type
      }}
    >
      <span
        className="d-flex campaignStudioWrapper__singletimeline__actions"
        style={{
          opacity:
            props.popOverContentID === props.value.contentLocalID &&
            props.popOverOpenToggle &&
            "1"
        }}
      >
        <span>
          <i
            role="presentation"
            id="mps-unique-button-inside-container-important"
            onClick={() => handleModal(props)}
            className="bx bx-pencil mr-3"
            style={{ color: "white", fontSize: "16px" }}
          />

          <i
            role="presentation"
            id={`mps-unique-button-inside-container-important-${props.value.contentLocalID}`}
            onClick={() =>
              props.toggleDeletePopOver(
                !props.popOverOpenToggle,
                props.value.contentLocalID
              )
            }
            className="fa fa-trash"
            style={{
              color: "white",
              fontSize: "16px"
            }}
          />
          <Popover
            placement="right"
            isOpen={
              props.popOverOpenToggle &&
              props.popOverContentID === props.value.contentLocalID
            }
            target={`mps-unique-button-inside-container-important-${props.value.contentLocalID}`}
            // toggle={props.toggleDeletePopOver}
            className="screenSetting"
            trigger="legacy"
          >
            <PopoverBody>
              <span>
                Are you sure you want to delete
                <strong> {`${props.value.contentName}?`}</strong>
              </span>
              <div
                className="mt-2"
                style={{ display: "block", float: "right" }}
              >
                <button
                  type="button"
                  className=" btn-outline-primary singleTimeCancelButton"
                  onClick={() => props.toggleDeletePopOver(false, "")}
                >
                  Cancel
                </button>
                <button
                  className="primaryButton primaryButton--singleTimeLine"
                  type="submit"
                  onClick={() => {
                    props.campaign.removeContentFromScreen(
                      props.screen.localID,
                      props.value.contentLocalID
                    );
                    props.updateCampaign(props.campaign);
                    props.toggleDeletePopOver(false, "");
                  }}
                >
                  Delete
                </button>
              </div>
            </PopoverBody>
          </Popover>
        </span>
      </span>
    </VerifyLockedPlaylist>
    {props.value.type === "playlist" || props.value.contentType === "video" ? (
      <div
        className="videoWrapper"
        style={{
          width: `${checkWidthOfVideoAndPlaylist(
            props.value,
            props.calculateTimeInSecond,
            props.campaign
          )}px`
        }}
      >
        <DragHandle value={props.value} {...props} />
      </div>
    ) : (
      <Resize
        contentID={props.value.contentLocalID}
        screenID={props.screen.localID}
        setCurrentlySelected={props.setCurrentlySelected}
        currentlySelected={props.currentlySelected}
      >
        {" "}
        <DragHandle value={props.value} {...props} />
      </Resize>
    )}
  </div>
));

const shouldCancelStart = ({ target: { id } }) => {
  if (id === "dragging-effect") {
    return false;
  }
  return true;
};

function Sortable(props) {
  return (
    <SortableList
      distance={1}
      axis="x"
      lockAxis="x"
      items={props}
      useDragHandle
      // shouldCancelStart={shouldCancelStart}
      onSortEnd={newIndex => onSortEnd(newIndex, props)}
      transitionDuration={0}
      getContainer={() => document.getElementById("style-1")}
    />
  );
}

export default connect(state => ({
  campaign: state.newCampaign.campaign
}))(Sortable);
