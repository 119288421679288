/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { Tooltip } from "../core";

const VisibleColumnToggler = props => {
  /**
   * @param {object} props. - props contains following items:
   *    - @argument {object} hiddenColumns - object of bools key pair, describing the table columns and their visibility; named "hidden Columns"(which is in the state of another component)
   *    - @argument {function} toggleHiddenColumns - an function to handle the state changed in the hidden Columns object and reflect it upon the parent component state
   *    - @argument {object} columnToggleProps: - an object which contains columns list of a table and some internal functions.
   *                         This object is returned by "react-bootstrap-table2-toolkit" via "props", by "ToolkitProvider". ref. see docs
   */

  /**
   * This component is dependent upon "react-bootstrap-table2-toolkit" so see it first
   * To use this component you'll need to:
   *    - create a state named "hiddenColumns" {object} with all the column names as {keys} and values {boolean}
   *    - write a function to handle the state change named "toggleHiddenColumns". The function will take keys and values and update
   *        the previously mentioned states accordingly. (one at a time)
   *    - the "columns" attributes passed to the <ToolkitProvider> of "react-bootstrap-table2-toolkit" needs to have
   *        columnName {string} (same as the one defined in "hiddenColumns")
   *        and hidden property for each of the columns
   *    - the same "hiddenColumns" needs to be passed to the <ToolkitProvider> of "react-bootstrap-table2-toolkit"
   *        ; which the component will use to set the hidden property of each columns
   */

  const { columnToggleProps, hiddenColumns, toggleHiddenColumns } = props; // hiddenCols are col names
  const { columns: allColumns, onColumnToggle } = columnToggleProps; // allColumns ia array of all cols object (detailed form)
  const toggleableColumns = cols =>
    cols
      .filter(
        column =>
          !(
            column.columnName === "name" ||
            column.columnName === "booking_name" ||
            column.columnName.search("isSelected") > -1 ||
            column.columnName.search("activity_name") > -1
          )
      )
      // now for each columns, set the toggle attribute info which is received from bootstrap table
      .map(column => ({
        ...column,
        toggle: !hiddenColumns[column.columnName]
        // toggle is a bool which when true 'cols will be hidden' and when false 'cols will be shown'
        // hence toggle and hidden bools shows opposite reltaion
      }));
  const formattedCol = toggleableColumns(allColumns);

  const handleChangeToggle = column =>
    toggleHiddenColumns(column, !hiddenColumns[column]);

  return (
    <UncontrolledDropdown className="mb-2">
      <DropdownToggle className="iconButton" caret>
        <Tooltip
          item={<i className="bx bx-list-ul" />}
          id="togglecol"
          tooltipContent="Toggle Column"
        />
      </DropdownToggle>
      <DropdownMenu right>
        {formattedCol.map((column, index) => (
          <DropdownItem toggle={false} key={index}>
            <span
              role="button"
              tabIndex={0}
              key={column.dataField}
              onMouseDown={() => onColumnToggle(column.columnName)}
              onClick={() => handleChangeToggle(column.columnName)}
            >
              <i
                className="fa fa-check"
                style={{ opacity: column.toggle ? 1 : 0 }}
              />
              {column.text}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default VisibleColumnToggler;
