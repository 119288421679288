/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";

export default class PopOver extends React.Component {
  static defaultProps = {
    title: "",
    buttonName: "Launch Popover",
    target: false
  };

  static propTypes = {
    title: PropTypes.string,
    buttonName: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      popoverOpen: !prevState.popoverOpen
    }));
    if (this.props.handleImageOverlayonPopoverToggle) {
      this.props.handleImageOverlayonPopoverToggle(this.props.id);
    }
  }

  render() {
    const { target, title, id } = this.props;
    return (
      <span>
        {target || (
          <span
            id={id}
            className="fa fa-ellipsis-h"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        )}
        <Popover
          placement="bottom"
          trigger="legacy"
          target={id}
          toggle={this.toggle}
          isOpen={this.state.popoverOpen}
        >
          {title !== "" && <PopoverHeader>{title}</PopoverHeader>}
          <PopoverBody>{this.props.children}</PopoverBody>
        </Popover>
      </span>
    );
  }
}
