import {
  playlists,
  Resources,
  generateCreatePlaylistData,
  RemoveItemFromContentList,
  playlistDetails,
  archivePlaylists
} from "./playlistRedux";
import { convertToServerData, convertToLocalEvent } from "./playlistData";

const playlistReducer = {
  playlists,
  playlistDetails,
  archivePlaylists
};

export {
  playlistReducer,
  Resources,
  playlistDetails,
  archivePlaylists,
  convertToServerData,
  convertToLocalEvent,
  generateCreatePlaylistData,
  RemoveItemFromContentList
};
