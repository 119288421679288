import React from "react";
import { reduxForm, Field, SubmissionError } from "redux-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  FormFeedback
} from "reactstrap";
import { required, isPasswordStrong } from "../../../helper/validation";

import { Input } from "../../../core";
import { Resources, generateUserUpdate } from "../redux";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.toggle = this.toggle.bind(this);
  }

  handleSubmit = async values => {
    const formValues = values;
    const { changePassword } = Resources;

    if (formValues.confirm_password !== formValues.new_password) {
      throw new SubmissionError({
        confirm_password: "Password didn't match."
      });
    }

    const data = {
      password: values.password,
      new_password: values.new_password
    };
    changePassword.body = generateUserUpdate(this.props.id, { data });
    const result = await this.props.Put(changePassword);
    if (result.status === 200) {
      this.props.destroy();
      this.toggle();
    } else {
      throw new SubmissionError({
        password: "Old password didn't match."
      });
    }
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <a
          href="javascript:void(0)"
          onClick={this.toggle}
          style={{ color: "#e44b32" }}
        >
          Change Password
        </a>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Change Password</ModalHeader>
          <ModalBody>
            <form className="validate-form">
              <div className="mb-3">
                <Field
                  component={Input}
                  name="password"
                  placeholder="Current Password"
                  validate={[required]}
                  type="password"
                  label="Current Password"
                />
              </div>
              <div className="mb-3">
                <Field
                  component={Input}
                  name="new_password"
                  placeholder="New Password"
                  validate={[required, isPasswordStrong]}
                  type="password"
                  label="New Password"
                />
              </div>
              <div className="mb-3">
                <Field
                  component={Input}
                  name="confirm_password"
                  placeholder="Confirm Password"
                  validate={[required]}
                  type="password"
                  label="Confirm Password"
                />
                <Row>
                  {this.state.passwordError && (
                    <FormFeedback>Password did not matched.</FormFeedback>
                  )}
                </Row>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn-outline-primary"
              onClick={this.toggle}
            >
              Cancel
            </button>
            {/* <Button color="secondary">Update Password</Button> */}
            <button
              className="primaryButton mt-0"
              type="submit"
              onClick={handleSubmit(values => this.handleSubmit(values))}
            >
              Update Password
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

// export default ChangePassword;

const ChangePasswordComponent = reduxForm({
  form: "ChangePasswordForm"
})(ChangePassword);

export default ChangePasswordComponent;
