import { Map } from "../../helper/routeMap";

/** Network */
import Calendar from "./calendar";
import NoRoutes from "../../core/layout/noroutes";
import CalendarDetails from "./pages/calendarDetails";
import EditCalendar from "./pages/editCalendar";
import createCalender from "./pages/createCalender";
import ArchiveCalendar from "./pages/archiveCalendar";

const mainPath = "/calendar";
const pathCreator = `${mainPath}`;
const subPathCreator = subPath => `${pathCreator}${subPath}`;
const feature = "Calendar";

export default [
  {
    path: subPathCreator("/create"),
    component: Map(createCalender),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}`,
    component: Map(Calendar),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/archive"),
    component: Map(ArchiveCalendar),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/show/:id"),
    component: Map(CalendarDetails),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/edit/:id"),
    component: Map(EditCalendar),
    exact: true,
    secured: true
  },
  {
    path: `${pathCreator}/*`,
    component: Map(NoRoutes),
    exact: true,
    secured: true,
    feature
  }
];
