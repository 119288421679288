export const repeatEventOptions = [
  {
    value: "not_repeat",
    label: "Don't repeat"
  },
  {
    value: "daily",
    label: "Repeat daily"
  },
  {
    value: "weekly",
    label: "Repeat weekly"
  },
  { value: "monthly", label: "Repeat monthly" }
];

export const repeatFreqOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 }
];
