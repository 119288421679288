import React from "react";
import { TextClipper } from "../../../helper/TextFormatter";
import { PopOver, Tooltip } from "../../../core";

export function columns(hiddenColumns, roles) {
  return [
    {
      dataField: "attributes.name",
      text: "Content Name",
      columnName: "name",
      hidden: hiddenColumns.name,
      headerStyle: { width: "300px" },
      formatter: (name, row) => (
        <div>
          {" "}
          {name ? (
            name.length >= 25 ? (
              <Tooltip
                item={<span className=""> {`${name.slice(0, 25)}...`} </span>}
                id={`report-${row.id}`}
                tooltipContent={name}
              />
            ) : (
              <span className="">{name}</span>
            )
          ) : (
            "N/A"
          )}
        </div>
      )
    },
    {
      dataField: "attributes.campaign_name",
      text: "Campaign Name",
      columnName: "campaign_name",
      headerStyle: { width: "200px" },
      hidden: hiddenColumns.campaign_name,
      formatter: (name, row) => (
        <div>
          {name || "N/A"} <br />
          <span className="durationblock">
            <i> {row.attributes.duration || "0"} sec </i>
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.content_type",
      text: "MIME type",
      columnName: "mime_type",
      hidden: hiddenColumns.mime_type,
      headerStyle: { width: "130px" },
      style: { textAlign: "center" }
    },
    {
      dataField: "attributes.height",
      text: "Dimension",
      columnName: "dimension",
      headerStyle: { width: "130px" },
      hidden: hiddenColumns.dimension,
      formatter: (name, row) => (
        <div>
          <span>{`${row.attributes.height} X ${row.attributes.width}`}</span>
        </div>
      )
    },
    {
      dataField: "attributes.device_mac",
      text: "Device MAC",
      columnName: "device_mac",
      hidden: hiddenColumns.device_mac,
      headerStyle: { width: "200px" }
    },
    {
      dataField: "attributes.content_tags",
      text: "Content Tags",
      columnName: "content_tags",
      headerStyle: { width: "170px" },
      hidden: hiddenColumns.content_tags,
      formatter: (name, row) => (
        <div className="contentTag" style={{ minWidth: "200px" }}>
          <ul>
            <li>
              {row.attributes.content_tags &&
                row.attributes.content_tags.slice(0, 2).map((i, index) => (
                  <span key={`tags-${row.id}`} className="contentTag__badge">
                    {TextClipper(
                      row.id,
                      i,
                      8,
                      index,
                      "tags",
                      "contentTag__wrapper"
                    )}
                  </span>
                ))}
              <span className="tagmenu-icon">
                {row.attributes.content_tags &&
                  row.attributes.content_tags.length > 2 && (
                    <PopOver id={`tags-${row.id}`}>
                      {row.attributes.content_tags.map(i => (
                        <span
                          key={`tags-${row.id}`}
                          className="contentTag__badge"
                        >
                          {i}
                        </span>
                      ))}
                    </PopOver>
                  )}
              </span>
            </li>
          </ul>
        </div>
      )
    },
    {
      dataField: "attributes.rate_per_minute",
      text: "Rate Per Minute (Nrs.)",
      columnName: "rate_per_minute",
      headerStyle: { width: "200px" },
      style: { textAlign: "center" },
      hidden:
        hiddenColumns.rate_per_content ||
        !["ReportManager", "SuperAdmin", "Admin"].some(i => roles.includes(i)),
      formatter: (name, row) => (
        <div>
          <span>{row.attributes.rate_per_minute || "-"}</span>
        </div>
      )
    },
    {
      dataField: "attributes.start_time",
      text: "UTC Start/End Time",
      columnName: "start_time",
      headerStyle: { width: "200px" },
      hidden: hiddenColumns.start_time,
      formatter: (name, row) => (
        <div>
          <span>{row.attributes.start_time || "-"}</span>
          <br />
          <span>{row.attributes.end_time || "-"}</span>
        </div>
      )
    },
    {
      dataField: "attributes.start_time_local",
      text: "NPT Start/End Time",
      columnName: "start_time_local",
      hidden: hiddenColumns.start_time_local,
      headerStyle: { width: "200px" },
      formatter: (name, row) => (
        <div>
          <span>{row.attributes.start_time_local || "-"}</span>
          <br />
          <span>{row.attributes.end_time_local || "-"}</span>
        </div>
      )
    }
  ];
}
