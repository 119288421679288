import React from "react";
import { Button } from "reactstrap";
import SearchTagRefresh from "../../../core/form/searchtagrefresh";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

import { Tooltip } from "../../../core";

const DomainPartyHeader = props => (
  <div className="d-flex contentHeader">
    <div className="mr-auto">
      <Button
        className="primaryButton primaryButton--contentHeader"
        onClick={() => props.toggleAddPartyDUH(true)}
        type="button"
      >
        <i className="fa fa-plus mr-2" />
        Add Party
      </Button>
    </div>
    <div className="d-flex flex-row-reverse">
      {props.isBulkDeleteVisible &&
        <Tooltip
          item={
            <div>
              <div
                className="iconButton fa fa-trash"
                onClick={() => {
                  props.handleBulkDeleteParties();
                }
                }
              />
            </div>
          }
          id="header-party-delete"
          tooltipContent="Delete"
        />
      }
      <SearchTagRefresh
        handleSearch={props.handleSearchValueDUH}
        placeholder="Search by Name"
        searchVal={props.searchValDUH}
        {...props}
      />
    </div>
    <VisibleColumnToggler
      hiddenColumns={props.hiddenColumns}
      toggleHiddenColumns={props.toggleHiddenColumns}
      columnToggleProps={props.columnToggleProps}
    />
  </div>
);

export default DomainPartyHeader;
