import normalize from "json-api-normalizer";
import { formatTicketsDetailsData } from "../utils/helper";

export const Resources = {
  getTicket: {
    url: "/tickets",
    asyncActions: {
      init: "getTicket_INIT",
      success: "getTicket_SUCCESS",
      error: "getTicket_ERROR"
    },
    syncActions: {
      updateList: "updateTicket"
    }
  },
  getTicketNotification: {
    url: "/tickets",
    asyncActions: {
      init: "getTicketNotification_INIT",
      success: "getTicketNotification_SUCCESS",
      error: "getTicketNotification_ERROR"
    },
    syncActions: {
      updateList: "updateTicket"
    }
  },
  showTicket: {
    url: `/tickets/id`,
    asyncActions: {
      init: "showTicket_INIT",
      success: "showTicket_SUCCESS",
      error: "showTicket_ERROR"
    }
  },
  // removeBulkTicket: {
  //   url: "tickets/bulk_destroy",
  //   asyncActions: {
  //     init: "removeBulkTicket_INIT",
  //     success: "removeBulkTicket_SUCCESS",
  //     error: "removeBulkTicket_ERROR"
  //   }
  // },
  putTicket: {
    url: "/tickets/id",
    asyncActions: {
      init: "updateTicket_INIT",
      success: "updateTicket_SUCCESS",
      error: "updateTicket_ERROR"
    }
  },
  deleteTicket: id => ({
    url: `/tickets/${id}`,
    asyncActions: {
      init: "deleteTicket_INIT",
      success: "deleteTicket_SUCCESS",
      error: "deleteTicket_ERROR"
    }
  }),
  changeToPending: {
    url: "/tickets/id/change_to_pending",
    asyncActions: {
      init: "showTicket_INIT",
      success: "showTicket_SUCCESS",
      error: "showTicket_ERROR",
      successMsg: "Ticket status updated ✓",
      errorMsg: "Could not update ticket",
      warningMsg: ""
    }
  },
  changeToResolved: {
    url: "/tickets/id/change_to_resolved",
    asyncActions: {
      init: "showTicket_INIT",
      success: "showTicket_SUCCESS",
      error: "showTicket_ERROR",
      successMsg: "Ticket resolved successfuly ✓",
      errorMsg: "Could not resolve ticket",
      warningMsg: ""
    }
  },
  newTicketsCount: {
    url: "/tickets/new_tickets_count",
    asyncActions: {
      init: "newTicketsCount_INIT",
      success: "newTicketsCount_SUCCESS",
      error: "newTicketsCount_ERROR"
    },
    syncActions: {
      updateCount: "updateCount"
    }
  }
};

export function RemoveItemFromContentList(id) {
  const { updateList } = Resources.getTicket.syncActions;
  return (dispatch, getState) => {
    const { list } = getState().tickets;
    const newlist = list.filter(item => item.id !== id);
    dispatch({ type: updateList, payload: [...newlist] });
  };
}

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: true,
  hasError: undefined
};

export function tickets(state = initialState, action = {}) {
  const { init, success, error } = Resources.getTicket.asyncActions;
  const { updateList } = Resources.getTicket.syncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case updateList: {
      return {
        ...state,
        list: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export function ticketsNotification(state = initialState, action = {}) {
  const { init, success, error } = Resources.getTicketNotification.asyncActions;
  const { updateList } = Resources.getTicketNotification.syncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case updateList: {
      return {
        ...state,
        list: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

const initialStateTickets = {
  list: { tickets: [] },
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: true,
  hasError: undefined
};

export function ticketDetails(state = initialStateTickets, action = {}) {
  const { init, success, error } = Resources.showTicket.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { links, meta },
        status,
        statusText
      } = action.payload;
      const normalizedData = formatTicketsDetailsData(
        normalize(action.payload.data)
      );
      return {
        list: normalizedData,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function ticketUpdateFromWebsocket(data) {
  return dispatch => {
    dispatch({ type: "updateTicketFromWebsocket", payload: { count: data } });
    return true;
  };
}

export function ticketWebsocketReducer(state = { count: 0 }, action) {
  if (action.type === "updateTicketFromWebsocket") {
    return { count: action.payload.count };
  }
  return state;
}
