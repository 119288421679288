import React, { Component } from "react";

import { Form, FormGroup, Label } from "reactstrap";
import { AddPlaylistStudioPlayButton } from "./addPlaylistPlayButton";

export class AddPlaylistStudioheader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { playlistControl } = this.props;

    return (
      <div className="playlistStudio__header d-flex">
        <div className=" mt-2">
          <Form>
            <FormGroup check inline>
              <Label className="checkBoxWrapper">
                <input
                  type="checkbox"
                  checked={playlistControl.selectAll}
                  onChange={e =>
                    this.props.handleControl(e.target.checked, "selectAll")
                  }
                />
                <span className="checkmark" />
                <span className="playlistStudio__text">All</span>
              </Label>
            </FormGroup>

            <FormGroup check inline>
              <Label className="checkBoxWrapper">
                <input
                  type="checkbox"
                  checked={playlistControl.date}
                  onChange={e =>
                    this.props.handleControl(e.target.checked, "date")
                  }
                />
                <span className="checkmark" />
                <span className="playlistStudio__text">Date Limit</span>
              </Label>
            </FormGroup>

            <FormGroup check inline>
              <Label className="checkBoxWrapper">
                <input
                  type="checkbox"
                  checked={playlistControl.restriction}
                  onChange={e =>
                    this.props.handleControl(e.target.checked, "restriction")
                  }
                />
                <span className="checkmark" />
                <span className="playlistStudio__text">Time Limit</span>
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label className="checkBoxWrapper">
                <input
                  type="checkbox"
                  checked={playlistControl.weight}
                  onChange={e =>
                    this.props.handleControl(e.target.checked, "weight")
                  }
                />
                <span className="checkmark" />
                <span className="playlistStudio__text">Share</span>
              </Label>
            </FormGroup>
          </Form>
        </div>
        <div className="ml-auto">
          <AddPlaylistStudioPlayButton
            popOne={this.props.popOne}
            random={this.props.random}
            playlistControl={this.props.playlistControl}
            playlistAttributeChange={this.props.playlistAttributeChange}
          />
        </div>
      </div>
    );
  }
}
