import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class Dialog extends React.Component {
  static defaultProps = {
    showHandler: true,
    toggleModel: () => {},
    externalControl: false,
    primaryMethod: () => {},
    primaryButtonText: "Confirm",
    hidePrimaryButton: false,
    body: "hello",
    noFooter: false,
    noHeader: false
  };

  state = {
    modal: this.props.modal || false
  };

  toggle = () => {
    const { externalControl, toggleModel, modal } = this.props;
    if (externalControl) {
      toggleModel(!modal);
      return;
    }
    this.setState(state => ({ modal: !state.modal }));
  };

  render() {
    const {
      showHandler,
      externalControl,
      primaryMethod,
      modal,
      primaryButtonText,
      isDisabled
    } = this.props;
    return (
      <div>
        {showHandler && (
          <Button color="danger" onClick={this.toggle}>
            {this.props.buttonLabel}
          </Button>
        )}
        <Modal
          isOpen={externalControl ? modal : this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          {!this.props.noHeader && (
            <ModalHeader className="modal-header--modhdr" toggle={this.toggle}>
              {this.props.title}
            </ModalHeader>
          )}
          <ModalBody>{this.props.body}</ModalBody>
          {!this.props.noFooter && (
            <ModalFooter>
              {this.props.fromCampaignDetail && (
                <i className="text-info text-info--custom-info">
                  Select all and press 'ctrl + c' to copy
                </i>
              )}
              <button className="btn-outline-primary" onClick={this.toggle}>
                Cancel
              </button>
              {!this.props.hidePrimaryButton && (
                <button
                  className="primaryButton mt-0"
                  onClick={() => primaryMethod()}
                  disabled={isDisabled}
                >
                  {primaryButtonText}
                </button>
              )}
              {this.props.fromCampaignDetail && (
                <div className="mt-0 previewButton">
                  {/* <i className="text-info text-info--custom-info">
                    Select all and press 'ctrl + c' to copy
                  </i> */}
                  <a
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    href={`${window.location.origin}/campaign/preview/${this.props.preview_token}`}
                    className="primaryButton "
                  >
                    Preview
                  </a>
                </div>
              )}
            </ModalFooter>
          )}
        </Modal>
      </div>
    );
  }
}

export default Dialog;
