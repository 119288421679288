import React, { useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import { func } from "prop-types";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

function checkLocation(obj) {
  if (
    (obj[0] > -124 && obj[0] < -68 && obj[1] < 49 && obj[1] > 26) ||
    (obj[0] > -179 && obj[0] < -130 && obj[1] < 69 && obj[1] > 53)
  ) {
    return true;
  }
  return false;
}

function markerGenerator(location) {
  if (location !== undefined) {
    return location
      .filter(obj => obj)
      .filter(obj => {
        if (checkLocation(obj)) {
          return obj;
        }
      })
      .map(i => ({
        coordinates: i
      }));
  }
  return [];
}

function normalizeLocations({ online, offline }) {
  return { online: markerGenerator(online), offline: markerGenerator(offline) };
}

const MapChart = ({ location }) => {
  const [markers, setMarker] = useState(normalizeLocations(location));
  useEffect(() => {
    setMarker(normalizeLocations(location));
  }, normalizeLocations(location));
  return (
    <React.Fragment>
      <div className="text-center p-4">
        <ComposableMap
          height={360}
          projection="geoAlbersUsa"
          projectionConfig={{
            // rotate: [58.0, 20.0],
            scale: 600
          }}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies
                // .filter(d => d.properties.NAME === "United States of America")
                .map(geo => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#EAEAEC"
                    stroke="#D6D6DA"
                  />
                ))
            }
          </Geographies>
          {markers.online.map(({ coordinates }) => (
            <Marker coordinates={coordinates}>
              <g
                fill="#28a745"
                stroke="#3cb371"
                strokeWidth=".5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle r="6" />
              </g>
            </Marker>
          ))}
          {markers.offline.map(({ coordinates }) => (
            <Marker coordinates={coordinates}>
              <g
                fill="#c7c2c2"
                stroke="#000000"
                strokeWidth=".5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle r="6" />
              </g>
            </Marker>
          ))}
        </ComposableMap>
      </div>
    </React.Fragment>
  );
};

export default MapChart;
