export const Resources = {
  getDeviceScreenShots: {
    url: "/devices",
    asyncActions: {
      init: "getScreenShot_INIT",
      success: "getScreenShot_SUCCESS",
      error: "getScreenShot_ERROR"
    }
  }
};

export function updateScreenShotDataFromWebSocket(status, data) {
  return (dispatch, getState) => {
    const { list } = getState().getDeviceScreenShots;
    const state = getState().getDeviceScreenShots;
    if (list) {
      list.map(item => {
        if (item.attributes.mac_address === data.mac) {
          // make a changes
          item.attributes = {
            ...item.attributes,
            ...data
          };
        }
      });
      state.list = list;
    }
    dispatch({
      type: "updateScreenshotDataFromWebSocket",
      payload: state
    });
  };
}
const initialState = {
  list: undefined
};

export const getDeviceScreenShots = (
  state = initialState,
  { type, payload }
) => {
  const {
    asyncActions: { init, success, error }
  } = Resources.getDeviceScreenShots;
  switch (type) {
    case success: {
      const {
        data: { data }
      } = payload;
      return { list: data };
    }
    case "updateScreenshotDataFromWebSocket": {
      return {
        ...payload
      };
    }
    case error: {
      return { ...initialState, hasError: true };
    }
    default:
      return state;
  }
};
