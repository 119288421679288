import React from "react";
import { FormGroup, Label } from "reactstrap";
import { Input } from "..";

function GetNameInsideSubDirectory(item, { name, subKey }) {
  if (subKey !== undefined) {
    return item[name][subKey];
  }
  return item[name];
}

const mapSelect = (data, { value, name, subKey }) =>
  data.map(item => (
    <option key={item[value]} value={item[value]}>
      {GetNameInsideSubDirectory(item, { name, subKey })}
    </option>
  ));

class Select extends React.Component {
  state = {};

  render() {
    return (
      <FormGroup className="mr-0 mb-1">
        <Label for="exampleSelect">{this.props.labelName}</Label>
        <Input type="select" name="select" {...this.props}>
          <option value="">{this.props.labelName}</option>
          {mapSelect(this.props.options, this.props.optionParams)}
        </Input>
      </FormGroup>
    );
  }
}

Select.defaultProps = {
  options: [{ value: "1", option: "1" }, { value: "2", option: "2" }, { value: "3", option: "3" }],
  optionParams: { value: "value", name: "option" }
};

export default Select;
