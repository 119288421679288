/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-multi-comp */
import React, { Component } from "react";
import humps from "lodash-humps";
import { findIndex } from "lodash";
import { Card, Row, Col, Progress, CardBody, CardSubtitle } from "reactstrap";
import moment from "moment";
import TooltipItem from "../../metrics/utils/tooltip";
import { mapContentData } from "./mapContentData";
import PreviewScreen from "./previewScreen";

const weather = require("../../../assets/picture/weather.png");
const text = require("../../../assets/picture/text.png");
const url = require("../../../assets/picture/url.png");
const hls = require("../../../assets/picture/url.png");
const rss = require("../../../assets/picture/rss.png");
const datetime = require("../../../assets/picture/datetime.png");
const youtube = require("../../../assets/picture/youtube_widget.png");
const picToScreen = require("../../../assets/picture/pic_to_screen.png");
const route = require("../../../assets/picture/route.png");
const transparent = require("../../../assets/picture/transparent.png");

function prefetch_file(
  url,
  fetched_callback,
  progress_callback,
  screenID,
  contentID,
  playlistID
) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";

  xhr.addEventListener(
    "load",
    () => {
      if (xhr.status === 200) {
        const URL = window.URL || window.webkitURL;
        const blob_url = URL.createObjectURL(xhr.response);
        fetched_callback(blob_url, screenID, contentID, playlistID);
      } else {
        error_callback();
      }
    },
    false
  );

  let prev_pc = 0;
  xhr.addEventListener("progress", event => {
    if (event.lengthComputable) {
      const pc = Math.round((event.loaded / event.total) * 100);
      if (pc != prev_pc) {
        prev_pc = pc;
        progress_callback(pc, screenID, contentID);
      }
    }
  });
  xhr.send();
}

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stageHeight: 545,
      stageWidth: 965,
      formatedScreens: [],
      loadStatus: false,
      totalDownloadCount: 0,
      totalDownloadCompleted: 0,
      newScreen: [],
      progress: [],
      startAll: false,
      startTime: null,
      pausedTime: null,
      isPaused: false,
      isForward: false,
      isBackward: false,
      forwardTime: null,
      backwardTime: null,
      style: {},
      timer: 0
    };

    this[`video-0`] = React.createRef();
    this[`video-1`] = React.createRef();
    this[`video-2`] = React.createRef();
    this[`video-3`] = React.createRef();
    this[`video-4`] = React.createRef();
    this[`video-5`] = React.createRef();
    this[`video-6`] = React.createRef();
  }

  createRefsForVideo = id => {
    // this.video[id] = React.createRef();
    // return this.video[id];

    this[`video-${id}`] = React.createRef();
    return this[`video-${id}`];
  };

  updateTime = () => {
    if (this.state.currentIndex === this.state.screenIndex) {
      const timeDiff =
        this.state.timeDiff +
        this.state.startTime.diff(this.state.pausedTime, "second");
      this.setState({ startTime: moment(), timeDiff });
    } else {
      const timeDiff = this.state.startTime.diff(
        this.state.pausedTime,
        "second"
      );
      this.setState({
        startTime: moment(),
        timeDiff,
        screenIndex: this.state.currentIndex
      });
    }
  };

  setStartTime = () => {
    this.setState({ startTime: moment() });
  };

  handlePause = () => {
    this.setState({ isPaused: true, pausedTime: moment() });
  };

  handlePlay = () => {
    this.setState({ isPaused: false, startTime: moment() });
  };

  handleForward = () => {
    this.handleForwardIcon();
    this.setState({ isForward: true, forwardTime: moment() });
  };

  startTimer = () => {
    this.timerID = this.timer();
  };

  timer = () =>
    setInterval(() => {
      this.setState(prevState => ({
        timer: prevState.timer + 1
      }));
    }, 1000);

  pauseTimer = () => {
    clearInterval(this.timerID);
  };

  increaseTimer = () => {
    this.setState(prevState => ({
      timer: prevState.timer + 5
    }));
  };

  decreaseTimer = () => {
    this.setState(prevState => ({
      timer: prevState.timer - 5
    }));
  };

  handleBackward = () => {
    this.handleBackwardIcon();
    this.setState({ isBackward: true, backwardTime: moment() });
  };

  handleStop = () => {
    this.setState({
      startAll: false,
      isPaused: false,
      startTime: null,
      pausedTime: null,
      isForward: false,
      isBackward: false,
      forwardTime: null,
      backwardTime: null,
      timer: 0
    });
    clearInterval(this.timerID);
  };

  resetForwardAndBackward = () => {
    this.setState({ isBackward: false, isForward: false });
  };

  removeForwardTime = () => {
    this.setState({
      forwardTime: null
    });
  };

  handleForwardIcon = () => {
    this.setState({ showForward: true });
    setTimeout(() => {
      this.setState({
        showForward: false
      });
    }, 250);
  };

  handleBackwardIcon = () => {
    this.setState({ showBackward: true });
    setTimeout(() => {
      this.setState({
        showBackward: false
      });
    }, 250);
  };

  componentDidMount = async () => {
    const token = this.props.match.params.id;
    const result = await fetch(
      `${process.env.REACT_APP_END_POINT}/campaigns/preview`,
      {
        headers: {
          "Content-Type": "application/json",
          "Preview-Token": `Preview ${token}`
        }
      }
    )
      .then(res => res.json())
      .catch(e => e);

    if (result.hasOwnProperty("campaign")) {
      this.handleMap(humps(result.campaign));
    } else {
      alert("URL has expired.");
    }
  };

  handleSettingStage = ({ screenHeight, screenWidth }) => {
    if (screenWidth > screenHeight) {
      const stage = {};
      stage.height = 540;
      stage.width = 960;
      const verticalScale = screenHeight / 540;
      const horizontalScale = screenWidth / 960;
      this.setState({
        stageHeight: stage.height,
        stageWidth: stage.width,
        verticalScale,
        horizontalScale
      });
      return { horizontalScale, verticalScale };
    }
    const stage = {};
    stage.height = 960;
    stage.width = 540;
    const verticalScale = screenHeight / stage.height;
    const horizontalScale = screenWidth / stage.width;
    this.setState({
      stageHeight: stage.height,
      stageWidth: stage.width,
      verticalScale,
      horizontalScale
    });
    return { verticalScale, horizontalScale };
  };

  handleScreensMapping = (screens, { verticalScale, horizontalScale }) =>
    screens.map((item, sIndex) => ({
      height: item.screenHeight * (1 / verticalScale),
      width: item.screenWidth * (1 / horizontalScale),
      x: item.screenX * (1 / horizontalScale),
      y: item.screenY * (1 / verticalScale),
      screenID: `Screen Number ${sIndex}`,
      scale: item.scale,
      content: item.contents.map((key, index) => {
        if (key.type.search("ideo") > -1) {
          return {
            contentID: `Content Number ${index + 1} (Vid)`,
            duration: key.duration,
            url: key.url,
            type: key.type,
            contentType: key.type.search("ideo") > -1 ? "video" : "image",
            preFetchUrl: null,
            // forwardRef: this.video
            forwardRef: this[`video-${sIndex}`]
          };
        }
        if (key.type.search("eather_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (Weather)`,
            duration: key.duration,
            url: weather,
            contentType: "weather",
            preFetchUrl: null,
            days: key.days,
            location: key.location,
            unit: key.unit,
            weather: key.weatherData
          };
        }
        if (key.type.search("ext_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (Text)`,
            duration: key.duration,
            url: text,
            contentType: "text",
            preFetchUrl: null,
            backgroundColor: key.backgroundColor,
            fontFamily: key.fontFamily,
            fontSize: key.fontSize,
            rotate: key.rotate,
            text: key.text,
            textColor: key.textColor,
            textAlign: key.textAlign
          };
        }
        if (key.type.search("url_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (URL)`,
            duration: key.duration,
            url,
            contentType: "url",
            preFetchUrl: null,
            scrollTo: key.scrollTo,
            dynamicUrl: key.url,
            scaleFactor: key.scaleFactor
            // forwardRef: this[`video-${sIndex}`]
          };
        }
        if (key.type.search("hls_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (HLS)`,
            duration: key.duration,
            hls,
            contentType: "hls",
            preFetchUrl: null,
            scrollTo: key.scrollTo,
            dynamicUrl: key.url
            // forwardRef: this[`video-${sIndex}`]
          };
        }
        if (key.type.search("ss_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (RSS)`,
            duration: key.duration,
            url: rss,
            contentType: "rss",
            preFetchUrl: null,
            backgroundColor: key.backgroundColor,
            delay: key.delay,
            direction: key.direction,
            fontFamily: key.fontFamily,
            fontSize: key.fontSize,
            rotate: key.rotate,
            textColor: key.textColor,
            dynamicUrl: key.url,
            title:
              key.rssData.length > 0
                ? JSON.parse(key.rssData)
                : ["Resource url does not contain RSS feed"]
          };
        }
        if (key.type.search("ate_time_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (DateTime)`,
            duration: key.duration,
            url: datetime,
            contentType: "datetime",
            preFetchUrl: null,
            backgroundColor: key.backgroundColor,
            textColor: key.textColor,
            dateFormat: key.dateFormat,
            timezone: { value: key.timezone },
            id: key.id
          };
        }
        if (key.type.search("playlist") > -1) {
          return {
            contentType: "playlist",
            playlistID: `Playlist Number ${index + 1} (Playlist)`,
            random: key.random,
            usePlaylistDuration: key.usePlaylistDuration,
            duration: key.duration,
            plcsPlayDuration: key.avgPossibleDuration,
            popOne: key.popOne,
            forwardRef: this[`video-${sIndex}`],
            playlistContents: key.playlistContents.map((plContent, index) => ({
              restrictions: {
                activationDate: plContent.startingDate,
                deactivationDate: plContent.endingDate,
                restrictedHours: {
                  startHour: plContent.startingHour,
                  endHour: plContent.endingHour
                },
                restrictedMinutes: {
                  startMinute: plContent.startingMinuteOfHour,
                  endMinute: plContent.endingMinuteOfHour
                },
                maxCount: plContent.maxCountPerHour,
                maxDuration: plContent.maxTimePerHour,
                weightLimit: plContent.weight,
                context: plContent.context
              },
              content: {
                contentID: `Playlist Content Number ${index + 1} (${
                  plContent.content.type.search("ideo") > -1 ? "Vid" : "Img"
                })`,
                duration: plContent.content.duration,
                url: plContent.content.url,
                contentType: plContent.content.type,
                preFetchUrl: null,
                scale: item.scale
              }
            }))
          };
        }
        if (key.type.search("youtube_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (URL)`,
            duration: key.duration,
            youtube,
            contentType: "youtube",
            preFetchUrl: null,
            scrollTo: key.scrollTo,
            dynamicUrl: key.url
          };
        }
        if (key.type.search("pic_to_screen_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (URL)`,
            duration: key.duration,
            picToScreen,
            contentType: "picToScreen",
            preFetchUrl: null,
            scrollTo: key.scrollTo,
            dynamicUrl: key.url
          };
        }
        if (key.type.search("routing_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (Route)`,
            duration: key.duration,
            route,
            contentType: "route",
            preFetchUrl: null,
            backgroundColor: key.backgroundColor,
            fontFamily: key.fontFamily,
            fontSize: key.fontSize,
            textColor: key.textColor,
            textAlign: key.textAlign,
            routeDuration:
              key.routingData.routingData &&
              Math.round(
                key.routingData.routingData.rows[0].elements[0].duration.value /
                  60
              )
          };
        }
        if (key.type.search("transparent_widget") > -1) {
          return {
            contentID: `Content Number ${index + 1} (Transparent)`,
            duration: key.duration,
            transparent,
            contentType: "transparent"
          };
        }
        return {
          contentID: `Content Number ${index + 1} (Img)`,
          duration: key.duration,
          url: key.url,
          contentType: key.type.search("ideo") > -1 ? "video" : "image",
          preFetchUrl: null,
          scale: item.scale
        };
      })
    }));

  handleScreensMappingOffline = screens =>
    screens.map(item => ({
      height: item.height,
      width: item.width,
      x: item.x,
      y: item.y,
      scale: item.scale,
      content: mapContentData(item, this.state.isPaused)
    }));

  handleMap = campaign => {
    const { screenHeight, screenWidth, orientation, screens } = campaign;
    const scale = this.handleSettingStage({
      screenHeight,
      screenWidth,
      orientation
    });
    const formatedScreens = this.handleScreensMapping(screens, scale);
    this.setState({ formatedScreens });
    formatedScreens.map(key => {
      key.content.map(item => {
        if (item.contentType && item.contentType.search("laylist") > -1) {
          item.playlistContents.map(plc => {
            this.handlePreFetch(
              plc.content.url,
              key.screenID,
              plc.content.contentID,
              item.playlistID
            );
          });
        } else {
          this.handlePreFetch(item.url, key.screenID, item.contentID);
        }
      });
    });
  };

  handlePreFetch = (url, screenID, contentID, playlistID) => {
    this.setState(state => ({
      totalDownloadCount: state.totalDownloadCount + 1,
      progress: [...state.progress, { screenID, contentID, progress: 0 }]
    }));
    prefetch_file(
      url,
      this.fetched_callback,
      this.progress_callback,
      screenID,
      contentID,
      playlistID
    );
  };

  fetched_callback = (url, screenID, contentID, playlistID) => {
    this.setState(state => ({
      totalDownloadCompleted: state.totalDownloadCompleted + 1
    }));
    const { formatedScreens } = this.state;
    const screen = findIndex(formatedScreens, { screenID });
    if (playlistID) {
      const playlistIndex = findIndex(formatedScreens[screen].content, {
        playlistID
      });
      const playlist = formatedScreens[screen].content[playlistIndex];
      const content = findIndex(playlist.playlistContents, {
        content: { contentID }
      });
      formatedScreens[screen].content[playlistIndex].playlistContents[
        content
      ].content.preFetchUrl = url;
    } else {
      const content = findIndex(formatedScreens[screen].content, { contentID });
      formatedScreens[screen].content[content].preFetchUrl = url;
    }
    this.setState({ formatedScreens });
    if (this.state.totalDownloadCompleted === this.state.totalDownloadCount) {
      const newScreen = this.handleScreensMappingOffline(
        this.state.formatedScreens
      );
      this.setState({
        newScreen,
        loadStatus: true
      });
    }
  };

  progress_callback = (progressStatus, screenID, contentID) => {
    const { progress } = this.state;
    const index = findIndex(progress, { contentID, screenID });
    progress[index].progress = progressStatus;
    this.setState({ progress });
  };

  changeStartScreen = () => {
    this.setState({ startAll: true, loadStatus: true });
  };

  render() {
    const {
      loadStatus,
      newScreen,
      stageHeight,
      stageWidth,
      startAll,
      isPaused
    } = this.state;

    if (loadStatus && !startAll) {
      return (
        <div>
          <div
            style={{
              height: stageHeight,
              width: stageWidth,
              backgroundColor: "black",
              position: "relative",
              margin: "0 auto",
              overflow: "hidden",
              marginTop: (window.innerHeight - 540) / 2,
              marginBottom:
                stageHeight > stageWidth
                  ? (window.innerHeight - 540) / 2
                  : undefined
            }}
          >
            <i
              className="fas fa-play startCampaign"
              onClick={() => {
                this.setState({
                  startAll: true,
                  loadStatus: true,
                  startTime: moment()
                });
                this.startTimer();
              }}
            />
          </div>
        </div>
      );
    }

    if (loadStatus && startAll) {
      return (
        <div>
          <div
            style={{
              height: stageHeight,
              width: stageWidth,
              backgroundColor: "black",
              position: "relative",
              margin: "0 auto",
              overflow: "hidden",
              marginTop: (window.innerHeight - 540) / 2,
              marginBottom:
                stageHeight > stageWidth
                  ? (window.innerHeight - 540) / 2
                  : undefined
            }}
          >
            {/* {isPaused && (
              <span
                style={{
                  position: "absolute",
                  top: "5px",
                  left: "20px",
                  zIndex: "2",
                  fontSize: "40px"
                }}
              >
                <i className="fas fa-pause" />
              </span>
            )} */}
            <span
              style={{
                position: "absolute",
                top: "40%",
                right: "35px",
                zIndex: "2",
                fontSize: "60px",
                // opacity: this.state.showForward ? "0.8" : "0",
                transition: "opacity .25s ease-in-out"
              }}
            >
              {/* <i className="fas fa-forward" />
               */}
              <img
                src={require("../../../assets/images/forward-5.png")}
                alt="IMG"
                style={{
                  opacity: this.state.showForward ? "0.5" : "0",
                  transition: "opacity .25s ease-in-out"
                }}
              />
            </span>
            <span
              style={{
                position: "absolute",
                top: "40%",
                left: "35px",
                zIndex: "2",
                fontSize: "60px",
                // opacity: this.state.showBackward ? "0.8" : "0",
                transition: "opacity .25s ease-in-out"
              }}
            >
              <img
                src={require("../../../assets/images/replay-5.png")}
                alt="IMG"
                style={{
                  opacity: this.state.showBackward ? "0.5" : "0",
                  transition: "opacity .25s ease-in-out"
                }}
              />
            </span>

            {newScreen.map((item, index) => (
              <div
                key={`${item.height}-${item.x}-${item.y}-${index}`}
                style={{
                  height: item.height,
                  width: item.width,
                  left: item.x,
                  top: item.y,
                  position: "absolute",
                  overflow:
                    (item.height < 16 || item.width) < 16 ? "" : "hidden",
                  backgroundColor: "transparent",
                  zIndex: "1"
                }}
              >
                <div className="imagePreviewWrapper">
                  <PreviewScreen
                    forwardRef={this[`video-${index}`]}
                    key={`${item.height}-${item.x}-${item.y}-${index}`}
                    name={`${item.height}-${item.x}-${item.y}-${index}`}
                    startTime={this.state.startTime}
                    pausedTime={this.state.pausedTime}
                    forwardTime={this.state.forwardTime}
                    backwardTime={this.state.backwardTime}
                    {...item}
                    // New Methods
                    isPaused={this.state.isPaused}
                    startAll={this.state.startAll}
                    isForward={this.state.isForward}
                    isBackward={this.state.isBackward}
                    resetForwardAndBackward={this.resetForwardAndBackward}
                    // setStartTime={this.setStartTime}
                    removeForwardTime={this.removeForwardTime}
                  />
                </div>
              </div>
            ))}
            <div className="preview-button-wrapper">
              {/* <span className="preview-time">
              {moment.utc(this.state.timer * 1000).format("HH:mm:ss")}
            </span> */}
              <span style={{ textAlign: "center" }}>
                <TooltipItem
                  item={
                    <button
                      id="backward"
                      type="button"
                      // disabled={this.state.timer < 5 ? "true" : "false"}
                      className={`preview-button primaryButton-preview ${
                        this.state.timer < 5 ? "disabled" : ""
                      }`}
                      onClick={() => {
                        if (this.state.timer >= 5) {
                          this.handleBackward();
                          this.decreaseTimer();
                        }
                      }}
                    >
                      <i className="fas fa-step-backward" />
                    </button>
                  }
                  tooltipContent="Rewind 5s"
                  target="backward"
                  placement="bottom"
                />
              </span>
              <span id="play-pause" style={{ textAlign: "center" }}>
                {isPaused ? (
                  <TooltipItem
                    item={
                      <button
                        // id="play-button"
                        type="button"
                        className="preview-button primaryButton-preview"
                        onClick={() => {
                          this.handlePlay();
                          this.startTimer();
                        }}
                      >
                        <i className="fas fa-play" />
                      </button>
                    }
                    tooltipContent="Play"
                    target="play-pause"
                    placement="bottom"
                  />
                ) : (
                  <TooltipItem
                    item={
                      <button
                        // id="pause"
                        type="button"
                        className="preview-button primaryButton-preview"
                        onClick={() => {
                          this.handlePause();
                          this.pauseTimer();
                        }}
                      >
                        <i className="fas fa-pause" />
                      </button>
                    }
                    tooltipContent="Pause"
                    target="play-pause"
                    placement="bottom"
                  />
                )}
              </span>

              <span style={{ textAlign: "center" }}>
                <TooltipItem
                  item={
                    <button
                      id="stop"
                      type="button"
                      className="preview-button primaryButton-preview"
                      onClick={this.handleStop}
                    >
                      <i className="fas fa-stop" style={{ color: "black" }} />
                    </button>
                  }
                  tooltipContent="Stop"
                  target="stop"
                  placement="bottom"
                />
              </span>

              <span style={{ textAlign: "center" }}>
                <TooltipItem
                  item={
                    <button
                      id="forward"
                      type="button"
                      className="preview-button primaryButton-preview forward-button"
                      onClick={() => {
                        this.handleForward();
                        this.increaseTimer();
                      }}
                    >
                      <i className="fas fa-step-forward" />
                    </button>
                  }
                  tooltipContent="Skip 5s"
                  target="forward"
                  placement="bottom"
                />
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Card className="previewloading-card">
        <h4>Loading Preview</h4>
        <CardBody>
          {this.state.progress.map(item => (
            <React.Fragment>
              <CardSubtitle>{`${item.screenID} and ${item.contentID}`}</CardSubtitle>
              <Row>
                <Col md={12}>
                  <Progress striped value={item.progress} />
                  <div className="progress-text">{`${item.progress}%`}</div>
                </Col>
              </Row>
            </React.Fragment>
          ))}
        </CardBody>
      </Card>
    );
  }
}

export default Preview;
