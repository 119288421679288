/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import {
  reduxForm,
  formValueSelector,
  initialize as remoteInitialize
} from "redux-form";
import { remove, debounce, capitalize } from "lodash";

//  styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

import DeviceGroupControlView from "./deviceGroupControlView";
import { Resources, updateGroupInfiniteScroll } from "../redux/networkRedux";
import { Get, Patch } from "../../../constant/thunk";
import NetworkHeader from "./networkHeader";
import { NetworkGroupTableDesktopView } from "../pages/networkGroupTableDesktopView";
import { NetworkGroupTableMobileView } from "../pages/networkGroupTableMobileView";
import DesktopBreakpoint from "../../../context/desktop_breakpoint";
import PhoneBreakpoint from "../../../context/phone_breakpoint";
import { timeZones } from "../../../constant/timeZoneNUCs";
import { resolutions } from "../../../constant/resolution";

class NetworkGroupTable extends Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    this.props.initialize({
      selectedDevicesInGroup: [],
      selectedGroup: this.props.deviceGroups[0]
    });
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      devices: { active: false, sortBy: "desc" },
      online: { active: false, sortBy: "desc" },
      version: { active: false, sortBy: "desc" }
    };
    this.initialDeviceSortOrder = {
      name: { active: false, sortBy: "desc" },
      mac_address: { active: false, sortBy: "desc" },
      received_media_name: { active: false, sortBy: "desc" },
      last_downloaded_name: { active: false, sortBy: "desc" },
      tag: { active: false, sortBy: "desc" },
      hostname: { active: false, sortBy: "desc" },
      electron_version: { active: false, sortBy: "desc" },
      timezone: { active: false, sortBy: "desc" },
      orientation: { active: false, sortBy: "desc" },
      status: { active: false, sortBy: "desc" },
      resolution: { active: false, sortBy: "desc" },
      block_pop: { active: false, sortBy: "desc" }
    };
    this.searchDevice = React.createRef();
    this.state = {
      forcePage: undefined,
      sendMediaModal: 0,
      sortOrder: this.initialSortOrder,
      deviceSortOrder: this.initialDeviceSortOrder,
      sortValue: { isSortable: false },
      addToGroupModal: 0,
      deviceControlModal: 0,
      isRestrating: false,
      isRebooting: false,
      isUpdating: false,
      isSendingEnv: false,
      isSendingRes: false,
      isSendingTimeZone: false,
      isSendingOrientation: false,
      selectedGroup: undefined,
      refetch: true,
      perPage: 20,
      devicesPerPage: 200,
      sendingEnvironment: false,
      action: "",
      createGroupModal: false,
      groupToBeEdited: undefined,
      searchVal: "",
      selectedSingleDevice: [],
      isSingleDevice: "",
      isGroupOpen: true,
      defaultCampaignSubmitModal: false,
      devicePage: 0,
      deviceSearch: "",
      refetchGroupDevices: true,
      devicePage: 1,
      deviceGroupPage: 1,
      enableNotification: false,
      notificationMessage: "",
      groupOptions: false,
      groupType: "all",
      isSendingPopRequest: false
    };

    // this.initializeAPICallCreate();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetSort === true) {
      this.setState({ deviceSortOrder: this.initialDeviceSortOrder });
      this.props.toggleSort(false);
    }
  }

  handleShowGroupOptions = () => {
    this.setState(state => ({ groupOptions: !state.groupOptions }));
  };

  toggleGroup = () => {
    this.setState(prevState => ({
      isGroupOpen: !prevState.isGroupOpen
    }));
  };

  clearSelectedValueAndReloadSelectedGroup = response => {
    const { getSelectedGroup } = Resources;
    const { newSelectedGroup } = this.props;
    const selectedGroup = newSelectedGroup.device_group_id;
    this.props.change("selectedDevicesInGroup", []);
    this[`group-${selectedGroup}`].current.selectionContext.selected = [];
    this[`group-${selectedGroup}`].current.setState({});
    if (response.status === 200 && response.data.data.length > 0) {
      getSelectedGroup.url = `/device_groups/${
        response.data.data.length > 0 ? response.data.data[0].id : 0
      }/devices?page=1&per_page=200`;
      this.props.setSelectedGroup(response.data.data[0]);
      this.props.Get(Resources.getSelectedGroup);
      // this.props.handleGroupLoading(false);
    }
    if (response.data.meta.totalPages <= response.data.meta.page) {
      this.props.handleRefetch();
    }
  };

  handleGroupSearchValue = value => {
    this.setState({ searchVal: value });
    this.handleGroupSearch();
  };

  handleGroupSearch = debounce(() => {
    const { getNetwork } = Resources;
    this.setState({ deviceSortOrder: this.initialDeviceSortOrder });
    if (this.state.searchVal === "") {
      this.setState({ deviceGroupPage: 1 });
      this.props.handleRefetch(true);
      this.state.groupType === "fav"
        ? (getNetwork.url = `/device_groups/favourites?page=${1}&per_page=${
            this.state.perPage
          }&sort=name&sort_by=asc`)
        : (getNetwork.url = `/device_groups?page=${1}&per_page=${
            this.state.perPage
          }&sort=name&sort_by=asc`);
    } else {
      this.setState({ deviceGroupPage: 1 });
      this.props.handleRefetch(true);
      getNetwork.url = `device_groups/search?search=${
        this.state.searchVal
      }&page=${1}&per_page=${
        this.state.perPage
      }&sort=name&sort_by=asc&fav=${this.state.groupType === "fav"}`;
    }
    this.props.Get(getNetwork).then(response => {
      this.clearSelectedValueAndReloadSelectedGroup(response);
    });
  }, 3000);

  setDeviceSearch = val => {
    this.setState({ deviceSearch: val });
  };

  handleDeviceSearch = debounce(() => {
    const { getSelectedGroup } = Resources;
    if (this.state.deviceSearch) {
      getSelectedGroup.url = `/device_groups/${this.props.selectedGroupId}/device_search?search=${this.state.deviceSearch}&page=1&per_page=200`;
    } else {
      getSelectedGroup.url = `/device_groups/${this.props.selectedGroupId}/devices?page=1&per_page=200`;
    }
    this.props.Get(Resources.getSelectedGroup).then(() =>
      this.setState({
        forcePage: 0,
        deviceSortOrder: this.initialDeviceSortOrder
      })
    );
  }, 3000);

  handleGroupType = groupType => {
    this.setState({ groupType });
    this.handleShowGroupOptions();
    const { getNetwork } = Resources;
    this.props.handleRefetch(true);
    this.setState({ deviceSortOrder: this.initialDeviceSortOrder });
    if (groupType === "fav") {
      getNetwork.url = this.state.searchVal
        ? `device_groups/search?search=${
            this.state.searchVal
          }&page=${1}&per_page=${
            this.state.perPage
          }&sort=name&sort_by=asc&fav=true`
        : `device_groups/favourites?page=1&per_page=20&sort=name&sort_by=asc`;
    } else {
      getNetwork.url = this.state.searchVal
        ? `device_groups/search?search=${
            this.state.searchVal
          }&page=${1}&per_page=${this.state.perPage}&sort=name&sort_by=asc`
        : `/device_groups?page=1&per_page=20&sort=name&sort_by=asc`;
    }
    this.props.Get(getNetwork).then(response => {
      this.clearSelectedValueAndReloadSelectedGroup(response);
    });
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    const { getSelectedGroup } = Resources;
    this.props.handleGroupLoading(true);
    if (moveToPrevious) {
      getSelectedGroup.url = `/device_groups/${
        this.props.newSelectedGroup.device_group_id
      }/devices?page=${data.page - 1}&per_page=${this.state.devicesPerPage}`;
      this.props.Get(getSelectedGroup).then(response => {
        this.props.changeDeviceForcePage(data.page - 2);
        // this.setState({ forcePage: data.page - 2 });
        this.props.handleGroupLoading(false);
      });
    } else {
      this.props.changeDeviceForcePage(undefined);
      // this.setState({ forcePage: undefined });
      // this.setState({ isLoading: true });
      const { selected } = data;
      if (this.state.deviceSearch) {
        getSelectedGroup.url = `/device_groups/${
          this.props.selectedGroupId
        }/device_search?search=${this.state.deviceSearch}&page=${selected +
          1}&per_page=${this.state.devicesPerPage}`;
      } else if (this.state.sortValue.isSortable) {
        getSelectedGroup.url = `/device_groups/${
          this.props.selectedGroupId
        }/devices?sort=${this.state.sortValue.name}&sort_by=${
          this.state.sortValue.sortBy
        }&page=${selected + 1}&per_page=${this.state.devicesPerPage}`;
      } else {
        getSelectedGroup.url = `/device_groups/${
          this.props.newSelectedGroup.device_group_id
        }/devices?page=${selected + 1}&per_page=${this.state.devicesPerPage}`;
      }
      this.props
        .Get(getSelectedGroup)
        .then(() => this.props.handleGroupLoading(false))
        .then(() => this.props.changeDeviceForcePage(selected));
      // .then(() => this.setState({ forcePage: selected }));
    }
  };

  handleDeviceSort = name => {
    this.setState(
      prevState => ({
        deviceSortOrder: {
          ...this.initialDeviceSortOrder,
          [name]: {
            active: true,
            sortBy:
              prevState.deviceSortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy:
            prevState.deviceSortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getSelectedGroup } = Resources;
        getSelectedGroup.url = `/device_groups/${this.props.selectedGroupId}/devices?sort=${name}&sort_by=${this.state.deviceSortOrder[name].sortBy}&page=1&per_page=${this.state.devicesPerPage}`;
        this.props
          .Get(Resources.getSelectedGroup)
          .then(() => this.setState({ forcePage: 0, deviceSearch: "" }));
      }
    );
  };

  handleSort = name => {
    this.setState({ loading: true });
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        }
      }),
      () => {
        const { getNetwork } = Resources;
        getNetwork.url = `/device_groups/?sort=${name}&sort_by=${
          this.state.sortOrder[name].sortBy
        }&page=${1}&per_page=${this.perPageCount}`;
        this.props.Get(Resources.getNetwork);
      }
    );
  };

  /** Create a Refs for sub Table */
  createRefsFromGroupDeviceTables = id => {
    // this.props.deviceGroups.map(item => {
    //   this[`group-${item.id}`] = React.createRef();
    // });
    this[`group-${id}`] = React.createRef();
    return this[`group-${id}`];
  };

  /** Estimate online device number */
  getOnlineDeviceLength = attr => {
    if (attr.devices_online) {
      return (
        <div>
          Online: <b />
        </div>
      );
    }
    return (
      <div>
        Online: <b>0/0</b>
      </div>
    );
  };

  /** Perform operation using refs in group tables */
  handleNewAccordionExpand = (uuid, e) => {
    const index = uuid[0];
    if (this[`group-${index}`]) {
      this.props.change("selectedDevicesInGroup", []);
      if (this[`group-${index}`] && this[`group-${index}`].current) {
        this[`group-${index}`].current.selectionContext.selected = [];
        this[`group-${index}`].current.setState({});
        this.setState(() => ({ currentIndex: index }));
      }
    }
  };

  handleChange = item => {
    const { newSelectedGroup } = this.props;
    const { selectedDeviceInGroup } = this.props;
    const { selected } = this[
      `group-${newSelectedGroup.device_group_id}`
    ].current.selectionContext;
    remove(selectedDeviceInGroup, { id: item.id });
    this.props.change(
      "NetworkDeviceSelector",
      "deviceSelectedInListView",
      selectedDeviceInGroup
    );
    this[
      `group-${newSelectedGroup.device_group_id}`
    ].current.selectionContext.selected = selected.filter(
      data => data !== item.id
    );
    this[`group-${newSelectedGroup.device_group_id}`].current.setState({});
    this.setState({ selectedSingleDevice: [] });
    this.forceUpdate();
  };

  /** Toggle send media modal */
  toggleSendToMediaModal = data => {
    this.setState({ sendMediaModal: data });
  };

  toggleAddToGroupModal = data => {
    this.setState({ addToGroupModal: data });
  };

  toggledeviceControlModal = data => {
    this.setState({ deviceControlModal: data });
    if (data === "cancel") {
      this.clearSelectedDevices();
      this.setState({ deviceControlModal: false });
      return true;
    }
    this.handleLoadAllTags();
    this.props.handleLoadAllNetworkGroup();
  };

  clearSelectedDevices = () => {
    const { newSelectedGroup } = this.props;
    const selectedGroup = newSelectedGroup.device_group_id;
    this.props.change("selectedDevicesInGroup", []);
    this[`group-${selectedGroup}`].current.selectionContext.selected = [];
    this[`group-${selectedGroup}`].current.setState({});
    this.props.remoteInitialize("DeviceGroupForm", {
      electronTag: [],
      electronEnv: [],
      resolution: [],
      orientation: {},
      timezone: []
    });
    this.props.remoteInitialize("AddAndChangeGroupForm", {
      selectedGroup: []
    });
  };

  handleSubmitNetworkSelector = (devices, campaign) => {
    this.setState((state, props) => ({
      enableNotification: true,
      isUpdating: true,
      notificationMessage: `Sending ${campaign.metaOptions.attributes.name} Campaign to Devices`
    }));
    const { playCampaignInNetwork } = Resources;
    const request = playCampaignInNetwork(campaign.value, "run");
    request.body = JSON.stringify({
      _jsonapi: {
        data: {
          type: "campaigns",
          attributes: {
            device_ids: devices.map(item => item.id)
          }
        }
      }
    });
    this.refreshNetworkGroup(request);
  };

  handleSubmitNetworkCalendarSelector = (devices, calendar) => {
    this.setState((state, props) => ({
      enableNotification: true,
      isUpdating: true,
      notificationMessage: `Sending ${calendar.metaOptions.attributes.name} Calendar to Devices`
    }));
    const { playCalendarInNetwork } = Resources;
    const request = playCalendarInNetwork(calendar.value, "run");
    request.body = JSON.stringify({
      _jsonapi: {
        data: {
          type: "calendar",
          attributes: {
            device_ids: devices.map(item => item.id)
          }
        }
      }
    });
    this.refreshNetworkGroup(request);
  };

  resyncGroupDevices = (id, values) => {
    this.toggledeviceControlModal(false);
    this.setState({
      enableNotification: true,
      notificationMessage: "Syncing Media to Devices"
    });
    this.forceUpdate();
    const { resyncPlay } = Resources;
    const request = resyncPlay(id);
    request.body = JSON.stringify({
      _jsonapi: {
        data: {
          type: "device_groups",
          attributes: {
            device_ids: values.map(value => value.id)
          }
        }
      }
    });
    this.props
      .Post(request)

      .then(() => this.props.Get(Resources.getNetwork))
      .then(res => {
        if (res.status === 200) {
          this.setState({
            enableNotification: false,
            notificationMessage: ""
          });
          this.clearSelectedDevices();
        }
      });
  };

  restartGroupDevices = (id, devices) => {
    const { restartGroupDevices } = Resources;
    const request = restartGroupDevices(id);
    request.body = request.bodyFunction(devices);
    this.setState((state, props) => ({
      isRestrating: true,
      enableNotification: true,
      notificationMessage: "Restarting MPS"
    }));
    this.props.Patch(request).then(() => {
      this.setState((state, props) => ({
        isRestrating: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedDevices();
    });
  };

  rebootGroupDevices = async (id, devices) => {
    const { rebootGroupDevices } = Resources;
    this.setState((state, props) => ({
      enableNotification: true,
      isRebooting: true,
      notificationMessage: "Restarting Devices"
    }));
    const request = rebootGroupDevices(id);
    request.body = request.bodyFunction(devices);
    this.props.Patch(request).then(() => {
      this.setState(() => ({
        isRebooting: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedDevices();
    });
  };

  // LATEST DEVICE UPDATE WITHOUT TAG
  // updateGroupDevices = async (id, devices) => {
  //   const { updateGroupDevices } = Resources;
  //   const request = updateGroupDevices(id);
  //   request.body = request.bodyFunction(devices);
  //   this.setState((state, props) => ({ isUpdating: true }));
  //   this.props.Patch(request).then(() => {
  //     this.props.remoteInitialize("DeviceGroupForm", {
  //       electronTags: []
  //     });
  //     this.setState(() => ({ isUpdating: false }));
  //   });
  // };

  updateGroupDevices = async (id, devices, tag) => {
    const { updateGroupDevices } = Resources;
    const request = updateGroupDevices(id);
    request.body = request.bodyFunction(devices, tag);
    this.setState((state, props) => ({
      enableNotification: true,
      isUpdating: true,
      updatingGroup: id,
      notificationMessage: `Sending ${tag} Version`
    }));
    this.setState((state, props) => ({ isUpdating: true }));
    this.props.Patch(request).then(() => {
      this.props.remoteInitialize("DeviceGroupForm", {
        electronTag: [],
        electronEnv: []
      });
      this.setState(() => ({
        isUpdating: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.handleCurrentGroupDeviceLoad();
      this.clearSelectedDevices();

      // this.setState(() => ({ sendingEnvironment: false }));
    });
  };

  legacyUpdateGroupDevices = async (id, devices, tag) => {
    const { legacyUpdateGroupDevices } = Resources;
    const request = legacyUpdateGroupDevices(id);
    request.body = request.bodyFunction(devices, tag);
    this.setState((state, props) => ({
      enableNotification: true,
      isUpdating: true,
      notificationMessage: `Sending ${tag} Legacy Version`
    }));
    this.props.Patch(request).then(() => {
      this.props.remoteInitialize("DeviceGroupForm", {
        electronTag: [],
        electronEnv: []
      });
      this.setState(() => ({
        isUpdating: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.handleCurrentGroupDeviceLoad();
      this.clearSelectedDevices();
      // this.setState(() => ({ sendingEnvironment: false }));
    });
  };

  sendGroupElectronAppEnvironment = async (id, devices, env) => {
    const { sendEnvironmentForGroupDevices } = Resources;
    const request = sendEnvironmentForGroupDevices(id);
    request.body = request.bodyFunction(devices, env);
    this.setState((state, props) => ({
      enableNotification: true,
      isSendingEnv: true,
      notificationMessage: `Sending ${env} Environment to Devices`
    }));
    this.props.Patch(request).then(() => {
      this.props.remoteInitialize("DeviceGroupForm", {
        electronTag: [],
        electronEnv: []
      });
      this.setState(() => ({
        isSendingEnv: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedDevices();
    });
  };

  sendGroupResolution = async (id, devices, res) => {
    this.toggledeviceControlModal(false);
    const { setResolutionGroupDevices } = Resources;
    const request = setResolutionGroupDevices(id);
    request.body = request.bodyFunction(devices, res);
    this.setState((state, props) => ({
      enableNotification: true,
      isSendingRes: true,
      notificationMessage: `Setting devices reolution to ${res}`
    }));
    this.props.Patch(request).then(() => {
      this.setState(() => ({
        isSendingRes: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.handleCurrentGroupDeviceLoad();
      this.clearSelectedDevices();
    });
  };

  sendGroupOrientation = async (id, devices, orientation) => {
    this.toggledeviceControlModal(false);
    const { setOrientationGroupDevices } = Resources;
    const request = setOrientationGroupDevices(id);
    request.body = request.bodyFunction(devices, orientation);
    this.setState((state, props) => ({
      enableNotification: true,
      isSendingOrientation: true,
      notificationMessage: `Setting devices orientation to ${orientation} `
    }));
    this.props.Patch(request).then(() => {
      this.setState(() => ({
        isSendingOrientation: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.handleCurrentGroupDeviceLoad();
      this.clearSelectedDevices();
    });
  };

  sendGrouptimeZone = async (id, devices, timezone) => {
    this.toggledeviceControlModal(false);
    const { setTimeZoneGroupDevices } = Resources;
    const request = setTimeZoneGroupDevices(id);
    request.body = request.bodyFunction(devices, timezone);
    this.setState((state, props) => ({
      enableNotification: true,
      isSendingTimeZone: true,
      notificationMessage: `Setting devices timezone to ${timezone}`
    }));
    this.props.Patch(request).then(() => {
      this.setState(() => ({
        isSendingTimeZone: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.handleCurrentGroupDeviceLoad();
      this.clearSelectedDevices();
    });
  };

  stopGroupDevices = async (id, values, status) => {
    const { stopGroup } = Resources;
    const request = stopGroup(id);
    this.setState((state, props) => ({
      enableNotification: true,
      notificationMessage: `Sending Stop Signal to Devices`
    }));
    request.body = JSON.stringify({
      _jsonapi: {
        data: {
          type: "device_groups",
          attributes: {
            device_ids: values.map(value => value.id)
          }
        }
      }
    });
    if (status === "advance") {
      this.props.Post(request).then(() => {
        this.setState((state, props) => ({
          enableNotification: false,
          notificationMessage: ""
        }));
      });
    } else {
      this.refreshNetworkGroup(request);
    }
  };

  handleGroupVolume = (id, values, volume) => {
    this.toggledeviceControlModal(false);
    const { setGroupDeviceVolume, getSelectedGroup } = Resources;
    this.setState({
      enableNotification: true,
      notificationMessage: "Updating the volume of Devices"
    });
    const request = setGroupDeviceVolume(id);
    request.body = request.bodyFunction(values, volume);
    this.props.Patch(request).then(() => {
      this.props.Get(Resources.getNetwork);
      getSelectedGroup.url = `/device_groups/${this.props.newSelectedGroup.device_group_id}/devices?page=1&per_page=${this.state.devicesPerPage}`;
      this.props.Get(getSelectedGroup);
      this.setState({
        enableNotification: false,
        notificationMessage: ""
      });
      this.clearSelectedDevices();
    });
  };

  refreshNetworkGroup = request => {
    const { newSelectedGroup } = this.props;
    const selectedGroup = newSelectedGroup.device_group_id;
    this.props
      .Post(request)
      // .then(() => this.props.Get(Resources.getNetwork))
      .then(() => {
        this.props.change("selectedDevicesInGroup", []);
        this.setState((state, props) => ({
          enableNotification: false,
          notificationMessage: ""
        }));
      })
      .then(() => {
        if (this[`group-${selectedGroup}`]) {
          this[`group-${selectedGroup}`].current.selectionContext.selected = [];
          this[`group-${selectedGroup}`].current.setState({});
        }
      });
  };

  // handleAddAndChangeGroup = values => {
  //   const { changeGroup } = Resources;
  //   const { selectedGroup } = values;
  //   const id = selectedGroup.value;
  //   const request = changeGroup(id);
  //   request.body = request.bodyFunction(this.props.selectedDeviceInGroup);
  //   this.props
  //     .Put(request)
  //     .then(() => this.props.Get(Resources.getNetwork))
  //     // .then(() => this.toggleAddToGroupModal())
  //     .then(() => this.props.destroy("AddAndChangeGroupForm", ""));
  // };

  triggerRS232Command = (id, action, devices) => {
    this.toggledeviceControlModal(false);
    this.setState({
      enableNotification: true,
      notificationMessage: `Sending ${action} signal to RS232`
    });
    const { rs232GroupControl } = Resources;
    const request = rs232GroupControl(id);
    request.body = request.bodyFunction(action, devices);
    this.props.Post(request).then(() => {
      this.setState({
        enableNotification: false,
        notificationMessage: ""
      });
      this.clearSelectedDevices();
    });
  };

  handleLoadAllTags = () => {
    const { getElectronTags, getLegacyElectronTags } = Resources;
    this.props.Get(getElectronTags);
    // this.props.Get(getLegacyElectronTags);
  };

  changeSelectedGroup = async selectedGroup => {
    const { newSelectedGroup } = this.props;

    if (selectedGroup.id !== newSelectedGroup.device_group_id) {
      this.props.setSelectedGroup(selectedGroup);
      this.setDeviceSearch("");
      this.setState({ forcePage: 0 });
      this.props.handleGroupLoading(true);
      const { getSelectedGroup } = Resources;
      const index = newSelectedGroup.device_group_id;
      this.props.change("selectedDevicesInGroup", []);
      this.props.change("selectedGroup", selectedGroup);
      if (this.searchDevice.current.value !== "") {
        getSelectedGroup.url = `/device_groups/${selectedGroup.id}/device_search?search=${this.searchDevice.current.value}&page=1&per_page=200`;
      } else {
        getSelectedGroup.url = `/device_groups/${selectedGroup.id}/devices?page=1&per_page=${this.state.devicesPerPage}`;
      }

      this.props.setSelectedGroupId(selectedGroup.id);
      await this.props.Get(getSelectedGroup).then(() => {
        this.props.handleGroupLoading(false);
      });

      if (this[`group-${index}`]) {
        this.props.change("selectedDevicesInGroup", []);
        if (this[`group-${index}`] && this[`group-${index}`].current) {
          this[`group-${index}`].current.selectionContext.selected = [];
          this[`group-${index}`].current.setState({});
        }
      }
    }
  };

  handleAddAndChangeGroup = (values, devices) => {
    this.toggledeviceControlModal(false);
    this.handleDeviceGroupState();
    this.setState({
      enableNotification: true,
      notificationMessage: `Updating Device Group`
    });
    const { changeGroup, getSelectedGroup, getNetwork } = Resources;
    const { selectedGroup } = values;
    const id = selectedGroup.value;
    const request = changeGroup(id);
    // request.body = request.bodyFunction(this.props.selectedDeviceInGroup);
    request.body = request.bodyFunction(devices);
    this.props
      .Put(request)
      .then(() => {
        getNetwork.url = `/device_groups?page=${1}&per_page=${20}&sort=name&sort_by=asc`;
        this.props.Get(Resources.getNetwork);
        getSelectedGroup.url = `/device_groups/${this.props.newSelectedGroup.device_group_id}/devices?page=1&per_page=200`;
        this.props.Get(Resources.getSelectedGroup);
      })

      .then(() => {
        this.props.remoteInitialize("AddAndChangeGroupForm", {
          selectedGroup: []
        });
        this.setState({
          enableNotification: false,
          notificationMessage: ""
        });
        this.clearSelectedDevices();
      });
    this.props.handleRefetch(true);
  };

  handleNext = async () => {
    const { getNetwork } = Resources;
    if (this.props.refetch) {
      this.setState(
        prevState => ({
          deviceGroupPage: prevState.deviceGroupPage + 1
        }),
        () => {
          if (this.state.searchVal) {
            getNetwork.url = `device_groups/search?search=${this.state.searchVal}&page=${this.state.deviceGroupPage}&per_page=${this.state.perPage}&sort=name&sort_by=asc`;
            this.props
              .Get(getNetwork)
              .then(response => {
                if (response.data.meta.totalPages <= response.data.meta.page) {
                  this.props.handleRefetch();
                }
              })
              .then(() => this.props.updateGroupInfiniteScroll());
            return 0;
          }
          getNetwork.url = `/device_groups?page=${this.state.deviceGroupPage}&per_page=${this.state.perPage}&sort=name&sort_by=asc`;
          this.props
            .Get(getNetwork)
            .then(response => {
              if (response.data.meta.totalPages <= response.data.meta.page) {
                this.props.handleRefetch();
              }
            })
            .then(() => this.props.updateGroupInfiniteScroll());
        }
      );
    }
    return true;
  };

  handleDeviceGroupState = () => {
    this.setState({ deviceGroupPage: 1 });
    this.forceUpdate();
  };

  removeDevice = async device => {
    const { newSelectedGroup } = this.props;
    this.setState({ deviceGroupPage: 1 });
    this.forceUpdate();
    const { createEditGroup, getNetwork } = Resources;
    const { devices } = this.props;
    const editedDevices = devices
      .filter(item => item.id !== device.id)
      .map(item => ({
        value: item.id,
        meta: item
      }));
    this.props.openGlobalModal({
      heading: "Remove Device",
      body:
        "Are you sure you want to remove devices form this group? Remove devices  will remove all device(s) from this group. Device(s) will be listed in the unassigned group.",
      actionFunc: () => {
        const request = createEditGroup(newSelectedGroup.device_group_id);
        request.body = request.bodyFunction({ devices: editedDevices });

        this.props.Put(request).then(() => {
          this.props.Get(Resources.getSelectedGroup);
          getNetwork.url = `/device_groups?page=${1}&per_page=${
            this.state.perPage
          }&sort=name&sort_by=asc`;
          this.props.handleRefetch(true);
          this.props.Get(Resources.getNetwork);
        });
      }
    });
  };

  initializeAPICallCreate = async () => {
    const allRequestPromise = [this.loadUnassignedDevices()];
    Promise.all(allRequestPromise).then(() => {
      this.setState({ isLoading: false });
    });
  };

  loadUnassignedDevices = () => {
    const { getUnassignedDevices } = Resources;
    this.props.Get(getUnassignedDevices);
  };

  toggleEditModal = flag => {
    this.setState({
      isModalOpen: flag
    });
  };

  handleGroupCreate = (modal, action, groupToBeEdited) => {
    this.setState({ createGroupModal: modal, action, groupToBeEdited });
    this.props.loadUnassignedDevices();
    this.props.remoteInitialize("CreateGroupForm", {
      deviceGroupName: "",
      devices: []
    });

    if (modal && action === "edit") {
      this.props.Get(Resources.getGroupEditID(groupToBeEdited.id)).then(res => {
        this.props.remoteInitialize("CreateGroupForm", {
          deviceGroupName: groupToBeEdited.attributes.name,
          devices: res.data.data.map(item => ({
            value: item.id,
            label: `${item.attributes.name} || ${item.attributes.mac_address}`,
            meta: item
          }))
        });
      });
    }
  };

  handleGroupDefaultCampaignSave = (modal, action, campaignList) => {
    if (!action || !campaignList) {
      this.setState({ defaultCampaignSubmitModal: modal });
    } else {
      this.setState({ defaultCampaignSubmitModal: modal });
      if (action !== "remove") {
        this.props.remoteInitialize("GroupDefaultCampaignSelectFrom", {
          campaigns: []
        });
      }
    }
  };

  addGroup = values => {
    this.handleCreate(values);
  };

  handleCreate = values => {
    const { newSelectedGroup } = this.props;
    const { getNetwork, getSelectedGroup } = Resources;
    const { createEditGroup } = Resources;
    this.setState({ deviceGroupPage: 1 });
    if (this.state.action === "edit") {
      const request = createEditGroup(newSelectedGroup.device_group_id);
      const formattedGroup = {
        id: newSelectedGroup.device_group_id,
        attributes: { name: values.deviceGroupName }
      };
      request.body = request.bodyFunction(values);
      this.props.Put(request).then(() => {
        this.setState({ createGroupModal: false });
        this.props.setSelectedGroup(formattedGroup);
        getSelectedGroup.url = `/device_groups/${newSelectedGroup.device_group_id}/devices?page=1&per_page=${this.state.devicesPerPage}`;
        this.props.Get(Resources.getSelectedGroup).then(response => {
          if (response.data.meta.totalPages <= response.data.meta.page) {
            this.props.handleRefetchGroup(false);
          } else {
            this.props.handleRefetchGroup(true);
          }
        });
        if (this.state.searchVal === "") {
          getNetwork.url = `/device_groups?page=${1}&per_page=${
            this.perPageCount
          }&sort=name&sort_by=asc`;
        } else {
          getNetwork.url = `/device_groups/search?search=${
            this.state.searchVal
          }&page=${1}&per_page=${this.perPageCount}&sort=name&sort_by=asc`;
        }
        this.props.Get(getNetwork);
        this.props.loadUnassignedDevices();
      });
    } else {
      const request = createEditGroup();
      request.url = "/device_groups";
      request.body = request.bodyFunction(values);
      this.props.Post(request).then(() => {
        this.setState({ createGroupModal: false });
        this.props.handleLoadNetworkGroups();
        this.props.loadUnassignedDevices();
      });
    }
    this.props.handleRefetch(true);
  };

  handleGroupDelete = id => {
    const { deleteGroup, getNetwork } = Resources;
    this.setState({ deviceGroupPage: 1 });
    this.props.openGlobalModal({
      heading: "Delete Group",
      body:
        "Are you sure you want to delete this group? Deleting this group will remove all device(s) from this group. Device(s) will be listed in the unassigned group.",
      actionFunc: args => {
        getNetwork.url = `/device_groups?page=${1}&per_page=${
          this.perPageCount
        }&sort=name&sort_by=asc`;
        this.props.Delete(args).then(() => {
          this.props.Get(getNetwork).then(res => {
            if (res.status === 200) {
              const { getSelectedGroup } = Resources;
              getSelectedGroup.url = `/device_groups/${res.data.data[0].id}/devices?page=1&per_page=200`;
              this.props.setSelectedGroup(res.data.data[0]);
              this.props.Get(Resources.getSelectedGroup);
            }
            this.props.handleLoadAllNetworkGroup();
          });
        });
      },
      event: { ...deleteGroup(id) }
    });
    this.props.handleRefetch(true);
  };

  setSingleDevice = item => {
    const singleDevice = [];
    singleDevice.push(item);
    this.setState({ selectedSingleDevice: singleDevice });
    this.props.remoteInitialize("DeviceGroupForm", {
      volume: 85,
      timezone: timeZones.filter(
        timezone => timezone.value === item.attributes.timezone
      ),
      resolution: resolutions.filter(
        res => res.resolution === item.attributes.resolution
      ),
      orientation: item.attributes.orientation,
      electronEnv: {
        value: item.attributes.electron_environment,
        label: capitalize(item.attributes.electron_environment)
      },
      proofOfPlay: !item.attributes.block_pop
    });
  };

  toggleSingleDevice = item => {
    this.setState({ isSingleDevice: item });
  };

  handleCurrentGroupDeviceLoad = () => {
    const { getSelectedGroup } = Resources;

    if (this.state.deviceSearch) {
      getSelectedGroup.url = `/device_groups/${this.props.selectedGroupId}/device_search?search=${this.state.deviceSearch}&page=1&per_page=200`;
    } else {
      getSelectedGroup.url = `/device_groups/${this.props.selectedGroupId}/devices?page=1&per_page=200&sort=name&sort_by=asc`;
    }
    this.props.Get(getSelectedGroup);
  };

  handleFavouriteGroup = (id, flag) => {
    const { setFavouriteGroup } = Resources;
    const request = setFavouriteGroup(id);
    request.body = request.bodyFunction(flag);
    this.props.Patch(request).then(res => {
      if (res.status === 200) {
        this.props.Get(Resources.getNetwork);
      }
    });
  };

  handleProofOfPlay = (devices, flag) => {
    const devicesMacAddress = devices.map(
      device => device.attributes.attributes.mac_address
    );
    const request = Resources.bulkDevicesPopOnOff;
    request.body = request.bodyFunction(devicesMacAddress, flag);
    this.setState((state, props) => ({
      isSendingPopRequest: true,
      enableNotification: true,
      notificationMessage: `Turning pop data ${!flag ? "on" : "off"}`
    }));
    this.props.Patch(request).then(() => {
      this.setState((state, props) => ({
        isSendingPopRequest: false,
        enableNotification: false,
        notificationMessage: ""
      }));
      this.clearSelectedDevices();
      this.toggledeviceControlModal(false);
    });
  };

  render() {
    if (this.props.isLoading) {
      return (
        <div
          className="d-block justify-content-center text-center"
          style={{ marginTop: "100px" }}
        >
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    return (
      <div>
        <NetworkHeader
          campaignList={this.props.campaignList}
          calendarList={this.props.calendarList}
          deviceGroups={this.props.deviceGroups}
          handleDeleteCallback={this.handleChange}
          handleSendCampaignToPlayer={this.handleSubmitNetworkSelector}
          handleSendCalendarToPlayer={this.handleSubmitNetworkCalendarSelector}
          modalSendToMedia={this.state.sendMediaModal}
          modalAddGroupModal={this.state.addToGroupModal}
          deviceControlModal={this.state.deviceControlModal}
          toggleSendToMediaModal={this.toggleSendToMediaModal}
          toggleAddToGroupModal={this.toggleAddToGroupModal}
          toggledeviceControlModal={this.toggledeviceControlModal}
          selectedGroup={
            this.props.newSelectedGroup &&
            this.props.newSelectedGroup.device_group_id
          }
          selectedGroupName={
            this.props.newSelectedGroup &&
            this.props.newSelectedGroup.device_group_name
          }
          groupDefaultCampaign={
            this.props.newSelectedGroup &&
            this.props.newSelectedGroup.default_campaign
          }
          deviceList={this.props.selectedDeviceInGroup.map(items => ({
            id: items.id,
            attributes: { ...items }
          }))}
          unassignedDevicesList={this.props.unassignedDevicesList}
          handleGroupVolume={this.handleGroupVolume}
          triggerRS232Command={this.triggerRS232Command}
          stopGroupDevices={this.stopGroupDevices}
          resyncGroupDevices={this.resyncGroupDevices}
          restartGroupDevices={this.restartGroupDevices}
          isRestrating={this.state.isRestrating}
          isRebooting={this.state.isRebooting}
          isUpdating={this.state.isUpdating}
          isSendingEnv={this.state.isSendingEnv}
          isSendingRes={this.state.isSendingRes}
          isSendingOrientation={this.state.isSendingOrientation}
          isSendingTimeZone={this.state.isSendingTimeZone}
          rebootGroupDevices={this.rebootGroupDevices}
          updateGroupDevices={this.updateGroupDevices}
          legacyUpdateGroupDevices={this.legacyUpdateGroupDevices}
          handleAddAndChangeGroup={this.handleAddAndChangeGroup}
          handleLoadNetworkGroups={this.props.handleLoadNetworkGroups}
          refreshing={this.props.refreshing}
          Get={this.props.Get}
          Put={this.props.Put}
          Post={this.props.Post}
          Patch={this.props.Patch}
          getUserSetting={this.props.getUserSetting}
          sendingEnvironment={this.state.sendingEnvironment}
          sendGroupElectronAppEnvironment={this.sendGroupElectronAppEnvironment}
          sendGroupResolution={this.sendGroupResolution}
          sendGroupOrientation={this.sendGroupOrientation}
          sendGrouptimeZone={this.sendGrouptimeZone}
          handleGroupCreate={this.handleGroupCreate}
          handleGroupDefaultCampaignSave={this.handleGroupDefaultCampaignSave}
          action={this.state.action}
          createGroupModal={this.state.createGroupModal}
          defaultCampaignSubmitModal={this.state.defaultCampaignSubmitModal}
          addGroup={this.addGroup}
          electronTags={this.props.electronTags}
          handleRefresh={this.props.handleRefresh}
          handleSystemRefresh={this.props.handleSystemRefresh}
          updateGroupDeviceAfterGroupChange={
            this.updateGroupDeviceAfterGroupChange
          }
          refetchCampaign={this.props.refetchCampaign}
          setSelectedGroup={this.props.setSelectedGroup}
          handleRefetch={this.props.handleRefetch}
          handleDeviceGroupState={this.handleDeviceGroupState}
          handleLoadNetworkList={this.props.handleLoadNetworkList}
          setUserSetting={this.props.setUserSetting}
          handleProofOfPlay={this.handleProofOfPlay}
          isSendingPopRequest={this.state.isSendingPopRequest}
          roles={this.props.roles}
        />
        <DesktopBreakpoint>
          <NetworkGroupTableDesktopView
            meta={this.props.meta}
            deviceGroups={this.props.deviceGroups}
            handleNext={this.handleNext}
            refetch={this.props.refetch}
            newSelectedGroup={this.props.newSelectedGroup}
            changeSelectedGroup={this.changeSelectedGroup}
            handleGroupDefaultCampaignSave={this.handleGroupDefaultCampaignSave}
            handleGroupCreate={this.handleGroupCreate}
            handleGroupDelete={this.handleGroupDelete}
            isGroupOpen={this.state.isGroupOpen}
            createRefsFromGroupDeviceTables={
              this.createRefsFromGroupDeviceTables
            }
            searchDevice={this.searchDevice}
            remoteFormChange={this.props.change}
            selectedDeviceInGroup={this.props.selectedDeviceInGroup}
            stopPlayingDevice={this.props.stopPlayingDevice}
            toggleSendToMediaModal={this.toggleSendToMediaModal}
            selectedGroupID={null}
            selectedGroupName={null}
            selectedGroupMeta={this.props.selectedGroupMeta}
            removeDevice={this.removeDevice}
            toggledeviceControlModal={this.toggledeviceControlModal}
            setSingleDevice={this.setSingleDevice}
            isSingleDevice={this.isSingleDevice}
            toggleSingleDevice={this.toggleSingleDevice}
            toggleGroup={this.toggleGroup}
            isGroupLoading={this.props.isGroupLoading}
            handlePageClick={this.handlePageClick}
            deviceForcePage={this.props.deviceForcePage}
            sortOrder={this.props.sortOrder}
            handleDeviceSearch={this.handleDeviceSearch}
            deviceSearch={this.state.deviceSearch}
            setDeviceSearch={this.setDeviceSearch}
            handleDeviceSort={this.handleDeviceSort}
            deviceSortOrder={this.state.deviceSortOrder}
            refetchGroupDevices={this.props.refetchGroupDevices}
            userDetail={this.props.userDetail}
            devices={this.props.devices}
            handleGroupSearchValue={this.handleGroupSearchValue}
            searchVal={this.state.searchVal}
            searchByText={this.searchByText}
            Put={this.props.Put}
            handleFavouriteGroup={this.handleFavouriteGroup}
            handleGroupType={this.handleGroupType}
            groupOptions={this.state.groupOptions}
            groupType={this.state.groupType}
            handleShowGroupOptions={this.handleShowGroupOptions}
          />
        </DesktopBreakpoint>

        <PhoneBreakpoint>
          <NetworkGroupTableMobileView
            deviceGroups={this.props.deviceGroups}
            handleNext={this.handleNext}
            refetch={this.props.refetch}
            changeSelectedGroup={this.changeSelectedGroup}
            deviceGroups={this.props.deviceGroups}
            newSelectedGroup={this.props.newSelectedGroup}
            isGroupOpen={this.state.isGroupOpen}
            createRefsFromGroupDeviceTables={
              this.createRefsFromGroupDeviceTables
            }
            searchDevice={this.searchDevice}
            remoteFormChange={this.props.change}
            selectedDeviceInGroup={this.props.selectedDeviceInGroup}
            stopPlayingDevice={this.props.stopPlayingDevice}
            toggleSendToMediaModal={this.toggleSendToMediaModal}
            selectedGroupID={null}
            selectedGroupName={null}
            selectedGroupMeta={this.props.selectedGroupMeta}
            removeDevice={this.removeDevice}
            toggledeviceControlModal={this.toggledeviceControlModal}
            setSingleDevice={this.setSingleDevice}
            isSingleDevice={this.isSingleDevice}
            toggleSingleDevice={this.toggleSingleDevice}
            toggleGroup={this.toggleGroup}
            isGroupLoading={this.props.isGroupLoading}
            handlePageClick={this.handlePageClick}
            deviceForcePage={this.props.deviceForcePage}
            sortOrder={this.props.sortOrder}
            handleDeviceSearch={this.handleDeviceSearch}
            deviceSearch={this.state.deviceSearch}
            setDeviceSearch={this.setDeviceSearch}
            handleDeviceSort={this.handleDeviceSort}
            deviceSortOrder={this.state.deviceSortOrder}
            refetchGroupDevices={this.props.refetchGroupDevices}
            userDetail={this.props.userDetail}
            devices={this.props.devices}
            handleNewAccordionExpand={this.handleNewAccordionExpand}
            handleGroupSearchValue={this.handleGroupSearchValue}
            searchVal={this.state.searchVal}
            searchByText={this.searchByText}
            Put={this.props.Put}
          />
        </PhoneBreakpoint>
        <div style={{ display: "none" }}>
          {!this.props.selectedDeviceInGroup.length > 0 && (
            <DeviceGroupControlView
              updateGroupDeviceAfterGroupChange={
                this.updateGroupDeviceAfterGroupChange
              }
              campaignList={this.props.campaignList}
              calendarList={this.props.calendarList}
              deviceGroups={this.props.deviceGroups}
              handleDeleteCallback={this.handleChange}
              handleSendCampaignToPlayer={this.handleSubmitNetworkSelector}
              handleSendCalendarToPlayer={
                this.handleSubmitNetworkCalendarSelector
              }
              modalSendToMedia={this.state.sendMediaModal}
              modalAddGroupModal={this.state.addToGroupModal}
              deviceControlModal={this.state.deviceControlModal}
              toggleSendToMediaModal={this.toggleSendToMediaModal}
              toggleAddToGroupModal={this.toggleAddToGroupModal}
              toggledeviceControlModal={this.toggledeviceControlModal}
              selectedGroup={
                this.props.newSelectedGroup &&
                this.props.newSelectedGroup.device_group_id
              }
              selectedGroupName={
                this.props.newSelectedGroup && this.props.newSelectedGroup.name
              }
              selectedDevices={this.state.selectedSingleDevice.map(items => ({
                id: items.id,
                attributes: { ...items }
              }))}
              unassignedDevicesList={this.props.unassignedDevicesList}
              handleGroupVolume={this.handleGroupVolume}
              triggerRS232Command={this.triggerRS232Command}
              stopGroupDevices={this.stopGroupDevices}
              resyncGroupDevices={this.resyncGroupDevices}
              restartGroupDevices={this.restartGroupDevices}
              isRestrating={this.state.isRestrating}
              isRebooting={this.state.isRebooting}
              isUpdating={this.state.isUpdating}
              isSendingEnv={this.state.isSendingEnv}
              isSendingRes={this.state.isSendingRes}
              isSendingOrientation={this.state.isSendingOrientation}
              isSendingTimeZone={this.state.isSendingTimeZone}
              rebootGroupDevices={this.rebootGroupDevices}
              updateGroupDevices={this.updateGroupDevices}
              legacyUpdateGroupDevices={this.legacyUpdateGroupDevices}
              handleAddAndChangeGroup={this.handleAddAndChangeGroup}
              handleLoadNetworkGroups={this.props.handleLoadNetworkGroups}
              Get={this.props.Get}
              Put={this.props.Put}
              Post={this.props.Post}
              getUserSetting={this.props.getUserSetting}
              sendingEnvironment={this.state.sendingEnvironment}
              sendGroupElectronAppEnvironment={
                this.sendGroupElectronAppEnvironment
              }
              sendGroupResolution={this.sendGroupResolution}
              sendGroupOrientation={this.sendGroupOrientation}
              sendGrouptimeZone={this.sendGrouptimeZone}
              handleGroupCreate={this.handleGroupCreate}
              action={this.state.action}
              createGroupModal={this.state.createGroupModal}
              defaultCampaignSubmitModal={this.state.defaultCampaignSubmitModal}
              addGroup={this.addGroup}
              handleRefresh={this.props.handleRefresh}
              handleSystemRefresh={this.props.handleSystemRefresh}
              increaseCampaignCount={this.props.increaseCampaignCount}
              refetchCampaign={this.props.refetchCampaign}
              handleRefetch={this.props.handleRefetch}
              handleDeviceGroupState={this.handleDeviceGroupState}
              updatingGroup={this.state.updatingGroup}
              handleProofOfPlay={this.handleProofOfPlay}
              isSendingPopRequest={this.state.isSendingPopRequest}
              {...this.props}
            />
          )}
        </div>
        {this.state.enableNotification && (
          <div
            id="notification_toast-flash"
            className="notification_toast info animated fadeInUp"
          >
            <p>
              {this.state.notificationMessage}{" "}
              <i className="fa fa-spinner fa-spin" />
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const selector = formValueSelector("deviceSelectFormForGroup");
    const { meta } = state.networks;
    const selectedGroup = state.selectedGroup.list || [];
    const selectedGroupMeta = state.selectedGroup.meta;
    const userDetail = state.userDetails.user;
    const { unassignedDevicesList } = state.unAssignedDevices;
    return {
      unassignedDevicesList: unassignedDevicesList || [],
      selectedDeviceInGroup: selector(state, "selectedDevicesInGroup") || [],
      groupSelected: selector(state, "selectedGroup") || props.deviceGroups[0],
      meta,
      devices: selectedGroup,
      selectedGroupMeta,
      userDetail
    };
  },
  { Patch, Get, remoteInitialize, updateGroupInfiniteScroll }
)(
  reduxForm({
    form: "deviceSelectFormForGroup"
  })(NetworkGroupTable)
);
