import React, { Component } from "react";
import BrandNotificationTable from "./brandNotificationTable";
import { Resources } from "../redux/headerNotificationRedux";

class AllBrandNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedList: [],
        }
    }

    componentDidMount = () => {
        this.getAllNotifications();
    }

    getAllNotifications = async (url) => {
        const { getAllBrandNotifications } = Resources;
        getAllBrandNotifications.url = url || "brand_notifications?page=1&per_page=20"
        await this.props.Get(getAllBrandNotifications)
            .then(res => {
                const { data: { data } } = res;
                const filteredList = [];
                data.forEach(elem => {
                    if (elem.attributes.is_checked) filteredList.push(elem.id);
                })
                this.setState({
                    checkedList: filteredList
                })
            })
            .catch(err => {
                console.error(err)
            })
    }

    render() {
        return (
            <div className="mt-3">
                <BrandNotificationTable
                    {...this.props}
                    getAllNotifications={this.getAllNotifications}
                    checkedList={this.state.checkedList}
                />
            </div>
        )
    }
}

export default AllBrandNotifications;