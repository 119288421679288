import { Map } from "../../helper/routeMap";
import NoRoutes from "../../core/layout/noroutes";
import Archives from "./archives";

const mainPath = "/archives";
const pathCreator = `${mainPath}`;
const feature = "Archive";

export default [
  {
    path: `${pathCreator}`,
    component: Map(Archives),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/*`,
    component: Map(NoRoutes),
    exact: true,
    secured: true,
    feature
  }
];
