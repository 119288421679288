import React, { Component } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

//  styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import NetworkGroupDeviceTable from "../modules/networkGroupDeviceTable";
import { NetworkGroupSearch } from "../modules/networkGroupSearch";

export class NetworkGroupTableMobileView extends Component {
  changeSelectedGroup = selectedGroup => {
    this.props.changeSelectedGroup(selectedGroup);
  };

  render() {
    const { deviceGroups } = this.props;
    return (
      <div>
        <NetworkGroupSearch
          searchByText={this.props.searchByText}
          handleGroupSearchValue={this.props.handleGroupSearchValue}
          searchVal={this.props.searchVal}
        />
        <InfiniteScroll
          dataLength={deviceGroups.length} // This is important field to render the next data
          next={this.props.handleNext}
          hasMore={this.props.refetch}
          // height={700}
          loader={
            <div className="d-block text-center">
              <span>...Loading</span>
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center", "margin-top": "15px" }}>
              <b>{deviceGroups.length === 0 && "No data"}</b>
            </p>
          }
          style={{ overFlow: "visible" }}
        >
          <Accordion
            onChange={this.props.handleNewAccordionExpand}
            allowZeroExpanded
          >
            {this.props.deviceGroups.map((item, index) => (
              <AccordionItem key={item.id} uuid={item.id}>
                <AccordionItemHeading key={item.id}>
                  <AccordionItemButton
                    key={item.id}
                    className={`accordion__button
                     ${this.props.newSelectedGroup.device_group_id ===
                       item.id && "activeAccordion"}
                    `}
                  >
                    <ul
                      role="presentation"
                      key={item.id}
                      onClick={() => this.changeSelectedGroup(item)}
                    >
                      <div className="d-flex">
                        <div className="mr-auto">
                          <li className="accordinTableList">
                            {item.attributes.name}
                          </li>{" "}
                          <div>
                            Online:{" "}
                            <b>{`          ${
                              item.attributes.devices_online
                            }/${item.attributes.devices_online +
                              item.attributes.devices_offline}
`}</b>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </AccordionItemButton>
                </AccordionItemHeading>
                {this.props.newSelectedGroup.device_group_id === item.id && (
                  <AccordionItemPanel>
                    {deviceGroups.length === 0 ? (
                      <NetworkGroupDeviceTable
                        devices={[]}
                        forwardRef={this.props.createRefsFromGroupDeviceTables(
                          this.props.newSelectedGroup.device_group_id
                        )}
                        deviceSearchRef={this.props.searchDevice}
                        remoteFormChange={this.props.remoteFormChange}
                        selectedDeviceInGroup={this.props.selectedDeviceInGroup}
                        stopPlayingDevice={this.props.stopPlayingDevice}
                        toggleSendToMediaModal={
                          this.props.toggleSendToMediaModal
                        }
                        selectedGroupID={null}
                        selectedGroupName={null}
                        meta={this.props.selectedGroupMeta}
                        removeDevice={this.props.removeDevice}
                        toggledeviceControlModal={
                          this.props.toggledeviceControlModal
                        }
                        setSingleDevice={this.props.setSingleDevice}
                        isSingleDevice={this.props.isSingleDevice}
                        toggleSingleDevice={this.props.toggleSingleDevice}
                        toggleGroup={this.props.toggleGroup}
                        isGroupOpen={this.props.isGroupOpen}
                        isGroupLoading={this.props.isGroupLoading}
                        handlePageClick={this.props.handlePageClick}
                        forcePage={this.props.deviceForcePage}
                        sortOrder={this.props.sortOrder}
                        handleDeviceSearch={this.props.handleDeviceSearch}
                        deviceSearch={this.props.deviceSearch}
                        setDeviceSearch={this.props.setDeviceSearch}
                        handleSort={this.props.handleDeviceSort}
                        deviceSortOrder={this.props.deviceSortOrder}
                        refetchGroupDevices={this.props.refetchGroupDevices}
                        newSelectedGroup={this.props.newSelectedGroup}
                        userDetail={this.props.userDetail}
                        Put={this.props.Put}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        isMobileView
                      />
                    ) : (
                      <NetworkGroupDeviceTable
                        devices={this.props.devices ? this.props.devices : []}
                        forwardRef={this.props.createRefsFromGroupDeviceTables(
                          this.props.newSelectedGroup.device_group_id
                        )}
                        deviceSearchRef={this.props.searchDevice}
                        {...this.props}
                        remoteFormChange={this.props.remoteFormChange}
                        selectedDeviceInGroup={this.props.selectedDeviceInGroup}
                        stopPlayingDevice={this.props.stopPlayingDevice}
                        toggleSendToMediaModal={
                          this.props.toggleSendToMediaModal
                        }
                        selectedGroupID={
                          this.props.newSelectedGroup
                            ? this.props.newSelectedGroup.device_group_id
                            : null
                        }
                        selectedGroupName={
                          this.props.newSelectedGroup
                            ? this.props.newSelectedGroup.device_group_name
                            : null
                        }
                        meta={this.props.selectedGroupMeta}
                        removeDevice={this.props.removeDevice}
                        toggledeviceControlModal={
                          this.props.toggledeviceControlModal
                        }
                        setSingleDevice={this.props.setSingleDevice}
                        isSingleDevice={this.props.isSingleDevice}
                        toggleSingleDevice={this.props.toggleSingleDevice}
                        toggleGroup={this.props.toggleGroup}
                        isGroupOpen={this.props.isGroupOpen}
                        isGroupLoading={this.props.isGroupLoading}
                        handlePageClick={this.props.handlePageClick}
                        forcePage={this.props.deviceForcePage}
                        sortOrder={this.props.sortOrder}
                        handleDeviceSearch={this.props.handleDeviceSearch}
                        deviceSearch={this.props.deviceSearch}
                        setDeviceSearch={this.props.setDeviceSearch}
                        handleSort={this.props.handleDeviceSort}
                        deviceSortOrder={this.props.deviceSortOrder}
                        refetchGroupDevices={this.props.refetchGroupDevices}
                        newSelectedGroup={this.props.newSelectedGroup}
                        userDetail={this.props.userDetail}
                        Put={this.props.Put}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        isMobileView
                      />
                    )}
                  </AccordionItemPanel>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </InfiniteScroll>
      </div>
    );
  }
}
