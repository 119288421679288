import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { textFilter, selectFilter } from "react-bootstrap-table2-filter";
import { Progress } from "reactstrap";
import { upperFirst } from "lodash";
import { pipe } from "ramda";
import context from "react-bootstrap-table2-filter/lib/src/context";
import { TextClipper } from "../../../helper/TextFormatter";
import { PopOver, Tooltip } from "../../../core";
import DesktopBreakpoint from "../../../context/desktop_breakpoint";

const headerSortingClasses = sortOrder =>
  sortOrder.currently_playing.active ? "demo-sorting-asc" : "demo-sorting-desc";

function shouldShowDownloadBar(totalDownload, totalContentDownloaded) {
  if (totalContentDownloaded === totalDownload) {
    return false;
  }
  if (totalContentDownloaded > totalDownload) {
    return false;
  }
  return true;
}

function checkLength(word, limit = 25) {
  if (word.length > limit) {
    return `${word.slice(0, limit)}...`;
  }
  return word;
}

function convertOrientaion(orientation) {
  const lowerCase = orientation.toLowerCase();
  if (
    lowerCase === "portrait_left" ||
    lowerCase === "portrait_right" ||
    lowerCase === "portrait"
  ) {
    return "Portrait";
  }
  if (
    lowerCase === "landscape_normal" ||
    lowerCase === "landscape_inverted" ||
    lowerCase === "landscape"
  ) {
    return "Landscape";
  }
}

function percentageCalculator(totalDownload, totalContentDownloaded) {
  return (totalContentDownloaded / totalDownload) * 100;
}

function toolTipsValueCalculator(text, id, limit = 25) {
  return (
    <Tooltip
      item={
        <span className="content__titlename">{checkLength(text, limit)}</span>
      }
      id={`viewname-${id}-${limit}`}
      tooltipContent={text}
    />
  );
}

const calculateType = ({ calendar_name, campaign_name }) => {
  if (calendar_name) {
    return { type: "calendar" };
  }
  if (campaign_name) {
    return { type: "campaign" };
  }
  return { type: null };
};

const formatMediaType = ({ type }) => {
  if (type === "calendar") {
    return <span className="networkPlaying">Calendar</span>;
  }
  if (type === "campaign") {
    return <span className="networkPlaying">Campaign</span>;
  }
  return null;
};

const formatedStatus = pipe(
  calculateType,
  formatMediaType
);

const formatCurrentlyPlaying = ({ campaign_name, calendar_name, id }) => {
  if (campaign_name || calendar_name) {
    return (
      <React.Fragment>
        {toolTipsValueCalculator(campaign_name || calendar_name, id)}
        {formatedStatus({ campaign_name, calendar_name })}
      </React.Fragment>
    );
  }
  return <span className="ntwk-features_calendar">None</span>;
};

const DownloadProgress = ({
  received_media_type,
  received_media_name,
  total_content_to_be_downloaded,
  total_downloaded,
  id
}) => (
  <React.Fragment>
    <div>{toolTipsValueCalculator(received_media_name, id, 25)}</div>
    <Progress
      striped
      value={percentageCalculator(
        total_content_to_be_downloaded,
        total_downloaded
      )}
    />
    <small> {`${total_downloaded} / ${total_content_to_be_downloaded}`}</small>
    <div className="text-left">
      {formatMediaType({ type: received_media_type })}
    </div>
  </React.Fragment>
);

export function columns(
  triggerHelpDialog,
  stopPlayingDevice,
  handleSort,
  sortOrder,
  hiddenColumn,
  toggleDrawer,
  toggleSendToDevice,
  deviceList
) {
  return [
    {
      dataField: "action_buttons",
      text: "",
      style: { position: "relative" },
      columnName: "action_buttons",
      formatter: (a, item) => (
        <div className="pl-2">
          <div className="contentSectionNWTable__actionButtonWrapper">
            <DesktopBreakpoint>
              <span className="contentSectionNWTable__actionButtonWrapper__buttons">
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname: `/networks/device/${item.id}?id=${item.attributes.device_group_id}&gn=${item.attributes.device_group_name}&n=${item.attributes.name}&view=lists`,
                    state: {
                      groupID: item.attributes.device_group_id,
                      groupName: item.attributes.device_group_name,
                      name: a || ""
                    }
                  }}
                  onClick={e => e.stopPropagation()}
                >
                  <Tooltip
                    item={
                      <i
                        role="presentation"
                        // onClick={e => stopPlayingDevice(item)}
                        style={{ cursor: "pointer" }}
                        className="fas fa-eye"
                        onClick={e => e.stopPropagation()}
                      />
                    }
                    id={`show-${item.id}`}
                    tooltipContent="View"
                  />
                </Link>
              </span>{" "}
            </DesktopBreakpoint>

            {deviceList.length === 0 && (
              <Fragment>
                <span className="contentSectionNWTable__actionButtonWrapper__buttons">
                  <Tooltip
                    item={
                      <i
                        role="presentation"
                        onClick={e => {
                          e.stopPropagation();
                          return toggleDrawer(item);
                        }}
                        style={{ cursor: "pointer" }}
                        className="fas fa-cog"
                      />
                    }
                    id={`setting-${item.id}`}
                    tooltipContent="Advanced Setting"
                  />
                </span>
                <span className="contentSectionNWTable__actionButtonWrapper__buttons">
                  <Tooltip
                    item={
                      <i
                        role="presentation"
                        onClick={e => {
                          e.stopPropagation();
                          return toggleSendToDevice(item);
                        }}
                        style={{ cursor: "pointer" }}
                        className="fas fa-play"
                      />
                    }
                    id={`play-${item.id}`}
                    tooltipContent="Play"
                  />
                </span>
                {(item.attributes.campaign_name ||
                  item.attributes.calendar_name) && (
                  <span className="contentSectionNWTable__actionButtonWrapper__buttons">
                    <Tooltip
                      item={
                        <i
                          role="presentation"
                          onClick={e => {
                            e.stopPropagation();
                            return stopPlayingDevice(item);
                          }}
                          style={{ cursor: "pointer" }}
                          className="fas fa-stop"
                        />
                      }
                      id={`delete-${item.id}`}
                      tooltipContent="Stop"
                    />
                  </span>
                )}
              </Fragment>
            )}
          </div>
        </div>
      )
    },
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      headerFormatter: (column, colIndex) => (
        <div>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-name",
      formatter: (name, row) => (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: `/networks/device/${row.id}?id=${row.attributes.device_group_id}&gn=${row.attributes.device_group_name}&n=${name}&view=lists`,
              state: {
                groupID: row.attributes.device_group_id,
                groupName: row.attributes.device_group_name,
                name: name || ""
              }
            }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <span className="content__titlename content__name">{name}</span>
          </Link>
          <br />
        </div>
      )
      // filter: textFilter({ placeholder: "tesNuc" })
    },
    {
      dataField: "attributes.mac_address",
      text: "Mac Address",
      columnName: "mac_address",
      hidden: hiddenColumn.mac_address,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <div>
          <span
            role="presentation"
            onClick={() => handleSort("mac_address")}
            className="sort-by "
            style={{
              color: sortOrder.mac_address.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            Mac Address
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.mac_address.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
          {/* {filterElement} */}
        </div>
      ),
      classes: "network-maAddress",
      formatter: value => (
        <span className="content__titlename content__name font-weight-bold">
          {value}
        </span>
      )
      // filter: textFilter({ placeholder: "94:c6:91:a1:8b:ea" })
    },
    {
      dataField: "attributes.currently_playing",
      text: "Currently Playing",
      columnName: "currently_playing",
      hidden: hiddenColumn.currently_playing,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <div>
          <span
            role="presentation"
            onClick={() => handleSort("currently_playing")}
            className="sort-by "
            style={{
              color: sortOrder.currently_playing.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.currently_playing.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-currentlyPlaying",
      formatter: (val, row) => {
        const {
          attributes: { calendar_name, campaign_name },
          id
        } = row;
        return formatCurrentlyPlaying({ campaign_name, calendar_name, id });
      }
      // sort: true,
      // onSort: (field, order) => {
      //   handleSort("currently_playing");
      // },
      // sortCaret: (order, column) => {
      //   if (!order) return <i className="fa fa-sort-alpha-up" />;
      //   if (order === "asc") return <i className="fa fa-sort-alpha-down" />;
      //   if (order === "desc") return <i className="fa fa-sort-alpha-up" />;
      //   return null;
      // },
      // headerSortingClasses: headerSortingClasses(sortOrder)
      // filter: textFilter({ placeholder: "Campaign" })
    },
    {
      dataField: "attributes.last_downloaded_name",
      text: "Last Sent",
      columnName: "last_downloaded_name",
      hidden: hiddenColumn.last_downloaded_name,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("last_downloaded_name")}
            className="sort-by "
            style={{
              color: sortOrder.last_downloaded_name.active
                ? "#2cc277"
                : "#2B2B2B"
            }}
          >
            {" "}
            Last Sent
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.last_downloaded_name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
          {/* {filterElement} */}
        </div>
      ),
      classes: "network-lastDownloadedName",
      formatter: (val, row) => {
        const {
          attributes: {
            total_content_to_be_downloaded,
            total_downloaded,
            last_downloaded_name,
            last_downloaded_type
          },
          id
        } = row;
        if (
          shouldShowDownloadBar(
            total_content_to_be_downloaded,
            total_downloaded
          )
        ) {
          return DownloadProgress({ ...row.attributes, id });
        }
        return formatCurrentlyPlaying({
          campaign_name:
            last_downloaded_type === "campaign" ? last_downloaded_name : null,
          calendar_name:
            last_downloaded_type === "calendar" ? last_downloaded_name : null,
          id
        });
      }
    },
    {
      dataField: "attributes.device_group_name",
      text: "Group",
      columnName: "group",
      hidden: hiddenColumn.group,
      headerFormatter: (column, colIndex) => (
        <div>
          <span
            role="presentation"
            onClick={e => {
              e.stopPropagation();
              return handleSort("device_group_name");
            }}
            className="sort-by "
            style={{
              color: sortOrder.device_group_name.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            Group
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.device_group_name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-name"
      // filter: textFilter({ placeholder: "tesNuc" })
    },
    {
      dataField: "attributes.tags",
      text: "Tags",
      columnName: "tags",
      hidden: hiddenColumn.tags,
      formatter: (value, item) => (
        // eslint-disable-next-line no-unused-expressions
        <div className="content-tag">
          <div className="tag-list">
            <div style={{ display: "block" }}>
              {item.attributes.tags &&
                item.attributes.tags.slice(0, 2).map((i, index) => (
                  <span key={`tags-${index + i}`} className="contentTag__badge">
                    {TextClipper(item.id, i, 8, index)}
                  </span>
                ))}
              <span className="tagmenu-icon">
                {item.attributes.tags && item.attributes.tags.length >= 3 && (
                  <PopOver id={`tags-${item.id}`}>
                    {item.attributes.tags.map(i => (
                      <span key={`tags-${i}`} className="contentTag__badge">
                        {i}
                      </span>
                    ))}
                  </PopOver>
                )}
              </span>
            </div>
          </div>
        </div>
      ),
      classes: "network-tag"
    },
    {
      dataField: "attributes.hostname",
      text: "Host Name",
      columnName: "hostname",
      hidden: hiddenColumn.hostname,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <div>
          <span
            role="presentation"
            onClick={() => handleSort("hostname")}
            className="sort-by "
            style={{
              color: sortOrder.hostname.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            Host Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.hostname.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
          {/* {filterElement} */}
        </div>
      ),
      // filter: textFilter({ placeholder: "GurzuNuc" }),
      formatter: (name, row) =>
        // eslint-disable-next-line no-unused-expressions
        row.attributes.hostname && row.attributes.hostname.length >= 25 ? (
          <Tooltip
            item={
              <span className="content__name">
                {" "}
                {`${row.attributes.hostname.slice(0, 25)}...`}{" "}
              </span>
            }
            id={`campaignViewHostName-${row.id}`}
            tooltipContent={row.attributes.hostname}
          />
        ) : (
          <span className="content__name" style={{ "word-break": "break-all" }}>
            {row.attributes.hostname}
          </span>
        )
    },
    {
      dataField: "attributes.electron_version",
      text: "Version",
      columnName: "electron_version",
      hidden: hiddenColumn.electron_version,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("electron_version")}
            className="sort-by "
            style={{
              color: sortOrder.electron_version.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            Version
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.electron_version.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <span className="network__electron_version">
          {name && name.length >= 25 ? (
            <Tooltip
              item={
                <span className="network__electron_version">
                  {" "}
                  {`${name.slice(0, 20)}...`}{" "}
                </span>
              }
              id={`viewnamelist-${row.id}`}
              tooltipContent={name}
            />
          ) : (
            <span className="network__electron_version">{name || "N/A"}</span>
          )}
        </span>
      ),
      style: { cursor: "pointer" }
    },
    {
      dataField: "attributes.resolution",
      text: "Resolution",
      columnName: "resolution",
      hidden: hiddenColumn.resolution,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("resolution")}
            className="sort-by "
            style={{
              color: sortOrder.resolution.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            Resolution
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.resolution.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <span className="network__electron_version">{name || "N/A"}</span>
      ),
      style: { cursor: "pointer" }
    },
    {
      dataField: "attributes.timezone",
      text: "Time Zone",
      classes: "network-timezone",
      columnName: "timezone",
      hidden: hiddenColumn.timezone,
      headerFormatter: column => (
        <div>
          <span
            role="presentation"
            onClick={() => handleSort("timezone")}
            className="sort-by "
            style={{
              color: sortOrder.timezone.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.timezone.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (value, name) => (
        <span className="network__electron_version">{value || "N/A"}</span>
      )
      // hidden: hiddenColumn.timezone
      // filter: textFilter({ placeholder: "Asia/Kathmandu" })
    },
    {
      dataField: "attributes.orientation",
      text: "Orientation",
      hidden: hiddenColumn.orientation,
      columnName: "orientation",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("orientation")}
            className="sort-by "
            style={{
              color: sortOrder.orientation.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            Orientation
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.orientation.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
          {/* {filterElement} */}
        </div>
      ),
      formatter: name => (
        <span className="network__electron_version">
          {convertOrientaion(name) || "N/A"}
          {/* {name} */}
        </span>
      ),
      style: { cursor: "pointer" }
    },

    {
      dataField: "attributes.block_pop",
      text: "Proof of Play",
      columnName: "block_pop",
      hidden: hiddenColumn.block_pop,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("block_pop")}
            className="sort-by "
            style={{
              color: sortOrder.block_pop.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            Proof of Play
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.block_pop.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: value => (
        <span className="network__electron_version">
          {!value ? "On" : "Off"}
        </span>
      ),
      style: { cursor: "pointer" }
    },

    {
      dataField: "attributes.status",
      text: "Status",
      columnName: "status",
      hidden: hiddenColumn.status,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("status")}
            className="sort-by "
            style={{
              color: sortOrder.status.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            Status
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.status.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
          {/* {filterElement} */}
        </div>
      ),
      formatter: (status, row) => (
        <div className="content" style={{ "padding-left": "13%" }}>
          <span
            className={`${
              status === "offline"
                ? "content__status__offline"
                : "content__status__online"
            }`}
          />
        </div>
      )
      // filter: selectFilter({
      //   placeholder: "All",
      //   options: { online: "Online", offline: "Offline" }
      // })
    }
  ];
}

export const paginationOptions = {
  sizePerPage: 50,
  hideSizePerPage: true
};

export const dialogs = {
  openHelp: {
    heading: "Search Help",
    body:
      "Search field in the header can be use to perform queries in the table.",
    actionFunc: () => {},
    event: {}
  }
};
