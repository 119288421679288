import React from "react";
import { SecondAdded } from "../../utls/helper";

const TimeParser = contents => {
  const d = SecondAdded(contents);
  return (
    <span className="campaignStudioWrapper__content-duration">
      {`0${d.minute}`.slice(-2)}:{`0${d.second}`.slice(-2)}
    </span>
  );
};
export default function Playlist({
  item,
  screen,
  updateCampaign,
  campaign,
  handleToggleWidgetEdit
}) {
  return (
    <div
      key={item.contentLocalID}
      className="mr-1"
      style={{
        width: "100%",
        height: "60px",
        paddingRight: "2px",
        paddingLeft: "2px",
        paddingTop: "2px",
        cursor: "grabbing"
      }}
    >
      <div
        style={{
          width: `100%`,
          height: "80%",
          // marginTop: "3px",
          borderRadius: "3px",
          backgroundImage: `url(${item.playlistThumbnail})`,
          backgroundPosition: "center center",
          backgroundSize: "contain",
          // backgroundColor: item.color,
          float: "right"
        }}
      >
        <div className="campaignStudioWrapper__playlist-overlay">
          {item.meta.attributes.locked ? (
            <img
              src={require("../../../../assets/images/playlist_locked.png")}
              alt={item.contentName || undefined}
              className="overlay-img content__thumb "
              style={{ height: "25px", width: "25px", left: "26px" }}
            />
          ) : (
            <img
              src={require("../../../../assets/images/playlist_thumbnail.png")}
              alt={item.contentName || undefined}
              className="overlay-img content__thumb "
              style={{ height: "30px", width: "30px" }}
            />
          )}
        </div>
        {TimeParser([item])}
        {/* <span className="d-flex justify-content-center align-item-center campaignStudioWrapper__singleTimeLine__widget-title">
          <i className={`${item.icon} mt-1 mr-1 `} />
          {item.contentName}
        </span> */}
      </div>
    </div>
  );
}
