/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { SearchTagRefresh, Tooltip } from "../../../core";
// import RouteAllowed from "../../../helper/isRouteAllowed";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

const selector = formValueSelector("SelectedTickets");

class TicketHeader extends Component {
  state = { dropdownOpen: false };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  render() {
    const { refreshing, roles, permission } = this.props;
    return (
      <div className="d-flex contentHeader">
        <div className="mr-auto">
          {/* <RouteAllowed roles={roles} permission={permission.ticket.creates}>
            <Link to="/ticket/create" className="mr-auto">
              <button
                className="primaryButton primaryButton--contentHeader"
                // onClick={handleSubmit(values => this.handleLogin(values))}
                type="submit"
              >
                <i className="fa fa-plus mr-2" />
                Add ticket
              </button>{" "}
            </Link>
          </RouteAllowed> */}
        </div>
        {/* <StatusButton
          handleStatus={this.props.handleStatusFilter}
          filterStatus={this.props.filterStatus}
        /> */}
        <div className="d-flex flex-row-reverse">
          <VisibleColumnToggler
            hiddenColumns={this.props.hiddenColumns}
            toggleHiddenColumns={this.props.toggleHiddenColumns}
            columnToggleProps={this.props.columnToggleProps}
          />
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle className="iconButton" caret>
              <i className="bx bx-filter" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <span
                  role="button"
                  onClick={() => this.props.handleFilterWithStatus(" ")}
                  tabIndex="0"
                  onKeyPress={() => {}}
                >
                  All
                </span>
              </DropdownItem>
              <DropdownItem>
                <span
                  role="button"
                  tabIndex="0"
                  onClick={() => this.props.handleFilterWithStatus("open")}
                  onKeyPress={() => {}}
                >
                  New
                </span>
              </DropdownItem>
              <DropdownItem>
                <span
                  role="button"
                  tabIndex="0"
                  onClick={() => this.props.handleFilterWithStatus("pending")}
                  onKeyPress={() => {}}
                >
                  Pending
                </span>
              </DropdownItem>
              <DropdownItem>
                <span
                  role="button"
                  tabIndex="0"
                  onClick={() => this.props.handleFilterWithStatus("resolved")}
                  onKeyPress={() => {}}
                >
                  Resolved
                </span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>{" "}
          {this.props.selectedTickets.length > 0 ? (
            <div className="d-flex">
              {/* <RouteAllowed roles={roles} permission={permission.ticket.delete}>
                <Tooltip
                  item={
                    <div className="iconButton mr-2">
                      <div
                        className="fa fa-trash pt-2"
                        onClick={() =>
                          this.props.handleDeletePlaylists(
                            this.props.selectedTickets
                          )
                        }
                      />
                    </div>
                  }
                  id="header-content-delete"
                  tooltipContent="Delete"
                />
              </RouteAllowed> */}
            </div>
          ) : (
            <Tooltip
              item={
                <div
                  className="iconButton"
                  onClick={() => this.props.handleRefresh()}
                >
                  {" "}
                  <i
                    className={`bx bx-revision ${
                      refreshing ? "refresh-rotate " : ""
                    }`}
                  />
                </div>
              }
              tooltipContent="Refresh"
              id="header-campaign-refresh"
            />
          )}
          <SearchTagRefresh
            handleSearch={this.props.handleSearch}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  selectedTickets: selector(state, "ticketSelected") || []
}))(TicketHeader);
