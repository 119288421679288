import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge
} from "reactstrap";
import { connect } from "react-redux";
import Moment from "moment";
import { filter } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { Resources } from "../../features/tickets/redux";
import { Get } from "../../constant/thunk";
import { Tooltip } from "..";
import RouteAllowed from "../../helper/isRouteAllowed";

class TicketNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      mpsModal: false,
      isLoading: true,
      refetch: true,
      perPage: 10
    };
  }

  getRecentTickets() {
    const { getTicketNotification } = Resources;
    this.setState({ isLoading: true });
    getTicketNotification.url = `/tickets?page=${1}&per_page=${10}`;
    this.props.Get(getTicketNotification).then(() => {
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1000);
    });
  }

  handleNext = async () => {
    const { getTicketNotification } = Resources;
    if (this.state.refetch) {
      this.setState(
        prevState => ({
          perPage: prevState.perPage + 10
        }),
        () => {
          getTicketNotification.url = `/tickets?page=${1}&per_page=${
            this.state.perPage
          }`;
          this.props.Get(getTicketNotification).then(response => {
            if (response.data.meta.totalPages <= response.data.meta.page) {
              this.setState({ refetch: false });
            }
          });
        }
      );
    }
    return true;
  };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
    if (this.state.mpsModal) {
      this.setState({ mpsModal: false });
    }
  };

  onMouseEnter = () => {
    this.setState({ dropdownOpen: true, mpsModal: false });
  };

  onMouseLeave = () => {
    this.setState({ dropdownOpen: false, mpsModal: false });
  };

  noNotification = () => (
    <DropdownItem className="ticket-notification--message">
      <div className="ticket-notification-item">
        <i className="fas fa-bell fa-4x" />
        <span style={{ fontWeight: "bold", fontSize: "20", color: "grey" }}>
          No notifications right now!
        </span>
      </div>
    </DropdownItem>
  );

  handleItems = () => {
    if (this.state.isLoading) {
      return (
        <DropdownItem className="ticket-notification--message">
          Loading...
        </DropdownItem>
      );
    }
    if (
      !this.state.isLoading &&
      this.props.ticketList &&
      this.props.ticketList.length > 0
    ) {
      const openTickets = filter(this.props.ticketList, item => {
        if (
          item.attributes.status === "open" ||
          item.attributes.status === "pending" ||
          item.attributes.status === "resolve"
        ) {
          return item;
        }
      });
      if (openTickets.length > 0) {
        return openTickets.map(item => (
          <DropdownItem
            key={item.id}
            className="ticket-notification--list"
            style={{
              background:
                item.attributes.status === "open"
                  ? "rgb(234, 248, 241)"
                  : "Null"
            }}
          >
            <Link
              to={{
                pathname: `/tickets/show/${item.id}/`,
                state: { name: item.attributes.name }
              }}
            >
              <div className="booking_request_info">
                Booking requested for{" "}
                {item.attributes.name.length > 30 ? (
                  <b className="ticket__name">
                    {`${item.attributes.name.slice(0, 30)}...`}
                  </b>
                ) : (
                  <b className="ticket__name">{item.attributes.name}</b>
                )}
              </div>
              <div className="booking_date_time_info">
                <span className="ticket_date">
                  <i className="far fa-calendar" />{" "}
                  {item.attributes.starting_date}
                  {" - "}
                  {item.attributes.ending_date}
                </span>{" "}
                <span className="ticket_time">
                  <i className="far fa-clock" /> {item.attributes.start_time}
                  {" - "}
                  {item.attributes.end_time}
                </span>
              </div>
              <div style={{ color: "darkgrey", fontSize: "12px" }}>
                {Moment.utc(
                  item.attributes.created_at,
                  "MMM DD, YYYY hh:mm A"
                ).fromNow()}
              </div>
            </Link>
          </DropdownItem>
        ));
      }
      return this.noNotification();
    }
    return this.noNotification();
  };

  render() {
    const { roles, permission } = this.props;
    return (
      <Dropdown
        className="d-inline-block"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <RouteAllowed
          roles={roles && roles}
          permission={permission.ticket.notification}
        >
          <DropdownToggle caret className="header-dropdown">
            <div className="">
              <i
                className="fas fa-bell mr-2"
                onClick={() => this.getRecentTickets()}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              >
                {this.props.ticketCount !== 0 ? (
                  <sup>
                    {" "}
                    <Badge
                      style={{
                        background: "#dd4a44",
                        color: "white",
                        padding: "3px"
                      }}
                    >
                      {this.props.ticketCount}
                    </Badge>
                  </sup>
                ) : null}
              </i>
            </div>
          </DropdownToggle>
        </RouteAllowed>

        <DropdownMenu className="ticket-notification">
          <DropdownItem className="ticket-notification-header">
            <span style={{ textDecoration: "none", color: " #382e29" }}>
              Booking Requests
            </span>
          </DropdownItem>
          <InfiniteScroll
            dataLength={this.props.ticketList.length} // This is important field to render the next data
            next={this.handleNext}
            hasMore={this.state.refetch}
            height={500}
            // loader={
            //   <div className="d-block text-center">
            //     <span>...Loading</span>
            //   </div>
            // }
            style={{ overFlow: "visible" }}
          >
            {this.handleItems()}
          </InfiniteScroll>
          <DropdownItem className="ticket-notification--footer">
            <Link to="/tickets" style={{ textDecoration: "none" }}>
              <span role="button">See All</span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
function mapStateToProps(state) {
  const { list, status, loading, hasError, meta } = state.ticketsNotification;
  const { count } = state.ticketWebsocketReducer;
  return {
    ticketList: list !== undefined ? list : [],
    loading,
    hasError,
    meta,
    status,
    ticketCount: count
  };
}

export default connect(
  mapStateToProps,
  { Get }
)(TicketNotification);
