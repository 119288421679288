/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

class SelectLayoutModal extends Component {
  changeCampaignTemplate = type => {
    this.props.change("selectedLayout", type);
  };

  render() {
    const { layout } = this.props;
    return (
      <Modal
        isOpen={this.props.isLayoutModalOpen}
        className="selectCampaignLayout"
      >
        <ModalHeader toggle={() => this.props.toggleSelectLayoutModal()}>
          Select Layout
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className=" col-6 col-md-4 ">
              <div
                role="presentation"
                onClick={() => this.changeCampaignTemplate("landscape")}
                className={`selectCampaignLayout__layoutWrapper ${layout ===
                  "landscape" && "activeLayout"}`}
              >
                <div className="defaultScreen" />
              </div>
              <span>Landscape</span>
            </div>
            <div className=" col-6 col-md-4 ">
              <div
                role="presentation"
                onClick={() => this.changeCampaignTemplate("landscapelayout1")}
                className={`selectCampaignLayout__layoutWrapper ${layout ===
                  "landscapelayout1" && "activeLayout"}`}
              >
                <div className="d-flex landScapeScreen">
                  <div className=" ml-auto landScapeScreen1" />
                  <div className="mr-auto">
                    <div className="landScapeScreen2" />
                    <div className="landScapeScreen3" />
                  </div>
                </div>
              </div>

              <span>Landscape 1</span>
            </div>
            <div className=" col-6 col-md-4 ">
              <div
                role="presentation"
                onClick={() => this.changeCampaignTemplate("landscapelayout2")}
                className={`selectCampaignLayout__layoutWrapper ${layout ===
                  "landscapelayout2" && "activeLayout"}`}
              >
                <div className="d-flex landScapeScreen">
                  <div className="ml-auto">
                    <div className=" landScapeScreenl1" />
                    <div className="landScapeScreenl2" />
                  </div>
                  <div className="mr-auto landScapeScreenl3" />
                </div>
              </div>{" "}
              <span>Landscape 2</span>
            </div>
            <div className=" col-6 col-md-4 ">
              <div
                role="presentation"
                onClick={() => this.changeCampaignTemplate("portrait")}
                className={`selectCampaignLayout__layoutWrapper ${layout ===
                  "portrait" && "activeLayout"}`}
              >
                <div className="portraitScreen portraitScreen--single" />
              </div>
              <span>Portrait </span>
            </div>
            <div className=" col-6 col-md-4 ">
              <div
                role="presentation"
                onClick={() => this.changeCampaignTemplate("portrait2Screen")}
                className={`selectCampaignLayout__layoutWrapper ${layout ===
                  "portrait2Screen" && "activeLayout"}`}
              >
                <div className="portraitScreen" />
                <div className="portraitScreen portraitScreen--mod" />
              </div>
              <span>Portrait 1</span>
            </div>
            <div className=" col-6 col-md-4">
              <div
                role="presentation"
                onClick={() => this.changeCampaignTemplate("portrait3Screen")}
                className={`selectCampaignLayout__layoutWrapper ${layout ===
                  "portrait3Screen" && "activeLayout"}`}
              >
                <div className="portraitScreen" />
                <div className="portraitScreen portraitScreen--mod-two" />
                <div className="portraitScreen portraitScreen--mod-three" />
              </div>
              <span>Portrait 2</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn-outline-primary"
            onClick={() => this.props.toggleSelectLayoutModal()}
          >
            Cancel
          </button>
          {this.props.customCreate}
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => {})(
  reduxForm({
    form: "CampaignLayout"
  })(SelectLayoutModal)
);
