export const convertOrientation = orientation => {
  const lowerCase = orientation.toLowerCase();
  if (
    lowerCase === "portrait_left" ||
    lowerCase === "portrait_right" ||
    lowerCase === "portrait"
  ) {
    return "Portrait";
  }
  if (
    lowerCase === "landscape_normal" ||
    lowerCase === "landscape_inverted" ||
    lowerCase === "landscape"
  ) {
    return "Landscape";
  }
};
