/* eslint-disable no-shadow */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { StyledIframe } from "../../../../core/layout/styledIframe";
import { updateCampaign } from "../../redux";

function handleFieldValueChange(e, data, node, campaign, fn) {
  const {
    contentLocalID,
    screen: { localID }
  } = data;
  const {
    target: { value }
  } = e;
  campaign.updateWidgetProperty(localID, contentLocalID, node, value);
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "url",
    defaultState.url
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "scaleFactor",
    defaultState.scaleFactor
  );
  updateCampaign(campaign);
  toggle(false);
}

function handleContentDurationChange(
  screenID,
  contentID,
  minute,
  second,
  campaign,
  updateCampaign
) {
  const duration = { minute, second };
  const newCampaign = campaign;
  newCampaign.changeDurationOfContent(contentID, screenID, duration);
  updateCampaign(newCampaign);
  return 0;
}

const URLWidget = props => (
  <Modal isOpen={props.open} backdrop className="widgetScreenModal">
    <ModalHeader toggle={() => cancelChanges(props)}>{props.type}</ModalHeader>
    <ModalBody>
      <div
        className="url-widget-background "
        style={{
          height: props.data.screen.height,
          width: props.data.screen.width,
          background: props.data.screen.fill
        }}
      >
        <StyledIframe
          id={`${props.URLData.URLWidgetID}-player`}
          title="URL-WIDGET_2019"
          dynamicUrl={props.URLData.url}
          height={
            props.data.screen.height *
            (props.data.screen.horizontalScale / props.URLData.scaleFactor)
          }
          width={
            props.data.screen.width *
            (props.data.screen.horizontalScale / props.URLData.scaleFactor)
          }
          transformScale={
            props.URLData.scaleFactor / props.data.screen.horizontalScale
          }
          expandFactor={
            props.data.screen.horizontalScale / props.URLData.scaleFactor
          }
        />
      </div>
      <Form>
        <FormGroup>
          <div className="globalInput">
            <Label for="examplePassword">URL</Label>
            <Input
              type="text"
              value={props.URLData.url}
              name="url"
              onChange={e =>
                handleFieldValueChange(
                  e,
                  props.data,
                  "url",
                  props.campaign,
                  props.updateCampaign
                )
              }
              onKeyPress={e => {
                if (e.charCode === 13) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              placeholder="Enter website url."
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <div className="col-md-4 mt-0 globalInput">
            <Label for="examplePassword">Scale Factor</Label>
            <Input
              type="number"
              value={props.URLData.scaleFactor}
              name="url"
              onChange={e =>
                handleFieldValueChange(
                  e,
                  props.data,
                  "scaleFactor",
                  props.campaign,
                  props.updateCampaign
                )
              }
              onKeyPress={e => {
                if (e.charCode === 13) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              placeholder="Enter Scale"
              maxlength={3}
              min={0.1}
              max={5.0}
              step={0.1}
            />
          </div>
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button
        className="btn-outline-primary"
        onClick={() => cancelChanges(props)}
      >
        Cancel
      </Button>
      <Button
        className="mt-0 primaryButton"
        onClick={() => props.toggle(false)}
      >
        Done
      </Button>
    </ModalFooter>
  </Modal>
);

export default connect(
  (state, props) => {
    const { campaign } = state.newCampaign;
    const {
      contentLocalID,
      screen: { localID }
    } = props.data;
    const URLData = campaign.getContent(localID, contentLocalID);
    return {
      campaign,
      URLData: URLData || []
    };
  },
  { updateCampaign }
)(URLWidget);
