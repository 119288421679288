import React from "react";
import { Label, Button } from "reactstrap";
import { reduxForm, Field } from "redux-form";
import { RangeSlider } from "../../../core";

const VolumeControlFrom = props => (
  <div className="row mt-3">
    <div className="col-md-2 informationBlock">
      <Label for="exampleSelect" className="mt-2">
        Volume
      </Label>
    </div>
    <div className="col-md-10">
      <div className="rangeslider-wrapper float-left">
        <Field
          component={RangeSlider}
          orientation="horizontal"
          name="volume"
          min={0}
          max={100}
          value={3}
        />
      </div>
      <Button
        className="btn-info ml-2"
        disabled={props.submitting || props.pristine}
        onClick={props.handleSubmit(props.handleVolumeSave)}
      >
        {props.submitting ? "Saving" : "Save"}
      </Button>
    </div>
  </div>
);

export default reduxForm({
  form: "volumeControlForm"
})(VolumeControlFrom);
