import React from "react";
import SingleContentUploadForm from "./singleContentUploadForm";
const GenerateForms = ({
    data,
    handleRemove,
    ContentTag,
    filteredTags,
    handleInputChange,
    ...rest
  }) =>
    data.map(item => (
      <SingleContentUploadForm
        handleRemove={handleRemove}
        item={item}
        id={item.id}
        key={item.id}
        data={data}
        ContentTag={ContentTag}
        filteredTags={filteredTags}
        handleInputChange={handleInputChange}
        {...rest}
      />
    ));
  
  export default GenerateForms;