import {
  Resources,
  userDetails,
  Logout,
  generateUserLogin,
  generateUserUpdate,
  generateForgotPassword,
  generateResetPassword,
  generateResendConfirmation,
  userList,
  gurzuUserList,
  userDomainDetails,
  userProfileImage
} from "./loginRedux";
import { Resource as statResource, Home } from "./homeRedux";
import {
  domainList,
  domainDetails,
  reloadHeader,
  reloadHeaderDispatcher,
  domainDevices,
  domainUsers,
  domainParties,
  partyBrands
} from "./domainRedux";
import { Resource as activityResource, activityLog } from "./activityLogRedux";

const usersReducers = {
  userDetails,
  Home,
  userList,
  gurzuUserList,
  domainList,
  reloadHeader,
  domainDetails,
  userDomainDetails,
  userProfileImage,
  domainDevices,
  domainUsers,
  domainParties,
  partyBrands,
  activityLog
};

export {
  Resources,
  usersReducers,
  Logout,
  generateUserLogin,
  generateUserUpdate,
  generateForgotPassword,
  generateResetPassword,
  generateResendConfirmation,
  reloadHeaderDispatcher,
  statResource,
  activityResource
};
