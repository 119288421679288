import React from "react";
import {
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Tooltip as DefaultTooltip
} from "reactstrap";
import {
  Field,
  reduxForm,
  formValueSelector,
  initialize as remoteInitialize
} from "redux-form";
import { connect } from "react-redux";
import Drawer from "rc-drawer";
import "rc-drawer/dist/rc-drawer.css";
import { has } from "lodash";
// import "./css.css";
// import "antd/dist/antd.css";
import { Tooltip, RangeSlider } from "../../../core";

import { MultipleSelect } from "../../../core/form/searchSelect";
import AddToGroup from "./addToGroup";
import { resolutions } from "../../../constant/resolution";
import { timeZones } from "../../../constant/timeZoneNUCs";

// eslint-disable-next-line react/prefer-stateless-function
class DeviceControlModal extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      restartDev: {
        active: false
      },
      stopAndReboot: { active: false },
      restartApp: {
        active: false
      },
      changeEnvironment: { active: false },
      updateSoftware: {
        active: false
      }
    };
    this.state = {
      volume: 100,
      modal: false,
      stopAndReboot: false,
      restartApp: false,
      changeEnvironment: false,
      updateSoftware: false,
      buttonState: this.initialState,
      isLegacy: false,
      tooltipOpen: false,
      proofOfPlay: false
    };
  }

  toggle = () => {
    this.setState(state => ({
      modal: !state.modal,
      stopAndReboot: false
    }));
  };

  toggleButtons = (name, flag) => {
    this.setState(state => ({
      buttonState: {
        ...this.initialState,
        [name]: {
          active: flag
        }
      }
    }));
  };

  toggleTooltip = () => this.setState({ tooltipOpen: !this.state.tooltipOpen });

  toggleRestartApp = () => {
    this.setState(state => ({ restartApp: !state.restartApp }));
  };

  toggleChangeEnvironment = () => {
    this.setState(state => ({ changeEnvironment: !state.changeEnvironment }));
  };

  toggleUpdateSoftware = () => {
    this.setState(state => ({ updateSoftware: !state.updateSoftware }));
  };

  handleStopAndReboot = () => {
    this.setState(state => ({ stopAndReboot: !state.stopAndReboot }));
  };

  handleSendUpdate = tag => {
    this.props.toggledeviceControlModal();

    // Change here for removing tag
    this.toggleButtons("updateSoftware", false);
    // this.toggleUpdateSoftware();
    this.state.isLegacy
      ? this.props.legacyUpdateGroupDevices(
          this.props.selectedGroup,
          this.props.selectedDevices,
          tag
        )
      : this.props.updateGroupDevices(
          this.props.selectedGroup,
          this.props.selectedDevices,
          tag
        );
  };

  // Change here for removing tag
  handleEnvChange = env => {
    this.props.toggledeviceControlModal();
    this.toggleChangeEnvironment();
    this.toggleButtons("changeEnvironment", false);
    this.props.sendGroupElectronAppEnvironment(
      this.props.selectedGroup,
      this.props.selectedDevices,
      env
    );
  };

  restartDevices = async () => {
    this.props.toggledeviceControlModal();
    this.toggleButtons("restartDev", false);
    if (this.state.stopAndReboot) {
      await this.props.stopGroupDevices(
        this.props.selectedGroup,
        this.props.selectedDevices,
        "advance"
      );
      await this.props.rebootGroupDevices(
        this.props.selectedGroup,
        this.props.selectedDevices
      );
      this.setState({
        stopAndReboot: false
      });
    } else {
      await this.props.rebootGroupDevices(
        this.props.selectedGroup,
        this.props.selectedDevices
      );
    }
  };

  restartApp = async () => {
    this.props.toggledeviceControlModal();
    this.toggleButtons("restartApp", false);
    this.props.restartGroupDevices(
      this.props.selectedGroup,
      this.props.selectedDevices
    );
  };

  handleProofOfPlay = flag => {
    this.props.remoteInitialize("DeviceGroupForm", {
      proofOfPlay: flag
    });
    this.props.handleProofOfPlay(this.props.selectedDevices, flag);
  };

  render() {
    const { selectedTags, selectedEnv } = this.props;
    const option = this.state.isLegacy
      ? this.props.legacyTagOptions
      : this.props.tagOptions;
    // debugger;

    return (
      <div>
        {this.props.children}
        <Drawer
          className="network-drawer"
          open={
            this.props.deviceControlModal === this.props.selectedGroup ||
            this.props.deviceControlModal
          }
          // onClose={() => this.props.toggledeviceControlModal("cancel")}
          // onmaskClick renamed to onClose in rc-drawer v2
          onMaskClick={() => this.props.toggledeviceControlModal("cancel")}
          handler={false}
          level={null}
          placement="right"
          // width="30%"
        >
          <div className="campaignDetail">
            <span className="campaignDetail__information campaignDetail__information--border">
              Device Control{" "}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.toggledeviceControlModal("cancel")}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </span>
            <div className="mt-3">
              <Button
                type="button"
                className="btn-info mb-3"
                disabled={this.props.isRestrating}
                onClick={() => this.toggleButtons("restartApp", true)}
              >
                Restart App
              </Button>

              {this.state.buttonState.restartApp.active && (
                <div className="confirmationMessage">
                  <span>Are you sure you want to restart the App?</span>
                  <div
                    className="mt-2"
                    style={{ display: "block", float: "right" }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => this.toggleButtons("restartApp", false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="primaryButton"
                      type="submit"
                      onClick={() => {
                        this.restartApp();
                      }}
                    >
                      Restart
                    </button>
                  </div>
                </div>
              )}
              <Button
                className="btn-info mb-3"
                onClick={() =>
                  this.props.resyncGroupDevices(
                    this.props.selectedGroup,
                    this.props.selectedDevices
                  )
                }
              >
                Sync Devices
              </Button>
              <Button
                color="danger"
                className="mb-3"
                disabled={this.props.isRebooting}
                onClick={() => this.toggleButtons("restartDev", true)}
                id={`Popover${this.props.selectedGroup}`}
              >
                Reboot Device
              </Button>
              {this.state.buttonState.restartDev.active && (
                <div className="confirmationMessage">
                  <div>Are you sure you want to Reboot device?</div>
                  {/* <FormGroup check inline> */}
                  <Label className="mt-2 checkBoxWrapper mr-4">
                    <input
                      type="checkbox"
                      checked={this.state.stopAndReboot}
                      onChange={this.handleStopAndReboot}
                    />
                    <span className="checkMark-content">
                      {" "}
                      Stop Playing Content.
                    </span>
                    <span className="checkmark" />
                  </Label>
                  <div
                    className="mt-2"
                    style={{ display: "block", float: "right" }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => this.toggleButtons("restartDev", false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="primaryButton"
                      type="submit"
                      onClick={() => {
                        this.restartDevices();
                      }}
                    >
                      Restart
                    </button>
                  </div>
                </div>
              )}
            </div>
            {this.props.roles.includes("SuperAdmin") && (
              <div className="mt-3 mb-3">
                <Field component="input" name="proofOfPlay" hidden />

                <h5 className="mb-2">Proof of Play (Reporting)</h5>
                <div
                  className={`subInput ${this.props.isSendingPopRequest &&
                    "subInput--disabled"}`}
                >
                  <div className="subscriptionContainer">
                    <input
                      type="radio"
                      id="on"
                      name="proofOfPlay"
                      value="on"
                      disabled={this.props.isSendingPopRequest}
                    />

                    <Label
                      for="on"
                      className={`${this.props.proofOfPlay &&
                        "activePlan"} ${this.props.proofOfPlay &&
                        this.props.isSendingPopRequest &&
                        "activePlan--disabled"}`}
                      onClick={() => this.handleProofOfPlay(false)}
                    >
                      On
                    </Label>
                  </div>
                  <div className="subscriptionContainer">
                    <input
                      type="radio"
                      id="off"
                      name="proofOfPlay"
                      value="off"
                      disabled={this.props.isSendingPopRequest}
                    />

                    <Label
                      for="off"
                      className={`${!this.props.proofOfPlay &&
                        "activePlan"} ${!this.props.proofOfPlay &&
                        this.props.isSendingPopRequest &&
                        "activePlan--disabled"}`}
                      onClick={() => this.handleProofOfPlay(true)}
                    >
                      Off
                    </Label>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-3 mb-3">
              <h5 className="mb-2">Volume</h5>
              <div className="d-flex">
                <Field component="input" name="volume" hidden />
                <div className="rangeslider-wrapper">
                  <RangeSlider
                    orientation="horizontal"
                    name="volume"
                    min={0}
                    max={100}
                    value={this.props.volume}
                    input={{
                      onChange: value => {
                        this.props.remoteInitialize("DeviceGroupForm", {
                          volume: value
                        });
                      }
                    }}
                  />
                </div>
                <Button
                  type="button"
                  className="btn-info mr-0 ml-auto"
                  onClick={() => {
                    this.props.handleGroupVolume(
                      this.props.selectedGroup,
                      this.props.selectedDevices,
                      this.props.volume
                    );
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
            <div className="mt-3 mb-3">
              <h5 className="mb-3">Time Zone </h5>
              <div className="deviceInformationTag d-flex">
                <Field
                  component={MultipleSelect}
                  name="timezone"
                  className="mr-0 mb-0"
                  options={timeZones}
                  clearable
                  isDisabled={this.props.isSendingTimeZone}
                  placeholder="Select Time Zone"
                  selectWrapperClass="w-100"
                />
                <Button
                  className="btn-info mr-0 ml-auto"
                  color="info"
                  disabled={this.props.isSendingTimeZone}
                  onClick={this.props.handleSubmit(value =>
                    this.props.sendGrouptimeZone(
                      this.props.selectedGroup,
                      this.props.selectedDevices,
                      value.timezone.value
                    )
                  )}
                >
                  Send
                </Button>
              </div>
            </div>
            <div className="mt-3 mb-3">
              <h5 className="mb-3">Resolution</h5>
              <div className="deviceInformationTag d-flex">
                <Field
                  component={MultipleSelect}
                  name="resolution"
                  className="mr-0 mb-0"
                  options={resolutions}
                  clearable
                  isDisabled={this.props.isSendingRes}
                  placeholder="Select Resolution "
                  selectWrapperClass="w-100"
                />
                <Button
                  className="btn-info mr-0 ml-auto"
                  color="info"
                  disabled={this.props.isSendingRes}
                  onClick={this.props.handleSubmit(value =>
                    this.props.sendGroupResolution(
                      this.props.selectedGroup,
                      this.props.selectedDevices,
                      value.resolution.value
                    )
                  )}
                >
                  Send
                </Button>
              </div>
            </div>
            <div className="mt-3 mb-3">
              <h5 className="mb-3">Orientation</h5>
              <div className="d-flex">
                <Field name="orientation" hidden component="input" />
                <div className="orientationWrapper">
                  <div className="equalHWrap eqWrap">
                    <div className="equalHW eq ">
                      <Tooltip
                        tooltipContent="Normal Screen"
                        id="drawer-lright"
                        item={
                          <button
                            type="button"
                            className={`network-orientation-button network-orientation-button--sideBorder ${
                              this.props.orientation === "landscape_normal" ||
                              this.props.orientation === "normal"
                                ? "active"
                                : ""
                            } `}
                            onClick={() =>
                              this.props.change("orientation", "normal")
                            }
                          >
                            Normal
                          </button>
                        }
                      />
                    </div>{" "}
                    <div className="equalHW eq ">
                      <Tooltip
                        tooltipContent="Inverted"
                        id="drawer-lleft"
                        item={
                          <button
                            type="button"
                            className={`network-orientation-button network-orientation-button--sideBorder ${
                              this.props.orientation === "landscape_inverted" ||
                              this.props.orientation === "inverted"
                                ? "active"
                                : ""
                            } `}
                            onClick={() =>
                              this.props.change("orientation", "inverted")
                            }
                          >
                            Inverted
                          </button>
                        }
                      />
                    </div>{" "}
                    <div className="equalHW eq ">
                      <Tooltip
                        tooltipContent="Turn Left"
                        id="drawer-pleft"
                        item={
                          <button
                            type="button"
                            className={`network-orientation-button network-orientation-button--sideBorder ${
                              this.props.orientation === "portrait_left" ||
                              this.props.orientation === "left"
                                ? "active"
                                : ""
                            } `}
                            onClick={() =>
                              this.props.change("orientation", "left")
                            }
                          >
                            Left
                          </button>
                        }
                      />
                    </div>{" "}
                    <div className="equalHW eq ">
                      <Tooltip
                        tooltipContent="Turn Right"
                        id="drawer-pright"
                        item={
                          <button
                            type="button"
                            className={`network-orientation-button ${
                              this.props.orientation === "portrait_right" ||
                              this.props.orientation === "right"
                                ? "active"
                                : ""
                            } `}
                            onClick={() =>
                              this.props.change("orientation", "right")
                            }
                          >
                            Right
                          </button>
                        }
                      />
                    </div>
                  </div>
                </div>
                <Button
                  className="btn-info mr-0 ml-auto"
                  disabled={this.props.isSendingOrientation}
                  onClick={this.props.handleSubmit(values =>
                    this.props.sendGroupOrientation(
                      this.props.selectedGroup,
                      this.props.selectedDevices,
                      values.orientation
                    )
                  )}
                >
                  {this.props.submitting ? "Saving" : "Save"}
                </Button>
              </div>
            </div>
            {/* Environment */}
            <div className="mt-3 mb-3">
              <h5 className="mb-3">Environment</h5>
              <div className="deviceInformationTag d-flex">
                <Field
                  component={MultipleSelect}
                  name="electronEnv"
                  className="mr-0 mb-0"
                  options={this.props.envOptions.map(i => ({
                    value: i.toLowerCase(),
                    label: i
                  }))}
                  clearable
                  isDisabled={this.props.isSendingEnv}
                  placeholder="Select Environment"
                  selectWrapperClass="w-100"
                />
                <Button
                  className="btn-info mr-0 ml-auto"
                  color="info"
                  disabled={
                    this.props.isSendingEnv ||
                    selectedEnv === undefined ||
                    selectedEnv.length <= 0
                  }
                  onClick={() => this.toggleButtons("changeEnvironment", true)}
                >
                  Send
                </Button>
              </div>
            </div>
            {this.state.buttonState.changeEnvironment.active && (
              <div className="confirmationMessage">
                <span>{`Are you sure you want to send ${this.props.selectedEnv.value} environment?`}</span>
                <div
                  className="mt-2"
                  style={{ display: "block", float: "right" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() =>
                      this.toggleButtons("changeEnvironment", false)
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="primaryButton"
                    type="submit"
                    onClick={this.props.handleSubmit(value =>
                      this.handleEnvChange(value.electronEnv.value)
                    )}
                  >
                    Send
                  </button>
                </div>
              </div>
            )}{" "}
            <div className="toggle-version mt-3 mb-3">
              {/* Electron Tag select */}
              <h5 className="mb-3">Version</h5>
              {/* <label className="ml-2 toggle-switch switch">
                  <input
                    type="checkbox"
                    name="status"
                    checked={this.state.isLegacy}
                    onChange={() => {
                      this.props.remoteInitialize("DeviceGroupForm", {
                        electronTag: []
                      });
                      this.setState({ isLegacy: !this.state.isLegacy });
                    }}
                  />
                  <span id="version-toggle" className="slider round" />
                  <DefaultTooltip
                    style={{ zIndex: "9999" }}
                    className="custom-tooltip-position"
                    // placement="top"
                    isOpen={this.state.tooltipOpen}
                    target="version-toggle"
                    toggle={this.toggleTooltip}
                  >
                    Toggle Legacy Update Mechanism
                  </DefaultTooltip>
                </label>
              </h5>
              {/* <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitches"
                  readOnly
                />
                <label
                  className="custom-control-label"
                  htmlFor="customSwitches"
                ></label>
              // </div> */}
              <div className="deviceInformationTag d-flex">
                <Field
                  component={MultipleSelect}
                  name="electronTag"
                  className="mr-0 mb-0"
                  options={option.map(i => ({
                    value: i,
                    label: i
                  }))}
                  clearable
                  isDisabled={
                    this.props.isUpdating &&
                    this.props.updatingGroup === this.props.selectedGroup
                  }
                  placeholder="Select Tag"
                  selectWrapperClass="w-100"
                />
                <Button
                  className="btn-info mr-0 ml-auto"
                  color="info"
                  disabled={
                    (this.props.isUpdating &&
                      this.props.updatingGroup === this.props.selectedGroup) ||
                    selectedTags === undefined ||
                    selectedTags.length <= 0
                  }
                  onClick={() => this.toggleButtons("updateSoftware", true)}
                >
                  Update
                </Button>
              </div>
            </div>
            {this.state.buttonState.updateSoftware.active && (
              <div className="confirmationMessage">
                <span>{`Are you sure you want to send ${this.props.selectedTags.value} version?`}</span>
                <div
                  className="mt-2"
                  style={{ display: "block", float: "right" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => this.toggleButtons("updateSoftware", false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="primaryButton"
                    type="submit"
                    onClick={this.props.handleSubmit(value =>
                      this.handleSendUpdate(value.electronTag.value)
                    )}
                  >
                    Update
                  </button>
                </div>
              </div>
            )}{" "}
            {this.state.isLegacy && (
              <div className="alert alert-warning fade mr-2 show mt-3">
                <h5 className="alert-heading">
                  <i className="fas fa-exclamation-triangle" /> Using Legacy
                  Update Mechanism
                </h5>
              </div>
            )}
            <span className="mt-4 campaignDetail__information campaignDetail__information--border">
              Group Control
            </span>
            <AddToGroup
              updateGroupDeviceAfterGroupChange={
                this.props.updateGroupDeviceAfterGroupChange
              }
              devices={this.props.devices}
              selectedDevices={this.props.selectedDevices}
              toggledeviceControlModal={this.props.toggledeviceControlModal}
              Put={this.props.Put}
              Get={this.props.Get}
              selectedGroup={this.props.selectedGroup}
              handleRefetch={this.props.handleRefetch}
              handleDeviceGroupState={this.props.handleDeviceGroupState}
              handleAddAndChangeGroup={this.props.handleAddAndChangeGroup}
            />
            <div>
              <span className="mt-4 campaignDetail__information campaignDetail__information--border">
                Monitor Control
              </span>
              <h5 className="mb-3">Power</h5>
              <div className="btn-group mb-3 ">
                <button
                  type="button"
                  onClick={() =>
                    this.props.triggerRS232Command(
                      this.props.selectedGroup,
                      "power",
                      this.props.selectedDevices
                    )
                  }
                  className="btn-outline-secondary buttonGroupOutline"
                >
                  <i className="fa fa-power-off" />
                </button>
              </div>
              <h5 className="mb-3">Volume</h5>
              <div className="btn-group mb-3 " tooltipContent="Stop playing.">
                <Tooltip
                  tooltipContent="Set volume to 25%"
                  id="Volume25"
                  customClass="custom-tooltip-position"
                  item={
                    <button
                      type="button"
                      onClick={() =>
                        this.props.triggerRS232Command(
                          this.props.selectedGroup,
                          "VOL25",
                          this.props.selectedDevices
                        )
                      }
                      className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
                    >
                      25%
                    </button>
                  }
                />
                <Tooltip
                  tooltipContent="Set volume to 50%"
                  id="Volume50"
                  customClass="custom-tooltip-position"
                  item={
                    <button
                      type="button"
                      onClick={() =>
                        this.props.triggerRS232Command(
                          this.props.selectedGroup,
                          "VOL50",
                          this.props.selectedDevices
                        )
                      }
                      className="btn-outline-secondary buttonGroupOutline"
                    >
                      50%
                    </button>
                  }
                />
                <Tooltip
                  tooltipContent="Set volume to 75%"
                  id="Volume75"
                  customClass="custom-tooltip-position"
                  item={
                    <button
                      type="button"
                      onClick={() =>
                        this.props.triggerRS232Command(
                          this.props.selectedGroup,
                          "VOL75",
                          this.props.selectedDevices
                        )
                      }
                      className="btn-outline-secondary buttonGroupOutline"
                    >
                      75%
                    </button>
                  }
                />
                <Tooltip
                  tooltipContent="Set Full Volume"
                  id="Volume100"
                  customClass="custom-tooltip-position"
                  item={
                    <button
                      type="button"
                      onClick={() =>
                        this.props.triggerRS232Command(
                          this.props.selectedGroup,
                          "VOL100",
                          this.props.selectedDevices
                        )
                      }
                      className="btn-outline-secondary btn-outline-secondary--rightSideBorder buttonGroupOutline"
                    >
                      100%
                    </button>
                  }
                />
              </div>
            </div>
            <div className="btn-group mb-3">
              <button
                type="button"
                onClick={() =>
                  this.props.triggerRS232Command(
                    this.props.selectedGroup,
                    "VOLDOWN",
                    this.props.selectedDevices
                  )
                }
                className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
              >
                Vol down
              </button>
              <button
                type="button"
                onClick={() =>
                  this.props.triggerRS232Command(
                    this.props.selectedGroup,
                    "MUTE",
                    this.props.selectedDevices
                  )
                }
                className="btn-outline-secondary buttonGroupOutline"
              >
                Mute{" "}
              </button>
              <button
                type="button"
                onClick={() =>
                  this.props.triggerRS232Command(
                    this.props.selectedGroup,
                    "VOLUP",
                    this.props.selectedDevices
                  )
                }
                className="btn-outline-secondary btn-outline-secondary--rightSideBorder buttonGroupOutline "
              >
                Vol up
              </button>
            </div>
            <h5 className="mb-3">HDMI</h5>
            <div className="btn-group mb-3">
              <Tooltip
                tooltipContent="Set HDMI 1"
                id="HDMI1"
                customClass="custom-tooltip-position"
                item={
                  <button
                    type="button"
                    onClick={() =>
                      this.props.triggerRS232Command(
                        this.props.selectedGroup,
                        "HDMI1",
                        this.props.selectedDevices
                      )
                    }
                    className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
                  >
                    HDMI 1
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Set HDMI 2"
                id="HDMI2"
                customClass="custom-tooltip-position"
                item={
                  <button
                    type="button"
                    onClick={() =>
                      this.props.triggerRS232Command(
                        this.props.selectedGroup,
                        "HDMI2",
                        this.props.selectedDevices
                      )
                    }
                    className="btn-outline-secondary buttonGroupOutline"
                  >
                    HDMI 2
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Set HDMI 3"
                customClass="custom-tooltip-position"
                id="HDMI3"
                item={
                  <button
                    type="button"
                    onClick={() =>
                      this.props.triggerRS232Command(
                        this.props.selectedGroup,
                        "HDMI3",
                        this.props.selectedDevices
                      )
                    }
                    className="btn-outline-secondary btn-outline-secondary--rightSideBorder buttonGroupOutline"
                  >
                    HDMI 3
                  </button>
                }
              />
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default connect(
  state => {
    const { tags } = state.electronTags.list || [];
    const { tags: legacyTags } = state.legacyElectronTags.list || [];
    const selector = formValueSelector("DeviceGroupForm");

    return {
      envOptions: ["Production", "Staging", "Development"],
      tagOptions: tags || [],
      legacyTagOptions: legacyTags || [],
      // electronTags: tags || [],
      volume: selector(state, "volume") || 100,
      proofOfPlay: selector(state, "proofOfPlay"),
      selectedEnv: selector(state, "electronEnv"),
      selectedTags: selector(state, "electronTag"),
      orientation: selector(state, "orientation")
    };
  },
  { remoteInitialize }
)(
  reduxForm({
    form: "DeviceGroupForm"
  })(DeviceControlModal)
);
