/* eslint-disable no-shadow */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { updateCampaign } from "../../redux";

function getCleanURL(url) {
  return url.split("?")[0];
}

function appendDurationAndUrl(url, data, node, campaign, fn, customDuration) {
  const {
    contentLocalID,
    screen: { localID }
  } = data;
  let newUrl = `${getCleanURL(url)}`;
  if (Number(customDuration)) {
    newUrl =
      newUrl !== "" ? `${getCleanURL(url)}?duration=${customDuration}` : "";
  }
  campaign.updateWidgetProperty(localID, contentLocalID, node, newUrl);
  fn(campaign);
}

function getSeconds(minutes = 0, seconds = 0) {
  const totalDuration = Number(seconds) + Number(minutes) * 60;
  return totalDuration;
}

function handleFieldValueChange(
  e,
  data,
  node,
  campaign,
  fn,
  useCustomDuration,
  contentDurationMinutes,
  contentDurationSeconds
) {
  const {
    contentLocalID,
    screen: { localID }
  } = data;
  const {
    target: { value }
  } = e;
  if (useCustomDuration) {
    const durn = getSeconds(
      contentDurationMinutes || 0,
      contentDurationSeconds || 0
    );
    appendDurationAndUrl(value, data, "url", campaign, updateCampaign, durn);
  } else {
    campaign.updateWidgetProperty(localID, contentLocalID, node, value);
  }
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  // debugger;
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "url",
    defaultState.url
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "customContentDuration",
    defaultState.customContentDuration
  );

  updateCampaign(campaign);
  toggle(false);
}

function handleDurationChange(seconds, data, node, campaign, fn) {
  const {
    contentLocalID,
    screen: { localID }
  } = data;
  campaign.updateWidgetProperty(localID, contentLocalID, node, seconds);
  fn(campaign);
}

function handleCustomDurationCheckBox(
  url,
  data,
  node,
  campaign,
  fn,
  checked,
  customDuration
) {
  const {
    contentLocalID,
    screen: { localID }
  } = data;
  const newUrl = getCleanURL(url);
  if (!checked || !customDuration) {
    campaign.updateWidgetProperty(
      localID,
      contentLocalID,
      "customContentDuration",
      null
    );
    campaign.updateWidgetProperty(localID, contentLocalID, node, newUrl);
    fn(campaign);
  }
}

const PicToScreenWidget = props => {
  const [useCustomDuration, setUseCustomDuration] = useState(
    !!props.customContentDuration
  );
  const [contentDurationMinutes, setContentDurationMinute] = useState(
    !!props.customContentDuration &&
      Math.floor(Number(props.customContentDuration) / 60)
  );
  const [contentDurationSeconds, setContentDurationSeconds] = useState(
    !!props.customContentDuration &&
      Math.floor(Number(props.customContentDuration) % 60)
  );

  return (
    <Modal
      isOpen={props.open}
      toggle={() => props.toggle(false)}
      backdrop
      className="widgetScreenModal picToScreenModal"
    >
      <ModalHeader toggle={() => cancelChanges(props)}>
        {props.type}
      </ModalHeader>
      <ModalBody>
        <div
          className="url-widget-background d-flex justify-content-center"
          style={{
            height: props.data.screen.height,
            width: props.data.screen.width,
            background: props.data.screen.fill
          }}
        >
          <iframe
            title="Pick_to_screen"
            style={{
              overflow: "hidden",
              overflowX: "hidden",
              border: "none"
            }}
            src={props.URLData.url}
            height={props.data.screen.height}
            width={props.data.screen.width}
          />
        </div>
        <Form>
          <FormGroup>
            <div className="globalInput">
              <Label for="examplePassword">URL</Label>
              <Input
                type="text"
                value={props.URLData.url}
                name="url"
                onChange={e =>
                  handleFieldValueChange(
                    e,
                    props.data,
                    "url",
                    props.campaign,
                    props.updateCampaign,
                    useCustomDuration,
                    contentDurationMinutes,
                    contentDurationSeconds
                  )
                }
                onKeyPress={e => {
                  if (e.charCode === 13) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                placeholder="Enter moderation url."
              />
            </div>
          </FormGroup>
        </Form>

        <div onClick={e => e.stopPropagation()}>
          <Label className="checkBoxWrapper">
            <Input
              type="checkbox"
              onChange={({ target: { checked } }) => {
                if (checked) {
                  // reset the form value
                  setContentDurationMinute(null);
                  setContentDurationSeconds(null);
                }
                setUseCustomDuration(checked);
                handleCustomDurationCheckBox(
                  props.URLData.url,
                  props.data,
                  "url",
                  props.campaign,
                  props.updateCampaign,
                  checked,
                  props.customContentDuration
                );
              }}
              defaultChecked={useCustomDuration}
            />
            <p className="ml-5">Custom content duration</p>
            <span className="checkmark" />
          </Label>{" "}
          <small
            style={{ fontSize: "12px", color: "rgb(112, 112, 112)" }}
            className="ml-2"
          >
            * Default duration is 15s.
          </small>
          {useCustomDuration && (
            <div>
              <div className="" style={{ display: "inline-block" }}>
                <div className="globalInput globalInput--mod">
                  <Label>Minutes</Label>
                  <Input
                    type="number"
                    onChange={({ target: { value } }) => {
                      const durn = getSeconds(
                        value || 0,
                        contentDurationSeconds || 0
                      );
                      setContentDurationMinute(value);
                      handleDurationChange(
                        durn,
                        props.data,
                        "customContentDuration",
                        props.campaign,
                        props.updateCampaign
                      );
                      appendDurationAndUrl(
                        props.URLData.url,
                        props.data,
                        "url",
                        props.campaign,
                        props.updateCampaign,
                        durn
                      );
                    }}
                    value={contentDurationMinutes}
                    max={60}
                    name="minute"
                    placeholder="Enter Minutes"
                  />
                </div>
              </div>
              <b>:</b>
              <div className="" style={{ display: "inline-block" }}>
                <div className="globalInput globalInput--mod">
                  <Label>Second</Label>
                  <Input
                    type="number"
                    onChange={({ target: { value } }) => {
                      const durn = getSeconds(
                        contentDurationMinutes || 0,
                        value || 0
                      );
                      setContentDurationSeconds(value);
                      handleDurationChange(
                        durn,
                        props.data,
                        "customContentDuration",
                        props.campaign,
                        props.updateCampaign
                      );
                      appendDurationAndUrl(
                        props.URLData.url,
                        props.data,
                        "url",
                        props.campaign,
                        props.updateCampaign,
                        durn
                      );
                    }}
                    value={contentDurationSeconds}
                    max={60}
                    name="second"
                    placeholder="Enter Second"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-primary"
          onClick={() => cancelChanges(props)}
        >
          Cancel
        </Button>
        <Button
          className="mt-0 primaryButton"
          onClick={() => props.toggle(false)}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect(
  (state, props) => {
    const { campaign } = state.newCampaign;
    const {
      contentLocalID,
      screen: { localID }
    } = props.data;
    const URLData = campaign.getContent(localID, contentLocalID);
    return {
      campaign,
      URLData: URLData || []
    };
  },
  { updateCampaign }
)(PicToScreenWidget);
