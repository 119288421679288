/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable no-shadow */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import { SketchPicker } from "react-color";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Get } from "../../../../constant/thunk";
import { MultipleSelect } from "../../../../core/form/searchSelect";
import { Tooltip } from "../../../../core";

import { updateCampaign } from "../../redux";

async function setRSSOnState(url, setter, get, updateWidgetProperty, props) {
  if (url === undefined) {
    return 0;
  }
  const {
    contentLocalID,
    screen: { localID }
  } = props.data;
  const data = {
    url: `/campaigns/fetch_rss?url=${url}`,
    asyncActions: {
      init: "getRSSData_INIT",
      success: "getRSSData_SUCCESS",
      error: "getRSSData_ERROR"
    }
  };
  const titles = await get(data);
  if (titles.data.length > 0) {
    updateWidgetProperty(
      localID,
      contentLocalID,
      "title",
      [...titles.data],
      props.campaign,
      props.updateCampaign
    );
    setter([...titles.data]);
  } else {
    updateWidgetProperty(
      localID,
      contentLocalID,
      "title",
      ["Resource url does not contain RSS feed"],
      props.campaign,
      props.updateCampaign
    );
    setter(["Resource url does not contain RSS feed"]);
  }
}

const RssRender = props =>
  props.rss.map(item => (
    <span
      className="ml-5 mr-5"
      style={{ fontFamily: `'${props.RSSData.fontFamily.value}', sans-serif` }}
    >
      {item}
    </span>
  ));

function updateWidgetProperty(screenID, contentID, node, value, campaign, fn) {
  campaign.updateWidgetProperty(screenID, contentID, node, value);
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "url",
    defaultState.url
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "delay",
    defaultState.delay
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "fontSize",
    defaultState.fontSize
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "direction",
    defaultState.direction
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "fontFamily",
    defaultState.fontFamily
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "textColor",
    defaultState.textColor
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "backgroundColor",
    defaultState.backgroundColor
  );
  updateCampaign(campaign);
  toggle(false);
}

const checkValidation = e => {
  if (e.target.value === "") {
    e.target.value = "";
    return e.target.value;
  }
};

const RSSWidget = props => {
  const [rss, setRSS] = useState(["Resource url does not contain RSS feed"]);
  const [rssurl, setURL] = useState(undefined);
  const [showTextColorSelector, setTextColorSelector] = useState(false);
  const [showTextBackgroundColorSelector, setTextBackColorSelector] = useState(
    false
  );

  useEffect(() => {
    if (rssurl === undefined || rssurl !== props.RSSData.url) {
      setRSSOnState(
        props.RSSData.url,
        setRSS,
        props.Get,
        updateWidgetProperty,
        props
      );
      setURL(props.RSSData.url);
    }
  });

  const {
    url,
    fontSize,
    backgroundColor,
    textColor,
    duration,
    delay
  } = props.RSSData;
  const {
    contentLocalID,
    screen: { localID }
  } = props.data;

  return (
    <Modal
      isOpen={props.open}
      toggle={() => props.toggle(false)}
      backdrop
      className="widgetScreenModal textWidget textWidget--rss"
    >
      <ModalHeader toggle={() => cancelChanges(props)}>
        {props.type}
      </ModalHeader>
      <ModalBody class="globalForm">
        <div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: `${props.screen.height}px`,
              width: `${props.screen.width}px`,
              maxHeight: `${props.data.screen.height - 10}px`,
              maxWidth: `${props.data.screen.width - 35}px`,
              fontSize: `${fontSize / props.data.screen.verticalScale}px`,
              backgroundColor: `${backgroundColor}`,
              color: textColor,
              overflow: "hidden"
            }}
          >
            <marquee
              scrollamount={props.RSSData.delay}
              trueSpeed
              direction={props.RSSData.direction.value}
              style={{
                backgroundColor,
                border: "none",
                fontFamily: `'${props.RSSData.fontFamily.value}', sans-serif`,
                padding: "none"
              }}
            >
              {rss && <RssRender rss={rss} {...props} />}
            </marquee>
          </div>
        </div>

        <Form>
          <FormGroup>
            <div className="globalInput">
              <Label>URL</Label>
              <Input
                type="text"
                value={url}
                onChange={({ target: { value } }) =>
                  updateWidgetProperty(
                    localID,
                    contentLocalID,
                    "url",
                    value,
                    props.campaign,
                    props.updateCampaign
                  )
                }
                placeholder="Enter URL"
              />
            </div>
          </FormGroup>
          <FormText>
            *For scroll delay, enter the value in milliseconds.
          </FormText>
          <Row
            className="color-picker-wrapper"
            onClick={e => e.stopPropagation()}
          >
            <Col md={12} className="btn-group">
              <Tooltip
                tooltipContent="Text Color"
                id="textColor"
                item={
                  <button
                    type="button"
                    className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
                    onClick={() => {
                      setTextColorSelector(!showTextColorSelector);
                      setTextBackColorSelector(false);
                    }}
                  >
                    <i className="fas fa-font" />{" "}
                    <b
                      className="selectColor"
                      style={{
                        backgroundColor: props.RSSData.textColor,
                        border: "1px solid black"
                      }}
                      role="presentation"
                    />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Background Color"
                id="bgColor"
                item={
                  <button
                    type="button"
                    className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
                    onClick={() => {
                      setTextColorSelector(false);
                      setTextBackColorSelector(
                        !showTextBackgroundColorSelector
                      );
                    }}
                  >
                    <i className="fas fa-fill-drip" />
                    <b
                      className="selectColor"
                      style={{
                        backgroundColor: props.RSSData.backgroundColor,
                        border: "1px solid black"
                      }}
                      role="presentation"
                    />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Scroll Delay"
                id="scroll"
                item={
                  <Input
                    type="number"
                    value={delay}
                    className="text-input"
                    min="0"
                    onChange={({ target: { value } }) =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "delay",
                        value,
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                    placeholder="Set scroll speed"
                    onInput={e => checkValidation(e)}
                  />
                }
              />
              <Tooltip
                tooltipContent="Font Size"
                id="fontSize"
                item={
                  <Input
                    type="number"
                    value={fontSize}
                    className="text-input"
                    min="0"
                    onChange={({ target: { value } }) =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "fontSize",
                        value,
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                    placeholder="Enter font size"
                    onInput={e => checkValidation(e)}
                  />
                }
              />
              <Tooltip
                className="w-100"
                tooltipContent="Direction"
                id="direction"
                item={
                  <MultipleSelect
                    input={{
                      value: props.RSSData.direction,
                      onChange: value =>
                        updateWidgetProperty(
                          localID,
                          contentLocalID,
                          "direction",
                          value,
                          props.campaign,
                          props.updateCampaign
                        )
                    }}
                    options={props.RSSData.marqueeDirectionsList}
                  />
                }
              />
              <Tooltip
                tooltipContent="Font Family"
                className="w-100"
                id="fontFamily"
                item={
                  <MultipleSelect
                    input={{
                      value: props.RSSData.fontFamily,
                      onChange: value =>
                        updateWidgetProperty(
                          localID,
                          contentLocalID,
                          "fontFamily",
                          value,
                          props.campaign,
                          props.updateCampaign
                        )
                    }}
                    options={props.RSSData.fontList}
                  />
                }
              />
            </Col>
            {showTextColorSelector && (
              <div className="d-flex justify-content-center textColorPicker">
                <SketchPicker
                  color={props.RSSData.textColor}
                  onChange={value => {
                    updateWidgetProperty(
                      localID,
                      contentLocalID,
                      "textColor",
                      value.hex,
                      props.campaign,
                      props.updateCampaign
                    );
                  }}
                />
              </div>
            )}
            {showTextBackgroundColorSelector && (
              <div className="d-flex justify-content-center textColorPicker">
                <SketchPicker
                  color={backgroundColor}
                  onChange={value =>
                    updateWidgetProperty(
                      localID,
                      contentLocalID,
                      "backgroundColor",
                      value.hex,
                      props.campaign,
                      props.updateCampaign
                    )
                  }
                />
              </div>
            )}
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-primary"
          onClick={() => cancelChanges(props)}
        >
          Cancel
        </Button>
        <Button
          className="mt-0 primaryButton"
          onClick={() => props.toggle(false)}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect(
  (state, props) => {
    const { campaign } = state.newCampaign;
    const {
      contentLocalID,
      screen: { localID }
    } = props.data;
    const RSSData = campaign.getContent(localID, contentLocalID);
    return {
      campaign,
      RSSData: RSSData || []
    };
  },
  { updateCampaign, Get }
)(RSSWidget);
