import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";

export function columns(
  roles,
  permission,
  handleDelete,
  handleUnArchiveItem,
  handleSort,
  sortOrder
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i className={`fa fa-sort-alpha-${sortOrder.name.sortBy === "desc" ? "up" : "down"}`} />
          </span>
        </div>
      ),
      style: { width: "550px" }
    },
    {
      dataField: "attributes.created_at",
      text: "Date Created",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.created_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "delete",
      text: "",
      formatter: (name, row) => (
        <div className="contentbutton action">
          <RouteAllowed roles={roles && roles} permission={permission && permission.content.delete}>
            <Tooltip
              item={
                <i
                  role="presentation"
                  onClick={() => handleUnArchiveItem(row.id, name)}
                  className="fas fa-history"
                />
              }
              id={`archive-${row.id}`}
              tooltipContent="Restore"
            />

            <Tooltip
              item={
                <i
                  role="presentation"
                  onClick={() => handleDelete(row.id, name)}
                  className="fa fa-trash action__trash"
                  style={{ cursor: "pointer" }}
                />
              }
              id={`delete-${row.id}`}
              tooltipContent="Delete"
            />
          </RouteAllowed>
        </div>
      )
    }
  ];
}
