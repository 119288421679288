export function requestFormatter(type, attributes) {
  return {
    _jsonapi: {
      data: {
        type,
        attributes
      }
    }
  };
}
