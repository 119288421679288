/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { columns } from "../utils/reportUtils";
import ReportHeader from "./reportHeader";

class ReportTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        campaign_name: false,
        mime_type: false,
        dimension: false,
        device_mac: false,
        content_tags: false,
        start_time: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  render() {
    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.reportList}
          columns={columns(
            this.state.hiddenColumns,
            this.props.roles
          )}
          columnToggle
        >
          {props => (
            <div>
              <ReportHeader
                handleRefresh={this.props.handleRefresh}
                initializeFilter={this.props.initializeFilter}
                refreshing={this.props.refreshing}
                campaignList={this.props.campaignList}
                handleToggleDrawer={this.props.handleToggleDrawer}
                drawer={this.props.drawer}
                updateTimeFrame={this.props.updateTimeFrame}
                handleTimeFrameChange={this.props.handleTimeFrameChange}
                defaultVal={this.props.defaultVal}
                timeFrame={this.props.timeFrame}
                // props below are needed for table col toggler
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                {...this.props}
              />
              <BootstrapTable
                classes="contentSectionTable reportTable"
                hover
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

// const TableRowGenerator = (
//   {
//     id,
//     // This  are attribute Definitions
//     attributes: {
//       campaign_name,
//       content_tags,
//       content_type,
//       device_id,
//       device_mac,
//       duration,
//       end_time,
//       height,
//       name,
//       start_time,
//       timezone_offset,
//       width
//     }
//   },
//   roles,
//   permission
// ) => (
//   <tr key={id} className="content__list network-table">
//     <th scope="row">
//       <span>{name || "N/A"}</span>
//       <br />

//       {/* <p className="tag-list"> */}
//       {/* <div style={{ display: "block" }}> */}
//       {content_tags &&
//         typeof content_tags === "object" &&
//         content_tags.map((i, index) => (
//           <span
//             key={`calendar-content-tags-${index}`}
//             className="contentTag__badge"
//           >
//             {TextClipper(i, 8)}
//           </span>
//         ))}
{
  /* </div> */
}
{
  /* </p> */
}

{
  /* CHANGE DEFINATION AS STATED ABOVE FOR ALL THE ATTRIBUTES */
}
{
  /* </th> */
}

{
  /* <td>
      <span>{campaign_name || "-"}</span>
      <br />
      <span className="durationblock">
        <i> {duration || "0"} sec </i>
      </span>
    </td>

    <td>
      <span className="content-text">{content_type || "N/A"}</span>
    </td>

    <td>
      <span>{`${height} X ${width}`}</span>
    </td> */
}

{
  /* <td>
      <span>{start_time || "-"}</span>
      <br />
      <span>{end_time || "-"}</span>
    </td> */
}
{
  /* <td>
      <span>{device_mac || "N/A"}</span>
    </td>

    <td>
      <span>{start_time || "-"}</span>
      <br />
      <span>{end_time || "-"}</span>
    </td>
  </tr>
); */
}

/** Data Signature for the Map Table Function
 *
 * campaign_name: "report testing"
 * content_tags: ["TEST", "TEST2", "GURZU", "DEV"]
 * content_type: "image/png"
 * device_id: "N/A"
 * device_mac: ""
 * duration: 11
 * end_time: "Mar 05, 2019 11:05 AM"
 * height: 1080
 * name: "Cloud.Png"
 * start_time: "Mar 05, 2019 11:05 AM"
 * timezone_offset: "N/A"
 * width: 1920
 *
 */

/**
 * DEMO DATA SIGNATURE
 *  campaign_name
 * content_tags
 * content_type
 * device_id
 * device_mac
 * duration
 * end_time
 * height
 * name
 * start_time
 * timezone_offset
 * width
 */

// const MapTable = (data, roles, permission) =>
//   data.map(item => TableRowGenerator(item, roles, permission));

/** Clear Sortable function if not used in future */

/** THIS FUNCTION DEFINE THE TABLE HEADER */
// const ReportTable = props => (
//   <div className="contentSectionTable">
//     <ReportDetails {...props} />
//     <Table responsive className="table-responsive">
//       <thead>
//         <tr>
//           <th>
//             {/* <span onClick={() => props.handleSort("duration")} className="sort-by"> */}
//             <span onClick={() => {}} className="sort-by nopdn">
//               Content Name
//               {/* <i className="fa fa-caret-down" /> */}
//             </span>
//           </th>
//           <th>
//             <span onClick={() => {}} className="sort-by nopdn">
//               Campaign Name
//             </span>
//           </th>

//           <th>
//             <span onClick={() => {}} className="sort-by nopdn">
//               MIME Type
//               {/* <i className="fa fa-caret-down" /> */}
//             </span>
//           </th>

//           <th>
//             <span onClick={() => {}} className="sort-by nopdn">
//               Dimension
//               {/* <i className="fa fa-caret-down" /> */}
//             </span>
//           </th>

//           <th>
//             <span onClick={() => {}} className="sort-by nopdn">
//               Device MAC
//               {/* <i className="fa fa-caret-down" /> */}
//             </span>
//           </th>

//           <th>
//             <span onClick={() => {}} className="sort-by nopdn">
//               Start Time / End Time
//               {/* <i className="fa fa-caret-down" /> */}
//             </span>
//           </th>
//         </tr>
//       </thead>

{
  /* THIS FUNCTION CALL GENERATE THE REPORT BODY.
          MAKE SURE CORRECT ATTRIBUTES WERE MAP BY VISITING DEFINITION
      */
}

{
  /* <tbody>{MapTable(props.reportList, props.roles, props.permission)}</tbody>
    </Table>
  </div>
); */
}

export default ReportTable;
