import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  textFilter,
  selectFilter,
  Comparator
} from "react-bootstrap-table2-filter";
import { Progress } from "reactstrap";
import { pipe } from "ramda";
import { TextClipper } from "../../../helper/TextFormatter";
import { PopOver, Tooltip } from "../../../core";

const headerSortingStyle = { color: "#e44b32" };

function shouldShowDownloadBar(totalDownload, totalContentDownloaded) {
  if (totalContentDownloaded === totalDownload) {
    return false;
  }
  if (totalContentDownloaded > totalDownload) {
    return false;
  }
  return true;
}
function checkLength(word, limit = 25) {
  if (word && word.length > limit) {
    return `${word.slice(0, limit)}...`;
  }
  return word;
}
function percentageCalculator(totalDownload, totalContentDownloaded) {
  return (totalContentDownloaded / totalDownload) * 100;
}

function convertOrientaion(orientation) {
  const lowerCase = orientation ? orientation.toLowerCase() : "";
  if (
    lowerCase === "portrait_left" ||
    lowerCase === "portrait_right" ||
    lowerCase === "portrait"
  ) {
    return "Portrait";
  }
  if (
    lowerCase === "landscape_normal" ||
    lowerCase === "landscape_inverted" ||
    lowerCase === "landscape"
  ) {
    return "Landscape";
  }
}

function toolTipsValueCalculator(text, id, limit = 25, current) {
  return (
    <Tooltip
      item={<span className="mb-1">{checkLength(text, limit)}</span>}
      id={`viewname-${id}-${limit}-${current}`}
      tooltipContent={text}
    />
  );
}
const calculateType = ({ calendar_name, campaign_name }) => {
  if (calendar_name) {
    return { type: "calendar" };
  }
  if (campaign_name) {
    return { type: "campaign" };
  }
  return { type: null };
};
const formatMediaType = ({ type }) => {
  if (type === "calendar") {
    return <span className="networkPlaying">Calendar</span>;
  }
  if (type === "campaign") {
    return <span className="networkPlaying">Campaign</span>;
  }
  return null;
};
const formatedStatus = pipe(
  calculateType,
  formatMediaType
);
const formatCurrentlyPlaying = (
  { campaign_name, calendar_name, id },
  current
) => {
  if (campaign_name || calendar_name) {
    return (
      <React.Fragment>
        {toolTipsValueCalculator(
          campaign_name || calendar_name,
          id,
          25,
          current
        )}
        {formatedStatus({ campaign_name, calendar_name })}
      </React.Fragment>
    );
  }
  return <span>None</span>;
};
const DownloadProgress = ({
  attributes: {
    received_media_type,
    received_media_name,
    total_content_to_be_downloaded: totalDownload,
    total_downloaded: totalContentDownloaded
  },
  id
}) => (
  <React.Fragment>
    <div>{toolTipsValueCalculator(received_media_name, id, 25)}</div>
    <Progress
      className="mt-1"
      striped
      value={percentageCalculator(totalDownload, totalContentDownloaded)}
    />

    <p className="clearfix">
      {" "}
      {formatMediaType({ type: received_media_type })}{" "}
      <small className="downloadingContent">
        {" "}
        {`${totalContentDownloaded} / ${totalDownload}`} contents
      </small>{" "}
    </p>
  </React.Fragment>
);

export function columns(
  stopPlayingDevice,
  toggleSendToDevice,
  removeDevice,
  toggleDrawer,
  hiddenColumn,
  changeColumn,
  isGroupOpen,
  handleSort,
  sortOrder,
  selectedGroupID,
  selectedGroupName,
  isMobileView
) {
  return [
    {
      dataField: "action_buttons",
      text: "",
      style: { position: "relative" },
      columnName: "action_buttons",
      formatter: (a, item) => (
        <div className="contentSectionNWTable__actionButtonContainer">
          <div className="contentSectionNWTable__actionButtonWrapper">
            {!isMobileView && (
              <div className="d-inline-flex">
                <span className="contentSectionNWTable__actionButtonWrapper__buttons">
                  <Link
                    to={{
                      pathname: `/networks/device/${item.id}?id=${selectedGroupID}&gn=${selectedGroupName}&n=${item.attributes.name}&view=groups`,
                      state: {
                        groupID: selectedGroupID,
                        groupName: selectedGroupName,
                        name: item.attributes.name || ""
                      }
                    }}
                  >
                    <Tooltip
                      item={
                        <i
                          role="presentation"
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          style={{ cursor: "pointer" }}
                          className="fas fa-eye"
                        />
                      }
                      id={`show-${item.id}`}
                      tooltipContent="View"
                    />
                  </Link>
                </span>
                <span className="contentSectionTable__actionButtonWrapper__buttons">
                  <Tooltip
                    item={
                      <i
                        role="presentation"
                        onClick={e => {
                          e.stopPropagation();
                          return removeDevice(item);
                        }}
                        style={{ cursor: "pointer" }}
                        className="fas fa-trash"
                      />
                    }
                    id={`remove-${item.id}`}
                    tooltipContent="Remove Device"
                  />
                </span>
              </div>
            )}
            {!a && (
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();
                        return toggleDrawer(item);
                      }}
                      style={{ cursor: "pointer" }}
                      className="fas fa-cog"
                    />
                  }
                  id={`setting-${item.id}`}
                  tooltipContent="Advanced Setting"
                />
              </span>
            )}

            {!a && (
              <Fragment>
                <span className="contentSectionTable__actionButtonWrapper__buttons">
                  <Tooltip
                    item={
                      <i
                        role="presentation"
                        onClick={e => {
                          e.stopPropagation();
                          return toggleSendToDevice(item);
                        }}
                        style={{ cursor: "pointer" }}
                        className="fas fa-play"
                      />
                    }
                    id={`play-${item.id}`}
                    tooltipContent="Play"
                  />
                </span>
                {(item.attributes.campaign_name ||
                  item.attributes.calendar_name) && (
                  <span className="contentSectionTable__actionButtonWrapper__buttons">
                    <Tooltip
                      item={
                        <i
                          role="presentation"
                          onClick={e => {
                            e.stopPropagation();
                            return stopPlayingDevice(item);
                          }}
                          style={{ cursor: "pointer" }}
                          className="fas fa-stop"
                        />
                      }
                      id={`delete-${item.id}`}
                      tooltipContent="Stop"
                    />
                  </span>
                )}
              </Fragment>
            )}
          </div>
        </div>
      )
    },
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      headerFormatter: () => (
        <div className="word-warp">
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "etwork-name word-warp word-warp",
      // sort: true,
      sortCaret: (order, column) => {
        if (!order) return <i className="fa fa-sort-alpha-up" />;
        if (order === "asc") return <i className="fa fa-sort-alpha-down" />;
        if (order === "desc") return <i className="fa fa-sort-alpha-up" />;
        return null;
      },

      headerSortingStyle,
      formatter: (value, item) => (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: `/networks/device/${item.id}?id=${selectedGroupID}&gn=${selectedGroupName}&n=${value}&view=groups`,
              state: {
                groupID: selectedGroupID,
                groupName: selectedGroupName,
                name: value || ""
              }
            }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            {" "}
            <span className="content__titlename content__name">{value}</span>
          </Link>
        </div>
      )

      // filter: textFilter({ placeholder: "tesNuc" })
    },
    {
      dataField: "attributes.mac_address",
      text: "Mac Address",
      columnName: "mac_address",
      headerFormatter: column => (
        <div className="word-warp">
          <span
            role="presentation"
            onClick={() => handleSort("mac_address")}
            className="sort-by "
            style={{
              color: sortOrder.mac_address.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.mac_address.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-maAddress word-warp",
      formatter: (value, name) => (
        <span className="content__titlename content__name font-weight-bold">
          {value}
        </span>
      ),
      hidden: hiddenColumn.mac_address
      // filter: textFilter({ placeholder: "94:c6:91:a1:8b:ea" })
    },
    {
      dataField: "attributes.currently_playing",
      text: "Currently Playing",
      columnName: "currently_playing",
      headerFormatter: column => (
        <div className="word-warp">
          <span
            role="presentation"
            onClick={() => handleSort("received_media_name")}
            className="sort-by "
            style={{
              color: sortOrder.received_media_name.active
                ? "#e44b32"
                : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.received_media_name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-currentlyPlaying word-warp",

      formatter: (val, row) => {
        const { calendar_name, campaign_name } = row.attributes;
        const { id } = row;
        return formatCurrentlyPlaying(
          { campaign_name, calendar_name, id },
          "current"
        );
      },
      hidden: hiddenColumn.currently_playing
      // filter: textFilter({ placeholder: "Campaign" })
    },
    {
      dataField: "attributes.last_downloaded_name",
      text: "Last Sent",
      columnName: "last_downloaded_name",
      headerFormatter: column => (
        <div className="word-warp">
          <span
            role="presentation"
            onClick={() => handleSort("last_downloaded_name")}
            className="sort-by "
            style={{
              color: sortOrder.last_downloaded_name.active
                ? "#e44b32"
                : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.last_downloaded_name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-lastDownloadedName word-warp",
      formatter: (val, row) => {
        const {
          total_content_to_be_downloaded,
          total_downloaded,
          last_downloaded_name,
          last_downloaded_type
        } = row.attributes;
        const { id } = row;
        if (
          shouldShowDownloadBar(
            total_content_to_be_downloaded,
            total_downloaded
          )
        ) {
          return DownloadProgress({ ...row });
        }
        return formatCurrentlyPlaying({
          campaign_name:
            last_downloaded_type === "campaign" ? last_downloaded_name : null,
          calendar_name:
            last_downloaded_type === "calendar" ? last_downloaded_name : null,
          id
        });
      },
      hidden: hiddenColumn.last_downloaded_name
    },
    {
      dataField: "attributes.tags",
      text: "Tags",
      columnName: "tags",
      formatter: (value, item) => (
        // eslint-disable-next-line no-unused-expressions
        <div className="content-tag">
          <div className="tag-list">
            <div style={{ display: "block" }}>
              {item.attributes.tags &&
                item.attributes.tags.slice(0, 2).map((i, index) => (
                  <span key={`tags-${index + i}`} className="contentTag__badge">
                    {TextClipper(item.id, i, 8, index)}
                  </span>
                ))}
              <span className="tagmenu-icon">
                {item.attributes.tags && item.attributes.tags.length >= 3 && (
                  <PopOver id={`tags-${item.id}`}>
                    {item.attributes.tags.map(i => (
                      <span key={`tags-${i}`} className="contentTag__badge">
                        {i}
                      </span>
                    ))}
                  </PopOver>
                )}
              </span>
            </div>
          </div>
        </div>
      ),
      classes: "network-tag word-warp",
      hidden: hiddenColumn.tags
    },
    {
      dataField: "attributes.hostname",
      text: "Host name",
      columnName: "hostname",
      headerFormatter: column => (
        <div className="word-warp">
          <span
            role="presentation"
            onClick={() => handleSort("hostname")}
            className="sort-by "
            style={{
              color: sortOrder.hostname.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.hostname.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-hostname word-warp",

      headerSortingStyle,
      // filter: textFilter({ placeholder: "gurzuNuc" }),
      formatter: (name, row) =>
        // eslint-disable-next-line no-unused-expressions
        row.attributes.hostname && row.attributes.hostname.length >= 25 ? (
          <Tooltip
            item={
              <span className="content__name">
                {" "}
                {`${row.attributes.hostname.slice(0, 25)}...`}{" "}
              </span>
            }
            id={`campaignViewHostName-${row.id}`}
            tooltipContent={row.attributes.hostname}
          />
        ) : (
          <span className="content__name" style={{ "word-break": "break-all" }}>
            {row.attributes.hostname}
          </span>
        ),
      hidden: hiddenColumn.hostname
    },
    {
      dataField: "attributes.electron_version",
      text: "Version",
      classes: "network-electronVersion word-warp",
      columnName: "electron_version",
      headerFormatter: column => (
        <div className="word-warp">
          <span
            role="presentation"
            onClick={() => handleSort("electron_version")}
            className="sort-by "
            style={{
              color: sortOrder.electron_version.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.electron_version.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <span className="network__electron_version">
          {name && name.length >= 25 ? (
            <Tooltip
              item={
                <span className="network__electron_version">
                  {" "}
                  {`${name.slice(0, 20)}...`}{" "}
                </span>
              }
              id={`viewnamelist-${row.id}`}
              tooltipContent={name}
            />
          ) : (
            <span className="network__electron_version">{name || "N/A"}</span>
          )}
        </span>
      ),
      style: { cursor: "pointer" },
      hidden: hiddenColumn.electron_version
    },
    {
      dataField: "attributes.resolution",
      text: "Resolution",
      columnName: "resolution",
      headerFormatter: column => (
        <div>
          <span
            role="presentation"
            onClick={() => handleSort("resolution")}
            className="sort-by "
            style={{
              color: sortOrder.resolution.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.resolution.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-maAddress",
      formatter: (value, name) => (
        <span className="network__electron_version">{value}</span>
      ),
      hidden: hiddenColumn.resolution
    },
    {
      dataField: "attributes.timezone",
      text: "Time Zone",
      classes: "network-timezone word-warp",
      columnName: "timezone",
      headerFormatter: column => (
        <div className="word-warp">
          <span
            role="presentation"
            onClick={() => handleSort("timezone")}
            className="sort-by "
            style={{
              color: sortOrder.timezone.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.timezone.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (value, name) => (
        <span className="network__electron_version">{value || "N/A"}</span>
      ),
      hidden: hiddenColumn.timezone
      // filter: textFilter({ placeholder: "Asia/Kathmandu" })
    },
    {
      dataField: "attributes.orientation",
      text: "Orientation",
      classes: "network-orientation word-warp",
      columnName: "orientation",
      headerFormatter: column => (
        <div className="word-warp">
          <span
            role="presentation"
            onClick={() => handleSort("orientation")}
            className="sort-by "
            style={{
              color: sortOrder.orientation.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.orientation.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: name => (
        <span className="network__electron_version">
          {convertOrientaion(name)}
        </span>
      ),

      // filter: selectFilter({
      //   placeholder: "All",
      //   options: {
      //     Landscape: "Landscape", // For Inverted and Normal
      //     Portrait: "Portrait" // For Portrait Left and right
      //   },
      //   comparator: Comparator.LIKE // default is Comparator.EQ
      // }),
      hidden: hiddenColumn.orientation
    },
    {
      dataField: "attributes.block_pop",
      text: "Proof of Play",
      columnName: "block_pop",
      headerFormatter: column => (
        <div>
          <span
            role="presentation"
            onClick={() => handleSort("block_pop")}
            className="sort-by "
            style={{
              color: sortOrder.block_pop.active ? "#2cc277" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.block_pop.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-maAddress",
      formatter: value => (
        <span className="network__electron_version">
          {!value ? "On" : "Off"}
        </span>
      ),
      hidden: hiddenColumn.block_pop
    },
    {
      dataField: "attributes.status",
      text: "Status ",
      columnName: "status",
      style: { width: "120px" },
      headerFormatter: column => (
        <div className="word-warp">
          <span
            role="presentation"
            onClick={() => handleSort("status")}
            className="sort-by "
            style={{
              color: sortOrder.status.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            {column.text}
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.status.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      classes: "network-status word-warp",
      formatter: (a, b) => (
        <div className="content" style={{ "padding-left": "13%" }}>
          <span
            className={`${
              a === "offline"
                ? "content__status__offline"
                : "content__status__online"
            }`}
          />
        </div>
      ),
      hidden: hiddenColumn.status
      // filter: selectFilter({
      //   placeholder: "All",
      //   options: { offline: "Offline", online: "Online" }
      // })
    }
  ];
}
