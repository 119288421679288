/* eslint-disable no-nested-ternary */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Get,
  Post,
  Delete,
  BulkDelete,
  Put,
  Patch,
  S3UPLOAD,
  S3URL,
  S3Delete,
  S3EDIT
} from "../constant";
import { Headers } from "../core/layout/header";
import {
  required,
  number,
  isName,
  isPasswordStrong,
  email,
  alphaNumeric
} from "./validation";
import { showNotification } from "../core/modal/toster";
import { permission } from "./permission";
import { FilterRoutesRoles } from "./roles";
import { requestFormatter } from "../constant/requestFormatter";
import { setUserSetting, getUserSetting } from "../constant/userSetting";
import { openGlobalModal, closeGlobalModal } from "../core/modal/globalDialog";

const formValidator = {
  required,
  number,
  isName,
  isPasswordStrong,
  email,
  alphaNumeric
};

export function DashboardRouters(routes, auth = false, roles) {
  return FilterRoutesRoles(routes, roles).map((route, i) => (
    <RouteWithSubRoutes key={i} {...route} auth={auth} roles={roles} />
  ));
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact
      render={props =>
        route.auth ? (
          <React.Fragment>
            <Headers
              {...props}
              routes={route.routes}
              permission={permission}
              roles={route.roles}
              feature={route.feature}
            />
            <route.component
              {...props}
              routes={route.routes}
              permission={permission}
              roles={route.roles}
              feature={route.feature}
            />
          </React.Fragment>
        ) : (
          /**
           *
           * @description Redirect location can be specified here.
           */
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: props.location
              }
            }}
          />
        )
      }
    />
  );
}

export function Map(Component) {
  const HOC = props => (
    <Component
      {...props}
      formValidator={formValidator}
      requestFormatter={requestFormatter}
      setUserSetting={setUserSetting}
      getUserSetting={getUserSetting}
    />
  );
  return connect(
    null,
    {
      Get,
      Post,
      Delete,
      BulkDelete,
      Put,
      Patch,
      showNotification,
      openGlobalModal,
      closeGlobalModal
    }
  )(HOC);
}
