import React, { Component } from "react";
import {
  Collapse,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  TabPane,
  TabContent,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  reduxForm,
  Field,
  formValueSelector,
  change,
  getFormValues,
  initialize as remoteInitialize
} from "redux-form";
import { connect } from "react-redux";
import { Resources } from "../redux/headerNotificationRedux";

class HeaderNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      currentSelected: null
    };
  }

  componentDidMount() {
    this.getLatestNotifications();
  }

  toggleNotification = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  getLatestNotifications = async () => {
    const today = moment();
    const yesterday = moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    const { getBrandNotifications } = Resources;
    getBrandNotifications.url = `/brand_notifications?gte_date=${yesterday}&lte_date=${today.format(
      "YYYY-MM-DD"
    )}`;
    await this.props.Get(getBrandNotifications).then(res => {
      res.data.data && this.initializeNotifications(res.data.data);
    });
  };

  selectCurrentNotification = id => {
    this.setState({
      currentSelected: this.state.currentSelected === id ? null : id
    });
  };

  toggleIsChecked = async (notificationId, value) => {
    const { postIsChecked } = Resources;
    postIsChecked.url = `brand_notifications/${notificationId}/checked?value=${value}`;
    this.setState({ [`loading-${notificationId}-checkbox`]: true });
    await this.props.Post(postIsChecked).then(data => {
      this.setState({ [`loading-${notificationId}-checkbox`]: false });
    });
  };

  initializeNotifications = notifications => {
    const fieldsObj = {};
    notifications.map(data => {
      fieldsObj[`notification-${data.id}-flag`] = data.attributes.is_checked;
    });
    this.props.remoteInitialize("notificationListForm", fieldsObj);
  };

  render() {
    const notifications = this.props.brandNotificationList.list;
    const { activeTab } = this.state;
    const { notificationFormValues } = this.props;
    return (
      <div
        style={{
          width: "50vw"
        }}
      >
        <button
          type="button"
          onClick={this.toggleNotification}
          className="header-dropdown bell-icon ml-auto"
          style={{
            border: "none",
            background: "transparent"
          }}
        >
          <i
            className="fas fa-bell pt-1"
            style={{ fontSize: "1.25rem", color: "#8e8e8e" }}
          />
        </button>
        <Collapse isOpen={this.state.dropdownOpen}>
          <Card
            className=""
            style={{
              boxShadow: "2px 2px 7px 0px gray",
              display: "block",
              height: "500px",
              overflowY: "auto"
            }}
          >
            <CardHeader className="d-flex flex-row justify-content-between">
              <span>Latest</span>
              <Link style={{ textDecoration: "none" }} to="/notifications">
                <span onClick={() => this.toggleNotification()}>See all Notifications</span>
              </Link>
            </CardHeader>
            <CardBody className="p-1">
              {notifications.map((data, index) => {
                const { attributes } = data;
                const { brand_details } = attributes;
                return (
                  <div key={index}>
                    <div
                      className="border-bottom"
                      style={{
                        padding: "15px",
                        backgroundColor: `${notificationFormValues &&
                          notificationFormValues[`notification-${data.id}-flag`]
                          ? "#f4f4f4"
                          : "#ffffff"
                          }`
                      }}
                    >
                      <div>
                        <div className="d-flex justify-content-between">
                          <div style={{paddingBottom: "5px"}}>
                            <b style={{fontSize: "1.05rem"}}>{`${brand_details.name}`} </b>
                            <br />
                          <div style={{color: "#565656", fontSize: "0.975rem !important", paddingBottom: "3px !important"}}>
                            <i> {attributes.title}</i>
                          </div> 
                          </div>
                          <div className="d-flex flex-row">
                            <p
                              className=""
                              style={{
                                marginRight: "7px",
                                fontSize: ".875rem"
                              }}
                            >
                              {" "}
                              Created At:{" "}
                              {moment(attributes.created_at).format(
                                "YYYY/MM/DD hh:mm a"
                              )}{" "}
                            </p>
                            {this.state[`loading-${data.id}-checkbox`] ? (
                              <span>
                                <svg
                                  className="spinner"
                                  style={{
                                    position: "relative"
                                  }}
                                  viewBox="0 0 50 50"
                                >
                                  <circle
                                    className="path"
                                    cx="25"
                                    cy="25"
                                    r="10"
                                    fill="none"
                                    strokeWidth="4"
                                  />
                                </svg>
                              </span>
                            ) : (
                              <Field
                                style={{ marginTop: "4px" }}
                                name={`notification-${data.id}-flag`}
                                id="notificationDoneFlag"
                                component="input"
                                type="checkbox"
                                checked={
                                  notificationFormValues &&
                                  notificationFormValues[
                                  `notification-${data.id}-flag`
                                  ]
                                }
                                onChange={event => {
                                  this.toggleIsChecked(
                                    data.id,
                                    !notificationFormValues[
                                    `notification-${data.id}-flag`
                                    ]
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="d-flex justify-content-between">
                            <div>
                              {/* <div style={{ fontSize: "1.05rem" }}>
                                <b>Start to End Date :</b>
                              </div> */}
                              <div style={{ fontSize: "0.965rem" }}>
                                <i>
                                {" "}
                                {moment(brand_details.start_date).format(
                                  "YYYY/MM/DD"
                                )}{" "}
                                to{" "}
                                {moment(brand_details.end_date).format(
                                  "YYYY/MM/DD"
                                )}{" "}
                                </i>
                              </div>
                            </div>
                            <div style={{ fontSize: "0.95rem" }}>
                              {" "}
                              <b>Play time per day:{" "}</b>
                              {brand_details.total_duration_minutes} min
                            </div>
                          </div>
                          {/* <p
                            onClick={() =>
                              this.selectCurrentNotification(data.id)
                            }
                            style={{ fontSize: "1.05rem", cursor: "pointer", marginTop: "15px"}}
                          >
                            <b>Contents List</b>{" "}
                            <span className="fa fa-caret-down" />
                          </p> */}
                        </div>
                      </div>
                      {/* <Collapse isOpen={this.state.currentSelected === data.id} style={{ paddingBottom: "20px" }}>
                        {attributes.contents &&
                          attributes.contents.length > 0 ? (
                          <ListGroup className="h-auto">
                            {attributes.contents.map((content, index) => (
                              <ListGroupItem key={index}>
                                <ListGroupItemHeading>
                                  {" "}
                                  {content.name}
                                </ListGroupItemHeading>
                                <ListGroupItemText>
                                  {content.display_name}
                                </ListGroupItemText>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        ) : (
                          <div>No Contents Available</div>
                        )}
                      </Collapse> */}
                    </div>
                  </div>
                );
              })}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}

export default connect(
  state => {
    const selector = formValueSelector("notificationListForm");

    return {
      brandNotificationList: state.headerNotificationList,
      notificationFormValues: getFormValues("notificationListForm")(state)
    };
  },
  { remoteInitialize }
)(
  reduxForm({
    form: "notificationListForm"
  })(HeaderNotification)
);
