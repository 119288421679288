export const orientations = [
  { orientation: "Normal", label: "Normal", value: "Normal" },
  { orientation: "Inverted", label: "Inverted", value: "Inverted" },
  {
    orientation: "Portrait Left",
    label: "Portrait Left",
    value: "Portrait Left"
  },
  {
    orientation: "Portrait Right",
    label: "Portrait Right",
    value: "Portrait Right"
  }
];
