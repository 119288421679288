export const Resource = {
  getReport: {
    url: "/stat",
    asyncActions: {
      init: "getStat_INIT",
      success: "getStat_SUCCESS",
      error: "getStat_ERROR"
    }
  }
};

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined
};

export function Home(state = initialState, action = {}) {
  const {
    asyncActions: { init, success, error }
  } = Resource.getReport;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}
