/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable react/prefer-stateless-function */

/** Importing library from Node Modules */
import React, { Component } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import UploadModal from "./features/contents/modules/uploadModal";
import Notification from "./core/modal/toster";
import TicketNotificationToast, {
  showTicketNotification
} from "./core/modal/ticketToaster";
import GlobalDialog from "./core/modal/globalDialog";
import TicketToastCard from "./core/modal/ticketToastCard";
import { Weather } from "./features/campaign/modules/weather";
/** Importing Helper and constant from helper and constant folder. */
import { Get, Post, Put, Patch, Delete, BulkDelete } from "./constant";

/** Importing Routes */

import { dashboardRoutes } from "./Routes/mainRoutes";
import { DashboardRouters } from "./helper/routeMap";
import Login from "./features/auth/login";
import NoRoute from "./core/layout/noroutes";
import Preview from "./features/campaign/pages/preview";
import ForgotPassword from "./features/auth/forgotPassword";
import ResetPassword from "./features/auth/resetPassword";
import { defaultUserSettings } from "./constant/userSetting";
import { deviceDownloadStatus } from "./helper/actionCable";
import { progress, hideModal } from "./features/contents/redux";
import { DomainNotFound } from "./features/auth/pages/domainNotFound";
import {
  updateDataFromWebSocket,
  updateScreenShotDataFromWebSocket,
} from "./features/network/redux";
import { updateNotificationCountFromWebSocket } from "./features/notifications/redux";
import { ticketUpdateFromWebsocket } from "./features/tickets/redux";
import Resize from "./features/campaign/utls/resize";
import PdfFormatter from "./features/auth/modules/pdfFormatter";
import { updateHeaderNotification } from "./features/notifications/redux/headerNotificationRedux";

// import Deployment from "./core/deployment";

/** Importing required compoennts */

// const Mohan = props => <p> hello Mohan dai</p>;
class App extends Component {
  constructor(props) {
    super(props);
    this.handleUserSetting();
    // this.props.ticketUpdateFromWebsocket(4);
    this.state = {
      websocketWasInitiated: false
    };
  }

  connectWebSocket = () => {
    if (this.props.authStatus && this.state.websocketWasInitiated === false) {
      deviceDownloadStatus(
        process.env.REACT_APP_WS_URL,
        this.props.updateDataFromWebSocket,
        this.showTicketToast,
        this.props.updateScreenShotDataFromWebSocket,
        this.props.updateNotificationCountFromWebSocket,
        this.props.updateHeaderNotification
      );
      this.setState(() => ({ websocketWasInitiated: true }));
    }
  };

  handleUserSetting = () => {
    const userSetting = window.localStorage.getItem("userSetting");
    const userContentViewSetting =
      userSetting && window.localStorage.getItem("userSetting").contentListView;
    if (userSetting === null || userContentViewSetting === undefined) {
      window.localStorage.setItem(
        "userSetting",
        JSON.stringify(defaultUserSettings)
      );
    }
  };

  showTicketToast = ticket => {
    const access = [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "CalendarManager",
      "Admin",
      "SuperAdmin"
    ];
    const commonRoles = this.props.roles.filter(x => access.indexOf(x) !== -1);
    if (commonRoles.length > 0) {
      this.props.ticketUpdateFromWebsocket(ticket.count);
      if (ticket.data) {
        this.props.showTicketNotification(ticket);
      }
    }
  };

  render() {
    this.connectWebSocket();
    if (this.props.hasError) {
      // You can render any custom fallback UI
      this.props.history.push("/domain_not_found");
    }
    const routes = this.props.roles.includes("SuperAdmin")
      ? dashboardRoutes
      : dashboardRoutes.filter(
        route =>
          this.props.roles.includes(route.feature) ||
          this.props.roles.includes("SuperAdmin") ||
          route.feature === undefined
      );
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/forgot_password"
              render={props => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/activate/forgot_password/:token"
              render={props => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/activate/register/:token"
              render={props => <ResetPassword {...props} />}
            />
            <Route exact path="/campaign/preview/:id" component={Preview} />
            {!this.props.authStatus && <Route path="/" component={Login} />}
            <Route exact path="/" component={Login} />
            <Route exact path="/widget" component={Resize} />
            {DashboardRouters(
              dashboardRoutes,
              this.props.authStatus,
              this.props.roles
            )}
            <Route exact path="/domain_not_found" component={DomainNotFound} />
            <Route
              exact
              path="/userManual"
              render={props => <PdfFormatter {...props} />}
            />{" "}
            <Route component={NoRoute} />
          </Switch>
        </BrowserRouter>
        <UploadModal />
        <GlobalDialog />
        <Notification />
        <TicketNotificationToast
          ToasterComponent={TicketToastCard}
          roles={this.props.roles}
        />
        <div style={{ position: "relative" }}>{/* <Deployment /> */} </div>
      </div>
    );
  }
}

export default connect(
  state => {
    const {
      userDetails: { auth, domains }
    } = state;
    const currentDomain = JSON.parse(
      window.localStorage.getItem("domain") !== "undefined"
        ? window.localStorage.getItem("domain")
        : null
    );
    let roles = [];
    if (currentDomain !== null) {
      roles = currentDomain.roles;
    } else if (domains && domains.length > 0) {
      roles = domains[0].roles;
    } else {
      roles = [""];
    }
    return {
      authStatus: auth,
      roles,
      domains,
      userID: state.userDetails.user ? state.userDetails.user.id : undefined
    };

    // }
  },
  {
    Get,
    Post,
    Put,
    Patch,
    Delete,
    BulkDelete,
    progress,
    hideModal,
    updateDataFromWebSocket,
    ticketUpdateFromWebsocket,
    showTicketNotification,
    updateScreenShotDataFromWebSocket,
    updateNotificationCountFromWebSocket,
    updateHeaderNotification
  }
  // state => ({
  //   authStatus: state.userDetails.auth,
  //   roles: state.userDetails.user ? [...state.userDetails.user.roles] : ["General"],
  // }),
  // { Get, Post, Put, Delete, progress, hideModal }
)(App);
