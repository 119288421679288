/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { SearchTagRefresh, Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

const selector = formValueSelector("SelectedPlaylists");

class PlaylistHeader extends Component {
  state = { dropdownOpen: false };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  render() {
    const { refreshing, roles, permission } = this.props;
    return (
      <div className="d-flex contentHeader">
        <div className="mr-auto">
          <RouteAllowed roles={roles} permission={permission.playlist.creates}>
            <Link to="/playlist/create" className="mr-auto">
              <button
                className="primaryButton primaryButton--contentHeader"
                // onClick={handleSubmit(values => this.handleLogin(values))}
                type="submit"
              >
                <i className="fa fa-plus mr-2" />
                Add playlist
              </button>{" "}
            </Link>
          </RouteAllowed>
        </div>
        {/* <StatusButton
              handleStatus={this.props.handleStatusFilter}
              filterStatus={this.props.filterStatus}
            /> */}
        <div className="d-flex flex-row-reverse">
          <VisibleColumnToggler
            hiddenColumns={this.props.hiddenColumns}
            toggleHiddenColumns={this.props.toggleHiddenColumns}
            columnToggleProps={this.props.columnToggleProps}
          />
          {this.props.selectedPlaylists.length > 0 ? (
            <div className="d-flex">
              <RouteAllowed roles={roles} permission={permission.playlist.edit}>
                <Tooltip
                  item={
                    <div
                      className={`${
                        this.props.disableArchiveIcon
                          ? "iconButton iconButton--disabled"
                          : "iconButton"
                      } `}
                      onClick={() =>
                        this.props.handleArchiveItems(
                          this.props.selectedPlaylists,
                          this.props.disableArchiveIcon
                        )
                      }
                    >
                      <div className="fa fa-archive " />
                    </div>
                  }
                  id="header-content-archive"
                  tooltipContent="Archive"
                />
              </RouteAllowed>
              <RouteAllowed
                roles={roles}
                permission={permission.playlist.delete}
              >
                <Tooltip
                  item={
                    <div
                      className="iconButton mr-2"
                      onClick={() =>
                        this.props.handleDeletePlaylists(
                          this.props.selectedPlaylists
                        )
                      }
                    >
                      <div className="fa fa-trash " />
                    </div>
                  }
                  id="header-content-delete"
                  tooltipContent="Delete"
                />
              </RouteAllowed>
            </div>
          ) : (
            <Tooltip
              item={
                <div
                  className="iconButton"
                  onClick={() => this.props.handleRefresh()}
                >
                  {" "}
                  <i
                    className={`bx bx-revision ${
                      refreshing ? "refresh-rotate " : ""
                    }`}
                  />
                </div>
              }
              tooltipContent="Refresh"
              id="header-campaign-refresh"
            />
          )}
          <SearchTagRefresh
            ref={this.props.searchRef}
            handleSearch={this.props.handleSearch}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  selectedPlaylists: selector(state, "playlistSelected") || []
}))(PlaylistHeader);
