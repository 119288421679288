import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Form,
  FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";
import {
  reduxForm,
  Field,
  formValueSelector,
  SubmissionError,
  clearSubmitErrors
} from "redux-form";

import { MultipleSelect } from "../../../core/form/searchSelect";
import { inequalityOptions, contextOptions } from "../utils/constant";

const selector = formValueSelector("ContextForm");

// eslint-disable-next-line react/prefer-stateless-function
class ContextModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { props } = this;
    if (Object.keys(get(props, "context", {})).length > 0) {
      this.props.initialize({
        ...props.context
      });
    }
  }

  onClickSave = values => {
    const formattedValues = {
      type: { value: "temperature", label: "temperature" },
      ...values
    };
    const resulthasError = this.validate(values);
    if (resulthasError) {
      throw new SubmissionError({
        _error: "Please fill in all the fields"
      });
    } else {
      this.props.handleFormDataChange(formattedValues, "context");
      this.props.destroy();
      this.props.toggleContextModal();
    }
  };

  validate = values => {
    if (
      !values.unit ||
      !values.tempfield ||
      !values.temp1 ||
      !values.inequality1
    ) {
      return true;
    }
    if (values.tempfield === "two") {
      if (!values.temp2 || !values.inequality2) {
        return true;
      }
    }
  };

  clearFormValues = () => {
    this.props.handleFormDataChange(undefined, "context");
    this.props.destroy();
    this.props.toggleContextModal();
  };

  render() {
    const { error } = this.props;
    return (
      <div>
        {this.props.children}
        <Modal
          isOpen={this.props.contextModal}
          className={this.props.className}
        >
          <ModalHeader className="controlModal">
            Set Context
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.toggleContextModal()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="globalForm">
            <React.Fragment>
              <Form>
                {/* {get(this.props, "contextValue.value", "") ===
                  "temperature" && ( */}
                <div className="temperatureWrapper">
                  <FormGroup>
                    <div className="tempType">
                      <Label for="unit" className="mt-1 mb-2">
                        {" "}
                        Temperature Unit
                      </Label>
                      <div className="radio-toolbar">
                        <Field
                          name="unit"
                          id="celsius"
                          component="input"
                          type="radio"
                          value="celsius"
                        />
                        <Label htmlFor="celsius" className="label mr-4">
                          Celsius
                        </Label>
                        <Field
                          name="unit"
                          id="fahrenheit"
                          component="input"
                          type="radio"
                          value="fahrenheit"
                        />
                        <Label htmlFor="fahrenheit" className="label">
                          Fahrenheit
                        </Label>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="tempType">
                      <Label for="temperature" className="mb-2">
                        Set Temperature
                      </Label>
                      <div className="radio-toolbar">
                        <Field
                          name="tempfield"
                          id="one"
                          component="input"
                          type="radio"
                          value="one"
                        />
                        <Label htmlFor="one" className="label mr-4">
                          One
                        </Label>
                        <Field
                          name="tempfield"
                          id="two"
                          component="input"
                          type="radio"
                          value="two"
                        />
                        <Label htmlFor="two" className="label">
                          Two
                        </Label>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="setTemperature">
                    {this.props.tempfield === "one" && (
                      <div className="d-flex">
                        <div className="d-flex temperatureOne ">
                          <Field
                            component="input"
                            name="temp1"
                            type="number"
                            className="modal-duration ml-1 mr-2 mb-2 "
                            style={{
                              width: "60px",
                              height: "38px",
                              textAlign: "center",
                              fontSize: "14px"
                            }}
                            // validate={required}
                            placeholder="Temp"
                          />
                          <Field
                            component={MultipleSelect}
                            searchable
                            placeholder="Select"
                            name="inequality1"
                            options={inequalityOptions}
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                        <div className="p-0">
                          <small className="mt-2 currentTemp ">
                            Current Temperature
                          </small>
                        </div>
                      </div>
                    )}
                    {this.props.tempfield === "two" && (
                      <div className="temperatureDisplay">
                        <div className="d-flex temperatureTwo">
                          <Field
                            component="input"
                            name="temp1"
                            type="number"
                            className="modal-duration ml-1 mr-2 mb-2"
                            style={{
                              width: "60px",
                              height: "38px",
                              textAlign: "center",
                              fontSize: "14px"
                            }}
                            placeholder="Temp"
                            // validate={required}
                          />
                          <Field
                            component={MultipleSelect}
                            searchable
                            placeholder="Select"
                            name="inequality1"
                            options={inequalityOptions}
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                        <div className=" p-0">
                          <small className="currentTemp ">
                            Current Temperature
                          </small>
                        </div>
                        <div className="d-flex temperatureTwoRightInput">
                          <Field
                            component={MultipleSelect}
                            searchable
                            placeholder="Select"
                            name="inequality2"
                            options={inequalityOptions}
                            style={{ fontSize: "14px" }}
                          />
                          <Field
                            component="input"
                            name="temp2"
                            type="number"
                            className="modal-duration ml-2 mb-2"
                            style={{
                              width: "60px",
                              height: "38px",
                              textAlign: "center",
                              fontSize: "14px"
                            }}
                            placeholder="Temp"
                            // validate={required}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {error && <small style={{ color: "red" }}>{error}</small>}
              </Form>
            </React.Fragment>
          </ModalBody>
          <ModalFooter className="d-flex">
            <button
              className="btn-outline-primary"
              onClick={() => this.props.toggleContextModal()}
            >
              Cancel
            </button>
            <button
              className="p-2 btn btn-info"
              onClick={() => this.clearFormValues()}
              disabled={!this.props.contextValue}
            >
              Reset
            </button>
            <button
              className="primaryButton mt-0"
              onClick={this.props.handleSubmit(values =>
                this.onClickSave(values)
              )}
            >
              Set
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const ContextModalComponent = reduxForm({
  form: "ContextForm",
  onChange: (values, dispatch, props) => {
    if (props.error) dispatch(clearSubmitErrors("ContextForm"));
  }
})(ContextModal);

export default connect(
  state => ({
    contextValue: selector(state, "type"),
    tempfield: selector(state, "tempfield")
  }),
  {}
)(ContextModalComponent);
