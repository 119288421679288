import React from "react";
import { Link } from "react-router-dom";
import { toLower, startCase } from "lodash";
import { Tooltip } from "../../../core";

export function columns(toggleRejectModal) {
  return [
    {
      dataField: "mac_address",
      text: "Mac Address",
      columnName: "name"
    },
    {
      dataField: "actionButtons",
      text: "",
      style: { position: "relative" },
      columnName: "isSelected",
      // headerFormatter: () => (
      //   <div>
      //     <button
      //       type="button"
      //       className="float-right btn-info contextButton"
      //       name="context"
      //       style={{
      //         fontSize: ".875rem",
      //         padding: "7px"
      //       }}
      //     >
      //       Accept
      //     </button>
      //   </div>
      // ),
      formatter: (name, row) => (
        <div>
          <button
            type="button"
            className="deviceListActionButton btn-info contextButton"
            name="context"
            style={{
              fontSize: ".875rem",
              padding: "7px"
            }}
            onClick={() => toggleRejectModal(row.mac_address)}
          >
            Reject
          </button>
        </div>
      )
    }
  ];
}
