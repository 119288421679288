/** Studio Reducer Import  */
import {
  SelectedContent,
  addToSelectedList,
  clearSelectedList
} from "./studioRedux";
import {
  Resources,
  campaign,
  resolutions,
  campaignDetails,
  campaignEdit,
  archiveCampaign,
  totalCampaign,
  groupDefaultCampaignSetter,
  groupDefaultCampaignRemover
} from "./campaignRedux";

import {
  newCampaign,
  updateCampaign,
  resetCampaign
} from "./campaignStudioReducer";

const campaignReducer = {
  SelectedContent,
  campaign,
  resolutions,
  campaignDetails,
  campaignEdit,
  newCampaign,
  archiveCampaign,
  totalCampaign,
  groupDefaultCampaignSetter,
  groupDefaultCampaignRemover
};

export {
  campaignReducer,
  addToSelectedList,
  clearSelectedList,
  updateCampaign,
  resetCampaign,
  Resources,
  archiveCampaign
};
