import { connect } from "react-redux";
import React from "react";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { initialize } from "redux-form";
import { Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import { Resources, Logout } from "../redux";
import {
  Resources as DeviceResources,
  searchUnacceptedDevices,
  searchRejectedDevices,
  searchDeniedDevices,
  searchAcceptedDevices,
  deleteUnacceptedDevice,
  deleteDeniedDevice,
  deleteRejectedDevice,
  deleteAcceptedDevice
} from "../../network/redux/deviceRedux";
import { Resources as DomainResources } from "../redux/domainRedux";

import { Patch } from "../../../constant/thunk";
import { Dialog } from "../../../core";
import UnAcceptedDeviceListTable from "../modules/unacceptedDeviceListTable";
import AcceptedDeviceListTable from "../modules/acceptedDeviceListTable";
import DeniedDeviceListTable from "../modules/deniedDeviceListTable";
import RejectedDeviceListTable from "../modules/rejectedDeviceListTable";
import { DeviceListHeader } from "./deviceListheader";
import { showNotification } from "../../../core/modal/toster";

class DeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;

    this.state = {
      rejectModal: false,
      activeTab: "unaccepted",
      isLoading: true,
      acceptedDeviceLoading: true,
      macAddress: undefined,
      acceptModal: false,
      enableNotification: false,
      type: undefined,
      selectedDomain: "",
      checked: false,
      searchVal: "",
      searchAcceptedDevice: "",
      searchDeniedDevice: "",
      searchRejectedDevice: "",
      unacceptedRefreshing: false,
      acceptedRefreshing: false,
      unAcceptedModalDelete: false,
      deniedDeviceLoading: true,
      deniedRefreshing: false,
      rejectedRefreshing: false,
      rejectedDeviceLoading: true,
      notificationMessage:""
    };
    this.page = 1;
    this.loadUnAcceptedDevices(this.page);

    this.loadDomains();
  }

  toggleTabs = activeTab => {
    this.setState({ activeTab , searchVal: "",  searchAcceptedDevice: "",
    searchDeniedDevice: "",
    searchRejectedDevice: ""});
  };

  loadUnAcceptedDevices = () => {
    this.setState({ isLoading: true });
    this.props
      .Get(DeviceResources.getUnacceptedDevices)
      .then(() => {
        if (this.state.unacceptedRefreshing) {
          this.props.showNotification("Refreshed", "success");
        }
      })
      .then(() =>
        this.setState({ isLoading: false, unacceptedRefreshing: false })
      );
  };

  loadAcceptedDevices = () => {
    this.setState({ acceptedDeviceLoading: true });

    const { getAcceptedDevices } = DeviceResources;
    getAcceptedDevices.url = `/devices/accepted_minions_key_list`;
    this.props
      .Get(DeviceResources.getAcceptedDevices)
      .then(() => {
        if (this.state.acceptedRefreshing) {
          this.props.showNotification("Refreshed", "success");
        }
      })
      .then(() =>
        this.setState({
          acceptedDeviceLoading: false,
          acceptedRefreshing: false
        })
      );
  };

  loadDeniedDevices = () => {
    this.setState({
      deniedDeviceLoading: true
    });
    this.props
      .Get(DeviceResources.getDeniedDevices)
      .then(() => {
        if (this.state.deniedRefreshing) {
          this.props.showNotification("Refreshed", "success");
        }
      })
      .then(() =>
        this.setState({
          deniedDeviceLoading: false,
          deniedRefreshing: false
        })
      );
  };

  loadRejectedDevices = () => {
    this.setState({
      rejectedDeviceLoading: true
    });
    this.props
      .Get(DeviceResources.getRejectedDevices)
      .then(() => {
        if (this.state.rejectedRefreshing) {
          this.props.showNotification("Refreshed", "success");
        }
      })
      .then(() =>
        this.setState({
          rejectedDeviceLoading: false,
          rejectedRefreshing: false
        })
      );
  };

  handleRefreshUnAcceptedDevice = () => {
    this.setState({ unacceptedRefreshing: true, searchVal: "" });
    if (!this.state.unacceptedRefreshing) {
      this.loadUnAcceptedDevices();
    }
  };

  handleRefreshAcceptedDevice = () => {
    this.setState({ acceptedRefreshing: true, searchAcceptedDevice: "" });
    if (!this.state.acceptedRefreshing) {
      this.loadAcceptedDevices();
    }
  };

  handleRefreshDeniedDevice = () => {
    this.setState({ deniedRefreshing: true, searchDeniedDevice: "" });
    if (!this.state.deniedRefreshing) {
      this.loadDeniedDevices();
    }
  };

  handleRefreshRejectedDevice = () => {
    this.setState({ rejectedRefreshing: true, searchRejectedDevice: "" });
    if (!this.state.rejectedRefreshing) {
      this.loadRejectedDevices();
    }
  };

  rejectAcceptedDevices = () => {
    this.setState({enableNotification:true, notificationMessage:`Rejecting ${this.state.macAddress} `})

    const { macAddress } = this.state;
    const { rejectAcceptedDevices } = DeviceResources;
    const { acceptedDevices, acceptDevicesFormattedList } = this.props;

    rejectAcceptedDevices.url = `/devices/reject_minion_keys?mac_address=${macAddress}`;
    this.props.Delete(rejectAcceptedDevices).then(() => {
      const list = acceptedDevices.filter(
        item => item.mac_address !== macAddress
      );
      const formattedList = acceptDevicesFormattedList.filter(
        item => item.mac_address !== macAddress
      );
      this.props.searchAcceptedDevices(list);
      this.props.deleteAcceptedDevice(formattedList);
      this.setState({enableNotification:false, notificationMessage:""})

    });
    this.toggleRejectModal(undefined);
  };

  handleDevicesSearchAndDeletion = (action, request, fn = undefined) => {
    const { macAddress, activeTab } = this.state;
    switch (activeTab) {
      case "unaccepted": {
        const {
          unAcceptedDeviceList,
          unAcceptedDeviceFormattedList
        } = this.props;

        action(request).then(() => {
          const list = unAcceptedDeviceList.filter(
            item => item.mac_address !== macAddress
          );
          const formattedList = unAcceptedDeviceFormattedList.filter(
            item => item.mac_address !== macAddress
          );
          this.props.searchUnacceptedDevices(list);

          this.props.deleteUnacceptedDevice(formattedList);
          this.setState({enableNotification:false, notificationMessage:""})

        });

        break;
      }
      case "denied": {
        const { deniedDevices, deniedDevicesFormattedList } = this.props;

        action(request).then(() => {
          const list = deniedDevices.filter(
            item => item.mac_address !== macAddress
          );
          const formattedList = deniedDevicesFormattedList.filter(
            item => item.mac_address !== macAddress
          );
          this.props.searchDeniedDevices(list);

          this.props.deleteDeniedDevice(formattedList);
          this.setState({enableNotification:false, notificationMessage:""})

        });
        break;
      }
      case "rejected": {
        const { rejectedDevices, rejectedDevicesFormattedList } = this.props;

        action(request).then(() => {
          const list = rejectedDevices.filter(
            item => item.mac_address !== macAddress
          );
          const formattedList = rejectedDevicesFormattedList.filter(
            item => item.mac_address !== macAddress
          );
          this.props.searchRejectedDevices(list);

          this.props.deleteRejectedDevice(formattedList);
          this.setState({enableNotification:false, notificationMessage:""})

        });
        break;
      }
      default: {
        return 0;
      }
    }
  };

  acceptDevices = async () => {

    const { macAddress, selectedDomain } = this.state;
    if (selectedDomain === "") {
      return 0;
    }
    this.setState({ enableNotification: true, notificationMessage:" Accepting device and assigning it to a domain " });

    const request = DeviceResources.acceptDevices;
    request.body = request.bodyFunction(macAddress, selectedDomain);

    this.handleDevicesSearchAndDeletion(
      this.props.Post,
      request,
      this.loadAcceptedDevices
    );
    this.setState({ selectedDomain: "" });
    this.toggleAcceptModal(undefined, undefined);
  };

  toggleRejectModal = macAddress => {
    this.setState(state => ({ rejectModal: !state.rejectModal, macAddress }));
  };

  toggleAcceptModal = (macAddress, type) => {
    this.setState(state => ({
      acceptModal: !state.acceptModal,
      macAddress,
      type,
      checked: false,
      selectedDomain: ""
    }));
  };

  loadDomains = () => {
    const { getDomains } = DomainResources;
    this.props.Get(getDomains);
  };

  selectDomain = e => {
    this.setState({ selectedDomain: e.value });
  };

  handleAgreeToAcceptDevice = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  filterUnAcceptedDevice = e => {
    const { unAcceptedDeviceFormattedList } = this.props;
    this.setState({ searchVal: e.target.value });

    const formattedList = unAcceptedDeviceFormattedList.filter(item =>
      item.mac_address.includes(e.target.value)
    );

    this.props.searchUnacceptedDevices(formattedList);
  };

  filterAcceptedDevice = e => {
    const { acceptDevicesFormattedList } = this.props;
    this.setState({ searchAcceptedDevice: e.target.value });

    const formattedList = acceptDevicesFormattedList.filter(item =>
      item.mac_address.includes(e.target.value)
    );

    this.props.searchAcceptedDevices(formattedList);
  };

  filterDeniedDevice = e => {
    const { deniedDevicesFormattedList } = this.props;
    this.setState({ searchDeniedDevice: e.target.value });

    const formattedList = deniedDevicesFormattedList.filter(item =>
      item.mac_address.includes(e.target.value)
    );

    this.props.searchDeniedDevices(formattedList);
  };

  filterRejectedDevice = e => {
    const { rejectedDevicesFormattedList } = this.props;
    this.setState({ searchRejectedDevice: e.target.value });

    const formattedList = rejectedDevicesFormattedList.filter(item =>
      item.mac_address.includes(e.target.value)
    );

    this.props.searchRejectedDevices(formattedList);
  };

  toggleDeleteUnAcceptedDeviceModal = macAddress => {
    this.setState(state => ({
      unAcceptedModalDelete: !state.unAcceptedModalDelete,
      macAddress
    }));
  };

  handleDeleteUnAcceptedDevice = () => {
    this.setState({enableNotification:true, notificationMessage:`Deleting ${this.state.macAddress} `})
    const { macAddress } = this.state;
    const { deleteAcceptedDevices } = DeviceResources;
    deleteAcceptedDevices.url = `/devices/delete_minion_key?mac_address=${macAddress}`;
    this.handleDevicesSearchAndDeletion(
      this.props.Delete,
      deleteAcceptedDevices
    );
    this.toggleDeleteUnAcceptedDeviceModal(undefined);
  };

  render() {
    const { permission, roles } = this.props;
    const { activeTab } = this.state;
    return (
      <div className="mainPage">
        <div className="contentSection">
          <Nav tabs className="mb-3">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "unaccepted"
                })}
                onClick={() => {
                  this.toggleTabs("unaccepted");
                  this.loadUnAcceptedDevices(this.page);
                }}
              >
                Unaccepted Devices
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "denied" })}
                onClick={() => {
                  this.toggleTabs("denied");
                  this.loadDeniedDevices(this.page);
                }}
              >
                Denied Devices
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "rejected" })}
                onClick={() => {
                  this.toggleTabs("rejected");
                  this.loadRejectedDevices(this.page);
                }}
              >
                Rejected Devices
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "accepted" })}
                onClick={() => {
                  this.toggleTabs("accepted");
                  this.loadAcceptedDevices(this.page);
                }}
              >
                Accepted Devices
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="unaccepted">
              <DeviceListHeader
                value={this.state.searchVal}
                onChange={this.filterUnAcceptedDevice}
                handleRefresh={this.handleRefreshUnAcceptedDevice}
                refreshing={this.state.unacceptedRefreshing}
              />
              <UnAcceptedDeviceListTable
                isLoading={this.state.isLoading}
                toggleAcceptModal={this.toggleAcceptModal}
                toggleDeleteUnAcceptedDeviceModal={
                  this.toggleDeleteUnAcceptedDeviceModal
                }
                permission={permission}
                roles={roles}
                {...this.props}
              />
            </TabPane>
            <TabPane tabId="denied">
              <DeviceListHeader
                refreshing={this.state.acceptedRefreshing}
                handleRefresh={this.handleRefreshDeniedDevice}
                value={this.state.searchDeniedDevice}
                onChange={this.filterDeniedDevice}
              />
              <DeniedDeviceListTable
                deviceList={this.props.deniedDevices}
                isLoading={this.state.deniedDeviceLoading}
                toggleAcceptModal={this.toggleAcceptModal}
                toggleDeleteUnAcceptedDeviceModal={
                  this.toggleDeleteUnAcceptedDeviceModal
                }
                permission={permission}
                roles={roles}
                {...this.props}
              />
            </TabPane>{" "}
            <TabPane tabId="rejected">
              <DeviceListHeader
                refreshing={this.state.acceptedRefreshing}
                handleRefresh={this.handleRefreshRejectedDevice}
                value={this.state.searchRejectedDevice}
                onChange={this.filterRejectedDevice}
              />
              <RejectedDeviceListTable
                deviceList={this.props.rejectedDevices}
                toggleDeleteUnAcceptedDeviceModal={
                  this.toggleDeleteUnAcceptedDeviceModal
                }
                isLoading={this.state.rejectedDeviceLoading}
                {...this.props}
              />
            </TabPane>
            <TabPane tabId="accepted">
              <DeviceListHeader
                refreshing={this.state.acceptedRefreshing}
                handleRefresh={this.handleRefreshAcceptedDevice}
                value={this.state.searchAcceptedDevice}
                onChange={this.filterAcceptedDevice}
              />

              <AcceptedDeviceListTable
                acceptedDevices={this.props.acceptedDevices}
                toggleRejectModal={this.toggleRejectModal}
                isLoading={this.state.acceptedDeviceLoading}
                {...this.props}
              />
            </TabPane>
          </TabContent>
          <Dialog
            showHandler={false}
            externalControl
            modal={this.state.rejectModal}
            toggleModal={this.toggleRejectModal}
            body={
              <span>
                <small>Are you sure you want to reject </small>
                <strong>{this.state.macAddress}</strong>?{" "}
                <small>
                  Rejecting a device would remove it from the network and
                  Publish.
                </small>
              </span>
            }
            primaryMethod={this.rejectAcceptedDevices}
            toggleModel={this.toggleRejectModal}
            title="Reject Device"
          />
          <Dialog
            showHandler={false}
            externalControl
            modal={this.state.acceptModal}
            toggleModal={this.toggleAcceptModal}
            isDisabled={
              (!this.state.checked &&
                (this.state.type === "denied" ||
                  this.state.type === "rejected")) ||
              this.state.selectedDomain === ""
            }
            body={
              <div className="deviceModalBody">
                <div>
                  <span>
                    <small>
                      Assign <strong>{this.state.macAddress}</strong> to a
                      domain{" "}
                    </small>
                  </span>
                  <Select
                    isSearchable
                    required
                    onChange={e => this.selectDomain(e)}
                    options={this.props.domainList.map(item => ({
                      label: item.attributes.name,
                      value: item.id
                    }))}
                  />
                  {(this.state.type === "rejected" ||
                    this.state.type === "denied") && (
                    <Label className="checkBoxWrapper mr-4">
                      <input
                        type="checkbox"
                        checked={this.state.checked}
                        onChange={this.handleAgreeToAcceptDevice}
                      />
                      <p>
                        {`I understand that the minion's key is in ${this.state.type} list.`}
                      </p>
                      <span className="checkmark" />
                    </Label>
                  )}
                </div>
              </div>
            }
            primaryMethod={this.acceptDevices}
            toggleModel={this.toggleAcceptModal}
            title="Select Domain"
          />
          <Dialog
            showHandler={false}
            externalControl
            modal={this.state.unAcceptedModalDelete}
            toggleModal={this.toggleDeleteUnAcceptedDeviceModal}
            body={
              <span>
                <small>Are you sure you want to delete </small>
                <strong>{this.state.macAddress}</strong>?
              </span>
            }
            primaryMethod={this.handleDeleteUnAcceptedDevice}
            toggleModel={this.toggleDeleteUnAcceptedDeviceModal}
            title="Delete Device"
          />
          {this.state.enableNotification && (
            <div
              id="notification_toast-flash"
              className="notification_toast info animated fadeInUp"
            >
              <p>
               {this.state.notificationMessage}
                <i className="fa fa-spinner fa-spin" />
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // const { list, status, loading, hasError, meta } = state.unAcceptedDeviceList;
  const { list, formattedList } = state.unacceptedDevices;
  const acceptedDevices = state.acceptedDevices.list;
  const acceptDevicesFormattedList = state.acceptedDevices.formattedList;
  const deniedDevices = state.deniedDevices.list;
  const deniedDevicesFormattedList = state.deniedDevices.formattedList;
  const rejectedDevices = state.rejectedDevices.list;
  const rejectedDevicesFormattedList = state.rejectedDevices.formattedList;

  const domainList = state.domainList.list;
  return {
    unAcceptedDeviceList: list !== undefined ? list : [],
    unAcceptedDeviceFormattedList: formattedList || [],
    acceptedDevices: acceptedDevices !== undefined ? acceptedDevices : [],
    acceptDevicesFormattedList: acceptDevicesFormattedList || [],
    deniedDevices: deniedDevices || [],
    deniedDevicesFormattedList: deniedDevicesFormattedList || [],
    rejectedDevices: rejectedDevices || [],
    rejectedDevicesFormattedList: rejectedDevicesFormattedList || [],
    domainList: domainList || []
  };
}

export default connect(
  mapStateToProps,
  {
    Patch,
    Logout,
    initialize,
    searchUnacceptedDevices,
    searchRejectedDevices,
    searchDeniedDevices,
    searchAcceptedDevices,
    deleteUnacceptedDevice,
    deleteDeniedDevice,
    deleteRejectedDevice,
    deleteAcceptedDevice,
    showNotification
  }
)(DeviceList);
