/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import StatusButton from "../../../core/buttons/statusbutton";
import RouteAllowed from "../../../helper/isRouteAllowed";

import { SearchTagRefresh, Tooltip } from "../../../core";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";
import SelectLayoutModal from "./selectLayoutModal";

const selector = formValueSelector("SelectedCampaigns");
const layoutSelector = formValueSelector("CampaignLayout");
class CampaignHeader extends Component {
  state = { dropdownOpen: false, isLayoutModalOpen: false };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  toggleSelectLayoutModal = () => {
    this.setState(prevState => ({
      isLayoutModalOpen: !prevState.isLayoutModalOpen
    }));
  };

  render() {
    const { refreshing, roles, permission, layout } = this.props;
    return (
      <div className="d-flex contentHeader">
        <div className="mr-auto">
          <RouteAllowed roles={roles} permission={permission.campaign.creates}>
            <button
              className="primaryButton primaryButton--contentHeader"
              onClick={() => this.toggleSelectLayoutModal()}
              type="submit"
            >
              <i className="fa fa-plus mr-2" />
              Add campaign
            </button>{" "}
          </RouteAllowed>
        </div>
        <SearchTagRefresh
          ref={this.props.forwardRef}
          handleSearch={this.props.handleSearch}
          {...this.props}
        />
        {this.props.selectedCampaigns.length > 0 ? (
          <div className="d-flex">
            <RouteAllowed roles={roles} permission={permission.campaign.edit}>
              <Tooltip
                item={
                  <div>
                    <div
                      className="iconButton fa fa-trash "
                      onClick={() =>
                        this.props.handleDeleteCampaigns(
                          this.props.selectedCampaigns
                        )
                      }
                    />
                  </div>
                }
                tooltipContent="Delete"
                id="header-campaign-delete"
              />
            </RouteAllowed>
            <RouteAllowed roles={roles} permission={permission.campaign.edit}>
              <Tooltip
                item={
                  <div>
                    <div
                      className={`${
                        this.props.disableArchiveIcon
                          ? "iconButton iconButton--disabled"
                          : "iconButton "
                      } fa fa-archive`}
                      onClick={() =>
                        this.props.handleArchiveItems(
                          this.props.selectedCampaigns,
                          this.props.disableArchiveIcon
                        )
                      }
                    />
                  </div>
                }
                id="header-campaign-archive"
                tooltipContent="Archive"
              />
            </RouteAllowed>
          </div>
        ) : (
          <Tooltip
            item={
              <div
                className="iconButton"
                onClick={() => this.props.handleRefresh()}
              >
                <i
                  className={`bx bx-revision ${
                    refreshing ? "refresh-rotate " : ""
                  }`}
                />{" "}
              </div>
            }
            id="header-content-refresh"
            tooltipContent="Refresh"
          />
        )}

        <StatusButton
          handleStatus={this.props.handleStatus}
          filterStatus={this.props.filterStatus}
        />
        <VisibleColumnToggler
          hiddenColumns={this.props.hiddenColumns}
          toggleHiddenColumns={this.props.toggleHiddenColumns}
          columnToggleProps={this.props.columnToggleProps}
        />
        <SelectLayoutModal
          isLayoutModalOpen={this.state.isLayoutModalOpen}
          toggleSelectLayoutModal={this.toggleSelectLayoutModal}
          customCreate={
            <Link
              to={{
                pathname: "/campaign/create",
                state: { layout }
              }}
            >
              <button className="primaryButton mt-0">Create</button>
            </Link>
          }
          layout={this.props.layout}
        />
      </div>
    );
  }
}
export default connect(state => ({
  selectedCampaigns: selector(state, "campaignsSelected") || [],
  layout: layoutSelector(state, "selectedLayout") || "landscape"
}))(CampaignHeader);
