export const Resources = {
  getAllDevices: {
    url: "/devices/device_list_metrics",
    asyncActions: {
      init: "getAllDevices_INIT",
      success: "getAllDevices_SUCCESS",
      error: "getAllDevices_ERROR"
    }
  }
};

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined,
  diskUsages: undefined,
  filterStatus: undefined
};

export function deviceListMetrics(state = { ...initialState }, action = {}) {
  const { init, success, error } = Resources.getAllDevices.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, meta },
        status,
        statusText
      } = action.payload;

      return {
        list: data,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        unassignedDevicesList: []
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }
    default: {
      return state;
    }
  }
}
