export const BrandTagResources = {
    getContent: (partyId) => ({
      url: `/parties/${partyId}/brands_list`,
      asyncActions: {
        init: "getBrandTag_INIT",
        success: "getBrandTag_SUCCESS",
        error: "getBrandTag_ERROR"
      }
    })
  };
  
  const initialState = {
    list: undefined,
    status: undefined,
    statusText: undefined,
    deleted: undefined,
    edited: undefined,
    hasList: undefined,
    loading: undefined,
    hasError: undefined
  };
  
  export function BrandTag(state = { initialState }, action = {}) {
    const { init, success, error } = BrandTagResources.getContent().asyncActions;
  
    switch (action.type) {
      case init: {
        return {
          ...state,
          loading: true,
          hasError: undefined
        };
      }
  
      case success: {
        const {
          data,
          status,
          statusText
        } = action.payload;
        return {
          list: data,
          status,
          statusText,
          deleted: [],
          edited: [],
          hasList: true,
          loading: false,
          hasError: false
        };
      }
  
  
      case error: {
        return {
          ...state,
          loading: false,
          hasError: true
        };
      }
  
      default: {
        return state;
      }
    }
  }
  