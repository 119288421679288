import React from "react";

export const standardTemplate = data => {
  const {
    fontSize,
    imgWidth,
    rowHeight,
    temprData,
    location,
    days,
    forecastFontSize,
    forecastFontSizeDay,
    forecastFontSizeLoc,
    forecastImgWidth
  } = data;
  switch (days) {
    case 5:
      return (
        <div className="WidgetwrapperWeather">
          <div className="d-flex" style={{ height: `${rowHeight}px` }}>
            <div className="d-flex justify-content-start">
              <div className="textSection">
                <h5
                  style={{
                    color: "#ffffff",
                    fontSize: `${forecastFontSize}rem`
                  }}
                >
                  {temprData[0].date}
                </h5>
                <h4 style={{ fontSize: `${forecastFontSizeLoc}rem` }}>
                  {location}
                </h4>
              </div>
            </div>
            <div className="d-flex justify-content-end ml-auto weather-icon-big-mr mr-5 ">
              <div className="imgWrapper">
                <img
                  src={require(`../../../assets/picture/weather/${temprData[0].icon}.png`)}
                  alt="sun"
                  className="img-fluid"
                  style={{ width: `${imgWidth}px` }}
                />
              </div>
            </div>
          </div>
          <div
            className=" justify-content-start time"
            style={{ height: `${rowHeight}px` }}
          >
            <h3 style={{ fontSize: `${fontSize}rem`, display: "inline" }}>
              {temprData[0].temp}
              <sup>°</sup>

              {/* <i>{temprData[0].unit}</i> */}
            </h3>
          </div>
          <div className="imgSection" style={{ height: `${rowHeight}px` }}>
            <div className="smallWeatherSection center-the-element-vertically">
              <div className="row">
                <div className="col-md-3 col-sm-6 temperatureUnit">
                  <div className="Day1">
                    <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                      {temprData[1].day}
                    </span>
                    <div className="temperatureUnit__img">
                      <img
                        src={require(`../../../assets/picture/weather/${temprData[1].icon}.png`)}
                        alt="raining"
                        style={{
                          width: `${forecastImgWidth}px`,
                          height: "auto"
                        }}
                      />
                    </div>
                    <b style={{ fontSize: `${forecastFontSize}rem` }}>
                      {temprData[1].temp}
                      <sup>°</sup>
                      {/* <i>{temprData[1].unit}</i> */}
                    </b>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 temperatureUnit">
                  <div className="Day1">
                    <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                      {temprData[2].day}
                    </span>
                    <div className="temperatureUnit__img">
                      <img
                        src={require(`../../../assets/picture/weather/${temprData[2].icon}.png`)}
                        alt="sun"
                        style={{
                          width: `${forecastImgWidth}px`,
                          height: "auto"
                        }}
                      />
                    </div>
                    <b style={{ fontSize: `${forecastFontSize}rem` }}>
                      {temprData[2].temp}
                      <sup>°</sup>
                      {/* <i>{temprData[2].unit}</i> */}
                    </b>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 temperatureUnit">
                  <div className="Day1">
                    <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                      {temprData[3].day}
                    </span>
                    <div className="temperatureUnit__img">
                      <img
                        src={require(`../../../assets/picture/weather/${temprData[3].icon}.png`)}
                        alt="raining"
                        style={{
                          width: `${forecastImgWidth}px`,
                          height: "auto"
                        }}
                      />
                    </div>
                    <b style={{ fontSize: `${forecastFontSize}rem` }}>
                      {temprData[3].temp}
                      <sup>°</sup>
                      {/* <i> {temprData[3].unit}</i> */}
                    </b>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 temperatureUnit">
                  <div className="Day1">
                    <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                      {temprData[4].day}
                    </span>
                    <div className="temperatureUnit__img">
                      <img
                        src={require(`../../../assets/picture/weather/${temprData[4].icon}.png`)}
                        alt="raining"
                        style={{
                          width: `${forecastImgWidth}px`,
                          height: "auto"
                        }}
                      />
                    </div>
                    <b style={{ fontSize: `${forecastFontSize}rem` }}>
                      {temprData[4].temp}
                      <sup>°</sup>
                      {/* <i> {temprData[4].unit}</i> */}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="WidgetwrapperWeather">
          <div className="d-flex" style={{ height: `${rowHeight}px` }}>
            <div className="d-flex justify-content-start">
              <div className="textSection">
                <h5
                  style={{
                    color: "#ffffff",
                    fontSize: `${forecastFontSize}rem`
                  }}
                >
                  {temprData[0].date}
                </h5>
                <h4 style={{ fontSize: `${forecastFontSizeLoc}rem` }}>
                  {location}
                </h4>
              </div>
            </div>
            <div className="d-flex justify-content-end ml-auto weather-icon-big-mr">
              <div className="imgWrapper">
                <img
                  src={require(`../../../assets/picture/weather/${temprData[0].icon}.png`)}
                  alt="sun"
                  className="img-fluid"
                  style={{ width: `${imgWidth}px` }}
                />
              </div>
            </div>
          </div>
          <div
            className="justify-content-start  time"
            style={{ height: `${rowHeight}px` }}
          >
            <h3 style={{ fontSize: `${fontSize}rem`, display: "inline" }}>
              {temprData[0].temp}
              <sup>°</sup>
              {/* <i>{temprData[0].unit}</i> */}
            </h3>
          </div>
          <div className="imgSection" style={{ height: `${rowHeight}px` }}>
            <div className="d-flex justify-content-around center-the-element-vertically smallWeatherSection">
              <div className="col-md-6 col-sm-6 temperatureUnit">
                <div className="Day1">
                  <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                    {temprData[1].day}
                  </span>
                  <div className="temperatureUnit__img">
                    <img
                      src={require(`../../../assets/picture/weather/${temprData[1].icon}.png`)}
                      alt="raining"
                      style={{ width: `${forecastImgWidth}px`, height: "auto" }}
                    />
                  </div>
                  <b style={{ fontSize: `${forecastFontSize}rem` }}>
                    {temprData[1].temp}
                    <sup>°</sup>

                    {/* <i>{temprData[1].unit}</i> */}
                  </b>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 temperatureUnit">
                <div className="Day1">
                  <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                    {temprData[2].day}
                  </span>
                  <div className="temperatureUnit__img">
                    <img
                      src={require(`../../../assets/picture/weather/${temprData[2].icon}.png`)}
                      alt="sun"
                      style={{ width: `${forecastImgWidth}px`, height: "auto" }}
                    />
                  </div>
                  <b style={{ fontSize: `${forecastFontSize}rem` }}>
                    {temprData[2].temp}
                    <sup>°</sup>
                    {/* <i> {temprData[2].unit}</i> */}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 1:
      return (
        <div className="widgetwrapper">
          <div className="d-flex justify-content-around center-the-element-vertically">
            <div className="flex-container">
              <div className="date-location">
                <div>
                  <img
                    src={require(`../../../assets/picture/weather/${temprData[0].icon}.png`)}
                    alt="sun"
                    className="img-fluid"
                    style={{ width: `${imgWidth}px` }}
                  />
                  <h5
                    style={{
                      color: "#ffffff",
                      fontSize: `${forecastFontSize}rem`
                    }}
                  >
                    {temprData[0].date}
                  </h5>
                  <h4 style={{ fontSize: `${forecastFontSizeLoc}rem` }}>
                    {location}
                  </h4>
                </div>
              </div>
              <div className="d-flex align-items-center">
                {" "}
                <h3 style={{ fontSize: `${fontSize}rem`, display: "inline" }}>
                  {temprData[0].temp}
                  <sup>°</sup>
                  {/* <i>{temprData[0].unit}</i> */}
                </h3>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="WidgetwrapperWeather">
          <div className="d-flex" style={{ height: `${rowHeight}px` }}>
            <div className="d-flex justify-content-start">
              <div className="textSection">
                <h5
                  style={{
                    color: "#ffffff",
                    fontSize: `${forecastFontSize}rem`
                  }}
                >
                  {temprData[0].date}
                </h5>
                <h4 style={{ fontSize: `${forecastFontSizeLoc}rem` }}>
                  {location}
                </h4>
              </div>
            </div>
            <div className="d-flex justify-content-end ml-auto weather-icon-big-mr mr-5 ">
              <div className="imgWrapper">
                <img
                  src={require(`../../../assets/picture/weather/${temprData[0].icon}.png`)}
                  alt="sun"
                  className="img-fluid"
                  style={{ width: `${imgWidth}px` }}
                />
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-start time"
            style={{ height: `${rowHeight}px` }}
          >
            <h3 style={{ fontSize: `${fontSize}rem`, display: "inline" }}>
              {temprData[0].temp}
              <sup>°</sup>
              {/* <i>{temprData[0].unit}</i> */}
            </h3>
          </div>
          <div className="imgSection" style={{ height: `${rowHeight}px` }}>
            <div className="smallWeatherSection center-the-element-vertically">
              <div className="row">
                <div className="col-md-3 col-sm-6 temperatureUnit">
                  <div className="Day1">
                    <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                      {temprData[1].day}
                    </span>
                    <div className="temperatureUnit__img">
                      <img
                        src={require(`../../../assets/picture/weather/${temprData[1].icon}.png`)}
                        alt="raining"
                        style={{
                          width: `${forecastImgWidth}px`,
                          height: "auto"
                        }}
                      />
                    </div>
                    <b style={{ fontSize: `${forecastFontSize}rem` }}>
                      {temprData[1].temp}
                      <sup>°</sup>
                      {/* <i>{temprData[1].unit}</i> */}
                    </b>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 temperatureUnit">
                  <div className="Day1">
                    <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                      {temprData[2].day}
                    </span>
                    <div className="temperatureUnit__img">
                      <img
                        src={require(`../../../assets/picture/weather/${temprData[2].icon}.png`)}
                        alt="sun"
                        style={{
                          width: `${forecastImgWidth}px`,
                          height: "auto"
                        }}
                      />
                    </div>
                    <b style={{ fontSize: `${forecastFontSize}rem` }}>
                      {temprData[2].temp}
                      <sup>°</sup>
                      {/* <i>{temprData[2].unit}</i> */}
                    </b>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 temperatureUnit">
                  <div className="Day1">
                    <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                      {temprData[3].day}
                    </span>
                    <div className="temperatureUnit__img">
                      <img
                        src={require(`../../../assets/picture/weather/${temprData[3].icon}.png`)}
                        alt="raining"
                        style={{
                          width: `${forecastImgWidth}px`,
                          height: "auto"
                        }}
                      />
                    </div>
                    <b style={{ fontSize: `${forecastFontSize}rem` }}>
                      {temprData[3].temp}
                      <sup>°</sup>
                      {/* <i> {temprData[3].unit}</i> */}
                    </b>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 temperatureUnit">
                  <div className="Day1">
                    <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                      {temprData[4].day}
                    </span>
                    <div className="temperatureUnit__img">
                      <img
                        src={require(`../../../assets/picture/weather/${temprData[4].icon}.png`)}
                        alt="raining"
                        style={{
                          width: `${forecastImgWidth}px`,
                          height: "auto"
                        }}
                      />
                    </div>
                    <b style={{ fontSize: `${forecastFontSize}rem` }}>
                      {temprData[4].temp}
                      <sup>°</sup>
                      {/* <i> {temprData[4].unit}</i> */}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
};
