import {
  Resources,
  content,
  AddItemToContentList,
  RemoveItemFromContentList,
  AddContentFromPutRequest,
  archivedContent
} from "./contentRedux";
import {
  ContentUploadModal,
  progress,
  removeProgress,
  hideModal
} from "./uploadModalReducer";
import { ContentTagResources, ContentTag } from "./TagReducer";
import { SponserTagResources, SponserTag } from "./SponserReducer";
import { PartyTagResources, PartyTag } from "./PartyReducer";
import { BrandTagResources, BrandTag } from "./BrandReducer";
import { PartyTagListResources, PartyTagList } from "./PartyTagReducer";
import {  BrandTagListResources,BrandTagList } from "./BrandTagReducer";

const contentReducer = {
  ContentUploadModal,
  content,
  ContentTag,
  archivedContent,
  SponserTag,
  PartyTag,
  BrandTag,
  PartyTagList,
  BrandTagList
};

export {
  contentReducer,
  Resources,
  progress,
  hideModal,
  removeProgress,
  AddItemToContentList,
  RemoveItemFromContentList,
  AddContentFromPutRequest,
  ContentTagResources,
  SponserTagResources, 
  PartyTagResources, 
  BrandTagResources,
  PartyTagListResources,
  BrandTagListResources
};
