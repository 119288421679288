/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { has, sortBy, findIndex, uniqBy } from "lodash";
import { template } from "handlebars";
import arrayMove from "array-move";
import {
  Screen,
  Playlist,
  Content,
  Weather,
  URL,
  Text,
  DateTime,
  RSS,
  HLS,
  Youtube,
  PicToScreen,
  Route,
  Transparent
} from "./components";
import { screenGenerator } from "./screens";
import { removeContentFromContentList, totalSeconds } from "./helper";
import { generateScreensAttributes } from "./mapUtils";

import { timeZonesList } from "../../../constant/timeZoneNUCs";

const isMobileView = window.matchMedia(
  "(max-width: 767px) and (min-width: 481px)"
);
const isMediumMobileView = window.matchMedia(
  "(max-width: 480px) and (min-width: 321px)"
);
const isSmallerMobileView = window.matchMedia("(max-width: 320px");

function generateStageHeight() {
  if (isSmallerMobileView.matches) {
    return 162;
  }
  if (isMediumMobileView.matches) {
    return 180;
  }
  if (isMobileView.matches) {
    return 198;
  }
  return 414;
}

function generateStageWidth() {
  if (isSmallerMobileView.matches) {
    return 288;
  }
  if (isMediumMobileView.matches) {
    return 320;
  }
  if (isMobileView.matches) {
    return 352;
  }
  return 736;
}

function setLoaderMargin(orientation) {
  if (
    orientation === "Landscape" &&
    (isSmallerMobileView.matches ||
      isMediumMobileView.matches ||
      isMobileView.matches)
  ) {
    return "75px auto";
  }
  return "185px auto";
}

function resetFlags() {
  return {
    WidgetIsBeingDragged: undefined,
    WeatherIsBeingDragged: undefined,
    contentIsBeingDragged: undefined,
    playlistIsBeingDragged: undefined,
    TextIsBeingDragged: undefined,
    URLIsBeingDragged: undefined,
    HLSISBeingDragged: undefined,
    DateTimeIsBeingDragged: undefined,
    RSSIsBeingDragged: undefined,
    YoutubeIsBeingDragged: undefined,
    PicToScreenIsBeingDragged: undefined,
    RouteIsBeingDragged: undefined
  };
}

function widgetToggle(flag, status) {
  const flags = resetFlags();
  flags.WidgetIsBeingDragged = status;
  flags[`${flag}IsBeingDragged`] = status;
  return { ...flags, contentToBeDropped: [], playlistToBeDropped: [] };
}

function calculateScales(screenWidth, screenHeight, studioHeight, studioWidth) {
  this.horizontalScale = screenWidth / studioWidth;
  this.verticalScale = screenHeight / studioHeight;
}

function addNewScreen() {
  this.screens = [...this.screens, new Screen()];
}

function removeScreen(screenID) {
  const { screens } = this;
  this.screens = screens.filter(item => item.localID !== screenID);
}

function setStudioDimensionAttribute(type) {
  if (type === "Portrait") {
    this.studioMaxHeight = 416;
    this.studioMaxWidth = 234;
    this.studioMinHeight = 320;
    this.studioMinWidth = 180;
    this.zoomedMaxHeight = 800;
    this.zoomedMaxWidth = 450;
    this.zoomedMinHeight = 496;
    this.zoomedMinWidth = 279;
  } else if (type === "Landscape") {
    this.studioMaxHeight = generateStageHeight();
    this.studioMaxWidth = generateStageWidth();
    this.studioMinHeight = 324;
    this.studioMinWidth = 576;
    this.zoomedMaxHeight = 810;
    this.zoomedMaxWidth = 1440;
    this.zoomedMinHeight = 504;
    this.zoomedMinWidth = 896;
  } else {
    throw Error(
      "Orientation provided to studio is not valid. It should be either Landscape or Portrait"
    );
  }
}

function setStudioDimension(mediaQueryFlag, isZoomed) {
  this.studioHeight = this.studioMaxHeight;
  this.studioWidth = this.studioMaxWidth;
  this.loaderMargin = setLoaderMargin(this.orientation);
  this.loaderFont = "25px";

  if (mediaQueryFlag) {
    this.studioHeight = this.studioMinHeight;
    this.studioWidth = this.studioMinWidth;
    this.loaderMargin = "145px auto";
    this.loaderFont = "18px";
  }
  if (isZoomed) {
    this.studioHeight = this.zoomedMaxHeight;
    this.studioWidth = this.zoomedMaxWidth;
    this.loaderMargin = "386px auto";
  }
  if (mediaQueryFlag && isZoomed) {
    this.studioHeight = this.zoomedMinHeight;
    this.studioWidth = this.zoomedMinWidth;
    this.loaderMargin = "238px auto";
  }
}

function setProperty(protoName, protoValue) {
  this[protoName] = protoValue;
}

function setOrientation(resID, resList, mediaQueryFlag) {
  if (resList.length === 0) {
    throw Error("Resolution List cannot be empty.");
  } else if (
    !(
      has(resList[0], "attributes.height") &&
      has(resList[0], "attributes.width") &&
      has(resList[0], "id")
    )
  ) {
    throw Error(
      "Resolution List is missing one or multiple properties. It is either height width or resolution. Those properties are required to lunch campaign studio. "
    );
  }
  resList.map(item => {
    if (item.id === resID) {
      this.orientation =
        item.attributes.height > item.attributes.width
          ? "Portrait"
          : "Landscape";
      this.displayResolution = `${item.attributes.width} X ${item.attributes.height}`;
      this.screenHeight = item.attributes.height;
      this.screenWidth = item.attributes.width;
      this.setStudioDimensionAttribute(this.orientation);
      // this.setStudioDimension(this.orientation, this.isZoomEnable);
      this.setStudioDimension(mediaQueryFlag, this.isZoomEnable);
    }
    return template;
  });
}

function generateScreens() {
  if (this.studioHeight === undefined) {
    throw Error("Studio height is not define");
  } else if (this.studioWidth === undefined) {
    throw Error("Studio width is not defined");
  } else if (this.screenNumber === undefined) {
    throw Error("Number of screens in campaign is undefined");
  } else if (this.orientation === undefined) {
    throw Error("Orientation is not define");
  } else {
    this.calculateScales(
      Number(this.screenWidth),
      Number(this.screenHeight),
      Number(this.studioHeight),
      Number(this.studioWidth)
    );
    /** width, height, orientation, screenCount  */
    const screenData = screenGenerator(
      Number(this.studioWidth),
      Number(this.studioHeight),
      this.orientation,
      Number(this.screenNumber),
      this.defaultTemplate,
      this.defaultScreenResolution,
      this.horizontalScale
    );
    screenData.map((item, index) => {
      const screen = new Screen();
      screen.screenX = item.x;
      screen.screenY = item.y;
      screen.width = item.width;
      screen.height = item.height;
      screen.screenPosition = Number(index + 1);
      screen.fill = item.fill;
      screen.defaultImage = item.sourceImage;
      screen.defaultImageContentType = item.defaultImageContentType;
      screen.sourceImage = item.sourceImage;
      screen.screenName = `Region-${Number(index + 1)}`;
      screen.scale = "stretch";
      /** screenWidth, screenHeight, studioHeight, studioWidth */
      screen.calculateScales(
        Number(this.screenWidth),
        Number(this.screenHeight),
        Number(this.studioHeight),
        Number(this.studioWidth)
      );
      this.screens = [...this.screens, screen];
      this.isCampaignInitialized = true;
    });
  }
}

function changeOrientation(height, width) {
  if (this.screenHeight && this.screenWidth) {
    const newWidth = width;
    const newHeight = height;

    const resolution = findIndex(this.resolutionList, {
      attributes: { height: newHeight, width: newWidth }
    });
    this.screenNumber = 1;
    this.resolution = this.resolutionList[resolution].id;
    const { id } = this.resolutionList[resolution];
    const res = this.resolutionList;
    return { id, res };
  }
  throw Error("Hight or Width is missing");
}

function updateScreen(id, data) {
  const screenIndex = findIndex(this.screens, { localID: id });
  this.screens[screenIndex] = { ...this.screens[screenIndex], ...data };
}

function swapScreens(oldIndex, newIndex) {
  const unReverseScreen = [...this.screens].reverse();
  const moveScreen = arrayMove(unReverseScreen, oldIndex, newIndex);
  const reverseMoveScreen = [...moveScreen].reverse();
  this.screens = reverseMoveScreen;
  this.screens.map((item, index) => {
    const temp = item;
    temp.screenPosition = Number(index + 1);
    return temp;
  });
}

function loadContentToBeDropped(data) {
  const newData = uniqBy([...this.contentToBeDropped, data], "id");
  this.contentToBeDropped = newData;
  Object.assign(this, { ...resetFlags(), contentIsBeingDragged: true });
}

const insertContent = (arr, index, item) => arr.splice(index, 0, ...item);

function unLoadContentToBeDropped(screenID, flag = false, index = 0) {
  const newContent = this.contentToBeDropped.map(
    (content, index) => new Content(content, index)
  );
  const screenIndex = findIndex(this.screens, { localID: screenID });
  if (flag) {
    this.screens[screenIndex].contents.splice(index, 0, ...newContent);
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      ...newContent
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.contentToBeDropped = [];
}

function removeContentFromScreen(localID, contentID) {
  const screenIndex = findIndex(this.screens, { localID });
  this.screens[screenIndex].contents = removeContentFromContentList(
    this.screens[screenIndex].contents,
    contentID
  );
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
}

function setDefaultContentOfScreen(screenID, contents) {
  const { screens } = this;
  this.screens = screens.map(screen => {
    if (screen.localID === screenID) {
      const temp = screen;
      temp.contents = contents;
      temp.calculateThumbnail();
      return temp;
    }
    return screen;
  });
}

function addScreen() {
  const { screens } = this;
  if (screens.length === 6) {
    return;
  }
  const screenData = screenGenerator(
    Number(this.studioWidth),
    Number(this.studioHeight),
    this.orientation,
    Number(screens.length + 1)
  ).pop();
  if (screenData) {
    const screen = new Screen();
    screen.screenX = screenData.x;
    screen.screenY = screenData.y;
    screen.width = screenData.width;
    screen.height = screenData.height;
    screen.screenPosition = Number(screens.length + 1);
    screen.fill = screenData.fill;
    screen.defaultImage = screenData.sourceImage;
    screen.defaultImageContentType = screenData.defaultImageContentType;
    screen.sourceImage = screenData.sourceImage;
    screen.screenName = `Region-${Number(screens.length + 1)}`;
    screen.scale = "stretch";
    /** screenWidth, screenHeight, studioHeight, studioWidth */
    screen.calculateScales(
      Number(this.screenWidth),
      Number(this.screenHeight),
      Number(this.studioHeight),
      Number(this.studioWidth)
    );
    this.screens = [...this.screens, screen];
  }
}

/** Is this a dublicate function. Check for code coverage if there is time. */
function changeDuration(duration, contentID, screenID) {
  this.screens[findIndex(this.screens, { localID: screenID })].contents[
    findIndex(
      this.screens[findIndex(this.screens, { localID: screenID })].contents,
      {
        contentLocalID: contentID
      }
    )
  ].duration = { ...duration };
  return 0;
}

function addWeatherWidgetToList() {
  const flags = widgetToggle("Weather", true);
  Object.assign(this, flags);
}

function addTextWidgetToList() {
  const flags = widgetToggle("Text", true);
  Object.assign(this, flags);
}

function addURLWidgetToList() {
  const flags = widgetToggle("URL", true);
  Object.assign(this, flags);
}
function addHLSWidgetToList() {
  const flags = widgetToggle("HLS", true);
  Object.assign(this, flags);
}

function addRSSWidgetToList() {
  const flags = widgetToggle("RSS", true);
  Object.assign(this, flags);
}

function addDateTimeWidgetToList() {
  const flags = widgetToggle("DateTime", true);
  Object.assign(this, flags);
}

const insertIntoArray = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index)
];

function addYTWidgetToList() {
  const flags = widgetToggle("Youtube", true);
  Object.assign(this, flags);
}

function addP2SWidgetToList() {
  const flags = widgetToggle("PicToScreen", true);
  Object.assign(this, flags);
}
function addRouteWidgetToList() {
  const flags = widgetToggle("Route", true);
  Object.assign(this, flags);
}
function addTransparentWidgetToList() {
  const flags = widgetToggle("Transparent", true);
  Object.assign(this, flags);
}

function addWeather(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const weather = new Weather();
  weather.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      weather
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...weather }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.WeatherIsBeingDragged = undefined;
}

function addText(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const text = new Text();
  text.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      text
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...text }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.TextIsBeingDragged = undefined;
}

function addURL(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const url = new URL();
  url.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      url
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...url }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.URLIsBeingDragged = undefined;
}

function addHLS(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const hls = new HLS();
  hls.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      hls
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...hls }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.HLSIsBeingDragged = undefined;
}

function addRSS(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const rss = new RSS();
  rss.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      rss
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...rss }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.RSSIsBeingDragged = undefined;
}

function addDateTime(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const dateTime = new DateTime();
  dateTime.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      dateTime
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...dateTime }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.DateTimeIsBeingDragged = undefined;
}

function addYoutube(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const yt = new Youtube();
  yt.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      yt
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...yt }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.YoutubeIsBeingDragged = undefined;
}

function addPicToScreen(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const p2s = new PicToScreen();
  p2s.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      p2s
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...p2s }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.PicToScreenIsBeingDragged = undefined;
}
function addRoute(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const route = new Route();
  route.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      route
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...route }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.RouteIsBeingDragged = undefined;
}
function addTransparent(screenID, flag = false, index) {
  const screenIndex = findIndex(this.screens, { localID: screenID });
  const contentLength = this.screens[screenIndex].contents.length;
  const route = new Transparent();
  route.contentPosition = contentLength + 1;
  if (flag) {
    this.screens[screenIndex].contents = insertIntoArray(
      this.screens[screenIndex].contents,
      index,
      route
    );
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      { ...route }
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  this.WidgetIsBeingDragged = undefined;
  this.TransparentIsBeingDragged = undefined;
}

function updateWidgetProperty(localID, contentLocalID, node, value) {
  const screenIndex = findIndex(this.screens, { localID });
  const contentIndex = findIndex(this.screens[screenIndex].contents, {
    contentLocalID
  });
  this.screens[screenIndex].contents[contentIndex][node] = value;
}

function setContents(localID, contents) {
  this.screens[findIndex(this.screens, { localID })].contents = contents;
}

function getContent(localID, contentLocalID) {
  const index = findIndex(this.screens, { localID });
  if (this.screens[index]) {
    const contentIndex = findIndex(this.screens[index].contents, {
      contentLocalID
    });
    return this.screens[index].contents[contentIndex];
  }
}

function LoadPlaylistToBeDropped(data) {
  const newData = uniqBy([...this.playlistToBeDropped, data], "id");
  this.playlistToBeDropped = newData;
  Object.assign(this, { ...resetFlags(), playlistIsBeingDragged: true });
}

function addPlaylist(screenID, fromTimeline = false, index) {
  if (this.playlistToBeDropped.length === 0) return;
  const screenIndex = findIndex(this.screens, { localID: screenID });
  let contentPosition = this.screens[screenIndex].contents.length;
  const newPlaylist = this.playlistToBeDropped.reduce((arr, item) => {
    const tempPlaylist = new Playlist(item, contentPosition);
    contentPosition += 1;
    return [...arr, tempPlaylist];
  }, []);
  if (fromTimeline) {
    this.screens[screenIndex].contents.splice(index, 0, ...newPlaylist);
  } else {
    this.screens[screenIndex].contents = [
      ...this.screens[screenIndex].contents,
      ...newPlaylist
    ];
  }
  this.screens[screenIndex].calculateThumbnail();
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  const flags = resetFlags();
  Object.assign(this, { ...flags, playlistToBeDropped: [] });
}

function prepareData() {
  return {
    _jsonapi: {
      data: {
        type: "campaigns",
        attributes: {
          name: this.campaignName.trim() || "Untitled Campaign",
          // subwindow_number: this.screens.length,
          screens_attributes: generateScreensAttributes(this.screens)
        },
        relationships: {
          resolution: {
            data: { type: "resolution", id: this.resolution }
          }
        }
      }
    }
  };
}

function loadCampaignData(
  { _id, name, resolution, resolution_id, screens },
  resolutions,
  mediaQueryFlag
) {
  this.campaignId = _id;
  this.campaignName = name;
  this.isEditing = true;
  this.isCampaignInitialized = true;
  this.screenNumber = screens.length;
  this.resolution = resolution_id;
  this.resolutionList = resolutions;
  this.setOrientation(resolution_id, resolutions, mediaQueryFlag);
  this.generateScreens();
  this.loadScreensFromServer(screens);
}

function loadScreensFromServer(screensToLoad) {
  const { screens } = this;
  this.screens = screens.map((screen, index) => {
    const temp = screen;
    const mapper = screensToLoad[index];
    temp.screenName = mapper.name || temp.screenName;
    temp.screenX = Number(mapper.starting_x) / this.horizontalScale;
    temp.screenY = Number(mapper.starting_y) / this.verticalScale;
    temp.width = Number(mapper.width) / this.horizontalScale;
    temp.height = Number(mapper.height) / this.verticalScale;
    temp.contents = this.loadContentToScreenFromServer(
      mapper.campaign_contents
    );
    temp.scale = mapper.scale;
    return temp;
  });
}

function loadContentToScreenFromServer(contents) {
  return contents.map((content, index) => {
    const {
      content_id,
      playlist_id,
      weather_widget_id,
      text_widget_id,
      url_widget_id,
      hls_widget_id,
      rss_widget_id,
      date_time_widget_id,
      youtube_widget_id,
      pic_to_screen_widget_id,
      routing_widget_id,
      transparent_widget_id,
      duration
    } = content;
    if (content_id !== null) {
      const { content: data } = content;
      return new Content(
        {
          id: data._id,
          attributes: { ...data, duration, duration_seconds: duration }
        },
        index
      );
    }
    if (playlist_id !== null) {
      const {
        playlist: data,
        duration,
        playlist_id,
        use_playlist_duration
      } = content;
      return new Playlist(
        {
          id: playlist_id,
          duration,
          usePlaylistDuration: use_playlist_duration,
          attributes: { ...data, usePlaylistDuration: use_playlist_duration }
        },
        index
      );
    }
    if (weather_widget_id && weather_widget_id !== null) {
      const { weather_widget_id, duration, weather_widget } = content;
      return new Weather(
        {
          weatherWidgetID: weather_widget_id,
          duration,
          days: weather_widget.days,
          location: weather_widget.location,
          geo_location: weather_widget.geo_location,
          unit: weather_widget.unit,
          contentPosition: index
        },
        index
      );
    }
    if (text_widget_id !== null) {
      const {
        text_widget_id,
        duration,
        text_widget: {
          background_color,
          font_family,
          font_size,
          rotate,
          text,
          text_color,
          text_align
        }
      } = content;
      return new Text({
        contentPosition: index,
        duration,
        backgroundColor: background_color,
        fontFamily: font_family,
        fontSize: font_size,
        marquee: rotate,
        text,
        textColor: text_color,
        textAlign: text_align,
        textWidgetID: text_widget_id
      });
    }
    if (url_widget_id !== null) {
      const { url_widget, duration } = content;
      return new URL({
        URLWidgetID: url_widget_id,
        scrollTo: url_widget.scroll_to,
        url: url_widget.url,
        scaleFactor: url_widget.scale_factor,
        duration
      });
    }
    if (hls_widget_id !== null) {
      const { hls_widget, duration } = content;
      return new HLS({
        HLSWidgetID: hls_widget_id,
        url: hls_widget.url,
        duration
      });
    }

    if (rss_widget_id !== null) {
      const {
        text_widget_id,
        duration,
        rss_widget: {
          background_color,
          font_family,
          font_size,
          url,
          delay,
          direction,
          text_color,
          rss_data
        }
      } = content;
      return new RSS({
        contentPosition: index,
        duration,
        backgroundColor: background_color,
        fontFamily: font_family,
        fontSize: font_size,
        url,
        textColor: text_color,
        textWidgetID: text_widget_id,
        delay,
        direction,
        title:
          rss_data.length > 0
            ? JSON.parse(rss_data)
            : ["Resource url does not contain RSS feed"]
      });
    }

    if (date_time_widget_id !== null) {
      const { date_time_widget_id, date_time_widget } = content;
      const time = timeZonesList.filter(
        item => item.Location === date_time_widget.timezone
      );
      return new DateTime({
        contentPosition: index,
        duration: date_time_widget.duration,
        textWidgetID: date_time_widget_id,
        textColor: date_time_widget.text_color,
        backgroundColor: date_time_widget.background_color,
        timezone: time.map(item => ({
          value: item.Location,
          label: `(${item.GMT}) ${item.Location}`
        }))[0]
      });
    }

    if (youtube_widget_id !== null) {
      const { youtube_widget, duration } = content;
      return new Youtube({
        YoutubeWidgetID: youtube_widget_id,
        scrollTo: youtube_widget.scroll_to,
        url: youtube_widget.url,
        duration
      });
    }

    if (pic_to_screen_widget_id !== null) {
      const { pic_to_screen_widget, duration } = content;
      return new PicToScreen({
        PicToScreenWidgetID: pic_to_screen_widget_id,
        url: pic_to_screen_widget.url,
        duration,
        customContentDuration: pic_to_screen_widget.custom_content_duration
      });
    }
    if (routing_widget_id !== null) {
      const {
        routing_widget_id,
        duration,
        routing_widget: {
          background_color,
          font_size,
          starting_location,
          destination_location,
          frequency,
          text_color,
          text_align,
          font_family,
          starting_geo_location,
          destination_geo_location
        }
      } = content;
      return new Route({
        contentPosition: index,
        duration,
        backgroundColor: background_color,
        fontSize: font_size,
        startingLocation: starting_location,
        destinationLocation: destination_location,
        startingGeoLocation: starting_geo_location,
        destinationGeoLocation: destination_geo_location,
        frequency,
        textColor: text_color,
        textAlign: text_align,
        RouteWidgetID: routing_widget_id,
        fontFamily: font_family
      });
    }
    if (transparent_widget_id !== null) {
      const { duration } = content;
      return new Transparent({
        TransparentWidgetID: transparent_widget_id,
        duration
      });
    }
    //  return 0;
  });
}
function getScale(screen, image, type, contentType) {
  const { height, width } = screen;
  const temp = screen;
  temp.fillPatternImage = image;

  let scale;
  if (
    contentType !== "image" &&
    contentType !== "video" &&
    contentType !== "playlist"
  ) {
    temp.fillPatternImage = image;
    temp.fillPatternScaleX = width / image.width;
    temp.fillPatternScaleY = height / image.height;
    temp.fillPatternX = undefined;
    temp.fillPatternY = undefined;
    return temp;
  }
  if (type === "stretch" && contentType !== "video") {
    temp.fillPatternImage = image;
    temp.fillPatternScaleX = width / image.width;
    temp.fillPatternScaleY = height / image.height;
    temp.fillPatternX = undefined;
    temp.fillPatternY = undefined;
    return temp;
  }

  if (type === "fit") {
    scale = Math.min(screen.width / image.width, screen.height / image.height);
  }
  if (contentType === "video" || type === "fill") {
    scale = Math.max(screen.width / image.width, screen.height / image.height);
  }
  const x = screen.width / 2 - (image.width / 2) * scale;
  const y = screen.height / 2 - (image.height / 2) * scale;
  temp.fillPatternScaleX = scale;
  temp.fillPatternScaleY = scale;
  temp.fillPatternX = x;
  temp.fillPatternY = y;
  return temp;
}

function changeDurationOfContent(contentID, screenID, duration) {
  this.screens[findIndex(this.screens, { localID: screenID })].contents[
    findIndex(
      this.screens[findIndex(this.screens, { localID: screenID })].contents,
      {
        contentLocalID: contentID
      }
    )
  ].duration = { ...duration };
  this.calculateTimelineContainer(this.initialTimeLineZoom);
  return 0;
}

function calculateTime({ minute, second }) {
  return Number(minute) * 60 + Number(second);
}

function handleMediaQuery(flag) {
  const newScreens = this.screens;
  this.screens = [];
  this.generateScreens();
  this.screens = newScreens;

  let hScale = this.studioMaxWidth / this.studioMinWidth;
  let vScale = this.studioMaxHeight / this.studioMinHeight;
  const mediaFlag = !flag;
  let zoomFlag = this.isZoomEnable;
  if (this.isZoomEnable) {
    hScale = this.zoomedMaxWidth / this.zoomedMinWidth;
    vScale = this.zoomedMaxHeight / this.zoomedMinHeight;
    zoomFlag = !this.isZoomEnable;
  }
  this.changeScreenAttribute(newScreens, hScale, vScale, mediaFlag, zoomFlag);
}

function handleScreenLayout(layout, resolutionList, mediaMatch) {
  switch (layout) {
    case "portrait2Screen": {
      this.screenNumber = 2;
      this.defaultTemplate = true;
      this.layout = "portrait2Screen";
      this.defaultScreenResolution = {
        screen1: { x: 0, y: 0, width: 1080, height: 640 },
        screen2: { x: 0, y: 641.6, width: 1080, height: 1280 }
      };
      this.resolution = resolutionList[1].id;
      this.setOrientation(
        resolutionList[1].id,
        resolutionList,
        mediaMatch.matches
      );
      break;
    }
    case "portrait3Screen": {
      this.screenNumber = 3;
      this.defaultTemplate = true;
      this.layout = "portrait3Screen";
      this.defaultScreenResolution = {
        screen1: { x: 0, y: 0, width: 1080, height: 640 },
        screen2: { x: 0, y: 641.6, width: 1080, height: 1130 },
        screen3: { x: 0, y: 1772.3, width: 1080, height: 150 }
      };
      this.resolution = resolutionList[1].id;
      this.setOrientation(
        resolutionList[1].id,
        resolutionList,
        mediaMatch.matches
      );
      break;
    }
    case "landscapelayout1": {
      this.screenNumber = 3;
      this.defaultTemplate = true;
      this.layout = "landscapelayout1";
      this.defaultScreenResolution = {
        screen1: { x: 0, y: 0, width: 380, height: 1080 },
        screen2: { x: 380.87, y: 866, width: 1540, height: 214 },
        screen3: { x: 380.87, y: 0, width: 1540, height: 866 }
      };
      this.resolution = resolutionList[0].id;
      this.setOrientation(
        resolutionList[0].id,
        resolutionList,
        mediaMatch.matches
      );
      break;
    }
    case "landscapelayout2": {
      this.screenNumber = 3;
      this.defaultTemplate = true;
      this.layout = "landscapelayout2";
      this.defaultScreenResolution = {
        screen1: { x: 0, y: 0, width: 960, height: 540 },
        screen2: { x: 0, y: 540, width: 960, height: 540 },
        screen3: { x: 960, y: 0, width: 960, height: 1080 }
      };
      this.resolution = resolutionList[0].id;
      this.setOrientation(
        resolutionList[0].id,
        resolutionList,
        mediaMatch.matches
      );
      break;
    }
    case "landscape": {
      this.screenNumber = 1;
      this.layout = "landscape";
      this.resolution = resolutionList[0].id;
      this.setOrientation(
        resolutionList[0].id,
        resolutionList,
        mediaMatch.matches
      );
      break;
    }
    case "portrait": {
      this.screenNumber = 1;
      this.layout = "portrait";
      this.resolution = resolutionList[1].id;
      this.setOrientation(
        resolutionList[1].id,
        resolutionList,
        mediaMatch.matches
      );
      break;
    }
    default: {
    }
  }
}

function changeScreenAttribute(
  screensToLoad,
  hScale,
  vScale,
  mediaQueryFlag,
  isZoomed
) {
  const { screens } = this;

  this.screens = screens.map((screen, index) => {
    const temp = screen;
    const mapper = screensToLoad[index];
    temp.screenName = mapper.screenName;
    if (mediaQueryFlag || isZoomed) {
      temp.screenX = mapper.screenX * hScale;
      temp.screenY = mapper.screenY * vScale;
      temp.width = mapper.width * hScale;
      temp.height = mapper.height * vScale;
    } else {
      temp.screenX = mapper.screenX / hScale;
      temp.screenY = mapper.screenY / vScale;
      temp.width = mapper.width / hScale;
      temp.height = mapper.height / vScale;
    }
    temp.horizontalScale = this.horizontalScale;
    temp.verticalScale = this.verticalScale;
    temp.contents = mapper.contents;
    return temp;
  });
}

function zoomCampaignEditor(flag, mediaQueryFlag) {
  this.isZoomEnable = flag;
  let mediaFlag = !mediaQueryFlag;
  this.setStudioDimension(mediaQueryFlag, flag);
  this.calculateScales(
    this.screenWidth,
    this.screenHeight,
    this.studioHeight,
    this.studioWidth
  );
  let hScale = this.zoomedMaxWidth / this.studioMaxWidth;
  let vScale = this.zoomedMaxHeight / this.studioMaxHeight;
  if (mediaQueryFlag) {
    hScale = this.zoomedMinWidth / this.studioMinWidth;
    vScale = this.zoomedMinHeight / this.studioMinHeight;
  }
  if (!flag && !mediaQueryFlag) {
    mediaFlag = mediaQueryFlag;
  }

  this.changeScreenAttribute(this.screens, hScale, vScale, mediaFlag, flag);
}

function enableSnap(flag) {
  this.isSnapEnabled = flag;
}

function setSelectedScreen(screenLocalID) {
  this.selectedScreen = screenLocalID;
}
function getMinutesAndSeconds(secondInTime) {
  if (secondInTime && secondInTime !== "N/A") {
    const minute = Math.floor(secondInTime / 60);
    const second = secondInTime - minute * 60;
    return { minute, second };
  }
  return { minute: 0, second: 5 };
}
function setSnapEnableForContent(localID, contentID, flag) {
  const screenIndex = findIndex(this.screens, { localID });
  const contentIndex = findIndex(this.screens[screenIndex].contents, {
    contentLocalID: contentID
  });
  const temp = this.screens[screenIndex].contents[contentIndex];
  temp.isSnapped = flag;
  this.screens[screenIndex].contents[contentIndex] = temp;
}

function handleSnap(screenID, contentID, duration) {
  const snapTime = [];
  const edgeDetection = 4;
  const { screens } = this;

  let i = 0;
  let counter = 0;
  let activeContentDuration = 0;

  const currentScreen = screens.filter(item => item.localID === screenID);
  const contentIndex = findIndex(currentScreen[0].contents, {
    contentLocalID: contentID
  });

  while (counter <= contentIndex) {
    if (currentScreen[0].contents[counter].contentLocalID === contentID) {
      currentScreen[0].contents[counter].duration = duration;
    }
    if (
      currentScreen[0].contents[counter].type === "playlist" &&
      currentScreen[0].contents[counter].usePlaylistDuration
    ) {
      activeContentDuration =
        currentScreen[0].contents[counter].estimatedDuration +
        activeContentDuration;
    } else {
      activeContentDuration =
        calculateTime(currentScreen[0].contents[counter].duration) +
        activeContentDuration;
    }
    counter += 1;
  }
  const formattedScreens = screens.filter(item => item.localID !== screenID);
  if (formattedScreens.length > 0) {
    while (i < formattedScreens.length) {
      let totalDuration = 0;
      let j = 0;
      if (formattedScreens[i].contents.length > 0) {
        while (j < formattedScreens[i].contents.length) {
          if (
            formattedScreens[i].contents[j].type === "playlist" &&
            formattedScreens[i].contents[j].usePlaylistDuration
          ) {
            totalDuration += formattedScreens[i].contents[j].estimatedDuration;
          } else {
            totalDuration += calculateTime(
              formattedScreens[i].contents[j].duration
            );
          }
          if (
            Math.abs(activeContentDuration - totalDuration) <= edgeDetection
          ) {
            const timeToReachTarget = totalDuration - activeContentDuration;
            const contentDuration = calculateTime(duration) + timeToReachTarget;
            const convertedTime = this.getMinutesAndSeconds(contentDuration);
            this.setSnapEnableForContent(screenID, contentID, true);
            snapTime.push(convertedTime);
            break;
          }
          j += 1;
        }
      }
      i += 1;
    }
  }
  if (snapTime.length >= 1) {
    return snapTime[0];
  }
  this.setSnapEnableForContent(screenID, contentID, false);
  return duration;
}

/* ----- campaign timeline zoom ---------- */

function setTimeLineZoomNumber(number, zoomValue) {
  this.initialTimeLineZoom = number;
  this.initialTimeLineZoomPercentage = zoomValue;
  this.calculateTimelineContainer(number);
}
function toMinuteSecond(seconds) {
  return {
    hour: parseInt(Number(seconds) / 3600, 10),
    minute: parseInt((Number(seconds) / 60) % 60, 10),
    second: parseInt(Number(seconds) % 60, 10),
    restriction:
      this.restrictedCampaign && seconds >= this.restrictedCampaignDuration
  };
}

function generateTimeStamp(seconds, totalSecondsofTimeline) {
  let intitalSecond = 0;
  let timeStamp;
  while (intitalSecond <= totalSecondsofTimeline) {
    timeStamp = 0 + intitalSecond;
    const time = this.toMinuteSecond(timeStamp);
    this.totalSecondsinContainer.push(time);
    intitalSecond += seconds;
  }
}

function calculateTimelineContainer(timeStamp) {
  /* Default the total time of the timeline is 600 seconds. But if the region duration increases with the
  number of contents, the total time of the timeline changes. If the duration of the region exceeds 600
  while droping the contents on the region or while resizing any particular content, the timeline is adjusted
  with respect to the duration of the region which has max duration.
  -- The timeStamp
       which is the number of divider that adjusts to the total time according to the range value.
      */
  /* each timeStamp will take 130px width (same with the container initial width).
       To make the timestamp line vertically aligned with the container,
       we have to make sure that the timeStamp is going to take it's full width.
*/

  let theTotalSecond = 600;
  const formattedTmeValue = this.screens.map(item =>
    totalSeconds(item.contents)
  );
  const maxRegionSeconds = Math.max(...formattedTmeValue);
  if (theTotalSecond < maxRegionSeconds) {
    theTotalSecond = maxRegionSeconds;
  }
  this.showExceededMessage = this.restrictedCampaign
    ? maxRegionSeconds > this.restrictedCampaignDuration
    : false;
  const widthValue = theTotalSecond / timeStamp;
  let intialTime = 0;
  this.totalSecondsinContainer = [];
  this.generateTimeStamp(timeStamp, theTotalSecond);
  intialTime = (widthValue + 3.2) * 130;
  this.timelineTotalWidth = intialTime;
}

function checkRestriction(restrictCampaign, restrictCampaignDuration) {
  this.restrictedCampaign = restrictCampaign;
  this.restrictedCampaignDuration = restrictCampaignDuration;
}
/* ------- campaign timeline zoom ends here -------- */

export {
  calculateScales,
  addNewScreen,
  removeScreen,
  setStudioDimension,
  setProperty,
  setOrientation,
  generateScreens,
  updateScreen,
  swapScreens,
  loadContentToBeDropped,
  unLoadContentToBeDropped,
  removeContentFromScreen,
  setDefaultContentOfScreen,
  addScreen,
  changeDuration,
  addWeatherWidgetToList,
  addTextWidgetToList,
  addYTWidgetToList,
  addURLWidgetToList,
  addHLSWidgetToList,
  addRSSWidgetToList,
  addDateTimeWidgetToList,
  addP2SWidgetToList,
  addRouteWidgetToList,
  addTransparentWidgetToList,
  addWeather,
  addText,
  addURL,
  addHLS,
  addRSS,
  addDateTime,
  addYoutube,
  addPicToScreen,
  addRoute,
  addTransparent,
  updateWidgetProperty,
  setContents,
  getContent,
  LoadPlaylistToBeDropped,
  addPlaylist,
  prepareData,
  loadCampaignData,
  loadScreensFromServer,
  loadContentToScreenFromServer,
  changeDurationOfContent,
  changeOrientation,
  zoomCampaignEditor,
  setSelectedScreen,
  handleMediaQuery,
  changeScreenAttribute,
  setStudioDimensionAttribute,
  handleSnap,
  getMinutesAndSeconds,
  enableSnap,
  setSnapEnableForContent,
  getScale,
  setTimeLineZoomNumber,
  toMinuteSecond,
  generateTimeStamp,
  calculateTimelineContainer,
  checkRestriction,
  handleScreenLayout
};
