/* eslint-disable no-undef */
/* eslint-disable react/no-multi-comp */
import React from "react";
import { Rect, Group, Text } from "react-konva";
import { newCoordinate } from "../../utls/helper";

export default class Rectangle extends React.Component {
  constructor(props) {
    super(props);
    this.setImage(this.props.defaultImage, this.props.defaultImageContentType);
    this.state = {
      fillPatternImage: null,
      style: {
        padding: 5
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.defaultImage !== nextProps.defaultImage) {
      this.setImage(nextProps.defaultImage, nextProps.defaultImageContentType);
    } else if (nextProps.defaultImage === undefined) {
      this.setState({ fillPatternImage: null });
    }
  }

  setImage = (sourceImage, contentType) => {
    const image = new window.Image();
    const { screen, campaign, updateCampaign } = this.props;
    image.onload = () => {
      const formattedScreen = campaign.getScale(
        screen,
        image,
        screen.scale,
        contentType
      );
      campaign.updateScreen(formattedScreen.localID, formattedScreen);
      updateCampaign(campaign);
      this.forceUpdate();
    };
    image.src = sourceImage;
  };

  handleBoundFunc = position => {
    const { screen } = this.props;
    const newC = newCoordinate(
      position.x,
      position.y,
      screen.height,
      screen.width,
      this.props.studioWidth,
      this.props.studioHeight
    );
    screen.screenX = newC.x;
    screen.screenY = newC.y;
    this.props.handleXYChange(screen, this.props.screen.localID);
    return { ...newC };
  };

  handleDragEnd = e => {
    const { localID, screen } = this.props;
    const {
      currentTarget: {
        attrs: { x, y, height, width }
      }
    } = e;
    const newC = newCoordinate(x, y, height, width, {
      width: this.props.divWidth,
      height: this.props.divHeight
    });
    screen.screenX = newC.x;
    screen.screenY = newC.y;
    this.props.handleXYChange(screen, localID);
  };

  handleClick = () => {
    console.error("asash");
  };

  handleSnapTarget = () => {
    setTimeout(() => {
      const { campaign, updateCampaign, screen } = this.props;
      screen.snappingTarget = false;
      campaign.updateScreen(screen.localID, screen);
      updateCampaign(campaign);
    }, 2000);
  };

  render() {
    const {
      height,
      localID,
      width,
      screen,
      screenX,
      screenY,
      screenName,
      verticalScale,
      horizontalScale,
      snappingTarget
    } = this.props;
    if (snappingTarget) {
      this.handleSnapTarget();
    }
    return (
      <Group
        onMouseEnter={e => this.props.handleContentDrop(this.props.localID)}
      >
        <Rect
          x={screenX}
          y={screenY}
          width={width}
          height={height}
          fillPatternImage={screen.fillPatternImage}
          fillPatternRepeat="no-repeat"
          fillPatternScaleX={screen.fillPatternScaleX}
          fillPatternScaleY={screen.fillPatternScaleY}
          fillPatternX={
            screen.fillPatternX !== undefined && screen.fillPatternX
          }
          fillPatternY={
            screen.fillPatternY !== undefined && screen.fillPatternY
          }
          name={localID}
          draggable
          dragBoundFunc={this.handleBoundFunc}
          onDragEnd={this.handleDragEnd}
        />
        <Group>
          <Rect x={screenX} y={screenY} fill="#000" width={80} height={40} />
          <Text
            x={screenX}
            y={screenY}
            drawBorder
            fill="#fff"
            shadowColor="black"
            text={`${(width * horizontalScale).toFixed(0)} X ${(
              height * verticalScale
            ).toFixed(0)}`}
            {...this.state.style}
          />
          <Text
            x={screenX}
            y={screenY + 18}
            drawBorder
            fill="#fff"
            shadowColor="black"
            text={screenName}
            {...this.state.style}
          />
        </Group>

        {snappingTarget && (
          <Group>
            <Rect
              x={screenX + width - 80}
              y={screenY + height - 20}
              fill="#034476cf"
              width={80}
              height={20}
            />
            <Text
              x={screenX + width - 70}
              y={screenY + height - 20}
              drawBorder
              fill="#fff"
              shadowColor="black"
              text="Snapped!"
              {...this.state.style}
            />
          </Group>
        )}
        {/* )} */}
      </Group>
    );
  }
}
