import { connect } from "react-redux";
import React from "react";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { initialize } from "redux-form";
import UserTable from "../modules/userTable";
import { Resources, Logout, generateResendConfirmation } from "../redux";
import { Dialog, SearchTagRefresh } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import { Patch } from "../../../constant/thunk";
import AddUser from "./addUser";
import UserHeader from "./userHeader";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      email: { active: false, sortBy: "desc" },
      created_at: { active: false, sortBy: "desc" },
      updated_at: { active: false, sortBy: "desc" }
    };
    this.state = {
      modal: false,
      forcePage: undefined,
      isLoading: true,
      sortOrder: this.initialSortOrder,
      sortValue: { isSortable: false },
      id: undefined,
      email: undefined,
      currentUser: JSON.parse(window.localStorage.getItem("user")).user.id,
      searchVal: "",
      action: "",
      createUserModal: false,
      contentToBeEdited: undefined,
      isSending: undefined
    };
    this.page = 1;
    this.handleUsersList(this.page);
  }

  handleUsersList = page => {
    Resources.getUsers.url = `/users?page=${page}&per_page=${this.perPageCount}`;
    this.props
      .Get(Resources.getUsers)
      .then(() => this.setState({ isLoading: false }));
  };

  toggleModal = () => {
    this.setState(state => ({ modal: !state.modal }));
  };

  handleDelete = (id, email) => {
    this.setState({
      modal: true,
      id,
      email
    });
  };

  handleResendConfirmation = async values => {
    this.setState({ isSending: values });
    const { getResendConfirmation } = Resources;
    getResendConfirmation.body = generateResendConfirmation(values);
    this.props
      .Patch(getResendConfirmation)
      .then(() => this.setState({ isSending: undefined }));
  };

  handleSort = name => {
    this.setState({ forcePage: 0, isLoading: true });
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getUsers } = Resources;
        getUsers.url = `/users/?sort=${name}&sort_by=${
          this.state.sortOrder[name].sortBy
        }&page=${1}&per_page=${this.perPageCount}`;
        this.props
          .Get(Resources.getUsers)
          .then(() => this.setState({ isLoading: false }));
      }
    );
  };

  handleSearchValue = value => {
    this.setState({ searchVal: value }, () => {
      this.handleSearch();
    });
  };

  handleSearch = debounce(() => {
    const { getUsers } = Resources;
    if (this.state.searchVal === "") {
      getUsers.url = `/users?page=${1}&per_page=${this.perPageCount}`;
    } else {
      getUsers.url = `/users/search?search=${
        this.state.searchVal
      }&page=${1}&per_page=${this.perPageCount}`;
    }
    this.props.Get(getUsers);
  }, 3000);

  letsDelete = () => {
    const { deleteUser, getUsers } = Resources;
    const { id } = this.state;
    deleteUser.url = `/users/${id}`;
    this.props.Delete(deleteUser).then(() =>
      this.props.Get(getUsers).then(result => {
        if (result.status === 200) {
          return (
            this.props.userList.length === 0 &&
            this.handlePageClick(this.props.meta, true)
          );
        }
      })
    );
    this.setState({
      id: undefined,
      email: undefined,
      modal: false
    });
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true, forcePage: undefined });
    const { getUsers } = Resources;
    if (moveToPrevious) {
      getUsers.url = `/users?page=${data.page - 1}&per_page=${
        this.perPageCount
      }`;
      this.props.Get(getUsers).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      const { selected } = data;
      if (this.state.sortValue.isSortable) {
        getUsers.url = `/users?sort=${this.state.sortValue.name}&sort_by=${
          this.state.sortValue.sortBy
        }&page=${selected + 1}&per_page=${this.perPageCount}`;
      } else {
        getUsers.url = `/users?page=${selected + 1}&per_page=${
          this.perPageCount
        }`;
      }
      this.props
        .Get(getUsers)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  closeAddUserModal = () => {
    this.setState({ createUserModal: false });
  };

  setLoading = flag => {
    this.setState({ isLoading: flag });
  };

  handleUserCreate = (createUserModal, action, contentToBeEdited) => {
    this.setState({ createUserModal, action, contentToBeEdited });
    if (createUserModal && action === "edit") {
      this.props.initialize("AddUserForm", {
        name: contentToBeEdited.attributes.name,
        email: contentToBeEdited.attributes.email
      });
    } else {
      this.props.initialize("AddUserForm", {});
    }
  };

  render() {
    const { permission, roles } = this.props;
    return (
      <div className="mainPage">
        <div className="contentSection">
          <AddUser
            action={this.state.action}
            createUserModal={this.state.createUserModal}
            handleUserCreate={this.handleUserCreate}
            handleUsersList={this.handleUsersList}
            contentToBeEdited={this.state.contentToBeEdited}
            meta={this.props.meta}
            setLoading={this.setLoading}
            closeAddUserModal={this.closeAddUserModal}
            {...this.props}
          />
          <Dialog
            showHandler={false}
            externalControl
            modal={this.state.modal}
            toggleModal={this.toggleModal}
            body={
              <span>
                <small>Are you sure you want to delete </small>
                <strong>{this.state.email}</strong>?
              </span>
            }
            primaryMethod={this.letsDelete}
            toggleModel={this.toggleModal}
            title="Delete User"
          />
          <UserTable
            {...this.props}
            handleDelete={this.handleDelete}
            currentUser={this.state.currentUser}
            isLoading={this.state.isLoading}
            handleSort={this.handleSort}
            sortOrder={this.state.sortOrder}
            handleResendConfirmation={this.handleResendConfirmation}
            handleUserCreate={this.handleUserCreate}
            isSending={this.state.isSending}
            // below props are sent for user table header
            rolesUH={roles}
            permissionUH={permission.content.creates}
            // handleUserCreate={this.handleUserCreate}
            handleSearchValue={this.handleSearchValue}
          />
          {!this.state.isLoading &&
            this.props.userList &&
            this.props.userList.length > 0 && (
              <div className="contentSectionPagination clearfix">
                <ReactPaginate
                  pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  marginPagesDisplayed={2}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  previousLabel="<"
                  forcePage={this.state.forcePage}
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  disabledClassName="paginationDisable"
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list, status, loading, hasError, meta } = state.userList;
  return {
    userList: list !== undefined ? list : [],
    loading,
    hasError,
    meta,
    status
  };
}

export default connect(
  mapStateToProps,
  { Patch, Logout, initialize }
)(UserList);
