/* eslint-disable no-shadow */
import React from "react";
import { connect } from "react-redux";
import { change } from "redux-form";

import { Label } from "reactstrap";
import SingleTimeLine from "./singleTimeLine";

import { SortableList } from "./sortableScreens";
import { updateCampaign } from "../../redux";
import { RangeSlider, Tooltip } from "../../../../core";
import SingleTimeActionButtons from "./singleTimeActionButtons";
import StudioActionButtons from "./studioActionButtons";
import DesktopBreakpoint from "../../../../context/desktop_breakpoint";
import PhoneBreakpoint from "../../../../context/phone_breakpoint";

function mapTimeLineData(
  data,
  props = undefined,
  state = undefined,
  onClick,
  handleContentDeleteFromTimeline,
  handleContentSortViaTimeline
) {
  return data.map((item, index) => (
    <SingleTimeLine
      {...state}
      {...props}
      screenTitle={`Region ${index + 1}`}
      id={item.id}
      allData={data}
      singleTimeLine={item}
      color={item.color}
      volume={item.volume}
      content={item.content}
      handleClick={onClick}
      key={item.id}
      handleContentDeleteFromTimeline={handleContentDeleteFromTimeline}
      handleContentSortViaTimeline={handleContentSortViaTimeline}
    />
  ));
}

function handleSorting({ oldIndex, newIndex }, { campaign, updateCampaign }) {
  if (campaign.screens.length === 0) {
    throw new Error("Region length cannot be zero");
  } else if (typeof updateCampaign !== "function") {
    throw Error(
      `Second argument is aspect to be function but receive ${typeof updateCampaign}`
    );
  } else if (oldIndex > campaign.screens.length) {
    throw new Error("Invalid index is provided.");
  } else if (oldIndex < 0) {
    throw new Error("Index cannot be less then 1 Negative or Zero is provided");
  }
  campaign.swapScreens(oldIndex, newIndex);
  updateCampaign(campaign);
}

class StudioTimeLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopOverOpen: undefined
    };
    this.seconds = 600;
    this.divRef = React.createRef();
    this.intitalSecond = [];
    this.totalSecondsinContainer = [];

    this.props.campaign.checkRestriction(
      this.props.restrictedCampaign,
      this.props.restrictedCampaignDuration
    );

    this.props.campaign.calculateTimelineContainer(5);
  }

  handlePopOver = screenID => {
    this.setState(state => ({ isPopOverOpen: screenID }));
  };

  handleClickScreen = screenID => {
    let selectedScreen = screenID;
    const { campaign, updateCampaign } = this.props;
    if (screenID === campaign.selectedScreen) {
      selectedScreen = undefined;
    }
    campaign.setSelectedScreen(selectedScreen);
    updateCampaign(campaign);
  };

  // handleContentDeleteFromTimeline = (singleTimeLine, id) => {
  //   this.props.change("SplitScreen", `screen${id}`, singleTimeLine);
  // };

  // handleContentSortViaTimeline = ({ oldIndex, newIndex }, e, data) => {
  //   const { content, id, singleTimeLine } = data;
  //   const temp = arrayMove(content, oldIndex, newIndex);
  //   singleTimeLine.content = [];
  //   singleTimeLine.content = temp;
  //   this.props.change("SplitScreen", `screen${id}`, singleTimeLine);
  //   this.props.updatePage();
  // };

  handleIconZoomIn = action => {
    const { initialTimeLineZoomPercentage } = this.props.campaign;
    let value = initialTimeLineZoomPercentage;
    if (action === "in" && initialTimeLineZoomPercentage < 100) {
      value = initialTimeLineZoomPercentage + 1;
    }
    if (action === "out" && initialTimeLineZoomPercentage > 0) {
      value = initialTimeLineZoomPercentage - 1;
    }
    this.handleTimelineContainerWidth(value);
  };

  // to handle the width of the container with respect to the time size//

  handleTimelineContainerWidth = value => {
    const { campaign, updateCampaign } = this.props;

    if (value > 0 && value <= 10) {
      campaign.setTimeLineZoomNumber(54, value);
    }
    if (value > 10 && value <= 20) {
      campaign.setTimeLineZoomNumber(48, value);
    }
    if (value > 20 && value <= 30) {
      campaign.setTimeLineZoomNumber(42, value);
    }
    if (value > 30 && value <= 40) {
      campaign.setTimeLineZoomNumber(36, value);
    }
    if (value > 40 && value <= 50) {
      campaign.setTimeLineZoomNumber(30, value);
    }
    if (value > 50 && value <= 60) {
      campaign.setTimeLineZoomNumber(24, value);
    }
    if (value > 60 && value <= 70) {
      campaign.setTimeLineZoomNumber(18, value);
    }
    if (value > 70 && value <= 80) {
      campaign.setTimeLineZoomNumber(12, value);
    }
    if (value > 80 && value <= 90) {
      campaign.setTimeLineZoomNumber(8, value);
    }
    if (value > 90 && value <= 100) {
      campaign.setTimeLineZoomNumber(5, value);
    }
    if (value === 0) {
      campaign.setTimeLineZoomNumber(60, value);
    }
    updateCampaign(campaign);
  };

  // handleCursor = e => {
  //   const cursor = document.getElementsByClassName("hoverIndicator")[0];
  //   cursor.setAttribute("style", `left:${e.pageX}px`);
  // };

  render() {
    const { campaign, updateCampaign, displayRegionsForMobile } = this.props;
    const reverseScreen = [...this.props.campaign.screens].reverse();
    return (
      <div className="campaignStudioWrapper__timeline  " ref={this.divRef}>
        <div className="timelineContainer">
          <div className="mr-0 ml-0 row upperSectionContainer">
            <div
              className={`col-md-6 singleActionButtons ${displayRegionsForMobile &&
                "displaySingleActionButtons"}`}
            >
              <PhoneBreakpoint>
                <button
                  className="mr-auto mt-0 p-1 ml-2 primaryButton"
                  onClick={this.props.handleRegionDisplayForMobile}
                >
                  {this.props.displayRegionsForMobile
                    ? "Hide Regions"
                    : "Show Regions"}
                </button>
              </PhoneBreakpoint>
              <SingleTimeActionButtons {...this.props} />
            </div>
            <DesktopBreakpoint>
              <div className="col-md-6 studioButtons ">
                <StudioActionButtons {...this.props} />
              </div>
            </DesktopBreakpoint>
          </div>
          <PhoneBreakpoint>
            <div className="d-flex zoomFieldMobileField">
              <Tooltip
                item={
                  <i
                    role="presentation"
                    className="zoomFieldIcon bx bx-minus"
                    onClick={() => this.handleIconZoomIn("out")}
                  />
                }
                id="zoomOut"
                tooltipContent="Zoom Out"
              />{" "}
              <div className="rangeslider-wrapper">
                <RangeSlider
                  min={0}
                  max={100}
                  orientation="horizontal"
                  input={{
                    onChange: value => {
                      this.handleTimelineContainerWidth(value);
                    }
                  }}
                  value={campaign.initialTimeLineZoomPercentage}
                />
              </div>
              <Tooltip
                item={
                  <i
                    role="presentation"
                    className="zoomFieldIcon bx bx-plus"
                    onClick={() => this.handleIconZoomIn("in")}
                  />
                }
                id="campaignEditorzoomIn"
                tooltipContent="Zoom In"
              />{" "}
            </div>
          </PhoneBreakpoint>
          <div className="lowerSectionContainer">
            <div className="lower">
              <div className="lowerContainer">
                <div className="lowerSection">
                  <div>
                    <div className="actualContainer" id="style-1">
                      <div
                        className="zoomHundered"
                        style={{ width: campaign.timelineTotalWidth }}
                      >
                        <div className="d-flex timeIndicator">
                          <DesktopBreakpoint>
                            <div className="d-flex zoomField">
                              <Tooltip
                                item={
                                  <i
                                    role="presentation"
                                    className="zoomFieldIcon bx bx-minus"
                                    onClick={() => this.handleIconZoomIn("out")}
                                  />
                                }
                                id="zoomOut"
                                tooltipContent="Zoom Out"
                              />{" "}
                              <div className="rangeslider-wrapper">
                                <RangeSlider
                                  min={0}
                                  max={100}
                                  orientation="horizontal"
                                  input={{
                                    onChange: value => {
                                      this.handleTimelineContainerWidth(value);
                                    }
                                  }}
                                  value={campaign.initialTimeLineZoomPercentage}
                                />
                              </div>
                              <Tooltip
                                item={
                                  <i
                                    role="presentation"
                                    className="zoomFieldIcon bx bx-plus"
                                    onClick={() => this.handleIconZoomIn("in")}
                                  />
                                }
                                id="campaignEditorzoomIn"
                                tooltipContent="Zoom In"
                              />{" "}
                            </div>
                          </DesktopBreakpoint>

                          <div className="indicator">
                            <div className="timeWrapper">
                              {campaign.totalSecondsinContainer.map(d => (
                                <span
                                  className="singleTimelineTimer"
                                  style={{
                                    background:
                                      d.restriction &&
                                      "rgba(178, 177, 177, 0.68)"
                                  }}
                                >
                                  {" "}
                                  <span className="singleTimelineTimerTime">
                                    {`0${d.hour}`.slice(-2)}:
                                    {`0${d.minute}`.slice(-2)}:
                                    {`0${d.second}`.slice(-2)}
                                  </span>
                                </span>
                              ))}
                            </div>
                            {campaign.totalSecondsinContainer.map(
                              (d, index) => (
                                <span
                                  className="singleStamp"
                                  style={{
                                    borderLeft:
                                      index === 0 && "2px solid #8e8e8e",
                                    borderRight:
                                      campaign.totalSecondsinContainer.length -
                                        1 ===
                                        index && "none"
                                  }}
                                />
                              )
                            )}
                          </div>

                          {/* {`0${d.minute}`.slice(-2)}:
                                {`0${d.second}`.slice(-2)}s */}
                        </div>
                        <div
                          className="dataContainer"
                          // onMouseMove={e => this.handleCursor(e)}
                        >
                          {/* <div className="indicatorStick">
                            <div className="innerStick">
                              <div className="hoverIndicator" />
                            </div>
                          </div> */}
                          <div className="singleRegion">
                            {" "}
                            <SortableList
                              forwardRef={this.divRef}
                              axis="y"
                              useDragHandle
                              items={reverseScreen}
                              distance={6}
                              onSortStart={this.onSortStart}
                              onSortEnd={newIndex => {
                                handleSorting(newIndex, this.props);
                              }}
                              style={{ zIndex: 99999999 }}
                              props={this.props}
                              helperClass="sortableHelper"
                              handlePopOver={this.handlePopOver}
                              isPopOverOpen={this.state.isPopOverOpen}
                              handleClickScreen={this.handleClickScreen}
                              selectedScreen={
                                this.props.campaign.selectedScreen
                              }
                              displayRegionsForMobile={
                                this.props.displayRegionsForMobile
                              }
                              displayMediaModal={this.props.displayMediaModal}
                            />
                            {campaign.screens.length !== 6 && (
                              <div className="campaignStudioWrapper__addButton">
                                <Tooltip
                                  item={
                                    <i
                                      role="presentation"
                                      className="bx bx-plus bx-sm"
                                      onClick={() => {
                                        campaign.addScreen();
                                        updateCampaign(campaign);
                                      }}
                                    />
                                  }
                                  id="addScreen"
                                  tooltipContent="Add Region"
                                />{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  state => {
    const { selectedList } = state.SelectedContent;
    const screens = state.form.SplitScreen ? state.form.SplitScreen.values : {};

    return {
      selectedList: selectedList || undefined,
      screens:
        screens != null || screens != undefined ? Object.values(screens) : []
    };
  },
  { change, updateCampaign }
)(StudioTimeLine);
