import React, { Component } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { remove } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from "react-bootstrap-table2-paginator";
import { columns } from "../utils/calendarUtils";

const rowClasses = (row, rowIndex, calendarSelected, isCreating) => {
  let classes = null;
  if (row.id === calendarSelected && !isCreating) {
    classes = "activeClass contentSectionTable__actionButtonWrapperCalendar";
  } else {
    classes = "normalClass contentSectionTable__actionButtonWrapperCalendar";
  }
  return classes;
};

// eslint-disable-next-line react/prefer-stateless-function
class PlaylistTable extends Component {
  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      selectColumnStyle: { width: "40px" },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("calendarSelected", [
            ...this.props.calendarSelected,
            row
          ]);
        } else {
          const { calendarSelected } = this.props;
          remove(calendarSelected, { id: row.id });
          this.props.change("calendarSelected", calendarSelected);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("calendarSelected", [
            ...this.props.calendarSelected,
            ...rows
          ]);
        } else {
          this.props.change("calendarSelected", []);
        }
      }
    };
    return (
      <div>
        <BootstrapTable
          classes="contentSectionTable calendarTable"
          hover
          wrapperClasses="table-responsive content-wrap"
          ref={this.props.forwardRef}
          bordered={false}
          keyField="id"
          loading={this.props.isLoading}
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: base => ({
                ...base,
                background: "rgba(221,221,221,0.3)"
              }),
              spinner: base => ({
                ...base,
                "& svg circle": {
                  stroke: "#e44b32",
                  strokeWidth: "4"
                }
              })
            }
          })}
          data={this.props.calendarsList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleDownload,
            this.props.handleSort,
            this.props.sortOrder,
            this.props.handleDuplicate,
            this.props.handleArchiveItem,
            this.props.handleCalendarEdit,
            this.props.dropdownOpen,
            this.props.toggle,
            this.props.calendarSelected,
            this.props.handleArchiveItems,
            this.props.handleDeleteCalendars,
            this.props.filterDropdownOpen,
            this.props.filterToggle,
            this.props.disableArchiveIcon,
            this.props.handleInventoryView
          )}
          selectRow={selectRow}
          rowClasses={(row, rowIndex) =>
            rowClasses(
              row,
              rowIndex,
              this.props.calendarId,
              this.props.isCreating
            )
          }
        />
      </div>
    );
  }
}
export default connect(state => {
  const selector = formValueSelector("SelectedCalendar");
  return {
    calendarSelected: selector(state, "calendarSelected") || []
  };
})(
  reduxForm({
    form: "SelectedCalendar"
  })(PlaylistTable)
);
