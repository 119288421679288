import React, { Component } from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";

export default class StatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.filterStatus || "all",
      dropdownOpen: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.status !== nextProps.filterStatus) {
      return {
        status: nextProps.filterStatus
      };
    }
    return null;
  }

  handleStatus = status => {
    this.setState(
      {
        status
      },
      () => this.props.handleStatus(status)
    );
  };

  toggle = () => {
    this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));
  };

  render() {
    return (
      <Dropdown
        className="d-inline-block"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle className="iconButton" caret>
          <i className="bx bx-filter" />{" "}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <span role="button" onClick={() => this.handleStatus("")}>
              All
            </span>
          </DropdownItem>
          <DropdownItem>
            <span role="button" onClick={() => this.handleStatus("live")}>
              Online{" "}
            </span>
          </DropdownItem>
          <DropdownItem>
            <span role="button" onClick={() => this.handleStatus("offline")}>
              Offline{" "}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
