import {
  networks,
  totalGroup,
  electronTags,
  legacyElectronTags,
  unAssignedDevices,
  Resources,
  updateDataFromWebSocket,
  GurzuDevices,
  selectedGroup,
  groupToBeEdited,
  updateGroupInfiniteScroll
} from "./networkRedux";
import {
  Resources as DeviceResources,
  deviceDetails,
  RS232BodyGenerator,
  acceptedDevices,
  unacceptedDevices,
  deniedDevices,
  rejectedDevices
} from "./deviceRedux";
import { NetworkTagResources, NetworkTag } from "./TagReducer";
import {
  getDeviceScreenShots,
  Resources as ScreenShotsResources,
  updateScreenShotDataFromWebSocket
} from "./screenshotsRedux";
import {
  deviceGroupDetails,
  Resources as deviceGroupResources
} from "./groupRedux";

import {
  deviceNucMetrics,
  latestDeviceNucMetrics,
  deviceNucMetricsOfRange,
  Resources as MetricsResources
} from "./metricsRedux";

const metricsReducer = {
  latestDeviceNucMetrics,
  deviceNucMetrics,
  deviceNucMetricsOfRange
};

const networkReducer = {
  networks,
  totalGroup,
  electronTags,
  legacyElectronTags,
  selectedGroup,
  groupToBeEdited,
  unAssignedDevices,
  deviceDetails,
  acceptedDevices,
  unacceptedDevices,
  deniedDevices,
  rejectedDevices,
  NetworkTag,
  getDeviceScreenShots,
  deviceGroupDetails,
  GurzuDevices,
  updateGroupInfiniteScroll
};

export {
  networkReducer,
  Resources,
  DeviceResources,
  ScreenShotsResources,
  RS232BodyGenerator,
  NetworkTagResources,
  deviceGroupResources,
  updateDataFromWebSocket,
  GurzuDevices,
  updateGroupInfiniteScroll,
  updateScreenShotDataFromWebSocket,
  metricsReducer,
  MetricsResources
};
