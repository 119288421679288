import React, { Component } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { remove, differenceBy } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import RouteAllowed from "../../../helper/isRouteAllowed";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { columns } from "../utils/campaignUtils";
import { CampaignHeader } from "./index";

const rowClasses = (row, rowIndex) => {
  let classes = null;
  if (row.attributes.status === "offline") {
    classes = "offlineClass";
  } else {
    classes = "onlineClass";
  }
  return classes;
};

// eslint-disable-next-line react/prefer-stateless-function
class CampaignTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        screen: false,
        orientation: false,
        date_created: false,
        date_updated: false,
        status: false
      }
    };
  }
  handleNodata = () => <span className="null-message-info">No Data</span>;

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  render() {
    const selectRow = {
      mode: "checkbox",
      selectColumnStyle: { width: "40px" },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("campaignsSelected", [
            ...this.props.campaignsSelected,
            row
          ]);
        } else {
          const { campaignsSelected } = this.props;
          remove(campaignsSelected, { id: row.id });
          this.props.change("campaignsSelected", campaignsSelected);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("campaignsSelected", [
            ...this.props.campaignsSelected,
            ...rows
          ]);
        } else {
          const { campaignsSelected } = this.props;
          const formattedCampaign = differenceBy(campaignsSelected, rows, "id");
          this.props.change("campaignsSelected", formattedCampaign);
        }
      }
    };

    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.tableRef}
          keyField="id"
          data={this.props.campaignList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleSort,
            this.props.sortOrder,
            this.props.handleDuplicate,
            this.props.handleArchiveItem,
            this.state.hiddenColumns
          )}
          columnToggle
        >
          {props => (
            <div>
              <CampaignHeader
                {...this.props}
                forwardRef={this.props.searchRef}
                refreshing={this.props.refreshing}
                handleRefresh={this.props.handleRefresh}
                handleSearch={this.props.handleSearch}
                handleStatus={this.props.handleStatus}
                handleArchiveItems={this.props.handleArchiveItems}
                handleDeleteCampaigns={this.props.handleDeleteCampaigns}
                // props below are for col toggler
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                disableArchiveIcon={this.props.disableArchiveIcon}
              />
              <BootstrapTable
                ref={this.props.tableRef}
                classes="contentSectionTable"
                wrapperClasses="table-responsive content-wrap"
                loading={this.props.isLoading}
                bordered={false}
                rowClasses={rowClasses}
                selectRow={selectRow}
                hover
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      width: "1412px",
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                rowEvents={{
                  onClick: (e, row) => {
                    e.stopPropagation();
                    this.props.history.push(`campaign/edit/${row.id}`);
                  },
                  style: {
                    cursor: "pointer"
                  }
                }}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
export default connect(state => {
  const selector = formValueSelector("SelectedCampaigns");
  return {
    campaignsSelected: selector(state, "campaignsSelected") || []
  };
})(
  reduxForm({
    form: "SelectedCampaigns"
  })(CampaignTable)
);
