import moment from "moment";

const uuidv4 = require("uuid/v4");

function repeatValueGenerator(repeatValue) {
  switch (repeatValue) {
    case "not_repeat":
      return { value: "not_repeat", label: "Don't repeat" };
    case "daily":
      return {
        value: "daily",
        label: "Repeat daily"
      };
    case "weekly":
      return {
        value: "weekly",
        label: "Repeat weekly"
      };
    case "monthly":
      return { value: "monthly", label: "Repeat monthly" };
    default:
      return { value: "not_repeat", label: "Don't repeat" };
  }
}

export function createEvent({ start, end }) {
  const id = uuidv4();
  return {
    id,
    title: "",
    start: moment(start).format("YYYY-MM-DDTHH:mm"),
    end: moment(end).format("YYYY-MM-DDTHH:mm"),
    campaignName: null,
    eventId: id,
    repeat: { value: "not_repeat", label: "Don't repeat" },
    excludedDates: [],
    _destroy: "0",
    repeatFreq: 1
  };
}

export function convertToLocalEvent({
  serverID,
  CampaignName,
  eventName,
  endTime,
  startTime,
  repeat,
  excludedDates,
  repeatFreq
}) {
  const id = uuidv4();
  return {
    id,
    title: eventName,
    start: startTime,
    end: endTime,
    campaignName: CampaignName,
    eventId: id,
    repeat: repeatValueGenerator(repeat),
    excludedDates,
    _destroy: "0",
    repeatFreq: repeatFreq || 1,
    serverID
  };
}

export function convertToServerEvent({
  title,
  start,
  end,
  campaignName,
  repeat,
  excludedDates,
  _destroy,
  repeatFreq,
  serverID
}) {
  return {
    id: serverID || undefined,
    event_name: title,
    campaign_id: campaignName.value,
    start_time: moment(start).format("YYYY/MM/DDTHH:mm"),
    end_time: moment(end).format("YYYY/MM/DDTHH:mm"),
    repeat: repeat.value,
    repeatFreq,
    event_starting_date: moment(start).format("YYYY/MM/DDTHH:mm"),
    event_ending_date: moment(end).format("YYYY/MM/DDTHH:mm"),
    excluded_dates: excludedDates,
    _destroy
  };
}

export function convertToInventoryEvent({
  serverID,
  eventName,
  endTime,
  startTime,
  repeat,
  excludedDates,
  repeatFreq
}) {
  const id = uuidv4();
  return {
    id,
    title: eventName,
    start: startTime,
    end: endTime,
    eventId: id,
    repeat: repeatValueGenerator(repeat),
    excludedDates,
    repeatFreq: repeatFreq || 1,
    serverID
  };
}
