import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Label } from "reactstrap";
import { Field } from "redux-form";
import moment from "moment";
import FormFeedback from "reactstrap/lib/FormFeedback";
import { debounce, capitalize } from "lodash";
import { Input, Tooltip } from "../../../core";
import DatePicker from "../../../core/form/datePicker";
import {
  brandEnddateIsAfter,
  brandStartdateIsBefore,
  isPositive,
  required,
  email
} from "../../../helper/validation";
import { Resources } from "../redux/domainRedux";

class BrandForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandNameValidationError: undefined
    };
  }

  validateBrandName = debounce(value => {
    const { getValidateBrand } = Resources;
    getValidateBrand.url = `domains/${this.props.domainId}/parties/${this.props.partyId}/brand_validation?name=${value}`;
    this.props.Get(getValidateBrand).then(res => {
      const { data, status } = res;
      if (status > 400) {
        this.props.toggleBrandValidity(false);
        this.setState({
          brandNameValidationError: "unable to connect"
        });
      } else if (data.valid) {
        this.props.toggleBrandValidity(true);
        this.setState({
          brandNameValidationError: false
        });
      } else {
        this.props.toggleBrandValidity(false);
        this.setState({
          brandNameValidationError: data.message
        });
      }
    });
  }, 2000);

  handleNegotiate = e => {
    this.props.dispatch(
      this.props.change(
        "brandForm",
        `brand-${this.props.brand.id}-is_negotiable`,
        e.target.checked
      )
    );
    if (e.target.checked == true) {
      this.props.dispatch(
        this.props.change(
          "brandForm",
          `brand-${this.props.brand.id}-rate_per_minute`,
          "0"
        )
      );
    } else {
      this.props.dispatch(
        this.props.change(
          "brandForm",
          `brand-${this.props.brand.id}-rate_per_minute`,
          null
        )
      );
    }
  };

  render() {
    const brandId = this.props.brand.id;
    const { brandFormValues } = this.props;
    return (
      <Form>
        <>
          <div className="d-flex justify-content-between">
            {/* Brand name */}
            <div className=" ">
              {this.props.editBrand && (
                <h5 style={{ marginLeft: "15px", fontWeight: "bold" }}>
                  {capitalize(this.props.brand.attributes.name)}
                </h5>
                
              )}
              <p style={{marginLeft: "15px", color: "#494949", fontSize: "15px", paddingTop:"7px"}}>Last Updated At : {moment.utc(this.props.brand.attributes.updated_at).local().format("MMM DD YYYY hh:mm A")}</p>
            </div>

            <div
              style={{
                display: this.props.editBrand ? "flex" : "none",
                justifyContent: "flex-end"
              }}
            >
              {/* Checkbox  for Negotiation in manage brand form */}
              <FormGroup className="tagup--chkbox checkBoxWrapper">
                <div>
                  <Label
                    className="d-flex"
                    style={{
                      padding: "7px 10px 7px 10px",
                      border: "1px solid #e44b32 ",
                      marginTop: "0px",
                      borderRadius: "3px",
                      fontSize: "14px",
                      marginRight: " 8px",
                      lineHeight: "normal",
                      width: "135px",
                      height: "35px"
                    }}
                  >
                    <Field
                      type="checkbox"
                      component="input"
                      name={`brand-${this.props.brand.id}-is_negotiable`}
                      onChange={this.handleNegotiate}
                      checked={
                        brandFormValues &&
                        brandFormValues[`brand-${brandId}-rate_per_minute`] === "0"
                      }
                    />{" "}
                    <p style={{ color: "#e44b32 " }}>Negotiate ?</p>
                    <span
                      className="checkmark filler-check"
                      style={{
                        color: "#e44b32 ",
                        border: "none",
                        top: "7px",
                        marginLeft: "98px"
                      }}
                    />
                  </Label>{" "}
                </div>
              </FormGroup>
              {/* Remove Button */}
              <div>
                <FormGroup className="ml-1" style={{ marginTop: "3px" }}>
                  <Tooltip
                    item={
                      <i
                        className="fa fa-trash"
                        style={{ marginRight: "19px", color: "#e44b32" }}
                        onClick={() => {
                          this.props.handleRemoveBrand(this.props.brand.id);
                        }}
                      />
                    }
                    id={`brand-${this.props.brand.id}-removeBtn`}
                    tooltipContent="Remove Brand"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div>
            <div className={`globalForm ${this.props.editBrand && "row"}`}>
              <FormGroup
                className={`${this.props.editBrand && "col-sm-6 col-md-6"}`}
              >
                <div className="globalInput ">
                  <Field
                    label="Name"
                    component={Input}
                    name={`brand-${this.props.brand.id}-name`}
                    validate={required}
                    onChange={value => this.validateBrandName(value)}
                    placeholder="Name"
                    externalerror={
                      this.state.brandNameValidationError
                        ? this.state.brandNameValidationError
                        : undefined
                    }
                  />
                </div>
              </FormGroup>
              <FormGroup
                className={`${this.props.editBrand && "col-sm-6 col-md-6"}`}
              >
                <div className="globalInput">
                  <Field
                    label="Email"
                    component={Input}
                    type="email"
                    name={`brand-${this.props.brand.id}-client_email`}
                    validate={[required, email]}
                    placeholder="Email"
                  />
                </div>
              </FormGroup>
              <FormGroup
                className={`${this.props.editBrand && "col-sm-3 col-md-3"}`}
              >
                <div className="globalInput">
                  <Field
                    label="Play time per day (in minutes)"
                    component={Input}
                    type="number"
                    name={`brand-${this.props.brand.id}-total_duration_minutes`}
                    placeholder="minutes"
                    validate={[required, isPositive]}
                    min={1}
                  />
                </div>
              </FormGroup>
              {this.props.editBrand == true ? (
                <FormGroup
                  className={`${this.props.editBrand && "col-sm-3 col-md-3"}`}
                >
                  <div className="globalInput ">
                    <div className="d-flex justify-content-start">
                      <Label>Rate Per Minute</Label>
                      {brandFormValues &&
                        brandFormValues[`brand-${brandId}-rate_per_minute`] ===
                        "0" ? (
                        <div className="ml-1">
                          <i
                            className="bx bxs-chat"
                            style={{
                              background: "#f0dc6f",
                              borderRadius: "25px",
                              padding: "5px 4px",
                              textAlign: "center",
                              color: "white",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              width: "24px"
                            }}
                          />
                        </div>
                      ) : brandFormValues && (brandFormValues[
                        `brand-${brandId}-rate_per_minute`
                      ] === null
                        || brandFormValues[
                        `brand-${brandId}-rate_per_minute`
                        ] === "") ? (
                        <></>
                      ) : (
                        <div className="ml-1">
                          <i
                            className="bx bxs-check-circle"
                            style={{
                              color: "#7bb465",
                              borderRadius: "25px",
                              padding: "3px 2.5px",
                              textAlign: "center",
                              textTransform: "capitalize",
                              fontSize: "24px",
                              width: "24px"
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <Field
                      component={Input}
                      type="number"
                      name={`brand-${this.props.brand.id}-rate_per_minute`}
                      placeholder="Nrs."
                      validate={[required]}
                      min={1}
                      disabled={
                        (
                          brandFormValues &&
                          brandFormValues[
                          `brand-${brandId}-rate_per_minute`
                          ] === "0"
                        )
                      }
                    />
                  </div>
                </FormGroup>
              ) : (
                <div
                  className="row"
                  style={{
                    marginLeft: "3px",
                    justifyContent: "space-between"
                  }}
                >
                  <FormGroup
                    className={`${this.props.editBrand && "col-sm-3 col-md-3"}`}
                  >
                    <div className="globalInput ">
                      <Field
                        label="Rate Per Minute"
                        component={Input}
                        type="number"
                        name={`brand-${this.props.brand.id}-rate_per_minute`}
                        placeholder="Nrs."
                        validate={[required]}
                        min={1}
                        disabled={
                          !!(
                            brandFormValues &&
                            brandFormValues[
                            `brand-${brandId}-rate_per_minute`
                            ] === "0"
                          )
                        }
                      />
                    </div>
                  </FormGroup>
                  {/* Checkbox  for Negotiation in Add Brand form */}
                  <FormGroup
                    className="tagup--chkbox checkBoxWrapper"
                    // style={{ top: "2.75rem" }}
                    style={{
                      display: this.props.editBrand ? "none" : "inline",
                      top: "2.75rem"
                    }}
                  >
                    <div>
                      <Label
                        className="d-flex"
                        style={{
                          padding: "7px 10px 7px 10px",
                          border: "1px solid #e44b32 ",
                          marginTop: "0px",
                          borderRadius: "3px",
                          fontSize: "14px",
                          marginRight: "15px",
                          lineHeight: "normal",
                          width: "204px",
                          height: "35px"
                        }}
                      >
                        <Field
                          type="checkbox"
                          component="input"
                          name={`brand-${this.props.brand.id}-is_negotiable`}
                          onChange={this.handleNegotiate}
                        />{" "}
                        <p style={{ color: "#e44b32 " }}>Negotiate ?</p>
                        <span
                          className="checkmark filler-check"
                          style={{
                            color: "#e44b32 ",
                            border: "none",
                            top: "3px",
                            marginLeft: "142px"
                          }}
                        />
                      </Label>{" "}
                    </div>
                  </FormGroup>
                </div>
              )}

              <FormGroup
                className={`${this.props.editBrand && "col-sm-3 col-md-3"}`}
              >
                <div className="globalInput">
                  <label>Start Date</label>
                  <Field
                    component={DatePicker}
                    dateFormat="yyyy/MM/dd"
                    name={`brand-${this.props.brand.id}-start_date`}
                    selected={
                      brandFormValues &&
                        brandFormValues[`brand-${brandId}-start_date`]
                        ? new Date(
                          brandFormValues[`brand-${brandId}-start_date`]
                        )
                        : null
                    }
                    onChange={input => {
                      this.props.dispatch(
                        this.props.change(
                          "brandForm",
                          `brand-${this.props.brand.id}-start_date`,
                          input
                        )
                      );
                    }}
                    validate={[required, brandStartdateIsBefore]}
                    placeholderText="YYYY-MM-DD"
                    popperPlacement={this.props.lastIndex ? "top" : "bottom"}
                  />
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {this.state.startDateErrorMsg}
                  </div>
                </div>
              </FormGroup>
              <FormGroup
                className={`${this.props.editBrand && "col-sm-3 col-md-3"}`}
              >
                <div className="globalInput">
                  <label>End Date</label>
                  <Field
                    label="End Date"
                    component={DatePicker}
                    dateFormat="yyyy/MM/dd"
                    name={`brand-${this.props.brand.id}-end_date`}
                    selected={
                      brandFormValues &&
                        brandFormValues[`brand-${brandId}-end_date`]
                        ? new Date(brandFormValues[`brand-${brandId}-end_date`])
                        : null
                    }
                    onChange={input => {
                      this.props.dispatch(
                        this.props.change(
                          "brandForm",
                          `brand-${this.props.brand.id}-end_date`,
                          input
                        )
                      );
                    }}
                    placeholderText="YYYY-MM-DD"
                    validate={[required, brandEnddateIsAfter]}
                    popperPlacement={this.props.lastIndex ? "top" : "bottom"}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
        </>
        {this.props.index !== this.props.brands.length - 1 && <hr />}
      </Form>
    );
  }
}
export default BrandForm;
