import React, { Component } from "react";

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap";
import { AddPlaylistStudioPlayButton } from "./addPlaylistPlayButton";

export class AddPlaylistStudioMobileViewHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { dropdownOpen: false };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  render() {
    const { playlistControl } = this.props;

    return (
      <div className="playlistStudio__header d-flex">
        <AddPlaylistStudioPlayButton
          popOne={this.props.popOne}
          random={this.props.random}
          playlistControl={this.props.playlistControl}
          playlistAttributeChange={this.props.playlistAttributeChange}
        />
        <UncontrolledDropdown>
          <DropdownToggle className=" iconButton toggleIconButton" caret>
            <i className="bx bx-list-ul" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem toggle={false}>
              {" "}
              <span
                role="presentation"
                onClick={() =>
                  this.props.handleControl(
                    !playlistControl.selectAll,
                    "selectAll"
                  )
                }
              >
                <i
                  className="fa fa-check"
                  style={{ opacity: playlistControl.selectAll ? "1" : "0" }}
                />
                All
              </span>
            </DropdownItem>
            <DropdownItem toggle={false}>
              {" "}
              <span
                role="presentation"
                onClick={() =>
                  this.props.handleControl(!playlistControl.date, "date")
                }
              >
                <i
                  className="fa fa-check"
                  style={{ opacity: playlistControl.date ? "1" : "0" }}
                />
                Date Limit
              </span>{" "}
            </DropdownItem>
            <DropdownItem toggle={false}>
              {" "}
              <span
                role="presentation"
                onClick={() =>
                  this.props.handleControl(
                    !playlistControl.restriction,
                    "restriction"
                  )
                }
              >
                <i
                  className="fa fa-check"
                  style={{ opacity: playlistControl.restriction ? "1" : "0" }}
                />
                Time Limit
              </span>{" "}
            </DropdownItem>
            <DropdownItem toggle={false}>
              {" "}
              <span
                role="presentation"
                onClick={() =>
                  this.props.handleControl(!playlistControl.weight, "weight")
                }
              >
                <i
                  className="fa fa-check"
                  style={{ opacity: playlistControl.weight ? "1" : "0" }}
                />
                Share
              </span>{" "}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
}
