/** Import Node Modules */
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactPaginate from "react-paginate";

/** Import Redux Library */
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { initialize, destroy, reset, change } from "redux-form";
import { Resources } from "./redux";

/** Import Local Components */
import ReportTable from "./modules/reportTable";
import ReportDetails from "./modules/reportDetail";
import { dummyList } from "./utils/dummyData";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      forcePage: undefined,
      page: 1,
      refetch: true,
      isLoading: true,
      url: "/reports?page=",
      drawer: false,
      timeFrame: "24.hour",
      basic: false,
      defaultVal: [{ label: "Last 24hrs", value: "24.hour" }],
      filter: {}
    };
    this.perPageCount = 500;
    Resources.getReports.url = `/reports?page=${1}&per_page=${
      this.perPageCount
    }&time_frame=24.hours`;
    this.props
      .Get(Resources.getReports)
      .then(res => {
        if (res.status === 403) {
          this.setState({ basic: true });
        } else {
          this.setState({ basic: false });
          if (res.data.meta.totalPages <= this.state.page) {
            this.setState({ refetch: false });
          }
        }
      })
      .then(() => this.setState({ isLoading: false }));
  }

  initializeFilter = () => {
    this.props.initialize("ReportFilter", {
      contentTags: []
    });
    this.setState({ filter: {} });
  };

  handleRefresh = () => {
    if (!this.state.refreshing) {
      Resources.getReports.url = `/reports?page=${1}&per_page=${
        this.perPageCount
      }`;
      this.setState({ refreshing: true, forcePage: 0 }, () => {
        this.props
          .Get(Resources.getReports)
          .then(() => {
            setTimeout(() => {
              this.setState({ refreshing: false });
            }, 3000);
          })
          .then(() => this.props.showNotification("Refreshed", "info"));
      });
      this.initializeFilter();
    }
  };

  handleNext = async () => {
    if (this.state.refetch) {
      this.setState(
        prevState => ({ page: prevState.page + 1 }),
        () => {
          // if index
          if (this.state.url.search("filter") === -1) {
            Resources.getReports.url = `${this.state.url}${this.state.page}`;
            this.props.Get(Resources.getReports).then(response => {
              if (response.data.meta.totalPages <= this.state.page) {
                this.setState({ refetch: false });
              }
            });
          }
          // else filter
          else {
            Resources.filterReports.url = `${this.state.url}&&page=${this.state.page}`;
            this.props.Get(Resources.filterReports).then(response => {
              if (response.data.meta.totalPages <= this.state.page) {
                this.setState({ refetch: false });
              }
            });
          }
        }
      );
    }
    return true;
  };

  handleFilter = (url, refetch = true) => {
    this.setState({ url, page: 1, refetch });
  };

  updateFilter = (filterObject) => {
    this.setState({ filter: filterObject });
  };

  handlePageClick = data => {
    window.scrollTo(0, 0);
    const { selected } = data;
    this.setState({ forcePage: undefined, isLoading: true });
    const { getReports } = Resources;
    if (this.state.url.search("filter_report?") > -1) {
      getReports.url = `${this.state.url}&&page=${selected + 1}`;
    } else {
      getReports.url = `/reports?page=${selected + 1}&per_page=${
        this.perPageCount
      }&time_frame=${this.state.timeFrame}`;
    }
    this.props
      .Get(getReports)
      .then(() => this.setState({ forcePage: selected, isLoading: false }));
  };

  handleToggleDrawer = flag => {
    this.setState({ drawer: flag });
  };

  updateTimeFrame = value => {
    this.setState({ timeFrame: value });
  };

  handleLoading = flag => {
    this.setState({ isLoading: flag });
  };

  handleTimeFrameChange = (value, label) => {
    this.setState({ defaultVal: [{ label, value }] });
    this.setState({ timeFrame: value });
    this.setState({ isLoading: true });
    Resources.getReports.url = `/reports?page=${1}&per_page=${
      this.perPageCount
    }&time_frame=${value}`;
    this.props
      .Get(Resources.getReports)
      .then(sucess => {
        if (sucess.data.meta.totalPages <= this.state.page) {
          this.setState({ refetch: false });
        }
      })
      .then(() => this.setState({ isLoading: false }));
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="d-block text-center">
          {/* <div className="lds-dual-ring" /> */}
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }

    return (
      <div className="mainPage">
        <div className="contentSection ">
          {/* {this.state.basic && (
            <div className="contentSectionOverlay">
              <div className="lockedOverlay">
                <i className="lockIcon bx bxs-lock" />
                <small className="lockedMessage">
                  <div> Premium Feature.</div> Please contact admin for further
                  details.
                </small>
              </div>
            </div>
          )} */}
          <ReactTooltip />

          <ReportTable
            {...this.props}
            handleFilter={this.handleFilter}
            roles={this.props.roles}
            permisssion={this.props.permisssion}
            // reportList={this.state.basic ? dummyList : this.props.reportList}
            reportList={this.props.reportList}
            // below are props required for report header
            handleRefresh={this.handleRefresh}
            initializeFilter={this.initializeFilter}
            refreshing={this.state.refreshing}
            campaignList={this.props.campaignList || []}
            handleToggleDrawer={this.handleToggleDrawer}
            drawer={this.state.drawer}
            updateTimeFrame={this.updateTimeFrame}
            handleTimeFrameChange={this.handleTimeFrameChange}
            defaultVal={this.state.defaultVal}
            timeFrame={this.state.timeFrame}
            filter={this.state.filter}
          />

          {this.props.reportList && this.props.reportList.length > 0 && (
            <div className="contentSectionPagination clearfix">
              <ReactPaginate
                pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                activeClassName="active"
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                previousLabel="<"
                forcePage={this.state.forcePage}
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                disabledClassName="paginationDisable"
              />
            </div>
          )}

          {/* {this.state.basic && (
            <div className="contentSectionPagination clearfix">
              <ReactPaginate
                pageCount={2}
                activeClassName="active"
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                previousLabel="<"
                forcePage={this.state.forcePage}
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                disabledClassName="paginationDisable"
              />
            </div>
          )} */}
          {/* </InfiniteScroll> */}
          <ReportDetails
            handleToggleDrawer={this.handleToggleDrawer}
            drawer={this.state.drawer}
            handleFilter={this.handleFilter}
            handleLoading={this.handleLoading}
            initializeFilter={this.initializeFilter}
            updateFilter={this.updateFilter}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list, status, loading, hasError, meta } = state.reports;

  return {
    reportList: list !== undefined ? list : [],
    loading,
    hasError,
    meta,
    status
  };
}

export default connect(
  mapStateToProps,
  { initialize, destroy, reset, change }
)(Reports);
