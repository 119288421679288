/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import { CollapseCalendarList } from "./collapseCalendarList";

import "../../../assets/stylesheet/big-calendar.css";
import { BigCalendarHeaderForDesktop } from "./BigCalendarHeaderForDesktop";
import { BigCalendarHeaderForMobile } from "./BigCalendarHeaderForMobile";
import DesktopBreakpoint from "../../../context/desktop_breakpoint";
import PhoneBreakpoint from "../../../context/phone_breakpoint";
import { CustomSelectViewForCalendar } from "./customSelectViewForCalendar";
import { CustomTimeDisplay } from "./customTimeDisplay";

export const customToolbar = (
  props,
  calendarTitleChange,
  calendarTitle,
  activateIput,
  disableInput,
  calendarInputRef,
  handleCalendarSave,
  disableCalendar,
  editCalendar,
  handleCalendarAction,
  calendarId,
  action,
  handleCancelChanges,
  calendarFormEventValues,
  toggleCalendarList,
  isCalendarListOpen
) => (
  <div className="d-flex" style={{ marginBottom: "12px" }}>
    <DesktopBreakpoint>
      <div className="d-flex">
        <CollapseCalendarList
          toggleCalendarList={toggleCalendarList}
          isCalendarListOpen={isCalendarListOpen}
        />
        <CustomTimeDisplay
          onNavigate={props.onNavigate}
          view={props.view}
          label={props.label}
          action={action}
          isCalendarListOpen={isCalendarListOpen}
          {...props}
        />
        <CustomSelectViewForCalendar
          onView={props.onView}
          views={props.views}
          {...props}
        />
      </div>
      <BigCalendarHeaderForDesktop
        calendarTitleChange={calendarTitleChange}
        calendarTitle={calendarTitle}
        activateIput={activateIput}
        disableInput={disableInput}
        calendarInputRef={calendarInputRef}
        handleCalendarSave={handleCalendarSave}
        disableCalendar={disableCalendar}
        editCalendar={editCalendar}
        handleCalendarAction={handleCalendarAction}
        calendarId={calendarId}
        action={action}
        handleCancelChanges={handleCancelChanges}
        calendarFormEventValues={calendarFormEventValues}
        isCalendarListOpen={isCalendarListOpen}
      />
    </DesktopBreakpoint>
    <PhoneBreakpoint>
      <div className="w-100">
        <div className=" d-flex">
          <CollapseCalendarList
            toggleCalendarList={toggleCalendarList}
            isCalendarListOpen={isCalendarListOpen}
          />
          <BigCalendarHeaderForMobile
            calendarTitleChange={calendarTitleChange}
            calendarTitle={calendarTitle}
            activateIput={activateIput}
            disableInput={disableInput}
            calendarInputRef={calendarInputRef}
            handleCalendarSave={handleCalendarSave}
            disableCalendar={disableCalendar}
            editCalendar={editCalendar}
            handleCalendarAction={handleCalendarAction}
            calendarId={calendarId}
            action={action}
            handleCancelChanges={handleCancelChanges}
            calendarFormEventValues={calendarFormEventValues}
            isCalendarListOpen={isCalendarListOpen}
          />
        </div>
        <div className="calendarDateTimeWrapper">
          <div className="d-flex calendarDateAndTime">
            <CustomTimeDisplay
              onNavigate={props.onNavigate}
              view={props.view}
              label={props.label}
              action={action}
              isCalendarListOpen={isCalendarListOpen}
              {...props}
            />
          </div>
          <div className="ml-auto">
            <CustomSelectViewForCalendar
              onView={props.onView}
              views={props.views}
              {...props}
            />
          </div>
        </div>
      </div>
    </PhoneBreakpoint>
  </div>
);
