import React, { Component } from "react";
import { Button } from "reactstrap";
import { Tooltip } from "../../../../core";
import { MultipleSelect } from "../../../../core/form/searchSelect";

class MetricsHeader extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    // const { refreshing } = this.props;
    const { isLoadingMetrics } = this.props;
    return (
      <div
        className="d-flex contentHeader justify-content-end metricsHeader"
        data-spy="affix"
        data-offset-top="197"
      >
        <div className="d-flex flex-row-reverse">
          <Tooltip
            item={
              <div>
                <div
                  role="button"
                  className=" iconButton"
                  onClick={() => this.props.handleRefresh()}
                  disabled={isLoadingMetrics}
                >
                  <i
                    className={`bx bx-revision ${
                      false ? "refresh-rotate " : ""
                    }`}
                  />{" "}
                </div>
              </div>
            }
            id="header-content-refresh"
            tooltipContent="Refresh"
          />
          <div className="reportSection-dropdown">
            <MultipleSelect
              style={{ padding: "1px", "margin-top": "1px" }}
              onChange={e => this.props.handleChange(e)}
              defaultValue={[{ label: "Last 15 mins", value: "15.mins" }]}
              options={[
                { label: "Last 15 mins", value: "15.minutes" },
                { label: "Last 1 hour", value: "1.hour" },
                { label: "Last 24 hours", value: "24.hours" },
                { label: "Last week", value: "1.week" }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MetricsHeader;
