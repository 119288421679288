/* eslint-disable no-plusplus */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { sortBy } from "lodash";
import Table from "reactstrap/lib/Table";
import { downloader } from "./dataLoader";
import Screen from "./screen";
import { Get } from "../../../../constant/thunk";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      formattedScreen: [],
      totalContent: 0,
      totalContentDownloaded: 0
    };
  }

  componentDidMount() {
    this.start(this.props.campaign);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.campaign !== this.props.campaign && this.props.isStart) {
  //     this.start(this.props.campaign);
  //     this.setState({ isLoading: true, formattedScreen: [] });
  //   }
  // }

  start = campaign => {
    const { screens } = campaign;
    let formattedScreen = [];
    let newContents = [];
    screens.map(screen => {
      // eslint-disable-next-line prettier/prettier
      const {
        localID,
        screenX,
        screenY,
        width,
        height,
        screenPosition,
        contents,
        scale
      } = screen;
      formattedScreen = [
        ...formattedScreen,
        {
          id: localID,
          width,
          height,
          screenPosition,
          screenX,
          screenY,
          contents: [],
          scale
        }
      ];
      contents.map((content, index) => {
        if (content.destroy !== 1) {
          newContents = [
            ...newContents,
            {
              url: content.preview,
              fetchCallback: this.fetchCallback,
              progressCallback: this.progressCallback,
              screenID: localID,
              contentThumbnail: content.contentThumbnail,
              contentID: content.contentLocalID,
              contentType:
                content.type === "content" ? content.contentType : content.type,
              duration: content.duration,
              position: index,
              backgroundColor: content.backgroundColor,
              textColor: content.textColor,
              delay: content.delay,
              direction: content.direction && content.direction.value,
              fontFamily: content.fontFamily && content.fontFamily.value,
              fontSize: content.fontSize,
              dynamicUrl: content.url,
              marquee: content.marquee,
              text: content.text,
              textAlign: content.textAlign,
              timezone: content.timezone,
              title: content.title,
              scaleFactor: content.scaleFactor,
              routeDuration: content.routeDuration,
              playlistThumbnailType: content.playlistThumbnailType
            }
          ];
        }
        return null;
      });
      return null;
    });
    this.setState({ formattedScreen: [...formattedScreen] });
    this.initiate(newContents);
  };

  fetchCallback = (
    data,
    screenID,
    contentThumbnail,
    contentID,
    duration,
    type,
    position,
    backgroundColor,
    textColor,
    delay,
    direction,
    fontFamily,
    fontSize,
    dynamicUrl,
    marquee,
    text,
    textAlign,
    timezone,
    title,
    scaleFactor,
    routeDuration,
    playlistThumbnailType
  ) => {
    const { formattedScreen } = this.state;

    this.setState({
      // eslint-disable-next-line react/no-unused-state
      fromatedScreen: formattedScreen.map(screen => {
        if (screen.id === screenID) {
          const temp = screen;
          temp.contents = [
            ...temp.contents,
            {
              data,
              contentThumbnail,
              contentID,
              duration,
              type,
              position,
              backgroundColor,
              textColor,
              delay,
              direction,
              fontFamily,
              fontSize,
              dynamicUrl,
              marquee,
              text,
              textAlign,
              timezone,
              title,
              scaleFactor,
              routeDuration,
              playlistThumbnailType
            }
          ];
          this.setState(state => ({
            totalContentDownloaded: state.totalContentDownloaded + 1
          }));

          return temp;
        }
        return screen;
      })
    });
    if (this.state.totalContent === this.state.totalContentDownloaded) {
      let newFormatedScreen = this.state.formattedScreen.map(item => {
        const temp = item;
        temp.contents = sortBy(item.contents, ["position"]);
        return temp;
      });
      newFormatedScreen = sortBy(newFormatedScreen, ["screenPosition"]);
      this.setState({ isLoading: false, formattedScreen: newFormatedScreen });
    }
  };

  progressCallback = data => {
    // console.error("*****", data);
  };

  initiate = contents => {
    this.setState({ totalContent: contents.length });
    contents.map(item => downloader({ ...item }));
  };

  render() {
    const { isLoading } = this.state;
    const {
      studioHeight,
      studioWidth,
      loaderMargin,
      loaderFont,
      verticalScale
    } = this.props.campaign;
    if (isLoading) {
      return (
        <div
          style={styles.parents(studioWidth, studioHeight)}
          className="campaignStudioWrapper__preview"
        >
          <p
            style={{
              color: "hsla(0,0%,100%,.3)",
              fontSize: loaderFont,
              display: "table",
              margin: loaderMargin
            }}
            data-text="Loading..."
          >
            Loading...
          </p>
        </div>
      );
    }
    return (
      <div style={styles.parents(studioWidth, studioHeight)}>
        {this.state.formattedScreen.map(item => (
          <Screen
            key={item.id}
            {...item}
            verticalScale={verticalScale}
            get={this.props.Get}
          />
        ))}
      </div>
    );
  }
}

const styles = {
  parents: (width, height) => ({
    height,
    width,
    maxHeight: height,
    maxWidht: width,
    position: "relative",
    border: "1px solid #cecece",
    backgroundColor: "#000"
  })
};

function mapStateToProps(state) {
  return {
    campaign: state.newCampaign.campaign
  };
}

export default connect(
  mapStateToProps,
  { Get }
)(Preview);
