import normalize from "json-api-normalizer";

export const Resources = {
  getNotifications: {
    url: "notifications",
    asyncActions: {
      init: "getNotification_INIT",
      success: "getNotification_SUCCESS",
      error: "getNotification_ERROR"
    }
  },
  getTotalNotifications: {
    url: "notifications",
    asyncActions: {
      init: "getTotalNotifications_INIT",
      success: "getTotalNotifications_SUCCESS",
      error: "getTotalNotifications_ERROR"
    }
  },
  resetNotificationCount: {
    url: "/notifications/read_alert",
    asyncActions: {
      init: "resetNotificationCount_INIT",
      success: "resetNotificationCount_SUCCESS",
      error: "resetNotificationCount_ERROR"
    }
  },

  notificationMarkAsRead: {
    url: "/notifications/id/read",
    asyncActions: {
      init: "resetNotificationCount_INIT",
      success: "resetNotificationCount_SUCCESS",
      error: "resetNotificationCount_ERROR"
    }
  }
};

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: true,
  hasError: undefined
};

export function notificationList(state = initialState, action = {}) {
  const { init, success, error } = Resources.getNotifications.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }
    case "updateNotificationCountFromWebSocket": {
      return {
        ...action.payload
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function totalNotificationList(state = initialState, action = {}) {
  const { init, success, error } = Resources.getTotalNotifications.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function updateNotificationCountFromWebSocket(data) {
  return (dispatch, getState) => {
    const { meta } = getState().notificationList;
    const state = getState().notificationList;
    if (meta) {
      state.meta.notification.count = data.count;
    }

    dispatch({
      type: "updateNotificationCountFromWebSocket",
      payload: state
    });
  };
}
