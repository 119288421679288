export function formatMetrics(data) {
  const neededData = {
    cpu: [],
    disk_used: [],
    memory_used: [],
    swap_used: [],
    temperature: [],
    uptime: [],
    timestamp: [],
    memory_total: [],
    swap_total: [],
    disk_total: [],
    network_upload: [],
    network_download: [],
    load_avg_1m: [],
    load_avg_5m: [],
    load_avg_15m: []
  };
  const formatedData = data.reduce((acc = neededData, i) => {
    const {
      attributes: {
        cpu_current,
        disk_current,
        memory_current,
        swap_current,
        temperature,
        timestamp,
        uptime,
        memory_total_GB,
        swap_total_MB,
        disk_total_GB,
        network_upload,
        network_download,
        load_avg_1m,
        load_avg_5m,
        load_avg_15m
        // disk_read,
        // disk_write
      }
    } = i;
    return {
      ...acc,
      cpu: [...(acc.cpu || []), cpu_current],
      disk: [...(acc.disk || []), disk_current],
      temperature: [...(acc.temperature || []), temperature],
      ram: [...(acc.ram || []), memory_current],
      swap: [...(acc.swap || []), swap_current],
      uptime: [...(acc.uptime || []), 1],
      timestamp: [...(acc.timestamp || []), timestamp],
      memory_total: [...(acc.memory_total || []), memory_total_GB],
      swap_total: [...(acc.swap_total || []), swap_total_MB],
      disk_total: [...(acc.disk_total || []), disk_total_GB],
      network_upload: [...(acc.network_upload || []), `-${network_upload}`],
      network_download: [...(acc.network_download || []), network_download],
      load_avg_1m: [...(acc.load_avg_1m || []), load_avg_1m],
      load_avg_5m: [...(acc.load_avg_5m || []), load_avg_5m],
      load_avg_15m: [...(acc.load_avg_15m || []), load_avg_15m]
      // disk_read: [...(acc.disk_read || []), disk_read],
      // disk_write: [...(acc.disk_write || []), disk_write]
    };
  }, {});
  return formatedData;
}
