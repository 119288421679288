/* eslint-disable camelcase */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from "reactstrap";

class AddToTimeLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedListTime: undefined,
      usePlaylistContentDuration: props.selectedList[0].type === "playlists"
    };
  }

  componentDidMount() {
    this.getDurationForContentOrPlaylist();
  }

  getDurationForContentOrPlaylist = () => {
    let selectedListTime = {};
    this.props.selectedList.forEach(item => {
      const duration_seconds = (item.attributes && item.attributes.duration_seconds) || "N/A";
      let minute;
      let second;
      if (duration_seconds !== "N/A") {
        minute = Math.floor(duration_seconds / 60);
        second = duration_seconds - minute * 60;
      } else {
        minute = 0;
        second = 15;
      }
      selectedListTime = { ...selectedListTime, [item.id]: { minute, second } };
    });
    this.setState({
      selectedListTime
    });
  };

  handleFormValueChange = ({ target: { name, value } }, id) => {
    this.setState(prevState => ({
      selectedListTime: {
        ...prevState.selectedListTime,
        [id]: { ...prevState.selectedListTime[id], [name]: value }
      }
    }));
  };

  toggle = () => {
    this.props.toggle(!this.props.modal);
  };

  handleControl = (value, name) => {
    this.setState({ [name]: value });
  };

  handleContentDurationActivationLogic = contentType => {
    if (contentType === undefined) {
      return false;
    }
    if (contentType === null) {
      return false;
    }
    if (contentType.search("video") > -1) {
      return true;
    }
    return false;
  };

  render() {
    const { usePlaylistContentDuration, selectedListTime } = this.state;
    const { primaryMethod, modal } = this.props;
    const isPlaylists =
      this.props.selectedList && this.props.selectedList[0]
        ? this.props.selectedList[0].type === "playlists"
        : undefined;
    return (
      <div>
        <Modal isOpen={modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Duration</ModalHeader>
          <ModalBody>
            {(!usePlaylistContentDuration || !isPlaylists) && (
              <React.Fragment>
                <h5>Set the duration for content:</h5>
                {this.props.selectedList.map(item => (
                  <div>
                    <div className="align-items-center">
                      <div className="media media-sort">
                        <div className="media-left">
                          <img
                            src={item.attributes.s3_thumbnail_medium_url}
                            className="media-object"
                            style={{ width: "70px", height: "50px" }}
                          />
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-2">{item.attributes.name}</h6>
                          <input
                            type="number"
                            className="modal-duration"
                            name="minute"
                            disabled={this.handleContentDurationActivationLogic(
                              item.attributes.content_type
                            )}
                            min={0}
                            max={59}
                            value={selectedListTime ? selectedListTime[item.id].minute : ""}
                            onChange={e => this.handleFormValueChange(e, item.id)}
                            placeholder="mm"
                          />
                          <b className="ml-2 mr-2">:</b>
                          <input
                            type="number"
                            className="modal-duration"
                            name="second"
                            disabled={this.handleContentDurationActivationLogic(
                              item.attributes.content_type
                            )}
                            min={0}
                            max={59}
                            value={selectedListTime ? selectedListTime[item.id].second : ""}
                            onChange={e => this.handleFormValueChange(e, item.id)}
                            placeholder="ss"
                          />
                          <small className="text-primary d-flex">Format mm:ss</small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            )}
            {isPlaylists && (
              <FormGroup check inline>
                <Label className="checkbox-wrp ml-0 mt-4">
                  <input
                    type="checkbox"
                    checked={usePlaylistContentDuration}
                    onChange={e =>
                      this.handleControl(e.target.checked, "usePlaylistContentDuration")
                    }
                  />
                  <span className="checkmark" style={{ top: "2px" }} />
                  <span className="block-element">Set as the playlist content duration.</span>
                </Label>
              </FormGroup>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggle}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() =>
                primaryMethod(selectedListTime, isPlaylists, usePlaylistContentDuration)
              }
            >
              {"Add"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AddToTimeLine;
