import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "../../../core";

export default class PlaylistFormHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
      editButtonDisabled: false
    };
  }

  componentDidMount() {
    this.activateInput();
  }

  activateInput = () => {
    const el = document.getElementById("playlist-title");
    el.readOnly = false;
    el.focus();
    el.value = el.value;
  };

  handlePlaylistSave = () => {
    this.setState(
      prevState => ({
        buttonDisabled: true
      }),
      () => {
        this.props.handlePlaylistSave().then(response => {
          !response && this.setState({ buttonDisabled: false });
        });
      }
    );
  };

  handlePlaylistUpdateAndsync = () => {
    this.setState(
      prevState => ({
        buttonDisabled: true
      }),
      () => {
        this.props.handlePlaylistSave(undefined, "WithSync").then(response => {
          !response && this.setState({ buttonDisabled: false });
        });
      }
    );
  };

  hideEditButton = hide => {
    if (hide) {
      this.setState(
        state => ({ editButtonDisabled: true }),
        () => {
          this.myInp.focus();
        }
      );
    } else {
      this.setState({ editButtonDisabled: false });
    }
  };

  render() {
    return (
      <div className="focuscampaignStudioWrapper__header playlist_header_name_input">
        {/* eslint-disable-next-line */}
        {/* {this.state.editButtonDisabled ? null : (
          <div
            onClick={() => {
              this.hideEditButton(true);
            }}
          >
            <i className="fas fa-pencil-alt" />
          </div>
        )} */}
        <input
          autoComplete="off"
          placeholder="Untitled Playlist"
          type="text"
          id="playlist-title"
          className="mr-auto headerTitle"
          value={this.props.playlistTitle}
          onChange={text => {
            this.props.playlistTitleChange(text.target.value);
          }}
          // disabled={!this.state.editButtonDisabled}
          onFocus={() => this.hideEditButton(true)}
          onBlur={() => this.hideEditButton(false)}
          ref={ip => {
            this.myInp = ip;
          }}
        />

        <Link to="/playlist">
          <button
            type="button"
            className="btn-outline-primary campaignStudioWrapper__header__buttons"
          >
            Cancel
          </button>
        </Link>
        <button
          type="button"
          className="primaryButton campaignStudioWrapper__header__buttons updatePlaylist"
          onClick={this.handlePlaylistSave}
          disabled={this.state.buttonDisabled}
        >
          {this.props.createPlaylist ? "Save " : this.props.updateText}
        </button>
        {!this.props.createPlaylist && (
          <button
            type="button"
            className="primaryButton campaignStudioWrapper__header__buttons updateAndSync"
            onClick={this.handlePlaylistUpdateAndsync}
            disabled={this.state.buttonDisabled}
          >
            {this.props.updateAndSyncText}
          </button>
        )}
      </div>
    );
  }
}
