import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { remove, differenceBy } from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { columns } from "../utils/contentArchiveUtils";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";
import { Tooltip } from "../../../core";

class ArchiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        size: false,
        dimension: false,
        duration: false,
        tags: false,
        date_created: false,
        date_updated: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      selectColumnStyle: { width: "40px" },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("selectedContents", [
            ...this.props.selectedContents,
            row
          ]);
        } else {
          const { selectedContents } = this.props;
          remove(selectedContents, { id: row.id });
          this.props.change("selectedContents", selectedContents);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("selectedContents", [
            ...this.props.selectedContents,
            ...rows
          ]);
        } else {
          const { selectedContents } = this.props;
          const formattedContents = differenceBy(selectedContents, rows, "id");
          this.props.change("selectedContents", formattedContents);
        }
      }
    };
    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.archivedContentList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleUnArchiveItem,
            this.props.handleSort,
            this.props.sortOrder,
            this.state.hiddenColumns
          )}
          columnToggle
        >
          {props => (
            <div>
              <div className="d-flex mt-3 mb-3">
                {this.props.selectedContentsLen > 0 && (
                  <div className="d-flex">
                    <Tooltip
                      item={
                        <div>
                          <div
                            role="presentation"
                            className="iconButton fas fa-history"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.handleUnArchiveContents();
                            }}
                          />
                        </div>
                      }
                      tooltipContent="Restore"
                      id="unarchive-content"
                    />
                    <Tooltip
                      item={
                        <div>
                          <div
                            role="presentation"
                            className="iconButton fa fa-trash"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.handleDeleteConfirmation();
                            }}
                          />
                        </div>
                      }
                      tooltipContent="Delete"
                      id="delete-archive-content"
                    />
                  </div>
                )}
                <div className="ml-auto">
                  <VisibleColumnToggler
                    hiddenColumns={this.state.hiddenColumns}
                    toggleHiddenColumns={this.toggleHiddenColumns}
                    columnToggleProps={{ ...props.columnToggleProps }}
                  />
                </div>
              </div>
              <BootstrapTable
                classes="contentSectionTable"
                hover={this.props.archivedContentList.length > 0}
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                keyField="id"
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                selectRow={selectRow}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

// export default ContentTable;
export default connect(state => {
  const selector = formValueSelector("SelectedContentsOnArchive");
  return {
    selectedContents: selector(state, "selectedContents") || []
  };
})(
  reduxForm({
    form: "SelectedContentsOnArchive"
  })(ArchiveTable)
);
