import React from "react";
import RouteAllowed from "../../../helper/isRouteAllowed";
import { SearchTagRefresh } from "../../../core";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

const DomainHeader = props => (
  <div className="d-flex contentHeader">
    <div className="mr-auto">
      {props.currentDomainName === "gurzu" && (
        <RouteAllowed roles={props.rolesDH} permission={props.permissionDH}>
          {/* <Link to="/dashboard/adddomain" className="mr-auto"> */}
          <button
            className="primaryButton primaryButton--contentHeader"
            onClick={() => props.handleDomainAction("create")}
            // onClick={handleSubmit(values => this.handleLogin(values))}
            type="submit"
          >
            <i className="fa fa-plus mr-2" />
            Add Domain
          </button>
          {/* </Link> */}
        </RouteAllowed>
      )}
    </div>
    <div className="d-flex flex-row-reverse">
      <SearchTagRefresh handleSearch={props.handleSearchValue} {...props} />
    </div>
    <VisibleColumnToggler
      hiddenColumns={props.hiddenColumns}
      toggleHiddenColumns={props.toggleHiddenColumns}
      columnToggleProps={props.columnToggleProps}
    />
  </div>
);

export default DomainHeader;
