import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Resources } from "../redux/calendarRedux";
import { BigCalendarHeaderForDesktop } from "../modules/BigCalendarHeaderForDesktop";
import Calendar from "../modules/calendar";
import BigCalendar from "react-big-calendar";
import { capitalize } from "lodash";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getHourMinutesAndSeconds } from "../../../../src/helper/helperFuncs";
import { buildInventory } from "../modules/inventory";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import { customToolbar } from "../modules/customToolBarForCalendar";

const localizer = BigCalendar.momentLocalizer(moment);

class CalendarInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventories: undefined,
      sectionData: undefined,
      calendarId: undefined,
      calendarTitle: undefined,
      sectionTitle: undefined,
      isLoading: true,
      isModalOpen: false,
      startDate: undefined,
      endDate: undefined
    };
    this.buildCalendarInventory(props.calendarId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.calendarId !== this.props.calendarId) {
      this.buildCalendarInventory(nextProps.calendarId);
    }
  }

  buildCalendarInventory = async calendarId => {
    if (calendarId) {
      const { showCalendar } = Resources;

      if (
        this.state.calendarId !== calendarId ||
        this.props.calendarDetails == undefined
      ) {
        process.nextTick(() => {
          this.setState({ isLoading: true });
        });
        this.setState({ calendarId });
        showCalendar.url = `/calendars/${calendarId}`;
        await this.props.Get(showCalendar);
      }

      const { calendarDetails } = this.props;

      const inventoryData = buildInventory(
        calendarDetails,
        this.state.startDate,
        this.state.endDate
      );
      const calendarTitle = Object.values(calendarDetails.calendars)[0]
        .attributes.name;

      const inventories = inventoryData
        ? Object.entries(inventoryData).flatMap(([eventDate, inventory]) => {
            return Object.entries(inventory.sections).map(
              ([sectionName, section]) => {
                return {
                  title: `${capitalize(sectionName)} (${
                    section.freeSlotPercentage
                  }% Filler Space)`,
                  start: new Date(eventDate + "T" + section.startTime),
                  end: new Date(eventDate + "T" + section.endTime),
                  sectionData: section,
                  name: capitalize(sectionName)
                };
              }
            );
          })
        : [];
      this.setState({ inventories, calendarTitle, isLoading: false });
      this.forceUpdate();
    } else {
      this.props.history.push("/calendar");
    }
  };

  onSectionSelect = section => {
    this.setState({
      sectionData: section.sectionData,
      sectionTitle: section.name
    });
    this.toggleInventoryModal();
  };

  eventStyleGetter = section => {
    const freeSlotPercentage = section.sectionData.freeSlotPercentage;
    return {
      style: {
        borderRadius: "8px",
        border: "none",
        background: `linear-gradient(to right, rgb(228 75 50) ${100 -
          freeSlotPercentage}%, rgb(185 71 52) ${freeSlotPercentage}%)`
      }
    };
  };

  toggleInventoryModal = () => {
    this.setState(state => ({ isModalOpen: !state.isModalOpen }));
  };

  formatInventorySectionDetails = () => {
    const { sectionData } = this.state;
    return (
      <div className="inventory-detail">
        <div className="summary">
          <p>
            {" "}
            <strong>
              {sectionData.contentDuration
                ? this.formatDuration(sectionData.contentDuration)
                : "0 sec"}
            </strong>{" "}
            Total Content Duration{" "}
          </p>
          <p>
            {" "}
            <strong>
              {sectionData.freeSlotDuration
                ? this.formatDuration(sectionData.freeSlotDuration)
                : "0 sec"}
              {sectionData.freeSlotDuration
                ? `(${sectionData.freeSlotPercentage}%)`
                : ""}
            </strong>{" "}
            Free Slots Available{" "}
          </p>
        </div>

        {sectionData.events ? this.formatSectionEventDetails(sectionData) : ""}
      </div>
    );
  };

  formatSectionEventDetails = section => {
    return (
      <Accordion className="accordion" allowZeroExpanded>
        {Object.values(section.events).map((event, index) => (
          <AccordionItem key={index}>
            <AccordionItemHeading key={index}>
              <AccordionItemButton key={index}>
                <div className="event-header">
                  <p className="title">{capitalize(event.name)}</p>
                  <p className="description">
                    Free Slots Duration:{" "}
                    {event.freeSlotDuration
                      ? this.formatDuration(event.freeSlotDuration)
                      : "0 sec"}
                  </p>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {event.screens ? this.formatEventScreenDetails(event) : ""}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    );
  };

  formatEventScreenDetails = event => {
    return Object.values(event.screens).map((screen, screenIndex) => {
      return (
        <div className="event-body" key={screenIndex}>
          <p className="region-title">{screen.name}</p>
          {Object.entries(screen.freeSlots).length ? (
            Object.entries(screen.freeSlots).map(
              ([contentName, freeSlotArr], fsIndex) => {
                const freeSlotRow = Object.values(freeSlotArr)
                  .reverse()
                  .map((i, index) => {
                    return (
                      <div className="free-slot" key={index}>
                        {index === 0 && (
                          <p className="content-name">
                            {" "}
                            {contentName.substr(0, 30)}{" "}
                          </p>
                        )}{" "}
                        {i.range}{" "}
                        <strong className="duration">
                          {this.formatDuration(i.duration)}
                        </strong>
                      </div>
                    );
                  });
                return (
                  <div className="content-section" key={fsIndex}>
                    {freeSlotRow}
                  </div>
                );
              }
            )
          ) : (
            <p className="no-free-slot">No Free Slot Available</p>
          )}
        </div>
      );
    });
  };

  formatDuration = duration => {
    const { hours, minutes, seconds } = getHourMinutesAndSeconds(duration);
    return (
      (hours ? hours + " hr " : "") +
      (minutes ? minutes + " min " : "") +
      (seconds ? seconds + " sec" : "")
    );
  };

  isSameDay(date1, date2) {
    let currentDate = new Date(date1);
    let previousDate = new Date(date2);
    return (
      previousDate.getFullYear() === currentDate.getFullYear() &&
      previousDate.getDate() === currentDate.getDate() &&
      previousDate.getMonth() === currentDate.getMonth()
    );
  }

  handleDateChange = props => {
    let currentPageYear = new Date(props.date).getFullYear();
    let currentPageMonth = new Date(props.date).toLocaleString("default", {
      month: "long"
    });
    let startDate, endDate;
    if (props.view === "week") {
      let regexToGetMonths = new RegExp(/[a-z]+/gi);
      let regexToGetDay = new RegExp(/[0-9]+/g);
      let matchedMonths = props.label.match(regexToGetMonths);
      let matchedDays = props.label.match(regexToGetDay);

      if (
        matchedMonths.length === 2 &&
        matchedMonths[0] === "December" &&
        currentPageMonth === "December"
      ) {
        startDate = new Date(
          `${matchedMonths[0]} ${matchedDays[0]}, ${currentPageYear}`
        );
        endDate = new Date(
          `${matchedMonths[1]} ${matchedDays[1]}, ${currentPageYear + 1}`
        );

        if (
          !this.isSameDay(startDate, this.state.startDate) &&
          !this.isSameDay(endDate, this.state.endDate)
        ) {
          this.setState({ startDate, endDate }, () =>
            this.buildCalendarInventory(this.props.calendarId)
          );
        }
      } else if (matchedMonths.length === 2) {
        startDate = new Date(
          `${matchedMonths[0]} ${matchedDays[0]}, ${currentPageYear}`
        );
        endDate = new Date(
          `${matchedMonths[1]} ${matchedDays[1]}, ${currentPageYear}`
        );
        if (
          !this.isSameDay(startDate, this.state.startDate) &&
          !this.isSameDay(endDate, this.state.endDate)
        ) {
          this.setState({ startDate, endDate }, () =>
            this.buildCalendarInventory(this.props.calendarId)
          );
        }
      } else {
        startDate = new Date(
          `${matchedMonths[0]} ${matchedDays[0]}, ${currentPageYear}`
        );
        endDate = new Date(
          `${matchedMonths[0]} ${matchedDays[1]}, ${currentPageYear}`
        );
        if (
          !this.isSameDay(startDate, this.state.startDate) &&
          !this.isSameDay(endDate, this.state.endDate)
        ) {
          this.setState({ startDate, endDate }, () =>
            this.buildCalendarInventory(this.props.calendarId)
          );
        }
      }
    } else if (props.view === "month") {
      startDate = new Date(`${currentPageMonth}, ${currentPageYear}`);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
      if (
        !this.isSameDay(startDate, this.state.startDate) &&
        !this.isSameDay(endDate, this.state.endDate)
      ) {
        this.setState({ startDate, endDate }, () =>
          this.buildCalendarInventory(this.props.calendarId)
        );
      }
    } else if (props.view === "day") {
      let startDate = props.date.toDateString();
      if (
        !this.isSameDay(startDate, this.state.startDate) &&
        !this.isSameDay(startDate, this.state.endDate)
      ) {
        this.setState({ startDate, endDate: startDate }, () =>
          this.buildCalendarInventory(this.props.calendarId)
        );
      }
    }
  };

  handleCancelChanges = () => {
    this.props.history.push("/calendar");
    this.props.handleCalendarAction("Edit");
    this.props.toggleCalendarList(true);
  };

  render() {
    const { isLoading } = this.state;
    this.props.handleCalendarAction("Inventory");
    return (
      <div>
        {isLoading ? (
          <div className="d-block text-center">
            <svg
              className="spinner"
              viewBox="0 0 50 50"
              style={{
                margin: !this.props.isCalendarListOpen && "22% 0 0 -5px"
              }}
            >
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="4"
              />
            </svg>
          </div>
        ) : (
          <div>
            <div className="clearfix" />
            <div className="bg-white">
              <div className="calendar-block">
                {this.state.inventories !== undefined && (
                  <BigCalendar
                    selectable
                    localizer={localizer}
                    events={this.state.inventories}
                    onSelectEvent={this.onSectionSelect}
                    eventPropGetter={this.eventStyleGetter}
                    defaultView={BigCalendar.Views.WEEK}
                    views={["month", "week", "day"]}
                    components={{
                      toolbar: props => {
                        this.handleDateChange(props);
                        return customToolbar(
                          props,
                          this.props.calendarTitleChange,
                          this.state.calendarTitle + " - Inventory",
                          this.props.activateIput,
                          this.props.disableInput,
                          this.props.calendarInputRef,
                          this.props.handleCalendarSave,
                          this.props.disableCalendar,
                          this.props.editCalendar,
                          this.props.handleCalendarAction,
                          this.props.calendarId,
                          this.props.action,
                          this.props.handleCancelChanges,
                          this.props.calendarFormEventValues,
                          this.props.toggleCalendarList,
                          this.props.isCalendarListOpen
                        );
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={this.state.isModalOpen}
          toggle={this.toggleInventoryModal}
        >
          <ModalHeader toggle={() => this.toggleInventoryModal()}>
            {this.state.calendarTitle + " - " + this.state.sectionTitle}
          </ModalHeader>
          <ModalBody className="globalForm">
            {this.state.sectionData ? this.formatInventorySectionDetails() : ""}
          </ModalBody>
          <ModalFooter className="d-flex">
            <Button
              className="btn-outline-primary"
              onClick={this.toggleInventoryModal}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  calendarDetails: state.calendarDetails.list
}))(CalendarInventory);
