import React from "react";
import { Input as Field, Label, FormFeedback } from "reactstrap";

const Input = ({
  value,
  info,
  input,
  label,
  labelClass,
  checked,
  inputClass,
  handleInputChange,
  meta: { touched, error, warning },
  plsylist,
  ...rest
}) => (
  <div className={touched && error ? "" : undefined}>
    <Label for="name" className={labelClass} style={{ display: `${label ? "inlineBlock" : "none"}` }}>
      {label}
      {info && <small className="text-primary ml-2">*{`(${info})`}</small>}
    </Label>
    <Field
      {...input}
      {...rest}
      className={inputClass}
      checked={checked}
      invalid={!!(touched && error) || !!rest.externalerror}
      onChange={e => {
        input.onChange(e.target.value);
        handleInputChange(e.target.value);
      }}
    />
    {touched &&
      ((error && <FormFeedback>{error}</FormFeedback>) ||
        (warning && <span>{warning}</span>))}
    {!error && rest.externalerror && <div className="invalid-feedback" style={{ display: "block" }}>
      {rest.externalerror}
    </div>}
  </div>
);

/** TODO: Sarina need to move to scss */

Input.defaultProps = {
  value: undefined,
  input: { onChange: () => { } },
  meta: { touched: undefined, error: undefined, warning: undefined },
  label: undefined,
  info: undefined,
  handleInputChange: () => { }
};

export default Input;
