export const contextOptions = [{ value: "temperature", label: "Temperature" }];

export const inequalityOptions = [
  { value: ">", label: ">" },
  { value: ">=", label: ">=" },
  { value: "==", label: "==" },
  { value: "<=", label: "<=" },
  { value: "<", label: "<" }
];

// export function convertToLabel(inequality) {
//   let value = "";
//   switch (inequality) {
//     case ">":
//       value = "Less Than";
//       break;
//     case ">=":
//       value = "Less Than or Equal";
//       break;
//     case "==":
//       value = "Equal To";
//       break;
//     case "<=":
//       value = "Greater Than or Equal";
//       break;
//     case "<":
//       value = "Greater Than";
//       break;
//     default:
//   }
//   return value;
// }
