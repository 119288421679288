import React, { Component } from "react";
import { Popover, PopoverBody, PopoverHeader, Label } from "reactstrap";
import { find, some } from "lodash";
import { Tooltip } from "../../../../core";
import TimeLineContentSort from "../sortTimelineContent";
import { newCoordinate } from "../../utls/helper";
import PhoneBreakPoint from "../../../../context/phone_breakpoint";
import DesktopBreakpoint from "../../../../context/desktop_breakpoint";

export default class SingleTimeActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopOverOpen: false,
      deletePopOver: false
    };
    this.xinputRef = React.createRef();
    this.yinputRef = React.createRef();
  }

  handlePopOver = flag => {
    this.setState({ isPopOverOpen: flag });
    if (this.state.isPopOverOpen) {
      this.handleRemoveSelectedScreen();
    }
  };

  handleRemoveSelectedScreen = () => {
    const { campaign, updateCampaign } = this.props;
    campaign.setSelectedScreen(undefined);
    updateCampaign(campaign);
  };

  handleDeletePopOver = (flag, isPlaylistLocked) => {
    if (isPlaylistLocked) {
      return null;
    }
    this.setState({ deletePopOver: flag });
    if (this.state.deletePopOver) {
      this.handleRemoveSelectedScreen();
    }
  };

  handleScreenDelete = () => {
    const { campaign, updateCampaign } = this.props;
    campaign.removeScreen(campaign.selectedScreen.localID);
    updateCampaign(campaign);
    this.handleDeletePopOver(false);
    this.handleRemoveSelectedScreen();
  };

  handleChangeWidth = (e, selectedScreen) => {
    const { campaign, updateCampaign } = this.props;
    const temp = selectedScreen;
    let width = Number(e.target.value);
    if (width > campaign.screenWidth) {
      width = campaign.screenWidth;
    }
    const newWidth = width / selectedScreen.horizontalScale;
    const xFromScreen = campaign.studioWidth - selectedScreen.screenX;
    if (newWidth <= xFromScreen) {
      temp.width = Number(newWidth);
    } else {
      temp.screenX = Number(
        Number(selectedScreen.screenX) -
          (Number(newWidth) - Number(xFromScreen))
      );
      temp.width = Number(
        Number(xFromScreen) + Number(newWidth) - Number(xFromScreen)
      );
    }
    const formattedScreen = campaign.getScale(
      temp,
      temp.fillPatternImage,
      temp.scale,
      temp.defaultImageContentType
    );
    campaign.updateScreen(selectedScreen.localID, formattedScreen);
    updateCampaign(campaign);
  };

  handleChangeHeight = (e, selectedScreen) => {
    const { campaign, updateCampaign } = this.props;
    const temp = selectedScreen;
    let height = Number(e.target.value);
    if (height > campaign.screenHeight) {
      height = campaign.screenHeight;
    }
    const newHeight = height / selectedScreen.verticalScale;
    const yFromScreen = campaign.studioHeight - selectedScreen.screenY;
    if (newHeight <= yFromScreen) {
      temp.height = Number(newHeight);
    } else {
      temp.screenY = Number(
        Number(selectedScreen.screenY) -
          (Number(newHeight) - Number(yFromScreen))
      );
      temp.height =
        Number(yFromScreen) + Number(newHeight) - Number(yFromScreen);
    }
    const formattedScreen = campaign.getScale(
      temp,
      temp.fillPatternImage,
      temp.scale,
      temp.defaultImageContentType
    );
    campaign.updateScreen(selectedScreen.localID, formattedScreen);
    updateCampaign(campaign);
  };

  handleChangeAxis = (e, selectedScreen, selectedAxis) => {
    const { campaign, updateCampaign } = this.props;
    const temp = selectedScreen;
    let newCordd;
    const axisToBeChanged = Number(e.target.value);
    if (this[`${selectedAxis}inputRef`].current) {
      this[`${selectedAxis}inputRef`].current.value = axisToBeChanged;
    }

    if (selectedAxis === "x") {
      newCordd = newCoordinate(
        axisToBeChanged,
        selectedScreen.screenY,
        selectedScreen.height,
        selectedScreen.width,
        campaign.studioWidth,
        campaign.studioHeight
      );
      temp.screenX = newCordd.x;
    } else {
      newCordd = newCoordinate(
        selectedScreen.screenX,
        axisToBeChanged,
        selectedScreen.height,
        selectedScreen.width,
        campaign.studioWidth,
        campaign.studioHeight
      );
      temp.screenY = newCordd.y;
    }
    campaign.updateScreen(selectedScreen.localID, temp);
    updateCampaign(campaign);
  };

  handleEnableSnap = flag => {
    const { campaign, updateCampaign } = this.props;
    campaign.enableSnap(flag);
    updateCampaign(campaign);
  };

  handleChangeScreenScale = (screen, type) => {
    const { campaign } = this.props;
    const formattedScreen = campaign.getScale(
      screen,
      screen.fillPatternImage,
      type,
      screen.defaultImageContentType
    );
    formattedScreen.scale = type;
    campaign.updateScreen(formattedScreen.localID, formattedScreen);
    this.props.updateCampaign(campaign);
  };

  render() {
    const { isPopOverOpen, deletePopOver } = this.state;
    const { selectedScreen } = this.props.campaign;
    const screen =
      selectedScreen &&
      find(this.props.campaign.screens, {
        localID: selectedScreen.localID
      });

    const isPlaylistLocked =
      !this.props.roles.includes("SuperAdmin") &&
      selectedScreen &&
      some(
        selectedScreen.contents,
        o => o.type === "playlist" && o.meta.attributes.locked
      );
    let isXDisabled = false;
    let isYDisabled = false;
    if (screen) {
      if (this.props.campaign.orientation === "Portrait") {
        isYDisabled =
          (screen.height * screen.verticalScale).toFixed(0) === "1920";
        isXDisabled =
          (screen.width * screen.horizontalScale).toFixed(0) === "1080";
      } else {
        isYDisabled =
          (screen.height * screen.verticalScale).toFixed(0) === "1080";
        isXDisabled =
          (screen.width * screen.horizontalScale).toFixed(0) === "1920";
      }
    }
    return (
      <div className="d-flex campaignStudioWrapper__icons">
        <TimeLineContentSort
          selectedScreen={selectedScreen}
          screen={screen}
          {...this.props}
          handleRemoveSelectedScreen={this.handleRemoveSelectedScreen}
        />
        <Tooltip
          item={
            <i
              className={`ml-2 fas fa-cog ${!selectedScreen &&
                "deleteDisabled"}`}
              id="Popover1"
              onClick={() => this.handlePopOver(true)}
              role="presentation"
              disabled
            />
          }
          id="setting"
          tooltipContent="Setting"
        />

        <Tooltip
          item={
            <i
              id="Delete1"
              role="presentation"
              onClick={() => this.handleDeletePopOver(true, isPlaylistLocked)}
              className={`ml-2 fa fa-trash ${!selectedScreen &&
                "deleteDisabled"} ${isPlaylistLocked &&
                "deleteDisabled deleteDisabled--mod"} `}
              disabled={!selectedScreen || isPlaylistLocked}
            />
          }
          id="deleteScreen"
          tooltipContent="Delete"
        />
        <DesktopBreakpoint>
          <Label className="d-flex checkBoxWrapper ">
            <input
              type="checkbox"
              onChange={e => this.handleEnableSnap(e.target.checked)}
              checked={this.props.campaign.isSnapEnabled}
            />{" "}
            <p className="snapContents">Snap Contents</p>
            <span className="checkmark" />
          </Label>{" "}
        </DesktopBreakpoint>

        {this.props.restrictedCampaign &&
          this.props.campaign.showExceededMessage && (
            <span className="exceed">
              <i className="fas fa-exclamation-triangle" />
              {/* <div className="exceededCampaignBox" /> */}
              <span className="exceededCampaignText">
                {" "}
                Campaign length limit exceeded
              </span>
            </span>
          )}

        <Popover
          placement="down"
          isOpen={deletePopOver}
          target="Delete1"
          toggle={() => this.handleDeletePopOver(false, isPlaylistLocked)}
          className="screenSetting"
          trigger="legacy"
        >
          <PopoverBody>
            {selectedScreen ? (
              <div>
                <span>
                  Are you sure you want to delete{" "}
                  <strong>{`${selectedScreen.screenName}?`}</strong>
                </span>
                <div
                  className="mt-2"
                  style={{ display: "block", float: "right" }}
                >
                  <button
                    type="button"
                    className=" btn-outline-primary singleTimeCancelButton"
                    onClick={() => this.handleDeletePopOver(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="primaryButton primaryButton--singleTimeLine"
                    type="submit"
                    onClick={() => this.handleScreenDelete(true)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ) : (
              <span>You must select a region.</span>
            )}
          </PopoverBody>
        </Popover>

        <Popover
          placement="down"
          isOpen={isPopOverOpen}
          target="Popover1"
          toggle={() => this.handlePopOver(false)}
          className="screenSetting"
          trigger="legacy"
        >
          <PopoverBody>
            {selectedScreen ? (
              <div>
                <div className="screenSetting__block">
                  <div className="screenSetting__title">Resolution</div>
                  <Label className="mr-1 ml-1">W</Label>
                  <input
                    type="number"
                    id="width"
                    name="width"
                    min="1"
                    max={this.props.campaign.screenWidth}
                    onChange={e => this.handleChangeWidth(e, screen)}
                    value={
                      screen &&
                      (screen.width * screen.horizontalScale).toFixed(0)
                    }
                  />
                  <Label className="mr-1 ml-2">H</Label>
                  <input
                    type="number"
                    id="height"
                    name="height"
                    min="1"
                    max={this.props.campaign.screenHeight}
                    value={
                      screen &&
                      (screen.height * screen.verticalScale).toFixed(0)
                    }
                    onChange={e => this.handleChangeHeight(e, screen)}
                  />
                </div>

                <div className="screenSetting__block">
                  <div className="screenSetting__title">Position</div>
                  <Label className="mr-1 ml-1">X</Label>
                  <input
                    type="number"
                    id="xAxis"
                    name="xAxis"
                    min="0"
                    onChange={e => this.handleChangeAxis(e, screen, "x")}
                    value={screen && Number(screen.screenX).toFixed(0)}
                    ref={this.xinputRef}
                    disabled={isXDisabled}
                  />
                  <Label className="mr-1 ml-2">Y</Label>
                  <input
                    type="number"
                    id="yAxis"
                    name="yAxis"
                    min="0"
                    value={screen && Number(screen.screenY).toFixed(0)}
                    onChange={e => this.handleChangeAxis(e, screen, "y")}
                    ref={this.yinputRef}
                    disabled={isYDisabled}
                  />{" "}
                </div>
                <div>
                  <div className="screenSetting__title">Scale</div>

                  <div className="d-flex">
                    <div>
                      <img
                        src={
                          screen.scale === "stretch"
                            ? require(`../../../../assets/images/stretchActive.png`)
                            : require(`../../../../assets/images/stretch.png`)
                        }
                        alt="IMG"
                        onClick={() =>
                          this.handleChangeScreenScale(screen, "stretch")
                        }
                      />
                      <p className="mt-1">Stretch</p>
                    </div>
                    <div>
                      <img
                        src={
                          screen.scale === "fit"
                            ? require("../../../../assets/images/fitActive.png")
                            : require("../../../../assets/images/fit.png")
                        }
                        alt="IMG"
                        onClick={() =>
                          this.handleChangeScreenScale(screen, "fit")
                        }
                      />
                      <p className="mt-1">Fit</p>
                    </div>
                    <div>
                      <img
                        src={
                          screen.scale === "fill"
                            ? require("../../../../assets/images/fillActive.png")
                            : require("../../../../assets/images/fill.png")
                        }
                        alt="IMG"
                        onClick={() =>
                          this.handleChangeScreenScale(screen, "fill")
                        }
                      />
                      <p className="mt-1">Fill</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span>You must select a region.</span>
            )}
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}
