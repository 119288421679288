import { Map } from "../../helper/routeMap";

/** Network */
import Playlist from "./playlist";
import NoRoutes, { UpcomingFeatures } from "../../core/layout/noroutes";

import AddPlaylist from "./pages/addPlaylist";
import EditPlaylist from "./pages/editPlaylist";
import ArchivePlaylist from "./pages/archivePlaylists";

const mainPath = "/playlist";
const pathCreator = `${mainPath}`;
const subPathCreator = subPath => `${pathCreator}${subPath}`;
const feature = "Playlist";

export default [
  {
    path: subPathCreator("/create"),
    component: Map(AddPlaylist),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}`,
    component: Map(Playlist),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/archive"),
    component: Map(ArchivePlaylist),
    exact: true,
    secured: true,
    feature
  },
  // {
  //   path: subPathCreator("/show/:id"),
  //   component: Map(PlaylistDetails),
  //   exact: true,
  //   secured: true,
  //   feature
  // },
  {
    path: subPathCreator("/edit/:id/"),
    component: Map(EditPlaylist),
    exact: true,
    secured: true
  },
  {
    path: `${pathCreator}/upcoming`,
    component: Map(UpcomingFeatures),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/*`,
    component: Map(NoRoutes),
    exact: true,
    secured: true,
    feature
  }
];
