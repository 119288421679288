import moment from "moment";

export const Widget = {
  weather: {
    id: 1,
    widgetName: "Weather",
    icon: "fa fa-smog",
    color: "#35678f",
    date: moment().format("LLL"),
    location: "Kathmandu",
    temp: `27&deg`,
    url: "some url"
  },
  TextW: {
    id: 2,
    widgetName: "Text",
    icon: "fas fa-heading",
    color: "#f4864c",
    text: "Sample Text",
    marquee: false,
    fontSize: 16,
    width: 100,
    height: 100,
    backgroundColor: "#000",
    textColor: "white",
    textAlign: "center"
  },
  UrlW: {
    id: 3,
    widgetName: "URL",
    icon: "fas fa-network-wired",
    color: "#09bda0",
    url: "https://splashnode.com",
    width: 300,
    height: 300,
    backgroundColor: "#000"
  },
  RSS: {
    id: 4,
    widgetName: "RSS",
    icon: "fas fa-rss",
    color: "#d9ad2b",
    url: "https://news.google.com/rss",
    backgroundColor: "#000",
    fontSize: 16,
    textColor: "#fff"
  },
  DateTime: {
    id: 5,
    widgetName: "DateTime",
    icon: "fas fa-clock",
    color: "#a9B2c1",
    backgroundColor: "#000",
    textColor: "#fff"
  },
  HlsW: {
    id: 6,
    widgetName: "HLS",
    icon: "fas fa-stream",
    color: "#a3c280",
    url: "",
    width: 300,
    height: 300,
    backgroundColor: "#000"
  },
  YoutubeW: {
    id: 7,
    widgetName: "Youtube",
    icon: "fas fa-play",
    color: "#c4302b",
    url: "",
    width: 300,
    height: 300,
    backgroundColor: "#000"
  },
  PicToScreenW: {
    id: 8,
    widgetName: "PicToScreen",
    icon: "fas fa-sitemap",
    color: "#339af0",
    url: "",
    width: 300,
    height: 300,
    backgroundColor: "#000"
  },
  RouteWidget: {
    id: 8,
    widgetName: "Route",
    icon: "fas fa-route",
    color: "rgb(238, 117, 139)",
    fontSize: 16,
    width: 300,
    height: 300,
    backgroundColor: "#000",
    textColor: "white",
    textAlign: "center",
    startingLocation: "New York, NY, USA",
    destinationLocation: "California City, CA, USA",
    routeDuration: ""
  },
  TransparentWidget: {
    id: 9,
    widgetName: "Transparent",
    icon: "fas fa-chess-board",
    color: "#00000080",
    width: 300,
    height: 300
  }
};
