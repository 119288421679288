import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form
} from "reactstrap";
import moment from "moment";
import { change } from "redux-form";
import { findIndex, values } from "lodash";
import DatePicker from "react-datepicker";
import { Flag } from "semantic-ui-react";
import { required } from "../../../helper/validation";
import { Input, PopOver, Tooltip } from "../../../core";
import { TagSelect } from "../../../core/form/tagSelect";
import { SingleTagSelect } from "../../../core/form/singleTagSelect";
import { EditModule } from "../modules";
import { S3UPLOAD } from "../../../constant/thunk";
import { TextClipper } from "../../../helper/TextFormatter";

import RouteAllowed from "../../../helper/isRouteAllowed";
import FeatureAllowed from "../../../helper/isFeaturedAllowed";
import {
  progress,
  hideModal,
  Resources,
  AddItemToContentList,
  AddContentFromPutRequest,
  ContentTagResources,
  SponserTagResources,
  PartyTagResources,
  BrandTagResources
} from "../redux";

const selector = formValueSelector("EditContent");

const CustomExpirationInput = ({ value, onClick, isContentManager }) => (
  <div className="expirationDateWrapper">
    <input
      onClick={onClick}
      value={value}
      disabled={isContentManager}
      style={{ cursor: "default" }}
    />
    <i className="expirationDateWrapper__calendar fa fa-calendar-alt" />
  </div>
);

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength90 = maxLength(90);

class EditContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expiration_date: undefined,
      edit: false,
      isFillerContent: false
    };
  }

  componentDidMount = () => {
    const {
      contentToBeEdited: { attributes }
    } = this.props;

    this.loadPartyTag();
    const partyId = attributes.party_id
    if (partyId !== "") {
      let brand = BrandTagResources.getContent(partyId);
      this.props.Get(brand)
    }
    if (attributes) {
      this.props.initialize({
        name: attributes.name || undefined,
        displayName:
          attributes.display_name === "N/A" ? "" : attributes.display_name,
        tags:
          (attributes.tags &&
            attributes.tags.map(tag => ({
              value: tag,
              label: tag
            }))) ||
          [],
        sponsers:
          (attributes.sponsor_tags &&
            attributes.sponsor_tags.map(sponser => ({
              value: sponser,
              label: sponser
            }))) ||
          [],

        party: attributes.party_name
          ? [
            {
              key: attributes.party_id,
              value: attributes.party_name,
              label: attributes.party_name
            }
          ]
          : [],
        brand: attributes.brand_name
          ? [
            {
              key: attributes.brand_id,
              value: attributes.brand_name,
              label: attributes.brand_name
            }
          ]
          : [],
        expiration_date:
          moment(attributes.expiration_date).toDate() || undefined,
        rateAmount: attributes.rate_amount
      });

      if (attributes.expiration_date) {
        this.setState({
          expiration_date: attributes.expiration_date
            ? moment(attributes.expiration_date).toDate()
            : null
        });
      }

      this.setState({
        isFillerContent: attributes.is_filler_content
      });
    }
  };

  handleFileChange = e => {
    this.props.change("file", e.target.files[0]);
  };

  updateToster = (p, item, length, request) => {
    const temp = item;
    temp.newName = temp.name;
    this.props.progress(p, item, length, request);
    return 0;
  };

  updateServer = (awsResult, item) => {
    const { putContent } = Resources;
    putContent.url = `/contents/${item.id}`;
    putContent.body = {
      _jsonapi: {
        data: {
          type: "contents",
          attributes: {
            name: item.name || undefined,
            s3_object_key: item.s3_object_key || undefined,
            tags: item.tags.map(tag => tag.value) || undefined,
            expiration_date: this.state.expiration_date
              ? moment(this.state.expiration_date).format("YYYY-MM-DD")
              : null,
            display_name: item.displayName,
            brand_id: item.brand.map(brand => brand.key)
              ? item.brand.map(brand => brand.key)
              : null
          }
        }
      }
    };
    this.props.Put(putContent).then(result => {
      // const { data } = result;
      this.props.Get(Resources.getContent);
      // this.props.AddContentFromPutRequest(data, item.id);
      this.props.hideModal();
    });
  };
  handleTagChange = (tags, type) => {
    this.props.dispatch(change("EditContent", "brand", null));
    if (type === "party" && tags && tags.key != "") {
      let brand = BrandTagResources.getContent(tags.key);
      this.props.Get(brand);
    }
    if (type === "party" && tags === null) {
      // const brand = { value: "", label: "" };
      this.props.dispatch(change("EditContent", `brand`, null));
    }
  };
  handleEditSubmit = async values => {
    const { id, attributes } = this.props.contentToBeEdited;
    const item = values;
    if (values.file) {
      item.key = attributes.s3_object_key;
      item.id = id;
      const request = S3UPLOAD(
        item.key,
        values.file,
        (progressPercentage, request) =>
          this.updateToster(progressPercentage, item, 1, request),
        (data, metaInfo) => this.updateServer(data, metaInfo),
        item
      );
    } else {
      const { putContent } = Resources;
      putContent.url = `/contents/${id}`;
      putContent.body = {
        _jsonapi: {
          data: {
            type: "content",
            attributes: {
              name: values.name || undefined,
              s3_object_key: attributes.s3_object_key,
              tags: values.tags.map(tag => tag.value) || undefined,
              sponsor_tags:
                values.sponsers.map(sponser => sponser.value) || undefined,
              expiration_date: this.state.expiration_date
                ? moment(this.state.expiration_date).format("YYYY-MM-DD")
                : null,

              display_name: values.displayName || "",
              party: values.party ? values.party : null,
              brand_id: values.brand ? values.brand.key : null,
              rate_amount: values.rateAmount || 0,
              is_filler_content: this.state.isFillerContent
            }
          }
        }
      };
      await this.props.Put(putContent);
    }
    this.props.handleContentEditAndView(false);
    this.props.handleLoadContents(this.props.forcePage + 1);
    this.loadTags();
    this.loadSponserTag();
  };

  editContent = flag => {
    this.setState({
      edit: flag
    });
  };

  handleExpirationDateChange = input => {
    this.setState({
      expiration_date: input
    });
  };

  loadTags = async () => {
    const { getContent } = ContentTagResources;
    await this.props.Get(getContent);
  };

  loadSponserTag = async () => {
    const { getContent } = SponserTagResources;
    await this.props.Get(getContent);
  };

  loadPartyTag = async () => {
    const { getContent } = PartyTagResources;
    await this.props.Get(getContent);
  };

  handleFillerContentToggle = () => {
    this.props.dispatch(change("EditContent", "party", null));
    this.props.dispatch(change("EditContent", "brand", null));
    this.setState(prevState => ({
      isFillerContent: !prevState.isFillerContent
    }));
  };

  render() {
    if (this.props.contentToBeEdited.id === undefined) {
      this.props.history.push("/dashboard/content");
      return null;
    }
    const { attributes } = this.props.contentToBeEdited;
    const { roles, permission } = this.props;
    const admin = roles.includes("Admin") || roles.includes("SuperAdmin")
    const reportManager = roles.includes("ReportManager") || roles.includes("Admin") || roles.includes("SuperAdmin");
    const contentManager = [
      "ContentManager",
      "Admin",
      "SuperAdmin",
      "PlaylistManager",
      "CampaignManager"
    ].some(elem => {
      return roles.includes(elem);
    })
    return (
      <Modal
        isOpen={this.props.isEditing}
        backdrop="static"
        keyboard={false}
        toggle={this.props.handleContentEditAndView}
        className="editContentModal"
      >
        <ModalHeader
          toggle={() => {
            this.props.handleContentEditAndView(false);
          }}
        >
          {" "}
          {this.props.action === "edit" || this.state.edit ? (
            "Edit Content"
          ) : (
            <div className="modal-title">
              Detail{" "}
              <RouteAllowed roles={roles} permission={permission.content.edit}>
                <i
                  role="presentation"
                  className="float-right editIcon fa fa-edit"
                  onClick={() => this.editContent(true)}
                />{" "}
              </RouteAllowed>
            </div>
          )}{" "}
        </ModalHeader>{" "}
        <ModalBody>
          <Form>
            <div className="globalForm">
              <div className="row">
                <div className="col-md-7">
                  <EditModule
                    awsKey={attributes.s3_object_key}
                    contentType={attributes.content_type}
                    localUrl={
                      this.props.file
                        ? URL.createObjectURL(this.props.file)
                        : undefined
                    }
                    localContentType={
                      this.props.file ? this.props.file.type : undefined
                    }
                  />{" "}
                </div>{" "}
                {this.props.action === "edit" || this.state.edit ? (
                  <div className="col-md-5">
                    <div>
                      <FormGroup>
                        <div className="mt-0 globalInput">
                          <Field
                            component={Input}
                            name="name"
                            label="Name"
                            inputClass="mt-2"
                            validate={[required, maxLength90]}
                            disabled={!contentManager}
                          />{" "}
                        </div>
                      </FormGroup>{" "}
                      <FormGroup>
                        <div className="mt-0 globalInput">
                          <Field
                            component={Input}
                            name="displayName"
                            label="Display Name"
                            inputClass="mt-2"
                            validate={[maxLength90]}
                            disabled={!contentManager}
                          />{" "}
                        </div>
                      </FormGroup>{" "}
                      <FormGroup>
                        <div className="mt-0 globalInput">
                          <Label> Tags </Label>{" "}
                          <div className="mt-2">
                            <Field
                              component={TagSelect}
                              label="Tags"
                              name="tags"
                              options={this.props.ContentTag.map(i => ({
                                value: i,
                                label: i
                              }))}
                              disabled={!contentManager}
                            />{" "}
                          </div>{" "}
                        </div>
                      </FormGroup>{" "}
                      <FormGroup>
                        <div className="mt-0 globalInput">
                          <Label> Sponsors </Label>{" "}
                          <div className="mt-2">
                            <Field
                              component={TagSelect}
                              label="Sponsers"
                              name="sponsers"
                              disabled={!contentManager}
                              options={this.props.SponserTag.map(i => ({
                                value: i,
                                label: i
                              }))}
                              placeholder="Add Sponsors"
                            />{" "}
                          </div>{" "}
                        </div>
                      </FormGroup>{" "}
                      <RouteAllowed roles={roles} permission={permission.content.contentPartyBrand}>
                        <FeatureAllowed
                          feature={"is_party_brand_feature_enabled"}
                        >
                          <FormGroup>
                            <div className="mt-0 globalInput">
                              <Label> Party </Label>{" "}
                              <div className="mt-3">
                                <Field
                                  component={SingleTagSelect}
                                  label="Party"
                                  name="party"
                                  onChange={e => this.handleTagChange(e, "party")}
                                  options={this.props.PartyTag.map(i => ({
                                    key: i.id,
                                    value: i.name,
                                    label: i.name
                                  }))}
                                  placeholder="Add Party"
                                  disabled={this.state.isFillerContent === true}
                                />{" "}
                              </div>{" "}
                            </div>
                          </FormGroup>{" "}
                        </FeatureAllowed>
                        <FeatureAllowed
                          feature={"is_party_brand_feature_enabled"}
                        >
                          <FormGroup>
                            <div className="mt-0 globalInput">
                              <Label> Brand </Label>{" "}
                              <div className="mt-2">
                                <Field
                                  component={SingleTagSelect}
                                  label="Brand"
                                  name="brand"
                                  options={this.props.BrandTag.map(i => ({
                                    key: i.id,
                                    value: i.name,
                                    label: i.name
                                  }))}
                                  placeholder="Add Brand"
                                  disabled={
                                    !this.props.editPartyTag ||
                                    this.props.editPartyTag.length === 0 ||
                                    this.state.isFillerContent === true
                                  }
                                />{" "}
                              </div>{" "}
                            </div>
                          </FormGroup>{" "}
                        </FeatureAllowed>
                      </RouteAllowed>
                      <FormGroup>
                        <div className="mt-0 globalInput">
                          <Label> Expiration Date </Label>{" "}
                          <DatePicker
                            className="mt-2"
                            selected={this.state.expiration_date}
                            onChange={this.handleExpirationDateChange}
                            minDate={new Date()}
                            customInput={<CustomExpirationInput isContentManager={!contentManager} />}
                            readOnly={!contentManager}
                          />{" "}
                        </div>{" "}
                      </FormGroup>{" "}
                      <RouteAllowed roles={roles} permission={permission.content.fillerContent}>
                        <FormGroup className="mt-4 checkBoxWrapper--edit ">
                          <Label className="d-flex fillerCheckbox--edit" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <input
                              type="checkbox"
                              name="isFillerContent"
                              checked={this.state.isFillerContent === true}
                              onChange={this.handleFillerContentToggle}
                            />{" "}
                            <p className="pt-2 text-white">Mark as Filler Content</p>
                            <span className="checkmark filler-check pt-1" style={{ background: "transparent", border: "none", top: "5px" }} />
                          </Label>{" "}
                        </FormGroup>
                      </RouteAllowed>
                      <FormGroup>
                        <div className="mt-0 globalInput">
                          <Label htmlFor="upload"> File Browser </Label>{" "}
                          <CustomInput
                            type="file"
                            id="upload"
                            accept={[
                              ".ogg",
                              ".mp4",
                              ".jpeg",
                              ".png",
                              ".gif",
                              ".jpg"
                            ]}
                            className="upload-content"
                            name="myFile"
                            label={
                              this.props.file
                                ? TextClipper(
                                  this.props.contentToBeEdited.id,
                                  this.props.file.name,
                                  35
                                )
                                : "Upload a file"
                            }
                            onChange={this.handleFileChange}
                            style={{
                              cursor: "pointer"
                            }}
                            disabled={!contentManager}
                          />{" "}
                        </div>
                      </FormGroup>{" "}
                    </div>{" "}
                  </div>
                ) : (
                  <div className="col-md-5">
                    <div className="edit-right">
                      <Label> Name </Label> <span className="mb-2"> {attributes.name} </span>{" "}
                      <Label> Display Name </Label>{" "}
                      <span className="mb-2">
                        {" "}
                        {attributes.display_name === ""
                          ? "N/A"
                          : attributes.display_name}{" "}
                      </span>{" "}
                      <Label> Dimension </Label>{" "}
                      <span className="mb-2"> {attributes.dimension} </span>{" "}
                      <Label> Size </Label> <span className="mb-2"> {attributes.size} </span>{" "}
                      <Label> Expiration Date </Label>{" "}
                      <span className="mb-2"> {attributes.expiration_date || "Null"} </span>{" "}
                      <Label> Extension </Label>{" "}
                      <span className="mb-2"> {attributes.content_type} </span>{" "}
                      <Label className="mt-1"> Tags </Label>{" "}
                      <div className="clearfix mt-1 mb-1 contentTag ">
                        {" "}
                        {attributes.tags && attributes.tags.length > 0 ? (
                          attributes.tags.map((i, index) => (
                            <span
                              key={`tags-${this.props.contentToBeEdited.id}`}
                              className="mb-0 contentTag__badge contentDetail-badge"
                            >
                              {" "}
                              {TextClipper(
                                this.props.contentToBeEdited.id,
                                i,
                                8,
                                index + 50,
                                "tags"
                              )}{" "}
                            </span>
                          ))
                        ) : (
                          <span className="mb-2">N/A</span>
                        )}{" "}
                      </div>{" "}
                      <Label className="mt-1"> Sponsors </Label>{" "}
                      <div className="clearfix mt-1 mb-1 contentTag">
                        {" "}
                        {attributes.sponsor_tags &&
                          attributes.sponsor_tags.length > 0 ? (
                          attributes.sponsor_tags.map((i, index) => (
                            <span
                              key={`sponser-${this.props.contentToBeEdited.id}`}
                              className="mb-0 contentTag__badge contentTag__badge--mod contentDetail-badge"
                            >
                              {" "}
                              {TextClipper(
                                this.props.contentToBeEdited.id,
                                i,
                                8,
                                index + 50,
                                "sponsers"
                              )}{" "}
                            </span>
                          ))
                        ) : (
                          <span className="mb-2">N/A</span>
                        )}{" "}
                      </div>{" "}
                      <Label className=" mt-1"> Party </Label>{" "}
                      <div className="clearfix mt-1 mb-1 contentTag">
                        {attributes.is_filler_content &&
                          attributes.is_filler_content === true ? (
                          <span className="clearfix mt-1 mb-0 contentTag">N/A</span>
                        ) : (
                          <>
                            {attributes.party_name ? (
                              <span
                                className="content__titlename mb-2"
                                role="presentation"
                              >
                                {attributes.party_name > 10 ? (
                                  <Tooltip
                                    item={
                                      <span className="contentTag__badge mb-1">
                                        {" "}
                                        {`${attributes.party_name.slice(
                                          0,
                                          10
                                        )}...`}{" "}
                                      </span>
                                    }
                                    tooltipContent={attributes.party_name}
                                  />
                                ) : (
                                  <span className={`contentTag__badge mb-1`}>
                                    {attributes.party_name}
                                  </span>
                                )}
                              </span>
                            ) : (
                              <span className="clearfix mt-1 mb-1 contentTag">N/A</span>
                            )}
                          </>
                        )}
                      </div>
                      <Label className=" mt-1"> Brand </Label>{" "}
                      <div className="clearfix mt-1 mb-1 contentTag">
                        {attributes.is_filler_content &&
                          attributes.is_filler_content === true ? (
                          <span className="clearfix mt-1 mb-1 contentTag">N/A</span>
                        ) : (
                          <>
                            {attributes.brand_name ? (
                              <span
                                className="content__titlename mb-2"
                                role="presentation"
                              >
                                {attributes.brand_name.length > 10 ? (
                                  <Tooltip
                                    item={
                                      <span className="contentTag__badge contentTag__badge--mod mb-1">
                                        {" "}
                                        {`${attributes.brand_name.slice(
                                          0,
                                          10
                                        )}...`}{" "}
                                      </span>
                                    }
                                    tooltipContent={attributes.brand_name}
                                  />
                                ) : (
                                  <span className="contentTag__badge contentTag__badge--mod mb-1">
                                    {attributes.brand_name}
                                  </span>
                                )}
                              </span>
                            ) : (
                              <span className="clearfix mt-1 mb-1 contentTag">N/A</span>
                            )}
                          </>
                        )}
                      </div>
                      {this.props.rateAmountRestriction && (
                        <RouteAllowed
                          roles={roles}
                          permission={permission.content.rateAmountFieldAccess}
                        >
                          <Label> Content Rate Nrs. </Label>
                          <span> {attributes.rate_amount} </span>
                        </RouteAllowed>
                      )}
                      <Label> Filler Content </Label>{" "}
                      <span>
                        {" "}
                        {attributes.is_filler_content ? "Yes" : "No"}{" "}
                      </span>{" "}
                    </div>{" "}
                  </div>
                )}{" "}
              </div>{" "}
            </div>{" "}
          </Form>
        </ModalBody>{" "}
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-outline-primary"
              onClick={() => this.props.handleContentEditAndView(false)}
            >
              Cancel{" "}
            </Button>{" "}
            {(this.props.action === "edit" || this.state.edit) && (
              <Button
                onClick={this.props.handleSubmit(value =>
                  this.handleEditSubmit(value)
                )}
                className="primaryButton mt-0"
              >
                Update{" "}
              </Button>
            )}{" "}
          </div>{" "}
        </ModalFooter>
      </Modal >
    );
  }
}

export default connect(
  state => {
    const { list } = state.content;
    // const index = findIndex(list, { id: ownProps.match.params.id });
    // const content =
    //   index === -1 ? { id: undefined, attribute: undefined } : list[index];
    return {
      file: selector(state, "file") || undefined,
      editPartyTag: selector(state, "party") || undefined,
      ContentTag: state.ContentTag.list
        ? state.ContentTag.list.attributes.tags
        : [],
      SponserTag: state.SponserTag.list ? state.SponserTag.list : [],
      PartyTag: state.PartyTag.list ? state.PartyTag.list.parties_list : [],
      BrandTag: state.BrandTag.list ? state.BrandTag.list.brands_list : []
    };
  },
  {
    progress,
    hideModal,
    AddItemToContentList,
    AddContentFromPutRequest
  }
)(
  reduxForm({
    form: "EditContent"
  })(EditContent)
);
