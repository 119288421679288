/* eslint-disable no-shadow */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Card
} from "reactstrap";
import { updateCampaign } from "../../redux";
const transparentImage = require("../../../../assets/picture/transparent.png");

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.changeDurationOfContent(
    defaultState.contentLocalID,
    screen.localID,
    {
      minute: defaultState.duration.minute,
      second: defaultState.duration.second
    }
  );
  updateCampaign(campaign);
  toggle(false);
}

function handleMinuteChange(
  { target: { value } },
  { contentLocalID, campaign, screen, updateCampaign },
  { duration: { second } }
) {
  campaign.changeDurationOfContent(contentLocalID, screen.localID, {
    minute: value ? Number(value) : value,
    second: Number(second)
  });
  updateCampaign(campaign);
}

function handleSecondChange(
  { target: { value } },
  { contentLocalID, campaign, screen, updateCampaign },
  { duration: { minute } }
) {
  campaign.changeDurationOfContent(contentLocalID, screen.localID, {
    minute: Number(minute),
    second: value ? Number(value) : value
  });
  updateCampaign(campaign);
}

const TransparentModal = props => (
  <Modal isOpen={props.open} backdrop>
    <ModalHeader toggle={() => cancelChanges(props)}>{props.type}</ModalHeader>
    <ModalBody>
      <Card>
        <div className="d-flex justify-content-center customModal">
          <img
            src={transparentImage}
            alt="transparentImage"
            className=" customModal__display-image"
          />
        </div>
        <form>
          <div className="d-flex globalForm">
            <div className="globalInput">
              <Label>Minutes</Label>
              <Input
                type="number"
                min="0"
                value={props.transparentContentData.duration.minute}
                name="minutes"
                onChange={e =>
                  handleMinuteChange(e, props, props.transparentContentData)
                }
                placeholder="Enter minutes to play."
              />
            </div>
            <b>:</b>
            <div className="globalInput">
              <Label>Second</Label>
              <Input
                type="number"
                min="0"
                name="seconds"
                value={props.transparentContentData.duration.second}
                onChange={e =>
                  handleSecondChange(e, props, props.transparentContentData)
                }
                placeholder="Enter seconds to play"
              />
            </div>
          </div>
        </form>
      </Card>
    </ModalBody>
    <ModalFooter>
      <Button
        className="btn-outline-primary"
        onClick={() => cancelChanges(props)}
      >
        Cancel
      </Button>
      <Button
        className="mt-0 primaryButton"
        onClick={() => props.toggle(false)}
      >
        Done
      </Button>
    </ModalFooter>
  </Modal>
);

export default connect(
  (state, props) => {
    const { campaign } = state.newCampaign;
    const {
      contentLocalID,
      screen: { localID }
    } = props.data;
    const transparentContentData = campaign.getContent(localID, contentLocalID);
    return {
      campaign,
      transparentContentData: transparentContentData || []
    };
  },
  { updateCampaign }
)(TransparentModal);
