import React from "react";
import moment from "moment";

export function columns(
    hiddenColumns,
) {
    return [
        {
            dataField: "attributes.title",
            text: "Title",
            columnName: "title",
            hidden: hiddenColumns.title,
        },
        {
            dataField: "attributes.brand_details.name",
            text: "Brand Name",
            columnName: "brandName",
            hidden: hiddenColumns.name,
        },
        {
            dataField: "attributes.brand_details.start_date",
            text: "Start Date",
            columnName: "startDate",
            hidden: hiddenColumns.startDate,
            formatter: (row) => moment(row).format("YYYY/MM/DD")
        },
        {
            dataField: "attributes.brand_details.end_date",
            text: "End Date",
            columnName: "endDate",
            hidden: hiddenColumns.endDate,
            formatter: (row) => moment(row).format("YYYY/MM/DD")
        },
        {
            dataField: "attributes.brand_details.total_duration_minutes",
            text: "Total Duration",
            columnName: "totalDuration",
            hidden: hiddenColumns.totalDuration,
        },
        {
            dataField: "attributes.brand_details.rate_per_minute",
            text: "Rate Per Minute",
            columnName: "RatePerMinute",
            hidden: hiddenColumns.ratePerMinute,
        },
        {
            dataField: "attributes.created_at",
            text: "Created At",
            columnName: "createdAt",
            hidden: hiddenColumns.createdAt,
            formatter: (row) => moment(row).format("YYYY/MM/DD hh:mm a")
        },
    ]
}
