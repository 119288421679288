/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { Logout } from "../redux";

class DomainError extends Component {
  render() {
    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <img
              src={require("../../../assets/images/splashnode-logo.svg")}
              alt="IMG"
              style={{ marginBottom: "16px" }}
            />
            <p>
              You are not associated with any domain. Please contact admin for
              further details.
            </p>{" "}
            <Button
              className="notfound__button"
              onClick={() => {
                this.props.Logout();
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps() {}
const DomainNotFound = connect(
  mapStateToProps,
  { Logout }
)(DomainError);

export { DomainNotFound };
