import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { initialize, destroy, change } from "redux-form";
import { duration } from "moment";
import DomainTable from "../modules/domainTable";
import { Resources, updateDomainInfiniteScroll } from "../redux/domainRedux";
import {
  Resources as UserResources,
  generateDefaultDomain
} from "../redux/loginRedux";
import { Dialog, SearchTagRefresh } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import AddDomain from "./addDomain";
import { getHourMinutesAndSeconds } from "../../../helper";
import DomainHeader from "./domainHeader";
import InfiniteScroll from "react-infinite-scroll-component";

class DomainList extends React.Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      screenshot_time: { active: false, sortBy: "desc" },
      subscription: { active: false, sortBy: "desc" },
      created_at: { active: false, sortBy: "desc" },
      updated_at: { active: false, sortBy: "desc" }
    };
    this.currentUser = JSON.parse(window.localStorage.getItem("user"));
    this.state = {
      deleteModal: false,
      modal: false,
      forcePage: undefined,
      sortOrder: this.initialSortOrder,
      sortValue: { isSortable: false },
      id: undefined,
      isLoading: true,
      defaultDomain: this.currentUser.user.default_domain,
      currentDomainID: JSON.parse(window.localStorage.getItem("domain")),
      searchVal: "",
      createModal: false,
      action: "",
      domainToBeEdited: undefined,
      refetch: true,
      domainListPage: 1
    };
    this.page = 1;
    this.loadDomainFromServer();
    this.loadDomainList(this.page);
  }


  loadDomainFromServer = async () => {
    const { getDomainsDetails } = Resources;
    const currentDomain = JSON.parse(window.localStorage.getItem("domain"));

    await this.props
      .Get(getDomainsDetails(currentDomain.id))
      .then(({ data }) => {
        const { data: { attributes } } = data;
        window.localStorage.setItem(
          "domain",
          JSON.stringify({ ...currentDomain, ...attributes })
        );
      })
  }

  update = () => {
    this.forceUpdate();
  };

  toggleModal = () => {
    this.setState(state => ({ modal: !state.modal }));
  };

  toggleDeleteModal = () => {
    this.setState(state => ({ deleteModal: !state.deleteModal }));
  };

  handleDelete = id => {
    this.setState({
      deleteModal: true,
      id
    });
  };

  loadDomainList = page => {
    Resources.getDomains.url = `/domains?sort=name&sort_by=asc&page=${page}&per_page=${this.perPageCount}`;
    this.props
      .Get(Resources.getDomains)
      .then(response => {
        if (response.data.meta.totalPages <= response.data.meta.page) {
          this.setState({ refetch: false });
        }
      })
      .then(() => this.setState({ isLoading: false }));
  };

  handleSearchValue = value => {
    this.setState({ domainListPage: 1, refetch: true });
    this.setState({ searchVal: value }, () => {
      this.handleSearch();
    });
  };

  handleSearch = debounce(() => {
    const { getDomains } = Resources;
    if (this.state.searchVal === "") {
      getDomains.url = `/domains?sort=name&sort_by=asc&page=${1}&per_page=${this.perPageCount
        }`;
    } else {
      getDomains.url = `/domains/search?search=${this.state.searchVal
        }&page=${1}&per_page=${this.perPageCount}`;
    }
    this.props.Get(getDomains).then(response => {
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.setState({ refetch: false });
      }
    });
  }, 3000);

  letsDelete = () => {
    const { deleteDomain, getDomains } = Resources;
    const { id } = this.state;
    deleteDomain.url = `/domains/${id}`;
    this.props.Delete(deleteDomain).then(() =>
      this.props.Get(getDomains).then(result => {
        if (result.status === 200) {
          return (
            this.props.domainList.length === 0 &&
            this.handlePageClick(this.props.meta, true)
          );
        }
      })
    );
    this.setState({
      id: undefined,
      deleteModal: false
    });
  };

  handleDomain = id => {
    this.setState({
      modal: true,
      id
    });
  };

  handleDomainCreateModalToggle = flag => {
    this.setState({ createModal: flag });
    if (!flag) {
      this.props.destroy("AddDomainForm");
    }
    this.props.change("AddDomainForm", "subscription", "premium");
  };

  handleDomainAction = (action, domain = undefined) => {
    this.setState({ action, domainToBeEdited: domain });
    this.handleDomainCreateModalToggle(true);
    if (action === "edit") {
      const screenShotDuration = getHourMinutesAndSeconds(
        domain.attributes.screenshot_time
      );
      this.props.initialize("AddDomainForm", {
        name: domain.attributes.name,
        hour: screenShotDuration.hours,
        minute: screenShotDuration.minutes,
        second: screenShotDuration.seconds
      });
    }
    this.forceUpdate();
  };

  handleSort = name => {
    this.setState({
      forcePage: 0,
      isLoading: true,
      domainListPage: 1,
      refetch: true
    });

    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getDomains } = Resources;
        getDomains.url = `/domains/?sort=${name}&sort_by=${this.state.sortOrder[name].sortBy
          }&page=${1}&per_page=${this.perPageCount}`;
        this.props
          .Get(Resources.getDomains)
          .then(() => this.setState({ isLoading: false }));
      }
    );
  };

  switchDomain = () => {
    const { id } = this.state;
    const { switchDomain } = Resources;
    switchDomain.url = `/domains/${id}/switch_domain`;
    this.props.Get(switchDomain).then(result => {
      if (result.status === 200) {
        window.localStorage.setItem(
          "domain",
          JSON.stringify(result.data.domain)
        );
        window.location.replace("/dashboard");
      }
    });
    this.setState({
      id: undefined,
      modal: false
    });
  };

  setDefaultDomain = id => {
    const { setDefaultDomain } = UserResources;
    setDefaultDomain.url = `/users/${this.currentUser.user.id}`;
    setDefaultDomain.body = generateDefaultDomain(this.currentUser.user.id, id);
    this.props.Put(setDefaultDomain).then(result => {
      this.setState({ defaultDomain: id });
    });
  };
  /** ---------------------- pagination ------------------------- */
  // handlePageClick = (data, moveToPrevious = false) => {
  //   window.scrollTo(0, 0);
  //   this.setState({ isLoading: true, forcePage: undefined });
  //   const { getDomains } = Resources;
  //   if (moveToPrevious) {
  //     getDomains.url = `/users?page=${data.page - 1}&per_page=${
  //       this.perPageCount
  //     }`;
  //     this.props.Get(getDomains).then(() => {
  //       this.setState({ forcePage: data.page - 2, isLoading: false });
  //     });
  //   } else {
  //     const { selected } = data;
  //     if (this.state.sortValue.isSortable) {
  //       getDomains.url = `/domains?sort=${this.state.sortValue.name}&sort_by=${
  //         this.state.sortValue.sortBy
  //       }&page=${selected + 1}&per_page=${this.perPageCount}`;
  //     } else {
  //       getDomains.url = `/domains?page=${selected + 1}&per_page=${
  //         this.perPageCount
  //       }`;
  //     }
  //     this.props
  //       .Get(getDomains)
  //       .then(() => this.setState({ forcePage: selected, isLoading: false }));
  //   }
  // };

  fetchDomain = () => {
    const { getDomains } = Resources;
    this.props
      .Get(getDomains)
      .then(response => {
        if (response.data.meta.totalPages <= response.data.meta.page) {
          this.setState({ refetch: false });
        }
      })
      .then(() => this.props.updateDomainInfiniteScroll());
  };

  handleNext = async () => {
    const { getDomains } = Resources;
    if (this.state.refetch) {
      this.setState(
        prevState => ({
          domainListPage: prevState.domainListPage + 1
        }),
        () => {
          if (this.state.searchVal) {
            getDomains.url = `domains/search?search=${this.state.searchVal}
            &page=${this.state.domainListPage}
            &per_page=${this.perPageCount}`;
            this.fetchDomain();
            return 0;
          }
          if (this.state.sortValue.isSortable) {
            getDomains.url = `/domains?sort=${this.state.sortValue.name}&sort_by=${this.state.sortValue.sortBy}&page=${this.state.domainListPage}&per_page=${this.perPageCount}`;
            this.fetchDomain();
            return 0;
          }
          getDomains.url = `/domains?sort=name&sort_by=asc&page=${this.state.domainListPage}&per_page=${this.perPageCount}`;
          this.fetchDomain();
        }
      );
    }
    return true;
  };

  refetchDomain = page => {
    this.setState({ refetch: true, domainListPage: page });
  };

  render() {
    const { roles } = this.props;
    const { permission } = this.props;
    return (
      <div className="mainPage">
        <div className="contentSection">
          <AddDomain
            handleDomainCreate={this.handleDomainCreateModalToggle}
            createModal={this.state.createModal}
            action={this.state.action}
            roles={roles}
            domain={this.state.domainToBeEdited}
            loadDomainList={this.loadDomainList}
            meta={this.props.meta}
            refetchDomain={this.refetchDomain}
            {...this.props}
          />

          <Dialog
            showHandler={false}
            externalControl
            modal={this.state.deleteModal}
            toggleModal={this.toggleDeleteModal}
            body={
              <span>
                <span>Are you sure you want to delete? </span>
                {/* <strong>{this.state.email}</strong>? */}
              </span>
            }
            primaryMethod={this.letsDelete}
            toggleModel={this.toggleDeleteModal}
            title="Delete Domain"
          />
          <Dialog
            showHandler={false}
            externalControl
            modal={this.state.modal}
            toggleModal={this.toggleModal}
            body={
              <span>
                <span>Are you sure you want to switch to this domain? </span>
                {/* <strong>{this.state.email}</strong>? */}
              </span>
            }
            primaryMethod={this.switchDomain}
            toggleModel={this.toggleModal}
            title="Switch Domain"
          />
          <InfiniteScroll
            dataLength={this.props.domainList.length} // This is important field to render the next data
            next={this.handleNext}
            hasMore={this.state.refetch}
            loader={
              <div className="d-block text-center">
                {this.props.domainList.length > 0 ? (
                  <span>...Loading</span>
                ) : (
                  <svg className="spinner" viewBox="0 0 50 50">
                    <circle
                      className="path"
                      cx="25"
                      cy="25"
                      r="20"
                      fill="none"
                      strokeWidth="4"
                    />
                  </svg>
                )}
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center", "margin-top": "15px" }}>
                <b>{this.props.domainList.length === 0 && "No data"}</b>
              </p>
            }
            style={{ overFlow: "visible" }}
          >
            <DomainTable
              {...this.props}
              update={this.update}
              isLoading={this.state.isLoading}
              deleteDomain={this.handleDelete}
              handleDomain={this.handleDomain}
              setDefaultDomain={this.setDefaultDomain}
              currentUser={this.currentUser}
              defaultDomain={this.state.defaultDomain}
              currentDomainID={this.state.currentDomainID.id}
              handleSort={this.handleSort}
              sortOrder={this.state.sortOrder}
              domain={this.state.domainToBeEdited}
              handleDomainAction={this.handleDomainAction}
              // following props are for domain header
              currentDomainName={this.state.currentDomainID.name}
              rolesDH={roles}
              permissionDH={permission.domain.creates}
              // handleDomainAction={this.handleDomainAction}
              handleSearchValue={this.handleSearchValue}
            />
          </InfiniteScroll>
          {/* ---------pagination-------------- */}
          {/* {!this.state.isLoading &&
            this.props.domainList &&
            this.props.domainList.length > 0 && (
              <div className="contentSectionPagination clearfix">
                <ReactPaginate
                  pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  marginPagesDisplayed={2}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  previousLabel="<"
                  forcePage={this.state.forcePage}
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  disabledClassName="paginationDisable"
                />
              </div>
            )} */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const userRoles = state.userDetails.user;
  const { list, meta } = state.domainList;
  return { domainList: list || [], userRoles, meta };
}

export default connect(
  mapStateToProps,
  { initialize, destroy, change, updateDomainInfiniteScroll }
)(DomainList);
