import React, { Component } from "react";
import {
  Button,
  Tooltip,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup
} from "reactstrap";
import { Input } from "../../../core";
import DatePicker from "../../../core/form/datePicker";
import { connect } from "react-redux";
import {
  reduxForm,
  Field,
  formValueSelector,
  initialize as remoteInitialize,
  FormSection,
  SubmissionError,
  change,
  getFormValues
} from "redux-form";
import { Resources, partyBrands, createBrandBody } from "../redux/domainRedux";
import GenerateForm from "../forms/generateForm";

class BrandModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBrandValid: this.props.editBrand
    };
  }

  componentDidMount() { }

  addBrandToParty = async values => {
    const { addPartyBrands } = Resources;
    addPartyBrands.url = `domains/${this.props.match.params.id}/parties/${this.props.partiesId}/brands`;
    addPartyBrands.body = createBrandBody(this.formatFormValues(values));
    await this.props.Post(addPartyBrands);
    this.props.toggleBrandModal(false, null, false);
    this.props.loadDomainParties();
  };

  updateBrand = async values => {
    const { updatePartyBrands } = Resources;
    updatePartyBrands.url = `domains/${this.props.match.params.id}/parties/${this.props.partiesId}/bulk_update`;
    updatePartyBrands.body = createBrandBody(this.formatFormValues(values));
    await this.props.Patch(updatePartyBrands);
    this.props.toggleBrandModal(false, null, false);
    this.props.loadDomainParties(this.props.page);
  };

  formatFormValues = values => {
    let tempObj = {};
    for (let key in values) {
      let tempArr = key.split("-");
      if (!this.props.removedBrands.includes(tempArr[1])) {
        if (!tempObj[tempArr[1]]) tempObj[tempArr[1]] = { id: tempArr[1] };
        tempObj[tempArr[1]][tempArr[2]] = values[key];
      }
    }
    return Object.values(tempObj);
  };

  toggleBrandValidity = flag => {
    this.setState({
      isBrandValid: flag
    });
  };

  render() {
    return (
      <>
        <ModalBody
          style={{
            maxHeight: "80vh",
            minHeight: "550px",
            overflowY: `${this.props.editBrand && "auto"}`
          }}
        >
          <GenerateForm
            brands={[...this.props.brands]}
            editBrand={this.props.editBrand}
            handleRemoveBrand={this.props.handleRemoveBrand}
            currentUserRoles={this.props.currentUserRoles}
            change={change}
            dispatch={this.props.dispatch}
            toggleBrandValidity={this.toggleBrandValidity}
            partyId={this.props.partiesId}
            domainId={this.props.match.params.id}
            Get={this.props.Get}
            brandFormValues={this.props.brandFormValues}
          />
        </ModalBody>

        <ModalFooter>
          <button
            className="btn-outline-primary"
            onClick={() => this.props.toggleBrandModal(false, null, false)}
          >
            Cancel
          </button>
          {this.props.brands.length > 0 && (
            <button
              className="mt-0 primaryButton"
              form="brandForm"
              type="submit"
              onClick={this.props.handleSubmit(values => {
                if (!this.state.isBrandValid) return;
                !this.props.editBrand
                  ? this.addBrandToParty(values)
                  : this.updateBrand(values);
              })}
            >
              {this.props.editBrand ? "Update" : "Add"}
            </button>
          )}
        </ModalFooter>
      </>
    );
  }
}

const mapState = (state) => {
  const selector = formValueSelector("brandForm");
  return {
    brandForm: state.form.brandForm || {},
    brandFormValues: getFormValues("brandForm")(state)
  };
}

export default connect(
  mapState,
  { remoteInitialize }
)(
  reduxForm({
    form: "brandForm"
  })(BrandModal)
);
