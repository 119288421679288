import React from "react";

export const horizontalLongTemplate = data => {
  const {
    fontSize,
    imgWidth,
    rowHeight,
    temprData,
    location,
    days,
    forecastFontSize,
    forecastFontSizeLoc,
    forecastFontSizeDay
  } = data;
  switch (days) {
    case 5:
      return (
        <div className="widgetwrapper">
          <div className="d-flex justify-content-around center-the-element-vertically horizontal-widget-background">
            <div className="flex-container flex-same-length">
              <div className="date-location">
                <div className="">
                  <img
                    src={require(`../../../assets/picture/weather/${temprData[0].icon}.png`)}
                    alt="sun"
                    className="img-fluid"
                    style={{ width: `${imgWidth}px` }}
                  />
                  <h5 style={{ fontSize: `${forecastFontSize}rem` }}>
                    {temprData[0].date}
                  </h5>
                  <h4 style={{ fontSize: `${forecastFontSizeLoc}rem` }}>
                    {location}
                  </h4>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <h3 style={{ fontSize: `${fontSize}rem` }} className="mt-2">
                  {temprData[0].temp}
                  <sup>°</sup>
                  {/* <i style={{ fontSize: `${fontSize}rem` }}>
                    {temprData[0].unit}
                  </i>{" "} */}
                </h3>
              </div>
            </div>
            <div className="flex-container flex-same-length">
              <div className="d-flex align-items-center width-fill">
                <div className="horizontal width-fill">
                  <div className="flex-container justify-content-around">
                    <div className="temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[1].icon}.png`)}
                            alt="raining"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[1].temp}
                          <sup>°</sup>
                          {/* <i>{temprData[1].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[1].day}
                        </span>
                      </div>
                    </div>
                    <div className="temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[2].icon}.png`)}
                            alt="sun"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[2].temp}
                          <sup>°</sup>
                          {/* <i>{temprData[2].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[2].day}
                        </span>
                      </div>
                    </div>
                    <div className="temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[3].icon}.png`)}
                            alt="raining"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[3].temp}
                          <sup>°</sup>
                          {/* <i> {temprData[3].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[3].day}
                        </span>
                      </div>
                    </div>
                    <div className=" temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[4].icon}.png`)}
                            alt="raining"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[4].temp}
                          <sup>°</sup>
                          {/* <i> {temprData[4].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[4].day}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="widgetwrapper ">
          <div className="d-flex justify-content-around center-the-element-vertically horizontal-widget-background">
            <div className="flex-container flex-same-length">
              <div className="date-location">
                <div className="">
                  <img
                    src={require(`../../../assets/picture/weather/${temprData[0].icon}.png`)}
                    alt="sun"
                    className="img-fluid"
                    style={{ width: `${imgWidth}px` }}
                  />
                  <h5 style={{ fontSize: `${forecastFontSize}rem` }}>
                    {temprData[0].date}
                  </h5>
                  <h4 style={{ fontSize: `${forecastFontSizeLoc}rem` }}>
                    {location}
                  </h4>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <h3 style={{ fontSize: `${fontSize}rem` }} className="mt-3">
                  {temprData[0].temp}
                  <sup>°</sup>
                  {/* <i style={{ fontSize: `${fontSize}rem` }}>
                    {temprData[0].unit}
                  </i> */}
                </h3>
              </div>
            </div>
            <div className="flex-container flex-same-length">
              <div className="d-flex align-items-center width-fill">
                <div className="horizontal width-fill">
                  <div className="flex-container justify-content-around">
                    <div className="temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[1].icon}.png`)}
                            alt="raining"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[1].temp}
                          <sup>°</sup>
                          {/* <i>{temprData[1].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[1].day}
                        </span>
                      </div>
                    </div>
                    <div className="temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[2].icon}.png`)}
                            alt="sun"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[2].temp}
                          <sup>°</sup>
                          {/* <i> {temprData[2].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[2].day}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 1:
      return (
        <div className="widgetwrapper">
          <div className="d-flex justify-content-around center-the-element-vertically">
            <div className="flex-container">
              <div className="date-location">
                <div className="">
                  <img
                    src={require(`../../../assets/picture/weather/${temprData[0].icon}.png`)}
                    alt="sun"
                    className="img-fluid"
                    style={{ width: `${imgWidth}px` }}
                  />
                  <h5 style={{ fontSize: `${forecastFontSize}rem` }}>
                    {temprData[0].date}
                  </h5>
                  <h4 style={{ fontSize: `${forecastFontSizeLoc}rem` }}>
                    {location}
                  </h4>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <h3 style={{ fontSize: `${fontSize}rem` }} className="mt-2">
                  {temprData[0].temp}
                  <sup>°</sup>
                  {/* <i style={{ fontSize: `${fontSize}rem` }}>
                    {temprData[0].unit}
                  </i> */}
                </h3>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="widgetwrapper">
          <div className="d-flex justify-content-around center-the-element-vertically horizontal-widget-background">
            <div className="flex-container flex-same-length">
              <div className="date-location">
                <div className="">
                  <img
                    src={require(`../../../assets/picture/weather/${temprData[0].icon}.png`)}
                    alt="sun"
                    className="img-fluid"
                    style={{ width: `${imgWidth}px` }}
                  />
                  <h5 style={{ fontSize: `${forecastFontSize}rem` }}>
                    {temprData[0].date}
                  </h5>
                  <h4 style={{ fontSize: `${forecastFontSizeLoc}rem` }}>
                    {location}
                  </h4>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <h3 style={{ fontSize: `${fontSize}rem` }} className="mt-2">
                  {temprData[0].temp}
                  <sup>°</sup>
                  {/* <i style={{ fontSize: `${fontSize}rem` }}>
                    {temprData[0].unit}
                  </i>{" "} */}
                </h3>
              </div>
            </div>
            <div className="flex-container flex-same-length">
              <div className="d-flex align-items-center width-fill">
                <div className="horizontal width-fill">
                  <div className="flex-container justify-content-around">
                    <div className="temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[1].icon}.png`)}
                            alt="raining"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[1].temp}
                          <sup>°</sup>
                          {/* <i>{temprData[1].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[1].day}
                        </span>
                      </div>
                    </div>
                    <div className="temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[2].icon}.png`)}
                            alt="sun"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[2].temp}
                          <sup>°</sup>
                          {/* <i>{temprData[2].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[2].day}
                        </span>
                      </div>
                    </div>
                    <div className="temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[3].icon}.png`)}
                            alt="raining"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[3].temp}
                          <sup>°</sup>
                          {/* <i> {temprData[3].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[3].day}
                        </span>
                      </div>
                    </div>
                    <div className=" temperatureUnit">
                      <div className="Day1">
                        <div className="temperatureUnit__img">
                          <img
                            src={require(`../../../assets/picture/weather/${temprData[4].icon}.png`)}
                            alt="raining"
                          />
                        </div>
                        <b style={{ fontSize: `${forecastFontSize}rem` }}>
                          {temprData[4].temp}
                          <sup>°</sup>
                          {/* <i> {temprData[4].unit}</i> */}
                        </b>
                        <span style={{ fontSize: `${forecastFontSizeDay}rem` }}>
                          {temprData[4].day}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
};
