/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { emailFormatter } from "../../../helper/emailFormatter";
// import manual from "./../../../assets/manual/";

class SystemInfo extends Component {
  state = 0;

  render() {
    const formattedDomainName = emailFormatter(this.props.domain);
    return (
      <div className="container">
        <Modal isOpen={this.props.mpsModal} className={this.props.className}>
          <ModalBody>
            {/* Both tabs of campaign and calendar are inside TabMenu */}
            <div className="systemInfoWrapper">
              <i
                className="fas fa-times"
                id="closeBtn"
                onClick={this.props.toggleMpsModal}
              />
              <div className="systemInfoWrapper__body">
                <h2>Splashnode Publish</h2>
                <img
                  src={require("../../../assets/images/splashnode-logo.svg")}
                  alt="IMG"
                />
                <p>
                  <b>Splashnode Publish Version :</b> 4.3.0{" "}
                </p>
                <p>
                  <b>Embedded Software Version :</b>3.9.0{" "}
                </p>
                <p>
                  <b>OS Version:</b> 18.04-02-v2.3.0{" "}
                </p>
              </div>
              <div>
                <b>Contact: </b>
                {`support.${formattedDomainName}@splashnode.com`}
              </div>

              <a target="_blank" href="/userManual">
                User Manual
              </a>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default SystemInfo;
