import { Component } from "react";

function isFeatureEnabled(feature) {
    const currentDomain = JSON.parse(
        window.localStorage.getItem("domain") !== "undefined"
            ? window.localStorage.getItem("domain")
            : null
    );
    if (currentDomain !== null) {
        return currentDomain[`${feature}`]
    }
    else {
        return false
    };
}

export default class FeatureAllowed extends Component {
    render() {
        if (isFeatureEnabled(this.props.feature)) {
            return this.props.children;
        }
        return null;
    }
}