/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import TagsInput from "react-tagsinput";
import PropTypes from "prop-types";

import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.t
import { alphaNumeric } from "../../helper/validation";

export default class Example extends React.Component {
  static defaultProps = {
    label: "",
    subLabel: "",
    handleChange: () => {}
  };

  static propTypes = {
    label: PropTypes.string,
    subLabel: PropTypes.string,
    handleChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = { tags: [] };
  }

  handleChange = tags => {
    // this.setState({ tags });
    this.props.input && this.props.input.onChange(tags);
    this.props.handleChange(tags);
  };

  render() {
    const { label, subLabel } = this.props;
    let value = this.props.input ? this.props.input.value : this.state.tags;
    if (!value) {
      value = [];
    }
    return (
      <div className="form-group">
        <label>
          {label}
          <span>{subLabel}</span>
        </label>
        <TagsInput
          addKeys={[9, 13, 32]}
          value={value}
          onChange={this.handleChange}
          inputProps={this.props.inputProps}
          // className={!pristine && error ? "is-invalid form-control" : "form-control"}
        />
        {/* {!pristine &&
          ((error && (
            <span className="invalid-feedback" style={{ display: "block" }}>
              {error}
            </span>
          )) ||
            (warning && <span className="invalid-feedback">{warning}</span>))} */}
      </div>
    );
  }
}
