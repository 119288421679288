import React from "react";
import { BigCalendarHeaderTitle } from "./bigHeaderCalendarTitle";

export const BigCalendarHeaderForDesktop = props => (
  <div className="flex-fill mainCalendar__wrapper big-calendar-header p-0">
    <BigCalendarHeaderTitle
      calendarTitle={props.calendarTitle}
      calendarTitleChange={props.calendarTitleChange}
      calendarInputRef={props.calendarInputRef}
      action={props.action}
      isCalendarListOpen={props.isCalendarListOpen}
      {...props}
    />
    {(props.action === "View" || props.action === "") && (
      <div className="d-flex flex-fill flex-row-reverse bd-highlight buttons-wrapper">
        {/* <button
          type="button"
          className="primaryButton"
          onClick={() => {
            props.disableInput();
            props.handleCalendarSave("sync");
          }}
          disabled={
            props.calendarFormEventValues.length === 0 || props.disableCalendar
          }
          hidden={!props.editCalendar}
        >
          {props.editCalendar ? "Update & Sync" : ""}
        </button> */}

        <button
          type="button"
          className="primaryButton mr-2"
          onClick={() => {
            props.disableInput();
            props.handleCalendarSave();
          }}
          disabled={
            props.calendarFormEventValues.length === 0 || props.disableCalendar
          }
        >
          {props.editCalendar ? "Update" : "Save As Calendar"}
        </button>

        <button
          type="button"
          className="btn-outline-primary mr-2"
          onClick={() => props.handleCancelChanges()}
        >
          Cancel
        </button>
      </div>
    )}
  </div>
);
