import moment from "moment";
import { checkOverlap } from "../utils/eventOverlapChecker";
import { allEventsViewDataGenerator } from "../modules/rrules";

export function validateForm(state, totalEvents) {
  const values = ["campaignName", "title", "start", "end"];
  const validateResult = { formValid: true };
  const formattedEvents = totalEvents.filter(
    event => event.eventId !== state.eventId
  );
  const latestEvents = [...formattedEvents, state];
  const allTotalEventsForValidation = allEventsViewDataGenerator(latestEvents);
  const result = checkOverlap(allTotalEventsForValidation);
  values.forEach(item => {
    if (!state[item]) {
      validateResult[`${item}HasError`] = "Required";
      validateResult.formValid = false;
    } else if (item === "start") {
      const oneYearAhead = moment().add(1, "years");
      const oneYearPrior = moment().subtract(1, "years");
      const checkRange = moment(state[item]).isBetween(
        oneYearPrior,
        oneYearAhead
      );

      if (!checkRange) {
        validateResult[`${item}HasError`] = "Must be in the range of one year.";
        validateResult.formValid = false;
      } else if (result.length > 1) {
        validateResult[
          `${item}HasError`
        ] = `${result[0].title} event and ${result[1].title} event cannot be played at the same time.`;
        validateResult.formValid = false;
      } else {
        validateResult[`${item}HasError`] = undefined;
      }
    } else if (item === "end") {
      const isAfter = moment(state.end).isAfter(state.start);

      const startDate = moment(state.start);
      const endDate = moment(state.end);

      const startDateMinutes = startDate.hour() * 60 + startDate.minute();
      const endDateMinutes = endDate.hour() * 60 + endDate.minute();

      if (!isAfter) {
        validateResult[`${item}HasError`] =
          "End Datetime must be greater than start Datetime.";
        validateResult.formValid = false;
      } else {
        validateResult[`${item}HasError`] = undefined;
      }
      // checking if end time must be greater than start time
      if (
        state.repeat.value !== "not_repeat" &&
        endDateMinutes <= startDateMinutes
      ) {
        validateResult.formValid = false;
        validateResult[`${item}HasError`] =
          "End time must be greater than start time in repeating events.";
      }
      if (
        state.repeat.value === "not_repeat" &&
        startDate.format("YYYY-MM-DD") !== endDate.format("YYYY-MM-DD")
      ) {
        validateResult.formValid = false;
        validateResult[`${item}HasError`] =
          "Start Date and End Date must be of same Day";
      }
    } else {
      validateResult[`${item}HasError`] = undefined;
    }
  });
  return validateResult;
}

export function getStartDate(
  repeat,
  hasManyEvents,
  startDate,
  parentStartDate,
  selectedStartDate
) {
  const startTimeString = moment(startDate).format("HH:mm");
  const selectedStartTimeString = moment(selectedStartDate).format("HH:mm");
  const parentStartTimeString = moment(parentStartDate).format("HH:mm");

  const startDateString = moment(startDate).format("YYYY-MM-DD");
  const selectedStartDateString = moment(selectedStartDate).format(
    "YYYY-MM-DD"
  );
  const parentStartDateString = moment(parentStartDate).format("YYYY-MM-DD");
  if (
    repeat === "not_repeat" &&
    hasManyEvents &&
    startDateString === selectedStartDateString &&
    startTimeString === selectedStartTimeString
  ) {
    return selectedStartDate;
  }
  if (
    startDateString === selectedStartDateString &&
    startTimeString === selectedStartTimeString
  ) {
    return parentStartDate;
  }
  if (
    startDateString === selectedStartDateString &&
    startTimeString !== parentStartTimeString
  ) {
    return `${parentStartDateString}T${startTimeString}`;
  }
  return startDate;
}
