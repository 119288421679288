/* eslint-disable no-shadow */
import React from "react";
import { findIndex, remove } from "lodash";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { Resources } from "../../../playlist/redux";
import { updateCampaign } from "../../redux";

const PlaylistItem = (item, handleClickedItem, handleDragStart, selected) => (
  <div
    key={item.id}
    draggable
    onClick={e => handleClickedItem(e, item)}
    onDragStart={e => handleDragStart(e, item)}
    className="subwindow-tabcontent-list"
    onKeyDown={() => {}}
    role="presentation"
  >
    <div className="thumb" style={{ cursor: "pointer" }}>
      <div className="content-thumb-image">
        <img
          src={
            item.attributes.playlist_thumbnail_medium_url ||
            require("../../../../assets/images/empty-img.png")
          }
          alt={item.attributes.name || undefined}
          className="content__thumb"
        />
        <div className="playlist-thumbnail-overlay">
          {item.attributes.locked ? (
            <img
              src={require("../../../../assets/images/playlist_locked.png")}
              alt={item.attributes.name || undefined}
              className="overlay-img content__thumb "
              style={{ height: "25px", width: "25px", left: "26px" }}
            />
          ) : (
            <img
              src={require("../../../../assets/images/playlist_thumbnail.png")}
              alt={item.attributes.name || undefined}
              className="overlay-img content__thumb "
              style={{ height: "25px", width: "25px" }}
            />
          )}
        </div>
      </div>
      <div className="tabcontent-list-title" style={{ position: "relative" }}>
        <h5>{item.attributes.name}</h5>
        <span>
          <i>No of Content:</i>
          {item.relationships.playlist_contents.data.length}
        </span>
        {findIndex(selected, { id: item.id }) > -1 && (
          <i
            className="fas fa-check-circle"
            style={{
              color: "#2cc277",
              fontSize: "20px",
              right: "0px",
              top: "10px",
              position: "absolute"
            }}
          />
        )}
      </div>
    </div>
  </div>
);

class PlaylistTab extends React.Component {
  // = props => {
  constructor(props) {
    super(props);
    this.state = {
      url: "/playlists?page="
    };
    if (this.props.campaignLoading) {
      this.loadPlaylist();
    }
  }

  loadPlaylist = () => {
    const { getPlaylist } = Resources;
    getPlaylist.url = "/playlists?page=1&per_page=18";
    this.props.Get(getPlaylist).then(response => {
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.props.toggleFetch(false);
      }
    });
  };

  handleDragStart = (e, item) => {
    // e.dataTransfer.setData("text", "foo");
    if (item) {
      const { campaign, updateCampaign } = this.props;
      campaign.LoadPlaylistToBeDropped(item);
      updateCampaign(campaign);
    }
  };

  handleClickedItem = (e, item) => {
    e.preventDefault();
    const { campaign } = this.props;
    if (findIndex(campaign.playlistToBeDropped, { id: item.id }) === -1) {
      campaign.LoadPlaylistToBeDropped(item);
      this.props.updateCampaign(campaign);
      return 0;
    }
    remove(campaign.playlistToBeDropped, { id: item.id });
    this.props.updateCampaign(campaign);
  };

  fetchMoreData = async () => {
    this.props.increasePerPage();
    this.setState(() => {
      if (this.props.playlistSearch) {
        Resources.getPlaylist.url = `/playlists/search?search=${this.props.playlistSearch}&page=1&per_page=${this.props.perPage}`;
        this.props.Get(Resources.getPlaylist).then(response => {
          if (response.data.meta.totalPages <= response.data.meta.page) {
            this.props.toggleFetch(false);
          }
        });
        return true;
      }

      Resources.getPlaylist.url = `${this.state.url}1&per_page=${this.props.perPage}`;
      this.props.Get(Resources.getPlaylist).then(response => {
        if (response.data.meta.totalPages <= response.data.meta.page) {
          this.props.toggleFetch(false);
        }
      });
    });
  };

  render() {
    const { playlist, playlistLoading, isLoading } = this.props;

    if (playlistLoading || isLoading) {
      return (
        <svg
          className="spinner"
          viewBox="0 0 50 50"
          style={{ marginTop: "35px" }}
        >
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
          />
        </svg>
      );
    }
    return (
      <InfiniteScroll
        dataLength={playlist.length}
        next={this.fetchMoreData}
        hasMore={this.props.fetch}
        loader={
          !(playlistLoading || isLoading) &&
          playlist.length > 0 && (
            <div className="d-block text-center">
              <span>...Loading</span>
            </div>
          )
        }
        className="content-infinity"
        height={355}
        style={{ overFlow: "visible" }}
      >
        {playlist.length > 0 ? (
          <div>
            {playlist.map(item =>
              PlaylistItem(
                item,
                this.handleClickedItem,
                this.handleDragStart,
                this.props.campaign.playlistToBeDropped
              )
            )}
          </div>
        ) : (
          <p
            className="text-center"
            style={{ lineHeight: "25vh", fontSize: "20px" }}
          >
            Playlist Not Found
          </p>
        )}
      </InfiniteScroll>
    );
  }
}

export default connect(
  state => ({
    campaign: state.newCampaign.campaign,
    playlistLoading: state.playlists.loading
  }),
  {
    updateCampaign
  }
)(PlaylistTab);
