import React, { Component } from "react";
import { Label, Button, Form, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { reduxForm, Field, reset } from "redux-form";
import moment from "moment";
import Drawer from "rc-drawer";
import DatePicker from "../../../core/form/datePicker";
import { Resources as networkResources } from "../redux";
import { ContentTagResources } from "../../contents/redux";
import { Input } from "../../../core";
import { MultipleSelect } from "../../../core/form/searchSelect";

import "rc-drawer/dist/rc-drawer.css";

class ReportDetails extends Component {
  constructor() {
    super();
    this.state = {};
  }

  handleRemoveFilterBar = () => {
    this.props.handleToggleDrawer(false);
  };

  componentDidMount = () => {
    const { getContent } = ContentTagResources;
    this.props.Get(getContent);
  };

  handleSubmit = async values => {
    const {
      startDate,
      endDate,
      deviceName,
      contentName,
      campaignName,
      contentTags
    } = values;
    this.props.updateFilter({startDate, endDate, deviceName, contentName, campaignName, contentTags});
    const startTime = startDate ? moment(startDate).format("MM/DD/YYYY") : "";
    const endTime = endDate ? moment(endDate).format("MM/DD/YYYY") : "";
    const tag = contentTags
      ? contentTags.map(contentTag => contentTag.value)
      : "";
    const { filterReports } = networkResources;
    const url = `/reports/filter_report?device_name=${deviceName ||
      ""}&&start_time=${startTime}&&end_time=${endTime}&&campaign_name=${campaignName ||
      ""}&&content_name=${contentName || ""}&&content_tags=${tag || ""}`;
    filterReports.url = `${url}&&page=1`;
    this.props.handleFilter(url);
    this.props.handleLoading(true);
    this.handleRemoveFilterBar();
    const result = await this.props.Get(filterReports);
    if (result.status === 200) {
      this.props.handleLoading(false);
      // disabling the refetch
      if (result.data.data.length < 500) {
        this.props.handleFilter(url, false);
      }
    }
  };

  render() {
    return (
      <div>
        <Drawer
          className="report-drawer"
          open={this.props.drawer}
          // // onClose={() => this.props.toggledeviceControlModal("cancel")}
          handler={false}
          level={null}
          placement="right"
          width="29vw"
          onMaskClick={() => this.handleRemoveFilterBar("false")}
          // onClick={e => console.error(e.currentTarget)}
        >
          <div className="campaignDetail reportFilter">
            <span className="campaignDetail__information campaignDetail__information--border">
              Filter By
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.handleRemoveFilterBar("false")}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </span>
            <Form>
              <FormGroup>
                <div className="">
                  <h5> Name</h5>

                  <Field component={Input} name="contentName" />
                </div>
              </FormGroup>

              {/* <Label>Filter By Campaign Name</Label> */}
              {/* <Field
              component={MultipleSelect}
              name="campaignName"
              className="mt-4"
              placeholder="Filter By Campaign Name"
              options={this.props.campaignList}
            /> */}
              <FormGroup>
                <div className="">
                  <h5>Campaign Name</h5>

                  <Field component={Input} name="campaignName" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="">
                  <h5>Start Date</h5>
                  <Field
                    component={DatePicker}
                    dateFormat="yyyy/MM/dd"
                    name="startDate"
                    placeholderText="mm/dd/yyyy"
                    label="Start Date"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="">
                  <h5>End Date</h5>
                  <Field
                    component={DatePicker}
                    dateFormat="yyyy/MM/dd"
                    name="endDate"
                    placeholderText="mm/dd/yyyy"
                    label="End Date"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="">
                  <h5>Device Name</h5>

                  <Field
                    className="reportinput pt-0"
                    component={Input}
                    name="deviceName"
                    placeholder="Filter By Device Name"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="">
                  <h5>Content Tags</h5>
                  <Field
                    className="reportinput pt-0"
                    component={MultipleSelect}
                    isMulti
                    isClearable
                    name="contentTags"
                    placeholder="Content Tags"
                    options={this.props.ContentTag.map(i => ({
                      value: i,
                      label: i
                    }))}
                  />
                </div>
              </FormGroup>
              <div className="buttonWrapper">
                <button
                  className="btn-outline-primary"
                  onClick={this.props.initializeFilter}
                >
                  Clear
                </button>
                <button
                  className="primaryButton mt-0"
                  // className="btn-secondary"
                  disabled={this.props.submitting}
                  onClick={this.props.handleSubmit(values => {
                    this.handleSubmit(values);
                  })}
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Drawer>
      </div>
    );
  }
}
export default connect(
  state => ({
    // files: selector(state, "Files") || [],
    // newTags: state.form.Contents,
    ContentTag: state.ContentTag.list
      ? state.ContentTag.list.attributes.tags
      : []
  }),
  { reset }
)(
  reduxForm({
    form: "ReportFilter",
    destroyOnMount: false
  })(ReportDetails)
);

// export default connect(
//   state => {
//     const { campaign, content } = state;
//     return {
//       campaignList:
//         (campaign.list &&
//           campaign.list.map(i => ({ label: i.attributes.name, value: i.attributes.name }))) ||
//         [],
//       contentList:
//         (content.list &&
//           content.list.map(i => ({ label: i.attributes.name, value: i.attributes.name }))) ||
//         []
//     };
//   },
//   {}
// )(ReportDetail);
