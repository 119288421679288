import {
  tickets,
  ticketsNotification,
  Resources,
  // generateCreatePlaylistData,
  RemoveItemFromContentList,
  ticketDetails,
  ticketWebsocketReducer,
  ticketUpdateFromWebsocket
} from "./ticketRedux";
import { convertToServerData, convertToLocalEvent } from "./ticketData";

const ticketReducer = {
  tickets,
  ticketsNotification,
  ticketDetails,
  ticketWebsocketReducer
};

export {
  ticketReducer,
  Resources,
  // ticketDetails,
  convertToServerData,
  convertToLocalEvent,
  RemoveItemFromContentList,
  ticketUpdateFromWebsocket
};
