import { Component } from "react";

function isRouteAllowed(roles, requiredRoles = []) {
  const allowedRoutes = requiredRoles.filter(value => roles.includes(value));
  if (allowedRoutes.length > 0) {
    return true;
  }
  return false;
}

export default class RouteAllowed extends Component {
  render() {
    if (isRouteAllowed(this.props.roles, this.props.permission)) {
      // permission defines: which roles are allowed this action
      // roles defines: which roles the current user has
      return this.props.children;
    }
    return null;
  }
}
