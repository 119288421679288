import {
  Resources,
  calendars,
  calendarDetails,
  RemoveItemFromCalendarList,
  generateCreateCalendarData,
  Resources as calendarResources,
  postArchivedItems,
  removeSingleArchivedCalendar,
  archiveCalendars,
  unArchiveItems,
  totalCalendar
} from "./calendarRedux";

const calendarReducer = {
  calendars,
  archiveCalendars,
  calendarDetails,
  totalCalendar
};

export {
  Resources,
  calendarReducer,
  RemoveItemFromCalendarList,
  calendarResources,
  generateCreateCalendarData,
  postArchivedItems,
  removeSingleArchivedCalendar,
  archiveCalendars,
  unArchiveItems
};
