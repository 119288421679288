import { uniq } from "lodash";

const forAll = [
  "/dashboard/upcoming",
  "/profile",
  "/dashboard",
  "/domain",
  "/dashboard/searchdomain",
  "/dashboard/systeminfo",
  "/archives"
];

const domainEdit = [
  "/dashboard/adddomain",
  "/domain/edit/:id",
  "/domain/setting/:id",
]

const contentView = ["/content", "/content/show/:id"];
const contentChange = [
  "/content/create",
  "/content/upload",
  "/content/edit/:id",
  "/content/show/:id",
  "/content/archive"
];

const campaignView = [
  "/campaign",
  "/campaign/show/:id",
  "/campaign/prepare_usb/:id",
  "/campaign/archive",
  "/campaign/defaultcampaign"
];
const campaignChange = [
  "/campaign/create",
  "/campaign/edit/:id",
  "/campaign/studio"
];

const calendarView = ["/calendar"];
const calendarChange = [
  "/calendar/create",
  "/calendar/edit/:id",
  "/calendar/archive"
];

const playlistView = ["/playlist"];
const playlistChange = [
  "/playlist/create",
  "/playlist/edit/:id/",
  "/playlist/archive"
];

const ticketView = ["/tickets"];
const ticketChange = ["/tickets/show/:id", "/tickets/edit/:id/"];

const metricsNotification = ["/notifications"];

const network = [
  "/networks",
  "/networks/device/:id",
  "/networks/table",
  "/networks/new_group",
  "/networks/group/edit/:id",
  "/networks/screen_shot_view/:id",
  "/networks/screen_shot_view_all"
];

const metrics = ["/metrics"];

const report = ["/reports"];
const userChange = ["/adduser", "/userlist", "/edituser/:id"];
const deviceChange = ["/devices"];
const domainChange = [
  "/dashboard/adddomain",
  "/dasboard/domain",
  "/dashboard/editdomain"
];

const campaign = [
  ...forAll,
  ...contentView,
  ...contentChange,
  ...playlistView,
  ...campaignView,
  ...campaignChange,
  ...ticketView,
  ...ticketChange
];

const roleRoutes = {
  General: [...forAll, ...contentView],

  ContentManager: [
    ...forAll,
    ...contentView,
    ...contentChange,
    ...ticketView,
    ...ticketChange,
    ...metricsNotification,
  ],

  CampaignManager: [...campaign, ...playlistChange, ...metricsNotification,],
  CalendarManager: [...campaign, ...calendarView, ...calendarChange, ...metricsNotification,],
  PlaylistManager: [
    ...forAll,
    ...contentView,
    ...contentChange,
    ...playlistView,
    ...playlistChange,
    ...ticketView,
    ...ticketChange,
    ...metricsNotification,
  ],
  NetworkManager: [
    ...forAll,
    ...contentView,
    ...campaignView,
    ...calendarView,
    ...playlistView,
    ...network,
    ...metricsNotification,

  ],
  ReportManager: [
    ...forAll,
    ...report,
    ...domainEdit,
    ...contentView,
    ...contentChange,
    ...metricsNotification,

  ],
  Admin: [
    ...campaign,
    ...calendarView,
    ...calendarChange,
    ...playlistChange,
    ...network,
    ...report,
    ...userChange,
    ...metricsNotification,
    ...domainChange,
    ...domainEdit
  ],
  SuperAdmin: [
    ...campaign,
    ...calendarView,
    ...calendarChange,
    ...playlistChange,
    ...network,
    ...report,
    ...userChange,
    ...deviceChange,
    ...domainChange,
    ...metrics,
    ...metricsNotification,
    ...domainEdit,
  ]
};

export function FilterRoutesRoles(routes, roles) {
  let newRoutes = [];
  roles.map(role => {
    const temp = roleRoutes[role] || [];
    const r = routes.filter(item => {
      if (temp.includes(item.path)) {
        return item;
      }
    });

    newRoutes = [...newRoutes, ...r];
  });

  return uniq(newRoutes);
}
