import React from "react";
import { remove } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactPaginate from "react-paginate";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {
  Resources as userResources,
  handleNetworkViewPreference
} from "../../auth/redux/loginRedux";

import { columns, paginationOptions, dialogs } from "../utls/networkListUtils";

import NetworkHeader from "./networkHeader";

const rowClasses = (row, rowIndex) => {
  let classes = null;
  // console.error(row.att);

  if (row.attributes.status === "offline") {
    classes = "offlineClass";
  } else {
    classes = "onlineClass";
  }

  return classes;
};
class NetworkListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hiddenColumns: {
        macAddress: false,
        currentlyPlaying: false,
        downloaded: false,
        deviceGroupName: true,
        tags: true,
        hostname: true,
        electronVersion: true,
        timezone: true,
        orientation: true,
        status: false,
        searchVal: "",
        resolution: true,
        block_pop: false
      }
    };
    this.initialState = {
      name: false,
      mac_address: false,
      currently_playing: false,
      last_downloaded_name: false,
      tags: true,
      hostname: true,
      electron_version: true,
      timezone: true,
      orientation: true,
      status: false,
      resolution: true,
      action_buttons: false,
      group: true
    };
  }

  toggleHiddenColumns = (colName, flag) => {
    let formattedCol;
    if (this.props.userDetail.network_preference) {
      formattedCol = {
        ...this.props.userDetail.network_preference,
        [colName]: flag
      };
    } else {
      formattedCol = {
        ...this.initialState,
        [colName]: flag
      };
    }
    const { updateUser } = userResources;
    updateUser.url = `/users/${this.props.userDetail.id}`;
    updateUser.body = handleNetworkViewPreference(formattedCol);
    this.props.Put(updateUser);
  };

  handleNodata = () => <span className="null-message-info">No Data</span>;

  onPageChange = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState(() => ({
        loading: false
      }));
    }, 2000);
  };

  triggerHelpDialog = () => {
    this.props.openGlobalModal({ ...dialogs.openHelp });
  };

  render() {
    const selectRow = {
      mode: "checkbox",
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <label htmlFor="input" className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label htmlFor="input" className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("deviceSelectedInListView", [
            ...this.props.deviceSelectedInListView,
            row
          ]);
        } else {
          const { deviceSelectedInListView } = this.props;
          remove(deviceSelectedInListView, { id: row.id });
          this.props.change(
            "deviceSelectedInListView",
            deviceSelectedInListView
          );
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("deviceSelectedInListView", [
            ...this.props.deviceSelectedInListView,
            ...rows
          ]);
        } else {
          this.props.change("deviceSelectedInListView", []);
        }
      }
    };
    const options = {
      sizePerPage: 20,
      hideSizePerPage: true,
      withFirstAndLast: true,
      hidePageListOnlyOnePage: true,
      prePageText: "Previous",
      nextPageText: "Next",
      onPageChange: this.onPageChange
    };
    return (
      <div>
        <Field component="input" name="deviceSelectedInListView" hidden />

        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.devicesList}
          columns={columns(
            this.triggerHelpDialog,
            this.props.stopPlayingDevice,
            this.props.handleSort,
            this.props.sortOrder,
            this.props.userDetail.network_preference
              ? this.props.userDetail.network_preference
              : this.initialState,
            this.props.toggleDrawer,
            this.props.toggleSendToDevice,
            this.props.deviceList
          )}
          columnToggle
        >
          {props => (
            <div>
              <NetworkHeader
                hiddenColumns={
                  this.props.userDetail.network_preference
                    ? this.props.userDetail.network_preference
                    : this.initialState
                }
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                {...this.props}
              />

              <BootstrapTable
                classes="contentSectionTable networkListTable"
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                keyField="id"
                loading={this.props.isListNetworkLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#2cc277",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                noDataIndication={() =>
                  this.props.isListNetworkLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                selectRow={selectRow}
                rowClasses={rowClasses}
                rowEvents={{
                  onClick: (e, row) => {
                    e.stopPropagation();
                    const win = window.open(
                      `/networks/device/${row.id}?id=${row.attributes.device_group_id}&gn=${row.attributes.device_group_name}&n=${row.attributes.name}&view=lists`,
                      "_blank"
                    );
                    win.focus();
                  },
                  style: {
                    cursor: "pointer"
                  }
                }}
                filter={filterFactory()}
                defaultSortDirection="asc"
                hover
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
        {!this.state.isLoading &&
          this.props.devicesList &&
          this.props.devicesList.length > 0 && (
            <div className="contentSectionPagination clearfix">
              <ReactPaginate
                pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                pageRangeDisplayed={3}
                onPageChange={this.props.handlePageClick}
                marginPagesDisplayed={2}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                previousLinkClassName="pagination-label
                  "
                nextLinkClassName="pagination-label"
                previousLabel="<"
                forcePage={this.props.forcePage}
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                disabledClassName="paginationDisable"
              />
            </div>
          )}
      </div>
    );
  }
}

export default connect(state => {
  const selector = formValueSelector("SelectedDeviceInListView");
  const devicesList = state.networks.formatedList.data || [];
  const userDetail = state.userDetails.user;

  return {
    deviceSelectedInListView: selector(state, "deviceSelectedInListView") || [],
    devicesList,
    userDetail
  };
})(NetworkListView);
