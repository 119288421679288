import React, { Component } from "react";
import { Popover, PopoverBody, PopoverHeader, Label } from "reactstrap";

import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from "react-sortable-hoc";
import { SecondAdded } from "../../utls/helper";
import { Tooltip } from "../../../../core";

import SingleTime from "./singleTime";

function handleChange(value, fn, screen, campaign) {
  const temp = screen;
  temp.screenName = value;
  campaign.updateScreen(temp.localID, screen);
  fn(campaign);
}

const TimeParser = contents => {
  const d = SecondAdded(contents);
  return (
    <span className="mr-2 campaignStudioWrapper__timeParser">
      {`0${d.minute}`.slice(-2)}:{`0${d.second}`.slice(-2)}
    </span>
  );
};

export const SortableList = SortableContainer(
  ({
    items,
    props,
    forwardRef,
    handlePopOver,
    isPopOverOpen,
    handleClickScreen,
    selectedScreen,
    displayRegionsForMobile,
    displayMediaModal
  }) => (
    <ul className="">
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.localID}`}
          index={index}
          oldIndex={value.screenPosition}
          value={value}
          style={{ zIndex: 99999999 }}
          forwardRef={forwardRef}
          props={props}
          handlePopOver={handlePopOver}
          isPopOverOpen={isPopOverOpen}
          handleClickScreen={handleClickScreen}
          selectedScreen={selectedScreen}
          displayRegionsForMobile={displayRegionsForMobile}
          displayMediaModal={displayMediaModal}
        />
      ))}
    </ul>
  )
);

const SortableItem = SortableElement(
  ({
    value,
    forwardRef,
    props,
    handlePopOver,
    isPopOverOpen,
    handleClickScreen,
    selectedScreen,
    displayRegionsForMobile,
    displayMediaModal
  }) => (
    <li
      key={value.localID}
      className={
        selectedScreen && value.localID === selectedScreen.localID
          ? "campaignStudioWrapper__singleTime-mod"
          : "campaignStudioWrapper__singleTime"
      }
      style={{ borderBottom: displayRegionsForMobile && "none" }}
    >
      <div>
        {/* <DesktopBreakpoint> */}
        <DragHandle
          key={value.localID}
          screen={value}
          props={props}
          handlePopOver={handlePopOver}
          isPopOverOpen={isPopOverOpen}
          handleClickScreen={handleClickScreen}
          selectedScreen={selectedScreen}
          displayRegionsForMobile={displayRegionsForMobile}
        />
        {/* </DesktopBreakpoint> */}
        <SingleTime
          screen={value}
          Get={props.Get}
          forwardRef={forwardRef}
          userRoles={props.roles}
          permission={props.permission}
          displayMediaModal={displayMediaModal}
        />
      </div>
    </li>
  )
);

const DragHandle = sortableHandle(
  ({
    screen,
    props,
    handlePopOver,
    isPopOverOpen,
    handleClickScreen,
    selectedScreen,
    displayRegionsForMobile
  }) => (
    <div
      role="presentation"
      className={`${
        selectedScreen && screen.localID === selectedScreen.localID
          ? "drag-screen drag-screen-mod"
          : "drag-screen"
      } ${displayRegionsForMobile ? "showDragScreen" : "hideDragScreen"}`}
      onClick={() => handleClickScreen(screen)}
    >
      <div
        className={`d-flex campaignStudioWrapper__singleTimeLine__left ${selectedScreen &&
          screen.localID === selectedScreen.localID &&
          "showActiveScreen"}`}
        style={{
          borderRight: `4px solid ${screen.fill}`
        }}
      >
        <div className="mr-auto float-left ml-2">
          <input
            placeholder="Region Name"
            onChange={e =>
              handleChange(
                e.target.value,
                props.updateCampaign,
                screen,
                props.campaign
              )
            }
            type="text"
            value={screen.screenName}
            className="campaignStudioWrapper__input"
          />
        </div>

        <div className="">{TimeParser(screen.contents)}</div>
      </div>
    </div>
  )
);
