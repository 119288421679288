/* eslint-disable no-else-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import moment from "moment";
import lodash from "lodash";
import dtFormatter from "./dateTimeFormatter";
import playlistChecker from "./playlistChecker";

export default class PlaylistContentPicker {
  constructor() {
    this.playlist = []; // used as ref var. so send the whole thing when passed as argument
    this.randomizedPlaylist = []; // used as ref var. so send the whole thing when passed as argument
    this.selectionIndex = 0;
    this.random = false;
    this.useConstantDuration = false;
    this.constantDuration = 15;
    this.popOne = true;
    this.entireDurationOfPlay = 0;

    this.playlistChecker = playlistChecker;
    this.dtFormatter = dtFormatter;
    this.loadash = lodash;
  }

  loadPlaylist(incommingPlaylist) {
    this.popOne = incommingPlaylist.popOne;
    this.random = incommingPlaylist.random;
    this.useConstantDuration = !incommingPlaylist.usePlaylistDuration;
    this.constantDuration = incommingPlaylist.duration;

    incommingPlaylist.playlistContents.forEach(plContent => {
      let tempContent = {
        playlistId: incommingPlaylist.playlistId,
        contentId: plContent.content.contentID,
        name: plContent.name,
        restrictions: {
          ...plContent.restrictions,
          activationDate: plContent.restrictions.activationDate || Infinity,
          deactivationDate: plContent.restrictions.deactivationDate || Infinity,
          restrictedHours: {
            startHour:
              plContent.restrictions.restrictedHours.startHour || "00:00",
            endHour: plContent.restrictions.restrictedHours.endHour || "23:59"
          }
        },
        content: plContent.content,
        duration:
          plContent.content.duration === null ? 5 : plContent.content.duration
      };
      this.playlist.push(tempContent);
    });
  }

  async getContent() {
    if (this.popOne) {
      // #####HANDLE POPPED PLAY#####//
      // if the playlist Content is expected to be received one at one time,
      if (this.random) {
        // if random flag on, choose from random shuffled playlist
        if (this.selectionIndex === 0) {
          this.randomizedPlaylist = this.loadash.shuffle(this.playlist);
        }
        return await this.playlistContentSelector(this.randomizedPlaylist);
      } else {
        //  else choose from plain received playlist
        return await this.playlistContentSelector(this.playlist);
      }
      // eslint-disable-next-line no-else-return
    } else {
      // #####HANDLE BURST PLAY#####//
      // if the playlist Content is expected to be received at burst,
      let burstPlaylist = [];
      if (this.random) {
        // if random flag on, choose from random shuffled playlist
        if (this.selectionIndex === 0) {
          this.randomizedPlaylist = this.loadash.shuffle(this.playlist);
        }
        //  this.randomizedPlaylist.forEach((rplContent, index, randomizedPL) => {
        //    //  then check each the playlist contents if they are valid,
        //    let response = await this.individualPlaylistContentChecker(rplContent);
        //    if(response){
        //      //  and then collect the valid playlist contents
        //      //  burstPlaylist.push(rplContent)
        //      let selectedPlContent = {
        //        ...rplContent,
        //        duration: this.useConstantDuration ? this.constantDuration : rplContent.duration
        //      }
        //      burstPlaylist.push(selectedPlContent)
        //    }
        //    this.selectionIndex = (index + 1) % randomizedPL.length;
        //  })
        // .................
        let indexR = 0;
        for (const rplContent of this.randomizedPlaylist) {
          //  then check each the playlist contents if they are valid,
          let response = await this.individualPlaylistContentChecker(
            rplContent
          );
          if (response) {
            //  and then collect the valid playlist contents
            //  burstPlaylist.push(rplContent)
            let selectedPlContent = {
              ...rplContent,
              duration: this.useConstantDuration
                ? this.constantDuration
                : rplContent.duration
            };
            burstPlaylist.push(selectedPlContent);
          }
          this.selectionIndex = (indexR + 1) % this.randomizedPlaylist.length;
          indexR++;
        }
        // .................
      } else {
        //  else choose from plain received playlist
        //  this.playlist.forEach((plContent, index, PL) => {
        //    //  then check each the playlist contents if they are valid,
        //    let response = await this.individualPlaylistContentChecker(plContent);
        //    if(response){
        //      //  and then collect the valid playlist contents
        //      let selectedPlContent = {
        //        ...plContent,
        //        duration: this.useConstantDuration ? this.constantDuration : plContent.duration
        //      }
        //      burstPlaylist.push(selectedPlContent)
        //    }
        //    this.selectionIndex = (index + 1) % PL.length;
        //  })
        // .................
        let indexNR = 0;
        for (const plContent of this.playlist) {
          //  then check each the playlist contents if they are valid,
          let response = await this.individualPlaylistContentChecker(plContent);
          if (response) {
            //  and then collect the valid playlist contents
            let selectedPlContent = {
              ...plContent,
              duration: this.useConstantDuration
                ? this.constantDuration
                : plContent.duration
            };
            burstPlaylist.push(selectedPlContent);
          }
          this.selectionIndex = (indexNR + 1) % this.playlist.length;
          indexNR++;
        }
        // .................
      }
      //  and then return the burst playlist
      return burstPlaylist;
    }
  }

  async playlistContentSelector(playlist) {
    let plLength = playlist.length;
    if (plLength !== 0) {
      let index = this.selectionIndex;
      const myInitialIndex = this.selectionIndex;
      let selectedContent;
      // select a content based off of restrcitions
      //  while(index % plLength <= plLength){
      while (index < plLength) {
        // check for the indexed content, if it is playable
        let plc = playlist[index]; // plc = playlistContent
        let a = this.activationCheck(
          plc.restrictions.activationDate,
          plc.restrictions.deactivationDate
        );
        let b = this.restrictionHourCheck(
          plc.restrictions.restrictedHours.startHour,
          plc.restrictions.restrictedHours.endHour
        );
        if (a && b) {
          // if playable return the content
          selectedContent = plc;
          // then set the selection index to be 1 more than the current index
          this.selectionIndex = (index + 1) % plLength;
          // TODO: also increment the counter of selected playlist-content; by reference
          break;
        } else if (!selectedContent && index === plLength - 1) {
          index = (index + 1) % plLength; // should always be 0;
        } else {
          // else
          // check for the next index
          index = (index + 1) % plLength;
          if (index === myInitialIndex) {
            this.selectionIndex = index;
            break;
          }
        }
      }
      if (selectedContent === undefined) {
        // return something that signifies nothing for a no duration
        return { contentType: "nullContent", duration: 0 };
        // eslint-disable-next-line no-else-return
      } else {
        return {
          ...selectedContent.content,
          duration: this.useConstantDuration
            ? this.constantDuration
            : selectedContent.duration
        };
      }
    }
  }

  async individualPlaylistContentChecker(plc) {
    let a = this.activationCheck(
      plc.restrictions.activationDate,
      plc.restrictions.deactivationDate
    );
    let b = this.restrictionHourCheck(
      plc.restrictions.restrictedHours.startHour,
      plc.restrictions.restrictedHours.endHour
    );
    if (a && b) {
      return true;
    } else {
      return false;
    }
  }

  // @util functions
  activationCheck(activationDate, deactivationDate) {
    let nowDate;
    if (activationDate === Infinity || deactivationDate === Infinity) {
      nowDate = this.dtFormatter.standardDate(
        moment()
          .format()
          .toString()
      );
    }
    if (activationDate === Infinity) {
      activationDate = nowDate;
    }
    if (deactivationDate === Infinity) {
      deactivationDate = moment(nowDate)
        .add(24, "hours")
        .format()
        .toString();
    }
    // remove utc information from activation and deactivation date
    activationDate = this.dtFormatter.standardDateTime(activationDate);
    deactivationDate = this.dtFormatter.standardDateTime(deactivationDate);
    return this.playlistChecker.isBetweenActivationPeriod(
      activationDate,
      deactivationDate
    );
  }

  restrictionHourCheck(startHour, endHour) {
    let state = false;
    //  let sStartHour = dateTimeFormatter.standardHour(startTime)
    //  let sEndHour = dateTimeFormatter.standardHour(endTime)
    // no need to PULL out hour information, startTime and endTime has by default hour information only

    if (
      this.playlistChecker.hasReachedRestrictionHour(startHour) &&
      !this.playlistChecker.hasExceededRestrictionHour(endHour)
    ) {
      state = true;
    }
    return state;
  }
}
