import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import ReactDOM from "react-dom";
import { Flag } from "semantic-ui-react";
import { Tooltip, Input } from "../../../core";

class DomainSettingHeader extends React.Component {
  constructor(props) {
    super(props);
    this.props.initialize({
      domainName: props.domainTitle
    });
    // this.myInp = React.createRef();
    this.state = {
      displayButtons: false
    };
  }

  hideEditButton = hide => {
    if (hide) {
      this.setState(
        state => ({ editButtonDisabled: true, displayButtons: true }),
        () => {
          ReactDOM.findDOMNode(this.myinp).focus();
        }
      );
    } else {
      this.setState({ editButtonDisabled: false, displayButtons: false });
    }
  };

  toggleEditButton = flag => {
    this.setState({ displayButtons: flag });
  };

  render() {
    return (
      <div className="playist-wrapper">
        {!this.props.currentUserRoles.includes("SuperAdmin") ||
        (this.props.domainTitle === "gurzu" ||
          this.props.domainTitle === "Gurzu") ? (
          <h5 className="ml-2">{this.props.domainTitle}</h5>
        ) : (
          <div className="d-flex contentheader contentheader--playlistheader">
            {this.state.editButtonDisabled ? null : (
              <div
                role="presentation"
                onClick={() => {
                  this.hideEditButton(true);
                }}
              >
                <i className="fas fa-pencil-alt" />
                {/* <Tooltip item={<i className="fas fa-pencil-alt" />} tooltipContent="Edit" /> */}
              </div>
            )}
            <Field
              component="input"
              autoComplete="off"
              placeholder="Domain Name"
              type="text"
              name="domainName"
              style={{ width: "80%", minHeight: "30px" }}
              id="calendar-title"
              value={this.props.domainTitle}
              onClick={() => this.toggleEditButton(true)}
              onFocus={() => this.hideEditButton(true)}
              // onBlur={() => {
              //   this.props.initialize({
              //     domainName: this.props.domainTitle
              //   });
              //   this.hideEditButton(false);
              // }}
              ref={input => {
                this.myinp = input;
              }}
            />
            {this.state.displayButtons && (
              <div className="contentbutton action domainTitle">
                <i
                  role="presentation"
                  onClick={() => {
                    this.props.handleSubmit(this.props.saveDomainName);
                    this.toggleEditButton(false);
                  }}
                  className="fas fa-check-circle ml-2 mt-2"
                  style={{ color: "#09bda0", fontSize: "18px" }}
                />
                <i
                  role="presentation"
                  className="fas fa-times-circle mt-2"
                  style={{ color: "red", fontSize: "18px" }}
                  onClick={() => {
                    this.props.initialize({
                      domainName: this.props.domainTitle
                    });
                    this.toggleEditButton(false);
                    // this.setState({ displayButtons: false });
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default reduxForm({
  form: "DomainNameForm"
})(DomainSettingHeader);
