/* eslint-disable no-shadow */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Form,
  Col,
  Row
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { FormGroup, Label } from "semantic-ui-react";
import { SketchPicker } from "react-color";
import { MultipleSelect } from "../../../../core/form/searchSelect";
import { Tooltip } from "../../../../core";
import { timeZones } from "../../../../constant/timeZoneNUCs";
import "moment-timezone";

import { updateCampaign } from "../../redux";

function updateWidgetProperty(screenID, contentID, node, value, campaign, fn) {
  campaign.updateWidgetProperty(screenID, contentID, node, value);
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "timezone",
    defaultState.timezone
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "textColor",
    defaultState.textColor
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "backgroundColor",
    defaultState.backgroundColor
  );
  updateCampaign(campaign);
  toggle(false);
}

const DateTimeWidget = props => {
  const [showTextColorSelector, setTextColorSelector] = useState(false);
  const [showTextBackgroundColorSelector, setTextBackColorSelector] = useState(
    false
  );

  return (
    <Modal
      isOpen={props.open}
      toggle={() => props.toggle(false)}
      backdrop
      className="widgetScreenModal "
    >
      <ModalHeader toggle={() => cancelChanges(props)}>
        {props.type}
      </ModalHeader>
      <ModalBody>
        <div
          className="url-widget-background d-flex justify-content-center align-items-center"
          style={{
            height: props.data.screen.height,
            width: props.data.screen.width,
            background: props.DateTimeWidget.backgroundColor
          }}
        >
          <span className="text-center">
            <span
              style={{
                fontSize: "24px",
                color: props.DateTimeWidget.textColor
              }}
            >
              <b>
                {moment()
                  .tz(props.DateTimeWidget.timezone.value)
                  .format("hh:mm A")}
              </b>
            </span>
            <br />
            <span
              style={{
                fontSize: "16px",
                color: props.DateTimeWidget.textColor
              }}
            >
              {moment()
                .tz(props.DateTimeWidget.timezone.value)
                .format("dddd, MMMM DD YYYY")}
            </span>
          </span>
        </div>
        <Form>
          <FormGroup>
            <div className="globalInput" onClick={e => e.stopPropagation()}>
              <Label className="mb-2">Time Zone</Label>
              <MultipleSelect
                name="timeZone"
                placeholder="Select time zone"
                input={{
                  onChange: value =>
                    updateWidgetProperty(
                      props.data.screen.localID,
                      props.data.contentLocalID,
                      "timezone",
                      value,
                      props.campaign,
                      props.updateCampaign
                    ),
                  value: props.DateTimeWidget.timezone
                }}
                options={timeZones}
              />
            </div>
          </FormGroup>
          <Row className="mt-3 color-picker-wrapper" form>
            <Col md={12} className="btn-group">
              <Tooltip
                tooltipContent="Text Color"
                id="textColor"
                item={
                  <button
                    type="button"
                    className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
                    onClick={() => {
                      setTextColorSelector(!showTextColorSelector);
                      setTextBackColorSelector(false);
                    }}
                  >
                    <i className="fas fa-font" />{" "}
                    <b
                      className="selectColor"
                      style={{
                        backgroundColor: props.DateTimeWidget.textColor,
                        border: "1px solid black"
                      }}
                      role="presentation"
                    />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Background Color"
                id="bgColor"
                item={
                  <button
                    type="button"
                    className="btn-outline-secondary btn-outline-secondary--rightSideBorder buttonGroupOutline"
                    onClick={() => {
                      setTextColorSelector(false);
                      setTextBackColorSelector(
                        !showTextBackgroundColorSelector
                      );
                    }}
                  >
                    <i className="fas fa-fill-drip" />
                    <b
                      className="selectColor "
                      style={{
                        backgroundColor: props.DateTimeWidget.backgroundColor,
                        border: "1px solid black"
                      }}
                      role="presentation"
                    />
                  </button>
                }
              />
            </Col>
            {showTextColorSelector && (
              <div className="d-flex justify-content-center textColorPicker">
                <SketchPicker
                  color={props.DateTimeWidget.textColor}
                  onChange={value => {
                    updateWidgetProperty(
                      props.data.screen.localID,
                      props.data.contentLocalID,
                      "textColor",
                      value.hex,
                      props.campaign,
                      props.updateCampaign
                    );
                  }}
                />
              </div>
            )}
            {showTextBackgroundColorSelector && (
              <div className="d-flex justify-content-center textColorPicker">
                <SketchPicker
                  color={props.DateTimeWidget.backgroundColor}
                  onChange={value =>
                    updateWidgetProperty(
                      props.data.screen.localID,
                      props.data.contentLocalID,
                      "backgroundColor",
                      value.hex,
                      props.campaign,
                      props.updateCampaign
                    )
                  }
                />
              </div>
            )}
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-primary"
          onClick={() => cancelChanges(props)}
        >
          Cancel
        </Button>
        <Button
          className="mt-0 primaryButton"
          onClick={() => props.toggle(false)}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect(
  (state, props) => {
    const { campaign } = state.newCampaign;
    const {
      contentLocalID,
      screen: { localID }
    } = props.data;
    const DateTimeWidget = campaign.getContent(localID, contentLocalID);
    return {
      campaign,
      DateTimeWidget: DateTimeWidget || []
    };
  },
  { updateCampaign }
)(DateTimeWidget);
