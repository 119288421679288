/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import Select from "react-select";
import { upperFirst } from "lodash";

export const CustomSelectViewForCalendar = props => (
  <Select
    isSearchable={false}
    required
    onChange={e => props.onView(e.value)}
    defaultValue={[{ label: props.view, value: props.view }]}
    options={props.views.map(item => ({
      label: upperFirst(item),
      value: item
    }))}
  />
);
