import React from "react";
import { connect } from "react-redux";
import {
  Card,
  Row,
  Col,
  CardImg,
  CardBody,
  CardSubtitle,
  CardTitle,
  CardText,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { toLower, startCase, debounce } from "lodash";
import { Tooltip, SearchTagRefresh } from "../../../core";
import { Resources as deviceResources } from "../redux/deviceRedux";

/** Local import */
import {
  ScreenShotsResources as Resources,
  deviceGroupResources
} from "../redux";

class CardImageWithLoad extends React.Component {
  constructor(props) {
    super(props);
    this.checkImages(props.url);
    this.state = {
      isValidUrl: false
    };
  }

  checkImages = url => {
    const img = new Image();
    img.onload = () => {
      this.setState({
        isValidUrl: true
      });
    };
    img.onerror = () => {
      this.setState({
        isValidUrl: false
      });
    };
    img.src = url;
  };

  render() {
    return (
      <img
        src={
          this.state.isValidUrl
            ? this.props.url
            : require("../../../assets/images/no-image.png")
        }
        alt="Card image cap"
        // className="network-screen__img"
      />
    );
  }
}
// eslint-disable-next-line react/no-multi-comp
class SSView extends React.Component {
  handleGroupSearch = debounce(() => {
    if (this.state.groupID) {
      const { getDeviceScreenShots } = Resources;
      if (this.state.searchVal) {
        getDeviceScreenShots.url = `/devices/search_device?search=${this.state.searchVal}&device_group_id=${this.state.groupID}&page=${this.state.page}&per_page=20`;
      } else {
        getDeviceScreenShots.url = `/devices?device_group_id=${this.state.groupID}&page=${this.state.page}&per_page=20`;
      }
      this.props.Get(getDeviceScreenShots).then(response => {
        this.setState({
          loading: false,
          filtered: this.props.devicesList
        });
        if (response.data.meta.totalPages <= response.data.meta.page) {
          this.setState({ refetch: false });
          // this.props.handleRefetch();
        }
      });
    } else {
      const { getDeviceScreenShots } = Resources;
      if (this.state.searchVal) {
        getDeviceScreenShots.url = `/devices/search_device?search=${this.state.searchVal}&page=${this.state.page}&per_page=20`;
      } else {
        getDeviceScreenShots.url = `/devices?page=${this.state.page}&per_page=20`;
      }
      this.props.Get(getDeviceScreenShots).then(response => {
        this.setState({
          loading: false,
          filtered: this.props.devicesList
        });
        if (response.data.meta.totalPages <= response.data.meta.page) {
          this.setState({ refetch: false });
        }
      });
    }
  }, 3000);

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isRefreshing: false,
      currentRefreshingId: undefined,
      searchVal: "",
      filtered: [],
      refetch: true,
      page: 1,
      groupID: null
    };
    if (this.props.match.path === "/networks/screen_shot_view/:id") {
      this.handleDeviceGroupDetails(this.props.match.params.id);
    } else {
      this.initiateBulkAPILoad();
    }
  }

  /** Bulk API LOAD */
  initiateBulkAPILoad = () => {
    const APIRequest = [];
    APIRequest.push(this.handleLoadNetworkList());
    Promise.all(APIRequest).then(() => {
      this.setState(() => ({
        loading: false,
        filtered: this.props.devicesList
      }));
    });
  };

  handleChange = e => {
    this.setState({
      searchVal: e.target.value,
      refetch: true,
      // loading: true,
      page: 1
    });
    this.handleGroupSearch();
  };

  /** API call for loading device list  */
  handleLoadNetworkList = async () => {
    const { getDeviceScreenShots } = Resources;
    getDeviceScreenShots.url = `/devices?page=1&per_page=20`;
    await this.props.Get(getDeviceScreenShots);
  };

  /** API call for loading group details */
  handleDeviceGroupDetails = async id => {
    const { getDeviceScreenShots } = Resources;
    // getSingleDeviceGroup.url = `/device_groups/${id}`;
    getDeviceScreenShots.url = `/devices?device_group_id=${id}&page=${this.state.page}&per_page=20`;
    this.props.Get(getDeviceScreenShots).then(response => {
      this.setState({
        groupID: id,
        loading: false,
        filtered: this.props.devicesList
      });
      if (response.data.meta.totalPages <= response.data.meta.page) {
        // this.props.handleRefetch();
        this.setState({ refetch: false });
      }
    });
    // getSingleDeviceGroup.url = `/device_groups/${id}/devices`;
    // await this.props.Get(getSingleDeviceGroup).then(() => {
    //   this.setState(() => ({
    //     loading: false,
    //     filtered: this.props.groupDeviceList
    //   }));
    // });
  };

  /** all device list screenshot refresh */
  handleRefresh = id => {
    const { getDeviceSS } = deviceResources;
    this.setState(() => ({
      isRefreshing: true,
      currentRefreshingId: id
    }));
    if (!this.state.isRefreshing || this.state.currentRefreshingId !== id) {
      this.props.Get(getDeviceSS(id)).then(response => {
        const newDevices = this.state.filtered;
        const refreshedIndex = newDevices.findIndex(
          i =>
            response.data &&
            response.data.data &&
            i.id === response.data.data.id
        );
        newDevices[refreshedIndex] = response.data ? response.data.data : [];
        this.setState(() => ({
          isRefreshing: false,
          currentRefreshingId: id,
          filtered: newDevices
        }));
      });
      // this.props.Get(getDeviceSS(id)).then(() => {
      //   if (this.props.match.path === "/networks/screen_shot_view/:id") {
      //     this.handleDeviceGroupDetails(this.props.match.params.id);
      //   } else {
      //     this.handleLoadNetworkList().then(() =>
      //       this.setState({
      //         filtered: this.props.devicesList
      //       })
      //     );
      //   }
      //   this.setState(() => ({
      //     isRefreshing: false,
      //     currentRefreshingId: id
      //   }));
      // });
    }
  };

  /** Refresh screenshots from a device in a group */
  handleDeviceINGroupScreenShotRefresh = id => {
    const { getDeviceSS } = deviceResources;
    this.setState(() => ({
      isRefreshing: true,
      currentRefreshingId: id
    }));
    this.props.Get(getDeviceSS(id)).then(() => {
      this.handleDeviceGroupDetails(this.props.match.params.id).then(() => {
        this.setState(() => ({
          isRefreshing: false,
          currentRefreshingId: id
        }));
      });
    });
  };

  handleNextForAllDevice = () => {
    const { getDeviceScreenShots } = Resources;
    if (this.state.searchVal) {
      getDeviceScreenShots.url = `/devices/search_device?search=${this.state.searchVal}&page=${this.state.page}&per_page=20`;
      this.props.Get(getDeviceScreenShots).then(response => {
        this.setState({
          filtered: [...this.state.filtered, ...this.props.devicesList]
        });
        if (response.data.meta.totalPages <= response.data.meta.page) {
          // this.props.handleRefetch();
          this.setState({ refetch: false });
        }
      });
      return 0;
    }
    getDeviceScreenShots.url = `/devices?page=${this.state.page}&per_page=20`;
    this.props.Get(getDeviceScreenShots).then(response => {
      this.setState({
        filtered: [...this.state.filtered, ...this.props.devicesList]
      });
      if (response.data.meta.totalPages <= response.data.meta.page) {
        // this.props.handleRefetch();
        this.setState({ refetch: false });
      }
    });
  };

  handleNextForGroupDevice = () => {
    const { getDeviceScreenShots } = Resources;
    if (this.state.searchVal) {
      getDeviceScreenShots.url = `/devices/search_device?search=${this.state.searchVal}&device_group_id=${this.state.groupID}&page=${this.state.page}&per_page=20`;
      this.props.Get(getDeviceScreenShots).then(response => {
        this.setState({
          filtered: [...this.state.filtered, ...this.props.devicesList]
        });
        if (response.data.meta.totalPages <= response.data.meta.page) {
          // this.props.handleRefetch();
          this.setState({ refetch: false });
        }
      });
      return 0;
    }
    getDeviceScreenShots.url = `/devices?device_group_id=${this.state.groupID}&page=${this.state.page}&per_page=20`;
    this.props.Get(getDeviceScreenShots).then(response => {
      this.setState({
        filtered: [...this.state.filtered, ...this.props.devicesList]
      });
      if (response.data.meta.totalPages <= response.data.meta.page) {
        // this.props.handleRefetch();
        this.setState({ refetch: false });
      }
    });
  };

  handleNext = async () => {
    if (this.state.refetch) {
      this.setState(
        prevState => ({
          page: prevState.page + 1
        }),
        () => {
          if (this.state.groupID) {
            this.handleNextForGroupDevice();
          } else {
            this.handleNextForAllDevice();
          }
        }
      );
    }
    return true;
  };

  render() {
    const { isRefreshing, currentRefreshingId } = this.state;
    if (this.state.loading) {
      return (
        <div className="d-block text-center">
          {" "}
          {/* <div className="lds-dual-ring" /> */}{" "}
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    const { refreshing } = this.props;
    return (
      <div className="mainPage">
        <div className="contentSection">
          <div className="d-flex contentHeader">
            <div className="ml-auto">
              <SearchTagRefresh
                handleChange={this.handleChange}
                searchVal={this.state.searchVal}
                searchTab
                placeholder="Search Device"
                {...this.props}
              />{" "}
            </div>
          </div>{" "}
          <div className="screenShot_container">
            <InfiniteScroll
              dataLength={this.state.filtered.length} // This is important field to render the next data
              next={this.handleNext}
              hasMore={this.state.refetch}
              // height={700}
              loader={
                <div className="d-block text-center">
                  <span>...Loading</span>
                </div>
              }
              style={{ overFlow: "visible", "overflow-x": "hidden" }}
            >
              {this.state.filtered.length !== 0 ? (
                <div className="row">
                  {" "}
                  {this.state.filtered.map(item => (
                    <div
                      className="col-xs-12
                    col-sm-8
                    col-md-6
                    col-lg-3"
                      key={item.id}
                    >
                      <Card className="screenShot_container__screen">
                        <CardBody>
                          <div className="screenShot_container__imageWrapper">
                            <Link
                              style={{ textDecoration: "none" }}
                              to={{
                                pathname: `/networks/device/${item.id}?id=${item.attributes.device_group_id}&gn=${item.attributes.device_group_name}&n=${item.attributes.name}`,
                                state: {
                                  groupID: item.attributes.device_group_id,
                                  groupName: item.attributes.device_group_name,
                                  name: item.attributes.name || ""
                                }
                              }}
                            >
                              {" "}
                              <CardImageWithLoad
                                url={item.attributes.s3_screenshot_url}
                              />
                            </Link>

                            <div className="imageOverlay">
                              <div className="overlayText">
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={{
                                    pathname: `/networks/device/${item.id}?id=${item.attributes.device_group_id}&gn=${item.attributes.device_group_name}&n=${item.attributes.name}`,
                                    state: {
                                      groupID: item.attributes.device_group_id,
                                      groupName:
                                        item.attributes.device_group_name,
                                      name: item.attributes.name || ""
                                    }
                                  }}
                                  className="deviceName"
                                >
                                  {" "}
                                  <span className="deviceName">
                                    {item.attributes.name}
                                  </span>
                                </Link>

                                <span className="macAddress">{`${item.attributes.mac_address} `}</span>
                              </div>
                              {/* <div
                                role="button"
                                className="actionIconButton"
                                onClick={() => this.handleRefresh(item.id)}
                              >
                                <i
                                  className={`bx bx-revision ${
                                    isRefreshing &&
                                    currentRefreshingId === item.id
                                      ? "refresh-rotate "
                                      : ""
                                  }`}
                                />
                              </div> */}
                            </div>
                          </div>
                          <div className="d-flex screenShot_container__textWrapper">
                            <div className="mr-auto screenShot_container__hostName">
                              {item.attributes.hostname &&
                              item.attributes.hostname.length >= 20 ? (
                                <Tooltip
                                  item={
                                    <span>
                                      {" "}
                                      {`${item.attributes.hostname.slice(
                                        0,
                                        20
                                      )}...`}{" "}
                                    </span>
                                  }
                                  id={`viewname-${item.id}`}
                                  tooltipContent={item.attributes.hostname}
                                />
                              ) : (
                                <span>{item.attributes.hostname}</span>
                              )}
                              {!item.attributes.hostname && <span>N/A</span>}
                            </div>
                            <div className="screenShot_container__buttonsWrapper">
                              <span className="screenShotButton screenShot_container__version">
                                {/* {item.attributes.electron_version || "N/A"}{" "} */}
                                {item.attributes.electron_version &&
                                item.attributes.electron_version.length >=
                                  20 ? (
                                  <Tooltip
                                    item={
                                      <span>
                                        {" "}
                                        {`${item.attributes.electron_version.slice(
                                          0,
                                          20
                                        )}...`}{" "}
                                      </span>
                                    }
                                    id={`viewname-${item.id}`}
                                    tooltipContent={
                                      item.attributes.electron_version
                                    }
                                  />
                                ) : (
                                  <span>
                                    {item.attributes.electron_version}
                                  </span>
                                )}
                                {!item.attributes.electron_version && (
                                  <span>N/A</span>
                                )}
                              </span>

                              <span
                                className={`screenShotButton screenShot_container${
                                  item.attributes.status === "online"
                                    ? "__activeStatus"
                                    : "__offlineStatus"
                                }`}
                              >
                                {item.attributes.status || "N/A"}{" "}
                              </span>
                              <div
                                role="button"
                                className="actionIconButton"
                                onClick={() => this.handleRefresh(item.id)}
                              >
                                <i
                                  className={`bx bx-revision ${
                                    isRefreshing &&
                                    currentRefreshingId === item.id
                                      ? "refresh-rotate "
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                          <span style={{ fontSize: "14px" }}>
                            {item.attributes.screenshot_updated_at
                              ? `Updated  ${moment(
                                  item.attributes.screenshot_updated_at
                                ).fromNow()}`
                              : "N/A"}
                          </span>

                          {/* <Button
                            size="sm"
                            onClick={() => this.handleRefresh(item.id)}
                            className={`btn-outline-primary ml-0 mt-2 ${
                              isRefreshing && currentRefreshingId === item.id
                                ? "refresh-not-allowed"
                                : ""
                            }`}
                            type="submit"
                          >
                            {isRefreshing && currentRefreshingId === item.id
                              ? "Refreshing..."
                              : "Refresh"}
                          </Button> */}
                        </CardBody>{" "}
                      </Card>{" "}
                    </div>
                  ))}{" "}
                </div>
              ) : (
                <React.Fragment>
                  <div className="null-message-info">
                    <h5 className="pt-5 pb-5"> There are no screenshots!! </h5>{" "}
                  </div>{" "}
                </React.Fragment>
              )}{" "}
            </InfiniteScroll>
          </div>{" "}
        </div>
      </div>
    );

    // if (this.props.location.state === undefined) {
    //   this.props.history.push("/networks");
    //   return null;
    // }
    // const { refreshing } = this.props;
    // return (
    //   <div className="mainPage">
    //     <div className="contentSection">
    //       <div className="d-flex mb-2 pr-0 contentheader networkFixedHeader">
    //         <div className="ml-auto">
    //           <SearchTagRefresh
    //             handleChange={this.handleChange}
    //             searchVal={this.state.searchVal}
    //             searchTab
    //             {...this.props}
    //           />{" "}
    //         </div>
    //       </div>{" "}
    //       {this.state.filtered.length !== 0 ? (
    //         <Row>
    //           {" "}
    //           {this.state.filtered.map(item => (
    //             <Col md="3" className="mb-5" key={item.id}>
    //               <Card>
    //                 <CardBody>
    //                   <div className="network-screen">
    //                     <CardImageWithLoad
    //                       url={item.attributes.s3ScreenshotUrl}
    //                     />{" "}
    //                   </div>
    //                   {/* <CardTitle>{item.attributes.name}</CardTitle> */}{" "}
    //                   <table className="mt-4 network-screen__info">
    //                     <tr>
    //                       <td>Name</td>
    //                       <td>
    //                         {item.attributes.name.length >= 20 ? (
    //                           <Tooltip
    //                             item={
    //                               <span>
    //                                 {" "}
    //                                 {`${item.attributes.name.slice(
    //                                   0,
    //                                   20
    //                                 )}...`}{" "}
    //                               </span>
    //                             }
    //                             id={`viewname-${item.id}`}
    //                             tooltipContent={item.attributes.name}
    //                           />
    //                         ) : (
    //                           <span>{item.attributes.name}</span>
    //                         )}
    //                       </td>
    //                     </tr>
    //                     <tr>
    //                       <td>MAC Address</td>
    //                       <td>{item.attributes.macAddress}</td>
    //                     </tr>
    //                     <tr>
    //                       <td>Updated At</td>
    //                       <td>
    //                         {item.attributes.screenshotUpdatedAt
    //                           ? moment(
    //                               `${item.attributes.screenshotUpdatedAt}`
    //                             ).format("lll")
    //                           : "N/A"}
    //                       </td>
    //                     </tr>
    //                   </table>
    //                   <Button
    //                     size="sm"
    //                     onClick={() => this.handleRefresh(item.id)}
    //                     className={`btn-outline-primary ml-0 mt-2 ${
    //                       isRefreshing && currentRefreshingId === item.id
    //                         ? "refresh-not-allowed"
    //                         : ""
    //                     }`}
    //                     type="submit"
    //                   >
    //                     {isRefreshing && currentRefreshingId === item.id
    //                       ? "Refreshing..."
    //                       : "Refresh"}
    //                   </Button>
    //                 </CardBody>{" "}
    //               </Card>{" "}
    //             </Col>
    //           ))}{" "}
    //         </Row>
    //       ) : (
    //         <React.Fragment>
    //           <div
    //             className="null-message-info "
    //             // style={{
    //             //   width: "100%",
    //             //   height: "200px"
    //             // }}
    //           >
    //             <h5 className="pt-5"> There are no screenshots!! </h5>{" "}
    //           </div>{" "}
    //         </React.Fragment>
    //       )}{" "}
    //     </div>
    //   </div>
    // );
  }
}

function mapStateToProps(state) {
  const { list } = state.getDeviceScreenShots;
  const groupDeviceList = state.deviceGroupDetails.list;
  const selectedGroup = state.selectedGroup.currentGroup;
  return {
    devicesList: list,
    groupDeviceList:
      groupDeviceList && groupDeviceList.hasOwnProperty("devices")
        ? Object.values(groupDeviceList.devices)
        : [],
    selectedGroup
  };
}
export default connect(mapStateToProps)(SSView);
