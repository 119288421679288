import React from "react";
import Resize from "../../utls/resize";
import { SecondAdded } from "../../utls/helper";

const RenderWarningNotice = (type, duration = { minute: 0, second: 0 }) => {
  if (type.search("video") > -1 && duration.minute > 1) {
    return (
      <span
        style={{
          fontSize: "14px",
          position: "absolute",
          top: "0px",
          right: "0px",
          borderRadius: "3px",
          backgroundColor: " #ffff06",
          color: "rgb(0, 0, 0)",
          padding: "1px"
        }}
      >
        Duration too long
      </span>
    );
  }
  if (
    type.search("image") > -1 &&
    (duration.second > 30 || duration.minute > 0)
  ) {
    return (
      <span
        style={{
          fontSize: "14px",
          position: "absolute",
          top: "0px",
          right: "0px",
          borderRadius: "3px",
          backgroundColor: " #ffff06",
          color: "rgb(0, 0, 0)",
          padding: "1px"
        }}
      >
        Duration too long
      </span>
    );
  }
  return null;
};

const TimeParser = contents => {
  const d = SecondAdded(contents);
  return (
    <span className="campaignStudioWrapper__content-duration">
      {`0${d.minute}`.slice(-2)}:{`0${d.second}`.slice(-2)}
    </span>
  );
};

const handleSnapMessage = (item, screen, campaign, fn) => {
  setTimeout(() => {
    campaign.setSnapEnableForContent(
      screen.localID,
      item.contentLocalID,
      false
    );
    fn(campaign);
  }, 4000);
};

const RenderContents = (
  item,
  handleModalToggle,
  setContentModalToggle,
  setContentModalData,
  screen,
  campaign,
  updateCampaign
) => (
  <span
    // className="pl-1 pr-1 pb-1"
    // style={{
    //   color: "#fff",
    //   backgroundColor: "#000",
    //   borderRadius: "2px"
    // }}
    className="campaignStudioWrapper__singletimeline__actions"
  >
    <i
      role="presentation"
      id="mps-unique-button-inside-container-important"
      onClick={() =>
        handleModalToggle(
          true,
          setContentModalToggle,
          setContentModalData,
          item,
          screen,
          campaign,
          updateCampaign
        )
      }
      className="fa fa-edit ml-1 mr-1"
      style={{ color: "white" }}
    />

    <i
      role="presentation"
      id="mps-unique-button-inside-container-important"
      onClick={() => {
        campaign.removeContentFromScreen(screen.localID, item.contentLocalID);
        updateCampaign(campaign);
      }}
      className="fa fa-trash mr-1"
      style={{ color: "white" }}
    />
    <i className="fas fa-bars mr-1" id="dragging-effect" />

    {`${item.duration.minute}:${item.duration.second}`}
  </span>
);

export default function Content({
  item,
  calculateTimeInSecond,
  setIsSelected,
  handleContentTileClick,
  setCurrentlySelected,
  currentlySelected,
  screen,
  campaign,
  updateCampaign,
  handleModalToggle,
  setContentModalData,
  setContentModalToggle,
  ...rest
}) {
  return (
    <div
      id="mps-unique-button-inside-container-important"
      key={item.contentLocalID}
      style={{
        width: "100%",
        height:
          item.meta.attributes.content_type.search("video") > -1
            ? "100%"
            : "60px",

        paddingRight: "2px",
        paddingLeft: "2px",
        cursor: "grabbing"
      }}
      className="studioImage"
      onClick={e => handleContentTileClick(e, item, setIsSelected)}
      role="presentation"
    >
      {/* <Resize
        contentID={item.contentLocalID}
        screenID={screen.localID}
        setCurrentlySelected={setCurrentlySelected}
        currentlySelected={currentlySelected}
      > */}
      {/* {console.error("item", item)} */}
      {item.meta.attributes.is_filler_content &&
      item.meta.attributes.is_filler_content === true ? (
        <>
          <div style={{ position: " absolute", bottom: "0px", left: "3px" }}>
            <i
              className="bx bxs-certification"
              style={{ color: "#e44b32" }}
            ></i>
          </div>
          <div
            style={{
              height:
                item.meta.attributes.content_type.search("video") > -1
                  ? "91%"
                  : "76%",
              marginTop: "2px",
              borderRadius: "3px",
              backgroundImage: `url(${item.contentThumbnail})`,
              backgroundPosition: "center center",
              backgroundSize: "contain",
              backgroundColor: "#000",
              resize: "horizontal"
            }}
          >
            {TimeParser([item])}
            {RenderWarningNotice(
              item.meta.attributes.content_type,
              item.duration
            )}
            {item.isSnapped && (
              <span className="campaignStudioWrapper__snapMessage">
                Snapped!
              </span>
            )}
            {item.isSnapped &&
              handleSnapMessage(item, screen, campaign, updateCampaign)}
          </div>
        </>
      ) : (
        <div
          style={{
            height:
              item.meta.attributes.content_type.search("video") > -1
                ? "91%"
                : "76%",
            marginTop: "2px",
            borderRadius: "3px",
            backgroundImage: `url(${item.contentThumbnail})`,
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundColor: "#000",
            resize: "horizontal"
          }}
        >
          {TimeParser([item])}
          {RenderWarningNotice(
            item.meta.attributes.content_type,
            item.duration
          )}
          {item.isSnapped && (
            <span className="campaignStudioWrapper__snapMessage">Snapped!</span>
          )}
          {item.isSnapped &&
            handleSnapMessage(item, screen, campaign, updateCampaign)}
        </div>
      )}

      {/* {item.duration.second > 30 && (
          <span
            style={{
              position: "absolute",
              bottom: 0,
              right: 5,
              borderRadius: "3px",
              backgroundColor: "yellow",
              color: "#000",
              padding: "1px"
            }}
          >
            Duration too long
          </span>
        )} */}
      {/* </Resize>{" "} */}
    </div>
  );
}
