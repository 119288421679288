import React, { useState } from "react";
import { Label, Col, FormGroup, Form, Button } from "reactstrap";
import { Field } from "redux-form";
import { Input } from "../../../core";
import { TagSelect } from "../../../core/form/tagSelect";

function UpdateDeviceInformationForm(props) {
  return (
    <Form>
      <FormGroup row>
        <Label for="HostName" sm={2}>
          Name:
        </Label>
        <Col sm={10}>
          <Field
            component={Input}
            type="name"
            name="name"
            id="name"
            placeholder="Name"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="assetId" sm={2}>
          Assest Id:
        </Label>
        <Col sm={10}>
          <Field
            component={Input}
            type="text"
            name="assetId"
            id="assetId"
            placeholder="Assest Id"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="assetId" sm={2}>
          Tags:
        </Label>
        <Col sm={10}>
          <Field
            component={TagSelect}
            isMulti
            disabled
            type="text"
            name="tags"
            id="tags"
            clearError={props.clearError}
            placeholder="Device Tag"
            onCreateOption={props.handleNewTag}
            tagHasError={props.tagHasError}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="assetId" sm={2}>
          Longitude:
        </Label>
        <Col sm={4}>
          <Field
            component={Input}
            type="text"
            name="longitude"
            id="longitude"
            placeholder="Longitude"
          />
        </Col>
        <Label for="assetId" sm={2} className="pr-0 pl-5">
          Latitude:
        </Label>
        <Col sm={4}>
          <Field
            component={Input}
            type="text"
            name="latitude"
            id="latitude"
            placeholder="Latitude"
          />
        </Col>
      </FormGroup>
      <Button
        onClick={props.handleSubmit(props.handleDeviceUpdate)}
        className="float-right"
        color="primary"
      >
        Save Change
      </Button>
    </Form>
  );
}

export default UpdateDeviceInformationForm;
