/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Card, CardTitle, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dialog, Tooltip } from "../../../core";
import { calendarResources } from "../redux";

/**
 * TODO:
 * this is also the module later on must be shifted to storybook
 * name, content_type, s3_thumbnail_url, dimension, size, status
 * s3_object_key
}
 */

export default class ItemDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    const { showCalendar } = calendarResources;
    const { id } = this.props.match.params;
    showCalendar.url = `/calendars/${id}`;
    this.props.Get(showCalendar);
  }

  state = {};

  render() {
    if (this.props.location.params === undefined) {
      this.props.history.push("/calendar/");
      return null;
    }
    const { location } = this.props;
    return (
      <div className="container">
        <h5 className="d-flex justify-content-start mt-4">
          {location.params.name}
        </h5>
        <Row className="contentheader">
          {/* <Col sm="7">
            <EditModule
              awsKey={location.params.s3_object_key}
              contentType={location.params.content_type}
            />
          </Col> */}
          {/* <Col sm="5"> */}
          <Col>
            <Card body>
              <CardTitle className="d-flex">
                Information
                <div className="d-flex justify-content-end ml-auto contentbutton  inneraction">
                  <span className="d-flex justify-content-end action">
                    {/* <Link
                      to={`/dashboard/content/edit/${location.params.id}`}
                      className="action__edit"
                    >
                      <Tooltip
                        item={<i className="fa fa-edit" />}
                        id={`edit-${location.params.id}`}
                        tooltipContent="Edit"
                      />
                    </Link>

                    <Tooltip
                      item={
                        <i
                          onClick={() => this.handleDeleteConfirmation(location.params.id)}
                          className="fa fa-trash action__trash pt-2 pl-2 mr-0"
                          style={{ cursor: "pointer" }}
                        />
                      }
                      id={`delete-${location.params.id}`}
                      tooltipContent="Delete"
                    /> */}
                  </span>
                </div>
              </CardTitle>
              <span className="title-border" />
              <p>
                <b>Name:</b>
                {location.params.name}
              </p>
              <p>
                <b>Start At:</b>
                {location.params.start_at}
              </p>
              <p>
                <b>Stop At:</b>
                {location.params.stop_at}
              </p>
              <p>
                <b>Created At:</b>
                {location.params.created_at}
              </p>
              <p>
                <b>Updated At:</b>
                {location.params.updated_at}
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
