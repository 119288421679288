import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import { connect } from "react-redux";
import { Resources } from "../../redux";
import { Get } from "../../../../constant/thunk";

class WithPromises extends Component {
  componentDidMount() {
    this.getWeather(this.props.location, "", this.props.weatherWidget);
  }

  getWeather = async (location, oldLocation, weatherWidget) => {
    const days = weatherWidget ? weatherWidget.days : 1;
    const geoLocation =
      weatherWidget && weatherWidget.geo_location
        ? weatherWidget.geo_location
        : { lat: "", lon: "" };
    const result = await this.props.Get(
      Resources.getWeather(location, days, geoLocation)
    );
    if (result.status === 200) {
      this.props.updateGeoLocation(result.data.geo_location);
      this.props.setWeather(result);
    } else {
      this.props.updateLocation(oldLocation);
    }
  };

  handleChange = value => {
    const oldLocation = this.props.location;
    const location = value.value.split(",")[0].trim();
    this.props.updateLocation(location);
    this.getWeather(location, oldLocation);
  };

  promiseOptions = async inputValue => {
    const result = await this.props.Get(Resources.autoComplete(inputValue));
    if (result.status === 200) {
      const {
        data: { predictions }
      } = result;
      return predictions.map(item => ({
        label: item.description,
        value: item.description
      }));
    }
  };

  render() {
    return (
      <AsyncSelect
        onChange={this.handleChange}
        cacheOptions
        value={{ value: this.props.location, label: this.props.location }}
        defaultOptions={{
          value: this.props.location,
          label: this.props.location
        }}
        loadOptions={this.promiseOptions}
      />
    );
  }
}

export default connect(
  null,
  { Get }
)(WithPromises);
