import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { remove } from "lodash";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { columns } from "../utils/domainListUtils";
import RouteAllowed from "../../../helper/isRouteAllowed";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";
import DomainHeader from "../pages/domainHeader";

class DomainTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        name: false,
        screenshot_time: false,
        subscription: false,
        date_created: false,
        date_updated: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.domainList}
          columns={columns(
            this.props.update,
            this.props.deleteDomain,
            this.props.handleDomain,
            this.props.setDefaultDomain,
            this.props.currentUser,
            this.props.defaultDomain,
            this.props.currentDomainID,
            this.props.handleSort,
            this.props.sortOrder,
            this.props.roles,
            this.props.handleDomainAction,
            this.state.hiddenColumns
          )}
          columnToggle
        >
          {props => (
            <div>
              <DomainHeader
                currentDomainName={this.props.currentDomainName}
                rolesDH={this.props.rolesDH}
                permissionDH={this.props.permissionDH}
                handleDomainAction={this.props.handleDomainAction}
                handleSearchValue={this.props.handleSearchValue}
                // following props are needed for visibible col-table toggler
                hiddenColumns={this.state.hiddenColumns}
                toggleHiddenColumns={this.toggleHiddenColumns}
                columnToggleProps={{ ...props.columnToggleProps }}
                {...this.props}
              />
              <BootstrapTable
                classes="contentSectionTable domainTable"
                hover
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      width: "1412px",
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#e44b32",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                keyField="id"
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default DomainTable;
