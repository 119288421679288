import moment from "moment";
import { convertToInventoryEvent } from "../modules/singleEventCreate";
import { allEventsViewDataGenerator } from "../modules/rrules";

export function buildInventory(calendarDetails, startDate, endDate) {
  const allEvents = Object.values(calendarDetails.events).map(i => {
    // Structure Calendar Events to calculate Recursive Dates for Repeated Events
    return convertToInventoryEvent({
      serverID: i.id,
      eventName: i.attributes.eventName,
      endTime: i.attributes.endTime,
      startTime: i.attributes.startTime,
      repeat: i.attributes.repeat,
      excludedDates: i.attributes.excludedDates,
      repeatFreq: i.attributes.repeatFreq
    });
  });
  // Get Recursive Dates and Filter Calendar Events starting from today
  const eventsStartingFromToday = allEventsViewDataGenerator(allEvents).filter(
    i => {
      return (
        moment(i.start).isSameOrAfter(moment().startOf("day")) &&
        moment(i.start).isSameOrBefore(endDate, "day") &&
        moment(i.start).isSameOrAfter(startDate, "day")
      );
    }
  );

  // Get Inventory Sections from Domain
  const inventorySections = JSON.parse(window.localStorage.getItem("domain"))
    .inventory_sections;
  // Initialize objects to compute/store Inventory Data
  let inventoryData = {};
  let eventTimeline = {};
  let screenTimeline = {};

  // Iterate over Calendar Events
  Object.values(eventsStartingFromToday).map(event => {
    const serverEvent = calendarDetails.events[event.parent.serverID];
    var eventDate = moment(event.start).format("YYYY-MM-DD");
    const eventStartTime = moment(event.start).format("HH:mm");
    const eventEndTime = moment(event.end).format("HH:mm");

    if (typeof inventoryData[eventDate] === "undefined") {
      Object.assign(inventoryData, { [eventDate]: { sections: {} } });

      // Initialize Inventory Sections
      Object.entries(inventorySections).map(([sectionName, section]) => {
        Object.assign(
          inventoryData[eventDate]["sections"],
          {
            [sectionName]: {
              contentDuration: 0,
              freeSlotDuration: 0,
              startTime: moment.utc(section.start_time).format("HH:mm"),
              endTime: moment.utc(section.end_time).format("HH:mm"),
              events: {}
            }
          },
          inventoryData[eventDate]["sections"]
            ? { ...inventoryData[eventDate]["sections"] }
            : {}
        );
      });
    }

    // Iterate over Inventory Sections
    Object.entries(inventorySections).map(([sectionName, section]) => {
      const sectionStartTime = moment.utc(section.start_time).format("HH:mm");
      const sectionEndTime = moment.utc(section.end_time).format("HH:mm");
      const sectionArr = inventoryData[eventDate]["sections"][sectionName];

      // Check if Calendar Events falls under Inventory Section's Time Range
      if (
        eventStartTime >= sectionStartTime &&
        eventEndTime <= sectionEndTime
      ) {
        const campaign =
          calendarDetails.campaigns[serverEvent.relationships.campaign.data.id];

        // Initialize Section Event
        Object.assign(
          sectionArr["events"],
          {
            [serverEvent.id]: {
              name: serverEvent.attributes.eventName,
              freeSlotDuration: 0,
              screens: {}
            }
          },
          sectionArr["events"] ? { ...sectionArr["events"] } : {}
        );

        // Initialize Content Timeline for Event if it doesn't exist
        if (typeof eventTimeline[serverEvent.id] === "undefined") {
          eventTimeline[serverEvent.id] = 0;
        }

        // Initialize Event ID for Screen Timeline
        screenTimeline[serverEvent.id] = {};

        // Iterate over Campaign Screens
        return campaign.relationships.screens.data.length
          ? Object.values(campaign.relationships.screens.data).map(
              (screenAttr, screenIndex) => {
                // Get Campaign's Screen instance
                const screen = calendarDetails.screens[screenAttr.id];

                // Initialize Campaign Screen Free Slots
                Object.assign(sectionArr["events"][serverEvent.id]["screens"], {
                  [screenIndex]: {
                    name: screen.attributes.name,
                    freeSlots: {}
                  }
                });

                // Iterate over Campaign Screen Contents
                return screen.relationships.campaignContents.data.length
                  ? Object.values(
                      screen.relationships.campaignContents.data
                    ).map(campaignAttr => {
                      const campaignContent =
                        calendarDetails.campaignContents[campaignAttr.id];

                      // Get Content Duration
                      const contentDuration =
                        campaignContent.attributes.usePlaylistDuration &&
                        campaignContent.relationships.playlist.data
                          ? calendarDetails.playlists[
                              campaignContent.relationships.playlist.data.id
                            ].attributes.avgPossibleDuration
                          : campaignContent.attributes.duration;

                      // Get Filler Content from Playlist if one exists
                      const fillerContent = getPlaylistFillerContent(
                        calendarDetails,
                        campaignContent
                      );

                      // Increment Content Duration for each Campaign Screen
                      sectionArr["contentDuration"] += contentDuration;

                      // Initialize Content Timeline for Event's Screen if it doesn't exist
                      if (
                        typeof screenTimeline[serverEvent.id][screenIndex] ===
                        "undefined"
                      ) {
                        Object.assign(screenTimeline[serverEvent.id], {
                          [screenIndex]: 0
                        });
                      }

                      // Get Campaign's Content instance
                      if (
                        fillerContent ||
                        campaignContent.relationships.content.data
                      ) {
                        const content = fillerContent
                          ? fillerContent
                          : calendarDetails.contents[
                              campaignContent.relationships.content.data.id
                            ];

                        // Extract Free Slot information from filler contents
                        if (
                          fillerContent || content.attributes.tags
                            ? content.attributes.isFillerContent
                            : false
                        ) {
                          sectionArr["freeSlotDuration"] += contentDuration;

                          // Increment Free Slot Duration for each Events
                          sectionArr["events"][serverEvent.id][
                            "freeSlotDuration"
                          ] += contentDuration;

                          // Calculate Free Slot Start Time
                          const freeSlotStartTime = moment(
                            eventStartTime,
                            "HH:mm:ss"
                          )
                            .add(
                              screenTimeline[serverEvent.id][screenIndex],
                              "seconds"
                            )
                            .format("HH:mm:ss");

                          // Calculate Free Slot End Time
                          const freeSlotEndTime = moment(
                            freeSlotStartTime,
                            "HH:mm:ss"
                          )
                            .add(contentDuration, "seconds")
                            .format("HH:mm:ss");

                          // Alias constant for Screen Array
                          const screenArr =
                            sectionArr["events"][serverEvent.id]["screens"][
                              screenIndex
                            ];

                          // Assign Free Slot Range/Duration
                          Object.assign(screenArr["freeSlots"], {
                            [content.playlistName ||
                            content.attributes.fileName]: [
                              {
                                range: `${freeSlotStartTime} - ${freeSlotEndTime}`,
                                duration: contentDuration
                              },
                              screenArr["freeSlots"][
                                content.attributes.fileName
                              ]
                                ? [
                                    ...screenArr["freeSlots"][
                                      content.attributes.fileName
                                    ]
                                  ]
                                : []
                            ].flat()
                          });
                        }
                      }
                      // Update/increment current Event's Timeline
                      eventTimeline[serverEvent.id] += contentDuration;
                      screenTimeline[serverEvent.id][
                        screenIndex
                      ] += contentDuration;
                    })
                  : {};
              }
            )
          : {};
      }
    });
  });

  // Calculate Free Slot Percentage for each Inventory Section
  Object.values(inventoryData).map(inventory => {
    Object.entries(inventorySections).map(([sectionName, section]) => {
      const sectionArr = inventory["sections"][sectionName];

      return (sectionArr["freeSlotPercentage"] = sectionArr["freeSlotDuration"]
        ? Math.round(
            (sectionArr["freeSlotDuration"] / sectionArr["contentDuration"]) *
              100
          )
        : 0);
    });
  });

  return inventoryData;
}

// Return Filler Content if one exists in Campaign's Playlist
function getPlaylistFillerContent(calendarDetails, campaignContent) {
  let fillerContent = undefined;
  if (
    campaignContent.attributes.usePlaylistDuration &&
    campaignContent.relationships.playlist.data
  ) {
    // Playlist Instance
    const playlist =
      calendarDetails.playlists[campaignContent.relationships.playlist.data.id];

    const playlistContents = playlist.relationships.playlistContents.data;

    // Iterate through Playlist's Contents
    Object.values(playlistContents).map(playlistContent => {
      const playlistContentModel =
        calendarDetails.playlistContents[playlistContent.id];

      // Content Instance
      const content =
        calendarDetails.contents[
          playlistContentModel.relationships.content.data.id
        ];

      // Check if Content Type is Filler
      if (
        content.attributes.tags ? content.attributes.isFillerContent : false
      ) {
        // Set Filler Content if not set
        if (typeof fillerContent === "undefined") {
          fillerContent = content;
          fillerContent.playlistName = playlist.attributes.name;
        }
      }
    });
  }
  return fillerContent;
}
