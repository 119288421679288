/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { debounce } from "lodash";
import { Button } from "reactstrap";
import Select from "react-select";
import { MultipleSelect } from "../../../../core/form/searchSelect";
import {
  Resources,
  ContentTagResources,
  SponserTagResources,
  PartyTagListResources,
  BrandTagListResources
} from "../../../contents/redux";
import { Resources as PlaylistResources } from "../../../playlist/redux";

export default class TabSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTag: [],
      MediaSearchValue: "",
      PlaylistSearchValue: "",
      showClearButtonMedia: false,
      showClearButtonPlaylist: false,
      searchVal: { value: "", url: "" },
      selectedOption: "Tags",
      showSearchOptions: false
    };
    if (this.props.campaignLoading) {
      this.loadTags();
    }
    this.searchByTagRef = React.createRef();
    this.loadSponserTag();
    this.loadPartyTag();
    this.loadBrandTag();
  }

  loadTags = async () => {
    const { getContent } = ContentTagResources;
    await this.props.Get(getContent);
  };

  loadSponserTag = async () => {
    const { getContent } = SponserTagResources;
    await this.props.Get(getContent);
  };
  loadPartyTag = async () => {
    const { getContent } = PartyTagListResources;
    await this.props.Get(getContent);
  };
  loadBrandTag = async () => {
    const { getContent } = BrandTagListResources;
    await this.props.Get(getContent);
  };
  handleRemove = tag => {
    const { searchTag } = this.state;
    const { activeTab } = this.props;
    searchTag.splice(tag, 1);
    if (searchTag.length === 0) {
      this.handleSearchValue("", activeTab, true);
      this.setState({ showClearButtonMedia: false });
      this.props.toggleFetch(true);
    } else {
      this.setState({ searchTag }, () => {
        this.handleSearchValue(
          searchTag.toString(),
          activeTab,
          true,
          this.state.selectedOption
        );
      });
    }
    this.searchByTagRef.current.state.value = "";
  };

  handleTagChange = newTag => {
    this.handleRemove(newTag)
    const { activeTab } = this.props;
    this.state.searchVal !== "" &&
      this.setState({ [`${activeTab}SearchValue`]: "" });
    if (newTag) {
      const { searchTag } = this.state;
      if (!searchTag.includes(newTag.value.toString())) {
        searchTag.push(newTag.value.toString());
        this.setState(
          () => ({ searchTag, showClearButtonMedia: true }),
          () =>
            this.handleSearchValue(
              this.state.searchTag.toString(),
              activeTab,
              true
            )
        );
        this.props.inputSearchTag(
          this.state.searchTag.toString(),
          this.state.selectedOption
        );
      }
    }
  };

  handleSelectedOption = () => {
    this.setState(state => ({ showSearchOptions: !state.showSearchOptions }));
  };

  handleChangeOptions = type => {
    this.setState({ selectedOption: type, showSearchOptions: false });
    if (this.state.searchTag.length > 0) {
      this.handleSearchValue(this.state.searchTag.toString(), "Media", true);
    }
  };

  handleSearchValue = (value, activeTab, url = false) => {
    this.props.setLoading(true);
    if (activeTab === "Media") {
      if (!url) {
        this.searchByTagRef.current.state.value = "";
        this.state.searchTag.length > 0 && this.setState({ searchTag: [] });
      }
      // this.props.inputSearchName(value);
      this.setState(
        { contentSearchVal: { value, url }, showClearButtonMedia: true },
        () => {
          this.handleContentSearch();
        }
      );
    } else if (activeTab === "Playlist") {
      this.setState({
        PlaylistSearchValue: value,
        showClearButtonPlaylist: true
      });
      this.handlePlaylistSearch();
    }
  };

  handleContentSearch = debounce(() => {
    const { getContent } = Resources;
    if (this.state.contentSearchVal.value === "") {
      getContent.url = "/contents?page=1&per_page=18";
      this.setState({
        showClearButtonMedia: false
      });
    } else {
      if (!this.state.contentSearchVal.url) {
        getContent.url = `/contents/search?search=${this.state.contentSearchVal.value}&page=1&per_page=18`;

        this.props.Get(Resources.getContent).then(response => {
          this.props.setLoading(false);
          if (response.data.meta.totalPages <= response.data.meta.page) {
            this.props.toggleFetch(false);
          }
        });
        return;
      }
      if (this.state.selectedOption === "Sponsors") {
        getContent.url = `/contents/search_sponsor_tag?sponsor_tags=${this.state.contentSearchVal.value}&page=1&per_page=18`;
      } else if (this.state.selectedOption === "Party") {
        getContent.url = `/contents/search_by_party?party_tags=${this.state.contentSearchVal.value}&page=1&per_page=18`;
      } else if (this.state.selectedOption === "Brand") {
        getContent.url = `/contents/search_by_brand?brand_tags=${this.state.contentSearchVal.value}&page=1&per_page=18`;
      } else {
        getContent.url = `/contents/search_tag?tags=${this.state.contentSearchVal.value}&page=1&per_page=18`;
      }
    }
    this.props.Get(Resources.getContent).then(response => {
      this.props.setLoading(false);
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.props.toggleFetch(false);
      }
    });
  }, 3000);

  handlePlaylistSearch = debounce(() => {
    const { getPlaylist } = PlaylistResources;
    if (this.state.PlaylistSearchValue === "") {
      getPlaylist.url = "/playlists?page=1&per_page=18";
      this.setState({
        showClearButtonPlaylist: false
      });
    } else {
      getPlaylist.url = `/playlists/search?search=${this.state.PlaylistSearchValue}&page=1&per_page=18`;
    }
    this.props.Get(PlaylistResources.getPlaylist).then(response => {
      this.props.setLoading(false);
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.props.toggleFetch(false);
      }
    });
  }, 3000);

  handleClear = () => {
    this.props.toggleFetch(true);
    this.handleSearchValue("", this.props.activeTab);
    if (this.props.activeTab === "Media") {
      this.searchByTagRef.current.state.value = "";
      this.setState({
        showClearButtonMedia: false,
        MediaSearchValue: "",
        searchTag: []
      });
    } else if (this.props.activeTab === "Playlist") {
      this.setState({
        showClearButtonPlaylist: false,
        PlaylistSearchValue: ""
      });
    }
  };

  handleSponsorChange = e => {
    if (e.value === "tag") {
      this.handleChangeOptions("Tags");
    }
    if (e.value === "sponsor") {
      this.handleChangeOptions("Sponsors");
    }
    if (e.value === "party") {
      this.handleChangeOptions("Party");
    }
    if (e.value === "brand") {
      this.handleChangeOptions("Brand");
    }
  };

  render() {
    const { activeTab } = this.props;
    const { selectedOption, showSearchOptions } = this.state;
    const searchTagOptions =
      this.state.selectedOption === "Sponsors"
        ? this.props.SponserTag
        : this.state.selectedOption === "Tags"
        ? this.props.ContentTag
        : this.state.selectedOption === "Party"
        ? this.props.PartyTagList
        : this.props.BrandTagList;
    return (
      <div className="row campaignStudioWrapper__search">
        {activeTab !== "Widget" && (
          <div className="col-md-6 p-1 search w-">
            <span
              className="bx bx-search bx-sm search__searchIcon"
              width="100%"
            />
            <input
              type="text"
              className="search__input search__input--campaignEditor 100"
              placeholder="Search by name"
              onChange={e => {
                // if (e.keyCode === 13) {
                this.setState({
                  [`${activeTab}SearchValue`]: e.target.value
                });
                this.props.inputSearchName(e.target.value);
                this.handleSearchValue(e.target.value, activeTab);
                // }
              }}
              value={this.state[`${activeTab}SearchValue`]}
              // onChange={e => this.setState({ [`${activeTab}SearchValue`]: e.target.value })}
            />
          </div>
        )}
        {activeTab === "Media" && (
          <div className="d-flex col-md-6 p-1 search w-100">
            {/* <Button
              role="button"
              className={`select_box__button searchTypeSelect ${showSearchOptions &&
                "searchTypeSelect--active"}`}
              onClick={() => this.handleSelectedOption()}
            >
              {selectedOption} <i className="fas fa-chevron-down" />
            </Button> */}
            <div className="sponsor-tag" style={{ width: "30%" }}>
              <Select
                isSearchable={false}
                required
                onChange={e => this.handleSponsorChange(e)}
                defaultValue={[{ label: "Tags", value: "tag" }]}
                options={
                  !this.props.featureAllowed
                    ? ([
                        { label: "Tags", value: "tag" },
                        { label: "Sponsors", value: "sponsor" }
                      ])
                    : ([
                        { label: "Tags", value: "tag" },
                        { label: "Sponsors", value: "sponsor" },
                        { label: "Party", value: "party" },
                        { label: "Brand", value: "brand" }
                      ])
                }
              />
            </div>
            <div style={{ width: "80%" }}>
              <React.Fragment>
                <span className="bx bx-search bx-sm search__searchIcon" />
                <MultipleSelect
                  forwardRef={this.searchByTagRef}
                  style={{ width: "47%" }}
                  searchable
                  onChange={this.handleTagChange}
                  placeholder={
                    this.state.selectedOption === "Tags"
                      ? "Search by Tag"
                      : this.state.selectedOption === "Sponsors"
                      ? "Search by Sponsor"
                      : this.state.selectedOption === "Party"
                      ? "Search by Party"
                      : "Search by Brand"
                  }
                  isClearable
                  value=""
                  options={
                    searchTagOptions.map(item => ({
                      value: item,
                      label: item
                    })) || []
                  }
                />
              </React.Fragment>
            </div>
          </div>
        )}

        <div className="pr-2 pl-2" style={{ width: "100%" }}>
          {this.state.searchTag.map((i, index) => (
            <div className="contentTag__wrapper">
              <span
                className={
                  this.state.selectedOption === "Sponsors"
                    ? "contentTag__badge contentTag__badge--mod"
                    : "contentTag__badge"
                }
                onClick={() => this.handleRemove(index)}
              >
                {`${i}  `}
                <i className="fa fa-times-circle" />
                {/* <span className="fa fa-times-circle"  /> */}
              </span>
            </div>
          ))}
          {this.state[`showClearButton${activeTab}`] && (
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus
            <span
              role="button"
              onClick={this.handleClear}
              className="float-right clearField"
            >
              Clear
            </span>
          )}
        </div>
      </div>
    );
  }
}
