export const updateHeaderNotification = (data) => {
    return (dispatch, getState) => {
        let { list } = getState().headerNotificationList;
        if (list) {
            list.splice(0, 0, data) //add new notification to beginning of headerNotificationList
        }
        const state = { ...getState().headerNotificationList, list }
        dispatch({
            type: "updateHeaderNotificationList",
            payload: state
        })
    }
}

export const Resources = {
    getBrandNotifications: {
        url: "/",
        asyncActions: {
            init: "getBrandNotification_INIT",
            success: "getBrandNotification_SUCCESS",
            error: "getBrandNotification_ERROR",
        }
    },
    getAllBrandNotifications: {
        url: "/",
        asyncActions: {
            init: "getAllBrandNotification_INIT",
            success: "getAllBrandNotification_SUCCESS",
            error: "getAllBrandNotification_ERROR",
        }
    },
    postIsChecked: {
        url: "/checked",
        asyncActions: {
            init: "postIsChecked_INIT",
            success: "postIsChecked_SUCCESS",
            error: "postIsChecked_ERROR",
        }
    }
}

const initialState = {
    list: [],
    links: undefined,
    status: undefined,
    statusText: undefined,
    meta: undefined,
    loading: true,
    hasError: undefined
}

export function headerNotificationList(state = initialState, action = {}) {
    const { init, success, error } = Resources.getBrandNotifications.asyncActions;

    switch (action.type) {
        case init: {
            return {
                ...state,
                loading: true,
                hasError: undefined
            };
        }

        case success: {
            const {
                data: { data, meta },
                status,
                statusText
            } = action.payload;
            return {
                list: data,
                meta,
                status,
                statusText,
                loading: false,
                hasError: false
            };
        }

        case error: {
            return {
                ...state,
                loading: false,
                hasError: true
            };
        }

        case "updateHeaderNotificationList": {
            return {
                ...action.payload
            }
        }

        default: {
            return state;
        }
    }
}


export function allBrandNotificationList(state = initialState, action = {}) {
    const { init, success, error } = Resources.getAllBrandNotifications.asyncActions;

    switch (action.type) {
        case init: {
            return {
                ...state,
                loading: true,
                hasError: undefined
            };
        }

        case success: {
            const {
                data: { data, meta },
                status,
                statusText
            } = action.payload;
            return {
                list: data,
                meta,
                status,
                statusText,
                loading: false,
                hasError: false
            };
        }

        case error: {
            return {
                ...state,
                loading: false,
                hasError: true
            };
        }

        default: {
            return state;
        }
    }
}


