import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  AddPlaylistStudio,
  PlaylistContentSelector,
  PlaylistFormHeader
} from "../modules";

import { Resources as ContentResources } from "../../contents/redux";
import {
  convertToServerData,
  generateCreatePlaylistData,
  Resources,
  convertToLocalEvent
} from "../redux";
import { showNotification } from "../../../core/modal/toster";
import {
  playlistByWeightRestrictions,
  formValidator
} from "../forms/playlistFormValidator";
import DesktopBreakpoint from "../../../context/desktop_breakpoint";
import PhoneBreakpoint from "../../../context/phone_breakpoint";
import AddPlaylistMobileView from "./addPlaylistMobileView";

class AddPlaylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropZone: false,
      playlistTitle: "",
      playlistControl: {
        selectAll: false,
        restriction: false,
        date: false,
        weight: false
      },
      isSubmitable: true,
      random: false,
      popOne: true,
      isLoading: true,
      isPlaylistLoading: true
    };
    this.loadContent();
  }

  componentDidMount() {
    this.getPlaylist();
  }

  loadContent = () => {
    ContentResources.getContent.url = "/contents";
    this.props
      .Get(ContentResources.getContent)
      .then(() => this.setLoading(false));
  };

  setLoading = flag => {
    this.setState({ isLoading: flag });
  };

  getPlaylist = async () => {
    const { params } = this.props.match;
    if (params.id) {
      const { showPlaylist } = Resources;
      showPlaylist.url = `/playlists/${params.id}`;
      const result = await this.props.Get(showPlaylist);
      if (result.status === 200) {
        const playlistData = convertToLocalEvent(this.props.playlistEditData);
        const { attributes } = result.data.data;
        this.setState({
          playlistControl: JSON.parse(attributes.playlist_controls),
          playlistTitle: attributes.name,
          random: attributes.random,
          popOne: attributes.pop_one,
          isPlaylistLoading: false
        });
        this.props.change("playListData", playlistData);
      } else {
        this.props.history.push("/playlist");
      }
    }
  };

  setIsSubmtable = flag => {
    this.setState({
      isSubmitable: flag
    });
  };

  showDropZone = showDropZone => {
    this.setState({ showDropZone });
  };

  playlistTitleChange = playlistTitle => {
    this.setState({ playlistTitle });
  };

  playlistControlChange = playlistControl => {
    this.setState({ playlistControl });
  };

  playlistAttributeChange = (name, val) => {
    this.setState({ [name]: val });
  };

  getSelectAllValue = (value, name) => {
    if (name === "restriction") {
      return (
        this.state.playlistControl.date &&
        this.state.playlistControl.weight &&
        value
      );
    }
    if (name === "date") {
      return (
        this.state.playlistControl.restriction &&
        this.state.playlistControl.weight &&
        value
      );
    }
    return (
      this.state.playlistControl.date &&
      this.state.playlistControl.restriction &&
      value
    );
  };

  handleControl = (value, name) => {
    if (name === "selectAll") {
      const playlistControl = {
        [name]: value,
        restriction: value,
        date: value,
        weight: value
      };
      // TODO: if all of the playlist controls are un-checked, then uncheck the selectAll button
      this.playlistControlChange(playlistControl);
      return 0;
    }
    const playlistControl = {
      ...this.state.playlistControl,
      [name]: value,
      selectAll: this.getSelectAllValue(value, name)
    };
    this.playlistControlChange(playlistControl);
  };

  handlePlaylistSave = async (values, sync = undefined) => {
    const { isSubmitable } = this.state;
    if (
      this.state.playlistControl.weight &&
      this.props.playListData.length > 0
    ) {
      const checkValidation = playlistByWeightRestrictions(
        this.props.playListData
      );
      if (
        isSubmitable &&
        (!checkValidation.startvalidated ||
          !checkValidation.endvalidated ||
          !checkValidation.startHourValidated ||
          !checkValidation.endHourValidated)
      ) {
        this.props.showNotification(
          "The contents should be played on same date and time.",
          "danger"
        );
        return false;
      }
      if (isSubmitable && !checkValidation.weight) {
        this.props.showNotification(
          "Total Share of Voice must be equal to 100",
          "danger"
        );
        return false;
      }
    }
    const { params } = this.props.match;
    // using this.props.playlistData check the validation once again
    this.props.playListData.forEach(row => {
      const requiredFields = {
        durationMinute: row.durationMinute,
        durationSecond: row.durationSecond,
        endDate: row.endDate,
        startDate: row.startDate,
        restrictEndHour: row.restrictEndHour,
        restrictStartHour: row.restrictStartHour,
        weight: row.weight
      };
      Object.keys(requiredFields).forEach(field => {
        if (requiredFields[field]) {
          const result = formValidator(
            requiredFields[field],
            field,
            requiredFields
          );
          this.setIsSubmtable(!result.error);
        }
      });
    });
    // then continue normally
    if (isSubmitable) {
      const playlistContents = convertToServerData(this.props.playListData);
      const { putPlaylist } = Resources;
      putPlaylist.url = `/playlists/${params.id}`;
      putPlaylist.body = generateCreatePlaylistData(
        playlistContents,
        this.state,
        params.id
      );
      if (sync === "WithSync") {
        // eslint-disable-next-line no-underscore-dangle
        putPlaylist.body._jsonapi.data.sync = true;
      }
      const result = await this.props.Put(putPlaylist);
      if (result.status === 200) {
        this.props.history.push("/playlist");
      }
    } else {
      this.props.showNotification(
        "Playlist Content form must be inserted correctly.",
        "danger"
      );
      return 0;
    }
  };

  render() {
    if (this.state.isPlaylistLoading) {
      return (
        <svg className="spinner" viewBox="0 0 50 50">
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
          />
        </svg>
      );
    }
    return (
      <div className="mainPage">
        <div className="contentSection campaignStudioWrapper">
          <DesktopBreakpoint>
            <PlaylistFormHeader
              playlistTitleChange={this.playlistTitleChange}
              playlistTitle={this.state.playlistTitle}
              handlePlaylistSave={this.handlePlaylistSave}
              updateText="Update "
              updateAndSyncText="Update and Sync "
            />
            <Field component="input" hidden type="text" name="playListData" />
            <Field
              component="input"
              hidden
              type="text"
              name="selectedContent"
            />
            <Row>
              <Col md="4" className="pr-0 ple_custom_height">
                <Card body>
                  {/* Playlist content selector i.e(Draggable zone) starts here */}
                  <PlaylistContentSelector
                    {...this.props}
                    showDropZoneFunc={this.showDropZone}
                    showDropZone={this.state.showDropZone}
                    isLoading={this.state.isLoading}
                    setLoading={this.setLoading}
                  />
                </Card>
              </Col>
              <AddPlaylistStudio
                list={this.props.list}
                showDropZone={this.state.showDropZone}
                playListData={this.props.playListData}
                showDropZoneFunc={this.showDropZone}
                playlistAttributeChange={this.playlistAttributeChange}
                selectedContent={this.props.selectedContent}
                playlistControlChange={this.playlistControlChange}
                playlistControl={this.state.playlistControl}
                {...this.state}
                setIsSubmtable={this.setIsSubmtable}
                handleControl={this.handleControl}
              />
            </Row>
          </DesktopBreakpoint>
          <PhoneBreakpoint>
            <PlaylistFormHeader
              playlistTitleChange={this.playlistTitleChange}
              playlistTitle={this.state.playlistTitle}
              handlePlaylistSave={this.handlePlaylistSave}
              updateText="Update"
              updateAndSyncText="Update and Sync"
            />
            <Field component="input" hidden type="text" name="playListData" />
            <Field
              component="input"
              hidden
              type="text"
              name="selectedContent"
            />
            <Row>
              <AddPlaylistMobileView
                isPlaylistLoading={this.state.isPlaylistLoading}
                isLoading={this.state.isLoading}
                setLoading={this.setLoading}
                list={this.props.list}
                playListData={this.props.playListData}
                playlistAttributeChange={this.playlistAttributeChange}
                selectedContent={this.props.selectedContent}
                playlistControlChange={this.playlistControlChange}
                playlistControl={this.state.playlistControl}
                {...this.state}
                {...this.props}
                setIsSubmtable={this.setIsSubmtable}
                handleDragStart={this.handleDragStart}
                handleControl={this.handleControl}
              />
            </Row>
          </PhoneBreakpoint>
          {/* Right part of Add Playlist i.e.(Droppable Zone) starts here */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("addPlaylistForm");
  const { list } = state.content;
  const playlistEditData = state.playlistDetails.list;

  return {
    list: list || [],
    selectedContent: selector(state, "selectedContent") || [],
    playListData: selector(state, "playListData") || [],
    playlistEditData,
    rateAmountRestriction: JSON.parse(
      window.localStorage.getItem("domain") !== "undefined"
        ? window.localStorage.getItem("domain")
        : null
    ).is_content_rate_enabled,
    featureAllowed: JSON.parse(window.localStorage.getItem("domain"))
      .is_party_brand_feature_enabled
  };
}

const addPlaylistForm = reduxForm({
  form: "addPlaylistForm"
})(AddPlaylist);

export default connect(
  mapStateToProps,
  { showNotification }
)(addPlaylistForm);
