import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { Dropdown, Button } from "reactstrap";
import { MultipleSelect } from "./searchSelect";

export default class SearchTagRefresh extends Component {
  static defaultProps = {
    handleSearchChange: () => {}
  };

  static propTypes = {
    handleSearchChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      searchVal: ""
    };
    this.searchByText = React.createRef();
  }

  clearSearchByName = () => {
    this.setState({ searchVal: "" });
    this.searchByText.current.value = "";
  };
  handleRemoveSearchTag = tag => {
    const { searchTag } = this.props;
    searchTag.splice(tag, 1);
    if (searchTag.length === 0) {
      this.props.handleSearch("", true);
    } else {
      this.setState({ searchTag }, () => {
        this.props.handleSearchTag(searchTag);
        this.props.handleSearch(
          searchTag.toString(),
          true,
          this.state.selectedOption
        );
      });
    }
    this.props.forwardRef.current.state.value = "";
  };
  handleChange = newTag => {
    this.handleRemoveSearchTag(newTag)
    this.searchByText.current.value = "";
    // this.props.clearSearchTag();
    this.state.searchVal !== "" && this.setState({ searchVal: "" });
    newTag && this.props.handleSearchChange(newTag.value.toString());
  };

  handleSponsorChange = e => {
    if (e.value === "tag") {
      this.props.handleChangeOptions("Tags");
    }
    if (e.value === "sponsor") {
      this.props.handleChangeOptions("Sponsors");
    }
    if (e.value === "party") {
      this.props.handleChangeOptions("Party");
    }
    if (e.value === "brand") {
      this.props.handleChangeOptions("Brand");
    }
  };

  // handleChangeOptions = type => {
  //   console.error("this.state.searchVal", this.state.searchVal);
  //   this.setState({ searchVal: "" });
  //   this.props.handleChangeOptions(type);
  // };

  render() {
    const { selectedOption, showSearchOptions } = this.props;
    return (
      <div className="d-flex flex-row">
        <div className="contentHeader__search search">
          <span
            className="bx bx-search bx-sm search__searchIcon"
            width="100%"
          />
          <input
            type="text"
            ref={this.searchByText}
            // onKeyUp={e => {
            //   if (e.keyCode === 13) {
            //     // eslint-disable-next-line no-unused-expressions
            //     this.props.searchTab
            //       ? this.props.handleChange
            //       : this.props.handleSearch(e.target.value);
            //   }
            // }}
            className="search__input"
            placeholder={
              this.props.placeholder ? this.props.placeholder : "Search by Name"
            }
            onChange={
              this.props.searchTab
                ? this.props.handleChange
                : e => {
                    this.setState({ searchVal: e.target.value });
                    this.props.handleSearch(e.target.value);
                  }
            }
            value={this.state.searchVal || this.props.searchVal}
          />
        </div>

        {!this.props.searchTab && this.props.match.path.search("content") > -1 && (
          <div className="d-flex contentHeader__search search ml-2">
            <div className="sponsor-tag">
              <Select
                isSearchable={false}
                required
                onChange={e => this.handleSponsorChange(e)}
                defaultValue={[{ label: "Tags", value: "tag" }]}
                options={
                  this.props.featureAllowed === true
                    ? ([
                        { label: "Tags", value: "tag" },
                        { label: "Sponsors", value: "sponsor" },
                        { label: "Party", value: "party" },
                        { label: "Brand", value: "brand" }
                      ])
                    : ([
                        { label: "Tags", value: "tag" },
                        { label: "Sponsors", value: "sponsor" }
                      ])
                }
              />
            </div>
            <div className="float-right">
              <span
                className="bx bx-search bx-sm search__searchIcon"
                width="100%"
              />
              <MultipleSelect
                forwardRef={this.props.forwardRef}
                searchable
                onChange={this.handleChange}
                placeholder={
                  this.props.featureAllowed === true
                    ? selectedOption === "Tags"
                      ? "Search by Tags"
                      : selectedOption === "Sponsors"
                      ? "Search by Sponsors"
                      : selectedOption === "Party"
                      ? "Search by Party"
                      : "Search by Brand"
                    : selectedOption === "Tags"
                    ? "Search by Tags"
                    : "Search by Sponsors"
                }
                isClearable={this.props.isClearable || false}
                value=""
                options={this.props.searchByTagOptions || []}
                clearSearchByName={this.clearSearchByName}
                {...this.props}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
