/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Tooltip } from "../../../core";

export const CustomTimeDisplay = props => (
  <div className="d-flex">
    <button
      type="button"
      className="btn-outline-primary today"
      onClick={() => props.onNavigate("TODAY")}
    >
      {" "}
      Today
    </button>
    <Tooltip
      item={
        <i
          role="presentation"
          className="bx bx-chevron-left navigation"
          onClick={() => props.onNavigate("PREV")}
        />
      }
      tooltipContent={`Previous ${props.view}`}
      id="previous-calendar-date"
    />
    <Tooltip
      item={
        <i
          role="presentation"
          className="bx bx-chevron-right navigation"
          onClick={() => props.onNavigate("NEXT")}
        />
      }
      tooltipContent={`Next ${props.view}`}
      id="next-calendar-date"
    />
    <span
      className={`date-label ${(props.action === "View" ||
        props.action === "") &&
        props.isCalendarListOpen &&
        "date-label--mod"}`}
    >
      {props.label}
    </span>{" "}
  </div>
);
