import React from "react";
import { Link } from "react-router-dom";
import { toLower, startCase } from "lodash";
import { Tooltip } from "../../../core";

export function columns(
  handleDelete,
  currentUser,
  handleSort,
  sortOrder,
  handleResendConfirmation,
  handleUserCreate,
  isSending,
  hiddenColumns
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      hidden: hiddenColumns.name,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      style: { width: "200px" }
    },
    {
      dataField: "attributes.email",
      text: "Email",
      columnName: "email",
      hidden: hiddenColumns.email,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("email")}
            className="sort-by "
            style={{
              color: sortOrder.email.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Email
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.email.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.roles",
      text: "Roles",
      columnName: "roles",
      hidden: hiddenColumns.roles,
      style: { width: "420px" },
      formatter: (val, row) =>
        row.attributes.roles.map(item => (
          <span key={item} className={`roles ${toLower(item)} `}>
            {startCase(item)}
          </span>
        ))
    },
    {
      dataField: "attributes.created_at",
      text: "Date Created",
      columnName: "date_created",
      hidden: hiddenColumns.date_created,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.created_at.sortBy === "desc" ? "up" : "down"
              }`}
            />{" "}
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      columnName: "date_updated",
      hidden: hiddenColumns.date_updated,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />{" "}
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.email_confirmed",
      text: "Confirmation",
      columnName: "confirmation",
      hidden: hiddenColumns.confirmation,
      formatter: (name, row) => (
        <div>
          <div>
            {row.attributes.email_confirmed ? "Confirmed" : "Not Confirmed"}
          </div>
          <div>
            {row.attributes.email_confirmed === false &&
              (isSending === row.id ? (
                <small style={{ color: "#6ed886", fontSize: "14px" }}>
                  Sending...
                </small>
              ) : (
                <button
                  id={row.id}
                  onClick={() => handleResendConfirmation(row.id)}
                  className="resendConfirmationButton"
                  type="submit"
                  style={{ fontSize: "14px" }}
                >
                  Resend
                </button>
              ))}
          </div>
        </div>
      )
    },

    {
      dataField: "actionButtons",
      text: "",
      style: { position: "relative" },
      columnName: "isSelected",
      formatter: (name, row) => (
        <div className="d-flex">
          {currentUser !== row.id && (
            <div className="contentSectionTable__actionButtonWrapper">
              <span
                // to={{
                //   pathname: `/dashboard/edituser/${row.id}`,
                //   params: { ...row }
                // }}
                className="contentSectionTable__actionButtonWrapper__buttons"
              >
                <Tooltip
                  item={
                    <i
                      className="fa fa-edit"
                      onClick={() => handleUserCreate(true, "edit", row)}
                    />
                  }
                  id={`edit-${row.id}`}
                  tooltipContent="Edit"
                />
              </span>
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={() => handleDelete(row.id, row.attributes.name)}
                      className="fa fa-trash"
                    />
                  }
                  id={`delete-${row.id}`}
                  tooltipContent="Delete"
                />
              </span>
            </div>
          )}
        </div>
      )
    }
  ];
}
