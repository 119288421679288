/* eslint-disable no-undef */
export function screenGenerator(
  width,
  height,
  orientation,
  screenCount,
  defaultTemplate,
  defaultScreenResolution,
  horizontalScale
) {
  const isLandscape = () => orientation === "Landscape";

  if (typeof width !== "number") {
    throw Error(
      `Width of screen is expected to be Number but received ${typeof width}`
    );
  } else if (typeof height !== "number") {
    throw Error(
      `Width of screen is expected to be Number but received ${typeof height}`
    );
  } else if (typeof screenCount !== "number") {
    throw Error(
      `Screen Count expected to be Number but received ${typeof screenCount}`
    );
  } else if (orientation !== "Landscape" && orientation !== "Portrait") {
    throw Error(
      `Orientation must be either Landscape or Portrait but received ${orientation}`
    );
  } else {
    switch (screenCount) {
      case 1: {
        return [
          {
            x: 0,
            y: 0,
            width,
            height,
            sourceImage: `/images/screenColor/red.png`,
            defaultImageContentType: "image",
            fill: "#e97451"
          }
        ];
      }

      case 2: {
        return [
          {
            x: defaultTemplate
              ? defaultScreenResolution.screen1.x / horizontalScale
              : 0,
            y: defaultTemplate
              ? defaultScreenResolution.screen1.y / horizontalScale
              : 0,
            width: defaultTemplate
              ? defaultScreenResolution.screen1.width / horizontalScale
              : !isLandscape
              ? width / 2
              : width,
            height: defaultTemplate
              ? defaultScreenResolution.screen1.height / horizontalScale
              : isLandscape
              ? height / 2
              : height,
            sourceImage: `/images/screenColor/red.png`,
            defaultImageContentType: "image",

            fill: "#e97451"
          },

          {
            x: defaultTemplate
              ? defaultScreenResolution.screen2.x / horizontalScale
              : !isLandscape
              ? width / 2
              : 0,
            y: defaultTemplate
              ? defaultScreenResolution.screen2.y / horizontalScale
              : isLandscape
              ? height / 2
              : 0,
            width: defaultTemplate
              ? defaultScreenResolution.screen2.width / horizontalScale
              : !isLandscape
              ? width / 2
              : width,
            height: defaultTemplate
              ? defaultScreenResolution.screen2.height / horizontalScale
              : isLandscape
              ? height / 2
              : height,
            sourceImage: `/images/screenColor/green.png`,
            defaultImageContentType: "image",

            fill: "#94ec98"
          }
        ];
      }

      case 3: {
        return [
          {
            x: defaultTemplate
              ? defaultScreenResolution.screen1.x / horizontalScale
              : 0,
            y: defaultTemplate
              ? defaultScreenResolution.screen1.y / horizontalScale
              : 0,
            width: defaultTemplate
              ? defaultScreenResolution.screen1.width / horizontalScale
              : 0.6 * width,
            height: defaultTemplate
              ? defaultScreenResolution.screen1.height / horizontalScale
              : 0.6 * height,
            sourceImage: `/images/screenColor/red.png`,
            defaultImageContentType: "image",

            fill: "#e97451"
          },

          {
            x: defaultTemplate
              ? defaultScreenResolution.screen2.x / horizontalScale
              : 0.6 * width,
            y: defaultTemplate
              ? defaultScreenResolution.screen2.y / horizontalScale
              : 0,
            width: defaultTemplate
              ? defaultScreenResolution.screen2.width / horizontalScale
              : 0.6 * width,
            height: defaultTemplate
              ? defaultScreenResolution.screen2.height / horizontalScale
              : height,
            sourceImage: `/images/screenColor/green.png`,
            defaultImageContentType: "image",

            fill: "#94ec98"
          },

          {
            x: defaultTemplate
              ? defaultScreenResolution.screen3.x / horizontalScale
              : 0,
            y: defaultTemplate
              ? defaultScreenResolution.screen3.y / horizontalScale
              : 0.6 * height,
            width: defaultTemplate
              ? defaultScreenResolution.screen3.width / horizontalScale
              : 0.6 * width,
            height: defaultTemplate
              ? defaultScreenResolution.screen3.height / horizontalScale
              : 0.6 * height,
            sourceImage: `/images/screenColor/blue.jpg`,
            defaultImageContentType: "image",

            fill: "#0999fb"
          }
        ];
      }

      case 4: {
        return [
          {
            x: 0,
            y: 0,
            width: width / 2,
            height: height / 2,
            sourceImage: `/images/screenColor/red.png`,
            defaultImageContentType: "image",

            fill: "#e97451"
          },
          {
            x: width / 2,
            y: 0,
            width: width / 2,
            height: height / 2,
            sourceImage: `/images/screenColor/green.png`,
            defaultImageContentType: "image",

            fill: "#94ec98"
          },
          {
            x: 0,
            y: height / 2,
            width: width / 2,
            height: height / 2,
            sourceImage: `/images/screenColor/blue.jpg`,
            defaultImageContentType: "image",

            fill: "#0999fb"
          },
          {
            x: width / 2,
            y: height / 2,
            width: width / 2,
            height: height / 2,
            fill: "#783c7a",
            sourceImage: `/images/screenColor/purple.jpg`,
            defaultImageContentType: "image"
          }
        ];
      }
      case 5: {
        return [
          {
            x: 0,
            y: 0,
            width: 0.5 * width,
            height: 0.5 * height,
            sourceImage: `/images/screenColor/red.png`,
            defaultImageContentType: "image",

            fill: "#e97451"
          },
          {
            x: 0.5 * width,
            y: 0,
            width: width * 0.5,
            height: height * 0.3,
            sourceImage: `/images/screenColor/green.png`,
            defaultImageContentType: "image",

            fill: "#94ec98"
          },
          {
            x: 0,
            y: 0.5 * height,
            width: width * 0.5,
            height: height * 0.5,
            sourceImage: `/images/screenColor/blue.jpg`,
            defaultImageContentType: "image",

            fill: "#0999fb"
          },
          {
            x: 0.5 * width,
            y: 0.3 * height,
            width: width * 0.5,
            height: height * 0.3,
            fill: "#783c7a",
            sourceImage: `/images/screenColor/purple.jpg`,
            defaultImageContentType: "image"
          },
          {
            x: 0.5 * width,
            y: 0.6 * height,
            width: width * 0.5,
            height: height * 0.4,
            fill: "#d94f87",
            sourceImage: `/images/screenColor/pink.jpg`,
            defaultImageContentType: "image"
          }
        ];
      }

      case 6: {
        return [
          {
            x: 0,
            y: 0,
            width: 0.5 * width,
            height: 0.4 * height,
            sourceImage: `/images/screenColor/red.png`,
            defaultImageContentType: "image",

            fill: "#e97451"
          },
          {
            x: 0.5 * width,
            y: 0,
            width: width * 0.5,
            height: height * 0.3,
            sourceImage: `/images/screenColor/green.png`,
            defaultImageContentType: "image",

            fill: "#94ec98"
          },
          {
            x: 0,
            y: 0.4 * height,
            width: width * 0.5,
            height: height * 0.3,
            sourceImage: `/images/screenColor/blue.jpg`,
            defaultImageContentType: "image",

            fill: "#0999fb"
          },
          {
            x: 0.5 * width,
            y: 0.3 * height,
            width: width * 0.5,
            height: height * 0.3,
            fill: "#783c7a",
            sourceImage: `/images/screenColor/purple.jpg`,
            defaultImageContentType: "image"
          },
          {
            x: 0.5 * width,
            y: 0.6 * height,
            width: width * 0.5,
            height: height * 0.4,
            fill: "#d94f87",
            sourceImage: `/images/screenColor/pink.jpg`,
            defaultImageContentType: "image"
          },
          {
            x: 0,
            y: 0.7 * height,
            width: width * 0.5,
            height: height * 0.3,
            fill: "#8d471c",
            sourceImage: `/images/screenColor/brown.png`,
            defaultImageContentType: "image"
          }
        ];
      }

      default: {
        throw Error(`There cannot be more than 6 screen`);
      }
    }
  }
}
