import React, { Component } from "react"
import BrandForm from "./brandForm";

class GenerateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (
            this.props.brands.length === 0 ?
                <div className="m-auto text-center">
                    No Brand Available.
                </div>
                :
                <>
                    {this.props.brands.map((brand, index) =>
                        <BrandForm brand={brand} {...this.props} key={index} index={index} lastIndex={this.props.brands.length > 1 && index >= this.props.brands.length - 1} />
                    )
                    }
                </>

        )
    }
}

export default GenerateForm