import { Widget } from "./widget";
import { fontList, marqueeDirection, frequencyList } from "./mapUtils";

const uuidv4 = require("uuid/v4");
const weatherImage = require("../../../assets/picture/weather.png");
const textImage = require("../../../assets/picture/text.png");
const urlImage = require("../../../assets/picture/url.png");
const hlsImage = require("../../../assets/picture/hls.png");
const rssImage = require("../../../assets/picture/rss.png");
const dateTimeImage = require("../../../assets/picture/datetime.png");
const YoutubeImage = require("../../../assets/picture/youtube_widget.png");
const picToScreenImage = require("../../../assets/picture/pic_to_screen.png");
const routeImage = require("../../../assets/picture/route.png");
const transparentImage = require("../../../assets/picture/transparent.png");

// const previewLink = require("../../../assets/picture/widget.png");

const playlistImg = require("../../../assets/images/playlist.png");

function convertMinutesToSecond({ minute, second }) {
  return Number(minute) * 60 + Number(second);
}

function convertSecondToMinutes(seconds) {
  return {
    minute: parseInt(seconds / 60, 10),
    second: parseInt(seconds % 60, 10)
  };
}

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
export function DateTime(params) {
  const { DateTime } = Widget;
  if (!DateTime) {
    throw new Error("DateTime widget is not initialized");
  }
  this.contentLocalID = uuidv4();
  this.meta = params;
  this.color = DateTime.color;
  this.type = DateTime.widgetName;
  this.icon = DateTime.icon;
  this.color = DateTime.color;
  this.backgroundColor = DateTime.backgroundColor;
  this.contentThumbnail = dateTimeImage;
  this.preview = dateTimeImage;
  this.contentName = "DateTime Widget";
  if (params) {
    this.contentPosition = params.contentPosition;
    this.DateTimeWidgetID = params.DateTimeWidgetID;
    this.duration = convertSecondToMinutes(params.duration);
    this.timezone = params.timezone;
    this.backgroundColor = params.backgroundColor;
    this.textColor = params.textColor;
  } else {
    this.DateTimeWidgetID = undefined;
    this.duration = { minute: 0, second: 15 };
    this.contentPosition = undefined;
    this.backgroundColor = "#000";
    this.timezone = {
      value: "Asia/Kathmandu",
      label: "(GMT +05:45) Asia/Kathmandu"
    };
    this.textColor = "#fff";
  }
}

export function RSS(params) {
  const { RSS } = Widget;
  if (!RSS) {
    throw new Error("RSS widget is not initialized");
  }
  this.contentLocalID = uuidv4();
  this.type = RSS.widgetName;
  this.meta = params;
  this.color = RSS.color;
  this.icon = RSS.icon;
  this.color = RSS.color;
  this.width = RSS.width;
  this.height = RSS.height;
  this.backgroundColor = RSS.backgroundColor;
  this.contentThumbnail = rssImage;
  this.directionsList = undefined;
  this.fontList = fontList;
  this.preview = rssImage;
  this.marqueeDirectionsList = marqueeDirection;
  this.contentName = "RSS Widget";
  if (params) {
    this.contentPosition = params.contentPosition;
    this.RSSWidgetID = params.URLWidgetID;
    this.url = params.url;
    this.duration = convertSecondToMinutes(params.duration);
    this.backgroundColor = params.backgroundColor;
    this.fontFamily = params.fontFamily;
    this.fontSize = params.fontSize;
    this.text = params.text;
    this.textColor = params.textColor;
    this.rssWidgetID = params.rssWidgetID;
    this.contentPosition = params.contentPosition;
    this.delay = params.delay || 60;
    this.title = params.title;
    this.fontFamily = {
      value: params.fontFamily,
      label: params.fontFamily
    } || { ...fontList[0] };
    this.direction = {
      value: params.direction,
      label: params.direction
    } || { ...marqueeDirection[0] };
  } else {
    this.RSSWidgetID = undefined;
    this.url = this.url;
    this.backgroundColor = RSS.backgroundColor;
    this.text = RSS.widgetName;
    this.textColor = RSS.textColor;
    this.text = RSS.text;
    this.fontSize = RSS.fontSize;
    this.duration = { minute: 0, second: 15 };
    this.contentPosition = undefined;
    this.title = [];
    this.delay = 6;
    this.fontFamily = { ...fontList[0] };
    this.direction = { ...marqueeDirection[0] };
  }
}

export function URL(params) {
  const { UrlW } = Widget;
  if (!UrlW) {
    throw new Error("URL widget is not initialized");
  }
  this.contentLocalID = uuidv4();
  this.meta = params;
  this.type = UrlW.widgetName;
  this.icon = UrlW.icon;
  this.color = UrlW.color;
  this.width = UrlW.width;
  this.height = UrlW.height;
  this.backgroundColor = UrlW.backgroundColor;
  this.contentThumbnail = urlImage; // just comment
  this.preview = urlImage;
  this.contentName = "URL Widget";
  this.destroy = 0;
  if (params) {
    this.URLWidgetID = params.URLWidgetID;
    this.scrollTo = params.scrollTo;
    this.url = params.url;
    this.duration = convertSecondToMinutes(params.duration);
    this.scaleFactor = params.scaleFactor;
  } else {
    this.contentPosition = undefined;
    this.URLWidgetID = undefined;
    this.scrollTo = 0;
    this.scaleFactor = 1.0;
    this.url = UrlW.url;
    this.duration = { minute: 0, second: 15 };
  }
}

export function HLS(params) {
  const { HlsW } = Widget;
  if (!HlsW) {
    throw new Error("Hls widget is not initialized");
  }
  this.contentLocalID = uuidv4();
  this.meta = params;
  this.type = HlsW.widgetName;
  this.icon = HlsW.icon;
  this.color = HlsW.color;
  this.width = HlsW.width;
  this.height = HlsW.height;
  this.backgroundColor = HlsW.backgroundColor;
  this.contentThumbnail = hlsImage; // just comment
  this.preview = hlsImage;
  this.contentName = "HLS Widget";
  this.destroy = 0;
  if (params) {
    this.HlsWidgetID = params.HlsWidgetID;
    this.url = params.url;
    this.duration = convertSecondToMinutes(params.duration);
  } else {
    this.contentPosition = undefined;
    this.HlsWidgetID = undefined;
    this.url = HlsW.url;
    this.duration = { minute: 0, second: 15 };
  }
}

export function Text(params) {
  const { TextW } = Widget;
  if (!TextW) {
    throw new Error("Text widget is not initialized");
  }
  this.contentLocalID = uuidv4();
  this.textWidgetID = undefined;
  this.meta = params;
  this.type = TextW.widgetName;
  this.icon = TextW.icon;
  this.color = TextW.color;
  this.contentThumbnail = textImage;
  this.fontList = fontList;
  this.preview = textImage;
  this.contentName = "Text Widget";
  this.destroy = 0;
  if (params) {
    this.contentPosition = params.contentPosition;
    this.duration = convertSecondToMinutes(params.duration);
    this.backgroundColor = params.backgroundColor;
    this.fontFamily = { label: params.fontFamily, value: params.fontFamily };
    this.fontSize = params.fontSize;
    this.marquee = params.marquee;
    this.textAlign = params.textAlign;
    this.text = params.text;
    this.textColor = params.textColor;
    this.textWidgetID = params.textWidgetID;
  } else {
    this.contentPosition = undefined;
    this.duration = { minute: 0, second: 15 };
    this.backgroundColor = TextW.backgroundColor;
    this.fontFamily = { ...fontList[0] };
    this.text = TextW.widgetName;
    this.textColor = TextW.textColor;
    this.marquee = TextW.marquee;
    this.text = TextW.text;
    this.fontSize = TextW.fontSize;
    this.textAlign = TextW.textAlign;
  }
}

export function Playlist(params, position) {
  this.contentLocalID = uuidv4();
  this.meta = params;
  this.playlistID = params.id;
  this.type = "playlist";
  this.estimatedDuration = params.attributes.total_possible_duration;
  this.color = getRandomColor();
  this.icon = "far fa-list-alt";
  this.contentPosition = position;
  this.preview = playlistImg;
  this.contentName = params.attributes.name;
  this.playlistThumbnailType =
    params.attributes.playlist_thumbnail_content_type;
  this.url = playlistImg;
  if (params.usePlaylistDuration !== undefined) {
    this.usePlaylistDuration = params.usePlaylistDuration;
  } else {
    this.usePlaylistDuration = true;
  }
  if (params.duration) {
    this.duration = convertSecondToMinutes(params.duration);
  } else {
    this.duration = { minute: 0, second: 15 };
  }
  if (params.attributes && params.attributes.playlist_thumbnail_medium_url) {
    this.playlistThumbnail = params.attributes.playlist_thumbnail_medium_url;
  }
  if (params.attributes && params.attributes.playlist_thumbnail_large_url) {
    this.contentThumbnail = params.attributes.playlist_thumbnail_large_url;
    this.preview = params.attributes.playlist_thumbnail_large_url;
  }
}

export function Weather(params) {
  const { weather } = Widget;

  if (!weather) {
    throw new Error("Weather widget is not initialized");
  }
  this.contentLocalID = uuidv4();

  this.meta = params;
  this.type = weather.widgetName;
  this.temp = weather.temp;
  this.color = weather.color;
  this.duration = { minute: 0, second: 15 };
  this.url = weather.url;
  this.icon = weather.icon;
  this.name = weather.name;
  this.params = params || undefined;
  this.contentThumbnail = weatherImage;
  this.preview = weatherImage;
  this.contentName = "Weather Widget";
  this.destroy = 0;
  if (params) {
    this.weatherWidgetID = params.weatherWidgetID;
    this.duration = convertSecondToMinutes(params.duration);
    this.location = params.location;
    this.geo_location = params.geo_location;
    this.unit = params.unit;
    this.contentPosition = params.contentPosition;
    this.days = Number(params.days);
  } else {
    this.days = 1;
    this.unit = "celsius";
    this.contentPosition = undefined;
    this.location = weather.location;
    this.weatherWidgetID = undefined;
    this.geo_location = weather.geo_location;
  }
}

export function Youtube(params) {
  const { YoutubeW } = Widget;
  if (!YoutubeW) {
    throw new Error("Youtube widget is not initialized");
  }
  this.contentLocalID = uuidv4();
  this.meta = params;
  this.type = YoutubeW.widgetName;
  this.icon = YoutubeW.icon;
  this.color = YoutubeW.color;
  this.width = YoutubeW.width;
  this.height = YoutubeW.height;
  this.backgroundColor = YoutubeW.backgroundColor;
  this.contentThumbnail = YoutubeImage;
  this.preview = YoutubeImage;
  this.contentName = "Youtube Widget";
  this.destroy = 0;
  if (params) {
    this.YoutubeWidgetID = params.YoutubeWidgetID;
    this.scrollTo = params.scrollTo;
    this.url = params.url;
    this.duration = convertSecondToMinutes(params.duration);
  } else {
    this.contentPosition = undefined;
    this.YoutubeWidgetID = undefined;
    this.scrollTo = 0;
    this.url = YoutubeW.url;
    this.duration = { minute: 0, second: 15 };
  }
}

export function PicToScreen(params) {
  const { PicToScreenW } = Widget;
  if (!PicToScreenW) {
    throw new Error("PicToScreen widget is not initialized");
  }
  this.contentLocalID = uuidv4();
  this.meta = params;
  this.type = PicToScreenW.widgetName;
  this.icon = PicToScreenW.icon;
  this.color = PicToScreenW.color;
  this.width = PicToScreenW.width;
  this.height = PicToScreenW.height;
  this.backgroundColor = PicToScreenW.backgroundColor;
  this.contentThumbnail = picToScreenImage;
  this.preview = picToScreenImage;
  this.contentName = "PicToScreen Widget";
  this.destroy = 0;
  if (params) {
    this.PicToScreenWidgetID = params.PicToScreenWidgetID;
    this.scrollTo = params.scrollTo;
    this.url = params.url;
    this.duration = convertSecondToMinutes(params.duration);
    this.customContentDuration = params.customContentDuration;
  } else {
    this.contentPosition = undefined;
    this.PicToScreenWidgetID = undefined;
    this.scrollTo = 0;
    this.url = PicToScreenW.url;
    this.duration = { minute: 0, second: 15 };
    this.customContentDuration = null;
  }
}

export function Route(params) {
  const { RouteWidget } = Widget;

  if (!RouteWidget) {
    throw new Error("Route widget is not initialized");
  }
  this.contentLocalID = uuidv4();

  this.meta = params;
  this.type = RouteWidget.widgetName;
  this.color = RouteWidget.color;
  this.duration = { minute: 0, second: 15 };
  this.url = RouteWidget.url;
  this.icon = RouteWidget.icon;
  this.fontList = fontList;
  this.frequencyList = frequencyList;
  this.params = params || undefined;
  this.contentThumbnail = routeImage;
  this.preview = routeImage;
  this.contentName = "Route Widget";
  this.destroy = 0;
  if (params) {
    this.RouteWidgetID = params.RouteWidgetID;
    this.duration = convertSecondToMinutes(params.duration);
    this.backgroundColor = params.backgroundColor;
    this.fontSize = params.fontSize;
    this.textAlign = params.textAlign;
    this.textColor = params.textColor;
    this.fontFamily = { label: params.fontFamily, value: params.fontFamily };
    this.startingLocation = params.startingLocation;
    this.startingGeoLocation = params.startingGeoLocation;
    this.destinationLocation = params.destinationLocation;
    this.destinationGeoLocation = params.destinationGeoLocation;
    this.contentPosition = params.contentPosition;
    this.frequency = frequencyList.filter(
      item => Number(item.value) === params.frequency
    )[0];
  } else {
    this.RouteWidgetID = undefined;
    this.frequency = { value: 15, label: "Every 15 seconds" };
    this.contentPosition = undefined;
    this.duration = { minute: 0, second: 15 };
    this.startingLocation = RouteWidget.startingLocation;
    this.startingGeoLocation = RouteWidget.startingGeoLocation;
    this.destinationLocation = RouteWidget.destinationLocation;
    this.destinationGeoLocation = RouteWidget.destinationGeoLocation;
    this.backgroundColor = RouteWidget.backgroundColor;
    this.textColor = RouteWidget.textColor;
    this.fontSize = RouteWidget.fontSize;
    this.fontFamily = { ...fontList[0] };
    this.textAlign = RouteWidget.textAlign;
  }
}

export function Transparent(params) {
  const { TransparentWidget } = Widget;
  if (!TransparentWidget) {
    throw new Error("Transparent widget is not initialized");
  }
  this.contentLocalID = uuidv4();
  this.meta = params;
  this.type = TransparentWidget.widgetName;
  this.icon = TransparentWidget.icon;
  this.color = TransparentWidget.color;
  this.width = TransparentWidget.width;
  this.height = TransparentWidget.height;
  this.contentThumbnail = transparentImage; // just comment
  this.preview = transparentImage;
  this.contentName = "Transparent Widget";
  this.destroy = 0;
  if (params) {
    this.TransparentWidgetID = params.TransparentWidgetID;
    this.duration = convertSecondToMinutes(params.duration);
  } else {
    this.contentPosition = undefined;
    this.TransparentWidgetID = undefined;
    this.duration = { minute: 0, second: 15 };
  }
}

export function Content(params, position) {
  this.contentLocalID = uuidv4();
  this.meta = params;
  this.contentID = params.id;
  this.type = "content";
  this.contentPosition = position;
  this.contentName = params.attributes.name;
  if (params.attributes && params.attributes.s3_thumbnail_medium_url) {
    this.contentThumbnail = params.attributes.s3_thumbnail_medium_url;
  }
  if (
    params.attributes &&
    params.attributes.content_type.search("video") > -1 &&
    params.attributes.s3_thumbnail_large_url
  ) {
    this.contentThumbnail = params.attributes.s3_thumbnail_large_url;
  }

  if (params.attributes && params.attributes.duration) {
    if (params.attributes.duration === "N/A") {
      this.duration = { minute: 0, second: 15 };
      this.durationInSecond = 15;
    } else {
      this.duration = {
        minute: parseInt(params.attributes.duration_seconds / 60, 10),
        second: parseInt(params.attributes.duration_seconds % 60, 10)
      };
      this.durationInSecond = Number(params.attributes.duration_seconds);
    }
  }

  if (
    params.attributes &&
    params.attributes.content_type.search("video") > -1
  ) {
    this.contentType = "video";
    this.preview = params.attributes.s3_downsample_480x360_url;
    this.previewRaw = params.attributes.s3_downsample_480x360_url;
  } else {
    this.contentType = "image";
    this.preview = params.attributes.s3_thumbnail_large_url;
    this.previewRaw = params.attributes.s3_url;
  }
  this.setProperty = (protoName, protoValue) => {
    this[protoName] = protoValue;
  };
}

export function Screen() {
  this.localID = uuidv4();
  this.screenName = "New Region";
  this.screenPosition = undefined;
  this.screenColor = undefined;
  this.screenX = undefined;
  this.screenY = undefined;
  this.width = undefined;
  this.height = undefined;
  this.screenImages = undefined;
  this.defaultImage = undefined;
  this.defaultImageContentType = undefined;
  this.verticalScale = undefined;
  this.horizontalScale = undefined;
  this.fill = undefined;
  this.scale = undefined;
  this.contents = [];
  this.fillPatternImage = null;
  this.fillPatternScaleX = undefined;
  this.fillPatternScaleY = undefined;
  this.fillPatternX = undefined;
  this.fillPatternY = undefined;

  this.removeContent = contentLocalID => {
    const { contents } = this;
    this.contents = contents.filter(item => item.localID !== contentLocalID);
    this.calculateThumbnail();
  };
  this.setProperty = (protoName, protoValue) => {
    this[protoName] = protoValue;
  };
  this.calculateScales = (
    screenWidth,
    screenHeight,
    studioHeight,
    studioWidth
  ) => {
    this.horizontalScale = screenWidth / studioWidth;
    this.verticalScale = screenHeight / studioHeight;
  };

  this.calculateThumbnail = () => {
    const { contents } = this;
    if (contents.length > 0) {
      const tempContent = contents[0];
      if (tempContent.type === "content") {
        this.defaultImage = tempContent.contentThumbnail;
        this.defaultImageContentType = tempContent.contentType;
      } else {
        this.defaultImage = tempContent.contentThumbnail;
        this.defaultImageContentType = tempContent.contentType;
      }
    } else {
      this.defaultImage = undefined;
      this.defaultImageContentType = undefined;
    }
  };
}
