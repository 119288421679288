import React from "react";
import { Tooltip } from "../../../core";

export class DeviceListHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="d-flex">
        <div className="ml-auto mb-3 contentHeader__search search">
          <span
            className="bx bx-search bx-sm search__searchIcon"
            width="100%"
          />
          <input
            type="text"
            className="search__input"
            placeholder="Search by Mac Address"
            onChange={e => this.props.onChange(e)}
            value={this.props.value}
          />
        </div>
        <Tooltip
          item={
            <div
              role="presentation"
              className="iconButton"
              onClick={() => this.props.handleRefresh()}
            >
              <i
                className={`bx bx-revision ${
                  this.props.refreshing ? "refresh-rotate " : ""
                }`}
              />
            </div>
          }
          id="header-content-refresh"
          tooltipContent="Refresh"
        />
      </div>
    );
  }
}
