import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { remove } from "lodash";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { columns } from "../utils/unacceptedDeviceListUtils";

class DeniedDeviceListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        name: false,
        email: false,
        roles: false,
        date_created: false,
        date_updated: false,
        confirmation: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      selectColumnStyle: { width: "40px" },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="contentSection__checkBoxWrapper">
          <input type={mode} {...rest} />
          <span className="checkmark" />
        </label>
      ),
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("devicesSelected", [
            ...this.props.devicesSelected,
            row
          ]);
        } else {
          const { devicesSelected } = this.props;
          remove(devicesSelected, { id: row.id });
          this.props.change("devicesSelected", devicesSelected);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("devicesSelected", [
            ...this.props.devicesSelected,
            ...rows
          ]);
        } else {
          this.props.change("devicesSelected", []);
        }
      }
    };

    return (
      <div>
        <ToolkitProvider
          forwardRef={this.props.forwardRef}
          keyField="id"
          data={this.props.deviceList}
          columns={columns(
            this.props.toggleAcceptModal,
            this.props.toggleDeleteUnAcceptedDeviceModal,
            this.props.permission,
            this.props.roles
          )}
          columnToggle
        >
          {props => (
            <div>
              <BootstrapTable
                // classes="contentSectionTable"
                hover
                wrapperClasses="table-responsive content-wrap"
                ref={this.props.forwardRef}
                bordered={false}
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      width: "1412px",
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#2cc277",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                keyField="id"
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No data</span>
                  )
                }
                // selectRow={selectRow}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

// export default ContentTable;

export default connect(state => {
  const selector = formValueSelector("SelectedDeniedDevices");
  return {
    devicesSelected: selector(state, "devicesSelected") || []
  };
})(
  reduxForm({
    form: "SelectedDeniedDevices"
  })(DeniedDeviceListTable)
);
