/* eslint-disable react/sort-comp */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prefer-stateless-function */
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Prompt } from "react-router";
import { Card, Button, ButtonGroup } from "reactstrap";
import { destroy, reduxForm, change, formValueSelector } from "redux-form";
import RouteLeavingGuard from "../modules/prompt";
import { Resources } from "../../contents/redux";
import { Resources as campaignResources } from "../redux";
import { Resources as playlistResources } from "../../playlist/redux";
import { updateCampaign } from "../redux/campaignStudioReducer";
import {
  StudioContentSelector,
  StudioSplitScreen,
  StudioTimeLine
} from "../modules";
import Preview from "../modules/preview/preview";
import { Tooltip, Dialog } from "../../../core";
import CampaignStudioHeader from "./campaignStudioHeader";
import StudioActionButtons from "../modules/studioTimeLine/studioActionButtons";
import { CampaignStudioDesktopView } from "./campaignStudioDesktopView";
import DesktopBreakpoint from "../../../context/desktop_breakpoint";
import PhoneBreakpoint from "../../../context/phone_breakpoint";

class Studio extends React.Component {
  constructor(props) {
    super(props);
    // this.serverConnector();
    this.state = {
      isLoading: true,
      showPreview: false,
      resolution: "landscape",
      showResOptions: false,
      displayRegionsForMobile: false,
      mediaModal: false,
      screenID: undefined
    };
    this.clearTagRef = React.createRef();
    this.perPageCount = 20;
    this.mediaMatch = window.matchMedia(
      "(max-width: 1650px) and (min-width: 1350px)"
    );
  }

  displayMediaModal = (flag, screenID) => {
    this.setState({ mediaModal: flag, screenID });
  };

  setLoading = flag => {
    this.setState({ isLoading: flag });
  };

  componentWillUnmount = () => {
    this.mediaMatch = undefined;
  };

  handleRegionDisplayForMobile = () => {
    const { campaign } = this.props;
    this.setState(state => ({
      displayRegionsForMobile: !state.displayRegionsForMobile
    }));
    if (this.state.displayRegionsForMobile) {
      const selectedScreen = undefined;
      campaign.setSelectedScreen(selectedScreen);
      this.props.updateCampaign(campaign);
    }
  };

  /** Load playlist from server */
  handlePlaylistLoadFromServer = async () => {
    const { getPlaylist } = playlistResources;
    getPlaylist.url = "/playlists";
    this.props.Get(getPlaylist);
  };

  /** Load content form server */
  handleContentLoadFromServer = async () => {
    const { getContent } = Resources;
    getContent.url = "/contents";
    this.props.Get(getContent);
  };

  /** Load Required data to load  */
  serverConnector = async () => {
    this.setLoading(true);
    const promises = [
      // this.handlePlaylistLoadFromServer()
      // this.handleContentLoadFromServer()
    ];
    Promise.all([...promises]).then(() => this.setLoading(false));
  };

  handleFilter = (filter, activeTab) => {
    this.setLoading(true);
    if (activeTab === "Media" && this.clearTagRef.current.searchByTagRef) {
      this.clearTagRef.current.searchByTagRef.current.state.value = "";
      this.clearTagRef.current.state.searchTag = [];
      this.clearTagRef.current.state.showClearButtonMedia = false;
    }
    const { getContent } = Resources;
    filter === "true"
      ? (getContent.url = `/contents/?is_filler_content=${filter}&page=1&per_page=18`)
      : (getContent.url = `/contents/?type=${filter}&page=1&per_page=18`);
    this.props.Get(Resources.getContent).then(() => this.setLoading(false));
  };

  handleCampaignSave = async (values, sync = undefined) => {
    const { postCampaign, putCampaign, getCampaign } = campaignResources;
    const { campaign } = this.props;
    if (campaign.screens.length > 0) {
      if (!campaign.isEditing) {
        postCampaign.body = this.props.campaign.prepareData();
        const result = await this.props.Post(postCampaign);
        if (result.status === 201) {
          this.props.destroy("SplitScreen");
          this.props.destroy("createCampaign");
          this.props.history.push("/campaign");
          getCampaign.url = `/campaigns?page=${1}&per_page=${
            this.perPageCount
          }`;
          this.props.Get(getCampaign);
          return 0;
        }
        return 0;
      }
      putCampaign.body = this.props.campaign.prepareData();
      putCampaign.url = `/campaigns/${this.props.campaign.campaignId}`;
      putCampaign.body._jsonapi.data.attributes.id = campaign.campaignId;
      if (sync === "WithSync") {
        putCampaign.body._jsonapi.data.sync = true;
      }
      const putResult = await this.props.Put(putCampaign);
      if (putResult.status === 200) {
        this.props.destroy("SplitScreen");
        this.props.destroy("createCampaign");
        this.props.history.push("/campaign");
        getCampaign.url = `/campaigns?page=${1}&per_page=${this.perPageCount}`;
        this.props.Get(getCampaign);
      } else {
        /** When Will be  */
      }
    } else {
      this.props.showNotification("There must be a region", "danger");
    }
  };

  handleCancelCampaignCreation = () => {
    this.props.destroy("SplitScreen");
    this.props.destroy("createCampaign");
    this.props.history.push("/campaign");
  };

  handleScreenGeneration = values =>
    Object.keys(values).map((item, index) => ({
      number: index,
      width: values[item].width * values[item].horizontalScale,
      height: values[item].height * values[item].verticalScale,
      starting_x: values[item].x * values[item].horizontalScale,
      starting_y: values[item].y * values[item].verticalScale,
      volume: values[item].volume,
      campaign_contents_attributes: this.handleContentCampaignMapping(
        values[item].content
      )
    }));

  handleContentCampaignMapping = content =>
    content.map(item => ({
      playlist_id: item.type === "playlists" ? item.id : null,
      content_id: item.type !== "playlists" ? item.id : null,
      duration: item.usePlaylistContentDuration
        ? 0
        : this.handleDurationCalculation(item.duration),
      use_playlist_duration: item.usePlaylistContentDuration
    }));

  handleDurationCalculation = duration => {
    if (duration) {
      return Number(duration.minute) * 60 + Number(duration.second);
    }
    return 10000;
  };

  updatePage = () => {
    this.setState(prevState => ({
      rerender: !prevState.rerender
    }));
    this.forceUpdate();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.screens !== this.props.screens) {
      this.setState(prevState => ({
        rerender: !prevState.rerender
      }));
    }
  }

  handleResolutionList = () => {
    this.setState(state => ({ showResOptions: !state.showResOptions }));
  };

  handleResolutionChange = (resolution, height, width) => {
    const { campaign } = this.props;
    if (campaign.displayResolution !== resolution) {
      this.setState({ resolution });
      const obj = campaign.changeOrientation(height, width);
      campaign.setOrientation(obj.id, obj.res, this.mediaMatch.matches);
      campaign.screens = [];
      campaign.generateScreens();
      this.props.updateCampaign(campaign);
    }
    this.handleResolutionList();
  };

  handleContentSort = (value, sort, activeTab) => {
    this.setLoading(true);
    if (sort) {
      if (activeTab === "Media" && this.clearTagRef.current.searchByTagRef) {
        this.clearTagRef.current.searchByTagRef.current.state.value = "";
        this.clearTagRef.current.state.searchTag = [];
        this.clearTagRef.current.state.showClearButtonMedia = false;
      }
    }
    const { getContent } = Resources;
    getContent.url = value;
    this.props.Get(Resources.getContent).then(() => this.setLoading(false));
  };

  togglePreview = (flag = false) => {
    this.setState({ showPreview: flag, isStart: true });
  };

  handleExitZoom = () => {
    const { campaign } = this.props;
    campaign.zoomCampaignEditor(false);
    this.props.updateCampaign(campaign);
  };

  checkMediaQuery = () => {
    const { campaign, updateCampaign: fn } = this.props;
    if (this.mediaMatch) {
      campaign.setOrientation(
        campaign.resolution,
        this.props.resolutionList,
        this.mediaMatch.matches
      );
      campaign.handleMediaQuery(this.mediaMatch.matches);
      fn(campaign);
    }
  };

  primaryMethod = () => {
    const { screenID } = this.state;
    const { campaign, updateCampaign: fn } = this.props;
    if (
      campaign.contentIsBeingDragged &&
      campaign.contentToBeDropped.length > 0
    ) {
      campaign.unLoadContentToBeDropped(screenID);
      fn(campaign);
    } else if (
      campaign.playlistIsBeingDragged &&
      campaign.playlistToBeDropped.length > 0
    ) {
      campaign.addPlaylist(screenID);
      fn(campaign);
    } else if (campaign.WidgetIsBeingDragged) {
      if (campaign.WeatherIsBeingDragged) {
        campaign.addWeather(screenID);
      }
      if (campaign.TextIsBeingDragged) {
        campaign.addText(screenID);
      }
      if (campaign.URLIsBeingDragged) {
        campaign.addURL(screenID);
      }
      if (campaign.HLSIsBeingDragged) {
        campaign.addHLS(screenID);
      }
      if (campaign.RSSIsBeingDragged) {
        campaign.addRSS(screenID);
      }
      if (campaign.YoutubeIsBeingDragged) {
        campaign.addYoutube(screenID);
      }
      if (campaign.PicToScreenIsBeingDragged) {
        campaign.addPicToScreen(screenID);
      }
      if (campaign.DateTimeIsBeingDragged) {
        campaign.addDateTime(screenID);
      }
      if (campaign.RouteIsBeingDragged) {
        campaign.addRoute(screenID);
      }
      if (campaign.TransparentIsBeingDragged) {
        campaign.addTransparent(screenID);
      }
      fn(campaign);
    } else {
      return 0;
    }

    this.displayMediaModal(false);
  };

  render() {
    const { campaign } = this.props;
    const isContentEmpty = campaign.screens.map(
      item => item.contents.length > 0
    );
    if (!campaign.isCampaignInitialized) {
      this.props.history.push("/campaign");
      return null;
    }
    const { history } = this.props;
    this.mediaMatch.onchange = () => this.checkMediaQuery();
    const campaignMediaStage = isContentEmpty.includes(true);
    return (
      <div className="mainPage">
        <div className="contentSection campaignStudioWrapper">
          {!campaign.isZoomEnable ? (
            <div>
              <RouteLeavingGuard history={history} campaign={campaign} />

              <DesktopBreakpoint>
                <CampaignStudioDesktopView
                  orientation={campaign.orientation}
                  handleFilter={this.handleFilter}
                  handleContentSearch={this.handleContentSort}
                  isLoading={this.state.isLoading}
                  setLoading={this.setLoading}
                  forwardRef={this.clearTagRef}
                  filter={this.state.filter}
                  resetFilter={this.resetFilter}
                  campaignLoading={this.state.isLoading}
                  subscription={this.props.subscription}
                  campaign={this.props.campaign}
                  handleCampaignSave={this.handleCampaignSave}
                  handleCancelCampaignCreation={
                    this.handleCancelCampaignCreation
                  }
                  showPreview={this.state.showPreview}
                  togglePreview={this.togglePreview}
                  infiniteScrollHeight={355}
                  {...this.props}
                />
              </DesktopBreakpoint>
              <PhoneBreakpoint>
                <div>
                  <div>
                    <Card body className="campaignStudioWrapper__rightSection">
                      <div className="d-flex flex-column campaignStudioWrapper__rightSection__column">
                        <StudioSplitScreen
                          handleCampaignSave={this.handleCampaignSave}
                          handleCancelCampaignCreation={
                            this.handleCancelCampaignCreation
                          }
                          campaign={campaign}
                          showPreview={this.state.showPreview}
                          togglePreview={this.togglePreview}
                          {...this.props}
                        />
                      </div>
                    </Card>

                    <StudioActionButtons
                      campaign={this.props.campaign}
                      {...this.props}
                      showPreview={this.state.showPreview}
                      togglePreview={this.togglePreview}
                      handleResolutionChange={this.handleResolutionChange}
                      isContentEmpty={isContentEmpty}
                      resolution={this.state.resolution}
                      showResOptions={this.state.showResOptions}
                      handleResolutionList={this.handleResolutionList}
                      layout={this.props.layout}
                    />
                  </div>
                  <Dialog
                    externalControl
                    showHandler={false}
                    modal={this.state.mediaModal}
                    body={
                      <StudioContentSelector
                        orientation={this.props.orientation}
                        handleFilter={this.handleFilter}
                        handleContentSearch={this.handleContentSort}
                        isLoading={this.state.isLoading}
                        setLoading={this.setLoading}
                        forwardRef={this.clearTagRef}
                        filter={this.state.filter}
                        resetFilter={this.resetFilter}
                        campaignLoading={this.state.isLoading}
                        subscription={this.props.subscription}
                        campaign={this.props.campaign}
                        infiniteScrollHeight={300}
                        {...this.props}
                      />
                    }
                    title="Select Media"
                    toggleModel={this.displayMediaModal}
                    primaryMethod={this.primaryMethod}
                    className="mediaModal mediaModal--mod"
                  />{" "}
                </div>
              </PhoneBreakpoint>

              <StudioTimeLine
                campaign={this.props.campaign}
                preview={this.props.preview}
                {...this.props}
                showPreview={this.state.showPreview}
                togglePreview={this.togglePreview}
                handleResolutionChange={this.handleResolutionChange}
                isContentEmpty={isContentEmpty}
                resolution={this.state.resolution}
                showResOptions={this.state.showResOptions}
                handleResolutionList={this.handleResolutionList}
                restrictedCampaign={this.props.restrictedCampaign}
                displayRegionsForMobile={this.state.displayRegionsForMobile}
                handleRegionDisplayForMobile={this.handleRegionDisplayForMobile}
                restrictedCampaignDuration={
                  this.props.restrictedCampaignDuration
                }
                displayMediaModal={this.displayMediaModal}
              />
            </div>
          ) : (
            <div className=" campaignStudioWrapper__zoomWrapper">
              <StudioSplitScreen
                handleCampaignSave={this.handleCampaignSave}
                handleCancelCampaignCreation={this.handleCancelCampaignCreation}
                showPreview={this.state.showPreview}
                togglePreview={this.togglePreview}
                {...this.props}
              />
              <div
                className="d-flex campaignStudioWrapper__zoomWrapper__block"
                style={{
                  marginTop: campaign.orientation === "Portrait" && "10px"
                }}
              >
                <StudioActionButtons
                  campaign={this.props.campaign}
                  {...this.props}
                  showPreview={this.state.showPreview}
                  togglePreview={this.togglePreview}
                  handleResolutionChange={this.handleResolutionChange}
                  isContentEmpty={isContentEmpty}
                  resolution={this.state.resolution}
                  showResOptions={this.state.showResOptions}
                  handleResolutionList={this.handleResolutionList}
                  layout={this.props.layout}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    const playlist = state.playlists.list || [];
    const { list } = state.resolutions;
    const selector = formValueSelector("CampaignLayout");

    // const { meta } = state.campaign;
    return {
      ContentTag: state.ContentTag.list
        ? state.ContentTag.list.attributes.tags
        : [],
      playlist,
      campaign: state.newCampaign.campaign,
      resolutionList: list || [],
      SponserTag: state.SponserTag.list ? state.SponserTag.list : [],
      PartyTagList: state.PartyTagList.partyList
        ? state.PartyTagList.partyList.attributes.tags
        : [],
      BrandTagList: state.BrandTagList.brandList
        ? state.BrandTagList.brandList.attributes.tags
        : [],
      subscription: state.resolutions.meta
        ? state.resolutions.meta.subscription
        : "basic",
      restrictedCampaign: state.resolutions.meta
        ? state.resolutions.meta.campaign_locked
        : false,
      restrictedCampaignDuration: state.resolutions.meta
        ? state.resolutions.meta.campaign_locked_duration
        : 600,
      layout:
        selector(state, "selectedLayout") || state.newCampaign.campaign.layout,
      featureAllowed: JSON.parse(
        window.localStorage.getItem("domain") !== "undefined"
          ? window.localStorage.getItem("domain")
          : null
      ).is_party_brand_feature_enabled,
      rateAmountRestriction: JSON.parse(
        window.localStorage.getItem("domain") !== "undefined"
          ? window.localStorage.getItem("domain")
          : null
      ).is_content_rate_enabled
    };
  },
  { destroy, change, updateCampaign }
)(
  reduxForm({
    form: "SplitScreen",
    destroyOnUnmount: false
  })(Studio)
);
