/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import moment from "moment";
import { upperFirst } from "lodash";
import { getMinutesAndSeconds } from "../../../helper";
import { convertToLabel } from "../utils/constant";

const uuidv4 = require("uuid/v4");

function timeToSecond(min, sec) {
  let second = 0;
  if (min) {
    second = Number(min * 60);
  }
  if (sec) {
    second += Number(sec);
  }
  return second;
}

export function convertToServerData(localData) {
  const d = localData.map(item => ({
    content_id: item.id,
    duration: timeToSecond(item.durationMinute, item.durationSecond) || 5,
    starting_hour: item.restrictStartHour
      ? moment(item.restrictStartHour).format("HH:mm")
      : undefined,
    ending_hour: item.restrictEndHour
      ? moment(item.restrictEndHour).format("HH:mm")
      : undefined,
    starting_date: item.startDate ? moment(item.startDate).format() : undefined,
    ending_date: item.endDate ? moment(item.endDate).format() : undefined,
    weight: item.weight ? Number(item.weight) : undefined,
    context: item.context
      ? {
          type: item.context.type.value,
          attributes: {
            unit: item.context.unit ? item.context.unit : undefined,
            tempField: item.context.tempfield
              ? item.context.tempfield
              : undefined,
            inequality1: item.context.inequality1
              ? item.context.inequality1.value
              : undefined,
            temp1: item.context.temp1 ? item.context.temp1 : undefined,
            inequality2: item.context.inequality2
              ? item.context.inequality2.value
              : undefined,
            temp2: item.context.temp2 ? item.context.temp2 : undefined
          }
        }
      : undefined
  }));
  return d;
}

export function convertToLocalEvent(data) {
  const playlistContent = data.playlistContents
    ? Object.values(data.playlistContents)
    : [];

  return playlistContent.map(i => {
    const time = getMinutesAndSeconds(i.attributes.duration);
    const content = data.contents[i.relationships.content.data.id];
    return {
      id: content.id,
      type: content.type,
      attributes: {
        name: content.attributes.name,
        duration_seconds: content.attributes.durationSeconds,
        file_name: content.attributes.fileName,
        s3_thumbnail_medium_url: content.attributes.s3ThumbnailMediumUrl,
        content_type: content.attributes.contentType,
        is_filler_content: content.attributes.isFillerContent
      },
      durationMinute: time.minutes,
      durationSecond: time.seconds,

      weight: i.attributes.weight ? i.attributes.weight : null,

      restrictStartHour: i.attributes.startingHour
        ? moment(i.attributes.startingHour, "HH:mm")._d
        : undefined,
      restrictEndHour: i.attributes.endingHour
        ? moment(i.attributes.endingHour, "HH:mm")._d
        : undefined,

      startDate: i.attributes.startingDate
        ? moment(i.attributes.startingDate)._d
        : undefined,
      endDate: i.attributes.endingDate
        ? moment(i.attributes.endingDate)._d
        : undefined,

      context: i.attributes.context
        ? {
            inequality1: {
              value: i.attributes.context.attributes.inequality1,
              label: i.attributes.context.attributes.inequality1
            },
            temp1: i.attributes.context.attributes.temp1,
            tempfield: i.attributes.context.attributes.tempField,
            type: {
              value: i.attributes.context.type,
              label: upperFirst(i.attributes.context.type)
            },
            unit: i.attributes.context.attributes.unit,
            inequality2: {
              value: i.attributes.context.attributes.inequality2,
              label: i.attributes.context.attributes.inequality2
            },
            temp2: i.attributes.context.attributes.temp2
          }
        : undefined,

      uniqueKey: uuidv4()
    };
  });
}
