import React from "react";
import { Link } from "react-router-dom";
import { toLower, startCase } from "lodash";
import { Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";

export function columns(
  toggleAcceptModal,
  toggleDeleteUnAcceptedDeviceModal,
  permission,
  roles
) {
  return [
    {
      dataField: "mac_address",
      text: "Mac Address",
      columnName: "name"
    },
    {
      dataField: "duplicate_in",
      text: "Duplicate In",
      columnName: "duplicate_in",
      formatter: value => (
        <span className={`${value && "roles admin "}`}>{startCase(value)}</span>
      )
    },
    {
      dataField: "acceptButton",
      text: "",
      style: { position: "relative" },
      columnName: "isSelected",
      formatter: (name, row) => (
        <div>
          <button
            type="button"
            className=" btn-info contextButton"
            name="context"
            style={{
              fontSize: ".875rem",
              padding: "7px"
            }}
            onClick={() => toggleAcceptModal(row.mac_address, row.type)}
          >
            Accept
          </button>
        </div>
      )
    },
    {
      dataField: "actionButtons",
      text: "",
      columnName: "isSelected",
      style: { position: "relative" },
      formatter: (name, row) => (
        <div className="d-flex">
          <div className="contentSectionTable__menu">
            <i className="fas fa-ellipsis-h" />
          </div>
          <div className="contentSectionTable__actionButtonWrapper">
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.devices.delete}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={e =>
                        toggleDeleteUnAcceptedDeviceModal(row.mac_address)
                      }
                      className="fa fa-trash"
                    />
                  }
                  id={`delete-${row.id}`}
                  tooltipContent="Delete"
                />
              </span>
            </RouteAllowed>
            .
          </div>
        </div>
      )
    }
  ];
}
