/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { SketchPicker } from "react-color";
import PerfectScrollbar from "react-perfect-scrollbar";
import { updateCampaign } from "../../redux";
import { MultipleSelect } from "../../../../core/form/searchSelect";
import { Tooltip } from "../../../../core";
import GetRouteWithPromises from "./routePlaceAutoSuggest";

function updateWidgetProperty(screenID, contentID, node, value, campaign, fn) {
  campaign.updateWidgetProperty(screenID, contentID, node, value);
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "startingLocation",
    defaultState.startingLocation
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "destinationLocation",
    defaultState.destinationLocation
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "textAlign",
    defaultState.textAlign
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "fontSize",
    defaultState.fontSize
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "fontFamily",
    defaultState.fontFamily
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "textColor",
    defaultState.textColor
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "backgroundColor",
    defaultState.backgroundColor
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "frequency",
    defaultState.frequency
  );
  updateCampaign(campaign);
  toggle(false);
}

const checkValidation = e => {
  if (e.target.value === "") {
    e.target.value = "";
    return e.target.value;
  }
};

const RouteWidget = props => {
  const [showTextColorSelector, setTextColorSelector] = useState(false);
  const [showTextBackgroundColorSelector, setTextBackColorSelector] = useState(
    false
  );
  const {
    routeDuration,
    contentLocalID,
    startingLocation,
    destinationLocation,
    fontSize,
    width,
    height,
    backgroundColor,
    textColor,
    frequency,
    textAlign,
    fontFamily
  } = props.routeWidgetData;
  const {
    screen: { localID }
  } = props.data;
  return (
    <Modal
      isOpen={props.open}
      toggle={() => props.toggle(false)}
      backdrop
      className=" widgetScreenModal"
    >
      <ModalHeader toggle={() => cancelChanges(props)}>
        {props.type}
      </ModalHeader>
      <ModalBody>
        <div>
          <div
            className=""
            style={{
              height: `${props.screen.height}px`,
              width: `${props.screen.width}px`,
              maxHeight: `${props.data.screen.height - 10}px`,
              maxWidth: `${props.data.screen.width - 35}px`,
              fontSize: `${fontSize / props.data.screen.verticalScale}px`,
              textAlign,
              backgroundColor,
              overflowX: props.data.screen.width < width ? "scroll" : "hidden",
              overflowY:
                props.data.screen.height < height ? "scroll" : "hidden",
              color: textColor,
              position: "relative"
            }}
          >
            <div
              className="center-the-element-vertically"
              style={{ fontFamily: fontFamily.value }}
            >
              {routeDuration}
            </div>
          </div>
        </div>
        <Form>
          <Row onClick={e => e.stopPropagation()}>
            <Col md={6}>
              <FormGroup>
                <div className=" globalInput">
                  <Label className="mb-2">Start Address </Label>
                  <GetRouteWithPromises
                    location={startingLocation}
                    isActive="start"
                    startingLocation={startingLocation}
                    destinationLocation={destinationLocation}
                    routeWidgetData={props.routeWidgetData}
                    setRouteDistance={duration => {
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "routeDuration",
                        duration,
                        props.campaign,
                        props.updateCampaign
                      );
                    }}
                    updateLocation={location => {
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "startingLocation",
                        location,
                        props.campaign,
                        props.updateCampaign
                      );
                    }}
                    updateGeoLocation={(
                      startingGeoLocation,
                      destinationGeoLocation
                    ) => {
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "startingGeoLocation",
                        startingGeoLocation,
                        props.campaign,
                        props.updateCampaign
                      );
                    }}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <div className=" globalInput">
                  <Label className="mb-2">Destination Address</Label>
                  <GetRouteWithPromises
                    startingLocation={startingLocation}
                    destinationLocation={destinationLocation}
                    isActive="destination"
                    location={destinationLocation}
                    routeWidgetData={props.routeWidgetData}
                    setRouteDistance={duration => {
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "routeDuration",
                        duration,
                        props.campaign,
                        props.updateCampaign
                      );
                    }}
                    updateLocation={location => {
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "destinationLocation",
                        location,
                        props.campaign,
                        props.updateCampaign
                      );
                    }}
                    updateGeoLocation={(
                      startingGeoLocation,
                      destinationGeoLocation
                    ) => {
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "destinationGeoLocation",
                        destinationGeoLocation,
                        props.campaign,
                        props.updateCampaign
                      );
                    }}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row
            className="color-picker-wrapper"
            onClick={e => e.stopPropagation()}
          >
            <Col md={12}>
              <FormGroup>
                <div className="mt-0 globalInput">
                  <Label className="mb-2">Frequency</Label>
                  <MultipleSelect
                    input={{
                      value: frequency,
                      onChange: value => {
                        updateWidgetProperty(
                          localID,
                          contentLocalID,
                          "frequency",
                          value,
                          props.campaign,
                          props.updateCampaign
                        );
                      }
                    }}
                    options={props.routeWidgetData.frequencyList}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row onClick={e => e.stopPropagation()}>
            <Col md={12} className=" textWidget btn-group">
              <Tooltip
                tooltipContent="Text Color"
                id="textColor"
                item={
                  <button
                    type="button"
                    className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
                    onClick={() => {
                      setTextColorSelector(!showTextColorSelector);
                      setTextBackColorSelector(false);
                    }}
                  >
                    <i className="fas fa-font" />{" "}
                    <b
                      className="selectColor"
                      style={{
                        backgroundColor: props.routeWidgetData.textColor,
                        border: "1px solid black"
                      }}
                      role="presentation"
                    />{" "}
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Background Color"
                id="backgroundColor"
                item={
                  <button
                    type="button"
                    className="btn-outline-secondary buttonGroupOutline"
                    onClick={() => {
                      setTextColorSelector(false);
                      setTextBackColorSelector(
                        !showTextBackgroundColorSelector
                      );
                    }}
                  >
                    <i className="fas fa-fill-drip" />
                    <b
                      className="selectColor"
                      style={{
                        backgroundColor: props.routeWidgetData.backgroundColor,
                        border: "1px solid black"
                      }}
                      role="presentation"
                    />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Left"
                id="alignLeft"
                item={
                  <button
                    type="button"
                    className={`btn-outline-secondary${
                      props.routeWidgetData.textAlign === "left"
                        ? " active"
                        : ""
                    } buttonGroupOutline`}
                    style={{ paddingTop: "15px" }}
                    onClick={() =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "textAlign",
                        "left",
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                  >
                    <i className=" fa fa-align-left" aria-hidden="true" />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Right"
                id="alignRight"
                item={
                  <button
                    type="button"
                    className={`btn-outline-secondary ${
                      props.routeWidgetData.textAlign === "right"
                        ? " active"
                        : ""
                    } buttonGroupOutline`}
                    style={{ paddingTop: "15px" }}
                    onClick={() =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "textAlign",
                        "right",
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                  >
                    <i className="fa fa-align-right" aria-hidden="true" />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Center"
                id="center"
                item={
                  <button
                    type="button"
                    className={`btn-outline-secondary${
                      props.routeWidgetData.textAlign === "center"
                        ? " active"
                        : ""
                    } buttonGroupOutline`}
                    style={{ paddingTop: "15px" }}
                    onClick={() =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "textAlign",
                        "center",
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                  >
                    <i className="fa fa-align-center" aria-hidden="true" />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Justify"
                id="alignJustify"
                item={
                  <button
                    type="button"
                    className={`btn-outline-secondary${
                      props.routeWidgetData.textAlign === "justify"
                        ? " active"
                        : ""
                    } buttonGroupOutline`}
                    style={{ paddingTop: "15px" }}
                    onClick={() =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "textAlign",
                        "justify",
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                  >
                    <i className="fa fa-align-justify" aria-hidden="true" />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Font Size"
                id="fontsize"
                item={
                  <Input
                    type="number"
                    value={fontSize}
                    className="text-input"
                    min="0"
                    onChange={({ target: { value } }) =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "fontSize",
                        value,
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                    placeholder="Enter font size"
                    onInput={e => checkValidation(e)}
                  />
                }
              />
              <Tooltip
                className="w-100"
                tooltipContent="Font Family"
                id="fontfamily"
                item={
                  <MultipleSelect
                    input={{
                      value: props.routeWidgetData.fontFamily,
                      onChange: value =>
                        updateWidgetProperty(
                          localID,
                          contentLocalID,
                          "fontFamily",
                          value,
                          props.campaign,
                          props.updateCampaign
                        )
                    }}
                    options={props.routeWidgetData.fontList}
                  />
                }
              />
            </Col>
            {showTextColorSelector && (
              <div className="d-flex justify-content-center textColorPicker">
                <SketchPicker
                  color={props.routeWidgetData.textColor}
                  onChange={value => {
                    updateWidgetProperty(
                      localID,
                      contentLocalID,
                      "textColor",
                      value.hex,
                      props.campaign,
                      props.updateCampaign
                    );
                  }}
                />
              </div>
            )}
            {showTextBackgroundColorSelector && (
              <div className="d-flex justify-content-center textColorPicker">
                <SketchPicker
                  color={backgroundColor}
                  onChange={value =>
                    updateWidgetProperty(
                      localID,
                      contentLocalID,
                      "backgroundColor",
                      value.hex,
                      props.campaign,
                      props.updateCampaign
                    )
                  }
                />
              </div>
            )}
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-primary"
          onClick={() => cancelChanges(props)}
        >
          Cancel
        </Button>
        <Button
          className="mt-0 primaryButton"
          onClick={() => props.toggle(false)}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function mapStateToProps(state, props) {
  const { campaign } = state.newCampaign;
  const {
    contentLocalID,
    screen: { localID }
  } = props.data;
  const routeWidgetData = campaign.getContent(localID, contentLocalID);
  campaign.getContent();
  return {
    campaign,
    routeWidgetData: routeWidgetData || []
  };
}

export default connect(
  mapStateToProps,
  { updateCampaign }
)(RouteWidget);
