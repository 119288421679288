import React, { Component } from "react";

export class NetworkGroupSearch extends Component {
  render() {
    return (
      <div className="calendarSection__leftSection__header networkHeaderSearch p-3 ">
        <span
          className="bx bx-search bx-sm search__searchIcon ml-n2"
          width="100%"
        />
        <input
          type="text"
          ref={this.props.searchByText}
          className="search__input"
          placeholder="Search by Name"
          onChange={e => {
            this.props.handleGroupSearchValue(e.target.value);
          }}
          value={this.props.searchVal}
        />
      </div>
    );
  }
}
