import React, { Component } from "react";
import {
  Card,
  CardBody,
  Button,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter
} from "reactstrap";

import { Resources } from "../redux/calendarRedux";

function downloadObjectAsJson(exportObj, exportName) {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(exportObj)
  )}`;
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", `${exportName}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export default class DownloadCalendarForUSB extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getContentFromServer = async () => {
    const result = await this.props.Get(
      Resources.getCalendarZIP(this.props.calendar.id)
    );
    if (result.status === 200) {
      const url = result.data._jsonapi.data.contents_s3_url;
      window.open(url);
      this.props.toggle(false)
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.downloadModal}
        className={this.props.className}
        toggle={() => this.props.toggle(false)}
      >
        <ModalHeader
          toggle={() => this.props.toggle(false)}
        >{`Calendar Name: ${this.props.calendar && this.props.calendar.attributes.name}`}</ModalHeader>
        <ModalBody>
          <div className="downloadCampaign">
            <h4>To download calendar data:</h4>
            <ul>
              <li>1. Download calendar contents from the button below.</li>
              <li>2. Extract the contents in your local hard drive.</li>
              <li>
                3. Transfer <code>all files</code> to root of USB device.
              </li>
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mt-3 bx-dots-verticalbtn btn-outline-primary"
            type="button"
            onClick={() => this.props.toggle(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={this.getContentFromServer}
            className="primaryButton"
            type="button"
          >
            Download
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
