/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { SketchPicker } from "react-color";
import PerfectScrollbar from "react-perfect-scrollbar";
import { updateCampaign } from "../../redux";
import { MultipleSelect } from "../../../../core/form/searchSelect";
import { Tooltip } from "../../../../core";

function updateWidgetProperty(screenID, contentID, node, value, campaign, fn) {
  campaign.updateWidgetProperty(screenID, contentID, node, value);
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "text",
    defaultState.text
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "textAlign",
    defaultState.textAlign
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "fontSize",
    defaultState.fontSize
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "fontFamily",
    defaultState.fontFamily
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "textColor",
    defaultState.textColor
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "backgroundColor",
    defaultState.backgroundColor
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "marquee",
    defaultState.marquee
  );
  updateCampaign(campaign);
  toggle(false);
}

const checkValidation = e => {
  if (e.target.value === "") {
    e.target.value = "";
    return e.target.value;
  }
};

const TextWidget = props => {
  const [showTextColorSelector, setTextColorSelector] = useState(false);
  const [showTextBackgroundColorSelector, setTextBackColorSelector] = useState(
    false
  );
  const {
    contentLocalID,
    text,
    marquee,
    fontSize,
    width,
    height,
    fontFamily,
    backgroundColor,
    textColor,
    textAlign
  } = props.textWidgetData;
  const {
    screen: { localID }
  } = props.data;

  return (
    <Modal
      isOpen={props.open}
      toggle={() => props.toggle(false)}
      backdrop
      className="textWidget widgetScreenModal"
    >
      <ModalHeader toggle={() => cancelChanges(props)}>
        {props.type}
      </ModalHeader>
      <ModalBody>
        <div>
          <div
            className=""
            style={{
              height: `${props.screen.height}px`,
              width: `${props.screen.width}px`,
              maxHeight: `${props.data.screen.height - 10}px`,
              maxWidth: `${props.data.screen.width - 35}px`,
              fontSize: `${fontSize / props.data.screen.verticalScale}px`,
              textAlign,
              backgroundColor,
              overflowX: props.data.screen.width < width ? "scroll" : "hidden",
              overflowY:
                props.data.screen.height < height ? "scroll" : "hidden",
              color: textColor,
              position: "relative"
            }}
          >
            <div className="center-the-element-vertically">
              {marquee ? (
                <marquee
                  className="d-flex justify-content-center align-item-center"
                  style={{
                    backgroundColor,
                    color: textColor,
                    border: "none",
                    fontFamily: fontFamily.value
                  }}
                >
                  {text}
                </marquee>
              ) : (
                <span style={{ fontFamily: fontFamily.value }}>{text}</span>
              )}
            </div>
          </div>
        </div>
        <Form>
          <FormGroup>
            <div className="globalInput">
              <Label>Text</Label>
              <Input
                type="textarea"
                value={text}
                onChange={({ target: { value } }) =>
                  updateWidgetProperty(
                    localID,
                    contentLocalID,
                    "text",
                    value,
                    props.campaign,
                    props.updateCampaign
                  )
                }
                placeholder="Enter text"
              />
            </div>
          </FormGroup>
          <Row
            className="color-picker-wrapper"
            onClick={e => e.stopPropagation()}
          >
            <Col md={12} className="btn-group">
              <Tooltip
                tooltipContent="Text Color"
                id="textColor"
                item={
                  <button
                    type="button"
                    className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
                    onClick={() => {
                      setTextColorSelector(!showTextColorSelector);
                      setTextBackColorSelector(false);
                    }}
                  >
                    <i className="fas fa-font" />{" "}
                    <b
                      className="selectColor"
                      style={{
                        backgroundColor: props.textWidgetData.textColor,
                        border: "1px solid black"
                      }}
                      role="presentation"
                    />{" "}
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Background Color"
                id="backgroundColor"
                item={
                  <button
                    type="button"
                    className="btn-outline-secondary buttonGroupOutline"
                    onClick={() => {
                      setTextColorSelector(false);
                      setTextBackColorSelector(
                        !showTextBackgroundColorSelector
                      );
                    }}
                  >
                    <i className="fas fa-fill-drip" />
                    <b
                      className="selectColor"
                      style={{
                        backgroundColor: props.textWidgetData.backgroundColor,
                        border: "1px solid black"
                      }}
                      role="presentation"
                    />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Left"
                id="alignLeft"
                item={
                  <button
                    type="button"
                    className={`btn-outline-secondary${
                      props.textWidgetData.textAlign === "left" ? " active" : ""
                    } buttonGroupOutline`}
                    style={{ paddingTop: "15px" }}
                    onClick={() =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "textAlign",
                        "left",
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                  >
                    <i className=" fa fa-align-left" aria-hidden="true" />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Right"
                id="alignRight"
                item={
                  <button
                    type="button"
                    className={`btn-outline-secondary ${
                      props.textWidgetData.textAlign === "right"
                        ? " active"
                        : ""
                    } buttonGroupOutline`}
                    style={{ paddingTop: "15px" }}
                    onClick={() =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "textAlign",
                        "right",
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                  >
                    <i className="fa fa-align-right" aria-hidden="true" />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Center"
                id="center"
                item={
                  <button
                    type="button"
                    className={`btn-outline-secondary${
                      props.textWidgetData.textAlign === "center"
                        ? " active"
                        : ""
                    } buttonGroupOutline`}
                    style={{ paddingTop: "15px" }}
                    onClick={() =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "textAlign",
                        "center",
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                  >
                    <i className="fa fa-align-center" aria-hidden="true" />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Justify"
                id="alignJustify"
                item={
                  <button
                    type="button"
                    className={`btn-outline-secondary${
                      props.textWidgetData.textAlign === "justify"
                        ? " active"
                        : ""
                    } buttonGroupOutline`}
                    style={{ paddingTop: "15px" }}
                    onClick={() =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "textAlign",
                        "justify",
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                  >
                    <i className="fa fa-align-justify" aria-hidden="true" />
                  </button>
                }
              />
              <Tooltip
                tooltipContent="Font Size"
                id="fontsize"
                item={
                  <Input
                    type="number"
                    value={fontSize}
                    className="text-input"
                    min="0"
                    onChange={({ target: { value } }) =>
                      updateWidgetProperty(
                        localID,
                        contentLocalID,
                        "fontSize",
                        value,
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                    placeholder="Enter font size"
                    onInput={e => checkValidation(e)}
                  />
                }
              />
              <Tooltip
                className="w-100"
                tooltipContent="Font Family"
                id="fontfamily"
                item={
                  <MultipleSelect
                    input={{
                      value: props.textWidgetData.fontFamily,
                      onChange: value =>
                        updateWidgetProperty(
                          localID,
                          contentLocalID,
                          "fontFamily",
                          value,
                          props.campaign,
                          props.updateCampaign
                        )
                    }}
                    options={props.textWidgetData.fontList}
                  />
                }
              />
            </Col>
            {showTextColorSelector && (
              <div className="d-flex justify-content-center textColorPicker ">
                <SketchPicker
                  color={props.textWidgetData.textColor}
                  onChange={value => {
                    updateWidgetProperty(
                      localID,
                      contentLocalID,
                      "textColor",
                      value.hex,
                      props.campaign,
                      props.updateCampaign
                    );
                  }}
                />
              </div>
            )}
            {showTextBackgroundColorSelector && (
              <div className="d-flex justify-content-center textColorPicker ">
                <SketchPicker
                  color={backgroundColor}
                  onChange={value =>
                    updateWidgetProperty(
                      localID,
                      contentLocalID,
                      "backgroundColor",
                      value.hex,
                      props.campaign,
                      props.updateCampaign
                    )
                  }
                />
              </div>
            )}
          </Row>
          <Row>
            <Col md={7}>
              <div
                className="mt-2 form-validation-arrange adduserinput"
                onClick={e => e.stopPropagation()}
              >
                <FormGroup check inline>
                  <Label className="checkBoxWrapper mr-4">
                    <Input
                      type="checkbox"
                      onChange={({ target: { checked } }) =>
                        updateWidgetProperty(
                          localID,
                          contentLocalID,
                          "marquee",
                          checked,
                          props.campaign,
                          props.updateCampaign
                        )
                      }
                      placeholder="Enter background color."
                      checked={props.textWidgetData.marquee}
                      // value={props.textWidgetData.marquee}
                    />
                    <p className="mt-1">Rotating</p>
                    <span className="checkmark" />
                  </Label>
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-primary"
          onClick={() => cancelChanges(props)}
        >
          Cancel
        </Button>
        <Button
          className="mt-0 primaryButton"
          onClick={() => props.toggle(false)}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function mapStateToProps(state, props) {
  const { campaign } = state.newCampaign;
  const {
    contentLocalID,
    screen: { localID }
  } = props.data;
  const textWidgetData = campaign.getContent(localID, contentLocalID);
  campaign.getContent();
  return {
    campaign,
    textWidgetData: textWidgetData || []
  };
}

export default connect(
  mapStateToProps,
  { updateCampaign }
)(TextWidget);
