import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { connect } from "react-redux";
import { columns } from "../utils/activityLogUtils";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

class ActivityLogTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: {
        activity_name: false,
        timeStamp: false,
        deviceDetail: false,
        ipAddress: false,
        browser: false
      }
    };
  }

  toggleHiddenColumns = (colName, flag) =>
    this.setState(prevState => ({
      hiddenColumns: {
        ...prevState.hiddenColumns,
        [colName]: flag
      }
    }));

  handleNodata = () => <span className="null-message-info">No Activity</span>;

  render() {
    return (
      <div>
        <ToolkitProvider
          keyField="id"
          data={this.props.activityLog}
          columns={columns(this.state.hiddenColumns)}
          columnToggle
        >
          {props => (
            <div>
              <div className="d-flex flex-row-reverse mb-2">
                <VisibleColumnToggler
                  hiddenColumns={this.state.hiddenColumns}
                  toggleHiddenColumns={this.toggleHiddenColumns}
                  columnToggleProps={{ ...props.columnToggleProps }}
                />
              </div>
              <BootstrapTable
                classes="contentSectionTable activitySectionTable"
                hover
                wrapperClasses="table-responsive content-wrap"
                bordered={false}
                loading={this.props.isLoading}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: base => ({
                      ...base,
                      width: "1412px",
                      background: "rgba(221,221,221,0.3)"
                    }),
                    spinner: base => ({
                      ...base,
                      "& svg circle": {
                        stroke: "#2cc277",
                        strokeWidth: "4"
                      }
                    })
                  }
                })}
                noDataIndication={() =>
                  this.props.isLoading ? (
                    <span style={{ minHeight: "100px" }} />
                  ) : (
                    <span>No Activity</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default connect(
  state => ({}),
  {}
)(ActivityLogTable);
