import React from "react";
import { Link } from "react-router-dom";
import { toLower, startCase } from "lodash";
import { TextClipper } from "../../../helper/TextFormatter";
import { PopOver, Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import { unitConvert } from "../../../helper/unitConvert";
import moment from "moment";
export function columns(
  roles,
  permission,
  handleDelete,
  handleSort,
  sortOrder,
  handleArchiveItem,
  handleContentEditAndView,
  hiddenColumns
) {
  let headers = [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      headerStyle: { width: "270px" },
      hidden: hiddenColumns.name,
      headerFormatter: () => (
        <div>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <div className="thumb" style={{ cursor: "pointer" }}>
          {row.attributes.is_filler_content &&
          row.attributes.is_filler_content === true ? (
            <div className="content-thumb-image">
              <div
                style={{ position: " absolute", bottom: "0px", right: "3px" }}
              >
                <i
                  className="bx bxs-certification"
                  style={{ color: "#e44b32" }}
                ></i>
              </div>
              <img
                src={
                  row.attributes.s3_thumbnail_url ||
                  require("../../../assets/images/empty-img.png")
                }
                alt={name || undefined}
                className="content__thumb"
              />
            </div>
          ) : (
            <div className="content-thumb-image">
              <img
                src={
                  row.attributes.s3_thumbnail_url ||
                  require("../../../assets/images/empty-img.png")
                }
                alt={name || undefined}
                className="content__thumb"
              />
            </div>
          )}

          <div className="tabletitle">
            {/* <RouteAllowed
roles={roles && roles}
permission={permission && permission.content.show}
>
<Link
to={{
pathname: `/dashboard/content/show/${row.id}`,
params: { ...row, handleDelete, roles, permission }
}}
> */}
            <span
              className="content__titlename"
              role="button"
              onClick={e => {
                e.stopPropagation();
                return handleContentEditAndView(true, "view", row);
              }}
            >
              {name.length > 10 ? (
                <Tooltip
                  item={
                    <span className="content__name">
                      {" "}
                      {`${name.slice(0, 15)}..`}{" "}
                    </span>
                  }
                  id={`viewname-${row.id}`}
                  tooltipContent={name}
                />
              ) : (
                <span className="content__name">{name}</span>
              )}
            </span>{" "}
            {/* </Link>
// </RouteAllowed> */}
            <span className="contentType"> {row.attributes.content_type}</span>
          </div>
        </div>
      )
    },
    {
      dataField: "attributes.size",
      text: "Size",
      columnName: "size",
      hidden: hiddenColumns.size,
      headerStyle: { width: "110px" },
      headerFormatter: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <span
            role="presentation"
            onClick={() => handleSort("size")}
            className="sort-by "
            style={{
              color: sortOrder.size.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Size
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.size.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{unitConvert(row)}</div>
    },
    {
      dataField: "attributes.dimension",
      text: "Dimension",
      columnName: "dimension",
      hidden: hiddenColumns.dimension,
      headerStyle: { width: "150px" },
      headerFormatter: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <span
            role="presentation"
            onClick={() => handleSort("dimension")}
            className="sort-by "
            style={{
              color: sortOrder.dimension.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Dimension
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.dimension.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.duration",
      text: "Duration",
      columnName: "duration",
      hidden: hiddenColumns.duration,
      headerStyle: { width: "150px" },
      headerFormatter: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <span
            role="presentation"
            onClick={() => handleSort("duration")}
            className="sort-by "
            style={{
              color: sortOrder.duration.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Duration
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.duration.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.tags",
      text: "Tags",
      columnName: "tags",
      hidden: hiddenColumns.tags,
      headerStyle: { width: "150px" },
      formatter: (name, row) => (
        <div>
          {row.attributes.sponsor_tags &&
          row.attributes.sponsor_tags.length > 0 ? (
            <>
              <div className="contentTag">
                <ul>
                  <li>
                    {row.attributes.tags &&
                      row.attributes.tags.slice(0, 2).map((i, index) => (
                        <span
                          key={`tags-${row.id}`}
                          className="contentTag__badge"
                        >
                          {TextClipper(
                            row.id,
                            i,
                            8,
                            index,
                            "tags",
                            "contentTag__wrapper"
                          )}
                        </span>
                      ))}
                    <span className="tagmenu-icon">
                      {row.attributes.tags && row.attributes.tags.length > 2 && (
                        <PopOver id={`tags-${row.id}`}>
                          {row.attributes.tags.map(i => (
                            <span
                              key={`tags-${row.id}`}
                              className="contentTag__wrapper contentTag__badge"
                            >
                              {i}
                            </span>
                          ))}
                        </PopOver>
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="contentTag">
                <ul>
                  <li>
                    {row.attributes.sponsor_tags &&
                      row.attributes.sponsor_tags
                        .slice(0, 2)
                        .map((i, index) => (
                          <span
                            key={`sponserTags-${row.id}`}
                            className="contentTag__badge contentTag__badge--mod"
                          >
                            {TextClipper(
                              row.id,
                              i,
                              8,
                              index,
                              "sponsers",
                              "contentTag__wrapper"
                            )}
                          </span>
                        ))}
                    <span className="tagmenu-icon">
                      {row.attributes.sponsor_tags &&
                        row.attributes.sponsor_tags.length > 2 && (
                          <PopOver id={`sponserTags-${row.id}`}>
                            {row.attributes.sponsor_tags.map(i => (
                              <span
                                key={`sponserTags-${row.id}`}
                                className="contentTag__wrapper contentTag__badge contentTag__badge--mod"
                              >
                                {i}
                              </span>
                            ))}
                          </PopOver>
                        )}
                    </span>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <div className="contentTag">
              <ul>
                <li>
                  {row.attributes.tags &&
                    row.attributes.tags.slice(0, 2).map((i, index) => (
                      <span
                        key={`tags-${row.id}`}
                        className="contentTag__badge"
                      >
                        {TextClipper(
                          row.id,
                          i,
                          8,
                          index,
                          "tags",
                          "contentTag__wrapper"
                        )}
                      </span>
                    ))}
                  <span className="tagmenu-icon">
                    {row.attributes.tags && row.attributes.tags.length > 2 && (
                      <PopOver id={`tags-${row.id}`}>
                        {row.attributes.tags.map(i => (
                          <span
                            key={`tags-${row.id}`}
                            className="contentTag__wrapper contentTag__badge"
                          >
                            {i}
                          </span>
                        ))}
                      </PopOver>
                    )}
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      )
    },
    {
      dataField: "attributes.party_name",
      text: "Party",
      columnName: "party",
      hidden: hiddenColumns.party,
      headerStyle: { width: "150px" },
      headerAlign: "center",
      style: { textAlign: "center" },
      formatter: (party, row) => (
        <>
            <div className="contentTag">
              {row.attributes.party_name ? (
                <span className="content__titlename" role="presentation">
                  {party && party.length > 10 ? (
                    <Tooltip
                      item={
                        <span className="contentTag__badge">
                          {" "}
                          {`${party.slice(0, 10)}...`}{" "}
                        </span>
                      }
                      id={`party-${row.id}`}
                      tooltipContent={party}
                    />
                  ) : (
                    <span className={`contentTag__badge`}>{party}</span>
                  )}
                </span>
              ) : (
                <span
                  style={{
                    color: "#000",
                   fontSize: "28px"
                  }}
                >
                  -
                </span>
              )}
            </div>
        
        </>
      )
    },
    {
      dataField: "attributes.brand_name",
      text: "Brand",
      columnName: "brand",
      hidden: hiddenColumns.brand,
      headerStyle: { width: "150px" },
      headerAlign: "center",
      style: { textAlign: "center" },
      formatter: (brand, row) => (
        <>
      
            <div className="contentTag">
              {row.attributes.brand_name ? (
                <span className="content__titlename" role="presentation">
                  {brand && brand.length > 10 ? (
                    <Tooltip
                      item={
                        <span className="contentTag__badge contentTag__badge--mod">
                          {" "}
                          {`${brand.slice(0, 10)}...`}{" "}
                        </span>
                      }
                      id={`brand-${row.id}`}
                      tooltipContent={brand}
                    />
                  ) : (
                    <span
                      className={`contentTag__badge contentTag__badge--mod`}
                    >
                      {brand}
                    </span>
                  )}
                </span>
              ) : (
                <span
                  style={{
                    color: "#000",
                   fontSize: "28px"
                  }}
                >
                  -
                </span>
              )}
            </div>
        
        </>
      )
    },

    {
      dataField: "attributes.rate_per_minute",
      text: "Rate per minute",
      columnName: "rate_per_minute",
      hidden: hiddenColumns.rate_per_minute,
      headerStyle: { width: "110px" },
      headerAlign: "center",
      style: { textAlign: "center" },
      headerFormatter: () => (
        <div>
          <div>
            <span role="presentation" className="sort-by ">
              {" "}
              Rate per minute
              <br />
              (Nrs.)
            </span>
          </div>
        </div>
      ),
      formatter: (rate_per_minute, row) =>
      rate_per_minute  ? (
          <span
            role="presentation"
            className="sort-by "
            style={{
              color: "#000"
            }}
          >
            {rate_per_minute}
          </span>
        ) : (
          <span
            style={{
              color: "#000",
             fontSize: "28px"
            }}
          >
            -
          </span>
        )
    },

    {
      dataField: "attributes.created_at",
      text: "Date Created",
      columnName: "date_created",
      headerStyle: { width: "150px" },
      hidden: hiddenColumns.date_created,
      headerFormatter: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.created_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (date_created, row) => (
        <span
          role="presentation"
          className="sort-by "
          style={{
            color: "#000"
          }}
        >
          {moment
            .utc(date_created)
            .local()
            .format("MMM DD YYYY hh:mm A")}
        </span>
      )
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      columnName: "date_updated",
      headerStyle: { width: "150px" },
      hidden: hiddenColumns.date_updated,
      headerFormatter: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (date_updated, row) => (
        <span
          role="presentation"
          className="sort-by "
          style={{
            color: "#000"
          }}
        >
          {moment
            .utc(date_updated)
            .local()
            .format("MMM DD YYYY hh:mm A")}
        </span>
      )
    },

    {
      dataField: "actionButtons",
      text: "",
      columnName: "isSelected",
      style: { position: "relative" },
      headerStyle: { width: "150px" },
      formatter: (name, row) => (
        <div className="d-flex">
          <div className="contentSectionTable__menu">
            <i className="fas fa-ellipsis-h" />
          </div>
          <div className="contentSectionTable__actionButtonWrapper">
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.content.show}
            >
              <span
                // to={{
                // pathname: `/dashboard/content/show/${row.id}`,
                // params: { ...row, handleDelete, roles, permission }
                // }}
                className="contentSectionTable__actionButtonWrapper__buttons"
              >
                <Tooltip
                  item={
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "18px" }}
                      onClick={e => {
                        e.stopPropagation();
                        return handleContentEditAndView(true, "view", row);
                      }}
                    />
                  }
                  id={`view-${row.id}`}
                  tooltipContent="View"
                />
              </span>
            </RouteAllowed>
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.content.edit}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      className="fa fa-edit"
                      onClick={e => {
                        e.stopPropagation();
                        return handleContentEditAndView(true, "edit", row);
                      }}
                    />
                  }
                  id={`edit-${row.id}`}
                  tooltipContent="Edit"
                />
              </span>
            </RouteAllowed>
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.content.delete}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();
                        return handleDelete(row.id, row.attributes.name);
                      }}
                      className="fa fa-trash"
                    />
                  }
                  id={`delete-${row.id}`}
                  tooltipContent="Delete"
                />
              </span>
            </RouteAllowed>
            <RouteAllowed
              roles={roles && roles}
              permission={permission && permission.archive.edit}
            >
              <span className="contentSectionTable__actionButtonWrapper__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();
                        return handleArchiveItem(row.id, name);
                      }}
                      className="fa fa-archive"
                    />
                  }
                  id={`archive-${row.id}`}
                  tooltipContent="Archive"
                />
              </span>
            </RouteAllowed>
          </div>
        </div>
      )
    }
  ];
  let newHeaders = [];
  let featureAllowed = JSON.parse(window.localStorage.getItem("domain"))
    .is_party_brand_feature_enabled;
  headers.map(header => {
    if (header.columnName == "rate_per_minute") {
      const allowedRoutes = permission.content.rateAmountFieldAccess.filter(
        value => roles.includes(value)
      );
      if (allowedRoutes.length > 0) {
        newHeaders = headers;
      } else {
        newHeaders = headers.filter(
          headerForFilter => headerForFilter !== header
        );
      }
    }
    if (!featureAllowed) {
      newHeaders = newHeaders.filter(headerForFilter => {
        return !(
          headerForFilter.columnName === "party" ||
          headerForFilter.columnName === "brand" ||
          headerForFilter.columnName === "rate_per_minute"
        );
      });
    }
  });
  return newHeaders;
}
