import React from "react";
import moment from "moment";

import { toLower, startCase } from "lodash";
import { Tooltip } from "../../../core";

export function columns(hiddenColumns) {
  return [
    {
      dataField: "name",
      text: "Name",
      columnName: "name",
      hidden: hiddenColumns.name
    },
    {
      dataField: "status",
      text: "Status",
      columnName: "status",
      hidden: hiddenColumns.status
    },
    {
      dataField: "created_at",
      text: "Date Created",
      columnName: "created_at",
      hidden: hiddenColumns.created_at,
      formatter: (name, row) => (
        <div>
          <span>{name && moment(name).format("MM/DD/YYYY")}</span>
        </div>
      )
    },
    {
      dataField: "orientation",
      text: "Orientation",
      columnName: "orientation",
      hidden: hiddenColumns.orientation
    }
  ];
}
