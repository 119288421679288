import React from "react";
import { Button } from "reactstrap";
import SearchTagRefresh from "../../../core/form/searchtagrefresh";
import VisibleColumnToggler from "../../../helper/visibleColumnToggler";

const DomainUserHeader = props => (
  <div className="d-flex contentHeader">
    <div className="mr-auto">
      {(props.currentUserRolesDUH.includes("SuperAdmin") || props.editDUH) && (
        <Button
          className="primaryButton primaryButton--contentHeader"
          onClick={() => props.handleAddUserDUH(true)}
          type="button"
        >
          <i className="fa fa-plus mr-2" />
          Add User
        </Button>
      )}
    </div>
    <div className="d-flex flex-row-reverse">
      <SearchTagRefresh
        handleSearch={props.handleSearchValueDUH}
        placeholder="Search by Name"
        searchVal={props.searchValDUH}
        {...props}
      />
    </div>
    <VisibleColumnToggler
      hiddenColumns={props.hiddenColumns}
      toggleHiddenColumns={props.toggleHiddenColumns}
      columnToggleProps={props.columnToggleProps}
    />
  </div>
);

export default DomainUserHeader;
