import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { findIndex, remove } from "lodash";
import {
  AddPlaylistStudio,
  PlaylistContentSelector,
  PlaylistFormHeader
} from "../modules";

import { Resources as ContentResources } from "../../contents/redux";
import {
  convertToServerData,
  generateCreatePlaylistData,
  Resources
} from "../redux";
import { showNotification } from "../../../core/modal/toster";
import {
  playlistByWeightRestrictions,
  formValidator
} from "../forms/playlistFormValidator";
import DesktopBreakpoint from "../../../context/desktop_breakpoint";
import PhoneBreakpoint from "../../../context/phone_breakpoint";
import AddPlaylistMobileView from "./addPlaylistMobileView";

class AddPlaylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropZone: false,
      playlistTitle: "",
      playlistControl: {
        selectAll: false,
        restriction: false,
        date: false,
        weight: false
      },
      isSubmitable: true,
      weightHasError: false,
      random: false,
      popOne: true,
      isLoading: true
    };
  }

  setLoading = flag => {
    this.setState({ isLoading: flag });
  };

  setIsSubmtable = flag => {
    this.setState({
      isSubmitable: flag
    });
  };

  showDropZone = showDropZone => {
    this.setState({ showDropZone });
  };

  playlistTitleChange = playlistTitle => {
    this.setState({ playlistTitle });
  };

  playlistControlChange = playlistControl => {
    this.setState({ playlistControl });
  };

  playlistAttributeChange = (name, val) => {
    this.setState({ [name]: val });
  };

  getSelectAllValue = (value, name) => {
    if (name === "restriction") {
      return (
        this.state.playlistControl.date &&
        this.state.playlistControl.weight &&
        value
      );
    }
    if (name === "date") {
      return (
        this.state.playlistControl.restriction &&
        this.state.playlistControl.weight &&
        value
      );
    }
    return (
      this.state.playlistControl.date &&
      this.state.playlistControl.restriction &&
      value
    );
  };

  handleControl = (value, name) => {
    if (name === "selectAll") {
      const playlistControl = {
        [name]: value,
        restriction: value,
        date: value,
        weight: value
      };
      // TODO: if all of the playlist controls are un-checked, then uncheck the selectAll button
      this.playlistControlChange(playlistControl);
      return 0;
    }
    const playlistControl = {
      ...this.state.playlistControl,
      [name]: value,
      selectAll: this.getSelectAllValue(value, name)
    };
    this.playlistControlChange(playlistControl);
  };

  handleDragStart = (e, item) => {
    // e.dataTransfer.setData("text", "foo");
    this.props.showDropZoneFunc(true);
    const { contentToBeDropped } = this.props;
    if (contentToBeDropped.length === 0) {
      this.props.change("addPlaylistForm", "contentToBeDropped", [item]);
      this.props.change("addPlaylistForm", "selectedContent", [item]);
      return 0;
    }
    if (findIndex(contentToBeDropped, { id: item.id }) > -1) {
      this.props.change(
        "addPlaylistForm",
        "selectedContent",
        contentToBeDropped
      );
      return 0;
    }
    if (findIndex(contentToBeDropped, { id: item.id }) === -1) {
      const newList = [...contentToBeDropped, item];
      this.props.change("addPlaylistForm", "selectedContent", newList);
    }
  };

  handlePlaylistSave = async () => {
    const { isSubmitable } = this.state;
    if (
      this.state.playlistControl.weight &&
      this.props.playListData.length > 0
    ) {
      const checkValidation = playlistByWeightRestrictions(
        this.props.playListData
      );
      if (
        isSubmitable &&
        (!checkValidation.startvalidated ||
          !checkValidation.endvalidated ||
          !checkValidation.startHourValidated ||
          !checkValidation.endHourValidated)
      ) {
        this.props.showNotification(
          "The contents should be played on same date and time.",
          "danger"
        );
        return false;
      }
      if (isSubmitable && !checkValidation.weight) {
        this.props.showNotification(
          "Total Share of Voice must be equal to 100",
          "danger"
        );
        return false;
      }
    }
    // using this.props.playlistData check the validation once again
    this.props.playListData.forEach(row => {
      const requiredFields = {
        durationMinute: row.durationMinute,
        durationSecond: row.durationSecond,
        endDate: row.endDate,
        startDate: row.startDate,
        restrictEndHour: row.restrictEndHour,
        restrictStartHour: row.restrictStartHour,
        weight: row.weight
      };
      Object.keys(requiredFields).forEach(field => {
        if (requiredFields[field]) {
          const result = formValidator(
            requiredFields[field],
            field,
            requiredFields
          );
          this.setIsSubmtable(!result.error);
        }
      });
    });
    // then continue normally
    if (isSubmitable) {
      const playlistContents = convertToServerData(this.props.playListData);
      const { createPlaylist } = Resources;
      createPlaylist.body = generateCreatePlaylistData(
        playlistContents,
        this.state
      );
      const result = await this.props.Post(createPlaylist);
      if (result.status === 201) {
        this.props.history.push("/playlist");
      }
    } else {
      this.props.showNotification(
        "Playlist Content form must be inserted correctly.",
        "danger"
      );
      return false;
    }
  };

  render() {
    return (
      <div className="mainPage">
        <div className="contentSection campaignStudioWrapper">
          <DesktopBreakpoint>
            <PlaylistFormHeader
              playlistTitleChange={this.playlistTitleChange}
              createPlaylist
              playlistTitle={this.state.playlistTitle}
              handlePlaylistSave={this.handlePlaylistSave}
              updateText="Update "
              updateAndSyncText="Update and Sync "
            />
            <Field component="input" hidden type="text" name="playListData" />
            <Field
              component="input"
              hidden
              type="text"
              name="selectedContent"
            />{" "}
            <Row>
              <Col md="4" className="pr-0 ple_custom_height">
                <Card body>
                  {/* Playlist content selector i.e(Draggable zone) starts here */}
                  <PlaylistContentSelector
                    {...this.props}
                    showDropZoneFunc={this.showDropZone}
                    showDropZone={this.state.showDropZone}
                    isLoading={this.state.isLoading}
                    setLoading={this.setLoading}
                    playlistTextLimit={20}
                  />
                </Card>
              </Col>
              <AddPlaylistStudio
                list={this.props.list}
                showDropZone={this.state.showDropZone}
                playListData={this.props.playListData}
                showDropZoneFunc={this.showDropZone}
                random={this.state.random}
                popOne={this.state.popOne}
                playlistAttributeChange={this.playlistAttributeChange}
                selectedContent={this.props.selectedContent}
                playlistControlChange={this.playlistControlChange}
                playlistControl={this.state.playlistControl}
                handleControl={this.handleControl}
                {...this.state}
                setIsSubmtable={this.setIsSubmtable}
              />
              {/* Right part of Add Playlist i.e.(Droppable Zone) starts here */}
            </Row>
          </DesktopBreakpoint>
          <PhoneBreakpoint>
            <PlaylistFormHeader
              playlistTitleChange={this.playlistTitleChange}
              createPlaylist
              playlistTitle={this.state.playlistTitle}
              handlePlaylistSave={this.handlePlaylistSave}
              updateText="Update"
              updateAndSyncText="Update and Sync"
            />
            <Field component="input" hidden type="text" name="playListData" />
            <Field
              component="input"
              hidden
              type="text"
              name="selectedContent"
            />{" "}
            <Row>
              <AddPlaylistMobileView
                isLoading={this.state.isLoading}
                setLoading={this.setLoading}
                list={this.props.list}
                playListData={this.props.playListData}
                random={this.state.random}
                popOne={this.state.popOne}
                playlistAttributeChange={this.playlistAttributeChange}
                selectedContent={this.props.selectedContent}
                playlistControlChange={this.playlistControlChange}
                playlistControl={this.state.playlistControl}
                handleControl={this.handleControl}
                {...this.state}
                {...this.props}
                setIsSubmtable={this.setIsSubmtable}
                handleDragStart={this.handleDragStart}
              />
            </Row>
          </PhoneBreakpoint>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("addPlaylistForm");
  const { list } = state.content;

  return {
    list: list || [],
    selectedContent: selector(state, "selectedContent") || [],
    playListData: selector(state, "playListData") || [],
    contentToBeDropped: selector(state, "contentToBeDropped"),
    rateAmountRestriction: JSON.parse(
      window.localStorage.getItem("domain") !== "undefined"
        ? window.localStorage.getItem("domain")
        : null
    ).is_content_rate_enabled,
    featureAllowed: JSON.parse(window.localStorage.getItem("domain"))
      .is_party_brand_feature_enabled
  };
}

const addPlaylistForm = reduxForm({
  form: "addPlaylistForm"
})(AddPlaylist);

export default connect(
  mapStateToProps,
  { showNotification, change }
)(addPlaylistForm);
