import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";

class DonutChart extends Component {
  static defaultProps = {
    // displayTitle: true,
    displayLegend: false,
    legendPosition: "right",
    location: "City"
  };

  constructor(props) {
    super(props);
    // const image = parseFloat(props.image.split(" ")[0]);
    // const video = parseFloat(props.video.split(" ")[0]);
    this.state = {
      chartData: {
        labels: ["Image", "Video"],
        datasets: [
          {
            label: "Library",
            data: [props.image, props.video],
            backgroundColor: ["#FFDA83", "#55D8FE"]
          }
        ]
      }
    };
  }

  render() {
    return (
      <div className="chart">
        <Doughnut
          data={this.state.chartData}
          // width={400}
          // height={241}
          options={{
            tooltips: false,
            layout: {
              padding: {
                // left: 0,
                // right: 25,
                // top: 20,
                // bottom: 0
              }
            },
            legend: {
              display: this.props.displayLegend,
              position: this.props.legendPosition
            },
            responsive: true,
            maintainAspectRatio: true
          }}
        />
      </div>
    );
  }
}

export default DonutChart;
