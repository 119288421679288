import Server from "./api";
import S3 from "./aws";
import { showNotification } from "../core/modal/toster";

const ServerConnector = new Server();
const s3 = new S3();

function success(actions, result, endPoint) {
  if (process.env.NODE_ENV === "development") {
    console.info(`Success with; ${endPoint}`);
  }

  return {
    type: actions.success,
    payload: result
  };
}

function error(actions, result, endPoint) {
  if (process.env.NODE_ENV === "development") {
    console.error(`Error on ${endPoint}`);
  }
  return {
    type: actions.error,
    payload: result
  };
}

function requested(actions, endPoint) {
  return {
    type: actions.init
  };
}

function successNotification(result, resources, notificationText, dispatch) {
  if (result.data && result.data.success && result.data.success.message) {
    if (resources.asyncActions.successMsg !== "nomsg") {
      dispatch(showNotification(result.data.success.message, "success"));
    }
  } else if (resources.asyncActions.successMsg) {
    if (resources.asyncActions.successMsg !== "nomsg") {
      dispatch(showNotification(resources.asyncActions.successMsg, "success"));
    }
  } else {
    dispatch(showNotification(notificationText, "success"));
  }
}

function errorNotification(result, resources, notificationText, dispatch) {
  if (result.data && result.data.error && result.data.error.message) {
    if (resources.asyncActions.errorMsg !== "nomsg") {
      dispatch(showNotification(result.data.error.message, "danger"));
    }
  } else if (resources.asyncActions.errorMsg) {
    if (resources.asyncActions.errorMsg !== "nomsg") {
      dispatch(showNotification(resources.asyncActions.errorMsg, "danger"));
    }
  } else {
    dispatch(showNotification(notificationText, "danger"));
  }
}

function Get(resources) {
  return async (dispatch, getState) => {
    const { token = undefined } = getState().userDetails;
    dispatch(requested(resources.asyncActions, resources.url));
    const result = await ServerConnector.get(resources.url, token).catch(
      e => e
    );
    if (result.status === 204) {
      dispatch(error(resources.asyncActions, result, resources.url));
      return result;
    }
    if (result.status === 403) {
      dispatch(error(resources.asyncActions, result, resources.url));
      return result;
    }
    if (result.status < 300) {
      dispatch(success(resources.asyncActions, result, resources.url));
      resources.asyncActions.successMsg &&
        successNotification(result, resources, "", dispatch);
      return result;
    }

    if (result.status >= 500 && result.status < 600) {
      if (result.status === 500) {
        errorNotification(result, resources, "Something Went Wrong!", dispatch);
      }
      dispatch(error(resources.asyncActions, result, resources.url));
      return result;
    }
    if (result.status === 401) {
      successNotification(result, resources, "Unauthorized Access", dispatch);
      window.localStorage.clear();
      dispatch({ type: "LOGOUT" });
      window.location.replace("/");
      return result;
    }

    if (result.status >= 400 && result.status < 499) {
      if (result.status !== 450) {
        errorNotification(
          result,
          resources,
          "Error 400! Couldn't Create",
          dispatch
        );
      }
      dispatch(error(resources.asyncActions, result, resources.url));
      return result;
    }
    dispatch(error(resources.asyncActions, result, resources.url));
    return result;
  };
}

function Post(resources) {
  return async (dispatch, getState) => {
    dispatch(requested(resources.asyncActions, resources.url));
    const { token = undefined } = getState().userDetails;
    const result = await ServerConnector.post(
      resources.url,
      resources.body,
      token
    ).catch(e => e);
    if (result.status < 300) {
      dispatch(success(resources.asyncActions, result, resources.url));
      resources.asyncActions.successMsg &&
        successNotification(result, resources, "Created ✓", dispatch);
      return result;
    }

    if (result.status >= 500 && result.status < 600) {
      dispatch(error(resources.asyncActions, result, resources.url));
      dispatch(showNotification("Error 500! Couldn't Create", "danger"));
      return result;
    }

    if (result.status >= 400 && result.status < 499) {
      dispatch(error(resources.asyncActions, result, resources.url));
      errorNotification(
        result,
        resources,
        "Error 400! Couldn't Create",
        dispatch
      );
      return result;
    }

    if (result.status === 401) {
      dispatch(error(resources.asyncActions, result, resources.url));
      dispatch(showNotification("Error 401! Couldn't Authorized", "danger"));
      return result;
    }

    dispatch(showNotification("Error 400! Couldn't Create", "danger"));
    dispatch(error(resources.asyncActions, result, resources.url));
    return result;
  };
}

function Put(resources) {
  return async (dispatch, getState) => {
    dispatch(requested(resources.asyncActions, resources.url));
    const { token = undefined } = getState().userDetails;
    const result = await ServerConnector.put(
      resources.url,
      resources.body,
      token
    ).catch(e => e);
    if (result.status < 300) {
      dispatch(success(resources.asyncActions, result, resources.url));
      successNotification(result, resources, "Updated ✓", dispatch);
      return result;
    }

    if (result.status >= 500 && result.status < 600) {
      dispatch(error(resources.asyncActions, result, resources.url));
      dispatch(showNotification("Error 500! Couldn't Update", "danger"));
      return result;
    }

    if (result.status >= 400 && result.status < 499) {
      dispatch(error(resources.asyncActions, result, resources.url));
      dispatch(error(resources.asyncActions, result, resources.url));
      errorNotification(
        result,
        resources,
        "Error 400! Couldn't Update",
        dispatch
      );
      return result;
    }

    dispatch(error(resources.asyncActions, result, resources.url));
    dispatch(showNotification("Error 400! Couldn't Update", "danger"));
    return result;
  };
}

function Patch(resources) {
  return async (dispatch, getState) => {
    dispatch(requested(resources.asyncActions, resources.url));
    const { token = undefined } = getState().userDetails;
    const result = await ServerConnector.patch(
      resources.url,
      resources.body,
      token
    ).catch(e => e);
    if (result.status < 300) {
      dispatch(success(resources.asyncActions, result, resources.url));
      successNotification(result, resources, "Updated ✓", dispatch);
      return result;
    }

    if (result.status >= 500 && result.status < 600) {
      dispatch(error(resources.asyncActions, result, resources.url));
      dispatch(showNotification("Error 500! Couldn't Update", "danger"));
      return result;
    }

    if (result.status === 422) {
      dispatch(success(resources.asyncActions, result, resources.url));
      errorNotification(
        result,
        resources,
        "Error 400! Couldn't Update",
        dispatch
      );

      return result;
    }

    if (result.status >= 400 && result.status < 499) {
      dispatch(error(resources.asyncActions, result, resources.url));
      dispatch(error(resources.asyncActions, result, resources.url));
      errorNotification(
        result,
        resources,
        "Error 400! Couldn't Update",
        dispatch
      );
      return result;
    }

    dispatch(error(resources.asyncActions, result, resources.url));
    dispatch(showNotification("Error 400! Couldn't Update", "danger"));
    return result;
  };
}

function Delete(resources) {
  return async (dispatch, getState) => {
    dispatch(requested(resources.asyncActions, resources.url));
    const { token = undefined } = getState().userDetails;
    const result = await ServerConnector.delete(resources.url, token).catch(
      e => e
    );
    if (result.status < 300) {
      dispatch(success(resources.asyncActions, result, resources.url));
      successNotification(result, resources, "Deleted ✓", dispatch);
      return result;
    }

    if (result.status >= 500 && result.status < 600) {
      dispatch(error(resources.asyncActions, result, resources.url));
      dispatch(showNotification("Error! Couldn't Delete", "danger"));
      return result;
    }

    if (result.status >= 400 && result.status < 499) {
      dispatch(error(resources.asyncActions, result, resources.url));
      errorNotification(
        result,
        resources,
        "Error 400! Couldn't Delete",
        dispatch
      );
      return result;
    }

    dispatch(error(resources.asyncActions, result, resources.url));
    dispatch(showNotification(result.data.error.message, "danger"));
    return result;
  };
}

function BulkDelete(resources) {
  return async (dispatch, getState) => {
    dispatch(requested(resources.asyncActions, resources.url));
    const { token = undefined } = getState().userDetails;
    const result = await ServerConnector.bulkDelete(
      resources.url,
      resources.body,
      token).catch(
        e => e
      );
    if (result.status < 300) {
      dispatch(success(resources.asyncActions, result, resources.url));
      successNotification(result, resources, "Deleted ✓", dispatch);
      return result;
    }

    if (result.status >= 500 && result.status < 600) {
      dispatch(error(resources.asyncActions, result, resources.url));
      dispatch(showNotification("Error! Couldn't Delete", "danger"));
      return result;
    }

    if (result.status >= 400 && result.status < 499) {
      dispatch(error(resources.asyncActions, result, resources.url));
      errorNotification(
        result,
        resources,
        "Error 400! Couldn't Delete",
        dispatch
      );
      return result;
    }

    dispatch(error(resources.asyncActions, result, resources.url));
    dispatch(showNotification(result.data.error.message, "danger"));
    return result;
  };
}


const S3UPLOAD = s3.upload;
const S3URL = s3.getUrl;
const S3Delete = s3.delete;
const S3EDIT = s3.put;

export { Get, Post, Put, Patch, Delete, BulkDelete, S3UPLOAD, S3URL, S3Delete, S3EDIT };
