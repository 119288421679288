import React, { Component } from "react";
import { Tooltip } from "../../../core";

export const MonitorControl = props => (
  <div style={{ paddingBottom: "163px" }}>
    <div className="row mt-3">
      <div className="col-md-2 informationBlock">
        <i className="mt-2">Brightness</i>
      </div>
      <div className="col-md-10 btn-group">
        <Tooltip
          tooltipContent="Set brightness to 25%"
          id="Brightness25"
          item={
            <button
              className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
              type="button"
              onClick={e => props.triggerRS232Command("BRIGHTNESS25")}
            >
              25%
            </button>
          }
        />
        <Tooltip
          tooltipContent="Set brightness to 50%"
          id="Brightness50"
          item={
            <button
              className="btn-outline-secondary buttonGroupOutline"
              type="button"
              onClick={e => props.triggerRS232Command("BRIGHTNESS50")}
            >
              50%
            </button>
          }
        />
        <Tooltip
          tooltipContent="Set brightness to 75%"
          id="Brightness75"
          item={
            <button
              className="btn-outline-secondary buttonGroupOutline"
              type="button"
              onClick={e => props.triggerRS232Command("BRIGHTNESS75")}
            >
              75%
            </button>
          }
        />
        <Tooltip
          tooltipContent="Full brightness"
          id="FullBrightness"
          item={
            <button
              className="btn-outline-secondary btn-outline-secondary--rightSideBorder buttonGroupOutline"
              type="button"
              onClick={e => props.triggerRS232Command("BRIGHTNESS100")}
            >
              100%
            </button>
          }
        />
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-2 informationBlock">
        <i className="mt-2">Volume</i>
      </div>
      <div className="col-md-10 btn-group">
        <Tooltip
          tooltipContent="Set volume to 25%"
          id="Volume25"
          item={
            <button
              type="button"
              className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
              onClick={e => props.triggerRS232Command("VOL25")}
            >
              25%
            </button>
          }
        />
        <Tooltip
          tooltipContent="Set volume to 50%"
          id="Volume50"
          item={
            <button
              type="button"
              className="btn-outline-secondary buttonGroupOutline"
              onClick={e => props.triggerRS232Command("VOL50")}
            >
              50%
            </button>
          }
        />
        <Tooltip
          tooltipContent="Set volume to 75%"
          id="Volume75"
          item={
            <button
              type="button"
              className="btn-outline-secondary buttonGroupOutline"
              onClick={e => props.triggerRS232Command("VOL75")}
            >
              75%
            </button>
          }
        />
        <Tooltip
          tooltipContent="Set Full Volume"
          id="Volume100"
          item={
            <button
              type="button"
              className="btn-outline-secondary btn-outline-secondary--rightSideBorder buttonGroupOutline"
              onClick={e => props.triggerRS232Command("VOL100")}
            >
              100%
            </button>
          }
        />
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-2 informationBlock">
        <i className="mt-2">HDMI</i>
      </div>
      <div className="col-md-10 btn-group">
        <Tooltip
          tooltipContent="Set HDMI 1"
          id="HDMI1"
          item={
            <button
              type="button"
              className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
              onClick={e => props.triggerRS232Command("HDMI1")}
            >
              HDMI 1
            </button>
          }
        />
        <Tooltip
          tooltipContent="Set HDMI 2"
          id="HDMI2"
          item={
            <button
              type="button"
              className="btn-outline-secondary buttonGroupOutline"
              onClick={e => props.triggerRS232Command("HDMI2")}
            >
              HDMI 2
            </button>
          }
        />
        <Tooltip
          tooltipContent="Set HDMI 3"
          id="HDMI3"
          item={
            <button
              type="button"
              className="btn-outline-secondary btn-outline-secondary--rightSideBorder buttonGroupOutline"
              onClick={e => props.triggerRS232Command("HDMI3")}
            >
              HDMI 3
            </button>
          }
        />
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-2 informationBlock">
        <i className="mt-2">Control</i>
      </div>
      <div className="col-md-10 btn-group">
        <button
          type="button"
          className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
          onClick={e => props.triggerRS232Command("ZOOM")}
        >
          Zoom In
        </button>
        <button
          type="button"
          className="btn-outline-secondary buttonGroupOutline"
          onClick={e => props.triggerRS232Command("SURROUNDED")}
        >
          Zoom Out
        </button>
        <button
          type="button"
          className="btn-outline-secondary buttonGroupOutline"
          onClick={e => props.triggerRS232Command("PMODE")}
        >
          PMODE
        </button>

        <button
          type="button"
          className="btn-outline-secondary btn-outline-secondary--rightSideBorder buttonGroupOutline"
          onClick={e => props.triggerRS232Command("SURROUNDED")}
        >
          SURROUNDED
        </button>
      </div>
    </div>

    <div className="row mt-3">
      <div className="col-md-2 informationBlock">
        <i className="mt-2">Power</i>
      </div>
      <div className="col-md-10 btn-group">
        <button
          type="button"
          className="btn-outline-secondary btn-outline-secondary--sideBorder buttonGroupOutline"
          onClick={e => props.triggerRS232Command("ZOOM")}
        >
          On
        </button>
        <button
          type="button"
          className="btn-outline-secondary btn-outline-secondary--rightSideBorder buttonGroupOutline"
          onClick={e => props.triggerRS232Command("SURROUNDED")}
        >
          Off
        </button>
      </div>
    </div>
    {/* <div className="row mt-3">
      <div className="col-md-2 informationBlock"><i>USB</i></div>
      <div className="col-md-10 btn-group">
      <button
      type="button"
      className="btn-outline-secondary "
      onClick={e => props.triggerRS232Command("ZOOM")}>
          On
        </button>

      </div>
    </div>
    */}
  </div>
);
