/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import {
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card
} from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { debounce } from "lodash";
import MediaTab from "./mediaTab";
import { Resources as ContentResources } from "../../contents/redux";
import TabSearch from "./TabSearch";

class PlaylistContentSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      tabID: [{ id: "1", TabName: "Media" }],
      dropdownOpen: false,
      filterdropdownOpen: false,
      searchVal: { value: "", url: "" },
      contentSearchByName: "",
      contentSearchByTag: "",
      contentFilter: "",
      contentSort: "",
      sortOrder: "",
      per_page: 20,
      fetch: true
    };
    this.clearTagRef = React.createRef();
    this.loadContent();
    this.props.setLoading(true);
  }

  loadContent = () => {
    // this.props.setLoading(true);
    ContentResources.getContent.url = "/contents?page=1&per_page=20";
    this.props.Get(ContentResources.getContent).then(response => {
      this.props.setLoading(false);
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.toggleFetch(false);
      }
    });
  };

  increasePerPage = () => {
    this.setState(prevState => ({
      per_page: prevState.per_page + 20
    }));
  };

  inputSearchName = name => {
    this.setState({
      contentSearchByName: name,
      fetch: true,
      contentSearchByTag: "",
      contentFilter: "",
      contentSort: "",
      per_page: 20
    });
  };

  inputSearchTag = (tags, type) => {
    this.setState({
      contentSearchByTag: tags,
      fetch: true,
      contentSearchByName: "",
      selectedOptionType: type,
      contentFilter: "",
      contentSort: "",
      per_page: 20
    });
  };

  inputFilter = type => {
    this.setState({
      contentFilter: type,
      fetch: true,
      contentSearchByName: "",
      contentSearchByTag: "",
      contentSort: "",
      per_page: 20
    });
  };

  inputSort = (sort, order) => {
    this.setState({
      contentSort: sort,
      sortOrder: order,
      fetch: true,
      contentSearchByName: "",
      contentSearchByTag: "",
      contentFilter: "",
      per_page: 20
    });
  };

  toggleFetch = flag => {
    if (flag === true) {
      this.setState({
        contentSearchByName: "",
        contentSearchByTag: "",
        contentFilter: "",
        contentSort: "",
        per_page: 20
      });
    }
    this.setState({
      fetch: flag
    });
  };

  toggleDropDown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  togglefilter = () => {
    this.setState(prevState => ({
      filterdropdownOpen: !prevState.filterdropdownOpen
    }));
  };

  handleSearch = debounce(() => {
    const { getContent } = ContentResources;
    if (this.state.searchVal.value === "") {
      getContent.url = `/contents?page=1&per_page=20`;
    } else {
      if (!this.state.searchVal.url) {
        getContent.url = `/contents/search?search=${this.state.searchVal.value}&page=1&per_page=20`;
        this.props.Get(ContentResources.getContent).then(response => {
          this.props.setLoading(false);
          if (response.data.meta.totalPages <= response.data.meta.page) {
            this.toggleFetch(false);
          }
        });
        return;
      }
      if (this.state.searchVal.type === "Sponsors") {
        getContent.url = `/contents/search_sponsor_tag?sponsor_tags=${this.state.searchVal.value}&page=1&per_page=20`;
      } else if (this.state.searchVal.type === "Party") {
        getContent.url = `/contents/search_by_party?party_tags=${this.state.searchVal.value}&page=1&per_page=20`;
      } else if (this.state.searchVal.type === "Brand") {
        getContent.url = `/contents/search_by_brand?brand_tags=${this.state.searchVal.value}&page=1&per_page=20`;
      } else {
        getContent.url = `/contents/search_tag?tags=${this.state.searchVal.value}&page=1&per_page=20`;
      }
    }
    this.props.Get(ContentResources.getContent).then(response => {
      this.props.setLoading(false);
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.toggleFetch(false);
      }
    });
  }, 3000);

  handleSearchValue = (value, url = false, type = "") => {
    this.props.setLoading(true);
    this.setState({ searchVal: { value, url, type } }, () => {
      this.handleSearch();
    });
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  handleFilter = filter => {
    if (this.clearTagRef.current.searchByTagRef) {
      this.clearTagRef.current.searchByTagRef.current.state.value = "";
      this.clearTagRef.current.state.searchTag = [];
      this.clearTagRef.current.state.showClearButtonMedia = false;
    }
    this.props.setLoading(true);
    const { getContent } = ContentResources;
    filter === "true" ? getContent.url = `/contents/?is_filler_content=${filter}&page=1&per_page=20`:getContent.url = `/contents/?type=${filter}&page=1&per_page=20`;
    this.props.Get(ContentResources.getContent).then(response => {
      this.props.setLoading(false);
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.toggleFetch(false);
      }
    });
  };

  handleSort = (value, order) => {
    this.inputSort(value, order);
    this.props.setLoading(true);
    const { getContent } = ContentResources;
    getContent.url = `/contents?sort=${value}&sort_by=${order}&page=1&per_page=20`;
    this.props.Get(ContentResources.getContent).then(response => {
      this.props.setLoading(false);
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.toggleFetch(false);
      }
    });
  };

  TabMapper = item => (
    <NavItem key={item.id}>
      <NavLink
        style={{ cursor: "default" }}
        className={classnames({
          active: this.state.activeTab === item.id
        })}
        onClick={() => {
          this.toggle(item.id);
        }}
      >
        {item.TabName}
      </NavLink>
    </NavItem>
  );

  render() {
    const { tabID, activeTab } = this.state;
    return (
      <div className="playlist_studio campaignStudioWrapper__mediaWrapper">
        <Nav tabs>
          {/* Nav Item Navigation List */}
          {tabID.map(item => this.TabMapper(item))}
          {/* Nav Item Navigation List End Here */}

          <div className="ml-auto">
            <div>
              {/* Sort Element Start Here */}
              <Dropdown
                className="d-inline-block"
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleDropDown}
              >
                <DropdownToggle className="btn btn-outline-primary media-sort">
                  <i className="bx bx-filter " />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header>Filter By</DropdownItem>

                  <DropdownItem>
                    <span
                      role="presentation"
                      disabled
                      onClick={() => {
                        this.handleFilter("");
                        this.inputFilter("");
                      }}
                    >
                      All{" "}
                    </span>
                  </DropdownItem>
                  <DropdownItem>
                    <span
                      role="presentation"
                      onClick={() => {
                        this.handleFilter("video");
                        this.inputFilter("video");
                      }}
                    >
                      Video{" "}
                    </span>
                  </DropdownItem>
                  <DropdownItem>
                    <span
                      role="presentation"
                      onClick={() => {
                        this.handleFilter("image");
                        this.inputFilter("image");
                      }}
                    >
                      Image
                    </span>
                  </DropdownItem>
                  <DropdownItem>
                    <span
                      role="presentation"
                      onClick={() => {
                        this.handleFilter("audio");
                        this.inputFilter("audio");
                      }}
                    >
                      Audio
                    </span>
                  </DropdownItem>
                  <DropdownItem>
                    <span
                      role="presentation"
                      onClick={() => {
                        this.handleFilter("true");
                        this.inputFilter("true");
                      }}
                    >
                      Filler
                    </span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem header>Sort By</DropdownItem>
                  <DropdownItem>
                    <span
                      role="presentation"
                      disabled
                      onClick={() => this.handleSort("name", "asc")}
                    >
                      Name{" "}
                    </span>
                  </DropdownItem>
                  <DropdownItem>
                    <span
                      role="presentation"
                      onClick={() => this.handleSort("updated_at", "desc")}
                    >
                      Date added
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </Nav>
        <TabSearch
          ref={this.clearTagRef}
          inputSearchName={this.inputSearchName}
          inputSearchTag={this.inputSearchTag}
          toggleFetch={this.toggleFetch}
          {...this.props}
          handleSearch={this.handleSearchValue}
        />
        <TabContent
          activeTab={activeTab}
          className="tab-content--media-tab remove-overflow"
        >
          <MediaTab
            isLoading={this.props.isLoading}
            fetch={this.state.fetch}
            perPage={this.state.per_page}
            increasePerPage={this.increasePerPage}
            toggleFetch={this.toggleFetch}
            contentSearchByName={this.state.contentSearchByName}
            contentSearchByTag={this.state.contentSearchByTag}
            contentFilter={this.state.contentFilter}
            contentSort={this.state.contentSort}
            sortOrder={this.state.sortOrder}
            selectedOptionType={this.state.selectedOptionType}
            handleDragStart={this.props.handleDragStart}
            playlistTextLimit={this.props.playlistTextLimit}
            {...this.props}
          />
        </TabContent>
      </div>
    );
  }
}

export default connect(state => ({
  ContentTag: state.ContentTag.list
    ? state.ContentTag.list.attributes.tags
    : [],
  SponserTag: state.SponserTag.list ? state.SponserTag.list : [],
  PartyTagList: state.PartyTagList.partyList
    ? state.PartyTagList.partyList.attributes.tags
    : [],
  BrandTagList: state.BrandTagList.brandList
    ? state.BrandTagList.brandList.attributes.tags
    : []
}))(PlaylistContentSelector);
