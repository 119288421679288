import { Map } from "../../helper/routeMap";

/** Contents */
import Contents from "./contents";
import UploadContent from "./pages/contentUpload";
import AddContent from "./pages/addContent";
import EditContent from "./pages/editContent";
import ArchiveContent from "./pages/archiveContent";
import ContentDetail from "./pages/contentDetail";
import NoRoute, { UpcomingFeatures } from "../../core/layout/noroutes";

const mainPath = "/content";
const pathCreator = "/content";
const subPathCreator = subPath => `${pathCreator}${subPath}`;
const feature = "Content";

export default [
  {
    path: subPathCreator("/show/:id"),
    component: Map(ContentDetail),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/edit/:id"),
    component: Map(EditContent),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/upload"),
    component: Map(UploadContent),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/create"),
    component: Map(AddContent),
    exact: true,
    secured: true,
    feature
  },
  {
    path: subPathCreator("/archive"),
    component: Map(ArchiveContent),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}`,
    component: Map(Contents),
    exact: true,
    secured: true
  },

  {
    path: `${pathCreator}/upcoming`,
    component: Map(UpcomingFeatures),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/*`,
    component: Map(NoRoute),
    exact: true,
    secured: true,
    feature
  }
];
