/* eslint-disable react/sort-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-multi-comp */
import React from "react";
import { Transformer } from "react-konva";

export default class TransformerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      handleZero: undefined
    };
  }

  componentDidMount() {
    this.checkNode();
  }

  componentDidUpdate() {
    this.checkNode();
  }

  checkNode() {
    // here we need to manually attach or detach Transformer node
    const stage = this.transformer.getStage();
    const { selectedShapeName } = this.props;

    // this.transformer.boundBoxFunc();

    const selectedNode = stage.findOne(`.${selectedShapeName}`);
    // do nothing if selected node is already attached
    if (selectedNode === this.transformer.node()) {
      return;
    }

    if (selectedNode) {
      // attach to another node
      this.transformer.rotateEnabled(false);
      this.transformer.attachTo(selectedNode);
    } else {
      // remove transformer
      this.transformer.detach();
    }
    this.transformer.getLayer().batchDraw();
  }

  boundBoxFunc = (oldBoundBox, newBoundBox) => {
    const { selectedShapeName, campaign } = this.props;
    const totalScreens = campaign.screens;
    const edgeDetection = 16;
    const width = this.props.campaign.studioWidth + 1;
    const height = this.props.campaign.studioHeight + 1;
    const temp = newBoundBox;
    const formattedScreen = totalScreens.filter(
      item => item.localID !== selectedShapeName
    );
    let i = 0;

    if (newBoundBox.x < 0 || newBoundBox.y < 0) {
      this.props.handleTransformationChange(oldBoundBox);
      return oldBoundBox;
    }

    if (newBoundBox.x + newBoundBox.width > width) {
      this.props.handleTransformationChange(oldBoundBox);
      return oldBoundBox;
    }

    if (newBoundBox.y + newBoundBox.height > height) {
      this.props.handleTransformationChange(oldBoundBox);
      return oldBoundBox;
    }

    if (newBoundBox.width < 5 || newBoundBox.height < 5) {
      this.setState({ handleZero: oldBoundBox });
      this.props.handleTransformationChange(oldBoundBox);
      return oldBoundBox;
    }
    if (isNaN(newBoundBox.width) || isNaN(newBoundBox.height)) {
      const { handleZero } = this.state;
      const formattedValue = handleZero;
      if (formattedValue.height <= 0) {
        formattedValue.height = 1;
      } else if (formattedValue.width <= 0) {
        formattedValue.width = 1;
      }

      this.props.handleTransformationChange(formattedValue);
      return handleZero;
    }

    /* -------- Snap------ */
    if (newBoundBox.x > 0 && newBoundBox.x <= edgeDetection) {
      temp.x = 0;
      temp.width = Math.abs(oldBoundBox.x + oldBoundBox.width);
      temp.snappedObject = true;
      this.props.handleTransformationChange(temp);
      return temp;
    }

    if (newBoundBox.y > 0 && newBoundBox.y <= edgeDetection) {
      temp.y = 0;
      temp.height = Math.abs(oldBoundBox.y + oldBoundBox.height);
      temp.snappedObject = true;
      this.props.handleTransformationChange(temp);
      return temp;
    }

    if (
      newBoundBox.x + newBoundBox.width < this.props.campaign.studioWidth &&
      Math.abs(width - (newBoundBox.width + newBoundBox.x)) <= edgeDetection
    ) {
      temp.width = this.props.campaign.studioWidth - temp.x;
      temp.snappedObject = true;
      this.props.handleTransformationChange(temp);
      return temp;
    }

    if (
      newBoundBox.y + newBoundBox.height < this.props.campaign.studioHeight &&
      Math.abs(height - (newBoundBox.height + newBoundBox.y)) <= edgeDetection
    ) {
      temp.height = this.props.campaign.studioHeight - temp.y;
      temp.snappedObject = true;
      this.props.handleTransformationChange(temp);
      return temp;
    }

    while (i < formattedScreen.length) {
      if (formattedScreen.length > 0) {
        const activeObjectXEnd = Math.abs(
          oldBoundBox.x + oldBoundBox.width - formattedScreen[i].screenX
        );
        const activeObjectYEnd = Math.abs(
          oldBoundBox.y + oldBoundBox.height - formattedScreen[i].screenY
        );
        const targetObjectXEnd = Math.abs(
          formattedScreen[i].screenX + formattedScreen[i].width - oldBoundBox.x
        );
        const targetObjectYEnd = Math.abs(
          formattedScreen[i].screenY + formattedScreen[i].height - oldBoundBox.y
        );
        const targetObjectAllXEnd = Math.abs(
          formattedScreen[i].screenX +
            formattedScreen[i].width -
            (oldBoundBox.x + oldBoundBox.width)
        );
        const targetObjectAllYEnd = Math.abs(
          formattedScreen[i].screenY +
            formattedScreen[i].height -
            (oldBoundBox.y + oldBoundBox.height)
        );

        const checkXEnd = Math.abs(oldBoundBox.x - formattedScreen[i].screenX);
        const checkYEnd = Math.abs(oldBoundBox.y - formattedScreen[i].screenY);

        const checkWidth = Math.abs(temp.x - formattedScreen[i].screenX);
        const checkHeight = Math.abs(temp.y - formattedScreen[i].screenY);
        const checkactiveObjectXEnd = Math.abs(
          temp.x + temp.width - formattedScreen[i].screenX
        );
        const checkactiveObjectYEnd = Math.abs(
          temp.y + temp.height - formattedScreen[i].screenY
        );
        const checkTargetAllEndWidth = Math.abs(
          formattedScreen[i].screenX +
            formattedScreen[i].width -
            (temp.x + temp.width)
        );
        const checkTargetAllEndHeight = Math.abs(
          formattedScreen[i].screenY +
            formattedScreen[i].height -
            (temp.y + temp.height)
        );
        const checktargetObjectXEnd = Math.abs(
          formattedScreen[i].screenX + formattedScreen[i].width - temp.x
        );
        const checktargetObjectYEnd = Math.abs(
          formattedScreen[i].screenY + formattedScreen[i].height - temp.y
        );
        /* --active object X end & Y end point w target Object X and Y -- */

        if (
          checkactiveObjectXEnd <= edgeDetection &&
          activeObjectXEnd <= edgeDetection
        ) {
          if (checkactiveObjectXEnd !== 0) {
            temp.width = formattedScreen[i].screenX - oldBoundBox.x;
            temp.snappedObject = true;
          }
        }
        if (
          checkactiveObjectYEnd <= edgeDetection &&
          activeObjectYEnd <= edgeDetection
        ) {
          if (checkactiveObjectYEnd !== 0) {
            temp.height = formattedScreen[i].screenY - oldBoundBox.y;
            temp.snappedObject = true;
          }
        }
        /** --active object X and Y with target object  X end & Y end  */
        if (
          checktargetObjectXEnd <= edgeDetection &&
          targetObjectXEnd <= edgeDetection
        ) {
          if (checktargetObjectXEnd !== 0) {
            temp.x = formattedScreen[i].screenX + formattedScreen[i].width;
            temp.width = Math.abs(oldBoundBox.x - temp.x + oldBoundBox.width);
            temp.snappedObject = true;
          }
        }
        if (
          checktargetObjectYEnd <= edgeDetection &&
          targetObjectYEnd <= edgeDetection
        ) {
          if (checktargetObjectYEnd !== 0) {
            temp.y = formattedScreen[i].screenY + formattedScreen[i].height;
            temp.height = Math.abs(oldBoundBox.y - temp.y + oldBoundBox.height);
            temp.snappedObject = true;
          }
        }
        /** --active object X end & Y end with target object  X end & Y end  */

        if (
          checkTargetAllEndWidth <= edgeDetection &&
          targetObjectAllXEnd <= edgeDetection
        ) {
          if (checkTargetAllEndWidth !== 0) {
            temp.width =
              formattedScreen[i].screenX +
              formattedScreen[i].width -
              oldBoundBox.x;
            temp.snappedObject = true;
          }
        }
        if (
          checkTargetAllEndHeight <= edgeDetection &&
          targetObjectAllYEnd <= edgeDetection
        ) {
          if (checkTargetAllEndHeight !== 0) {
            temp.height =
              formattedScreen[i].screenY +
              formattedScreen[i].height -
              oldBoundBox.y;
            temp.snappedObject = true;
          }
        }
        /** --active object X  & Y  with target object  X  & Y   */

        if (checkWidth <= edgeDetection && checkXEnd <= edgeDetection) {
          if (checkWidth !== 0) {
            temp.x = formattedScreen[i].screenX;
            temp.width = Math.abs(oldBoundBox.x - temp.x + oldBoundBox.width);
            temp.snappedObject = true;
          }
        }
        if (checkHeight <= edgeDetection && checkYEnd <= edgeDetection) {
          if (checkHeight !== 0) {
            temp.y = formattedScreen[i].screenY;
            temp.height = Math.abs(oldBoundBox.y - temp.y + oldBoundBox.height);
            temp.snappedObject = true;
          }
        }
      }
      i += 1;
      this.props.handleTransformationChange(temp);
    }

    this.props.handleTransformationChange(newBoundBox);
    return newBoundBox;
  };

  render() {
    return (
      <Transformer
        boundBoxFunc={this.boundBoxFunc}
        ref={node => {
          this.transformer = node;
        }}
      />
    );
  }
}
