import {
  notificationList,
  totalNotificationList,
  updateNotificationCountFromWebSocket,
} from "./notificationRedux";
import {
  headerNotificationList,
  allBrandNotificationList
} from "./headerNotificationRedux";

const notificationReducer = {
  notificationList,
  totalNotificationList,
  headerNotificationList,
  allBrandNotificationList
};

export { notificationReducer, updateNotificationCountFromWebSocket };
