import { Map } from "../../helper/routeMap";

/** Network */
import Reports from "./reports";
import NoRoutes from "../../core/layout/noroutes";
// import { UpcomingFeatures } from "../../core/layout/noroutes";

const mainPath = "/reports";
const pathCreator = `${mainPath}`;
// const subPathCreator = subPath => `${pathCreator}${subPath}`;
const feature = "Report";
export default [
  {
    path: `${pathCreator}`,
    component: Map(Reports),
    exact: true,
    secured: true,
    feature
  },
  {
    path: `${pathCreator}/*`,
    component: Map(NoRoutes),
    exact: true,
    secured: true,
    feature
  }
];
