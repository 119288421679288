export function formatTicketsDetailsData(data) {
  const { tickets, contents, domains, devices, users } = data;

  return {
    ...tickets[Object.keys(tickets)[0]].attributes,
    content: Object.values(contents),
    domain: Object.values(domains),
    devices: Object.values(devices),
    users: users ? Object.values(users) : {}
  };
}
