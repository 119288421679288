import React, { Component } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { remove } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from "react-bootstrap-table2-paginator";

import BootstrapTable from "react-bootstrap-table-next";
import { columns } from "../utils/calendarArchiveUtils";

// eslint-disable-next-line react/prefer-stateless-function
class CalendarArchiveTable extends Component {
  handleNodata = () =><span className="null-message-info">No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("calendarSelected", [...this.props.calendarSelected, row]);
        } else {
          const { calendarSelected } = this.props;
          remove(calendarSelected, { id: row.id });
          this.props.change("calendarSelected", calendarSelected);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("calendarSelected", [...rows]);
        } else {
          this.props.change("calendarSelected", []);
        }
      }
    };
    return (
      <div>
        <BootstrapTable
          ref={this.props.forwardRef}
          bordered={false}
          keyField="id"
          loading={this.props.isLoading}
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: base => ({
                ...base,
                background: "rgba(221,221,221,0.3)"
              }),
              spinner: base => ({
                ...base,
                "& svg circle": {
                  stroke: "rgba(0, 193, 222)",
                  strokeWidth: "4"
                }
              })
            }
          })}
          data={this.props.calendarsList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleUnArchiveItem,
            this.props.handleSort,
            this.props.sortOrder
          )}
          noDataIndication={() =>
            this.props.isLoading ? <span style={{ minHeight: "100px" }} /> : <span>No data</span>
          }
          selectRow={selectRow}
        />
      </div>
    );
  }
}
export default connect(state => {
  const selector = formValueSelector("SelectedCalendarOnArchive");
  return {
    calendarSelected: selector(state, "calendarSelected") || []
  };
})(
  reduxForm({
    form: "SelectedCalendarOnArchive"
  })(CalendarArchiveTable)
);
