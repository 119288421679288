import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Resources, generateCreateCalendarData } from "../redux/calendarRedux";
import Calendar from "../modules/calendar";
import {
  convertToLocalEvent,
  convertToServerEvent
} from "../modules/singleEventCreate";

class EditCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventData: undefined,
      calendarTitle: undefined,
      editCalendar: true,
      isLoading: true,
      disableCalendar: false
    };

    this.getCalendar(props.calendarId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.calendarId !== this.props.calendarId) {
      this.getCalendar(nextProps.calendarId);
    }
  }

  calendarTitleChange = calendarTitle => {
    this.setState({ calendarTitle });
  };

  getCalendar = async calendarId => {
    this.setState({ isLoading: true });
    if (calendarId) {
      const { showCalendar } = Resources;
      showCalendar.url = `/calendars/${calendarId}`;
      const result = await this.props.Get(showCalendar);
      if (result.status === 200) {
        const { calendarDetails } = this.props;
        const calendarTitle = Object.values(calendarDetails.calendars)[0]
          .attributes.name;
        const eventData = calendarDetails.events
          ? Object.values(calendarDetails.events).map(item => {
              const campaignName = {
                label: item.relationships.campaign.data
                  ? calendarDetails.campaigns[
                      item.relationships.campaign.data.id
                    ].attributes.name
                  : "",
                value: item.relationships.campaign.data
                  ? item.relationships.campaign.data.id
                  : ""
              };
              return convertToLocalEvent({
                serverID: item.id,
                CampaignName: campaignName,
                eventName: item.attributes.eventName,
                endTime: moment
                  .utc(item.attributes.endTime)
                  .format("YYYY-MM-DDTHH:mm"),
                startTime: moment
                  .utc(item.attributes.startTime)
                  .format("YYYY-MM-DDTHH:mm"),
                repeat: item.attributes.repeat,
                excludedDates: item.attributes.excludedDates,
                repeatFreq: item.attributes.repeatFreq
              });
            })
          : [];
        this.setState({ eventData, calendarTitle, isLoading: false });
        this.forceUpdate();
      } else {
        this.props.history.push("/calendar");
      }
    }
  };

  handleCalendarSave = async () => {
    this.setState({ disableCalendar: true });
    const { state } = this;
    const { Put, calendarFormEventValues, calendarId } = this.props;
    const { putCalendar } = Resources;
    const eventDataForServer = calendarFormEventValues.map(i =>
      convertToServerEvent(i)
    );
    putCalendar.body = generateCreateCalendarData(
      eventDataForServer,
      state.calendarTitle
    );
    putCalendar.url = `/calendars/${calendarId}`;
    // const result = await Put(putCalendar).then(() =>
    //   this.props.Get(Resources.getCalendar)
    // );
    const result = await Put(putCalendar);

    if (result.status !== 422) {
      await this.props.Get(Resources.getCalendar);
    }

    if (result.status === 200) {
      this.props.history.push("/calendar");
      this.props.handleCalendarAction("Edit");
      this.props.toggleCalendarList(true);
      // this.props.handleCalendarEdit("Edit", calendarId);
    }
    this.setState({ disableCalendar: false });
  };

  handleCancelChanges = () => {
    this.props.history.push("/calendar");
    this.props.handleCalendarAction("Edit");
    this.props.toggleCalendarList(true);
  };

  render() {
    const { isLoading } = this.state;
    const { isCalendarListOpen } = this.props;
    return (
      <div>
        {isLoading ? (
          <div className="d-block text-center">
            <svg
              className="spinner"
              viewBox="0 0 50 50"
              style={{ margin: !isCalendarListOpen && "22% 0 0 -5px" }}
            >
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="4"
              />
            </svg>{" "}
          </div>
        ) : (
          <div>
            <div className="clearfix" />
            <div className="bg-white">
              <div className="calendar-block">
                {this.state.eventData !== undefined && (
                  <Calendar
                    {...this.props}
                    handleToggleEventForm={this.handleToggleEventForm}
                    onSelectEvent={this.onSelectEvent}
                    events={this.state.eventData}
                    handleCalendarAction={this.props.handleCalendarAction}
                    calendarTitleChange={this.calendarTitleChange}
                    calendarTitle={this.state.calendarTitle}
                    activateIput={this.props.activateIput}
                    disableInput={this.props.disableInput}
                    calendarInputRef={this.props.calendarInputRef}
                    handleCalendarSave={this.handleCalendarSave}
                    disableCalendar={this.state.disableCalendar}
                    editCalendar={this.state.editCalendar}
                    calendarId={this.props.calendarId}
                    action={this.props.action}
                    handleCancelChanges={this.handleCancelChanges}
                    calendarFormEventValues={this.props.calendarFormEventValues}
                    isCalendarListOpen={isCalendarListOpen}
                    toggleCalendarList={this.props.toggleCalendarList}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    calendarDetails: state.calendarDetails.list,
    calendarFormEventValues:
      (state.form &&
        state.form.createCalendar &&
        state.form.createCalendar.values &&
        state.form.createCalendar.values.calendar) ||
      []
  }),
  null
)(EditCalendar);
