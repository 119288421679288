/* eslint-disable no-undef */

export const defaultUserSettings = {
  deviceListView: "groups",
  contentListView: "lists"
};

export function setUserSetting(setting, value) {
  const userSetting = JSON.parse(window.localStorage.getItem("userSetting"));
  userSetting[setting] = value;
  window.localStorage.setItem(
    "userSetting",
    JSON.stringify({ ...userSetting })
  );
  return true;
}

export function getUserSetting() {
  return JSON.parse(window.localStorage.getItem("userSetting"));
}
