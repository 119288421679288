import React, { Component } from "react";
import Hls from "hls.js";

function getYTVideoId(ytUrl) {
  return ytUrl.search(/\/watch\?v=/) > -1
    ? ytUrl.split(/\/watch\?v=/)[1].split("&")
    : ytUrl.split(/youtu.be\//)[1];
}

export default props => (
  <iframe
    title="YOUTUBE"
    style={{
      overflow: "hidden",
      overflowX: "hidden",
      border: "none"
    }}
    src={`https://www.youtube.com/embed/${getYTVideoId(
      props.url
    )}?rel=0&autoplay=1`}
    height={props.height}
    width={props.width}
  />
);
