import React from "react";
import { Link } from "react-router-dom";
import { TextClipper } from "../../../helper/TextFormatter";
import { PopOver, Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";

export function columns(
  roles,
  permission,
  handleDelete,
  handleUnArchiveItem,
  handleSort,
  sortOrder
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i className={`fa fa-sort-alpha-${sortOrder.name.sortBy === "desc" ? "up" : "down"}`} />
          </span>
        </div>
      ),
      style: { width: "150px" },
      formatter: (name, row) => (
        <div className="thumb">
          <div className="content-thumb-image">
            <img
              src={
                row.attributes.s3_thumbnail_url || require("../../../assets/images/empty-img.png")
              }
              alt={name || undefined}
              className="content__thumb"
            />
          </div>
          <span className="content__titlename">
            {name.length >= 25 ? (
              <Tooltip
                item={<span className="content__name"> {`${name.slice(0, 25)}...`} </span>}
                id={`viewname-${row.id}`}
                tooltipContent={name}
              />
            ) : (
              <span className="content__name">{name}</span>
            )}
          </span>{" "}
          <RouteAllowed roles={roles && roles} permission={permission && permission.content.show}>
            <Link
              className="linkTitle"
              to={{
                pathname: `/dashboard/content/show/${row.id}`,
                params: { ...row, handleDelete, roles, permission }
              }}
            />
          </RouteAllowed>
          <div className="content-tag">
            <p className="tag-list">
              <div style={{ display: "block" }}>
                {row.attributes.tags &&
                  row.attributes.tags.slice(0, 2).map((i, index) => (
                    <span key={`tags-${row.id}`} className="contentTag__badge">
                      {TextClipper(i, 8)}
                    </span>
                  ))}

                <span className="tagmenu-icon">
                  {row.attributes.tags && row.attributes.tags.length > 2 && (
                    <PopOver id={`tags-${row.id}`}>
                      {row.attributes.tags.map(i => (
                        <span key={`tags-${row.id}`} className="contentTag__badge">
                          {i}
                        </span>
                      ))}
                    </PopOver>
                  )}
                </span>
              </div>
            </p>
          </div>
        </div>
      )
    },
    {
      dataField: "attributes.size",
      text: "Size",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("size")}
            className="sort-by "
            style={{
              color: sortOrder.size.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            Size
            <i className={`fa fa-sort-alpha-${sortOrder.size.sortBy === "desc" ? "up" : "down"}`} />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.dimension",
      text: "Dimension",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("dimension")}
            className="sort-by "
            style={{
              color: sortOrder.dimension.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            {" "}
            Dimension
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.dimension.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.duration",
      text: "Duration",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("duration")}
            className="sort-by "
            style={{
              color: sortOrder.duration.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            {" "}
            Duration
            <i
              className={`fa fa-sort-alpha-${sortOrder.duration.sortBy === "desc" ? "up" : "down"}`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.content_type",
      text: "Kind",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("content_type")}
            className="sort-by "
            style={{
              color: sortOrder.content_type.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            Kind
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.content_type.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.created_at",
      text: "Date Created",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.created_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#00c1de" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    // {
    //   dataField: "attributes.status",
    //   text: " Status",
    //   headerFormatter: () => (
    //     <div style={{ display: "flex", flexDirection: "column" }}>
    //       <span
    //         role="presentation"
    //         onClick={() => handleSort("status")}
    //         className="sort-by "
    //         style={{
    //           color: sortOrder.status.active ? "#00c1de" : "#2B2B2B"
    //         }}
    //       >
    //         Status
    //         <i
    //           className={`fa fa-sort-alpha-${sortOrder.status.sortBy === "desc" ? "up" : "down"}`}
    //         />
    //       </span>
    //     </div>
    //   ),
    //   formatter: (name, row) => (
    //     <span
    //       className={`content-text ${
    //         row.attributes.status === "offline"
    //           ? "text-offline statusoffline"
    //           : "text-info statuslive"
    //       }`}
    //     >
    //       {row.attributes.status}
    //     </span>
    //   )
    // },
    {
      dataField: "delete",
      text: "",
      formatter: (name, row) => (
        <div className="contentbutton action">
          <RouteAllowed roles={roles && roles} permission={permission && permission.content.delete}>
            <Tooltip
              item={
                <i
                  role="presentation"
                  onClick={() => handleUnArchiveItem(row.id, name)}
                  className="fas fa-history "
                />
              }
              id={`archive-${row.id}`}
              tooltipContent="Restore"
            />

            <Tooltip
              item={
                <i
                  role="presentation"
                  className="fa fa-trash action__trash"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDelete(row.id);
                  }}
                />
              }
              tooltipContent="Delete"
              id="delete-archive-content"
            />
          </RouteAllowed>
        </div>
      )
    }
  ];
}
