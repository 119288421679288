export function convertURLToString(string) {
  const removableString = ["edit", "show", "device", "setting", ""];
  const routes = string.slice(1, string.length).split("/");
  return routes.filter(item => !removableString.includes(item));
}

export function getUrlParameter(query) {
  let name = query;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
