/** Import Node Modules */
import React, { Component } from "react";
import { change as ReduxFormChange, destroy } from "redux-form";
/** Import Redux Library */
import { connect } from "react-redux";
/** Import Local Components */
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import TicketTable from "./modules/ticketTable";
import { Dialog } from "../../core";
import { RemoveItemFromContentList } from "./redux";

import { Resources } from "./redux/ticketRedux";

class Ticket extends Component {
  handleSearch = debounce(() => {
    const { getTicket } = Resources;
    if (this.state.searchVal === "") {
      getTicket.url = `/tickets?page=${1}&per_page=${this.perPageCount}`;
      this.props.Get(getTicket);
    } else {
      getTicket.url = `/tickets/search?search=${
        this.state.searchVal
      }&page=${1}&per_page=${this.perPageCount}`;
      this.props.Get(getTicket);
    }
  }, 3000);

  constructor(props) {
    super(props);
    this.perPageCount = 20;
    Resources.getTicket.url = `/tickets?page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props.Get(Resources.getTicket).then(() => {
      this.setState({
        isLoading: false
      });
    });
    this.ticketTableRefs = React.createRef();
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      share_of_voice: { active: false, sortBy: "desc" },
      starting_date: { active: false, sortBy: "desc" },
      ending_date: { active: false, sortBy: "desc" },
      start_time: { active: false, sortBy: "desc" },
      end_time: { active: false, sortBy: "desc" },
      status: { active: false, sortBy: "desc" },
      created_at: { active: false, sortBy: "desc" },
      updated_at: { active: false, sortBy: "desc" },
      customer: { active: false, sortBy: "desc" }
    };

    this.state = {
      forcePage: undefined,
      refreshing: false,
      sortOrder: this.initialSortOrder,
      isLoading: true,
      sortValue: { isSortable: false },
      deleteModal: {
        id: undefined,
        modal: false,
        body: "Are you sure you want to delete this content.",
        title: "Confirm Delete"
      },
      searchVal: ""
    };
  }

  handleRefresh = () => {
    if (!this.state.refreshing) {
      Resources.getTicket.url = `/tickets?page=${1}&per_page=${
        this.perPageCount
      }`;
      this.setState(
        { refreshing: true, forcePage: 0, sortOrder: this.initialSortOrder },
        () => {
          this.props
            .Get(Resources.getTicket)
            .then(() => {
              setTimeout(() => {
                this.setState({ refreshing: false });
              }, 3000);
            })
            .then(() => this.props.showNotification("Refreshed", "info"));
        }
      );
    }
  };

  // handleFilter = filter => {
  //   this.setState({ forcePage: 0 });
  //   const { getTicket } = Resources;
  //   getTicket.url = `/tickets/search?search=${filter}&page=${1}&per_page=${
  //     this.perPageCount
  //   }`;
  //   this.props
  //     .Get(Resources.getTicket)
  //     .then(() => this.setState({ isLoading: false }));
  // };

  handleFilterWithStatus = filter => {
    this.setState({ isLoading: true, forcePage: 0 });
    const { getTicket } = Resources;
    getTicket.url = `/tickets?status=${filter}&page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props
      .Get(Resources.getTicket)
      .then(() => this.setState({ isLoading: false }));
  };

  handleSort = name => {
    this.setState({ isLoading: true, forcePage: 0 });
    this.props.ReduxFormChange("SelectedTickets", "ticketSelected", []);
    // this.ticketTableRefs.current.selectionContext.selected = [];
    // this.ticketTableRefs.current.setState({});
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getTicket } = Resources;
        getTicket.url = `/tickets/?sort=${name}&sort_by=${
          this.state.sortOrder[name].sortBy
        }&page=${1}&per_page=${this.perPageCount}`;
        this.props
          .Get(Resources.getTicket)
          .then(() => this.setState({ isLoading: false }));
      }
    );
  };

  handleStatus = value => {
    this.setState({ forcePage: 0 });
    const { getTicket } = Resources;
    getTicket.url = `/tickets?status=${value}&page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props.Get(Resources.getTicket);
  };

  handleDeleteConfirmation = (id, name) => {
    const { deleteModal } = this.state;
    deleteModal.modal = true;
    deleteModal.id = id;
    deleteModal.body = (
      <span>
        <small>Are you sure you want to delete </small>
        <strong>{name}</strong>?
      </span>
    );

    this.setState({ deleteModal });
  };

  handleDelete = () => {
    const {
      deleteModal: { id }
    } = this.state;
    if (id) {
      this.props
        .Delete(Resources.deleteTicket(id))
        .then(result => {
          if (result.status === 200) {
            this.props.RemoveItemFromContentList(id);
            if (this.props.ticketList.length === 0) {
              this.handlePageClick(this.props.meta, true);
            }
          }
          const { deleteModal } = this.state;
          deleteModal.modal = false;
          deleteModal.id = undefined;
          this.setState({
            deleteModal
          });
        })
        .then(() =>
          this.props.ReduxFormChange("SelectedTickets", "ticketSelected", [])
        );
      return 0;
    }
    return 0;
  };

  toggleModel = flag => {
    const { deleteModal } = this.state;
    deleteModal.modal = flag;
    this.setState({ deleteModal });
  };

  handleSearchValue = value => {
    this.setState({ forcePage: 0, searchVal: value }, () => {
      this.handleSearch();
    });
  };

  handlePageClick = (data, moveToPrevious = false) => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    const { getTicket } = Resources;
    if (moveToPrevious) {
      getTicket.url = `/tickets?page=${data.page - 1}&per_page=${
        this.perPageCount
      }`;
      this.props.Get(getTicket).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      this.setState({ forcePage: undefined });
      this.setState({ isLoading: true });
      const { selected } = data;
      if (this.state.sortValue.isSortable) {
        getTicket.url = `/tickets/?sort=${this.state.sortValue.name}&sort_by=${
          this.state.sortValue.sortBy
        }&page=${selected + 1}&per_page=${this.perPageCount}`;
      } else {
        getTicket.url = `/tickets?page=${selected + 1}&per_page=${
          this.perPageCount
        }`;
      }
      this.props
        .Get(getTicket)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  render() {
    const { refreshing } = this.state;
    const { deleteModal } = this.state;
    return (
      <div className="mainPage">
        <div className="contentSection">
          <Dialog
            externalControl
            showHandler={false}
            modal={deleteModal.modal}
            body={deleteModal.body}
            title={deleteModal.title}
            toggleModel={this.toggleModel}
            primaryMethod={this.handleDelete}
          />
          <TicketTable
            {...this.props}
            forwardRef={this.ticketTableRefs}
            handleDelete={this.handleDeleteConfirmation}
            handleSort={this.handleSort}
            sortOrder={this.state.sortOrder}
            isLoading={this.state.isLoading}
            ticketList={this.props.ticketList}
            // below props are needed for table header
            refreshing={refreshing}
            handleRefresh={this.handleRefresh}
            handleStatusFilter={this.handleStatus}
            handleFilter={this.handleFilter}
            handleSearch={this.handleSearchValue}
            handleFilterWithStatus={this.handleFilterWithStatus}
          />
          {!this.state.isLoading &&
            this.props.ticketList &&
            this.props.ticketList.length > 0 && (
              <div className="contentSectionPagination clearfix">
                <ReactPaginate
                  pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  marginPagesDisplayed={2}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  previousLabel="<"
                  forcePage={this.state.forcePage}
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  disabledClassName="paginationDisable"
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { list, status, loading, hasError, meta } = state.tickets;
  return {
    ticketList: list !== undefined ? list : [],
    loading,
    hasError,
    meta,
    status
  };
}

export default connect(
  mapStateToProps,
  { ReduxFormChange, destroy, RemoveItemFromContentList }
)(Ticket);
