import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Tooltip as DefaultTooltip
} from "reactstrap";
import { reduxForm, Field, initialize as remoteInitialize } from "redux-form";
import { connect } from "react-redux";
import Select from "react-select";
import { MultipleSelect } from "../../../core/form/searchSelect";
import SelectAsync from "../../../core/form/selectAsyncClass";
import SelectAsyncCalendar from "../../../core/form/selectAsynCalendar";
import { Input } from "../../../core";

// eslint-disable-next-line react/prefer-stateless-function
class DeviceControl extends Component {
  constructor() {
    super();
    this.state = {
      isLegacy: false,
      tooltipOpen: false,
      media: "campaign"
    };
  }

  handleMediaChange = e => {
    this.props.remoteInitialize("DeviceControlForm", {
      media: []
    });
    const { value } = e;
    this.setState({ media: value });
  };

  toggleTooltip = () => this.setState({ tooltipOpen: !this.state.tooltipOpen });

  render() {
    const option = this.state.isLegacy
      ? this.props.legacyTagOptions
      : this.props.tagOptions;
    return (
      <div>
        {/* Device Environment */}
        <div className="row mt-3">
          <div className="col-md-2 informationBlock">
            <Label className="mt-2">Environment</Label>
          </div>
          <div className="col-md-10 informationTag">
            <Field
              component={MultipleSelect}
              name="electronEnv"
              className="mr-0 mb-0"
              options={this.props.envOptions.map(i => ({
                value: i.toLowerCase(),
                label: i
              }))}
              clearable
              isDisabled={this.props.sendingEnvironmentInstruction}
              placeholder="Select Environment"
            />
            <Button
              className="btn-info ml-2"
              color="info"
              disabled={
                this.props.sendingEnvironmentInstruction ||
                this.props.selectedEnv === undefined ||
                this.props.selectedEnv.length <= 0
              }
              onClick={() =>
                this.props.toggleButtons("changeEnvironment", true)
              }
            >
              {this.props.sendingEnvironmentInstruction ? "Sending..." : "Send"}
            </Button>
            {this.props.buttonState.changeEnvironment.active && (
              <div className="confirmationMessage confirmationMessage">
                <span>{`Are you sure you want to send ${this.props
                  .selectedEnv &&
                  this.props.selectedEnv.value} environment?`}</span>
                <div
                  className="mt-2"
                  style={{ display: "block", float: "right" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() =>
                      this.props.toggleButtons("changeEnvironment", false)
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="primaryButton"
                    type="submit"
                    onClick={this.props.handleSubmit(values =>
                      this.props.sendElectronAppEnvironment(values)
                    )}
                  >
                    Send
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Device Version */}
        <div className="row mt-3">
          <div
            className="toggle-version toggle-version-mod col-md-2 informationBlock"
            style={{ paddingRight: 0 }}
          >
            <Label className="mt-2">Version</Label>
            {/* <label id="version-toggle" className="toggle-switch switch ml-2 ">
              <input
                type="checkbox"
                id="booking_status"
                name="status"
                checked={this.state.isLegacy}
                onChange={() => {
                  this.props.remoteInitialize("DeviceControlForm", {
                    electronTag: []
                  });
                  this.setState({ isLegacy: !this.state.isLegacy });
                }}
              />
              <span className="slider round" />
            </label>
            <DefaultTooltip
              placement="top"
              isOpen={this.state.tooltipOpen}
              target="version-toggle"
              toggle={this.toggleTooltip}
            >
              Toggle Legacy Update Mechanism
            </DefaultTooltip>{" "} */}
          </div>
          <div className="col-md-10 informationTag">
            <Field
              component={MultipleSelect}
              name="electronTag"
              className="mr-0 mb-0"
              options={option.map(i => ({
                value: i,
                label: i
              }))}
              clearable
              isDisabled={this.props.sendingVersion}
              placeholder="Select Tag"
            />
            <Button
              className="btn-info ml-2"
              color="info"
              disabled={
                this.props.sendingVersion ||
                this.props.selectedTags === undefined ||
                this.props.selectedTags.length <= 0
              }
              onClick={() => this.props.toggleButtons("updateSoftware", true)}
            >
              {this.props.sendingVersion ? "Updating..." : "Update"}
            </Button>
            {this.props.buttonState.updateSoftware.active && (
              <div className="confirmationMessage confirmationMessage">
                <span>{`Are you sure you want to send ${this.props
                  .selectedTags &&
                  this.props.selectedTags.value} version?`}</span>
                <div
                  className="mt-2"
                  style={{ display: "block", float: "right" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() =>
                      this.props.toggleButtons("updateSoftware", false)
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="primaryButton"
                    type="submit"
                    onClick={this.props.handleSubmit(values =>
                      this.state.isLegacy
                        ? this.props.sendUpdateSoftwareToDeviceLegacy(values)
                        : this.props.sendUpdateSoftwareToDevice(values)
                    )}
                  >
                    Update
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.isLegacy && (
          <div className="alert alert-warning fade version-alert-message show mt-3">
            <h5 className="alert-heading">
              <i className="fas fa-exclamation-triangle" /> Using Legacy Update
              Mechanism
            </h5>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-md-2 informationBlock">
            <Label className="mt-2">Media</Label>
          </div>

          <div className="col-md-10  informationTag">
            <div className="selectMediaWrapper">
              <div className="dropdownWrapper sponsor-tag">
                <Select
                  isSearchable={false}
                  required
                  onChange={e => this.handleMediaChange(e)}
                  defaultValue={[{ label: "Campaign", value: "campaign" }]}
                  options={[
                    { label: "Campaign", value: "campaign" },
                    { label: "Calendar", value: "calendar" }
                  ]}
                />
              </div>
              <div className="selectWrapper">
                <Field
                  component={
                    this.state.media === "campaign"
                      ? SelectAsync
                      : SelectAsyncCalendar
                  }
                  name="media"
                  className="mr-0 mb-0"
                  isDisabled={this.props.isSendingMediaToDevice}
                  placeholder={
                    this.state.media === "campaign"
                      ? "Select Campaign"
                      : "Select Calendar"
                  }
                />
              </div>
            </div>
            <Button
              className="btn-info ml-2"
              color="info"
              disabled={
                this.props.isSendingMediaToDevice ||
                this.props.selectedMedia === undefined ||
                this.props.selectedMedia.length <= 0
              }
              onClick={() => this.props.toggleButtons("sendMedia", true)}
            >
              {this.props.isSendingMediaToDevice ? "Sending..." : "Send"}
            </Button>
            {this.props.buttonState.sendMedia.active && (
              <div className="confirmationMessage confirmationMessage">
                <span>{`Are you sure you want to send ${this.props
                  .selectedMedia && this.props.selectedMedia.label} ${
                  this.state.media
                }?`}</span>
                <div
                  className="mt-2"
                  style={{ display: "block", float: "right" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => this.props.toggleButtons("sendMedia", false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="primaryButton"
                    type="submit"
                    onClick={this.props.handleSubmit(values =>
                      this.props.sendMediaToDevice(values, this.state.media)
                    )}
                  >
                    Send
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.props.roles.includes("SuperAdmin") && (
          <div className="row mt-3">
            <div className="col-md-2 informationBlock">
              <Label className="mt-2">Proof of Play (Reporting)</Label>
            </div>

            <div className="col-md-10">
              <div className="subInput">
                <div className="subscriptionContainer">
                  {/* popValue */}

                  <input
                    type="radio"
                    id="on"
                    name="proofOfPlay"
                    value="on"
                    // disabled={this.props.isSendingPopRequest}
                  />
                  <Label
                    for="on"
                    className={`${!this.props.popValue && "activePlan"} ${this
                      .state.proofOfPlay &&
                      this.props.isSendingPopRequest &&
                      "activePlan--disabled"}`}
                    onClick={() => this.props.handleProofOfPlay(false)}
                  >
                    On
                  </Label>
                </div>
                <div className="subscriptionContainer">
                  <input
                    type="radio"
                    id="off"
                    name="proofOfPlay"
                    value="off"
                    // disabled={this.props.isSendingPopRequest}
                  />
                  <Label
                    for="off"
                    className={`${this.props.popValue && "activePlan"} ${this
                      .state.proofOfPlay &&
                      this.props.isSendingPopRequest &&
                      "activePlan--disabled"}`}
                    onClick={() => this.props.handleProofOfPlay(true)}
                  >
                    Off
                  </Label>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mt-3 mb-5">
          <Button
            className="btn-info"
            disabled={this.props.restartingMPS}
            onClick={() => this.props.toggleButtons("restartApp", true)}
          >
            {this.props.restartingMPS ? " Restarting..." : "Restart App"}
          </Button>
          {this.props.buttonState.restartApp.active && (
            <div className="confirmationMessage confirmationMessage">
              <span>Are you sure you want to restart the App?</span>
              <div
                className="mt-2"
                style={{ display: "block", float: "right" }}
              >
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => this.props.toggleButtons("restartApp", false)}
                >
                  Cancel
                </button>
                <button
                  className="primaryButton"
                  type="submit"
                  onClick={this.props.restartElectronClient}
                >
                  Restart{" "}
                </button>
              </div>
            </div>
          )}

          <Button
            className="btn-info"
            disabled={
              !this.props.attributes.campaign_name &&
              !this.props.attributes.calendar_name
            }
            onClick={() => this.props.toggleButtons("stopPlayingContent", true)}
          >
            Stop Playing Content
          </Button>

          {this.props.buttonState.stopPlayingContent.active && (
            <div className="confirmationMessage confirmationMessage">
              <span>Are you sure you want to stop playing the content?</span>
              <div
                className="mt-2"
                style={{ display: "block", float: "right" }}
              >
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() =>
                    this.props.toggleButtons("stopPlayingContent", false)
                  }
                >
                  Cancel
                </button>
                <button
                  className="primaryButton"
                  type="submit"
                  onClick={this.props.stopPlayingDeviceContent}
                >
                  Stop{" "}
                </button>
              </div>
            </div>
          )}
          <Button
            color="danger"
            onClick={() => this.props.toggleButtons("restartDev", true)}
            disabled={this.props.restartingDevice}
          >
            {this.props.restartingDevice ? "Restarting..." : "Reboot Device"}
          </Button>
          {this.props.buttonState.restartDev.active && (
            <div className="confirmationMessage confirmationMessage">
              <span>Are you sure you want to Reboot device?</span>
              <div>
                <Label className="mt-2 checkBoxWrapper mr-4">
                  <input
                    type="checkbox"
                    checked={this.props.stopAndReboot}
                    onChange={this.props.handleStopAndReboot}
                  />
                  <span className="checkMark-content">
                    {" "}
                    Stop Playing Content.
                  </span>
                  <span className="checkmark" />
                </Label>
              </div>
              <div style={{ display: "block", float: "right" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => this.props.toggleButtons("restartDev", false)}
                >
                  Cancel
                </button>
                <button
                  className="primaryButton"
                  type="submit"
                  onClick={() => {
                    this.props.restartDevice();
                  }}
                >
                  Reboot
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { remoteInitialize }
)(
  reduxForm({
    form: "DeviceControlForm"
  })(DeviceControl)
);
