/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { SearchTagRefresh } from "../../../core";
// import ContentTable from "./contentTable";

export default class ArchiveHeader extends Component {
  state = {};

  render() {
    return (
      <section>
        {
          <div className="d-flex contentheader">
            <h5 className="p-0 m mt-2">Archive</h5>

            {/* <SearchTagRefresh /> */}
            <i className="fas fa-filter dropdown">
              <div className="dropdown-content">
                <div className="caret" />
                <a href="#">All</a>
                <a href="#">Video</a>
                <a href="#">Image</a>
              </div>
            </i>
          </div>
        }
      </section>
    );
  }
}
