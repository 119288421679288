/** Import Node Modules */
import React, { Component } from "react";
import { change as ReduxFormChange, destroy } from "redux-form";
/** Import Redux Library */
import { connect } from "react-redux";
/** Import Local Components */
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import PlaylistTable from "./modules/playlistTable";
import { Dialog } from "../../core";
import { RemoveItemFromContentList } from "./redux";

import {
  Resources,
  postArchivedItems,
  updatePlaylistLockedAttribute
} from "./redux/playlistRedux";

class Playlist extends Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    Resources.getPlaylist.url = `/playlists?page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props.Get(Resources.getPlaylist).then(() => {
      this.setState({
        isLoading: false
      });
    });
    this.playlistTableRefs = React.createRef();
    this.initialSortOrder = {
      name: { active: false, sortBy: "desc" },
      status: { active: false, sortBy: "desc" },
      created_at: { active: false, sortBy: "desc" },
      updated_at: { active: false, sortBy: "desc" }
    };

    this.state = {
      forcePage: undefined,
      refreshing: false,
      sortOrder: this.initialSortOrder,
      isLoading: true,
      sortValue: { isSortable: false },
      deleteModal: {
        id: undefined,
        modal: false,
        body: "Are you sure you want to delete this content.",
        title: "Confirm Delete"
      },
      lockUnlockModal: {
        modal: false,
        title: "",
        id: undefined,
        flag: undefined
      },
      searchVal: "",
      searchRes: false,
      disableArchiveIcon: false
    };
    this.searchRef = React.createRef();
  }

  handleRefresh = () => {
    if (!this.state.refreshing) {
      Resources.getPlaylist.url = `/playlists?page=${1}&per_page=${
        this.perPageCount
      }`;
      this.searchRef.current.clearSearchByName();
      this.setState(
        { refreshing: true, forcePage: 0, sortOrder: this.initialSortOrder },
        () => {
          this.props
            .Get(Resources.getPlaylist)
            .then(() => {
              setTimeout(() => {
                this.setState({ refreshing: false });
              }, 3000);
            })
            .then(() => this.props.showNotification("Refreshed", "info"));
        }
      );
    }
  };

  handleFilter = filter => {
    this.setState({ forcePage: 0 });
    const { getPlaylist } = Resources;
    getPlaylist.url = `/playlists/search?search=${filter}&page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props.Get(Resources.getPlaylist);
  };

  handleFilterWithType = filter => {
    this.setState({ forcePage: 0 });
    const { getPlaylist } = Resources;
    getPlaylist.url = `/playlists?type=${filter}&page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props.Get(Resources.getPlaylist);
  };

  handleSort = name => {
    this.setState({ isLoading: true, forcePage: 0 });
    this.props.ReduxFormChange("SelectedPlaylists", "playlistSelected", []);
    this.playlistTableRefs.current.selectionContext.selected = [];
    this.playlistTableRefs.current.setState({});
    this.setState(
      prevState => ({
        sortOrder: {
          ...this.initialSortOrder,
          [name]: {
            active: true,
            sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc"
          }
        },
        sortValue: {
          name,
          active: true,
          sortBy: prevState.sortOrder[name].sortBy === "desc" ? "asc" : "desc",
          isSortable: true
        }
      }),
      () => {
        const { getPlaylist } = Resources;
        getPlaylist.url = `/playlists/?sort=${name}&sort_by=${
          this.state.sortOrder[name].sortBy
        }&page=${1}&per_page=${this.perPageCount}`;
        this.props
          .Get(Resources.getPlaylist)
          .then(() => this.setState({ isLoading: false }));
      }
    );
  };

  handleStatus = value => {
    this.setState({ forcePage: 0 });
    const { getPlaylist } = Resources;
    getPlaylist.url = `/playlists?status=${value}&page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props.Get(Resources.getPlaylist);
  };

  handleDeleteConfirmation = (id, name) => {
    const { deleteModal } = this.state;
    deleteModal.modal = true;
    deleteModal.id = id;
    deleteModal.body = (
      <span>
        <small>Are you sure you want to delete </small>
        <strong>{name}</strong>?
      </span>
    );

    this.setState({ deleteModal });
  };

  handleLockUnLockConfirmation = (id, name, action, flag) => {
    const { lockUnlockModal } = this.state;
    lockUnlockModal.modal = true;
    lockUnlockModal.id = id;
    lockUnlockModal.title = `Confirm ${action}`;
    lockUnlockModal.flag = flag;
    lockUnlockModal.body = (
      <span>
        <small>{`Are you sure you want to ${action} `}</small>
        <strong>{name}</strong>?
      </span>
    );
    this.setState({ lockUnlockModal });
  };

  handleDelete = () => {
    const {
      deleteModal: { id }
    } = this.state;
    if (id) {
      this.props
        .Delete(Resources.deletePlaylist(id))
        .then(result => {
          if (result.status === 200) {
            this.props.RemoveItemFromContentList(id);
            if (this.props.playlistList.length === 0) {
              this.handlePageClick(this.props.meta, true);
            }
          }
          const { deleteModal } = this.state;
          deleteModal.modal = false;
          deleteModal.id = undefined;
          this.setState({
            deleteModal
          });
        })
        .then(() =>
          this.props.ReduxFormChange(
            "SelectedPlaylists",
            "playlistSelected",
            []
          )
        );
      return 0;
    }
    return 0;
  };

  toggleModel = flag => {
    const { deleteModal } = this.state;
    deleteModal.modal = flag;
    this.setState({ deleteModal });
  };

  toggleLockUnLockModel = flag => {
    const { lockUnlockModal } = this.state;
    lockUnlockModal.modal = flag;
    this.setState({ lockUnlockModal });
  };

  handleSearchValue = value => {
    this.setState({ forcePage: 0, searchVal: value }, () => {
      this.handleSearch();
    });
  };

  // eslint-disable-next-line react/sort-comp
  handleSearch = debounce(() => {
    const { getPlaylist } = Resources;
    if (this.state.searchVal === "") {
      this.setState({ searchRes: false });
      getPlaylist.url = `/playlists?page=${1}&per_page=${this.perPageCount}`;
      this.props.Get(getPlaylist);
    } else {
      this.setState({ searchRes: true });
      getPlaylist.url = `/playlists/search?search=${
        this.state.searchVal
      }&page=${1}&per_page=${this.perPageCount}`;
      this.props.Get(getPlaylist);
    }
  }, 3000);

  handleArchiveItems = async (playlists, isDisabled) => {
    this.setState({ disableArchiveIcon: true });
    this.forceUpdate();

    if (!isDisabled) {
      const { postArchivePlaylist } = Resources;
      const filteredPlaylistId = playlists.map(item => item.id);
      postArchivePlaylist.body = postArchivedItems({ filteredPlaylistId });
      await this.props
        .Put(postArchivePlaylist)

        .then(result => {
          if (result.status === 200) {
            this.props.Get(Resources.getPlaylist);
            this.props.ReduxFormChange(
              "SelectedPlaylists",
              "playlistSelected",
              []
            );
            return (
              this.props.playlistList.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        })

        .then(() => {
          this.setState({ disableArchiveIcon: false });
        });
    }
  };

  handleArchiveItem = async id => {
    const { postArchivePlaylist } = Resources;
    postArchivePlaylist.body = postArchivedItems({ filteredPlaylistId: [id] });
    await this.props
      .Put(postArchivePlaylist)
      .then(() =>
        this.props.Get(Resources.getPlaylist).then(result => {
          if (result.status === 200) {
            return (
              this.props.playlistList.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        })
      )
      .then(() =>
        this.props.ReduxFormChange("SelectedPlaylists", "playlistSelected", [])
      );
  };

  handleDeletePlaylists = playlists => {
    const { removeBulkPlaylist } = Resources;
    const filteredPlaylistId = playlists.map(item => item.id);
    removeBulkPlaylist.body = postArchivedItems({ filteredPlaylistId });
    this.props.openGlobalModal({
      heading: "Delete Playlists",
      body: "Are you sure you want to delete?",
      actionFunc: () => {
        this.props.Put(removeBulkPlaylist).then(result => {
          if (result.status === 200) {
            this.props.ReduxFormChange(
              "SelectedPlaylists",
              "playlistSelected",
              []
            );
            this.props.Get(Resources.getPlaylist);
            return (
              this.props.playlistList.length === 0 &&
              this.handlePageClick(this.props.meta, true)
            );
          }
        });
      }
    });
  };

  handleLockUnlockPlaylist = () => {
    const { id, flag } = this.state.lockUnlockModal;
    const { lockUnlockPlaylist } = Resources;
    lockUnlockPlaylist.url = `/playlists/${id}/locked_update`;
    lockUnlockPlaylist.body = updatePlaylistLockedAttribute(id, flag);
    this.props
      .Put(lockUnlockPlaylist)
      .then(() => this.props.Get(Resources.getPlaylist));
    const { lockUnlockModal } = this.state;
    lockUnlockModal.modal = false;
    lockUnlockModal.id = undefined;
    lockUnlockModal.title = "";
    lockUnlockModal.flag = undefined;
    this.setState({ lockUnlockModal });
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    const { getPlaylist } = Resources;
    if (moveToPrevious) {
      getPlaylist.url = `/playlists?page=${data.page - 1}&per_page=${
        this.perPageCount
      }`;
      this.props.Get(getPlaylist).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      this.setState({ forcePage: undefined });
      this.setState({ isLoading: true });
      const { selected } = data;
      if (this.state.sortValue.isSortable) {
        getPlaylist.url = `/playlists/?sort=${
          this.state.sortValue.name
        }&sort_by=${this.state.sortValue.sortBy}&page=${selected +
          1}&per_page=${this.perPageCount}`;
      } else if (this.state.searchRes) {
        getPlaylist.url = `/playlists/search?search=${
          this.state.searchVal
        }&page=${selected + 1}&per_page=${this.perPageCount}`;
      } else {
        getPlaylist.url = `/playlists?page=${selected + 1}&per_page=${
          this.perPageCount
        }`;
      }
      this.props
        .Get(getPlaylist)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  handleDuplicate = id => {
    this.props.Get(Resources.duplicatePlaylist(id)).then(() => {
      this.props.Get(Resources.getPlaylist);
    });
  };

  render() {
    const { refreshing } = this.state;
    const { deleteModal, lockUnlockModal } = this.state;
    return (
      <div className="mainPage">
        <div className="contentSection">
          <Dialog
            externalControl
            showHandler={false}
            modal={deleteModal.modal}
            body={deleteModal.body}
            title={deleteModal.title}
            toggleModel={this.toggleModel}
            primaryMethod={this.handleDelete}
          />
          <Dialog
            externalControl
            showHandler={false}
            modal={lockUnlockModal.modal}
            body={lockUnlockModal.body}
            title={lockUnlockModal.title}
            toggleModel={this.toggleLockUnLockModel}
            primaryMethod={this.handleLockUnlockPlaylist}
          />

          <PlaylistTable
            {...this.props}
            tableRef={this.playlistTableRefs}
            searchRef={this.searchRef}
            handleDelete={this.handleDeleteConfirmation}
            handleLockUnLockPlaylist={this.handleLockUnLockConfirmation}
            handleSort={this.handleSort}
            sortOrder={this.state.sortOrder}
            isLoading={this.state.isLoading}
            playlistList={this.props.playlistList}
            handleArchiveItem={this.handleArchiveItem}
            handleDuplicate={this.handleDuplicate}
            // below props used by table header
            refreshing={refreshing}
            handleRefresh={this.handleRefresh}
            handleStatusFilter={this.handleStatus}
            handleFilter={this.handleFilter}
            handleSearch={this.handleSearchValue}
            handleFilterWithType={this.handleFilterWithType}
            handleArchiveItems={this.handleArchiveItems}
            handleDeletePlaylists={this.handleDeletePlaylists}
            disableArchiveIcon={this.state.disableArchiveIcon}
          />
          {!this.state.isLoading &&
            this.props.playlistList &&
            this.props.playlistList.length > 0 && (
              <div className="contentSectionPagination clearfix">
                <ReactPaginate
                  pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  marginPagesDisplayed={2}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  previousLabel="<"
                  forcePage={this.state.forcePage}
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  disabledClassName="paginationDisable"
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { list, status, loading, hasError, meta } = state.playlists;
  return {
    playlistList: list !== undefined ? list : [],
    loading,
    hasError,
    meta,
    status
  };
}

export default connect(
  mapStateToProps,
  {
    ReduxFormChange,
    destroy,
    RemoveItemFromContentList
  }
)(Playlist);
