import { deviceListMetrics, Resources } from "./deviceRedux";
import {
  deviceNucMetrics,
  latestDeviceNucMetrics,
  deviceNucMetricsOfRange,
  Resources as MetricsResources
} from "./metricsRedux";

const metricsReducer = {
  deviceListMetrics,
  latestDeviceNucMetrics,
  deviceNucMetrics,
  deviceNucMetricsOfRange
};

export { metricsReducer, Resources, MetricsResources };
