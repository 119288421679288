/* eslint-disable no-shadow */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect, Fragment } from "react";

import {
  Form,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  FormGroup,
  ButtonGroup
} from "reactstrap";
import { connect } from "react-redux";
import { Weather } from "../weather";
import { MultipleSelect } from "../../../../core/form/searchSelect";
import { validateWidgetResolution } from "../../../../helper/widgetValidation";
import { updateCampaign, Resources } from "../../redux";
import WithPromises from "./placesAutoSuggest";
import { Get } from "../../../../constant/thunk";

const days = [
  { value: "1", label: "1 Day", selected: true },
  { value: "3", label: "3 Days" },
  { value: "5", label: "5 Days" }
];

function updateWidgetProperty(
  screenID,
  contentID,
  node,
  value,
  campaign,
  fn,
  setWDays
) {
  if (node === "days") {
    const { screens } = campaign;
    const selectedScreenInfo = screens.find(a => a.localID === screenID);
    const valid = validateWidgetResolution(selectedScreenInfo, value);
    if (valid) {
      setWDays(false);
    } else {
      setWDays(true);
    }
  }
  campaign.updateWidgetProperty(screenID, contentID, node, value);
  fn(campaign);
}
function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "days",
    defaultState.days
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "unit",
    defaultState.unit
  );
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "location",
    defaultState.location
  );

  updateCampaign(campaign);
  toggle(false);
}

function checkedDimension(
  screenID,
  contentID,
  node,
  value,
  campaign,
  fn,
  setWDays
) {
  const { screens } = campaign;
  const selectedScreenInfo = screens.find(a => a.localID === screenID);
  const { days } = selectedScreenInfo.contents.find(
    a => a.contentLocalID === contentID
  );
  const valid = validateWidgetResolution(selectedScreenInfo, days.toString());
  if (valid) {
    setWDays(false);
  } else {
    setWDays(true);
  }
}

async function getWeather(location, GetWeather, setWeather) {
  const result = await GetWeather(Resources.getWeather(location));
  setWeather(result);
}

function selectSize(day) {
  if (day === "5") {
    return (
      // <Fragment>
      //   <li>500 x 330</li>
      //   <li>850 X 160</li>
      //   <li>180 X 800</li>
      // </Fragment>
      "500 X 330 or 850 X 160 or 180 X 800"
    );
  }
  if (day === "3") {
    return (
      // <Fragment>
      //   <li>500 x 330</li>
      //   <li>500 x 330</li>
      //   <li>160 X 450</li>
      // </Fragment>
      "500 X 330 or 600 X 160 or 160 X 450"
    );
  }
  return (
    // <Fragment>
    //   <li>200 x 140</li>
    //   <li>200 X 140</li>
    //   <li>120 X 300</li>
    // </Fragment>
    "200 X 140 or 120 X 300"
  );
}

const WeatherModal = props => {
  const [weather, setWeather] = useState(undefined);
  const [wdays, setWDays] = useState(false);

  const {
    contentLocalID,
    campaign,
    screen: { localID },
    updateCampaign,
    weatherWidget
  } = props;

  useEffect(() => {
    checkedDimension(
      localID,
      contentLocalID,
      "days",
      days.value,
      campaign,
      updateCampaign,
      setWDays
    );
  }, [wdays]);

  return (
    <Modal
      isOpen={props.open}
      toggle={() => props.toggle(false)}
      backdrop
      className="widgetModal"
    >
      <ModalHeader toggle={() => cancelChanges(props)}>
        {props.type}
      </ModalHeader>
      <ModalBody className="globalForm">
        {wdays && (
          <div className="alert alert-warning fade show mt-3">
            <h5 className="alert-heading">
              <i className="fas fa-exclamation-triangle" /> Screen size is too
              small for {weatherWidget.days}{" "}
              {weatherWidget.days === "1" ? "day" : "days"} weather widget!
            </h5>
            <p
              style={{
                fontSize: "14px",
                paddingLeft: "10px",
                marginTop: "10px"
              }}
            >
              For best view, set screen size greater than{" "}
              {selectSize(weatherWidget.days.toString())}
            </p>

            {/* <ol
              style={{
                listStyle: "decimal",
                paddingLeft: "40px",
                fontSize: "14px",
                paddingTop: "3px"
              }}
            >
              {selectSize(weatherWidget.days)}
            </ol> */}
          </div>
        )}
        <Weather
          location={weatherWidget.location}
          days={weatherWidget.days}
          weather={weather}
          unit={weatherWidget.unit}
        />
        <Form>
          <FormGroup>
            <div className="globalInput" onClick={e => e.stopPropagation()}>
              <Label className="mb-2">Location</Label>
              <WithPromises
                location={weatherWidget.location}
                weatherWidget={weatherWidget}
                setWeather={setWeather}
                updateLocation={location => {
                  updateWidgetProperty(
                    localID,
                    contentLocalID,
                    "location",
                    location,
                    campaign,
                    updateCampaign
                  );
                }}
                updateGeoLocation={geoLocation => {
                  updateWidgetProperty(
                    localID,
                    contentLocalID,
                    "geo_location",
                    geoLocation,
                    campaign,
                    updateCampaign
                  );
                }}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div
              className="col-md-6 globalInput"
              onClick={e => e.stopPropagation()}
            >
              <Label className="mb-2">Days</Label>
              <MultipleSelect
                input={{
                  value: {
                    label: `${weatherWidget.days} day`,
                    value: weatherWidget.days
                  },
                  onChange: days => {
                    updateWidgetProperty(
                      localID,
                      contentLocalID,
                      "days",
                      days.value,
                      campaign,
                      updateCampaign,
                      setWDays
                    );
                  }
                }}
                options={days}
              />
            </div>
            <div
              className="col-md-6 globalInput tempType"
              onClick={e => e.stopPropagation()}
            >
              {" "}
              <Label className="mb-2">Unit</Label>
              <div className="mt-2 radio-toolbar">
                <input
                  onClick={() =>
                    updateWidgetProperty(
                      localID,
                      contentLocalID,
                      "unit",
                      "celsius",
                      campaign,
                      updateCampaign
                    )
                  }
                  type="radio"
                  id="celsius"
                  name="unit"
                  value="celsius"
                  checked={props.weatherWidget.unit === "celsius"}
                />
                <Label for="celsius">Celsius</Label>
                <input
                  onClick={() =>
                    updateWidgetProperty(
                      localID,
                      contentLocalID,
                      "unit",
                      "fahrenheit",
                      campaign,
                      updateCampaign
                    )
                  }
                  type="radio"
                  id="fahrenheit"
                  name="unit"
                  value="fahrenheit"
                  checked={props.weatherWidget.unit === "fahrenheit"}
                />
                <Label for="fahrenheit">Fahrenheit</Label>
              </div>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-primary"
          onClick={() => cancelChanges(props)}
        >
          Cancel
        </Button>
        <Button
          className="mt-0 primaryButton"
          onClick={() => props.toggle(false)}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect(
  (state, props) => {
    const { campaign } = state.newCampaign;
    const {
      contentLocalID,
      screen: { localID }
    } = props;
    const weatherWidget = campaign.getContent(localID, contentLocalID);
    return {
      campaign: state.newCampaign.campaign,
      weatherWidget
    };
  },
  { updateCampaign, Get }
)(WeatherModal);
