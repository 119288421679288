import React, { useState } from "react";
import { find, upperFirst } from "lodash";
import { Link, Route } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import { connect } from "react-redux";
import { convertURLToString, getUrlParameter } from "../../helper/formatter";
import { getUserSetting } from "../../constant/userSetting";
import { ExitCampaignStudio } from "../../helper/exitCampaignStudioPrompt";
import DesktopBreakpoint from "../../context/desktop_breakpoint";
import PhoneBreakpoint from "../../context/phone_breakpoint";

// import { Breadcrumb, BreadcrumbItem } from "reactstrap";
function handleCampaignPageExit(flag, setModal) {
  setModal(flag);
}

function handleConfirmNavigationClick(history) {
  history.push("/campaign");
}

const BreadcrumbsItem = ({
  item,
  isLast,
  routes,
  isFirst,
  fromNetwork,
  group,
  setModal
}) => (
  <BreadcrumbItem
    className={`${isLast && !isFirst ? "breadcrumb__last_item" : "breadcrumb__first_item"
      }`}
  >
    {isLast ? (
      <span>
        {isFirst ? (
          <span className="active"> {upperFirst(item)}</span>
        ) : (
          <span>
            <DesktopBreakpoint>
              {item.length > 50 ? (
                <span>{`${item.slice(0, 50)}...`}</span>
              ) : (
                <span>{upperFirst(item)}</span>
              )}
            </DesktopBreakpoint>
            <PhoneBreakpoint>
              {item.length > 15 ? (
                <span>{`${item.slice(0, 15)}...`}</span>
              ) : (
                <span>{upperFirst(item)}</span>
              )}
            </PhoneBreakpoint>
          </span>
        )}
      </span>
    ) : (
      //* to display the prompt if the user wants to get back from the campaign studio */
      <div className="d-inline">
        {routes.includes("campaign") && routes.includes("studio") ? (
          <span
            role="presentation"
            className="active campaignPrompt"
            onClick={() => handleCampaignPageExit(true, setModal)}
          >
            Campaign
          </span>
        ) : (
          <Link
            to={{
              pathname: fromNetwork ? `/${routes[0]}` : `/${item}`,
              state: { fromDashboard: item === routes[1], group }
            }}
            className="active"
          >
            {upperFirst(item)}
          </Link>
        )}
      </div>
    )}
  </BreadcrumbItem>
);

function CustomBreadcrumb({ match, history, selectedGroup }) {
  const [isModalOpen, setModal] = useState(false);

  let routes = convertURLToString(match.url);
  const titles = convertURLToString(match.path);
  let fromNetwork = false;
  let group = {};
  if (titles.includes("playlist") && titles.includes(":id")) {
    routes[routes.length - 1] = "Studio";
  } else if (titles.includes("campaign") && titles.includes(":id")) {
    routes[routes.length - 1] = "Detail";
  } else if (
    titles.includes("networks") &&
    titles.includes("screen_shot_view") &&
    titles.includes(":id")
  ) {
    routes = routes.filter(item => !item.includes("screen_shot_view"));
    routes[routes.length - 1] = history.location.state.name;
    routes[routes.length] = "Screenshots";
    fromNetwork = true;
    group = {
      id: history.location.state.id,
      name: history.location.state.name
    };
  } else if (titles.includes("screen_shot_view_all")) {
    routes[routes.length - 1] = "Domain Screenshots";
  } else if (
    titles.includes("networks") &&
    titles.includes(":id") &&
    !titles.includes("screen_shot_view_all")
  ) {
    const groupName = getUrlParameter("gn");
    const name = getUrlParameter("n");
    const id = getUrlParameter("id");
    routes[routes.length - 1] = groupName;
    routes[routes.length] = name;
    fromNetwork = true;
    group = {
      id,
      name: groupName
    };
  } else if (titles.includes("tickets") && titles.includes(":id")) {
    routes[routes.length - 1] = "Detail";
  } else if (titles.includes("domain") && titles.includes(":id")) {
    routes[routes.length - 1] = "Domain";
  } else if (titles.includes(":id")) {
    routes[routes.length - 1] = history.location.state.name;
  }
  if (
    getUserSetting().deviceListView === "groups" &&
    selectedGroup &&
    titles.includes("networks") &&
    !titles.includes(":id") &&
    !titles.includes("screen_shot_view_all")
  ) {
    routes.push(selectedGroup.device_group_name);
  }

  return (
    <div className="breadcrumbs">
      <Breadcrumb>
        {routes.map((item, index) => (
          <BreadcrumbsItem
            key={item}
            item={item}
            isLast={index === routes.length - 1}
            isFirst={index === 0}
            fromNetwork={fromNetwork}
            routes={routes}
            group={group}
            setModal={setModal}
          />
        ))}
      </Breadcrumb>
      <ExitCampaignStudio
        onExitCampaignStudio={isModalOpen}
        handleCampaignPageExit={handleCampaignPageExit}
        handleConfirmNavigationClick={handleConfirmNavigationClick}
        history={history}
        setModal={setModal}
      />
    </div>
  );
}

export default connect(
  state => {
    const selectedGroup = state.selectedGroup.currentGroup;
    return {
      selectedGroup
    };
  },
  {}
)(CustomBreadcrumb);
