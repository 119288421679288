/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NoRoute extends Component {
  state = {};

  render() {
    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <img
              src={require("../../assets/images/404.png")}
              alt="page error"
            />
          </div>
          <Link to="/dashboard">
            Go to Homepage
            <i className="fa fa-arrow-right" />
          </Link>
        </div>
      </div>
    );
  }
}

const UpcomingFeatures = () => (
  <div id="notfound">
    <div className="notfound">
      <div className="notfound-404">
        <img
          src={require("../../assets/images/splashnode-logo.svg")}
          alt="IMG"
        />

        <h2>Upcoming Features</h2>
      </div>
      <Link to="/dashboard">
        Go to Homepage
        <i className="fa fa-arrow-right" />
      </Link>
    </div>
  </div>
);

export { UpcomingFeatures };
