import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Form, Label, Col } from "reactstrap";
import classnames from "classnames";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { MultipleSelect } from "./searchSelect";
import SelectedDevice from "../../features/network/modules/SelectedDevice";

class TabMenu extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      campaignHasError: false,
      calendarHasError: false
      // playlistHasError: false
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  handleChange = (selectedObj, selectedTab) => {
    this.props.change("NetworkDeviceSelector", selectedTab, selectedObj);
  };

  handleSubmit = values => {
    /** if campaign is submitted */
    if (this.state.activeTab === "1") {
      if (
        values.hasOwnProperty("campaignToBePlayed") &&
        Object.entries(values.campaignToBePlayed).length !== 0
      ) {
        this.props.handleSubmitNetworkSelector(values);
        return this.clearSelectedList(values);
      }
      this.setState({
        campaignHasError: true
      });
      return 0;
    }
    /** if calendar is submitted */
    if (this.state.activeTab === "2") {
      if (
        values.hasOwnProperty("calendarToBePlayed") &&
        Object.entries(values.calendarToBePlayed).length !== 0
      ) {
        this.props.handleSubmitNetworkCalendarSelector(values);
        return this.clearSelectedList(values);
      }
      this.setState({
        calendarHasError: true
      });
    }
    /** if playlist is submitted */
    // if (this.state.activeTab === "3") {
    //   if (
    //     values.hasOwnProperty("playlistToBePlayed") &&
    //     Object.entries(values.playlistToBePlayed).length !== 0
    //   ) {
    //     this.props.handleSubmitNetworkPlaylistSelector(values);
    //     return this.clearSelectedList(values);
    //   }
    //   this.setState({
    //     playlistHasError: true
    //   });
    // }
  };

  clearSelectedList = values => {
    this.handleChange({}, "campaignToBePlayed");
    this.handleChange({}, "calendarToBePlayed");
    // this.handleChange({}, "playlistToBePlayed");
    this.props.toggleSelectCampaignCalendarModal();
    return 0;
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <div>
        <div className="navTab-Wrapper">
          <Nav tabs style={{ margin: "0 15px", width: "calc(100% - 30px)" }}>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                CAMPAIGN
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                CALENDAR
              </NavLink>
            </NavItem>

            {/* <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                PLAYLIST
              </NavLink>
            </NavItem> */}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <Form>
                    <MultipleSelect
                      className="mulipleselect-section calendarselect"
                      onChange={e => this.handleChange(e, "campaignToBePlayed")}
                      searchable
                      placeholder="List of Campaign"
                      isClearable
                      options={this.props.campaignList.map(item => ({
                        value: item.id,
                        label: item.attributes.name
                      }))}
                    />
                    {this.state.campaignHasError && (
                      <span className="ml-2 valid-info">This Field is Required</span>
                    )}
                  </Form>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Form>
                <MultipleSelect
                  className="mulipleselect-section calendarselect"
                  onChange={e => this.handleChange(e, "calendarToBePlayed")}
                  placeholder="List of Calendar"
                  searchable
                  isClearable
                  options={this.props.calendarList.map(item => ({
                    value: item.id,
                    label: item.attributes.name
                  }))}
                />
                {this.state.calendarHasError && (
                  <span className="ml-2 valid-info">This Field is Required</span>
                )}
              </Form>
            </TabPane>
            {/* <TabPane tabId="3">
              <Form>
                <MultipleSelect
                  className="mulipleselect-section"
                  onChange={e => this.handleChange(e, "playlistToBePlayed")}
                  placeholder="List of Playlist"
                  searchable
                  isClearable
                  options={this.props.playlistList.map(item => ({
                    value: item.id,
                    label: item.attributes.name
                  }))}
                />
                {this.state.playlistHasError && (
                  <span className="ml-2 valid-info">This Field is Required</span>
                )}
              </Form>
            </TabPane> */}
          </TabContent>
          <SelectedDevice
            selectedDevice={this.props.selectedDevice}
            handleSelectedDeviceChange={this.props.handleSelectedDeviceChange}
          />
          {this.props.selectedDevice < 1 && (
            <span className="ml-2 valid-info">Please select devices</span>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    const selector = formValueSelector("NetworkDeviceSelector");
    return {
      selectedDevice: selector(state, "selected"),
      campaignToBePlayed: selector(state, "campaignToBePlayed"),
      calendarToBePlayed: selector(state, "calendarToBePlayed")
      // playlistToBePlayed: selector(state, "playlistToBePlayed")
    };
  },
  { change }
)(TabMenu);
