export const url =
  "https://29yfv81ouawe42l81kjrw9ls-wpengine.netdna-ssl.com/wp-content/uploads/2010/11/billboard-advertisement.jpg";

export const Resources = {
  selectedContent: {
    syncActions: {
      updateToList: "selectedContent_update",
      clearList: "selectedContent_delete"
    }
  }
};

const initialState = {
  selectedList: []
};

/**
 *
 * @param {Array} selectedList Item that is selected
 */
export function addToSelectedList(selectedList) {
  const {
    selectedContent: {
      syncActions: { updateToList }
    }
  } = Resources;
  return dispatch => {
    dispatch({ type: updateToList, payload: { selectedList } });
  };
}

export function clearSelectedList() {
  const {
    selectedContent: {
      syncActions: { clearList }
    }
  } = Resources;
  return dispatch => {
    dispatch({ type: clearList, payload: undefined });
  };
}

export function SelectedContent(state = initialState, { type, payload }) {
  const {
    selectedContent: {
      syncActions: { updateToList, clearList }
    }
  } = Resources;
  switch (type) {
    case updateToList: {
      const { selectedList } = payload;
      return {
        selectedList
      };
    }

    case clearList: {
      return initialState;
    }

    default:
      return state;
  }
}
