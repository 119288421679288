/** Import Node Modules */
import React, { Component } from "react";
import {
  reduxForm,
  change as ReduxFormChange,
  destroy,
  formValueSelector
} from "redux-form";
import { remove, debounce } from "lodash";

/** Import Redux Library */
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Resources } from "./redux";

/** Import Local Components */
import NetworkHeader from "./modules/networkHeader";
// import NetworkTable from "./modules/networkTable";
import NetworkGroupTable from "./modules/networkGroupTable";
import { setSelectedGroup } from "./redux/networkRedux";
import { Resources as DeviceResources } from "./redux/deviceRedux";
import { Resources as CampaignResources } from "../campaign/redux";
import {
  Resources as CalendarResources,
  calendarResources
} from "../calendar/redux";

/** Utilities and Import */
import { formatNetworkGroups } from "./utls/networkGroupUtls";
import NetworkListTable from "./modules/networkListTable";

/** Form value importer */
const selectorForNetworkListView = formValueSelector(
  "SelectedDeviceInListView"
);
const selectorForNetworkGroupView = formValueSelector(
  "deviceSelectFormForGroup"
);

class Network extends Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    this.state = {
      refreshing: false,
      isLoading: true,
      searchVal: { value: "", url: "" },
      refetch: true,
      isGroupLoading: true,
      selectedGroupId: "",
      refetchGroupDevices: true,
      resetSort: false,
      deviceForcePage: undefined,
      isListNetworkLoading: false
    };
    this.searchByTag = React.createRef();
  }

  componentDidMount = () => {
    this.initiateBulkAPILoad();
  };

  toggleSort = flag => {
    this.setState({ resetSort: flag });
  };

  /** Bulk API LOAD */
  initiateBulkAPILoad = () => {
    const APIRequest = [];
    const userSetting = this.props.getUserSetting();
    if (
      this.props.history.location.state &&
      this.props.history.location.state.fromDashboard
    ) {
      userSetting.deviceListView = "groups";
      this.props.setUserSetting("deviceListView", "groups");
    }
    if (userSetting.deviceListView === "groups") {
      APIRequest.push(this.handleLoadNetworkGroups());
    } else {
      APIRequest.push(this.handleLoadNetworkList());
    }
    Promise.all(APIRequest).then(() => {
      this.setState({
        isLoading: false
      });
      // this.loadUnassignedDevices();
      this.getCampaigns();
      this.getCalendar();
    });
  };

  loadUnassignedDevices = async () => {
    const { getUnassignedDevices } = Resources;
    await this.props.Get(getUnassignedDevices);
  };

  getCampaigns = () => {
    const { getTotalCampaign } = CampaignResources;
    getTotalCampaign.url = `/campaigns?page=1&per_page=20`;
    this.props.Get(CampaignResources.getTotalCampaign);
  };

  getCalendar = () => {
    const { getTotalCalendar } = CalendarResources;
    getTotalCalendar.url = `/calendars?page=1&per_page=20`;
    this.props.Get(CalendarResources.getTotalCalendar);
  };

  handleRefetchGroup = flag => {
    this.setState({ refetchGroupDevices: flag });
    this.forceUpdate();
  };

  setSelectedGroupId = id => {
    this.setState({ selectedGroupId: id });
    this.toggleSort(true);
  };

  /** API call for loading device group  */
  handleLoadNetworkGroups = async () => {
    this.setState({ isLoading: true });
    Resources.getNetwork.url = `/device_groups?page=${1}&per_page=${
      this.perPageCount
    }&sort=name&sort_by=asc`;
    if (
      this.props.history.location.state &&
      this.props.history.location.state.fromDashboard
    ) {
      await this.controlGroupFromDashboard();
    } else {
      await this.props.Get(Resources.getNetwork).then(async response => {
        if (response.status === 200) {
          const { getSelectedGroup } = Resources;
          getSelectedGroup.url = `/device_groups/${response.data.data[0].id}/devices?page=1&per_page=200&sort=name&sort_by=asc`;
          // Set group Ids
          this.setSelectedGroupId(response.data.data[0].id);
          this.setSelectedGroup(response.data.data[0]);
          await this.props
            .Get(Resources.getSelectedGroup)
            .then(res => {
              if (res.data.meta.totalPages <= res.data.meta.page) {
                this.handleRefetchGroup(false);
              } else {
                this.handleRefetchGroup(true);
              }
            })
            .then(() => this.setState({ isLoading: false }))
            .then(() => this.handleGroupLoading(false));
        }
        if (response.data.meta.totalPages <= response.data.meta.page) {
          this.handleRefetch();
        }
      });
    }
  };

  controlGroupFromDashboard = async () => {
    const page =
      this.props.newSelectedGroup && this.props.pageMeta
        ? this.props.pageMeta.page
        : "1";
    const { getSelectedGroup } = Resources;
    getSelectedGroup.url = `/device_groups/${this.props.history.location.state.group.id}/devices?page=${page}&per_page=200`;
    const formattedGroup = {
      id: this.props.history.location.state.group.id,
      attributes: { name: this.props.history.location.state.group.name }
    };
    this.setSelectedGroup(formattedGroup);
    this.props
      .Get(Resources.getSelectedGroup)
      .then(res => {
        if (res.data.meta.totalPages <= res.data.meta.page) {
          this.handleRefetchGroup(false);
        } else {
          this.handleRefetchGroup(true);
        }
      })
      .then(() => this.changeDeviceForcePage(page - 1))
      .then(() => this.handleGroupLoading(false));
    await this.props.Get(Resources.getNetwork).then(response => {
      if (response.data.meta.totalPages <= response.data.meta.page) {
        this.handleRefetch();
      }
    });
  };

  changeDeviceForcePage = page => {
    this.setState({ deviceForcePage: page });
    this.forceUpdate();
  };

  setSelectedGroup = group => {
    if (group) {
      const formattedGroup = {
        device_group_id: group.id,
        device_group_name: group.attributes.name,
        default_campaign: group.attributes.default_campaign
      };
      this.setState({
        selectedGroupId: group.id
      });
      this.props.setSelectedGroup(formattedGroup);
    }
    this.forceUpdate();
  };

  handleListNetworkLoading = flag => {
    this.setState({ isListNetworkLoading: flag });
    this.forceUpdate();
  };

  handleGroupLoading = flag => {
    this.setState({ isGroupLoading: flag });
    this.forceUpdate();
  };

  handleLoadAllNetworkGroup = async () => {
    this.props.Get(Resources.getAllGroups);
  };

  /** API call for loading device list  */
  handleLoadNetworkList = () => {
    this.handleListNetworkLoading(true);
    Resources.getNetwork.url = `/devices/devices_list?page=${1}&per_page=${
      this.perPageCount
    }`;
    this.props.Get(Resources.getNetwork).then(() => {
      this.handleListNetworkLoading(false);
      this.forceUpdate();
    });
  };

  /** API call for loading calendar list */
  handleLoadCalendarList = async () => {
    calendarResources.getCalendar.url = "/calendars";
    await this.props.Get(calendarResources.getCalendar);
  };

  /** API call for loading campaign list */
  // handleLoadCampaignList = async () => {
  //   CampaignResources.getCampaign.url = "/campaigns";
  //   await this.props.Get(CampaignResources.getCampaign);
  // };

  handleLoadCampaignList = async () => {
    CampaignResources.getTotalCampaign.url = `/campaigns?page=${1}&per_page=${50}`;
    await this.props.Get(CampaignResources.getTotalCampaign);
  };

  // initializeAPICallCreate = async () => {
  //   const allRequestPromise = [this.loadUnassignedDevices()];
  //   Promise.all(allRequestPromise).then(() => {
  //     this.setState({ isLoading: false });
  //   });
  // };

  loadUnassignedDevices = () => {
    const { getUnassignedDevices } = Resources;
    this.props.Get(getUnassignedDevices);
  };

  handleLoadSearchCampaignList = async () => {};

  handleChangeView = name => {
    if (this.searchByTag.current) {
      this.searchByTag.current.state.value = "";
      this.searchByTag.current.props.clearSearchTag();
    }
    this.setState({ isLoading: true });
    if (name === "lists") {
      this.handleLoadNetworkList().then(() =>
        this.setState({ isLoading: false })
      );
    } else if (name === "groups") {
      this.handleLoadNetworkGroups().then(() =>
        this.setState({ isLoading: false, sortOrder: this.initialSortOrder })
      );
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    const userSetting = this.props.getUserSetting();
    if (userSetting.deviceListView === "groups") {
      return (
        nextProps.deviceGroups !== this.props.deviceGroups ||
        nextState.isLoading !== this.state.isLoading
      );
    }
    return (
      nextProps.devicesList !== this.props.devicesList ||
      nextState.isLoading !== this.state.isLoading
    );
  };

  /** Initialize any require form filed here */
  handleLoadReduxFormLoad = () => {
    this.props.ReduxFormChange("NetworkDeviceSelector", "selected", []);
  };

  /** Handle delete Group action. */
  handleGroupDelete = id => {
    const { deleteGroup, getNetwork } = Resources;
    this.props.openGlobalModal({
      heading: "Delete Group",
      body:
        "Are you sure you want to delete this group? Deleting this group will remove all device(s) from this group. Device(s) will be listed in the unassigned group.",
      actionFunc: args => {
        getNetwork.url = `/device_groups?page=${1}&per_page=${
          this.perPageCount
        }`;
        this.props.Delete(args).then(() => {
          this.props.Get(getNetwork).then(res => {
            if (res.status === 200) {
              const { getSelectedGroup } = Resources;
              getSelectedGroup.url = `/device_groups/${res.data.data[0].id}/devices?page=1&per_page=200`;
              this.setSelectedGroup(res.data.data[0]);
              this.props.Get(Resources.getSelectedGroup);
            }
            this.handleLoadAllNetworkGroup();
          });
        });
      },
      event: { ...deleteGroup(id) }
    });
  };

  removeDeviceFromGroup = async values => {
    const { createEditGroup } = Resources;
    const request = createEditGroup();
    request.url = "/device_groups";
    request.body = request.bodyFunction(values);
    await this.props.Post(request);
    this.props.history.goBack();
  };

  /** This function resync device status. */
  /** TODO: Post resync required switch group view and list view. */
  handleRefresh = () => {
    this.setState({
      refreshing: true,
      forcePage: 0,
      sortOrder: this.initialSortOrder
    });
    this.forceUpdate();
    const { reSyncDevice, getNetwork } = Resources;
    this.props
      .Get(reSyncDevice)
      .then(() => this.props.Get(getNetwork))
      .then(() => {
        this.setState({ refreshing: false });
        this.forceUpdate();
      })
      .then(() => this.props.showNotification("Refreshed", "success"));
  };

  handleSystemRefresh = () => {
    this.setState({
      refreshing: true,
      forcePage: 0,
      sortOrder: this.initialSortOrder
    });
    this.forceUpdate();
    const { reSyncSystemDevice, getNetwork } = Resources;
    this.props
      .Get(reSyncSystemDevice)
      .then(() => this.props.Get(getNetwork))
      .then(() => {
        this.setState({ refreshing: false });
        this.forceUpdate();
      })
      .then(() => this.props.showNotification("Refreshed", "success"));
  };

  /** This code search for device in list tab. */
  handleSearchValue = (value, url = false) => {
    if (!url) {
      this.searchByTag.current.state.value = "";
      this.searchByTag.current.props.clearSearchTag();
    }
    this.setState({ searchVal: { value, url } }, () => {
      this.handleSearch();
    });
  };

  handleSearch = debounce(() => {
    const { getNetwork } = Resources;
    if (this.state.searchVal.value === "") {
      getNetwork.url = `/devices?page=${1}&per_page=${
        this.perPageCount
      }&sort=name&sort_by=asc`;
      this.props.Get(getNetwork);
    } else {
      getNetwork.url = this.state.searchVal.url
        ? `/devices/search_tag?tags=${
            this.state.searchVal.value
          }&page=${1}&per_page=${this.perPageCount}`
        : `/devices/search_device?search=${
            this.state.searchVal.value
          }&page=${1}&per_page=${this.perPageCount}&sort=name&sort_by=asc`;
      this.props.Get(getNetwork);
    }
  }, 3000);

  handleChange = item => {
    const { selectedDeviceFromListView } = this.props;
    // const { selected } = this.networkListTableRefs.current.selectionContext;
    remove(selectedDeviceFromListView, { id: item.id });
    this.props.ReduxFormChange(
      "NetworkDeviceSelector",
      "deviceSelectedInListView",
      selectedDeviceFromListView
    );
    // this.networkListTableRefs.current.selectionContext.selected = selected.filter(
    //   id => id !== item.id
    // );
    // this.networkListTableRefs.current.setState({});
    this.forceUpdate();
  };

  stopPlayingSingleDevice = row => {
    const { id } = row;
    const { stopDevice } = DeviceResources;
    this.props.Get(stopDevice(id));
    // .then(() => this.props.Get(Resources.getNetwork));
  };

  handleStatusFilter = filter => {
    const { getNetwork } = Resources;
    let newFilter = filter;
    if (filter === "live") {
      newFilter = "online";
    }
    getNetwork.url = `/devices?status=${newFilter}&page=${1}&per_page=${
      this.perPageCount
    }&sort=name&sort_by=asc`;
    this.props.Get(getNetwork);
  };

  handleRefetch = flag => {
    if (flag) {
      this.setState({ refetch: true });
    } else {
      this.setState({ refetch: false });
    }
    this.forceUpdate();
  };

  render() {
    return (
      <div className="mainPage">
        <div className="contentSection">
          {this.props.getUserSetting().deviceListView === "groups" &&
          this.props.newSelectedGroup ? (
            <NetworkGroupTable
              refetch={this.state.refetch}
              handleRefetch={this.handleRefetch}
              openGlobalModal={this.props.openGlobalModal}
              deviceGroups={this.props.deviceGroups}
              handleGroupDelete={this.handleGroupDelete}
              campaignList={this.props.campaignList}
              calendarList={this.props.calendarList}
              isLoading={this.state.isLoading}
              stopPlayingDevice={this.stopPlayingSingleDevice}
              refreshing={this.state.refreshing}
              Get={this.props.Get}
              Post={this.props.Post}
              Put={this.props.Put}
              push={this.props.push}
              history={this.props.history}
              getUserSetting={this.props.getUserSetting}
              setUserSetting={this.props.setUserSetting}
              handleSystemRefresh={this.handleSystemRefresh}
              handleRefresh={this.handleRefresh}
              handleLoadNetworkList={this.handleLoadNetworkList}
              handleLoadNetworkGroups={this.handleLoadNetworkGroups}
              loadUnassignedDevices={this.loadUnassignedDevices}
              meta={this.props.meta}
              location={this.props.location}
              isGroupLoading={this.state.isGroupLoading}
              handleGroupLoading={this.handleGroupLoading}
              handleLoadAllNetworkGroup={this.handleLoadAllNetworkGroup}
              sortOrder={this.state.sortOrder}
              selectedGroupId={this.state.selectedGroupId}
              setSelectedGroupId={this.setSelectedGroupId}
              refetchGroupDevices={this.state.refetchGroupDevices}
              handleRefetchGroup={this.handleRefetchGroup}
              newSelectedGroup={this.props.newSelectedGroup}
              setSelectedGroup={this.setSelectedGroup}
              Delete={this.props.Delete}
              resetSort={this.state.resetSort}
              toggleSort={this.toggleSort}
              changeDeviceForcePage={this.changeDeviceForcePage}
              deviceForcePage={this.state.deviceForcePage}
              roles={this.props.roles}
            />
          ) : (
            <NetworkListTable
              networkListTableRefs={this.networkListTableRefs}
              // devicesList={this.props.devicesList}
              openGlobalModal={this.props.openGlobalModal}
              closeGlobalModal={this.closeGlobalModal}
              stopPlayingDevice={this.stopPlayingSingleDevice}
              isLoading={this.state.isLoading}
              getUserSetting={this.props.getUserSetting}
              setUserSetting={this.props.setUserSetting}
              match={this.props.match}
              Get={this.props.Get}
              Post={this.props.Post}
              Put={this.props.Put}
              push={this.props.push}
              history={this.props.history}
              handleChange={this.handleChange}
              isListNetworkLoading={this.state.isListNetworkLoading}
              handleListNetworkLoading={this.handleListNetworkLoading}
              handleLoadNetworkGroups={this.handleLoadNetworkGroups}
              handleLoadAllNetworkGroup={this.handleLoadAllNetworkGroup}
              handleRefetch={this.handleRefetch}
              change={this.props.ReduxFormChange}
              roles={this.props.roles}
              refreshing={this.state.refreshing}
              handleSystemRefresh={this.handleSystemRefresh}
              handleRefresh={this.handleRefresh}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    list,
    loading,
    meta,
    groups,
    formatedList: { devices },
    filterStatus
  } = state.networks;

  /** Redux Form value import */
  const selectedDeviceFromListView =
    selectorForNetworkListView(state, "deviceSelectedInListView") || [];

  /** Redux Form value import ends */
  const campaignList = state.totalCampaign.list;
  const calendarList = state.calendars.list;
  const { location } = ownProps;
  const newSelectedGroup = state.selectedGroup.currentGroup;
  const pageMeta = state.selectedGroup.meta;
  return {
    contentList: list !== undefined ? list : [],
    campaignList: campaignList !== undefined ? campaignList : [],
    calendarList: calendarList !== undefined ? calendarList : [],
    deviceGroups: state.networks.list || [],
    // (groups && Object.values(formatNetworkGroups(groups || undefined))) || [],
    devicesList:
      state.networks.formatedList && state.networks.formatedList.devices
        ? Object.values(state.networks.formatedList.devices)
        : [],
    loading,
    meta,
    selectedDeviceFromListView: selectedDeviceFromListView || [],
    filterStatus,
    location,
    newSelectedGroup,
    pageMeta
  };
}

const Networks = reduxForm({
  form: "NetworkDeviceSelector"
})(Network);

export default connect(
  mapStateToProps,
  { ReduxFormChange, destroy, setSelectedGroup }
)(Networks);
