/* eslint-disable */
import axios from "axios";

/** Axios instant are bumping with defaut configuration. */
axios.defaults.baseURL = process.env.REACT_APP_END_POINT; // "http://18.222.166.225/api/v1";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

/** Interceptiong this request in development mode. */
if (process.env.NODE_ENV === "development") {
  axios.interceptors.request.use(request => {
    console.info("Starting Request", request);
    return request;
  });

  axios.interceptors.response.use(response => {
    console.info("Response:", response);
    return response;
  });
}

export default class ServerConnector {
  constructor() {
    this.token = "token";
    this.config = {
      headers: {
        Accept: "application/json",
      }
    };
  }

  handleToken = token => {
    if (token) {
      this.config.headers.Authorization = `Bearer ${token}`;
    } else {
      this.config.headers = { Accept: "application/json" };
    }
  };

  handleDomain = resourceURL => {
    let domains = JSON.parse(window.localStorage.getItem("domain"));
    return `domains/${domains.id}/${resourceURL}`;
  };

  async get(resourceURL, token) {
    this.handleToken(token);
    return await axios
      .get(this.handleDomain(resourceURL), this.config)
      .catch(e => e.response);
  }

  async post(resourceURL, body, token) {
    if (resourceURL === "/users/login") {
      this.handleToken(token);
      return await axios
        .post(resourceURL, body, this.config)
        .catch(e => e.response);
    }
    this.handleToken(token);
    return await axios
      .post(this.handleDomain(resourceURL), body, this.config)
      .catch(e => e.response);
  }

  async put(resourceURL, body, token) {
    this.handleToken(token);
    return await axios
      .put(this.handleDomain(resourceURL), body, this.config)
      .catch(e => e.response);
  }

  async patch(resourceURL, body, token) {
    if (
      resourceURL === "/users/forgot_password" ||
      resourceURL === "/users/reset_password"
    ) {
      this.handleToken(token);
      return await axios
        .patch(resourceURL, body, this.config)
        .catch(e => e.response);
    }
    this.handleToken(token);
    return await axios
      .patch(this.handleDomain(resourceURL), body, this.config)
      .catch(e => e.response);
  }

  async delete(resourceURL, token) {
    this.handleToken(token);
    return await axios
      .delete(this.handleDomain(resourceURL), this.config)
      .catch(e => e.response);
  }

  async bulkDelete(resourceURL, body, token) {
    this.handleToken(token);
    return await axios
      .delete(this.handleDomain(resourceURL), { ...this.config, data: body })
      .catch(e => e.response);
  }
}
