import React, { Component } from "react"
import { Resources } from "../redux/headerNotificationRedux";
import moment from "moment";
import { Dropdown, Form, Label, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Input } from "../../../core";
import AppDatePicker from "../../../core/form/datePicker";
import { connect } from "react-redux";
import { Tooltip } from "../../../core"


const CustomInput = ({ value, onClick }) => (
    <div className="expirationDateWrapper" onClick={onClick}>
        <input value={value} />
        <i className="expirationDateWrapper__calendar fa fa-calendar-alt" />
    </div>
);

class BrandNotificationHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            selectedDropDownItem: "All"
        }

    }

    toggleDropDown = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    }

    render() {
        const { filterParams } = this.props;
        return (
            <Form className="d-flex flex-column align-items-end mb-3 mr-3">
                <div className="row">
                    <div className="col">
                        <label style={{ color: "#8e8e8e" }}>From :</label>
                        <AppDatePicker
                            selected={filterParams.gte_date}
                            onChange={(input) => {
                                this.props.handleDateChange(input, true)
                            }}
                            customInput={<CustomInput />}

                        />
                    </div>
                    <div className="col">
                        <label style={{ color: "#8e8e8e" }}>To :</label>
                        <AppDatePicker
                            selected={filterParams.lte_date}
                            onChange={(input) => {
                                this.props.handleDateChange(input, false)
                            }}
                            customInput={<CustomInput />}
                        />
                    </div>
                    <div>
                        <label></label>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                            <DropdownToggle className="iconButton " caret>
                                <i className="bx bx-filter" />
                            </DropdownToggle>
                            <DropdownMenu right className="notification_filter_dropdown">
                                <DropdownItem className={`${filterParams.is_checked === "" ? "activeItem" : undefined}`}>
                                    <span
                                        role="button"
                                        tabIndex={0}
                                        onClick={(value) => {
                                            this.props.setFilterParams({ is_checked: "" }, true)
                                        }}
                                    >
                                        All
                                    </span>
                                </DropdownItem>
                                <DropdownItem className={`${filterParams.is_checked === "true" ? "activeItem" : undefined}`}>
                                    <span
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => {
                                            this.props.setFilterParams({ is_checked: "true" }, true)

                                        }}
                                    >
                                        Checked
                                    </span>
                                </DropdownItem>
                                <DropdownItem className={`${filterParams.is_checked === "false" ? "activeItem" : undefined}`}>
                                    <span
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => {
                                            this.props.setFilterParams({ is_checked: "false" }, true)

                                        }}
                                    >
                                        Unchecked
                                    </span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </Form>

        )
    }
}


const mapState = (state) => {
    const { allBrandNotificationList } = state;
    return {
        allBrandNotificationList
    }
}
export default connect(
    mapState,
)(
    BrandNotificationHeader
)