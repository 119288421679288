import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { remove } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import overlayFactory from "react-bootstrap-table2-overlay";
import { connect } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import { columns } from "../utils/archivePlaylistUtils";

class PlaylistArchiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  onPageChange = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState(() => ({
        loading: false
      }));
    }, 2000);
  };

  handleNodata = () => <span className="null-message-info">No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("selectedPlaylist", [...this.props.selectedPlaylist, row]);
        } else {
          const { selectedPlaylist } = this.props;
          remove(selectedPlaylist, { id: row.id });
          this.props.change("selectedPlaylist", selectedPlaylist);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("selectedPlaylist", [...rows]);
        } else {
          this.props.change("selectedPlaylist", []);
        }
      }
    };

    return (
      <div style={{ paddingBottom: "10px" }}>
        <Field component="input" name="selectedPlaylist" hidden />
        <BootstrapTable
          ref={this.props.forwardRef}
          bordered={false}
          keyField="id"
          loading={this.props.isLoading}
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: base => ({
                ...base,
                background: "rgba(221,221,221,0.3)"
              }),
              spinner: base => ({
                ...base,
                "& svg circle": {
                  stroke: "rgba(0, 193, 222)",
                  strokeWidth: "4"
                }
              })
            }
          })}
          data={this.props.playlistList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleUnArchiveItem,
            this.props.handleSort,
            this.props.sortOrder
          )}
          noDataIndication={() =>
            this.props.isLoading ? <span style={{ minHeight: "100px" }} /> : <span>No data</span>
          }
          selectRow={selectRow}
        />
      </div>
    );
  }
}

// export default ContentTable;
export default connect(state => {
  const selector = formValueSelector("SelectedPlaylistOnArchive");
  return {
    selectedPlaylist: selector(state, "selectedPlaylist") || []
  };
})(
  reduxForm({
    form: "SelectedPlaylistOnArchive"
  })(PlaylistArchiveTable)
);
