/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Row,
  Col,
  Input,
  Label,
  // FormText
  Card
} from "reactstrap";

import { updateCampaign } from "../../redux";

function handleCheckChange(value, node, screenID, contentID, campaign, fn) {
  campaign.updateWidgetProperty(screenID, contentID, node, value);
  fn(campaign);
}

function updateWidgetProperty(
  screenID,
  contentID,
  minute,
  second,
  campaign,
  fn
) {
  campaign.changeDurationOfContent(contentID, screenID, { minute, second });
  fn(campaign);
}
function handleMinuteChange(screenID, contentID, minute, second, campaign, fn) {
  campaign.changeDurationOfContent(contentID, screenID, {
    minute: minute ? Number(minute) : minute,
    second: Number(second)
  });
  fn(campaign);
}

function handleSecondChange(screenID, contentID, minute, second, campaign, fn) {
  campaign.changeDurationOfContent(contentID, screenID, {
    minute: Number(minute),
    second: second ? Number(second) : second
  });
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  // debugger;
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "usePlaylistDuration",
    defaultState.usePlaylistDuration
  );
  campaign.changeDurationOfContent(
    defaultState.contentLocalID,
    screen.localID,
    {
      minute: defaultState.duration.minute,
      second: defaultState.duration.second
    }
  );
  updateCampaign(campaign);
  toggle(false);
}

const PlaylistModal = props => (
  <div>
    <Modal isOpen={props.open}>
      <ModalHeader toggle={() => cancelChanges(props)}>Playlist </ModalHeader>
      <ModalBody>
        <span toggle={() => props.toggle(false)}>{props.contentName}</span>

        <Card>
          <div className="d-flex justify-content-center customModal ">
            <img
              src={
                props.playlistThumbnail ||
                require("../../../../assets/images/empty-img.png")
              }
              alt={props.contentName || undefined}
              className="content__thumb customModal__display-image"
            />
            <div className="playlist-thumbnail-overlay">
              {props.meta.attributes.locked ? (
                <img
                  src={require("../../../../assets/images/playlist_locked.png")}
                  alt={props.contentName || undefined}
                  className="overlay-img content__thumb "
                  style={{ height: "25px", width: "25px" }}
                />
              ) : (
                <img
                  src={require("../../../../assets/images/playlist_thumbnail.png")}
                  alt={props.contentName || undefined}
                  className="overlay-img content__thumb "
                  style={{ height: "25px", width: "25px" }}
                />
              )}
            </div>
          </div>
          <form className="globalForm playlistModalCheckBox">
            <div className="">
              <Label
                className="checkBoxWrapper"
                onClick={e => e.stopPropagation()}
              >
                <Input
                  type="checkbox"
                  checked={!props.playlistData.usePlaylistDuration}
                  onChange={({ target: { checked } }) =>
                    handleCheckChange(
                      !checked,
                      "usePlaylistDuration",
                      props.screen.localID,
                      props.contentLocalID,
                      props.campaign,
                      props.updateCampaign
                    )
                  }
                />
                <p className="ml-4 mt-1">Custom playlist time</p>
                <span className="checkmark" />
              </Label>{" "}
            </div>
            {!props.playlistData.usePlaylistDuration && (
              <div className="d-flex">
                <div className="globalInput">
                  <Label>Minutes</Label>
                  <Input
                    type="number"
                    value={props.playlistData.duration.minute}
                    name="minutes"
                    onChange={({ target: { value } }) =>
                      handleMinuteChange(
                        props.screen.localID,
                        props.contentLocalID,
                        value,
                        props.playlistData.duration.second,
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                    max={60}
                    placeholder="Enter Minutes"
                  />
                </div>
                <b>:</b>
                <div className="globalInput">
                  <Label>Second</Label>
                  <Input
                    type="number"
                    onChange={({ target: { value } }) =>
                      handleSecondChange(
                        props.screen.localID,
                        props.contentLocalID,
                        props.playlistData.duration.minute,
                        value,
                        props.campaign,
                        props.updateCampaign
                      )
                    }
                    value={props.playlistData.duration.second}
                    max={60}
                    name="second"
                    placeholder="Enter Second"
                  />
                </div>
              </div>
            )}
          </form>
          {/* )} */}
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-primary"
          onClick={() => cancelChanges(props)}
        >
          Cancel
        </Button>
        <Button
          className="primaryButton mt-0"
          onClick={() => props.toggle(false)}
        >
          Done
        </Button>{" "}
      </ModalFooter>
    </Modal>
  </div>
);

function mapStateToProps(state, props) {
  const { campaign } = state.newCampaign;
  const {
    contentLocalID,
    screen: { localID }
  } = props.data;
  const playlistData = campaign.getContent(localID, contentLocalID);
  campaign.getContent();
  return {
    campaign,
    playlistData: playlistData || []
  };
}

export default connect(
  mapStateToProps,
  { updateCampaign }
)(PlaylistModal);
