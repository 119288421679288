import React, { useState, useEffect } from "react";
import Resizable from "re-resizable";
import { pipe, prop } from "ramda";
import { connect } from "react-redux";
import { updateCampaign } from "../redux";

function calculateTime({ minute, second }, campaign) {
  const totalSecond = Number(minute) * 60 + Number(second);
  return { totalSecond, campaign };
}

function calculateWidth({ totalSecond, campaign }) {
  const { initialTimeLineZoom } = campaign;
  return ((totalSecond * 1.0) / initialTimeLineZoom) * 130;
}

function calculateSecondsFromWidth(width, obj) {
  const { initialTimeLineZoom } = obj;
  return (width * initialTimeLineZoom) / 130;
}

function calculateMinuteAndTimeFromSecond(second) {
  return {
    minute: parseInt(second / 60, 10),
    second: parseInt(second % 60, 10)
  };
}

function updateMinuteAndSecondToCampaign({
  fn,
  obj,
  duration,
  screenID,
  contentID
}) {
  if (obj.isSnapEnabled) {
    const formattedDuration = obj.handleSnap(screenID, contentID, duration);

    obj.changeDurationOfContent(contentID, screenID, formattedDuration);
  } else {
    obj.changeDurationOfContent(contentID, screenID, duration);
  }
  fn(obj);
  return { fn, obj, duration, screenID, contentID };
}

function calculateNewShape({ width, fn, obj, screenID, contentID }) {
  return { fn, obj, screenID, contentID, duration: getSecond(width, obj) };
}

function enableResizeOption(opt, flag = true) {
  const newObj = opt;
  Object.keys(opt).map(key => {
    if (key === "left" || key === "right") {
      newObj[key] = flag;
    }
    newObj[key] = opt[key];
  });
  return newObj;
}

const setChanges = pipe(
  calculateNewShape,
  updateMinuteAndSecondToCampaign
);

const getWidth = pipe(
  calculateTime,
  calculateWidth
);

const getSecond = pipe(
  calculateSecondsFromWidth,
  calculateMinuteAndTimeFromSecond
);

const Resize = props => {
  const { height, enable } = props;
  const [ActiveClass, setActiveClass] = useState("before");
  const [currentEnableOption, setEnableOption] = useState({ ...enable });
  const [currentWidth, setCurrentWidth] = useState(
    getWidth(
      props.currentContent.duration
        ? props.currentContent.duration
        : { minute: 0, second: 0 },
      props.campaign
    )
  );

  function onResize(e, direction, ref, d) {
    /** make it temporary */
    const { screenID, contentID, campaign, fn } = props;
    setChanges({
      width: ref.offsetWidth,
      fn,
      obj: campaign,
      screenID,
      contentID
    });
    setActiveClass("after_click_content_tile");
    setEnableOption(enableResizeOption(currentEnableOption, true));
  }

  function onResizeStop(e, direction, ref, d) {
    setActiveClass("before");
    setEnableOption(enableResizeOption(currentEnableOption, false));
    /** This is where I want to update my campaign object */
  }

  function handleClick(e, opt) {
    setActiveClass("after_click_content_tile");
    setEnableOption(enableResizeOption(currentEnableOption, true));
  }

  return (
    <Resizable
      className={ActiveClass}
      size={{
        width: getWidth(
          props.currentContent.duration
            ? props.currentContent.duration
            : { minute: 0, second: 0 },
          props.campaign
        ),
        height
      }}
      onResizeStop={onResizeStop}
      onResize={onResize}
      onClick={handleClick}
      enable={currentEnableOption}
    // resizeRatio={2 / 1}
    >
      {props.children}
    </Resizable>
  );
};

Resize.defaultProps = {
  height: 56,
  screenID: undefined,
  contentID: undefined,
  duration: { minute: 0, second: 15 },
  currentlySelected: undefined,
  setCurrentlySelected: value => {
  },
  enable: {
    top: false,
    right: false,
    bottom: false,
    left: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false
  }
};

function mapState(state, ownProps) {
  const { campaign } = state.newCampaign;
  const { screenID, contentID } = ownProps;
  const currentContent = campaign.getContent(screenID, contentID);
  return {
    currentContent,
    campaign
  };
}

export default connect(
  mapState,
  { fn: updateCampaign }
)(Resize);
