/* eslint-disable no-unused-vars */
import normalize from "json-api-normalizer";
import { getFilterStatus } from "../../../helper/helperFuncs";
import { getUserSetting } from "../../../constant/userSetting";

const createEditGroupResponseGenerator = ({ deviceGroupName, devices }) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        name: deviceGroupName
      },
      relationships: {
        devices: {
          data: devices.map(({ meta }) => ({
            type: "devices",
            id: meta.id
          }))
        }
      }
    }
  }
});
const changeGroupResponseGenerator = selectedDevices => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      relationships: {
        devices: {
          data: selectedDevices.map(item => ({
            type: "devices",
            id: item.id
          }))
        }
      }
    }
  }
});

const RS232GroupBodyGenerator = (code, devices) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id),
        control_signal: code
      }
    }
  }
});

const RestartGroupDevices = devices => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id)
      }
    }
  }
});

const RebootGroupDevices = (devices, electron_tag) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id),
        electron_tag
      }
    }
  }
});

const UpdateGroupDevices = (devices, tag) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id),
        electron_tag: tag
      }
    }
  }
});

// For Legacy Update Mechanism
const LegacyUpdateGroupDevices = (devices, tag) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id),
        legacy_electron_tag: tag
      }
    }
  }
});

const SendEnvGroupDevices = (devices, env) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id),
        electron_environment: env
      }
    }
  }
});

const SetGroupVolume = (devices, volume) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id),
        volume_level: volume
      }
    }
  }
});

const SetGroupResolution = (devices, resolution) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id),
        resolution
      }
    }
  }
});

const SetGroupOrientation = (devices, orientation) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id),
        orientation
      }
    }
  }
});

const SetGroupTimeZone = (devices, timezone) => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        device_ids: devices.map(device => device.id),
        timezone
      }
    }
  }
});

const SetFavoriteGroup = flag => ({
  _jsonapi: {
    data: {
      type: "device_groups",
      attributes: {
        favourite: flag
      }
    }
  }
});

const BulkListPopBodyGenerator = (devices, flag) => ({
  _jsonapi: {
    data: {
      type: "devices",
      attributes: {
        mac_addresses: devices,
        block_pop: flag
      }
    }
  }
});

export const Resources = {
  getNetwork: {
    url: "/device_groups",
    asyncActions: {
      init: "getNetwork_INIT",
      success: "getNetwork_SUCCESS",
      error: "getNetwork_ERROR"
    }
  },
  getAllGroups: {
    url: "/device_groups?sort=name&sort_by=asc",
    asyncActions: {
      init: "getAllGroups_INIT",
      success: "getAllGroups_SUCCESS",
      error: "getAllGroups_ERROR"
    }
  },

  getSelectedGroup: {
    url: `/device_groups/id/devices`,
    asyncActions: {
      init: "getSelectedGroup_INIT",
      success: "getSelectedGroup_SUCCESS",
      error: "getSelectedGroup_ERROR"
    }
  },

  getGroupEditID: id => ({
    url: `/device_groups/${id}/devices`,
    asyncActions: {
      init: "getGroupEditID_INIT",
      success: "getGroupEditID_SUCCESS",
      error: "getGroupEditID_ERROR"
    }
  }),

  playCampaignInNetwork: (id, flag) => ({
    url: `/campaigns/${id}/${flag}`,
    asyncActions: {
      init: "playCampaignInNetwork_INIT",
      success: "playCampaignInNetwork_SUCCESS",
      error: "playCampaignInNetwork_ERROR"
    }
  }),
  playCalendarInNetwork: (id, flag) => ({
    url: `/calendars/${id}/${flag}`,
    asyncActions: {
      init: "playCalendarInNetwork_INIT",
      success: "playCalendarInNetwork_SUCCESS",
      error: "playCalendarInNetwork_ERROR"
    }
  }),
  getGurzuDevices: {
    url: `devices/gurzu_devices`,
    asyncActions: {
      init: "gurzuDevice_INIT",
      success: "gurzuDevice_SUCCESS",
      error: "gurzuDevice_ERROR"
    }
  },

  getElectronTags: {
    url: `devices/electron_tags`,
    asyncActions: {
      init: "electron_tags_INIT",
      success: "electron_tags_SUCCESS",
      error: "electron_tags_ERROR"
    }
  },

  getLegacyElectronTags: {
    url: `devices/legacy_electron_tags`,
    asyncActions: {
      init: "legacy_electron_tags_INIT",
      success: "legacy_electron_tags_SUCCESS",
      error: "legacy_electron_tags_ERROR"
    }
  },

  // playPlaylistInNetwork: (id, flag) => ({
  //   url: `/playlists/${id}/${flag}`,
  //   asyncActions: {
  //     init: "playPlaylistInNetwork_INIT",
  //     success: "playPlaylistInNetwork_SUCCESS",
  //     error: "playPlaylistInNetwork_ERROR"
  //   }
  // }),
  reSyncDevice: {
    url: "devices/resync_devices",
    asyncActions: {
      init: "reSync_INIT",
      success: "reSync_SUCCESS",
      error: "reSync_ERROR"
    }
  },

  reSyncSystemDevice: {
    url: "devices/resync_system_devices",
    asyncActions: {
      init: "reSyncSystemDevice_INIT",
      success: "reSyncSystemDevice_SUCCESS",
      error: "reSyncSystemDevice_ERROR"
    }
  },

  setGroupDeviceVolume: id => ({
    url: `device_groups/${id}/device_volume`,
    bodyFunction: SetGroupVolume,
    asyncActions: {
      init: "setGroupDeviceVolume_INIT",
      success: "setGroupDeviceVolume_SUCCESS",
      error: "setGroupDeviceVolume_ERROR",
      successMsg: "Volume is updated",
      errorMsg: "Couldn't update volume. Please try again."
    }
  }),
  stopGroup: id => ({
    url: `device_groups/${id}/stop_all`,
    asyncActions: {
      init: "stopGroupDevices_INIT",
      success: "stopGroupDevices_SUCCESS",
      error: "stopGroupDevices_ERROR",
      successMsg: "Group Stopped",
      errorMsg: "Couldn't stop the group. Please try again."
    }
  }),
  resyncPlay: id => ({
    url: `device_groups/${id}/resync_play `,
    asyncActions: {
      init: "resyncGroupDevices_INIT",
      success: "resyncGroupDevices_SUCCESS",
      error: "resyncGroupDevices_ERROR",
      successMsg: "Group Resynced",
      errorMsg: "Couldn't resync the group. Please try again."
    }
  }),
  putNetwork: {
    url: "devices/:id",
    asyncActions: {
      init: "getDevice_INIT",
      success: "getDevice_SUCCESS",
      error: "getDevice_ERROR"
    }
  },
  getUnassignedDevices: {
    url: "devices/unassigned_devices",
    asyncActions: {
      init: "getUnassignedDevices_INIT",
      success: "getUnassignedDevices_SUCCESS",
      error: "getUnassignedDevices_ERROR"
    }
  },
  getGroupById: (groupID = null) => ({
    url: `device_groups/${groupID}`,
    bodyFunction: createEditGroupResponseGenerator,
    asyncActions: {
      init: "getGroupById_INIT",
      success: "getGroupById_SUCCESS",
      error: "getGroupById_ERROR"
    }
  }),
  createEditGroup: id => ({
    url: `device_groups/${id}`,
    bodyFunction: createEditGroupResponseGenerator,
    asyncActions: {
      init: " createEditGroup_INIT",
      success: " createEditGroup_SUCCESS",
      error: " createEditGroup_ERROR"
    }
  }),
  changeGroup: id => ({
    url: `device_groups/${id}/add_devices`,
    bodyFunction: changeGroupResponseGenerator,
    asyncActions: {
      init: "changeGroup_INIT",
      success: "changeGroup_SUCCESS",
      error: "changeGroup_ERROR"
    }
  }),
  deleteGroup: id => ({
    url: `device_groups/${id}`,
    asyncActions: {
      init: "deleteEditGroup_INIT",
      success: "deleteEditGroup_SUCCESS",
      error: "deleteEditGroup_ERROR",
      successMsg: "Group has been deleted successfully.",
      errorMsg: "Group couldn't deleted. Please try again later."
    }
  }),
  rs232GroupControl: id => ({
    url: `device_groups/${id}/run_rs232_codes`,
    bodyFunction: RS232GroupBodyGenerator,
    asyncActions: {
      init: "rs232GroupControl_INIT",
      success: "rs232GroupControl_SUCCESS",
      error: "rs232GroupControl_ERROR"
    }
  }),
  restartGroupDevices: id => ({
    url: `device_groups/${id}/restart_mps`,
    bodyFunction: RestartGroupDevices,
    asyncActions: {
      init: "RestartGroupDevices_INIT",
      success: "RestartGroupDevices_SUCCESS",
      error: "RestartGroupDevices_ERROR"
    }
  }),
  rebootGroupDevices: id => ({
    url: `device_groups/${id}/restart_devices`,
    bodyFunction: RebootGroupDevices,
    asyncActions: {
      init: "RebootGroupDevices_INIT",
      success: "RebootGroupDevices_SUCCESS",
      error: "RebootGroupDevices_ERROR"
    }
  }),
  updateGroupDevices: id => ({
    url: `device_groups/${id}/update_electron`,
    bodyFunction: UpdateGroupDevices,
    asyncActions: {
      init: "UpdateGroupDevices_INIT",
      success: "UpdateGroupDevices_SUCCESS",
      error: "UpdateGroupDevices_ERROR"
    }
  }),
  legacyUpdateGroupDevices: id => ({
    url: `device_groups/${id}/update_legacy_electron`,
    bodyFunction: LegacyUpdateGroupDevices,
    asyncActions: {
      init: "LegacyUpdateGroupDevices_INIT",
      success: "LegacyUpdateGroupDevices_SUCCESS",
      error: "LegacyUpdateGroupDevices_ERROR"
    }
  }),
  sendEnvironmentForGroupDevices: id => ({
    url: `device_groups/${id}/update_electron_env`,
    bodyFunction: SendEnvGroupDevices,
    asyncActions: {
      init: "SendEnvGroupDevices_INIT",
      success: "SendEnvGroupDevices_SUCCESS",
      error: "SendEnvGroupDevices_ERROR"
    }
  }),
  setResolutionGroupDevices: id => ({
    url: `device_groups/${id}/device_resolution`,
    bodyFunction: SetGroupResolution,
    asyncActions: {
      init: "setResolutionGroupDevices_INIT",
      success: "setResolutionGroupDevices_SUCCESS",
      error: "setResolutionGroupDevices_ERROR"
    }
  }),
  setOrientationGroupDevices: id => ({
    url: `device_groups/${id}/device_orientation`,
    bodyFunction: SetGroupOrientation,
    asyncActions: {
      init: "setOrientationGroupDevices_INIT",
      success: "setOrientationGroupDevices_SUCCESS",
      error: "setOrientationGroupDevices_ERROR"
    }
  }),
  setTimeZoneGroupDevices: id => ({
    url: `device_groups/${id}/device_timezone`,
    bodyFunction: SetGroupTimeZone,
    asyncActions: {
      init: "setTimeZoneGroupDevices_INIT",
      success: "setTimeZoneGroupDevices_SUCCESS",
      error: "setTimeZoneGroupDevices_ERROR"
    }
  }),
  setFavouriteGroup: id => ({
    url: `device_groups/${id}/toggle_favourites`,
    bodyFunction: SetFavoriteGroup,
    asyncActions: {
      init: " setFavouriteGroup_INIT",
      success: " setFavouriteGroup_SUCCESS",
      error: " setFavouriteGroup_ERROR"
    }
  }),
  bulkDevicesPopOnOff: {
    url: `/devices/bulk_update_pop`,
    bodyFunction: BulkListPopBodyGenerator,
    asyncActions: {
      init: "deleteDevices_INIT",
      success: "deleteDevices_SUCCESS",
      error: "deleteDevices_ERROR"
    }
  }
};

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  formatedList: { devices: [] },
  deleted: undefined,
  edited: undefined,
  unassignedDevicesList: [],
  hasList: undefined,
  loading: undefined,
  hasError: undefined,
  groups: undefined,
  singleGroup: undefined,
  filterStatus: undefined,
  devices: []
};

/**
 *
 *
 *  calendarId: "",
      campaignId: "",
      currentPlayingType: "",
      currentPlayingName: "",
      currentPlayingStatus: "",
      receivedMediaType: "",
      receivedMediaName: "",
      totalContentToBeDownloaded: "",
      totalDownloaded: "",
      mac: "",
      lastDownloadedType: "",
      lastDownloadedName: "",
      _meta: ""
 */
export function setSelectedGroup(group) {
  return (dispatch, getState) => {
    const state = getState().selectedGroup;
    state.currentGroup = group;
    dispatch({
      type: "updateSelectedGroup",
      payload: state
    });
  };
}

export function updateDataFromWebSocket(status, data) {
  return (dispatch, getState) => {
    const { list } = getState().selectedGroup;
    const { formatedList } = getState().networks;
    const deviceDetail = getState().deviceDetails.list;
    let state;
    if (list) {
      state = getState().selectedGroup;
      list.map(item => {
        if (item.attributes.mac_address === data.mac) {
          // make a changes
          item.attributes = {
            ...item.attributes,
            ...data
          };
        }
      });
      state.list = list;
      dispatch({
        type: "updateListDataFromWebSocket",
        payload: state
      });
    }

    if (deviceDetail) {
      state = getState().deviceDetails;
      if (deviceDetail.attributes.mac_address === data.mac) {
        deviceDetail.attributes = {
          ...deviceDetail.attributes,
          ...data
        };
      }
      state.list = deviceDetail;
      dispatch({
        type: "updateSingleDeviceDataFromWebSocket",
        payload: state
      });
    }
    // eslint-disable-next-line
    // const { groups, formatedList } = getState().networks;
    // const state = getState().networks;
    // if (groups && groups.hasOwnProperty("devices")) {
    //   const { devices } = groups;
    //   Object.keys(devices).map(item => {
    //     if (devices[item].attributes.macAddress === data.mac) {
    //       devices[item].attributes = {
    //         ...devices[item].attributes,
    //         ...data
    //       };
    //     }
    //   });
    //   state.groups.devices = devices;
    // }

    if (formatedList) {
      const formattedData = formatedList.data;
      state = getState().networks;
      formattedData &&
        formattedData.map(item => {
          if (item.attributes.mac_address === data.mac) {
            // make a changes
            item.attributes = {
              ...item.attributes,
              ...data
            };
          }
        });
      state.formatedList.data = formattedData;
      dispatch({
        type: "updateNetworkListDataFromWebSocket",
        payload: state
      });
    }
  };
}

export function networks(
  state = { ...initialState, newDevices: [] },
  action = {}
) {
  const { init, success, error } = Resources.getNetwork.asyncActions;
  const unassignedDevicesListFetchSuccess =
    Resources.getUnassignedDevices.asyncActions.success;
  const fetchSingleGroupSuccess = Resources.getGroupById().asyncActions.success;
  const userSetting = getUserSetting();

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, meta },
        status,
        statusText
      } = action.payload;
      const groups =
        userSetting.deviceListView === "groups"
          ? action.payload.data
          : undefined;
      const formatedList =
        userSetting.deviceListView !== "groups" ? action.payload.data : [];
      return {
        groups,
        list: data,
        formatedList,
        newDevices: state.list,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        unassignedDevicesList: [],
        filterStatus: getFilterStatus(action.payload.config.url)
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }
    case unassignedDevicesListFetchSuccess: {
      const {
        data: { data }
      } = action.payload;
      return {
        ...state,
        unassignedDevicesList: data
      };
    }

    case "infinite_group-scroll": {
      return {
        ...action.payload
      };
    }

    case "updateNetworkListDataFromWebSocket": {
      return {
        ...action.payload
      };
    }

    // case "updateGroupsDataFromWebSocket": {
    //   return {
    //     ...action.payload
    //   };
    // }

    case fetchSingleGroupSuccess: {
      return {
        ...state,
        singleGroup: normalize(action.payload.data)
      };
    }

    default: {
      return state;
    }
  }
}

export function totalGroup(state = { ...initialState }, action = {}) {
  const { init, success, error } = Resources.getAllGroups.asyncActions;
  const unassignedDevicesListFetchSuccess =
    Resources.getUnassignedDevices.asyncActions.success;
  const fetchSingleGroupSuccess = Resources.getGroupById().asyncActions.success;
  const userSetting = getUserSetting();

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, meta },
        status,
        statusText
      } = action.payload;
      const groups =
        userSetting.deviceListView === "groups"
          ? normalize(action.payload.data)
          : undefined;
      const formatedList =
        userSetting.deviceListView !== "groups"
          ? normalize(action.payload.data)
          : [];
      return {
        groups,
        list: data,
        formatedList,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        unassignedDevicesList: [],
        filterStatus: getFilterStatus(action.payload.config.url)
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }
    case unassignedDevicesListFetchSuccess: {
      const {
        data: { data }
      } = action.payload;
      return {
        ...state,
        unassignedDevicesList: data
      };
    }

    case fetchSingleGroupSuccess: {
      return {
        ...state,
        singleGroup: normalize(action.payload.data)
      };
    }

    default: {
      return state;
    }
  }
}

export function unAssignedDevices(state = { initialState }, action = {}) {
  const { init, success, error } = Resources.getUnassignedDevices.asyncActions;
  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }
    case success: {
      const {
        data: { data }
      } = action.payload;
      return {
        ...state,
        unassignedDevicesList: data
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function electronTags(state = { initialState }, action = {}) {
  const { init, success, error } = Resources.getElectronTags.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: {
          _jsonapi: {
            data: { attributes }
          },
          links,
          meta
        },
        status,
        statusText
      } = action.payload;
      return {
        list: attributes,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function legacyElectronTags(state = { initialState }, action = {}) {
  const { init, success, error } = Resources.getLegacyElectronTags.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: {
          _jsonapi: {
            data: { attributes }
          },
          links,
          meta
        },
        status,
        statusText
      } = action.payload;
      return {
        list: attributes,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function GurzuDevices(state = { ...initialState }, action = {}) {
  const { init, success, error } = Resources.getGurzuDevices.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, meta },
        status,
        statusText
      } = action.payload;

      return {
        list: data,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        unassignedDevicesList: [],
        filterStatus: getFilterStatus(action.payload.config.url)
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function selectedGroup(
  state = { initialState, devices: [] },
  action = {}
) {
  const { init, success, error } = Resources.getSelectedGroup.asyncActions;
  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }
    case success: {
      const {
        data: { data, meta }
      } = action.payload;

      return {
        ...state,
        list: data,
        meta
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }
    case "updateListDataFromWebSocket": {
      return {
        ...action.payload
      };
    }
    case "updateSelectedGroup": {
      return {
        ...action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export function groupToBeEdited(state = { initialState }, action = {}) {
  const { init, success, error } = Resources.getGroupEditID().asyncActions;
  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }
    case success: {
      const {
        data: { data }
      } = action.payload;

      return {
        ...state,
        list: data
      };
    }
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function updateGroupInfiniteScroll() {
  return (dispatch, getState) => {
    const { list, newDevices } = getState().networks;
    const state = getState().networks;
    state.list = [...newDevices, ...list];
    dispatch({
      type: "infinite_group-scroll",
      payload: state
    });
  };
}
