/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
import React from "react";
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label
} from "reactstrap";

function handleMinuteChange(
  { target: { value } },
  { contentLocalID, campaign, screen, updateCampaign },
  { duration: { second } }
) {
  campaign.changeDurationOfContent(contentLocalID, screen.localID, {
    minute: value ? Number(value) : value,
    second: Number(second)
  });
  updateCampaign(campaign);
}

function handleSecondChange(
  { target: { value } },
  { contentLocalID, campaign, screen, updateCampaign },
  { duration: { minute } }
) {
  campaign.changeDurationOfContent(contentLocalID, screen.localID, {
    minute: Number(minute),
    second: value ? Number(value) : value
  });
  updateCampaign(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.changeDurationOfContent(
    defaultState.contentLocalID,
    screen.localID,
    {
      minute: defaultState.duration.minute,
      second: defaultState.duration.second
    }
  );
  updateCampaign(campaign);
  toggle(false);
}

export const ContentModal = props => {
  const {
    meta: {
      attributes: { name, content_type, s3_url }
    },
    contentThumbnail,
    contentLocalID,
    duration: { minute, second },
    screen
  } = props;

  const content = props.campaign.getContent(screen.localID, contentLocalID);

  return (
    <div>
      <Modal isOpen={props.open}>
        <ModalHeader toggle={() => cancelChanges(props)}>
          {content_type.search("image") > -1 ? "Image" : "Video"}
        </ModalHeader>
        <ModalBody>
          <span toggle={() => props.toggle(false)}>{name}</span>
          <Card>
            <div className="d-flex justify-content-center customModal">
              {content_type.search("image") > -1 ? (
                <img
                  src={contentThumbnail}
                  alt={name}
                  className=" customModal__display-image"
                />
              ) : (
                <video track={name} className=" customModal__display-image">
                  <source src={s3_url} type={content_type} />
                </video>
              )}
            </div>
            {content_type.search("image") > -1 ? (
              <form>
                <div className="d-flex globalForm">
                  <div className="globalInput">
                    <Label>Minutes</Label>
                    <Input
                      type="number"
                      min="0"
                      value={content.duration.minute}
                      name="minutes"
                      onChange={e => handleMinuteChange(e, props, content)}
                      placeholder="Enter minutes to play."
                    />
                  </div>
                  <b>:</b>
                  <div className="globalInput">
                    <Label>Second</Label>

                    <Input
                      type="number"
                      min="0"
                      name="seconds"
                      value={content.duration.second}
                      onChange={e => handleSecondChange(e, props, content)}
                      placeholder="Enter seconds to play"
                    />
                  </div>
                </div>
              </form>
            ) : (
              <p className="ml-2">
                <b>Duration:</b> {content.duration.minute} minutes :
                {content.duration.second} seconds
              </p>
            )}
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-outline-primary"
            onClick={() => cancelChanges(props)}
          >
            Cancel
          </Button>
          {content_type.search("image") > -1 && (
            <Button
              className="primaryButton mt-0"
              onClick={() => props.toggle(false)}
            >
              Done
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};
