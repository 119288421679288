import React, { Component } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { remove } from "lodash";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import overlayFactory from "react-bootstrap-table2-overlay";

import BootstrapTable from "react-bootstrap-table-next";
import { columns } from "../utils/campaignArchiveUtils";

// eslint-disable-next-line react/prefer-stateless-function
class CampaignArchiveTable extends Component {
  handleNodata = () => <span>No Data</span>;

  render() {
    const selectRow = {
      mode: "checkbox",
      onSelect: (row, isSelect) => {
        if (isSelect) {
          this.props.change("campaignsSelected", [...this.props.campaignsSelected, row]);
        } else {
          const { campaignsSelected } = this.props;
          remove(campaignsSelected, { id: row.id });
          this.props.change("campaignsSelected", campaignsSelected);
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.props.change("campaignsSelected", [...rows]);
        } else {
          this.props.change("campaignsSelected", []);
        }
      }
    };
    return (
      <div style={{ paddingBottom: "10px", overflowX: "auto" }}>
        <BootstrapTable
          ref={this.props.forwardRef}
          classes="contentReactTable"
          bordered={false}
          keyField="id"
          loading={this.props.isLoading}
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: base => ({
                ...base,
                width: "1412px",
                background: "rgba(221,221,221,0.3)"
              }),
              spinner: base => ({
                ...base,
                "& svg circle": {
                  stroke: "rgba(0, 193, 222)",
                  strokeWidth: "4"
                }
              })
            }
          })}
          data={this.props.campaignList}
          columns={columns(
            this.props.roles,
            this.props.permission,
            this.props.handleDelete,
            this.props.handleUnArchiveItem,
            this.props.handleSort,
            this.props.sortOrder
          )}
          noDataIndication={() =>
            this.props.isLoading ? <span style={{ minHeight: "100px" }} /> : <span>No data</span>
          }
          selectRow={selectRow}
        />
      </div>
    );
  }
}
export default connect(state => {
  const selector = formValueSelector("SelectedCampaignsOnArchive");
  return {
    campaignsSelected: selector(state, "campaignsSelected") || []
  };
})(
  reduxForm({
    form: "SelectedCampaignsOnArchive"
  })(CampaignArchiveTable)
);
