/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { connect } from "react-redux";

class GlobalDialog extends React.Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} className={this.props.className}>
          <ModalHeader
            className="modal-header--modhdr"
            toggle={() => this.props.closeGlobalModal()}
          >
            {this.props.heading}
          </ModalHeader>
          <ModalBody>{this.props.body}</ModalBody>
          <ModalFooter>
            {this.props.heading !== "Search Help" && (
              <button
                className="btn-outline-primary"
                onClick={() => this.props.closeGlobalModal()}
              >
                Cancel
              </button>
            )}
            <button
              className="primaryButton mt-0"
              onClick={() => {
                this.props.actionFunc(this.props.event);
                this.props.closeGlobalModal();
              }}
            >
              OK
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { globalModal } = state;
  return {
    ...globalModal
  };
}
export default connect(
  mapStateToProps,
  { closeGlobalModal }
)(GlobalDialog);

const initialState = {
  isOpen: false,
  heading: "Dialog",
  body: "Your Message Here.",
  actionFunc: () => console.warn("you have click ok"),
  event: { actionName: "trigger-ok" }
};

export function closeGlobalModal() {
  return dispatch => {
    dispatch({ type: "globalModal_CLOSE", payload: initialState });
  };
}

export function openGlobalModal({ heading, body, actionFunc, event }) {
  return dispatch => {
    dispatch({
      type: "globalModal_SHOW",
      payload: {
        heading: heading || initialState.heading,
        body: body || initialState.body,
        actionFunc: actionFunc || initialState.actionFunc,
        event: event || initialState.event
      }
    });
  };
}

export function globalModal(state = initialState, { type, payload }) {
  switch (type) {
    case "globalModal_SHOW":
      return { ...payload, isOpen: true };

    case "globalModal_CLOSE":
      return { ...payload, isOpen: false };

    default:
      return state;
  }
}
