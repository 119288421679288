import React, { Component } from "react";
import { Tooltip } from "../../../core";

export default class CampaignStudioHeader extends Component {
  componentDidMount() {
    this.activateInput();
  }

  activateInput = () => {
    const el = document.getElementById("campaign-title");
    el.readOnly = false;
    el.focus();
    el.value = el.value;
  };

  handleTitleChange = ({ target: { value } }) => {
    const { campaign } = this.props;
    campaign.campaignName = value;
    this.props.updateCampaign(campaign);
  };

  render() {
    const { campaign } = this.props;
    return (
      <div className=" campaignStudioWrapper__campaign-display-header ">
        <input
          id="campaign-title"
          placeholder="Untitled Campaign"
          onChange={this.handleTitleChange}
          type="text"
          value={campaign.campaignName}
          className="mr-auto campaignStudioWrapper__campaign-display-header__title"
        />
        <div className="d-flex">
          {/* <button
            className="btn-outline-primary campaignStudioWrapper__header__buttons"
            onClick={() => this.props.handleCancelCampaignCreation()}
            type="submit"
          >
            Cancel
          </button> */}
          <button
            className="mt-0 primaryButton campaignStudioWrapper__header__buttons"
            onClick={this.props.handleSubmit(value =>
              this.props.handleCampaignSave(value)
            )}
            type="submit"
          >
            {this.props.campaign.isEditing ? "Update" : "Save"}
          </button>
          {this.props.campaign.isEditing && (
            // <div className="campaignStudioWrapper__campaign-display-header__image">
            //   <Tooltip
            //     item={
            //       <img
            //         src={require("../../../assets/images/updateAndSync.png")}
            //         alt="Italian Trulli"
            //         className="mr-2"
            //         onClick={this.props.handleSubmit(value =>
            //           this.props.handleCampaignSave(value, "WithSync")
            //         )}
            //       />
            //     }
            //     id="updateandsync"
            //     tooltipContent="Update & Sync to Devices"
            //   />
            // </div>

            <button
              className="mt-0 primaryButton ml-2"
              type="button"
              onClick={this.props.handleSubmit(value =>
                this.props.handleCampaignSave(value, "WithSync")
              )}
            >
              Update & Sync
            </button>
          )}

          {/* <div className="campaignStudioWrapper__campaign-display-header__image">
            <Tooltip
              item={
                <img
                  src={require("../../../assets/images/save_without_sync.svg")}
                  alt="Italian Trulli"
                  onClick={this.props.handleSubmit(value =>
                    this.props.handleCampaignSave(value)
                  )}
                />
              }
              id="update"
              tooltipContent={
                this.props.campaign.isEditing
                  ? "Update Campaign"
                  : "Save Campaign"
              }
            />
          </div> */}
        </div>
      </div>
    );
  }
}
