import { findIndex, remove } from "lodash";
import { destroy } from "redux-form";

const Resources = {
  uploadModal: {
    syncActions: {
      showModal: "uploadModal_SHOW",
      hideModal: "uploadModal_HIDE",
      updateProgress: "uploadModal_UPDATE_PROGRESS",
      uploadInitialize: "uploadModal_INIt"
    }
  }
};

const initialState = {
  showModal: false,
  totalFiles: undefined,
  data: []
};

/**
 *
 * @param {*} files
 *
 */

export function progress(p, item, length, request) {
  return (dispatch, getState) => {
    const {
      uploadModal: {
        syncActions: { updateProgress }
      }
    } = Resources;
    const { data } = getState().ContentUploadModal;

    const index = findIndex(data, { id: item.id });
    if (index !== -1) {
      data[index].progress = p;
    } else {
      data.push({ id: item.id, progress: p, name: item.newName, request });
    }
    dispatch({
      type: updateProgress,
      payload: { data, length, showModal: true }
    });
  };
}

export function hideModal() {
  const {
    uploadModal: {
      syncActions: { updateProgress }
    }
  } = Resources;
  return dispatch => {
    dispatch({
      type: updateProgress,
      payload: { data: [], length: 0, showModal: false }
    });
  };
}

export function removeProgress(id, hide = false) {
  const {
    uploadModal: {
      syncActions: { updateProgress }
    }
  } = Resources;
  return (dispatch, getState) => {
    const { data, length } = getState().ContentUploadModal;
    remove(data, { id });
    if (hide === false) {
      if (data.length === 0) {
        dispatch(destroy("ContentUploadForms"));
        dispatch(destroy("Contents"));
      }
      dispatch({
        type: updateProgress,
        payload: { data, length: length - 1, showModal: data.length !== 0 }
      });
    } else {
      dispatch({
        type: updateProgress,
        payload: { data, length: length - 1, showModal: false }
      });
    }
  };
}

export function ContentUploadModal(state = initialState, { type, payload }) {
  const {
    uploadModal: {
      syncActions: { updateProgress }
    }
  } = Resources;
  switch (type) {
    case updateProgress: {
      return Object.assign({}, state, {
        showModal: payload.showModal || false,
        totalFiles: payload.length || [],
        data: [...payload.data] || []
      });
    }

    default:
      return state;
  }
}
