/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  TabContent,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import classnames from "classnames";
import MediaTab from "./mediaTab";
import Playlist from "./playlistTab";

import TabSearch from "./TabSearch";
import Widget from "./widgetTab";

export default class studioContentSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Media",
      tabID: [
        { id: "Media", TabName: "Media" },
        { id: "Playlist", TabName: "Playlist" },
        { id: "Widget", TabName: "Widget" }
      ],
      contentSearchByName: "",
      contentSearchByTag: "",
      contentFilter: "",
      contentSort: "",
      sortOrder: "",
      per_page: 18,
      per_page_playlist: 18,
      playlistSearch: "",
      fetch: true,
      fetchPlaylist: true
    };
  }

  increasePerPage = () => {
    if (this.state.activeTab === "Playlist") {
      this.setState(prevState => ({
        per_page_playlist: prevState.per_page_playlist + 18
      }));
      return true;
    }
    this.setState(prevState => ({
      per_page: prevState.per_page + 18
    }));
  };

  inputSearchName = name => {
    if (this.state.activeTab === "Playlist") {
      this.setState({
        fetchPlaylist: true,
        playlistSearch: name,
        per_page_playlist: 18
      });
      return true;
    }

    this.setState({
      contentSearchByName: name,
      fetch: true,
      contentSearchByTag: "",
      contentFilter: "",
      contentSort: "",
      per_page: 18
    });
  };

  inputSearchTag = (tags, type) => {
    this.setState({
      contentSearchByTag: tags,
      fetch: true,
      contentSearchByName: "",
      contentFilter: "",
      contentSort: "",
      selectedOptionType: type,
      per_page: 18
    });
  };

  inputFilter = type => {
    this.setState({
      contentFilter: type,
      fetch: true,
      contentSearchByName: "",
      contentSearchByTag: "",
      contentSort: "",
      per_page: 18
    });
  };

  inputSort = (sort, order) => {
    this.setState({
      contentSort: sort,
      sortOrder: order,
      fetch: true,
      contentSearchByName: "",
      contentSearchByTag: "",
      contentFilter: "",
      per_page: 18
    });
  };

  toggleFetch = flag => {
    if (flag === true) {
      this.setState({
        contentSearchByName: "",
        contentSearchByTag: "",
        contentFilter: "",
        contentSort: "",
        per_page: 18
      });
    }
    this.setState({
      fetch: flag
    });
  };

  togglePlaylistFetch = flag => {
    if (flag === true) {
      this.setState({
        playlistSearch: "",
        per_page_playlist: 18
      });
    }
    this.setState({
      fetchPlaylist: flag
    });
    return true;
  };

  toggleDropDown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  togglefilter = () => {
    this.setState(prevState => ({
      filterdropdownOpen: !prevState.filterdropdownOpen
    }));
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  TabMapper = item => (
    <NavItem key={item.id}>
      <NavLink
        className={classnames({
          active: this.state.activeTab === item.id
        })}
        onClick={() => {
          this.toggle(item.id);
        }}
      >
        {item.TabName}
      </NavLink>
    </NavItem>
  );

  render() {
    const { tabID, activeTab } = this.state;
    return (
      <div
        className={`campaignStudioWrapper__mediaWrapper ${
          activeTab === "Widget" ? "campaign-widget" : ""
        }`}
      >
        <Nav tabs>
          {/* Nav Item Navigation List */}
          {tabID.map(item => this.TabMapper(item))}
          {/* Nav Item Navigation List End Here */}

          <div className="ml-auto">
            <div>
              {this.state.activeTab === "Media" && (
                <Dropdown
                  isOpen={this.state.filterdropdownOpen}
                  toggle={this.togglefilter}
                  className="d-inline-block"
                >
                  <DropdownToggle className="btn btn-outline-primary media-sort">
                    <i className="bx bx-filter " />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem header>Filter By</DropdownItem>
                    <DropdownItem>
                      <span
                        role="presentation"
                        disabled
                        onClick={() => {
                          this.props.handleFilter("", this.state.activeTab);
                          this.inputFilter("");
                        }}
                      >
                        All{" "}
                      </span>
                    </DropdownItem>
                    <DropdownItem>
                      <span
                        role="presentation"
                        onClick={() => {
                          this.props.handleFilter(
                            "video",
                            this.state.activeTab
                          );
                          this.inputFilter("video");
                        }}
                      >
                        Video{" "}
                      </span>
                    </DropdownItem>
                    <DropdownItem>
                      <span
                        role="presentation"
                        onClick={() => {
                          this.props.handleFilter(
                            "image",
                            this.state.activeTab
                          );
                          this.inputFilter("image");
                        }}
                      >
                        Image
                      </span>
                    </DropdownItem>
                    <DropdownItem>
                      <span
                        role="presentation"
                        onClick={() => {
                          this.props.handleFilter(
                            "audio",
                            this.state.activeTab
                          );
                          this.inputFilter("audio");
                        }}
                      >
                        Audio
                      </span>
                    </DropdownItem>
                    <DropdownItem>
                      <span
                        role="presentation"
                        onClick={() => {
                          this.props.handleFilter(
                            "true",
                            this.state.activeTab
                          );
                          this.inputFilter("true");
                        }}
                      >
                        Filler
                      </span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem header>Sort By</DropdownItem>
                    <DropdownItem>
                      <span
                        role="presentation"
                        disabled
                        onClick={() => {
                          this.props.handleContentSearch(
                            `/contents?sort=name&sort_by=asc&page=1&per_page=18`,
                            "true",
                            this.state.activeTab
                          );
                          this.inputSort("name", "asc");
                        }}
                      >
                        Name{" "}
                      </span>
                    </DropdownItem>
                    <DropdownItem>
                      <span
                        role="presentation"
                        onClick={() => {
                          this.props.handleContentSearch(
                            `/contents?sort=updated_at&sort_by=desc&page=1&per_page=18`,
                            "true",
                            this.state.activeTab
                          );
                          this.inputSort("updated_at", "desc");
                        }}
                      >
                        Date added
                      </span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}

              {/* <i className="fa fa-sort-amount-down dropdown dropdown--filter ml-2">
                <div className="dropdown-content dropdown-sort">
                  <div className="caret" />
                  <li
                    onClick={() => this.props.handleContentSort(`/contents?sort=name&sort_by=asc`)}
                  >
                    Name
                  </li>
                  <li
                    onClick={() =>
                      this.props.handleContentSort(`/contents?sort=updated_at&sort_by=desc`)
                    }
                  >
                    Date added
                  </li>
                </div>
              </i>

              <i className="fas fa-filter dropdown dropdown--filter">
                <div className="dropdown-content">
                  <div className="caret" />
                  <li onClick={() => this.props.handleFilter("")}>All</li>
                  <li onClick={() => this.props.handleFilter("video")}>Video</li>
                  <li onClick={() => this.props.handleFilter("image")}>Image</li>
                  <li onClick={() => this.props.handleFilter("audio")}>Audio</li>
                </div>
              </i> */}
              {/* Filter Elmement End Here */}
            </div>
          </div>
        </Nav>

        <TabSearch
          ref={this.props.forwardRef}
          {...this.props}
          inputSearchName={this.inputSearchName}
          inputSearchTag={this.inputSearchTag}
          activeTab={activeTab}
          toggleFetch={this.toggleFetch}
          campaignLoading={this.props.campaignLoading}
        />

        <TabContent
          activeTab={activeTab}
          className={
            activeTab === "Media"
              ? "tab-content--media-tab campaignmedia_box"
              : "tab-content--media-tab campaignmedia_box"
          }
        >
          {/* Media Tab Component contain Only Media List */}
          <TabPane tabId="Media">
            <MediaTab
              isLoading={this.props.isLoading}
              fetch={this.state.fetch}
              perPage={this.state.per_page}
              increasePerPage={this.increasePerPage}
              toggleFetch={this.toggleFetch}
              contentSearchByName={this.state.contentSearchByName}
              contentSearchByTag={this.state.contentSearchByTag}
              contentFilter={this.state.contentFilter}
              contentSort={this.state.contentSort}
              sortOrder={this.state.sortOrder}
              campaignLoading={this.props.campaignLoading}
              selectedOptionType={this.state.selectedOptionType}
              // filter={this.props.filter}
              {...this.props}
            />
          </TabPane>

          {/* Text Tab Component contain Only Media List */}
          <TabPane tabId="Playlist">
            <Playlist
              isLoading={this.props.isLoading}
              fetch={this.state.fetchPlaylist}
              perPage={this.state.per_page_playlist}
              increasePerPage={this.increasePerPage}
              toggleFetch={this.togglePlaylistFetch}
              playlistSearch={this.state.playlistSearch}
              campaignLoading={this.props.campaignLoading}
              {...this.props}
            />
          </TabPane>
          {/* Widget Tab Component contain Only Media List */}
          <TabPane tabId="Widget">
            <Widget {...this.props} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
