import React from "react";
import Select, { components } from "react-select";
import { connect } from "react-redux";

import { Get } from "../../constant/thunk";
import { Resources } from "../../features/calendar/redux";
import PhoneBreakpoint from "../../context/phone_breakpoint";

const menuHeaderStyle = {
  color: "#2cc277"
};

const MenuList = props => (
  <components.MenuList {...props}>
    {props.children}
    <PhoneBreakpoint>
      {props.selectProps.hasMore && (
        <span
          role="presentation"
          onClick={() => props.selectProps.userScrollAtEnd()}
          className="customSelectMenuListForMobile"
          style={menuHeaderStyle}
        >
          Load More
        </span>
      )}
    </PhoneBreakpoint>
  </components.MenuList>
);

class SelectAsyncCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      search: "",
      hasMore: true
    };
  }

  getCalendar = () => {
    const { getTotalCalendar } = Resources;
    getTotalCalendar.url = `/calendars?page=${this.state.page}&per_page=20`;
    this.props.Get(Resources.getTotalCalendar).then(res => {
      if (res.status === 200) {
        this.setState({
          hasMore: res.data.meta.totalPages > res.data.meta.page
        });
      }
    });
  };

  searchCalendar = async keyword => {
    const { searchTotalCalendar } = Resources;
    searchTotalCalendar.url = `/calendars/search?search=${keyword}&page=${this.state.page}&per_page=20`;
    this.props.Get(Resources.searchTotalCalendar).then(res => {
      if (res.status === 200) {
        this.setState({
          hasMore: res.data.meta.totalPages > res.data.meta.page
        });
      }
    });
  };

  userScrollAtEnd = () => {
    this.setState(
      prevState => ({ page: prevState.page + 1 }),
      () => {
        if (!this.state.search || this.state.search.hasOwnProperty("label")) {
          this.getCalendar();
        } else {
          this.searchCalendar(this.state.search);
        }
      }
    );
  };

  handleClose = () => {
    this.setState({ page: 1 }, () => {
      const { getTotalCampaign } = Resources;
      getTotalCampaign.url = `/campaigns?page=${this.state.page}&per_page=20`;
      this.props.Get(Resources.getTotalCampaign).then(response => {
        if (response.data.meta.totalPages <= response.data.meta.page) {
          this.setState({ fetch: false });
        }
      });
    });
  };

  handleChange = (value, onChange, setPage, setSearch, hit, setHit) => {
    this.setState({ search: value });
    if (value && value.hasOwnProperty("label")) {
      onChange(value);
    } else if (value === null || value === "" || !value) {
      // this.setState({ page: 1 });
      this.setState({ page: 1 }, () => {
        this.getCalendar();
      });
    } else if (typeof value === "string") {
      this.setState({ page: 1 }, () => {
        this.searchCalendar(value);
      });
    } else {
      this.getCalendar();
    }
  };

  optionsFormatter = ({ id, attributes: { name, updated_at } }) => ({
    label: name,
    value: id,
    updated: updated_at
  });

  optionsGenerator = data => {
    if (data.hasOwnProperty("initialState")) {
      return [];
    }
    if (data.hasOwnProperty("list")) {
      return data.list.map(this.optionsFormatter);
    }
    return data;
  };

  onKeyDown = e => {
    const target = e.currentTarget.children[2].children[0];
    const { scrollHeight } = target;
    const { scrollTop } = target;
    const { clientHeight } = target;
    if (scrollHeight - scrollTop === clientHeight + 71) {
      this.userScrollAtEnd();
    }
  };

  render() {
    return (
      <Select
        // options={sortBy(data, ["created"]).reverse()}
        // options={sortBy(this.optionsGenerator(this.props.campaignList), [
        //   "updated"
        // ]).reverse()}
        options={this.props.calendarList.map(item => ({
          value: item.id,
          label:
            item.attributes.name.length > 60
              ? `${item.attributes.name.slice(0, 60)}..`
              : item.attributes.name,
          metaOptions: item
        }))}
        isSearchable
        value={this.props.input.value || ""}
        onMenuOpen={e => this.handleOnMenuOpen}
        // onMenuClose={e => this.handleClose(e)}
        onMenuScrollToBottom={e => this.userScrollAtEnd(e)}
        userScrollAtEnd={this.userScrollAtEnd}
        onChange={e => this.handleChange(e, this.props.input.onChange)}
        onInputChange={e => this.handleChange(e, this.props.input.onChange)}
        components={{ MenuList }}
        hasMore={this.state.hasMore}
        onKeyDown={e => this.onKeyDown(e)}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const campaignList = state.totalCampaign.list;
  const calendarList = state.totalCalendar ? state.totalCalendar.list : [];
  return {
    campaignList: campaignList !== undefined ? campaignList : [],
    calendarList: calendarList !== undefined ? calendarList : []
  };
}

export default connect(
  mapStateToProps,
  { Get }
)(SelectAsyncCalendar);
