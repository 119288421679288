import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Tooltip } from "../../../core";

export const CustomToggleList = ({
  columns,
  onColumnToggle,
  toggles,
  hiddenColumn,
  handleChangeColumn
}) => {
  // debugger;
  const formattedCol =
    columns &&
    columns
      .filter(
        column =>
          column.columnName !== "name" && column.columnName !== "action_buttons"
      )
      .map(column => ({
        ...column,
        toggle: toggles[column.dataField]
      }));
  // const colOnTheTable = formattedCol.filter(item => item.toggle);
  // const random = Math.floor(Math.random() * 4);
  // if (colOnTheTable.length > 4) {
  //   const toggleList = colOnTheTable[random];
  //   onColumnToggle(toggleList.dataField);
  // }
  function handleChangeToggle(column) {
    handleChangeColumn(column, !hiddenColumn[column]);
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle className="iconButton" caret>
        <Tooltip
          item={<i className="bx bx-list-ul" style={{ color: "#8e8e8e" }} />}
          id="togglecol"
          tooltipContent="Toggle Column"
        />
      </DropdownToggle>
      <DropdownMenu right>
        {formattedCol &&
          formattedCol.map(column => (
            <DropdownItem toggle={false}>
              <span
                role="button"
                key={column.dataField}
                // style={{ background: column.toggle ? "red" : "blue" }}
                // aria-pressed={column.toggle ? "true" : "false"}
                onMouseDown={() => onColumnToggle(column.columnName)}
                onClick={() => handleChangeToggle(column.columnName)}
              >
                <i
                  className="fa fa-check"
                  style={{ opacity: column.toggle ? 1 : 0 }}
                />
                {column.text}
              </span>
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
