import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";
import { getLocalTime } from "../../../helper/helperFuncs";

export function columns(
  roles,
  permission,
  handleDelete,
  handleSort,
  sortOrder,
  handleDuplicate,
  handleArchiveItem,
  hiddenColumns
) {
  return [
    {
      dataField: "attributes.name",
      text: "Name",
      columnName: "name",
      hidden: hiddenColumns.name,
      headerStyle: { width: "400px" },
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("name")}
            className="sort-by "
            style={{
              color: sortOrder.name.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            {" "}
            Name
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.name.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        // eslint-disable-next-line no-unused-expressions
        <div className="thumb">
          <div className="content-thumb-image">
            <img
              src={
                row.attributes.campaign_thumbnail_url ||
                require("../../../assets/images/empty-img.png")
              }
              alt={name || undefined}
              className="content__thumb"
            />
            <div className="playlist-thumbnail-overlay">
              <img
                src={require("../../../assets/images/campaign_play.png")}
                alt={name || undefined}
                className="overlay-img content__thumb "
                style={{
                  height: "14px",
                  width: "14px",
                  bottom: "2px",
                  left: "34px"
                }}
              />
            </div>
          </div>
          {permission.campaign.edit.some(r => roles.includes(r)) ? (
            <Link
              to={{
                pathname: `/campaign/edit/${row.id}`,
                state: { name: row.attributes.name || "" }
              }}
              onClick={e => e.stopPropagation()}
            >
              <span className="content__titlename">
                {name.length >= 35 ? (
                  <Tooltip
                    item={
                      <span className="content__name">
                        {" "}
                        {`${name.slice(0, 35)}...`}{" "}
                      </span>
                    }
                    id={`campaignViewName-${row.id}`}
                    tooltipContent={name}
                  />
                ) : (
                  <span className="content__name">{name}</span>
                )}
              </span>{" "}
              <span className="contentType">
                {`${
                  row.attributes.campaign_contents_count > 1
                    ? "Contents"
                    : "Content"
                }: `}
                {row.attributes.campaign_contents_count}
              </span>
            </Link>
          ) : (
            <div>
              <span className="content__titlename">
                {name.length >= 25 ? (
                  <Tooltip
                    item={
                      <span className="content__name">
                        {" "}
                        {`${name.slice(0, 25)}...`}{" "}
                      </span>
                    }
                    id={`campaignViewName-${row.id}`}
                    tooltipContent={name}
                  />
                ) : (
                  <span className="content__name">{name}</span>
                )}
              </span>
              <span className="contentType">
                {`${
                  row.attributes.campaign_contents_count > 1
                    ? "Contents"
                    : "Content"
                }: `}
                {row.attributes.campaign_contents_count}
              </span>
            </div>
          )}
        </div>
      )
    },
    {
      dataField: "attributes.subwindow_number",
      text: "Region",
      columnName: "screen",
      hidden: hiddenColumns.screen,
      headerStyle: { width: "150px"  },
      headerAlign: 'center',
      style: { textAlign: "center" },
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("screen_size")}
            className="sort-by "
            style={{
              color: sortOrder.screen_size.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Region
            <i
              role="presentation"
              className={`fa fa-sort-alpha-${
                sortOrder.screen_size.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.orientation",
      text: "Orientation",
      columnName: "orientation",
      headerStyle: { width: "150px" },
      hidden: hiddenColumns.orientation,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("orientation")}
            className="sort-by "
            style={{
              color: sortOrder.orientation.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Orientation
            <i
              role="presentation"
              className={`fa fa-sort-alpha-${
                sortOrder.orientation.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      )
    },
    {
      dataField: "attributes.created_at",
      text: "Date Created",
      columnName: "date_created",
      headerStyle: { width: "180px" },
      hidden: hiddenColumns.date_created,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("created_at")}
            className="sort-by "
            style={{
              color: sortOrder.created_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Created
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.created_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{getLocalTime(row)}</div>
    },
    {
      dataField: "attributes.updated_at",
      text: " Date Updated",
      columnName: "date_updated",
      headerStyle: { width: "180px" },
      hidden: hiddenColumns.date_updated,
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("updated_at")}
            className="sort-by "
            style={{
              color: sortOrder.updated_at.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Date Updated
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.updated_at.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: row => <div>{getLocalTime(row)}</div>
    },
    {
      dataField: "attributes.status",
      text: " Status",
      columnName: "status",
      hidden: hiddenColumns.status,
      headerStyle: { width: "100px" },
      headerAlign: 'center',
      style: { textAlign: "center" },
      headerFormatter: () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            role="presentation"
            onClick={() => handleSort("status")}
            className="sort-by "
            style={{
              color: sortOrder.status.active ? "#e44b32" : "#2B2B2B"
            }}
          >
            Status
            <i
              className={`fa fa-sort-alpha-${
                sortOrder.status.sortBy === "desc" ? "up" : "down"
              }`}
            />
          </span>
        </div>
      ),
      formatter: (name, row) => (
        <span
          className={`content-text ${
            row.attributes.status === "offline"
              ? "content__status__offline"
              : "content__status__online"
          }`}
        />
      )
    },
    {
      dataField: "actions",
      text: "",
      style: { position: "relative" },
      columnName: "isSelected",
      headerStyle: { width: "170px" },
      formatter: (name, row) => (
        <div className="d-flex">
          <div className="contentSectionTable__menu">
            <i className="fas fa-ellipsis-h" />
          </div>
          <div className="contentSectionTable__actionButtonWrapper">
            <RouteAllowed
              roles={roles || []}
              permission={permission.campaign.show}
            >
              <Link
                to={{
                  pathname: `/campaign/show/${row.id}`,
                  state: { name: row.attributes.name || "" },
                  params: { ...row, handleDelete, roles, permission }
                }}
                className="contentSectionTable__actionButtonWrapperCampaign__buttons"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Tooltip
                  item={
                    <i
                      className="fa fa-eye "
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    />
                  }
                  id={`view-${row.id}`}
                  tooltipContent="View"
                />
              </Link>
            </RouteAllowed>
            <RouteAllowed
              roles={roles || []}
              permission={permission.campaign.edit}
            >
              <Link
                to={{
                  pathname: `/campaign/edit/${row.id}`,
                  state: { name: row.attributes.name ? name : "" }
                }}
                className="contentSectionTable__actionButtonWrapperCampaign__buttons"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Tooltip
                  item={
                    <i
                      className="fa fa-edit"
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    />
                  }
                  id={`edit-${row.id}`}
                  tooltipContent="Edit"
                />
              </Link>
            </RouteAllowed>
            <RouteAllowed
              roles={roles || []}
              permission={permission.campaign.dublicate}
            >
              <span className="contentSectionTable__actionButtonWrapperCampaign__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();
                        return handleDuplicate(row.id);
                      }}
                      className="fa fa-clone action__clone"
                    />
                  }
                  id={`clone-${row.id}`}
                  tooltipContent="Duplicate"
                />
              </span>
            </RouteAllowed>
            <RouteAllowed
              roles={roles || []}
              permission={permission.campaign.delete}
            >
              <span className="contentSectionTable__actionButtonWrapperCampaign__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();
                        return handleDelete(row.id, row.attributes.name);
                      }}
                      className="fa fa-trash action__trash"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  id={`delete-${row.id}`}
                  tooltipContent="Delete"
                />
              </span>
            </RouteAllowed>
            <RouteAllowed
              roles={roles || []}
              permission={permission.archive.campaign}
            >
              <span className="contentSectionTable__actionButtonWrapperCampaign__buttons">
                <Tooltip
                  item={
                    <i
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();
                        return handleArchiveItem(row.id, row.attributes.name);
                      }}
                      className="fas fa-archive action-archive"
                    />
                  }
                  id={`archive-${row.id}`}
                  tooltipContent="Archive"
                />
              </span>
            </RouteAllowed>
          </div>
        </div>
      )
    }
  ];
}
