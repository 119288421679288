export const permission = {
  content: {
    show: [
      "General",
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "CalendarManager",
      "NetworkManager",
      "Admin",
      "SuperAdmin",
      "ReportManager"
    ],
    edit: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "Admin",
      "SuperAdmin",
      "ReportManager"
    ],
    delete: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "Admin",
      "SuperAdmin"
    ],
    dublicate: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "Admin",
      "SuperAdmin"
    ],
    creates: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "Admin",
      "SuperAdmin"
    ],
    rateAmountFieldAccess: ["Admin", "SuperAdmin", "ReportManager"],
    contentPartyBrand: ["Admin", "SuperAdmin", "ReportManager"],
    fillerContent: ["ReportManager", "Admin", "SuperAdmin"],
    contentDetailAccess: ["Admin", "SuperAdmin", "ContentManager"],
  },
  campaign: {
    show: [
      "CampaignManager",
      "CalendarManager",
      "NetworkManager",
      "Admin",
      "SuperAdmin"
    ],
    edit: ["CampaignManager", "CalendarManager", "Admin", "SuperAdmin"],
    delete: ["CampaignManager", "CalendarManager", "Admin", "SuperAdmin"],
    dublicate: ["CampaignManager", "CalendarManager", "Admin", "SuperAdmin"],
    creates: ["CampaignManager", "CalendarManager", "Admin", "SuperAdmin"]
  },
  user: {
    show: ["SuperAdmin", "Admin"],
    edit: ["SuperAdmin", "Admin"],
    delete: ["SuperAdmin", "Admin"],
    dublicate: ["SuperAdmin", "Admin"],
    creates: ["SuperAdmin", "Admin"]
  },
  calendar: {
    show: ["CalendarManager", "NetworkManager", "Admin", "SuperAdmin"],
    edit: ["CalendarManager", "Admin", "SuperAdmin"],
    delete: ["CalendarManager", "Admin", "SuperAdmin"],
    dublicate: ["CalendarManager", "Admin", "SuperAdmin"],
    creates: ["CalendarManager", "Admin", "SuperAdmin"]
  },
  playlist: {
    show: [
      "PlaylistManager",
      "CampaignManager",
      "CalendarManager",
      "Admin",
      "NetworkManager",
      "SuperAdmin"
    ],
    edit: ["PlaylistManager", "CampaignManager", "Admin", "SuperAdmin"],
    delete: ["PlaylistManager", "CampaignManager", "Admin", "SuperAdmin"],
    dublicate: ["PlaylistManager", "CampaignManager", "Admin", "SuperAdmin"],
    creates: ["PlaylistManager", "CampaignManager", "Admin", "SuperAdmin"],
    lock: ["SuperAdmin"]
  },
  ticket: {
    show: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "CalendarManager",
      "Admin",
      "SuperAdmin"
    ],
    edit: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "CalendarManager",
      "Admin",
      "SuperAdmin"
    ],
    delete: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "CalendarManager",
      "Admin",
      "SuperAdmin"
    ],
    creates: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "CalendarManager",
      "Admin",
      "SuperAdmin"
    ],
    notification: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "CalendarManager",
      "Admin",
      "SuperAdmin"
    ]
  },
  network: {
    show: ["NetworkManager", "SuperAdmin"],
    edit: ["NetworkManager", "SuperAdmin"],
    delete: ["NetworkManager", "SuperAdmin"],
    dublicate: ["NetworkManager", "SuperAdmin"],
    creates: ["NetworkManager", "SuperAdmin"]
  },
  domain: {
    show: [
      "SuperAdmin",
      "Admin",
      "PlaylistManager",
      "CampaignManager",
      "CalendarManager",
      "NetworkManager"
    ],
    edit: ["SuperAdmin", "Admin"],
    delete: ["SuperAdmin"],
    dublicate: ["SuperAdmin"],
    creates: ["SuperAdmin"],
    editInventorySection: ["CalendarManager", "Admin", "SuperAdmin"]
  },
  archive: {
    show: [
      "SuperAdmin",
      "Admin",
      "ContentManager",
      "PlaylistManager",
      "CampaignManager",
      "CalendarManager",
      "NetworkManager"
    ],
    edit: ["SuperAdmin", "Admin"],
    delete: ["SuperAdmin", "Admin"],
    content: [
      "ContentManager",
      "CampaignManager",
      "PlaylistManager",
      "Admin",
      "SuperAdmin"
    ],
    playlist: ["PlaylistManager", "CampaignManager", "Admin", "SuperAdmin"],
    campaign: ["CampaignManager", "CalendarManager", "Admin", "SuperAdmin"],
    calendar: ["CalendarManager", "Admin", "SuperAdmin"]
  },
  metrics: {
    show: ["SuperAdmin"]
  },
  devices: {
    delete: ["SuperAdmin"]
  }
};
