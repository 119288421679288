import React, { Component } from "react";
import Hls from "hls.js";
import { Player, ControlBar } from "video-react";

class HLSSource extends Component {
  constructor(props, context) {
    super(props, context);
    this.hls = new Hls();
  }

  componentDidMount() {
    this.handleSourceCreate(this.props.src);
  }

  componentWillUnmount() {
    this.handleSourceDestroy();
  }

  // eslint-disable-next-line react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      // just remove previous hls and create new hls streamer
      this.handleSourceDestroy();
      this.hls = new Hls();
      this.handleSourceCreate(nextProps.src);
    }
  }

  handleSourceCreate = source => {
    // `src` is the property get from this component
    // `video` is the property insert from `Video` component
    // `video` is the html5 video element
    const { video } = this.props;
    // load hls video source base on hls.js
    if (Hls.isSupported()) {
      this.hls.loadSource(source);
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    }
  };

  handleSourceDestroy = () => {
    // destroy hls video source
    if (this.hls) {
      this.hls.destroy();
    }
  };

  render() {
    return (
      <source
        src={this.props.src}
        type={this.props.type || "application/x-mpegURL"}
      />
    );
  }
}

export default props => (
  <Player fluid={false} height={props.height} width={props.width} autoplay>
    <HLSSource isVideoChild src={props.src} />
    <ControlBar
      autoHide
      disableDefaultControls={props.disableDefaultControls || false}
      className="my-class"
    />
  </Player>
);
