import { uniqBy } from "lodash";

export const Resources = {
  getReports: {
    url: "/reports",
    asyncActions: {
      init: "getReport_INIT",
      success: "getReport_SUCCESS",
      error: "getReport_ERROR"
    }
  },
  filterReports: {
    url: "/api/v1/reports/filter_report",
    asyncActions: {
      init: "filterReport_INIT",
      success: "filterReport_SUCCESS",
      error: "filterReport_ERROR"
      // successMsg: "Reports filtered successfully.",
      // errorMsg: "Unable to filter the reports. Please try again later."
    }
  },
  downloadReport: {
    url: "/reports/report_as_csv",
    asyncActions: {
      init: "_INIT",
      success: "downloadReport_SUCCESS",
      error: "downloadReport_ERROR",
      successMsg: "Email Sent Successfully"
    }
  }
};

/**
 *
 * This is static report Format for test and build
 */

const initialState = {
  list: [],
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined,
  url: "http://dummy.com/reports?page=1"
};

function checkSameURL(prevURLString, nextURLString) {
  const prevURL = new URL(prevURLString);
  const nextURL = new URL(nextURLString);
  const previousPage = prevURL.searchParams.get("page");
  const nextPage = nextURL.searchParams.get("page");
  return Number(previousPage) + 1 === Number(nextPage);
}

export function reports(state = initialState, action = {}) {
  const { init, success, error } = Resources.getReports.asyncActions;
  const {
    filterReports: { asyncActions }
  } = Resources;

  switch (action.type) {
    case asyncActions.init:
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      const url = action.payload.request.responseURL;
      const preservePreviousData = checkSameURL(state.url, url);
      return {
        list: preservePreviousData
          ? uniqBy([...state.list, ...data], "id")
          : uniqBy([...data], "id"),
        links,
        status,
        url,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case asyncActions.error:
    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    case asyncActions.success:
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      const url = action.payload.request.responseURL;
      const preservePreviousData = checkSameURL(state.url, url);
      return {
        list: preservePreviousData
          ? uniqBy([...state.list, ...data], "id")
          : uniqBy([...data], "id"),
        links,
        status,
        statusText,
        meta,
        url,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };

    default: {
      return state;
    }
  }
}
