/* eslint-disable react/no-multi-comp */
import React, { Component } from "react";
import { Stage, Layer } from "react-konva";
import { connect } from "react-redux";
import { sortBy } from "lodash";
import Rectangle from "./rectangle";
import TransformerComponent from "./transformer";
import { updateCampaign } from "../../redux";

class Split extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedShapeName: ""
    };
    this.edgeDetection = 16;
  }

  handleTransformationChange = boundBox => {
    const { campaign } = this.props;

    const editedScreen = campaign.screens.filter(
      item => item.localID === this.state.selectedShapeName
    );

    editedScreen[0].screenX = boundBox.x;
    editedScreen[0].screenY = boundBox.y;
    editedScreen[0].height = boundBox.height;
    editedScreen[0].width = boundBox.width;
    editedScreen[0].snappingTarget = boundBox.snappedObject;
    const formattedScreen = campaign.getScale(
      editedScreen[0],
      editedScreen[0].fillPatternImage,
      editedScreen[0].scale,
      editedScreen[0].defaultImageContentType
    );
    campaign.updateScreen(formattedScreen.localID, formattedScreen);
    this.props.updateCampaign(campaign);
  };

  handleStageMouseDown = e => {
    // clicked on stage - clear selection
    if (e.target === e.target.getStage()) {
      this.setState({
        selectedShapeName: ""
      });
      return;
    }
    // clicked on transformer - do nothing
    const clickedOnTransformer =
      e.target.getParent().className === "Transformer";
    if (clickedOnTransformer) {
      return;
    }

    // find clicked rect by its name
    const name = e.target.name();
    const rect = this.props.campaign.screens.find(r => r.localID === name);
    if (rect) {
      this.setState({
        selectedShapeName: rect.localID
      });
    } else {
      this.setState({
        selectedShapeName: ""
      });
    }
  };

  handleXYChange = (screen, id) => {
    const { campaign } = this.props;
    campaign.updateScreen(id, screen);
    this.props.updateCampaign(campaign);
  };

  checkInititalScreen = () => {
    const { screens } = this.props.campaign;
    return screens.map(item => {
      const temp = item;
      const filteredContents = item.contents.filter(
        content => content.destroy !== 1
      );
      if (filteredContents.length > 0) {
        if (filteredContents.destroy && filteredContents.destroy === 1) {
          temp.defaultImage = temp.sourceImage;
          temp.defaultImageContentType =
            temp.type === "playlist" && temp.meta
              ? temp.meta.attributes.playlist_thumbnail_content_type
              : temp.contentType || temp.type;
          return temp;
        }

        temp.defaultImage =
          filteredContents[0].contentType &&
          filteredContents[0].contentType.search("image") > -1
            ? filteredContents[0].preview
            : filteredContents[0].contentThumbnail;

        temp.defaultImageContentType =
          filteredContents[0].type === "playlist" && filteredContents[0].meta
            ? filteredContents[0].meta.attributes
                .playlist_thumbnail_content_type
            : filteredContents[0].contentType || filteredContents[0].type;
        return temp;
      }
      if (temp.sourceImage === temp.defaultImage) {
        return item;
      }
      temp.defaultImage = temp.sourceImage;
      temp.defaultImageContentType = "image";

      return temp;
    });
  };

  handleContentDrop = screenID => {
    const { campaign, updateCampaign: fn } = this.props;
    if (
      campaign.contentIsBeingDragged &&
      campaign.contentToBeDropped.length > 0
    ) {
      campaign.unLoadContentToBeDropped(screenID);
      fn(campaign);
    } else if (
      campaign.playlistIsBeingDragged &&
      campaign.playlistToBeDropped.length > 0
    ) {
      campaign.addPlaylist(screenID);
      fn(campaign);
    } else if (
      campaign.WeatherIsBeingDragged &&
      campaign.WidgetIsBeingDragged
    ) {
      campaign.addWeather(screenID);
      fn(campaign);
    } else if (campaign.TextIsBeingDragged && campaign.WidgetIsBeingDragged) {
      campaign.addText(screenID);
      fn(campaign);
    } else if (campaign.URLIsBeingDragged && campaign.WidgetIsBeingDragged) {
      campaign.addURL(screenID);
      fn(campaign);
    } else if (campaign.HLSIsBeingDragged && campaign.WidgetIsBeingDragged) {
      campaign.addHLS(screenID);
      fn(campaign);
    } else if (campaign.RSSIsBeingDragged && campaign.WidgetIsBeingDragged) {
      campaign.addRSS(screenID);
      fn(campaign);
    } else if (
      campaign.YoutubeIsBeingDragged &&
      campaign.WidgetIsBeingDragged
    ) {
      campaign.addYoutube(screenID);
      fn(campaign);
    } else if (
      campaign.PicToScreenIsBeingDragged &&
      campaign.WidgetIsBeingDragged
    ) {
      campaign.addPicToScreen(screenID);
      fn(campaign);
    } else if (
      campaign.DateTimeIsBeingDragged &&
      campaign.WidgetIsBeingDragged
    ) {
      campaign.addDateTime(screenID);
      fn(campaign);
    } else if (campaign.RouteIsBeingDragged && campaign.WidgetIsBeingDragged) {
      campaign.addRoute(screenID);
      fn(campaign);
    } else if (
      campaign.TransparentIsBeingDragged &&
      campaign.WidgetIsBeingDragged
    ) {
      campaign.addTransparent(screenID);
      fn(campaign);
    } else {
      return 0;
    }
  };

  render() {
    const { campaign } = this.props;
    this.checkInititalScreen();
    return (
      <div>
        <Stage
          width={campaign.studioWidth}
          height={campaign.studioHeight}
          onMouseDown={this.handleStageMouseDown}
          on
        >
          <Layer>
            {this.props.campaign.screens.map((rect, i) => (
              <Rectangle
                key={rect.localID}
                screen={rect}
                style={{ maxWidth: "660px", maxHeight: "360px" }}
                handleXYChange={this.handleXYChange}
                handleContentDrop={this.handleContentDrop}
                studioWidth={campaign.studioWidth}
                studioHeight={campaign.studioHeight}
                {...rect}
                {...this.props}
              />
            ))}
            <TransformerComponent
              handleTransformationChange={this.handleTransformationChange}
              selectedShapeName={this.state.selectedShapeName}
              campaign={this.props.campaign}
            />
          </Layer>
        </Stage>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaign: state.newCampaign.campaign
  };
}

export default connect(
  mapStateToProps,
  { updateCampaign }
)(Split);
