/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { Field, reduxForm, change, formValueSelector, destroy, unregisterField } from "redux-form";
import { remove } from "lodash";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Resources, ContentTagResources, SponserTagResources, PartyTagResources, BrandTagResources } from "../redux";
import UploadNewContent from "./contentUpload";

import ContentDropzone from "../../../core/modal/contentDropZone";

const selector = formValueSelector("ContentUploadForms");

const getIconForFile = item => {
  switch (item.type) {
    case "Video":
      return (
        <i
          className="far fa-play-circle"
          style={{ fontSize: "45px", color: "#00c1de" }}
        />
      );
    case "Audio":
      return (
        <i
          className="fas fa-music"
          style={{ fontSize: "45px", color: "#00c1de" }}
        />
      );
    default:
      return (
        <i
          className="far fa-image"
          style={{ fontSize: "45px", color: "#00c1de" }}
        />
      );
  }
};

const FileList = (item, handleDelete) => (
  <div key={item.id} className="Uploadlist--selectedfile">
    <div className="Uploadlist-thumb">{getIconForFile(item)}</div>
    <div className="itemname">
      <span>{item.name}</span>
    </div>
    <div className="closeicon">
      <i
        onClick={() => handleDelete(item.id)}
        className="fas fa-times Uploadlist__close "
      />
    </div>
  </div>
);

class AddContent extends Component {
  constructor(props) {
    super(props);
    if (
      !this.props.history.location.state ||
      !this.props.history.location.state.fromContentUpload
    ) {
      this.props.destroy();
      this.state = {
        visibleContentForm: false,
        fileList: []
      };
    } else {
      this.state = {
        fileList: this.props.files
      };
    }
    this.loadTags();
    this.loadSponserTag();
    this.loadPartyTag();
    // this.loadBrandTag();
  }

  onAddFile = fileList => {
    this.setState({ fileList: [...fileList], visibleContentForm: true });
  };

  loadTags = async () => {
    const { getContent } = ContentTagResources;
    await this.props.Get(getContent);
  };

  loadSponserTag = async () => {
    const { getContent } = SponserTagResources;
    await this.props.Get(getContent);
  };

  loadPartyTag = async () => {
    const { getContent } = PartyTagResources;
    await this.props.Get(getContent);
  };

  // loadBrandTag = async () => {
  //   const { getContent } = BrandTagResources;
  //   await this.props.Get(getContent);
  // };

  handleDelete = id => {
    const { fileList } = this.state;
    remove(fileList, { id });
    this.props.dispatch(change("ContentUploadForms", "Files", fileList));
    this.setState({ fileList });
  };

  onPressCancelAddContent = () => {
    this.props.destroy("ContentUploadForms");
    this.props.destroy("Contents");
    this.props.history.push("/dashboard/content");
  };

  handleRemoveButtonPressed = (files, item) => {
    remove(files, { ...item });
    if (files.length === 0) {
      this.setState({ fileList: [], visibleContentForm: false })
    } else {
      this.props.dispatch(change("ContentUploadForms", "Files", files));
    }
    this.forceUpdate();
  };

  render() {
    const { fileList } = this.state;
    return (
      <div className="mainPage">
        <div className="contentSection">
          {" "}
          <Field
            name="Files"
            component={ContentDropzone}
            onAddFile={this.onAddFile}
            history={this.props.history}
            files={this.props.files}
          />
          {this.state.visibleContentForm && (
            <UploadNewContent
              Get={this.props.Get}
              Post={this.props.Post}
              history={this.props.history}
              permission={this.props.permission}
              roles={this.props.roles}
              files={this.props.files}
              handleRemoveButtonPressed={this.handleRemoveButtonPressed}
            // {...this.props} passing all the props will blow up some features. so only send what's needed
            />
          )}
          {/* <div className="Uploadlist"> */}
          {/* {fileList.map(item => FileList(item, this.handleDelete))} */}
          {/* <div className="d-flex justify-content-end mt-4">
            <Button
              outline
              onClick={this.onPressCancelAddContent}
              className="btn btn-outline-primary mr-2"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className={`btn text-white ${
                !this.props.files.length > 0
                  ? "btn-primary disabled"
                  : "btn-primary"
              } `}
              disabled={!this.props.files.length > 0}
              onClick={() => {
                this.props.history.push("/dashboard/content/upload");
              }}
            >
              Next
            </Button>
          </div> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    files: selector(state, "Files") || []
  }),
  null
)(
  reduxForm({
    // a unique name for the form
    form: "ContentUploadForms",
    // destroyOnUnmount: false, // <------ preserve form data
    // forceUnregisterOnUnmount: false // <------ unregister fields on unmount
  })(AddContent)
);
