import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import {
  reduxForm,
  Field,
  formValueSelector,
  initialize as remoteInitialize
} from "redux-form";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { toLower, debounce } from "lodash";
import { required } from "../../../helper/validation";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { Dialog } from "../../../core";
import DomainUserTable from "./domainUserTable";
import { Resources, removeUserFromDomain } from "../redux/domainRedux";

// eslint-disable-next-line react/prefer-stateless-function
class DomainUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      edit: false,
      modal: false,
      isLoading: true,
      forcePage: undefined,
      searchVal: "",
      userModal: false,
      removeUserID: null,
      removeUserName: null
    };
  }

  componentDidMount() {
    this.loadDomainUsers();
  }

  toggleUserModal = () => {
    this.setState(state => ({ userModal: !state.userModal }));
  };

  handleRemoveUser = (id, name) => {
    this.setState({
      userModal: true,
      removeUserID: id,
      removeUserName: name
    });
  };

  /** Delete request for server to remove user */
  removeExistingUserFromDomain = async userId => {
    const { removeUserID } = this.state;
    /**  make a api call for removing user from domain with received id */
    const { updateDomain } = Resources;
    updateDomain.url = `domains/${this.props.match.params.id}/remove_user`;
    updateDomain.body = removeUserFromDomain(
      this.props.match.params.id,
      removeUserID
    );
    await this.props.Put(updateDomain).then(() => {
      this.setState({
        userModal: false,
        removeUserID: null,
        removeUserName: null
      });
      if (this.state.searchVal) {
        this.setState({ searchVal: "" }, () => {
          this.loadDomainUsers();
        });
      } else {
        this.loadDomainUsers(this.props.meta.page);
      }
      this.props.loadGurzuUserFromServer();
    });
  };

  loadDomainUsers = async (data = 1) => {
    const { getDomainUsers } = Resources;
    getDomainUsers.url = `domains/${this.props.match.params.id}/domainusers_list?page=${data}&per_page=20`;
    this.props.Get(getDomainUsers).then(() => {
      this.setState({ isLoading: false });
    });
  };

  handleAddUser = flag => {
    this.setState({ edit: false, modal: flag });
    this.props.remoteInitialize("DomainUserForm", {
      users: {},
      roles: []
    });
  };

  toggleAddUser = flag => {
    this.setState({ modal: flag });
  };

  editUser = (id, name, roles) => {
    this.props.editUserRoles(id, name, roles);
    this.toggleAddUser(true);
    this.setState({ edit: true });
  };

  addUser = async values => {
    this.toggleAddUser(false);
    this.state.edit
      ? await this.props.updateUserToDomain(values)
      : await this.props.addNewUserToDomain(values);
    await this.loadDomainUsers();
    if (!this.state.edit) {
      this.props.loadGurzuUserFromServer();
    }
  };

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true, forcePage: undefined });
    const { getDomainUsers } = Resources;
    if (moveToPrevious) {
      if (this.state.searchVal) {
        getDomainUsers.url = `/domains/${this.props.selectedDomain
          }/domainusers_search/${this.state.searchVal}?page=${data.page -
          1}&per_page=20`;
        this.props.Get(getDomainUsers).then(() => {
          this.setState({ forcePage: data.page - 2, isLoading: false });
        });
      } else {
        getDomainUsers.url = `domains/${this.props.selectedDomain
          }/domainusers_list?page=${data.page - 1}&per_page=20`;
        this.props.Get(getDomainUsers).then(() => {
          this.setState({ forcePage: data.page - 2, isLoading: false });
        });
      }
    } else {
      const { selected } = data;
      if (this.state.searchVal) {
        getDomainUsers.url = `/domains/${this.props.selectedDomain
          }/domainusers_search/${this.state.searchVal}?page=${selected +
          1}&per_page=20`;
        this.props.Get(getDomainUsers).then(() => {
          this.setState({ forcePage: selected, isLoading: false });
        });
      } else {
        getDomainUsers.url = `domains/${this.props.selectedDomain
          }/domainusers_list?page=${selected + 1}&per_page=20`;
        this.props
          .Get(getDomainUsers)
          .then(() => this.setState({ forcePage: selected, isLoading: false }));
      }
    }
  };

  handleSearchValue = value => {
    this.setState({ searchVal: value, forcePage: 0 }, () => {
      this.handleSearch();
    });
  };

  handleSearch = debounce(() => {
    this.setState({ isLoading: true, forcePage: undefined });
    const { getDomainUsers } = Resources;
    if (this.state.searchVal === "") {
      getDomainUsers.url = `domains/${this.props.match.params.id}/domainusers_list?page=1&per_page=20`;
    } else {
      getDomainUsers.url = `/domains/${this.props.selectedDomain}/domainusers_search/${this.state.searchVal}?page=1&per_page=20`;
    }
    this.props.Get(getDomainUsers).then(() => {
      this.setState({ isLoading: false });
    });
  }, 3000);

  render() {
    return (
      <div>
        <DomainUserTable
          currentUser={this.props.currentUser}
          users={this.props.domainUserList}
          editUser={this.editUser}
          removeExistingUserFromDomain={this.handleRemoveUser}
          isLoading={this.state.isLoading}
          // below props are used for domain user header component
          currentUserRolesDUH={this.props.currentUserRoles}
          editDUH={this.state.edit}
          handleAddUserDUH={this.handleAddUser}
          handleSearchValueDUH={this.handleSearchValue}
          searchValDUH={this.state.searchVal}
          {...this.props}
        />
        <div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggleAddUser}
            className={this.props.className}
          >
            <ModalHeader toggle={() => this.toggleAddUser(false)}>
              {this.state.edit ? "Edit User" : "Add User"}
            </ModalHeader>
            <ModalBody>
              {" "}
              <Form>
                <div className="globalForm">
                  {!this.state.edit && (
                    <FormGroup>
                      <div className="globalInput">
                        <Label>Users</Label>
                        <div className="mt-2">
                          <Field
                            component={MultipleSelect}
                            name="users"
                            validate={[required]}
                            options={this.props.allUsersList.map(item => ({
                              value: item.id,
                              label: item.attributes.name
                            }))}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <div className="globalInput">
                      <Label>Roles</Label>
                      <div className="mt-2">
                        <Field
                          component={MultipleSelect}
                          name="roles"
                          validate={[required]}
                          options={this.props.userRoles}
                          isMulti
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </Form>
            </ModalBody>
            <ModalFooter>
              <button
                className=" btn-outline-primary"
                onClick={() => this.toggleAddUser(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={this.props.handleSubmit(values =>
                  this.addUser(values)
                )}
                className="mt-0 primaryButton"
                disabled={
                  !!(
                    this.props.domainUsers.length === 0 ||
                    this.props.domainRoles.length === 0
                  )
                }
              >
                {this.state.edit ? "Update " : "Add "}
              </button>
            </ModalFooter>
          </Modal>
          {this.props.domainUserList && this.props.domainUserList.length > 0 && (
            <div className="contentSectionPagination clearfix">
              <ReactPaginate
                pageCount={this.props.meta ? this.props.meta.totalPages : 0}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                marginPagesDisplayed={2}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                previousLabel="<"
                forcePage={this.state.forcePage}
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                disabledClassName="paginationDisable"
              />
            </div>
          )}
        </div>
        <Dialog
          showHandler={false}
          externalControl
          modal={this.state.userModal}
          toggleModal={this.toggleUserModal}
          body={
            <span>
              <span>Are you sure you want to remove </span>
              <strong>{this.state.removeUserName}</strong>?
            </span>
          }
          primaryMethod={this.removeExistingUserFromDomain}
          toggleModel={this.toggleUserModal}
          title="Remove User"
        />
      </div>
    );
  }
}
function mapState(state) {
  const selector = formValueSelector("DomainUserForm");
  const { list, meta } = state.domainUsers;
  return {
    domainUsers: selector(state, "users") || [],
    domainRoles: selector(state, "roles") || [],
    domainUserList: list || [],
    meta
  };
}

export default connect(
  mapState,
  { remoteInitialize }
)(
  reduxForm({
    form: "DomainUserForm"
  })(DomainUser)
);
