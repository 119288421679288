/*
 * @Author: mikey.zhaopeng
 * @Date: 2019-07-04 22:23:16
 * @Last Modified by:   mikey.zhaopeng
 * @Last Modified time: 2019-07-04 22:23:16
 */
/** NPM Resources to get the component details.
 * https://react-dropzone.netlify.com/#proptypes
 */

import React from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Button } from "reactstrap";
import UploadNewContent from "../form/uploadNewContent";
import { Dialog } from "..";

const uuidv4 = require("uuid/v4");

export default class ContentDropzone extends React.Component {
  static defaultProps = {
    onDrop: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      dialogModal: {
        modal: false,
        body: "Are you sure you want to delete this content.",
        title: "Confirm Delete"
      }
    };
  }

  onDrop = (a, r) => {
    this.props.input.onChange([
      ...this.props.input.value,
      ...this.createDataWithUUID(a)
    ]);
    this.props.onAddFile([
      ...this.props.input.value,
      ...this.createDataWithUUID(a)
    ]);
    // this.props.history.push("/dashboard/content/upload");
  };

  onDropRejected = (file, event) => {
    const fileNameList = file.map(i => (
      <li
        key={i.path + Math.random()}
        style={{ fontSize: "18px", display: "list-item", marginLeft: "30px" }}
      >
        {i.path}
      </li>
    ));
    const body = (
      <div>
        <h3 className="mb-1">These file formats are not supported.</h3>
        <ul
          className="unsupported-fileName-list"
          style={{ listStyleType: "square" }}
        >
          {fileNameList}
        </ul>
      </div>
    );
    this.setState({
      dialogModal: {
        modal: true,
        body,
        title: "Unaccepted Format"
      }
    });
  };

  toggleModel = flag => {
    const { dialogModal } = this.state;
    dialogModal.modal = flag;
    this.setState({ dialogModal });
  };

  createDataWithUUID = a =>
    a.map(item => ({
      file: item,
      id: uuidv4(),
      url: URL.createObjectURL(item),
      name: item.name,
      tags: [],
      type: item.type.search("video") === -1 ? "Image" : "Video",
      expiration_date: "",
      sponsers: []
    }));

  render() {
    const { dialogModal } = this.state;
    return (
      <React.Fragment>
        <Dialog
          externalControl
          showHandler={false}
          modal={dialogModal.modal}
          body={dialogModal.body}
          title={dialogModal.title}
          toggleModel={this.toggleModel}
          primaryMethod={this.handleDelete}
          noFooter
        />
        <Dropzone
          onDrop={this.onDrop}
          accept={[".ogg", ".mp4", ".jpeg", ".png", ".gif", ".jpg"]}
          onDropRejected={this.onDropRejected}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={classNames(
                `dropzone d-flex justify-content-center ${
                  this.props.files.length > 0
                    ? "addcontent addcontent--small"
                    : "addcontent"
                } text-a`,
                {
                  "dropzone--isActive": isDragActive
                }
              )}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <div className="groupicon">
                  <i className="bx bx-cloud-upload bx-lg" />{" "}
                </div>
              ) : (
                <div
                  className={
                    this.props.files.length > 0
                      ? "groupicon groupicon--small"
                      : "groupicon"
                  }
                >
                  <div className="center text-center">
                    <i className="bx bx-cloud-upload bx-lg" />
                  </div>
                  <p>
                    Drag and Drop files here
                    <span className="d-block">
                      or <a href="#">browse</a> file to upload
                    </span>
                  </p>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </React.Fragment>
    );
  }
}
