/**
 * We are creating store and and history.
 */
import { createBrowserHistory } from "history";
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import promiseMiddleware from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistCombineReducers } from "redux-persist";
import thunk from "redux-thunk";

// Local Import
import reducers from "./reducer";
import moment from "moment";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userDetails"]
};
const middleware = [promiseMiddleware(), thunk];

const persistedReducer = (state, action) =>
  persistCombineReducers(persistConfig, { ...reducers })(
    action.type === "LOGOUT" ? undefined : state,
    action
  );

if (process.env.NODE_ENV === "development") {
  middleware.push(createLogger());
}

const store = createStore(
  persistedReducer,
  undefined,
  composeWithDevTools(
    applyMiddleware(...middleware)
    // other store enhancers if any
  )
);

const persistor = persistStore(store);

const history = createBrowserHistory();

export { store, persistor, history };
