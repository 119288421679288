/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { Table, Label, Form, FormGroup, Card, Button, Col } from "reactstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { uniqBy, remove } from "lodash";
import { required } from "../../../helper/validation";

// import Select from "react-select";
import { Input, Tooltip } from "../../../core";
import { MultipleSelect } from "../../../core/form/searchSelect";
import { Resources } from "../redux/networkRedux";
import { Resources as DeviceResources } from "../redux/deviceRedux";

class AddGroupTable extends Component {
  constructor(props) {
    super(props);
    this.props.change("devices", []);
    if (this.props.id) {
      this.initializeAPICallForEdit();
    } else {
      this.initializeAPICallCreate();
    }
    this.state = {
      isLoading: true
    };
  }

  /** Pre-load API call for creating group */
  initializeAPICallCreate = async () => {
    const allRequestPromise = [this.loadUnassignedDevices()];
    Promise.all(allRequestPromise).then(() => {
      this.setState({ isLoading: false });
    });
  };

  /** Pre-load api call for editig group */
  initializeAPICallForEdit = async () => {
    const allRequestPromise = [
      this.loadUnassignedDevices(),
      this.loadGroupDetails()
    ];
    Promise.all(allRequestPromise).then(() => {
      this.loadFormDataForEditing();
      this.setState({ isLoading: false });
    });
  };

  /** API call to get unassigned device list */
  loadUnassignedDevices = async () => {
    const { getUnassignedDevices } = Resources;
    await this.props.Get(getUnassignedDevices);
  };

  /** API call to get group Edit data */
  loadGroupDetails = async () => {
    const { getGroupById } = Resources;
    await this.props.Get(getGroupById(this.props.id));
  };

  loadFormDataForEditing = () => {
    const { devices, deviceGroups } = this.props.singleGroup;
    this.props.initialize({
      deviceGroupName: deviceGroups[this.props.id].attributes.name,
      devices: devices
        ? Object.values(devices).map(item => ({
            value: item.id,
            label: `${item.attributes.name} | ${item.attributes.mac_address}`,
            meta: item
          }))
        : []
    });
  };

  handleAddToList = () => {
    if (this.props.device) {
      this.props.change(
        "devices",
        uniqBy([...this.props.devices, ...this.props.device], "value")
      );
      this.props.change("device", []);
    }
  };

  handleDeleteFromDevicesList = id => {
    const { devices } = this.props;
    this.props.change("devices", devices.filter(item => item.value !== id));
  };

  handleGroupCreate = async values => {
    const { createEditGroup } = Resources;
    if (this.props.id) {
      const request = createEditGroup(this.props.id);
      request.body = request.bodyFunction(values);
      await this.props.Put(request);
      this.props.history.goBack();
    } else {
      const request = createEditGroup();
      request.url = "/device_groups";
      request.body = request.bodyFunction(values);
      await this.props.Post(request);
      this.props.history.goBack();
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="d-block text-center">
          {/* <div className="lds-dual-ring" /> */}
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    return (
      <div className="contentwrapper">
        <div className="d-flex contentheader ">
          <h5 className="p-0 mr-auto mt-2 mb-3">Create Group</h5>
        </div>
        <div className="groupTable">
          <Form className="row">
            <FormGroup className="col-md-4 " style={{ width: "20%" }}>
              {/* <Label for="exampleName">Group Name</Label> */}
              <Field
                component={Input}
                type="text"
                name="deviceGroupName"
                placeholder="Group Name"
                validate={required}
              />
            </FormGroup>
            <FormGroup
              className="col-md-5 mulitselectAction"
              style={{ width: "40%" }}
            >
              <Field
                component={MultipleSelect}
                name="device"
                isSearchable
                clearable
                placeholder="Select Device"
                style={{ display: "inline-block;" }}
                options={this.props.deviceList
                  .filter(
                    allDevices =>
                      this.props.devices.filter(
                        device => device.meta.id === allDevices.id
                      ).length === 0
                  )
                  .map(item => ({
                    value: item.id,
                    label: `${item.attributes.name} | ${item.attributes.mac_address}`,
                    meta: item
                  }))}
                closeMenuOnSelect={false}
                isMulti
              />
              <Field component="input" name="devices" hidden />
              <Button
                type="button"
                onClick={this.handleAddToList}
                className="float-right"
                disabled={this.props.device.length === 0}
              >
                Add To Group
              </Button>
            </FormGroup>
          </Form>

          {this.props.devices.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Mac Address</th>
                  <th>Host name</th>
                  <th>Orientation</th>
                  <th>Resolution</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {this.props.devices &&
                  this.props.devices.map(({ meta: { id, attributes } }) => (
                    <React.Fragment>
                      <tr key={id}>
                        <td width="200px">{attributes.name}</td>
                        <td>{attributes.mac_address}</td>
                        <td>{attributes.hostname}</td>
                        <td>{attributes.orientation}</td>
                        <td>{attributes.resolution}</td>
                        <td>{attributes.status}</td>

                        <td>
                          <Tooltip
                            item={
                              <i
                                onClick={e =>
                                  this.handleDeleteFromDevicesList(id)
                                }
                                className="fas fa-times-circle"
                                style={{ cursor: "pointer" }}
                              />
                            }
                            id={`delete-${id}`}
                            tooltipContent="Remove"
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </Table>
          ) : (
            <React.Fragment>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Roles</th>
                    <th />
                  </tr>
                </thead>
                <tbody />
              </Table>
              <div
                className="drag-playlist "
                style={{ width: "100%", height: "100px" }}
              >
                <h5 className="pt-5">
                  There are no devices in this group yet.
                </h5>
              </div>
            </React.Fragment>
          )}
          <Button
            type="submit"
            color="primary"
            onClick={this.props.handleSubmit(this.handleGroupCreate)}
            className="btn btn-primary float-right mb-3 mr-0 mt-2"
          >
            Save
          </Button>
          <Button
            onClick={() => this.props.history.goBack()}
            type="button"
            color="primary"
            outline
            className="float-right mb-3 mr-0 mt-2"
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
function mapState(state, ownProps) {
  const {
    match: {
      params: { id }
    }
  } = ownProps;
  const { singleGroup, unassignedDevicesList } = state.networks;
  const selector = formValueSelector("CreateAndEditGroupForm");
  return {
    id,
    singleGroup,
    deviceList: unassignedDevicesList || [],
    device: selector(state, "device") || [],
    devices: selector(state, "devices") || []
  };
}
export default connect(mapState)(
  reduxForm({
    form: "CreateAndEditGroupForm"
  })(AddGroupTable)
);
/*



*/
