import React, { Component, Fragment } from "react";
import { FormGroup, Label, Button, ButtonGroup } from "reactstrap";
import { remove, isEmpty } from "lodash";
import { Field } from "redux-form";
import { Tooltip, Input } from "../../../core";
import { MultipleSelect } from "../../../core/form/searchSelect";
import DatePicker from "../../../core/form/datePicker";
import ContentModal from "./contentModal";

import { formValidator } from "../forms/playlistFormValidator";

export default class AddPlaylistSingleItem extends Component {
  constructor(props) {
    super(props);

    const { data: content } = props;
    this.state = {
      contentSelected: false,

      durationMinute: content.durationMinute,
      durationSecond: content.durationSecond,
      durationMinuteError: undefined,
      durationSecondError: undefined,

      restrictStartHour: content.restrictStartHour || undefined,
      restrictEndHour: content.restrictEndHour || undefined,
      restrictStartHourError: undefined,
      restrictEndHourError: undefined,

      startDate: content.startDate || undefined,
      endDate: content.endDate || undefined,
      startDateError: undefined,
      endDateError: undefined,

      weight: content.weight || undefined,
      weightError: undefined,

      playlistControl: this.props.playlistControl,

      context: content.context || {},

      tempType: "°C",
      temperatureAbove: 0,
      temperatureBelow: 0,
      contextModal: false
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const clearMessage = {};
    if (!nextProps.playlistControl.selectAll) {
      clearMessage.restrictStartHourError = null;
      clearMessage.restrictEndHourError = null;
      clearMessage.startDateError = null;
      clearMessage.endDateError = null;
      clearMessage.weightError = null;
    }

    if (nextProps.playlistControl !== prevState.playlistControl) {
      let obj = {};

      if (!nextProps.playlistControl.date) {
        obj = { ...obj, startDate: undefined, endDate: undefined };
      }

      if (!nextProps.playlistControl.restriction) {
        obj = {
          ...obj,
          restrictStartHour: undefined,
          restrictEndHour: undefined
        };
      }

      if (!nextProps.playlistControl.weight) {
        obj = { ...obj, weight: null };
      }

      const changedPropsForReduxStore = nextProps.playListData.map(item => {
        const newItem = item;
        if (item.uniqueKey === nextProps.data.uniqueKey) {
          if (!nextProps.playlistControl.date) {
            newItem.startDate = undefined;
            newItem.endDate = undefined;
          } else if (!nextProps.playlistControl.restriction) {
            newItem.restrictStartHour = undefined;
            newItem.restrictEndHour = undefined;
          } else if (!nextProps.playlistControl.weight) {
            newItem.weight = undefined;
          }
          return { ...newItem, ...obj };
        }
        return newItem;
      });

      nextProps.change(
        "addPlaylistForm",
        "playListData",
        changedPropsForReduxStore
      );
      return {
        playlistControl: nextProps.playlistControl,
        ...obj,
        ...clearMessage
      };
    }
  };

  handleDelete = uniqueKey => {
    const { playListData } = this.props;
    remove(playListData, { uniqueKey });

    this.props.change("addPlaylistForm", "playListData", playListData);
  };

  toggleContextModal = (contextModal = false) => {
    this.setState(state => ({ contextModal }));
  };

  handleFormDataChange = (value, name) => {
    this.setState({
      [name]: value
    });

    // validation starts here
    const result = formValidator(value, name, this.state);
    /**
     * sending boolean value to parent saying if the form is okay to be submitted or not
     * - if result.error is true form is not submittable
     * - else the form can be submitted
     */
    this.props.setIsSubmtable(!result.error);
    /** Triggering validation in form */
    this.setState({ ...result });
    // validation ends here

    const { uniqueKey, order } = this.props.data;
    const newList = this.props.playListData.map(item => {
      if (uniqueKey === item.uniqueKey) {
        return { ...this.props.data, [name]: value };
      }
      return item;
    });
    this.props.change("addPlaylistForm", "playListData", newList);
  };

  checkError = field =>
    this.state[field] && <p className="text-warning">{this.state[field]}</p>;

  render() {
    const { data: content, playlistControl, sortableItemStyle } = this.props;
    return (
      <div>
        <ul className="playlist-table__listitem">
          <li className="d-flex flex-row bd-highlight ml-2">
            <div
              className="thumb content-name"
              style={{
                minWidth: sortableItemStyle.minWidth,
                marginLeft: sortableItemStyle.marginLeft
              }}
            >
              {content.attributes.is_filler_content  &&
              content.attributes.is_filler_content === true ? (
                <div className="content-thumb-image">
                  <div
                    style={{
                      position: " absolute",
                      bottom: "0px",
                      right: "3px"
                    }}
                  >
                    <i
                      className="bx bxs-certification"
                      style={{ color: "#e44b32" }}
                    ></i>
                  </div>
                  <img
                    src={
                      content.attributes.s3_thumbnail_medium_url ||
                      require("../../../assets/images/empty-img.png")
                    }
                    alt={content.attributes.name || undefined}
                    className="content__thumb"
                    id={content.id}
                  />
                </div>
              ) : (
                <div className="content-thumb-image">
                  <img
                    src={
                      content.attributes.s3_thumbnail_medium_url ||
                      require("../../../assets/images/empty-img.png")
                    }
                    alt={content.attributes.name || undefined}
                    className="content__thumb"
                    id={content.id}
                  />
                </div>
              )}

              <div className="p-2 playlist-content-name-wrapper bd-highlight">
                {" "}
                {content.attributes.name.length >= 25 ? (
                  <Tooltip
                    item={
                      <span className="playlist-content-name">
                        {" "}
                        {`${content.attributes.name.slice(0, 25)}...`}{" "}
                      </span>
                    }
                    id={content.id}
                    tooltipContent={content.attributes.name}
                  />
                ) : (
                  <span className="playlist-content-name">
                    {content.attributes.name}
                  </span>
                )}
              </div>
            </div>
            <div className="p-2 bd-highlight" style={{ width: "120px" }}>
              <FormGroup className="form-control-sm mb-0  playlist__input-group">
                <input
                  type="number"
                  className="modal-duration mt-1 text-center"
                  name="minute"
                  min={0}
                  max={59}
                  disabled={
                    content.attributes.content_type.search("video") > -1
                  }
                  value={this.state.durationMinute}
                  onChange={e =>
                    this.handleFormDataChange(e.target.value, "durationMinute")
                  }
                  placeholder="MM"
                />
                <b>:</b>
                <input
                  type="number"
                  className="modal-duration mt-1"
                  name="second"
                  disabled={
                    content.attributes.content_type.search("video") > -1
                  }
                  min={0}
                  max={59}
                  value={this.state.durationSecond}
                  onChange={e =>
                    this.handleFormDataChange(e.target.value, "durationSecond")
                  }
                  placeholder="SS"
                />
                {this.checkError("durationSecondError")}
                {this.checkError("durationMinuteError")}
              </FormGroup>
            </div>
            {playlistControl.date && (
              <div
                className="p-2 bd-highlight "
                style={{
                  width: "332px",
                  fontSize: ".875rem",
                  marginTop: "1px"
                }}
              >
                {" "}
                <div className="inputDates">
                  <FormGroup>
                    {/* <Label className="playlistFormLabel">Starting Date</Label> */}
                    <DatePicker
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      name="startDate"
                      placeholderText="MM/DD/YYYY"
                      autocomplete="off"
                      input={{
                        onChange: date =>
                          this.handleFormDataChange(date, "startDate"),
                        value: this.state.startDate
                      }}
                      customInput={this.props.customDatePickerInput}
                    />
                  </FormGroup>
                </div>
                <div className="inputDates">
                  <FormGroup>
                    {/* <Label className="playlistFormLabel">Ending Date</Label> */}
                    <DatePicker
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      name="endDate"
                      placeholderText="MM/DD/YYYY"
                      autocomplete="off"
                      input={{
                        onChange: date =>
                          this.handleFormDataChange(date, "endDate"),
                        value: this.state.endDate
                      }}
                      customInput={this.props.customDatePickerInput}
                    />
                  </FormGroup>
                </div>
                {this.checkError("startDateError")}
                {this.checkError("endDateError")}
              </div>
            )}
            {playlistControl.restriction && (
              <div
                className="p-2 bd-highlight"
                style={{
                  width: "200px",
                  fontSize: ".875rem",
                  marginTop: "1px"
                }}
              >
                <div className="inputTime">
                  {" "}
                  <FormGroup className="mt-1">
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      timeFormat="HH:mm"
                      placeholderText="HH:MM"
                      dateFormat="h:mm aa"
                      name="restrictStartHour"
                      input={{
                        onChange: date =>
                          this.handleFormDataChange(date, "restrictStartHour"),
                        value: this.state.restrictStartHour
                      }}
                      customInput={this.props.customTimePickerInput}
                    />
                  </FormGroup>
                </div>
                <div className="inputTime">
                  <FormGroup className="mt-1">
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeFormat="HH:mm"
                      placeholderText="HH:MM"
                      dateFormat="h:mm aa"
                      name="restrictEndHour"
                      input={{
                        onChange: date =>
                          this.handleFormDataChange(date, "restrictEndHour"),
                        value: this.state.restrictEndHour
                      }}
                      customInput={this.props.customTimePickerInput}
                    />
                  </FormGroup>
                </div>
              </div>
            )}
            {playlistControl.weight && (
              <div className="p-2 bd-highlight" style={{ width: "120px" }}>
                <FormGroup className="mt-0 pos-r">
                  <input
                    type="number"
                    className="modal-duration input  form-control-sm w-100 playlist__input-voice"
                    min={1}
                    max={100}
                    value={this.state.weight}
                    name="weight"
                    placeholder="Num"
                    onChange={e =>
                      this.handleFormDataChange(e.target.value, "weight")
                    }
                  />
                  <span className="weight-percentage">%</span>
                </FormGroup>
                {this.checkError("weightError")}
              </div>
            )}
            <div className="p-2 bd-highlight">
              <FormGroup className="mt-0">
                <div className="d-flex ml-auto ">
                  <button
                    type="button"
                    className="btn-info contextButton "
                    style={{
                      fontSize: ".875rem",
                      padding: "7px",
                      marginTop: "5px"
                    }}
                    name="context"
                    onClick={() => this.toggleContextModal(true)}
                  >
                    {isEmpty(this.state.context)
                      ? "Add Context"
                      : "Edit Context"}
                  </button>
                  {this.state.contextModal ? (
                    <ContentModal
                      {...this.props}
                      contextModal={this.state.contextModal}
                      context={this.state.context}
                      toggleContextModal={this.toggleContextModal}
                      handleFormDataChange={this.handleFormDataChange}
                    />
                  ) : null}
                </div>
              </FormGroup>
            </div>
            <div className="p-2 ml-2 playlistStudioButton mt-2">
              <Tooltip
                item={
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <i
                    onClick={() => this.handleDelete(content.uniqueKey)}
                    className="fa fa-trash"
                    role="button"
                    tabIndex={0}
                  />
                }
                id={`deletePlaylistContent-${content.uniqueKey}`}
                tooltipContent="Delete"
                className="mt-1"
              />
            </div>
          </li>
        </ul>
      </div>
    );
  }
}
