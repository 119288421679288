import AWS from "aws-sdk";

AWS.config.region = process.env.REACT_APP_REGION;
AWS.config.accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
AWS.config.secretAccessKey = process.env.REACT_APP_SECRET_KEY_ID;
const s3 = new AWS.S3({
  Bucket: process.env.REACT_APP_CONTENT_BUCKET
});

export default class S3 {
  createParams = (key, data = undefined) => {
    const params = {
      Bucket: process.env.REACT_APP_CONTENT_BUCKET, // pass your bucket name
      Key: key // file will be saved as testBucket/contacts.csv
    };
    if (data) {
      params.Body = data;
    }
    return { ...params };
  };

  upload = (key, file, progress, completed, item) => {
    const params = this.createParams(key, file);
    let request = s3
      .upload(params, (s3Err, data) => {
        if (s3Err) {
          return s3Err;
        }
        completed(data, item);
      })
      .on("httpUploadProgress", evt => {
        progress(`${parseInt((evt.loaded * 100) / evt.total)}`, request);
      });
    return request;
  };

  getUrl = async key => {
    const params = this.createParams(key);
    params.Expires = 60 * 60 * 24 * 7;
    return await s3.getSignedUrl("getObject", params);
  };

  delete = key => {
    const params = this.createParams(key);
    s3.deleteObject(params, (s3Err, data) => {
      if (s3Err) {
        return s3Err;
      }
      return { success: true };
    });
  };

  put = (key, file, progress) => {
    const data = this.delete(key);
    const result = this.upload(key, file, progress);
    return result;
  };
}
