export const SponserTagResources = {
  getContent: {
    url: "contents/sponsor_tag_list",
    asyncActions: {
      init: "getContentSponser_INIT",
      success: "getContentSponser_SUCCESS",
      error: "getContentSponser_ERROR"
    }
  }
};

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined
};

export function SponserTag(state = { initialState }, action = {}) {
  const { init, success, error } = SponserTagResources.getContent.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: {
          _jsonapi: { data },
          links,
          meta
        },
        status,
        statusText
      } = action.payload;
      return {
        list: data.attributes.tags,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}
