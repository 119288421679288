/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import { findIndex, remove } from "lodash";
import { TabPane, Row, Col, Card } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { Tooltip } from "../../../core";
import { Resources } from "../../contents/redux/contentRedux";
import moment from "moment"

function UseableComponent(props) {
  const {
    item,
    handleClickedItem,
    handleDragStart,
    showDropZone,
    showDropZoneFunc,
    selected,
    playlistTextLimit
  } = props;
  return (
    <Col
      key={item.id}
      onClick={e => handleClickedItem(e, item)}
      onDragStart={e => handleDragStart(e, item)}
      onDrag={() => !showDropZone && showDropZoneFunc(true)}
      onDragEnd={() => showDropZoneFunc(false)}
      className="contentWrapper"
      md="3"
      sm="4"
      xs="6"
    >
      <div>
        <div draggable>
          <div className="imgcheck">
            <input type="checkbox" id="check" name="1" value="" />
            <label htmlFor="check">
              {item.attributes.is_filler_content &&
              item.attributes.is_filler_content === true ? (
                <div className="imageWrap">
                  <img
                    src={item.attributes.s3_thumbnail_medium_url}
                    alt="img"
                    className="img-fluid--contentImage"
                  />
                  <div
                    style={{ position: "relative", top: "70px", left: "75px" }}
                  >
                    <i
                      class="bx bxs-certification"
                      style={{ color: "#e44b32" }}
                    ></i>
                  </div>
                </div>
              ) : (
                <div className="imageWrap">
                  <img
                    src={item.attributes.s3_thumbnail_medium_url}
                    alt="img"
                    className="img-fluid--contentImage"
                  />
                </div>
              )}
              {findIndex(selected, { id: item.id }) > -1 && (
                <i
                  className="fas fa-check-circle mt-2 ml-2"
                  style={{ color: "#e44b32", fontSize: "20px" }}
                />
                // <img src="" alt="checked" className="checked " />
              )}
            </label>
          </div>
          {item.attributes.name.length >= playlistTextLimit ? (
            <Tooltip
              item={
                <p className="">
                  {" "}
                  {`${item.attributes.name.slice(
                    0,
                    playlistTextLimit
                  )}...`}{" "}
                </p>
              }
              id={item.id}
              tooltipContent={item.attributes.name}
            />
          ) : (
            <p className="">{item.attributes.name}</p>
          )}
        </div>
      </div>
    </Col>
  );
}

function UnuseableComponent(props) {
  const { item, playlistTextLimit } = props;
  return (
    <Col key={item.id} className="contentWrapper" md="3" sm="4" xs="6">
      <div style={{ cursor: "not-allowed" }} className="widgetWrapper--mod">
        <div style={{ height: "95px", width: "95px", position: "relative" }}>
        {item.attributes.is_filler_content &&
              item.attributes.is_filler_content === true ? (
                <div className="imageWrap">
                  <img
                    src={item.attributes.s3_thumbnail_medium_url}
                    alt="img"
                    className="img-fluid--contentImage"
                  />
                  <div
                    style={{ position: "relative", top: "70px", left: "75px" }}
                  >
                    <i
                      class="bx bxs-certification"
                      style={{ color: "#e44b32" }}
                    ></i>
                  </div>
                </div>
              ) : (
                <div className="imageWrap">
                  <img
                    src={item.attributes.s3_thumbnail_medium_url}
                    alt="img"
                    className="img-fluid--contentImage"
                  />
                </div>
              )}
          <div
            className="lockedOverlay"
            style={{ width: "120px", height: "100%" }}
          >
            <small
              className="lockedMessage"
              style={{ fontSize: 12, paddingLeft: "16px", color: "white" }}
            >
              Please contact admin or report manager.
            </small>
          </div>
        </div>
        {item.attributes.name.length >= playlistTextLimit ? (
          <Tooltip
            item={
              <p className="">
                {" "}
                {`${item.attributes.name.slice(0, playlistTextLimit)}...`}{" "}
              </p>
            }
            id={item.id}
            tooltipContent={item.attributes.name}
          />
        ) : (
          <p className="">{item.attributes.name}</p>
        )}
      </div>
    </Col>
  );
}

function MediaMapper(
  items,
  handleClickedItem,
  handleDragLeave,
  handleDragStart,
  selected,
  contentLoading,
  showDropZone,
  showDropZoneFunc,
  isLoading,
  playlistTextLimit,
  rateAmountRestriction
) {
  if (contentLoading || isLoading) {
    return (
      <svg className="spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </svg>
    );
  }
  return items.map(item => {
    if (
      !item.attributes.is_negotiable &&
      !item.attributes.is_filler_content &&
      rateAmountRestriction &&
      !(item.attributes.rate_per_minute || item.attributes.is_negotiable)
      || (rateAmountRestriction && item.attributes.end_date && moment().isAfter(moment(new Date(item.attributes.end_date))))
    ) {
      return (
        <UnuseableComponent item={item} playlistTextLimit={playlistTextLimit} />
      );
    }
    return (
      <UseableComponent
        item={item}
        handleClickedItem={handleClickedItem}
        handleDragStart={handleDragStart}
        showDropZone={showDropZone}
        showDropZoneFunc={showDropZoneFunc}
        selected={selected}
        playlistTextLimit={playlistTextLimit}
      />
    );
  });
}

class Mediatab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      hasMore: true
    };
  }

  /**
   * @param item elemnt array data being click
   */
  handleClickedItem = (e, item) => {
    e.preventDefault();
    const { contentToBeDropped } = this.props;

    if (findIndex(contentToBeDropped, { id: item.id }) === -1) {
      this.props.change("addPlaylistForm", "contentToBeDropped", [
        ...contentToBeDropped,
        item
      ]);
      return 0;
    }
    remove(contentToBeDropped, { id: item.id });

    this.props.change(
      "addPlaylistForm",
      "contentToBeDropped",
      contentToBeDropped
    );
    return 0;
  };

  handleDragLeave = (e, item) => {
    e.preventDefault();
    this.props.change("addPlaylistForm", "contentToBeDropped", []);
  };

  handleDragStart = (e, item) => {
    // e.dataTransfer.setData("text", "foo");
    this.props.showDropZoneFunc(true);
    const { contentToBeDropped } = this.props;
    if (contentToBeDropped.length === 0) {
      this.props.change("addPlaylistForm", "contentToBeDropped", [item]);
      this.props.change("addPlaylistForm", "selectedContent", [item]);
      return 0;
    }
    if (findIndex(contentToBeDropped, { id: item.id }) > -1) {
      this.props.change(
        "addPlaylistForm",
        "selectedContent",
        contentToBeDropped
      );
      return 0;
    }
    if (findIndex(contentToBeDropped, { id: item.id }) === -1) {
      const newList = [...contentToBeDropped, item];
      this.props.change("addPlaylistForm", "selectedContent", newList);
    }
  };

  fetchMoreData = async () => {
    if (this.state.hasMore) {
      this.props.increasePerPage();
      this.setState(() => {
        if (this.props.contentSearchByName) {
          Resources.getContent.url = `/contents/search?search=${this.props.contentSearchByName}&page=1&per_page=${this.props.perPage}`;
          this.props.Get(Resources.getContent).then(response => {
            if (response.data.meta.totalPages <= response.data.meta.page) {
              this.props.toggleFetch(false);
            }
          });
          return true;
        }

        if (this.props.contentSearchByTag) {
          Resources.getContent.url =
            this.props.selectedOptionType === "Sponsors"
              ? `/contents/search_sponsor_tag?sponsor_tags=${this.props.contentSearchByTag}&page=1&per_page=${this.props.perPage}`
              : this.props.selectedOptionType === "Tags"
              ? `/contents/search_tag?tags=${this.props.contentSearchByTag}&page=1&per_page=${this.props.perPage}`
              : this.props.selectedOptionType === "Brand"
              ? `/contents/search_by_brand?brand_tags=${this.props.contentSearchByTag}&page=1&per_page=${this.props.perPage}`
              : `/contents/search_by_party?party_tags=${this.props.contentSearchByTag}&page=1&per_page=${this.props.perPage}`;
          this.props.Get(Resources.getContent).then(response => {
            if (response.data.meta.totalPages <= response.data.meta.page) {
              // this.setState({ hasMore: false });
              this.props.toggleFetch(false);
            }
          });
          return true;
        }

        if (this.props.contentFilter) {
          this.props.contentFilter === "true" ? (Resources.getContent.url = `/contents/?is_filler_content=${this.props.contentFilter}&page=1&per_page=${this.props.perPage}`):
          (Resources.getContent.url = `/contents/?type=${this.props.contentFilter}&page=1&per_page=${this.props.perPage}`);
          this.props.Get(Resources.getContent).then(response => {
            if (response.data.meta.totalPages <= response.data.meta.page) {
              this.props.toggleFetch(false);
            }
          });
          return true;
        }

        if (this.props.contentSort) {
          Resources.getContent.url = `/contents?sort=${this.props.contentSort}&sort_by=${this.props.sortOrder}&page=1&per_page=${this.props.perPage}`;
          this.props.Get(Resources.getContent).then(response => {
            if (response.data.meta.totalPages <= response.data.meta.page) {
              this.props.toggleFetch(false);
            }
          });
          return true;
        }

        Resources.getContent.url = `/contents?page=1&per_page=${this.props.perPage}`;
        this.props.Get(Resources.getContent).then(response => {
          if (response.data.meta.totalPages <= response.data.meta.page) {
            this.props.toggleFetch(false);
          }
        });
      });
    }
    return true;
  };

  render() {
    const { selected } = this.state;

    const {
      list,
      contentLoading,
      showDropZone,
      showDropZoneFunc,
      isLoading,
      contentToBeDropped,
      playlistTextLimit
    } = this.props;
    if (contentLoading || isLoading) {
      return (
        <svg className="spinner" viewBox="0 0 50 50">
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
          />
        </svg>
      );
    }

    return (
      <TabPane tabId="1">
        {/* <div className="tab-content__wrapper"> */}
        <InfiniteScroll
          dataLength={list.length}
          next={this.fetchMoreData}
          hasMore={this.props.fetch}
          loader={
            !(contentLoading || isLoading) &&
            list.length > 0 && (
              <div className="d-block text-center">
                <span>...Loading</span>
              </div>
            )
          }
          className="content-infinity"
          height={625}
          // endMessage={
          //   !(contentLoading || isLoading) &&
          //   list.length > 0 && (
          //     <p style={{ textAlign: "center" }}>
          //       <b>This is the end of Content Section.</b>
          //     </p>
          //   )
          // }
          style={{ overFlow: "visible" }}
        >
          {list.length > 0 ? (
            <Row>
              {MediaMapper(
                list,
                this.handleClickedItem,
                this.handleDragLeave,
                this.handleDragStart,
                contentToBeDropped,
                contentLoading,
                showDropZone,
                showDropZoneFunc,
                isLoading,
                playlistTextLimit,
                this.props.rateAmountRestriction
              )}
            </Row>
          ) : (
            <p
              className="text-center"
              style={{ lineHeight: "50vh", fontSize: "20px" }}
            >
              Content Not Found
            </p>
          )}
        </InfiniteScroll>
        {/* </div> */}
      </TabPane>
    );
  }
}

Mediatab.defaultProps = {
  mediaList: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }]
};

export default connect(
  state => {
    const { selectedList } = state.SelectedContent;
    const { list, loading: contentLoading } = state.content;
    const selector = formValueSelector("addPlaylistForm");
    return {
      selectedList,
      contentLoading,
      list: list || [],
      contentToBeDropped: selector(state, "contentToBeDropped") || []
    };
  },
  { change }
)(Mediatab);
