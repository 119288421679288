import React, { Component } from "react";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

export class AddPlaylistStudioPlayButton extends Component {
  constructor(props) {
    super(props);
    this.state = { dropdownOpen: false };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  render() {
    return (
      <div>
        <Dropdown
          className="d-inline-block"
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          direction="left"
          style={{ height: "100%" }}
        >
          <DropdownToggle className="btn-outline-primary mr-0">
            <span>Play as </span>
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-19bqh2r"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Sequence</DropdownItem>
            <DropdownItem onClick={this.toggle}>
              <span
                onClick={() =>
                  this.props.playlistAttributeChange("random", true)
                }
              >
                <i
                  className="fa fa-check"
                  style={{ opacity: this.props.random ? "1" : "0" }}
                />
                Randomly
              </span>
            </DropdownItem>
            <DropdownItem onClick={this.toggle}>
              <span
                onClick={() =>
                  this.props.playlistAttributeChange("random", false)
                }
              >
                <i
                  className="fa fa-check"
                  style={{ opacity: this.props.random ? "0" : "1" }}
                />
                Sequentially
              </span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>Mode</DropdownItem>
            <DropdownItem onClick={this.toggle}>
              <span
                onClick={() =>
                  this.props.playlistAttributeChange("popOne", true)
                }
              >
                <i
                  className="fa fa-check"
                  style={{ opacity: this.props.popOne ? "1" : "0" }}
                />
                Single content
              </span>
            </DropdownItem>
            <DropdownItem onClick={this.toggle}>
              <span
                onClick={() =>
                  this.props.playlistAttributeChange("popOne", false)
                }
              >
                <i
                  className="fa fa-check"
                  style={{ opacity: this.props.popOne ? "0" : "1" }}
                />
                Burst content
              </span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
