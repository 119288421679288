/**
 *
 * @description make sure that all validation function name are declared camelCase.
 * @description undefined indicate that value pass validaion.
 * @description Import this funtion directly from this file. Don not eport this to constant/index
 *
 */

import { ifElse } from "ramda";
import moment from "moment";

export const required = value =>
  value || typeof value === "number" ? undefined : "Required Field";

export const checkHourValue = value => {
  if (value > 23) {
    return "Max 23 hr allowed";
  }
  if (value < 0) {
    return "Min 0 min allowed";
  }
};

export const checkMinValue = (value, formValues) => {
  if (
    formValues.hour === 0 ||
    formValues.hour === "" ||
    formValues.hour === "0"
  ) {
    if (value > 59) {
      return "Max 59 min allowed";
    }
    if (value < 1 && formValues.second !== "60") {
      return "Required at least 1 min";
    }
  } else {
    if (value > 59) {
      return "Max 59 min allowed";
    }
    if (value < 0) {
      return "Min 0 min allowed";
    }
  }
};

export const checkRestrictionMinValue = value => {
  if (value > 59) {
    return "Max 59 min allowed";
  }
  if (value < 0) {
    return "Min 0 min allowed";
  }
};

export const checkSecValue = value => {
  if (value > 60) {
    return "Max 60 sec allowed";
  }
  if (value < 0) {
    return "Min 0 sec required";
  }
};

export const validateRestrictionCampaignDuration = (value, formValues) => {
  const total =
    Number(formValues.campaignRestrictSecond) +
    Number(formValues.campaignRestrictMinute) * 60 +
    Number(formValues.campaignRestrictHour) * 3600;
  if (formValues.campaignRestrictionFlag && total < 15) {
    return "Min 15 sec required";
  }
};

export const number = value =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const integer = value => {
  if (value === "") {
    return;
  }
  const newVal = Number(value);
  return value && Number.isInteger(newVal) ? undefined : "Must be an integer";
};

export const isPositive = (value) => {
  if (value === "") {
    return;
  }
  const newVal = Number(value);
  return value && newVal >= 0
    ? undefined
    : "Must be a positive value"
}

export const isName = value =>
  value && /^[a-z ,.'-]+$/i.test(value)
    ? undefined
    : "Contains Invalid Characters";

export const isPasswordStrong = value =>
  value && value.length >= 8 ? undefined : "Minimum eight characters";

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;

export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;

export const fileName = value =>
  value && /[!@#$%^&*(),?":{}|<>]/g.test(value)
    ? "Invalid file name"
    : undefined;

export const validateTags = value => {
  let errorCheck = false;
  // eslint-disable-next-line
  value &&
    value.forEach(i => {
      if (i.indexOf(",") > -1 || i.indexOf(" ") > -1) {
        errorCheck = true;
      }
    });
  if (errorCheck) return "Do not use comma / space in tags.";
};

export const validateSubscriptionExpiry = (value, formValues) => {
  if (
    formValues.subscription_start_date &&
    formValues.subscription_end_date &&
    moment(formValues.subscription_start_date).isSameOrAfter(
      formValues.subscription_end_date
    )
  ) {
    return "End date must be greater than start date";
  }
  return undefined;
};

export const brandStartdateIsBefore = (value, formValues, props, field) => {
  const id = field.split("-")[1];
  const endDate = formValues[`brand-${id}-end_date`]
  if (!endDate) {
    return "End date is required"
  }
  else if (!moment(value).isBefore(endDate)) {
    return "Start date should be before end date";
  }
  return undefined;
}

export const brandEnddateIsAfter = (value, formValues, props, field) => {
  const id = field.split("-")[1];
  const startDate = formValues[`brand-${id}-start_date`]
  if (!startDate) {
    return "Start date is required"
  }
  else if (!moment(value).isAfter(startDate)) {
    return "End date should be after start date";
  }
  return undefined;
}

export const isTelegramId = (value) => {
  const regex = /-\d+\d$/g;
  if (value && !regex.test(value)) {
    return "Invalid telegram ID";
  }
  return undefined;
}

export const mailTime2BeforeMailTime1 = (value, formValues, props, field) => {
  const { mailTime1, mailTime2 } = formValues;
  if (!mailTime1 || !mailTime2) {
    return undefined;
  }
  const time1 = moment(mailTime1).format().split("+")[0];
  const time2 = moment(mailTime2).format().split("+")[0];
  const time1x = moment(time1, 'YYYY-MM-DDTHH:mm:ss');
  const time2x = moment(time2, 'YYYY-MM-DDTHH:mm:ss');
  if (time1x.isAfter(time2x)) {
    return undefined;
  } else {
    return "mail time 2 should be before mail time 1"
  }
}