/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from "react";
import {
  textPreview,
  urlPreview,
  hlsPreview,
  dateTimePreview,
  rssPreview,
  youtubePreview,
  picToScreenPreview,
  routePreview,
  transparentPreview
} from "../../pages/widgetPreview";

export default class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0
    };
  }

  componentDidMount() {
    this.startSetTimeOut(0);
  }

  indexRotator = (contentLength, currentIndex) => {
    console.info(contentLength, currentIndex);
    if (contentLength - 1 === currentIndex) {
      return 0;
    }
    return currentIndex + 1;
  };

  convertMinutesToSecond = ({ minute, second }) =>
    Number(minute) * 60 + Number(second);

  startSetTimeOut(index) {
    const { contents } = this.props;
    this.setState({ currentIndex: index });
    contents[index] &&
      setTimeout(() => {
        const newIndex = this.indexRotator(contents.length, index);
        this.startSetTimeOut(newIndex);
      }, 1000 * Number(this.convertMinutesToSecond(contents[index].duration)));
  }

  checkScale = scale => {
    if (scale === "fit") {
      return "fitImage";
    }
    if (scale === "fill") {
      return "fillImage";
    }

    return "stretchImage";
  };

  showContentPreview = (scale, content, width, height, verticalScale) => {
    if (content) {
      switch (content.type) {
        case "video": {
          return (
            <video
              autoPlay
              loop
              src={content.data}
              style={{ width, height }}
              poster={content.contentThumbnail}
            />
          );
        }
        case "Text": {
          return textPreview({}, content, verticalScale);
        }
        case "RSS": {
          return rssPreview({}, content, verticalScale);
        }
        case "URL": {
          return urlPreview({ width, height }, content, verticalScale);
        }
        case "DateTime": {
          return dateTimePreview({}, content, verticalScale);
        }
        case "HLS": {
          return hlsPreview({ width, height }, content);
        }
        case "Youtube": {
          return youtubePreview({ width, height }, content);
        }
        case "PicToScreen": {
          return picToScreenPreview({ width, height }, content);
        }
        case "Route": {
          return routePreview({}, content, verticalScale);
        }
        case "Transparent": {
          return transparentPreview({ width, height }, content);
        }
        default: {
          return (
            <img
              className={
                content.type === "playlist" &&
                content.playlistThumbnailType === "video"
                  ? "fillImage"
                  : this.checkScale(scale)
              }
              // style={{ width, height }}
              src={content.data}
              alt="preview"
            />
          );
        }
      }
    }
  };

  render() {
    const {
      screenX,
      screenY,
      width,
      height,
      contents,
      scale,
      verticalScale
    } = this.props;
    const { currentIndex } = this.state;
    return (
      <div style={styles.screen(screenX, screenY, width, height)}>
        <div
          className="imagePreviewWrapper"
          style={{
            backgroundColor:
              contents[currentIndex] && contents[currentIndex].backgroundColor
          }}
        >
          {this.showContentPreview(
            scale,
            contents[currentIndex],
            width,
            height,
            verticalScale
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  screen: (screenX, screenY, width, height) => ({
    top: screenY,
    left: screenX,
    width,
    height,
    position: "absolute",
    backgroundColor: "transparent"
  })
};
