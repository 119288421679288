/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { Tooltip } from "reactstrap";

export default class TooltipItem extends React.Component {
  static defaultProps = {
    tooltipContent: "Text"
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      id: props.id
    };
  }

  toggle() {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen
    }));
  }

  render() {
    return (
      <div className={this.props.className}>
        <span id={`Tooltip-${this.state.id}`} style={{ color: "#5b5b5b" }}>
          {this.props.item}
        </span>
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipOpen}
          target={`Tooltip-${this.state.id}`}
          toggle={this.toggle}
          className={this.props.customClass}
        >
          {this.props.tooltipContent
            ? this.props.tooltipContent
            : this.props.children}
        </Tooltip>
      </div>
    );
  }
}
