import React from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";

class ApexChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: this.props.data || [],
      options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: { enabled: false }
        },
        colors: this.props.color,
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: [1.5, 1.5]
        },
        markers: {
          size: this.props.marker
        },
        legend: {
          position: "top",
          horizontalAlign: "left"
        },
        xaxis: {
          type: "datetime",
          categories: this.props.timestamp || [],
          tooltip: {
            enabled: false
          }
        },
        animation: {
          enabled: false
        },
        dataLables: {
          enabled: false
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm"
          }
        },
        yaxis:
          {
            ...(this.props.yaxis || [])
          } || {}
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        series: this.props.data || [],
        options: {
          chart: {
            height: 350,
            type: "line",
            dropShadow: { enabled: false },
            events: {
              // scrolled: function(chartContext, { xaxis }) {
              //   console.error("scrolled");
              //   console.error(chartContext);
              //   console.error(xaxis);
              // },
              zoomed: (chartContext, { xaxis, yaxis }) => {
                const min = moment
                  .utc(xaxis.min)
                  .format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
                const max = moment
                  .utc(xaxis.max)
                  .format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
                const diff = moment(xaxis.max).diff(
                  moment(xaxis.min),
                  "minute"
                );
                if (diff < "6" && diff > "0") {
                  this.props.handleLoadDeviceNucMetricsWithRange(min, max);
                }
              },
              scrolled: (chartContext, { xaxis }) => {
                const min = moment
                  .utc(xaxis.min)
                  .format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
                const max = moment
                  .utc(xaxis.max)
                  .format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
                const diff = moment(xaxis.max).diff(
                  moment(xaxis.min),
                  "minute"
                );
                if (diff < "6" && diff > "0") {
                  this.props.toggleZoom(false);
                  this.props.handleLoadDeviceNucMetricsWithRange(min, max);
                }
              },
              click: e => {
                if (
                  (e.target.title === "Reset Zoom" ||
                    e.target.title === "Zoom Out") &&
                  this.props.isZoom === true
                ) {
                  this.props.toggleZoom(false);
                }
              }
            }
          },
          colors: this.props.color,
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight",
            width: [1.5, 1.5]
          },
          markers: {
            size: this.props.marker
          },
          legend: {
            position: "top",
            horizontalAlign: "left"
          },
          xaxis: {
            type: "datetime",
            categories: this.props.timestamp || []
          },
          yaxis:
            {
              ...this.props.yaxis
            } || {},
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm"
            }
          }
        }
      });
    }
  }

  render() {
    return (
      <div
        id="chart"
        style={{ border: "1px solid rgb(219, 219, 235)", padding: "1rem" }}
      >
        {this.props.isLoadingMetrics && (
          <div className="d-block text-center">
            <svg className="spinner" viewBox="0 0 50 50">
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="4"
              />
            </svg>{" "}
          </div>
        )}
        <span>
          <strong>{this.props.name}</strong>
        </span>
        <span style={{ float: "right" }}> {this.props.latestdata}</span>
        <ReactApexChart
          style={{ marginTop: "16px" }}
          options={this.state.options}
          series={this.state.series}
          type={this.props.chart}
          height={280}
        />
      </div>
    );
  }
}

export default ApexChart;
