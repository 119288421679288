import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Tooltip } from "../../../core";
import { Resources } from "../redux/ticketRedux";
// import { object } from "prop-types";

class TicketDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      modal: false,
      ticketStatus: "new",
      content_name: false,
      users: undefined
    };
    this.getTicketDetails();
  }

  getTicketDetails(flag, nextId) {
    const { id } = this.props.match.params;
    const { showTicket } = Resources;
    if (flag) {
      showTicket.url = `tickets/${nextId}`;
    } else {
      showTicket.url = `tickets/${id}`;
    }
    this.props.Get(showTicket).then(response => {
      if (response.status === 200) {
        const users = response.data.included.filter(
          item => item.type === "users"
        );
        this.setState({
          ticketStatus: response.data.data.attributes.status,
          users
        });
        this.handleChangeStatus();
      }
      this.setState({
        isLoading: false
      });
    });
  }

  //
  // eslint-disable-next-line react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.getTicketDetails(true, nextProps.match.params.id);
    }
  }

  toggleResolveModal = flag => {
    this.setState({ modal: flag });
  };

  handleChangeStatus() {
    const { ticketStatus } = this.state;
    const { changeToPending } = Resources;
    if (ticketStatus === "open" || ticketStatus === "new") {
      changeToPending.url = `tickets/${this.props.match.params.id}/change_to_pending`;
      this.props.Put(changeToPending).then(response => {
        if (response.status === 200) {
          const users = response.data.included.filter(
            item => item.type === "users"
          );
          this.setState({
            ticketStatus: "pending",
            users
          });
        }
      });
    }
  }

  handleChangeStatusResolved(flag) {
    this.setState({ modal: flag });
    const { changeToResolved } = Resources;
    if (this.state.ticketStatus !== "resolved") {
      changeToResolved.url = `tickets/${this.props.match.params.id}/change_to_resolve`;
      this.props.Put(changeToResolved).then(response => {
        if (response.status === 200) {
          const users = response.data.included.filter(
            item => item.type === "users"
          );
          this.setState({
            ticketStatus: "resolved",
            users
          });
        }
      });
    }
  }

  handleBookingStatus() {
    const { bookingStatus } = this.props.tickets;
    if (bookingStatus === "locked") {
      return <div className="badge badge-resolved">{bookingStatus}</div>;
    }
    return <div className="badge badge-open">{bookingStatus}</div>;
  }

  handleTooltipClose = name => {
    this.setState({ [name]: false });
  };

  handleTooltipOpen = name => {
    this.setState({ [name]: true });
  };

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="d-block text-center">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    const content = this.props.tickets.content[0];
    const { devices } = this.props.tickets;
    // const { users } = this.props.tickets;
    // const cal_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return (
      <div className="mainPage">
        <div className="contentSection">
          <div className="contentHeader row justify-content-between">
            <div className="col-md-9 mt-2">
              <span className="contentHeader__information">
                {" "}
                <b>Ticket Created</b>{" "}
                <span className="ticketCreatedinfo">
                  {this.props.tickets.createdAt}
                </span>
              </span>
            </div>
            <div className="col-md-3">
              <div className="float-right">
                {this.state.ticketStatus !== "resolved" ? (
                  <button
                    type="button"
                    onClick={() => this.toggleResolveModal(true)}
                    className="btn btn-outline-primary"
                  >
                    Resolve
                  </button>
                ) : null}
                <Modal
                  isOpen={this.state.modal}
                  toggle={() => this.toggleResolveModal(true)}
                >
                  <ModalHeader
                    toggle={() => {
                      this.toggleResolveModal(false);
                    }}
                  >
                    Ticket Confirmation
                  </ModalHeader>
                  <ModalBody>
                    Are you sure you want to resolve this ticket{" "}
                    <b>{this.props.tickets.name} </b>?
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="btn-outline-primary"
                      onClick={() => {
                        this.toggleResolveModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="primaryButton mt-0"
                      onClick={() => {
                        this.handleChangeStatusResolved(false);
                      }}
                    >
                      Resolve
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
          <div className="networkDetail__info row">
            <div className="col-md-4">
              {" "}
              <div className="networkDetail__info__leftSection">
                {" "}
                <div className="networkDetail__devicescreenshot network-screen">
                  <img
                    src={
                      content.attributes.s3Url ||
                      require("../../../assets/images/empty-img.png")
                    }
                    alt="Content"
                    className="network-screen__img"
                  />
                </div>
                <div className="contentInfo">
                  <span className="contentType">
                    {" "}
                    {content.attributes.content_type}
                  </span>
                  <span className="ticket_content__titlename">
                    {content.attributes.name.length >= 25 ? (
                      <Tooltip
                        id="tooltip-controlled-content_name"
                        onClose={() => this.handleTooltipClose("content_name")}
                        onOpen={() => this.handleTooltipOpen("content_name")}
                        open={this.state.content_name}
                        placement="bottom"
                        title="content_name"
                        className="content_tooltip"
                        item={
                          <span className="content__name">
                            {" "}
                            {`${content.attributes.name.slice(0, 25)}...`}{" "}
                          </span>
                        }
                        // id={`viewname-${content.attributes.id}`}
                        tooltipContent={content.attributes.name}
                      />
                    ) : (
                      <span className="content__name">
                        {content.attributes.name}
                      </span>
                    )}
                  </span>{" "}
                  <span className="contentType">
                    {" "}
                    {content.attributes.contentType}
                  </span>
                </div>
                <Row className="mt-3">
                  <Col>
                    <div className="informationBlock">
                      <i>
                        {this.state.ticketStatus === "resolved"
                          ? "Resolve By "
                          : "Viewed By"}{" "}
                      </i>
                      <span className="mb-0 repetitioninfo">
                        {this.state.users[0] &&
                          this.state.users[0].attributes.name}
                      </span>
                      <p>
                        {this.state.users[0] &&
                          this.state.users[0].attributes.email}
                      </p>
                    </div>
                  </Col>
                  <Col className="md-6">
                    <div className="informationBlock">
                      <i>Ticket Status</i>
                      <div className="statusinfo">
                        {this.state.ticketStatus === "resolved" ? (
                          <div className="badge badge-resolved">
                            {this.state.ticketStatus}
                          </div>
                        ) : this.state.ticketStatus === "pending" ? (
                          <div className="badge badge-pending">pending</div>
                        ) : (
                          <div className="badge badge-open">new</div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="col-md-8">
              <div className="ticketDetail__ticketinfo-wrapper">
                <div className="ticketDetail__bookinginfo-wrapper">
                  <Row>
                    <Col className="md-4">
                      <div className="informationBlock">
                        <i>Booking Name</i>
                        <span className="mb-0">{this.props.tickets.name}</span>
                      </div>
                    </Col>

                    <Col className="md-4">
                      {" "}
                      <div className="informationBlock">
                        <i>Booking Dates</i>
                        <span className="mb-0">
                          {this.props.tickets.startingDate || " - "}
                          {" - "}
                          {this.props.tickets.endingDate || " - "}
                        </span>
                      </div>
                    </Col>

                    <Col className="md-4">
                      <div className="informationBlock">
                        <i>Screen Time</i>
                        <span className="mb-0">
                          {this.props.tickets.startTime || " - "}
                          {" - "}
                          {this.props.tickets.endTime || " - "}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="md-4">
                      <div className="informationBlock">
                        <i>Share of Voice </i>
                        <span className="mb-0">
                          {this.props.tickets.shareOfVoice}% out of{" "}
                          {this.props.tickets.blocksize || "N/A"} sec
                        </span>
                      </div>
                    </Col>
                    <Col className="md-4">
                      <div className="informationBlock">
                        <i>Repetition</i>
                        <span className="mb-0 repetitioninfo">
                          {this.props.tickets.repeat}
                        </span>
                      </div>
                    </Col>
                    <Col className="md-4">
                      <div className="informationBlock">
                        <i>Booking Status</i>
                        <div className="statusinfo">
                          {this.handleBookingStatus()}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="md-4">
                      <div className="informationBlock">
                        <i>Account Name</i>
                        <span className="mb-0">
                          {this.props.tickets.customer || "N/A"}
                        </span>
                      </div>
                    </Col>
                    <Col className="md-4">
                      <div className="informationBlock">
                        <i>Contact</i>
                        <span className="mb-0 repetitioninfo">
                          {this.props.tickets.contactName || "N/A"}
                        </span>
                        <span className="mb-0">
                          {this.props.tickets.contactEmail || "N/A"}
                        </span>
                      </div>
                    </Col>
                    <Col className="md-4"></Col>
                  </Row>
                  <Row>
                    <Col className="md-12">
                      <div className="informationBlock">
                        <i>Notes</i>
                        {this.props.tickets.note !== "" ? (
                          <div className="note-view">
                            <div
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: this.props.tickets.note
                              }}
                            />
                          </div>
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="bookingheaderinfo">
                <span className="contentHeader__information">Devices</span>
              </div>
              <div className="ticketDetail__ticketinfo-wrapper mt-3">
                <table className="tableForDeviceInformation">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Hostname</th>
                      <th>MacAddress</th>
                    </tr>
                  </thead>
                  <tbody>
                    {devices.map(item => (
                      <tr key={item.id}>
                        <td>{item.attributes.name}</td>
                        <td>{item.attributes.hostname}</td>
                        <td>{item.attributes.macAddress}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  const myProps = ownProps;
  const { list } = state.ticketDetails;
  return { myProps, tickets: list };
})(TicketDetails);
